const data = [

    { name:'xintian',
    type:'inc',
    description:'xintian inc is a technology company focus on software development',
    image:'https://res.cloudinary.com/dlapk94rx/image/upload/v1609885844/Logo_dgkrtv.png',
    background:'https://res.cloudinary.com/dvr397xda/image/upload/v1653927826/cube/1921iq6-a-room-that-has-a-umbrella-in-her-hand_rainyw.jpg',
    title:'媒体广告',
    chinese:'星天工作室',
    stage:'https://res.cloudinary.com/dlapk94rx/image/upload/v1644614268/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/q_q_nlwuh1.png'

},
    {
        name:'360realty',
        type:'llc',
        description:'360realty llc is a realty company focus on realty market',
        image:'https://res.cloudinary.com/dlapk94rx/image/upload/v1644527422/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/logo%E6%9C%80%E6%9C%80%E7%BB%88%E4%B8%AD%E9%97%B4%E5%B0%8F_nsb7yy.png',
        background:'https://res.cloudinary.com/dvr397xda/image/upload/v1653927826/cube/lok-yiu-cheung-o6k0ZH1eOwg-unsplash_v47jfx.jpg',
        title:'地产',
        chinese:'线上地产',
        stage:'https://res.cloudinary.com/dlapk94rx/image/upload/v1644527525/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/logo%E6%9C%80%E6%9C%80%E7%BB%88%E5%B0%8F%E5%B0%8F%E4%B8%AD_ayuyzo.png'
    },
    { name:'weiiz',
    type:'inc',
    description:'xintian inc is a technology company focus on software development',
    image:'https://res.cloudinary.com/dvr397xda/image/upload/v1654294846/cube/weiiz_iojznp.png',
    background:'https://res.cloudinary.com/dvr397xda/image/upload/v1653927826/cube/1921iq6-a-room-that-has-a-umbrella-in-her-hand_rainyw.jpg',
    title:'预约平台',
    chinese:'微芝',
    stage:'https://res.cloudinary.com/dvr397xda/image/upload/v1654294846/cube/weiiz_iojznp.png'

},
{ name:'xintianprint',
type:'inc',
description:'xintian inc is a technology company focus on software development',
image:'https://res.cloudinary.com/dlapk94rx/image/upload/v1644527134/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/%E9%BD%BF%E8%BD%AE_cjhhew.png',
background:'https://res.cloudinary.com/dvr397xda/image/upload/v1653927826/cube/1921iq6-a-room-that-has-a-umbrella-in-her-hand_rainyw.jpg',
title:'设计印刷',
chinese:'星天印刷',
stage:'https://res.cloudinary.com/dlapk94rx/image/upload/v1644527134/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/%E9%BD%BF%E8%BD%AE_cjhhew.png'

},
{ name:'xintianwuliu',
type:'inc',
description:'xintian inc is a technology company focus on software development',
image:'https://res.cloudinary.com/dlapk94rx/image/upload/v1609885844/Logo_dgkrtv.png',
background:'https://res.cloudinary.com/dvr397xda/image/upload/v1653927826/cube/1921iq6-a-room-that-has-a-umbrella-in-her-hand_rainyw.jpg',
title:'物流',
chinese:'星天物流',
stage:'https://res.cloudinary.com/dlapk94rx/image/upload/v1644344724/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/ezgif-1-6b24390582_ak6q7y.gif'

},
{ name:'groupchop',
type:'inc',
description:'xintian inc is a technology company focus on software development',
image:'https://res.cloudinary.com/dlapk94rx/image/upload/v1633559638/dichan/3d-cube_nnkcun.png',
background:'https://res.cloudinary.com/dvr397xda/image/upload/v1653927826/cube/1921iq6-a-room-that-has-a-umbrella-in-her-hand_rainyw.jpg',
title:'线上商城',
chinese:'团购商城',
stage:'https://res.cloudinary.com/dlapk94rx/image/upload/v1633559638/dichan/3d-cube_nnkcun.png'

},









]



const fetchCompany = ()=>{
    return data;
}

export default fetchCompany;