const data = [
    'https://pryrealty.s3.amazonaws.com/1657743249200817.mp4',
    'https://pryrealty.s3.amazonaws.com/%E5%86%99%E5%AD%97.mp4',
]




const fetchVideo=()=>{

return data


}

export default fetchVideo

