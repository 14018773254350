import React,{useState,useEffect,useRef,Suspense,useCallback}from 'react'
import { Canvas, useFrame,extend,useThree,useLoader,} from '@react-three/fiber'
import * as THREE from "three";
import HTMLFlipBook from "react-pageflip";
import {useParams} from 'react-router-dom'

import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { TextureLoader } from 'three/src/loaders/TextureLoader.js';
import fetchBasic from '../../basic3'
import {useGLTF,useFBX} from '@react-three/drei'

import './index.scss'


const  Model=({people})=>{
    const mesh = useRef()
    const defaultMap ='https://res.cloudinary.com/dvr397xda/image/upload/v1659113641/cube/basic/yuxin/Mew_Diff_tg9hgf.png'
    let key = useFBX(people.model.url)

  
    const [colorMap, metalMap, normalMap, roughnessMap, aoMap] = useLoader(THREE.TextureLoader, [
        people.model.colorMap||defaultMap,
        people.model.metalMap||defaultMap,
        people.model.normalMap||defaultMap,
        people.model.roughnessMap||defaultMap,
        people.model.aoMap||defaultMap,
      ])
    


    key.traverse( function ( child ) {

        if ( child.isMesh ) {
            console.log(child);
            if(people.model.colorMap)
            child.material.map = colorMap;
            if(people.model.aoMap)
            child.material.aoMap =aoMap;
            if(people.model.normalMap)
            child.material.normalMap = normalMap;
            if(people.model.metalMap)
            child.material.metalMap = metalMap; 
            if(people.model.roughnessMap)
            child.material.roughnessMap = roughnessMap; 
          }
      
      } );
      useFrame((state, delta) => {
      
    
        mesh.current.rotation.y += 0.005
      
      })

      return (
        <mesh scale={people.model.scale} position={people.model.position} rotation={people.model.rotation}  ref={mesh}>
            <primitive   object={key} />
    
        </mesh>
      )

}




const  Cube = ({position})=>{

  const image = 'https://res.cloudinary.com/dvr397xda/image/upload/v1653324529/cube/%E8%BE%B9%E6%A1%862_b3lfk1.png'
  const mesh = useRef()
  const map = useLoader(THREE.TextureLoader,image)
  useFrame((state, delta) => {
    mesh.current.scale.x = 1 + (1 + Math.sin(state.clock.elapsedTime * 1)) * 0.03
    mesh.current.scale.y = 1 + (1 + Math.sin(state.clock.elapsedTime * 1)) * 0.03
    mesh.current.scale.z = 1 + (1 + Math.sin(state.clock.elapsedTime * 1)) * 0.03
  })

  return (
    <>
    <mesh position={position} ref={mesh}>
        <boxBufferGeometry  attach="geometry"  args={[2.2, 2.2, 2.2]}/>
        <meshStandardMaterial transparent={true} opacity={1}  attach="material" map={map}  roughness={0.1}
         />

       


    </mesh>
    </>
  )
}

function Book({people}){



  const onFlip = useCallback((e) => {
    console.log('Current page: ' + e.data);
}, []);

  return (


 
  <HTMLFlipBook className="wrapper" 
  size='stretch'  width={195}  height={338}        maxShadowOpacity={0.5}
  onFlip={onFlip}
  showCover
 >
  
  {
      people.show&&people.show.map((v,i)=>(
          <div id='page-item' className="demoPage" key={i}>
            
            <h2>
                    {v.name}
                </h2>
                {v.title&&<h2>
                    {v.title}
                </h2>}
               
             <img src={v.image} alt="" />
               
          </div>
      ))
  }
</HTMLFlipBook>

)
}


function Box(props) {
  const {people,position} = props
  const mesh = useRef()
  const [index,setIndex] = useState(0)

 

 
  // setInterval(handleChangeIndex,1000)

  let textures;

textures = useLoader(THREE.TextureLoader,[people.images[0],people.images[1],people.images[2],people.images[3],people.images[4],people.images[5]])
// textures = useLoader(TextureLoader,[people.images[0],people.images[1],people.images[2],people.images[3],people.images[4],people.images[5]])

textures.minFilter = textures.magFilter = THREE.LinearFilter;



textures.format= THREE.RGBAFormat;




  function Map (){
    return(
    
      textures.map((texture, idx) =>(
        idx ===2||idx===3 || idx===0?<meshBasicMaterial key={idx} attach={`material-${idx}`} map={texture}   transparent={true} opacity={1}/>
        :<meshBasicMaterial opacity={1} transparent={true}  key={idx} attach={`material-${idx}`} map={texture}  />
        )
)
    )
  } 

  return (
    <>
    <mesh
      onClick={(e)=>{
        e.stopPropagation()
      }}
      ref={mesh}
     position={position}
     
      >
          
      <boxBufferGeometry attach="geometry"  args={[2.5, 2.5, 2.5]}/>
        <Map/>
       
      

    </mesh>


    </>
  )
}

extend({ OrbitControls });

const CameraControls = () => {

  const {
    camera,
    gl: { domElement },
  } = useThree();
  const controls = useRef();
  useFrame(() => controls.current.update());
  return (
    <orbitControls
      ref={controls}
      args={[camera, domElement]}
      autoRotate={false}
      enableZoom={true}
      maxDistance={5.5}
      minDistance={2}
    />
  );
};

const Phone = ({people,opacity,posY})=>{

  const mesh1 = useRef()


  const handleClick=()=>{
    window.open(`tel:+1${people.tel}`,'_self')
  }


  return (
    <>
    <mesh position={[-0.8,posY-1.65,1.3]} rotation={[0,Math.PI * 2.5,0]} onClick={(e)=>{ handleClick();     e.stopPropagation()
}}   ref={mesh1}>
        <boxBufferGeometry  attach="geometry"  args={[0.1,1.1,0.8]}/>
        <meshStandardMaterial visible={false} transparent={true} opacity={opacity}   color='green'  />

       


    </mesh>
    </>
  )
}

const Cell = ({people,opacity,posY})=>{
   
    const mesh1 = useRef()
 
  
    const handleClick=()=>{
      window.open(`tel:+1${people.cell}`,'_self')
    }
  
  
    return (
      <>
      <mesh position={[0,posY-1.9,1.3]} rotation={[0,Math.PI * 2.5,0]} onClick={(e)=>{ handleClick();     e.stopPropagation()
  }}   ref={mesh1}>
          <boxBufferGeometry  attach="geometry"  args={[0.1,0.6,2.5]}/>
          <meshStandardMaterial visible={false} transparent={true} opacity={opacity}  color='red' />
  
         
  
  
      </mesh>
      </>
    )
  }

const Email = ({people,opacity,posY})=>{

  const mesh1 = useRef()
 

  const handleClick=()=>{
 
    window.open(`mailto:+${people.email}`,'_self')
  }

  return (
    <>
    <mesh position={[0,posY-1.8,1.3]} rotation={[0,Math.PI * 2.5,0]} onClick={(e)=>{ handleClick();     e.stopPropagation()
}}   ref={mesh1}>
        <boxBufferGeometry  attach="geometry"  args={[0.1,0.8,0.8]}/>
        <meshStandardMaterial visible={false} transparent={true} opacity={opacity}  color='#fff' />

       


    </mesh>
    </>
  )
}

const Map = ({people,opacity,posY})=>{
 
  const mesh1 = useRef()


  const handleClick=()=>{

    window.open(`${people.map}`,'_self')
  }

  return (
    <>
    <mesh rotation={[0,Math.PI * 2.5,0]} position={[0,-1.65+posY,1.3]} onClick={(e)=>{ handleClick();     e.stopPropagation()
}}   ref={mesh1}>
        <boxBufferGeometry  attach="geometry"  args={[0.1,1.1,0.8]}/>
        <meshStandardMaterial visible={false} transparent={true} opacity={opacity}   color='blue'   />

       


    </mesh>
    </>
  )
}

const Youtube = ({people,opacity,posY})=>{

    const mesh1 = useRef()
   
  
    const handleClick=()=>{
  
      window.open(`${people.video}`,'_self')
    }
  
    return (
      <>
      <mesh position={[-1.4,-1+posY,0.6]} onClick={(e)=>{ handleClick();     e.stopPropagation()
  }}   ref={mesh1}>
          <boxBufferGeometry  attach="geometry"  args={[0.1,0.7,1.2]}/>
          <meshStandardMaterial visible={false} transparent={true} opacity={opacity}  attach="material" color='green'  roughness={0.7}
          metalness={0.1} />
  
         
  
  
      </mesh>
      </>
    )
  }

  const QR = ({opacity,posY,handleOpenQr})=>{
  
    const mesh1 = useRef()
  
  
  
    return (
      <>
      <mesh rotation={[0, Math.PI * 2.5, 0]} position={[0,-1.6+posY,-1.3]} onClick={(e)=>{  handleOpenQr();     e.stopPropagation()
  }}   ref={mesh1}>
          <boxBufferGeometry  attach="geometry"  args={[0.1,1.3,2.5]}/>
          <meshStandardMaterial visible={false} transparent={true} opacity={opacity}   color='green'   />
  
         
  
  
      </mesh>
      </>
    )
  }



const Web = ({people,opacity,posY})=>{
 
  const mesh1 = useRef()

  const handleClick=()=>{
    window.open(`${people.website}`,'_self')
  }

  return (
    <>
    <mesh rotation={[0, Math.PI * 2.5, 0]} position={[0.8,-1.65+posY,1.3]} onClick={(e)=>{ handleClick();     e.stopPropagation()
}}   ref={mesh1}>
        <boxBufferGeometry  attach="geometry"  args={[0.1,1.1,0.8]}/>
        <meshStandardMaterial visible={false} transparent={true} opacity={opacity}   color='#fff'   />

       


    </mesh>
    </>
  )
}

const Menu = ({people,opacity,posY})=>{
 
  const mesh1 = useRef()

  const handleClick=()=>{
    window.open(`${people.menu}`,'_self')
  }

  return (
    <>
    <mesh rotation={[0, Math.PI * 2.5, 0]} position={[0,-1.65+posY,-1.3]} onClick={(e)=>{ handleClick();     e.stopPropagation()
}}   ref={mesh1}>
        <boxBufferGeometry  attach="geometry"  args={[0.1,1.1,2.5]}/>
        <meshStandardMaterial transparent={true} opacity={opacity}   color='#fff'   />

       


    </mesh>
    </>
  )
}



export default function Basic3() {
    const{ name } = useParams();
    
    const mesh2 = useRef()
    const [people,setPeople]=useState({})

   useEffect(()=>{

    const data = fetchBasic();
    const result = data.filter(e=>e.id===name)
 
    setPeople(result[0])

   },[])
   



  const [isTeam,setIsTeam] = useState(false)
  const [isQR,setIsQR] = useState(false)
  const opacity=0
  const posY = 1

  const [load,setLoad] = useState(true)

  const handleOpenQr = ()=>{
      setIsQR(true)
  }




  const handleClickTeam = ()=>{
    setIsTeam(true)
  }
  const handleCloseTeam = ()=>{
    setIsTeam(false)
    setIsQR(false)
  }
  
  useEffect(() => {
    if(people.loadImg){
        const timer = setTimeout(()=>{
            setLoad(false)
          },3000)
          return ()=>clearTimeout(timer)
    }
    else{
        setLoad(false)
    }
 
  },[people])

  return (
    <div className="container-card" style={{backgroundImage:`url(${people.gif})`}}  >
     
     <Canvas  gl={{ alpha: true, antialias: true}}  className='canvas-wrap'>
    <CameraControls />
    {/* <ambientLight intensity={0.8} /> */}
 <pointLight position={[3, 3, 3]} intensity={1} color='#f0f0f0'/>
        <pointLight position={[3, -3, 3]} intensity={1} color='#f0f0f0'/>

        <pointLight position={[-3, 3, 3]} intensity={1} color='#f0f0f0'/>

        <pointLight position={[3, 3, -3]} intensity={1} color='#f0f0f0'/>
        <spotLight  position={[0, 0, 0]} intensity={1} color='#f0f0f0'/>

      <Suspense fallback={null}>
   

        
      <Box people={people}   position={[0, 0, 0]} />
    
        <Model people={people}/>
      {/* <Cube position={[0, 0, 0]} /> */}
      

      <Phone posY={posY} people={people} opacity={opacity}/>
      {people.cell&&<Cell posY={posY} people={people} opacity={opacity}/>}

     { people.email&&<Email posY={posY} people={people} opacity={opacity}/>}
      {people.menu&&<QR handleOpenQr={handleOpenQr} posY={posY} people={people} opacity={opacity}/>}
      <Map posY={posY} people={people} opacity={opacity}/>
      <Web posY={posY} people={people} opacity={opacity}/>
     { people.video&&<Youtube posY={posY} people={people} opacity={opacity}/>}
     {/* {people.menu&&<Menu posY={posY} people={people} opacity={opacity}/>} */}

      {/* <Linkedin posY={posY} people={people} opacity={opacity}/> */}
      {/* <VideoBtn people={people} opacity={opacity}/> */}

      {/* <mesh rotation={[0,0,0]} position={[-1.4,-1.8+posY,-0.6]} onClick={(e)=>{ handleClickTeam();     e.stopPropagation()
}}   ref={mesh2}>
        <boxBufferGeometry  attach="geometry"  args={[0.1,0.7,1.2]}/>
        <meshStandardMaterial transparent={true} opacity={opacity}    color='red'   />

       
    </mesh> */}
    
    </Suspense>

    </Canvas>
    {people.show&&<div style={isTeam ? {top:'20%',transition:'1.5s'}:{top:'200%',transition:'1.5s'}}  className="wrapper">
          <Book people={people}/>
          <img onClick={()=>handleCloseTeam()} id='close' src="https://res.cloudinary.com/dvr397xda/image/upload/v1656101942/cube/cancel_dmukx0.png" alt="" />
    </div>}
         

          <div style={load ?{ zIndex:'100000',opacity:'1'} :{zIndex:'-10',opacity:'0',transition:'3.5s' }} className='load-image'>
                <img src={people.loadImg} alt="" />
          </div>

          <div style={isQR ? {top:'20%',transition:'1.5s'}:{top:'200%',transition:'1.5s'}}  className="wrapper">
          <img id='qr' src={people.menu} alt="" />
          <a href={`tel:+1${people.tel}`}>

          <button>
            <h3>
            Booking Now
            </h3>
            </button>
          </a>

          <img onClick={()=>handleCloseTeam()} id='close' src="https://res.cloudinary.com/dvr397xda/image/upload/v1656101942/cube/cancel_dmukx0.png" alt="" />
    </div>

    </div>
  )
}

