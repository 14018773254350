import { useLayoutEffect,useState,useRef,Suspense} from 'react'
import { applyProps, Canvas,useLoader, } from '@react-three/fiber'
import { useGLTF, Image, Environment,  BakeShadows,OrbitControls ,PerspectiveCamera,Html, useProgress,} from '@react-three/drei'
import * as THREE from "three";


import Pop from './Pop'
import './index.scss'
import Card from './components/Cube/index'
import Bottle from './components/Bottle/index'

const pictures = [
  {
    position:[-15.3,3,0],
    map:'https://res.cloudinary.com/dvr397xda/image/upload/v1656697290/cube/davanci_pbqoyi.png',
    title:'达芬奇作品',
    des:'列奥纳多·达·芬奇是意大利文艺复兴时期佛罗伦萨共和国的博学者：在绘画、音乐、建筑、数学、几何学、解剖学、生理学、动物学、植物学、天文学、气象学、地质学、地理学、物理学、光学、力学、发明、土木工程等领域都有显著的成就。这使他成为文艺复兴时期人文主义的代表人物，也是历史上最著名的艺术家之一，与米开朗基罗和拉斐尔并称文艺复兴三杰',
    price:'3,000'
  },
  {
    position:[-15.3,3,9],
    map:'https://res.cloudinary.com/dvr397xda/image/upload/v1657046181/cube/Vincent-van-Gogh-Self-Portrait-niood_dyu2ed.webp',
    title:'达芬奇作品',
    des:'列奥纳多·达·芬奇是意大利文艺复兴时期佛罗伦萨共和国的博学者：在绘画、音乐、建筑、数学、几何学、解剖学、生理学、动物学、植物学、天文学、气象学、地质学、地理学、物理学、光学、力学、发明、土木工程等领域都有显著的成就。这使他成为文艺复兴时期人文主义的代表人物，也是历史上最著名的艺术家之一，与米开朗基罗和拉斐尔并称文艺复兴三杰',
    price:'6,000'
  },

  {
   position:[-15.3,3,18],
   map:'https://res.cloudinary.com/dvr397xda/image/upload/v1656698305/cube/4_sxsd0v.png',
   title:'达芬奇作品',
   des:'列奥纳多·达·芬奇是意大利文艺复兴时期佛罗伦萨共和国的博学者：在绘画、音乐、建筑、数学、几何学、解剖学、生理学、动物学、植物学、天文学、气象学、地质学、地理学、物理学、光学、力学、发明、土木工程等领域都有显著的成就。这使他成为文艺复兴时期人文主义的代表人物，也是历史上最著名的艺术家之一，与米开朗基罗和拉斐尔并称文艺复兴三杰',
   price:'11,000'
 },
 {
   position:[-15.3,3,28],
   map:'https://res.cloudinary.com/dvr397xda/image/upload/v1656698305/cube/2_dgvxso.png',
   title:'达芬奇作品',
   des:'列奥纳多·达·芬奇是意大利文艺复兴时期佛罗伦萨共和国的博学者：在绘画、音乐、建筑、数学、几何学、解剖学、生理学、动物学、植物学、天文学、气象学、地质学、地理学、物理学、光学、力学、发明、土木工程等领域都有显著的成就。这使他成为文艺复兴时期人文主义的代表人物，也是历史上最著名的艺术家之一，与米开朗基罗和拉斐尔并称文艺复兴三杰',
   price:'4,000'
 },
 {
   position:[-15.3,3,-18],
   map:'https://res.cloudinary.com/dvr397xda/image/upload/v1656698306/cube/3_mpk7y3.png',
   title:'达芬奇作品',
   des:'列奥纳多·达·芬奇是意大利文艺复兴时期佛罗伦萨共和国的博学者：在绘画、音乐、建筑、数学、几何学、解剖学、生理学、动物学、植物学、天文学、气象学、地质学、地理学、物理学、光学、力学、发明、土木工程等领域都有显著的成就。这使他成为文艺复兴时期人文主义的代表人物，也是历史上最著名的艺术家之一，与米开朗基罗和拉斐尔并称文艺复兴三杰',
   price:'8,000'
 },
 {
   position:[-15.3,3,-28],
   map:'https://res.cloudinary.com/dvr397xda/image/upload/v1657046180/cube/portrait-paintings-Maddow2017_48x60_johayPFN1-802x1024_jvzfty.jpg',
   title:'达芬奇作品',
   des:'列奥纳多·达·芬奇是意大利文艺复兴时期佛罗伦萨共和国的博学者：在绘画、音乐、建筑、数学、几何学、解剖学、生理学、动物学、植物学、天文学、气象学、地质学、地理学、物理学、光学、力学、发明、土木工程等领域都有显著的成就。这使他成为文艺复兴时期人文主义的代表人物，也是历史上最著名的艺术家之一，与米开朗基罗和拉斐尔并称文艺复兴三杰',
   price:'6,000'
 },
 {
  position:[-15.3,3,-9],
  map:'https://res.cloudinary.com/dvr397xda/image/upload/v1657046180/cube/Large_eric-alfaro-oil-painting-dafne-_k19d3m.jpg',
  title:'达芬奇作品',
  des:'列奥纳多·达·芬奇是意大利文艺复兴时期佛罗伦萨共和国的博学者：在绘画、音乐、建筑、数学、几何学、解剖学、生理学、动物学、植物学、天文学、气象学、地质学、地理学、物理学、光学、力学、发明、土木工程等领域都有显著的成就。这使他成为文艺复兴时期人文主义的代表人物，也是历史上最著名的艺术家之一，与米开朗基罗和拉斐尔并称文艺复兴三杰',
  price:'3,500'
},


 

]


export default function Office() {

        const [position,setPosition]= useState([-5, -2, 0])
        const [index,setIndex] = useState(0)
        const [picture,setPicture] = useState(pictures[0])
        const [isOpen,setIsOpen] = useState(false)

        const [isShow,setIsShow] = useState(false)

        const handleTransform = (i)=>{
          console.log(i)
          setPosition(points[i].position)
          setIndex(i)
        }


      

     


       const pods = [
         {
           position:[1.5,0,0]
         },
         {
          position:[1.5,0,16]
        },
        {
          position:[1.5,0,28]
        },
        {
          position:[1.5,0,-16]
        },
        {
          position:[1.5,0,-28]
        },
        {
          position:[-1.5,0,0]
        },
        {
          position:[-1.5,0,16]
        },
        {
          position:[-1.5,0,28]
        },
        {
           position:[-1.5,0,-16]
         },
         {
          position:[-1.5,0,-28]
        },
       ]
      const points =[
        {
          position:[-5,-2,0]
        },
        {
         position:[-5,-2,-16]
       },
       {
         position:[-5,-2,-28]
       },
       {
         position:[-5,-2,16]
       },
       {
         position:[-5,-2,28]
       },
       {
         position:[5,-2,0]
       },
       {
         position:[5,-2,-16]
       },
       {
         position:[5,-2,-28]
       },
       {
          position:[5,-2,16]
        },
        {
         position:[5,-2,28]
       },
      ]


      const cases =[
        {
          position:[9,0,0],
          name:'cube'
        },
        {
          position:[9,0,-16],
          name:''
        },
        {
          position:[9,0,-28],
          name:''
        },
        {
          position:[9,0,16],
          name:''
        },
        {
          position:[9,0,28],
          name:''
        },
        
       
      ]


      

      const handleOpenPic = (i)=>{
          console.log(i)
          setPicture(pictures[i])
          setIsOpen(true)
      }

      const handleClose=()=>{
        setIsOpen(false)
      }

      const handleChoseModel =(i)=>{
       
        if(i===0){
          window.open('/boss/johnny','_self')
        }
        else{
          console.log(i);
          setIsShow(true)
        }
      }
  

      
   
  return (
    <>
    <Canvas    shadows gl={{  antialias: true}} >
       {/* <Canvas dpr={[1, 1.5]} shadows camera={{ position: [0, 3, -5], near: 0.1, far: 60, fov: 30 }}> */}
             

      <PerspectiveCamera makeDefault position={[2, 2, -5]}/>
     
      {/* <OrbitControls  minDistance={2.5} maxPolarAngle={0,Math.PI/2} /> */}

      {/* <fog attach="fog" args={['#eee', 0, 50]} /> */}
      <ambientLight intensity={0.1} />
    <Suspense fallback={<Loader />}>
      <group position={position}>
        <spotLight castShadow intensity={10} angle={0.1} position={[-200, 220, -100]} shadow-mapSize={[2048, 2048]} shadow-bias={-0.000001} />
        <spotLight angle={0.1} position={[-250, 120, -200]} intensity={1} castShadow shadow-mapSize={[50, 50]} shadow-bias={-0.000001} />
        <spotLight angle={0.1} position={[250, 120, 200]} intensity={1} castShadow shadow-mapSize={[50, 50]} shadow-bias={-0.000001} />
        <Museum scale={2}/>
        {/* <Wall position={[-15.5, 5, 0]} rotation={[0, -Math.PI/2,0]}/> */}
        {/* {
          pods&&pods.map((v,i)=>(
            index!==i&&<YBot key={i}  position={v.position} handleTransform={handleTransform} index={i}/>
          ))
        }
        {
          pictures&&pictures.map((v,i)=>(
            <Picture handleOpenPic={handleOpenPic} index={i}  key={i} rotation={[0, Math.PI/2,0]}  position={v.position} map={v.map}/>
          ))
        }

        {
          cases&&cases.map((v,i)=>(
            <ShowCase key={i} position={v.position} handleChoseModel={handleChoseModel} index={i} />
          ))
        } */}
        {/* <Card  position={[9,2,0]}/> */}

        {/* <Bottle position={[9,2,-16]} /> */}
       
     
        
{/* <Lambo   /> */}
{/* <Plane map={map1} position={[6,0,-34]} rotation={[Math.PI/2,0,0]} /> */}

{/* <Porsche /> */}
{/* <Plane map={map1} position={[-6,0,-34]} rotation={[Math.PI/2,0,0]} /> */}
{/* <Porsche2 /> */}
{/* <Plane map={map1} position={[-6,0,34]} rotation={[Math.PI/2,0,0]} /> */}
      </group>
      {/* <FirstPersonControls movementSpeed={1.0} lookSpeed={0.005} /> */}



      </Suspense>
      <Environment files="https://dl.polyhaven.org/file/ph-assets/HDRIs/hdr/1k/canary_wharf_1k.hdr" background />
      <BakeShadows />
      <OrbitControls minDistance={2} maxDistance={3} maxPolarAngle={[Math.PI/4,Math.PI/20]} maxAzimuthAngle={[-Math.PI/2,Math.PI/2]} />
{/* 46    <PointerLockControls/> */}
    </Canvas>

      <div style={{top:isOpen? '30vh':'120vh',transition:'1s'}} className='pop-wrap'>
          <div id='art'>
            <Pop  picture={picture}/>
           
          </div>
          <div id='des'>
            <h2>{picture.title}</h2>
            <h3>起拍价:{picture.price}</h3>
            <button>竞拍</button>
           

            <p>简介:{picture.des}</p>
          
            <img onClick={()=>handleClose()} src="https://res.cloudinary.com/dvr397xda/image/upload/v1656101942/cube/cancel_dmukx0.png" alt="" />
          </div>
        </div>

        <div style={{top:isShow? '30vh':'120vh',transition:'1s'}} className='pop-wrap'>
          <div id='art'>
            <Pop  picture={picture}/>
           
          </div>
          <div id='des'>
            <h2>{picture.title}</h2>
            <h3>起拍价:{picture.price}</h3>
            <button>竞拍</button>
           

            <p>简介:{picture.des}</p>
          
            <img onClick={()=>handleClose()} src="https://res.cloudinary.com/dvr397xda/image/upload/v1656101942/cube/cancel_dmukx0.png" alt="" />
          </div>
        </div>
        
    </>
  )
}

function Loader() {
  const { progress } = useProgress()
  return <Html center> <h2 id='loadText'>
        Loading ...{progress.toFixed(0)}%
        <img id='loadImage' src="https://res.cloudinary.com/dvr397xda/image/upload/v1657312449/cube/output-onlinegiftools_i29wl2.gif" alt="" />
        </h2></Html>
}
function Museum(props) {
  const { scene } = useGLTF('/NFTSHOW_adjust.glb')
  useLayoutEffect(() => {
    scene.traverse((o) => {
      if (o.isMesh) {
        // applyProps(o, { castShadow: true, receiveShadow: true, 'material-envMapIntensity': 0.3 })
       
      }
   
    })
  }, [scene])
  return <primitive  object={scene} {...props} />
}
useGLTF.preload('/NFTSHOW_adjust.glb')


function YBot({scale,position,handleTransform,index}) {
  const ref = useRef()

  const { nodes, materials } = useGLTF('https://res.cloudinary.com/dvr397xda/image/upload/v1656450163/cube/museum/human_udse9g.glb')
 
  
  return (
    <group rotation={[0, index<5 ? Math.PI /2 :-Math.PI /2, 0]} ref={ref} dispose={null} scale={1.2}   position={position} onClick={(e)=>{ handleTransform(index);     e.stopPropagation()
    }} >
      <mesh castShadow receiveShadow geometry={nodes.Alpha_Surface.geometry}>
        <meshStandardMaterial
          
          color={materials.Alpha_Body_MAT.color}
         
          
        />
      </mesh>
      <mesh castShadow geometry={nodes.Alpha_Joints.geometry}>
        <meshStandardMaterial  color={materials.Alpha_Joints_MAT.color} />
      </mesh>
    </group>
  )
}
useGLTF.preload('https://res.cloudinary.com/dvr397xda/image/upload/v1656450163/cube/museum/human_udse9g.glb')


function Picture({position,rotation,map,handleOpenPic,index}){

  
  const mesh = useRef()

    return(
      <Image ref={mesh} onClick={()=>handleOpenPic(index)} scale={[2,4,2]} position={position} rotation={rotation} url={map} />
         
  
    )
}

function Wall({position,rotation,map}){

  
  const mesh = useRef()

    return(
      <mesh ref={mesh} position={position} rotation={rotation} >
         <planeGeometry  attach="geometry"  args={[100,10]}/>
          <meshStandardMaterial    side={THREE.DoubleSide}   />
    </mesh>
    )
}

function ShowCase({position,rotation,handleChoseModel,index}){
  const map = 'https://res.cloudinary.com/dvr397xda/image/upload/v1650920511/cube/%E7%8E%BB%E7%92%832_ukdjz2.jpg'
  const texture = useLoader(THREE.TextureLoader,map)

  return(
    <group position={position} rotation={rotation}>
    <mesh   >
       <boxBufferGeometry  attach="geometry"  args={[2,2,2]}/>
        <meshStandardMaterial     side={THREE.DoubleSide}   />
    </mesh>

    <mesh onClick={(e)=>{ handleChoseModel(index);     e.stopPropagation()
    }}  position={[0,2,0]}>
       <boxBufferGeometry   attach="geometry"  args={[2,2,2]}/>
        <meshStandardMaterial transparent={true} opacity={0.2} map={texture}     side={THREE.DoubleSide}   />
    </mesh>

  </group>
  )
}