import React ,{useRef,useState}from 'react'
import {useFrame,useLoader} from '@react-three/fiber'
import {random} from 'lodash'

import * as THREE from "three";

const randomPosition = ()=>{

    // let x =random(-3, 3, true)
    // let y =random(-3, 3, true)
    // let z =random(-3, 3, true)

    // while(x>-1 && x<1){
    //     x =random(-3, 3, true)
    // }
    // while(y>-1 && y<1){
    //     x =random(-3, 3, true)
    // }
    // while(z>-1 && z<1){
    //     x =random(-3, 3, true)
    // }
    // console.log(x,y,z)
    // return [x,y,z]

    return [random(-3, 3, true), random(-3, 3, true), random(-3, 3, true)]
}

const  Kuang = ()=>{

    const image = 'https://res.cloudinary.com/dvr397xda/image/upload/v1653324529/cube/%E8%BE%B9%E6%A1%862_b3lfk1.png'
    const mesh = useRef()
    const map = useLoader(THREE.TextureLoader,image)
    useFrame((state, delta) => {
      mesh.current.scale.x = 1 + (1 + Math.sin(state.clock.elapsedTime * 3)) * 0.05
      mesh.current.scale.y = 1 + (1 + Math.sin(state.clock.elapsedTime * 3)) * 0.05
      mesh.current.scale.z = 1 + (1 + Math.sin(state.clock.elapsedTime * 3)) * 0.05
    })
    return (
      <>
      <mesh ref={mesh}>
          <planeGeometry  attach="geometry"  args={[ 1.1, 1.1]}/>
          <meshStandardMaterial transparent={true} opacity={1}  attach="material" map={map}  roughness={0.7}
          metalness={0.1} 
          side={THREE.DoubleSide}

          />
      </mesh>
      </>
    )
  }

export default function Cube(props) {
 
    const {people,map} = props
    const [position,setPosition] = useState(randomPosition())
    const mesh = useRef()
    const speed = random(1,10,true)

    useFrame((state, delta) => {
        mesh.current.rotation.y += 0.005*speed
        
        // mesh2.current.rotation.y += 0.005*speed    
    }
    
    
    )
   
    const texture = useLoader(THREE.TextureLoader,map)
    const handleClick = ()=>{
        console.log(people.url)
        // navigate(`/${props.people.id}`)
        window.location=`${people.url}`

    }
 
    return (
        <>
        {map&&<mesh
          {...props}
          ref={mesh}
        position={position}
        onClick={()=>handleClick()}
        rotation={[0, 0, 0]}
          >
              
              
              <planeGeometry attach="geometry" args={[ 1, 1]} />
              <meshStandardMaterial
        attach="material"
        roughness={0.6}
        metalness={0.1}
        side={THREE.DoubleSide}
        map={texture}
        color='white'
      />

<Kuang position={position}         rotation={[0, Math.PI / 5, Math.PI / 5]}
/>
        </mesh>}
    
        {/* <mesh
          {...props}
          ref={mesh2}
        position={position}
        onClick={()=>handleClick()}
          >
              
              
              <boxBufferGeometry attach="geometry" args={[0.001, 0.5, 0.5]} />
              <meshStandardMaterial
        attach="material"
        
        map={map}
      />


        </mesh> */}

        </>
       
 

      
       
      )
  
}
