import React,{useState,useEffect,useRef,Suspense}from 'react'
import { Canvas, useFrame,extend,useThree,useLoader,} from '@react-three/fiber'
import * as THREE from "three";

import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { TextureLoader } from 'three/src/loaders/TextureLoader.js';
import {useParams} from 'react-router-dom'
import fetchSingle from '../../person'
import './index.scss'

const  Cube = ({position})=>{

  const image = 'https://res.cloudinary.com/dvr397xda/image/upload/v1653324529/cube/%E8%BE%B9%E6%A1%862_b3lfk1.png'
  const mesh = useRef()
  const map = useLoader(TextureLoader,image)
  useFrame((state, delta) => {
    mesh.current.scale.x = 1 + (1 + Math.sin(state.clock.elapsedTime * 1)) * 0.03
    mesh.current.scale.y = 1 + (1 + Math.sin(state.clock.elapsedTime * 1)) * 0.03
    mesh.current.scale.z = 1 + (1 + Math.sin(state.clock.elapsedTime * 1)) * 0.03
  })

  return (
    <>
    <mesh position={position} ref={mesh}>
        <boxBufferGeometry  attach="geometry"  args={[2.2, 2.2, 2.2]}/>
        <meshStandardMaterial transparent={true} opacity={1}  attach="material" map={map}  roughness={0.7}
        metalness={0.1} />

       


    </mesh>
    </>
  )
}


function Box(props) {
  const {people,position} = props
  const mesh = useRef()
//   const [index,setIndex] = useState(0)

 

//   useEffect(() => {
//     const interval = setInterval(() => {

//         if(index ===people.show.length-1){
//             setIndex(0)
//         }
//         else{
//             setIndex(index+1)
//         }
//     }, 2500);
//     return () => clearInterval(interval);
//   }, [index]);
//   setInterval(handleChangeIndex,1000)

  let textures;

// textures = useLoader(TextureLoader,[people.images[0],people.images[1],people.images[2],people.images[3],people.images[4],people.show[index]])
textures = useLoader(THREE.TextureLoader,[people.images[0],people.images[1],people.images[2],people.images[3],people.images[4],people.images[5]])

textures.minFilter = textures.magFilter = THREE.LinearFilter;



textures.format= THREE.RGBAFormat;




  function Map (){
    return(
    
      textures.map((texture, idx) =>(
        idx ===2||idx===3?<meshBasicMaterial key={idx} attach={`material-${idx}`} map={texture}  />
        :<meshBasicMaterial  key={idx} attach={`material-${idx}`} map={texture}  />
        )
)
    )
  } 

  return (
    <>
    <mesh
      onClick={(e)=>{
        e.stopPropagation()
      }}
      ref={mesh}
     position={position}
     
      >
          
      <boxBufferGeometry attach="geometry"  args={[2, 2, 2]}/>
        <Map/>
       

    </mesh>


    </>
  )
}

extend({ OrbitControls });

const CameraControls = () => {

  const {
    camera,
    gl: { domElement },
  } = useThree();
  const controls = useRef();
  useFrame(() => controls.current.update());
  return (
    <orbitControls
      ref={controls}
      args={[camera, domElement]}
      autoRotate={false}
      enableZoom={true}
      maxDistance={5.5}
      minDistance={2}
    />
  );
};

const Phone = ({people,opacity,posY})=>{
  const image = 'https://res.cloudinary.com/dvr397xda/image/upload/v1650920511/cube/%E7%8E%BB%E7%92%832_ukdjz2.jpg'
  const mesh1 = useRef()
  const map = useLoader(TextureLoader,image)

  const handleClick=()=>{
    window.open(`tel:+${people.tel}`,'_self')
  }


  return (
    <>
    <mesh position={[1.2,-0.5+posY,0]} onClick={(e)=>{ handleClick();     e.stopPropagation()
}}   ref={mesh1}>
        <boxBufferGeometry  attach="geometry"  args={[0.1,1,2]}/>
        <meshStandardMaterial transparent={true} opacity={opacity} color='blue'  attach="material" map={map}  roughness={0.7}
        metalness={0.1} />

       


    </mesh>
    </>
  )
}

const Cell = ({people,opacity,posY})=>{
    const image = 'https://res.cloudinary.com/dvr397xda/image/upload/v1650920511/cube/%E7%8E%BB%E7%92%832_ukdjz2.jpg'
    const mesh1 = useRef()
    const map = useLoader(TextureLoader,image)
  
    const handleClick=()=>{
      window.open(`tel:+${people.cell}`,'_self')
    }
  
  
    return (
      <>
      <mesh position={[1.2,-1.5+posY,0.5]} onClick={(e)=>{ handleClick();     e.stopPropagation()
  }}   ref={mesh1}>
          <boxBufferGeometry  attach="geometry"  args={[0.1,1,1]}/>
          <meshStandardMaterial transparent={true} opacity={opacity}  attach="material" map={map}  roughness={0.7}
          metalness={0.1} />
  
         
  
  
      </mesh>
      </>
    )
  }

const Email = ({people,opacity,posY})=>{
  const image = 'https://res.cloudinary.com/dvr397xda/image/upload/v1650920511/cube/%E7%8E%BB%E7%92%832_ukdjz2.jpg'
  const mesh1 = useRef()
  const map = useLoader(TextureLoader,image)

  const handleClick=()=>{
    console.log(1);
    window.open(`mailto:+${people.email}`,'_self')
  }

  return (
    <>
    <mesh position={[1.2,-1.5+posY,0.5]} onClick={(e)=>{ handleClick();     e.stopPropagation()
}}   ref={mesh1}>
        <boxBufferGeometry  attach="geometry"  args={[0.1,1,1]}/>
        <meshStandardMaterial transparent={true} opacity={opacity} color='red'  attach="material" map={map}  roughness={0.7}
        metalness={0.1} />

       


    </mesh>
    </>
  )
}

const Map = ({people,opacity,posY})=>{
  
  const mesh1 = useRef()

  const handleClick=()=>{
    console.log(1);
    window.open(`${people.map}`,'_self')
  }

  return (
    <>
    <mesh position={[1.2,-1.5+posY,-0.5]} onClick={(e)=>{ handleClick();     e.stopPropagation()
}}   ref={mesh1}>
        <boxBufferGeometry  attach="geometry"  args={[0.1,1,1]}/>
        <meshStandardMaterial transparent={true} opacity={opacity}  attach="material" color='green'  roughness={0.7}
        metalness={0.1} />

       


    </mesh>
    </>
  )
}

const Youtube = ({people,opacity,posY})=>{
    
    const mesh1 = useRef()
  
    const handleClick=()=>{
      console.log(1);
      window.open(`${people.video}`,'_self')
    }
  
    return (
      <>
      <mesh position={[-1.2,-0.5+posY,0.5]} onClick={(e)=>{ handleClick();     e.stopPropagation()
  }}   ref={mesh1}>
          <boxBufferGeometry  attach="geometry"  args={[0.1,1,1]}/>
          <meshStandardMaterial transparent={true} opacity={opacity}  attach="material" color='green'  roughness={0.7}
          metalness={0.1} />
  
         
  
  
      </mesh>
      </>
    )
  }

  const Facebook = ({people,opacity,posY})=>{
    
    const mesh1 = useRef()
  
    const handleClick=()=>{
      console.log(1);
      window.open(`${people.facebook}`,'_self')
    }
  
    return (
      <>
      <mesh position={[-1.2,-0.5+posY,-0.5]} onClick={(e)=>{ handleClick();     e.stopPropagation()
  }}   ref={mesh1}>
          <boxBufferGeometry  attach="geometry"  args={[0.1,1,1]}/>
          <meshStandardMaterial transparent={true} opacity={opacity}  attach="material" color='green'  roughness={0.7}
          metalness={0.1} />
  
         
  
  
      </mesh>
      </>
    )
  }

  const Linkedin = ({people,opacity,posY})=>{
  
    const mesh1 = useRef()
  
    const handleClick=()=>{
      console.log(1);
      window.open(`${people.linkedin}`,'_self')
    }
  
    return (
      <>
      <mesh rotation={[0,0,Math.PI * 2.5]} position={[0.5,0.2+posY,0.5]} onClick={(e)=>{ handleClick();     e.stopPropagation()
  }}   ref={mesh1}>
          <boxBufferGeometry  attach="geometry"  args={[0.1,1,1]}/>
          <meshStandardMaterial transparent={true} opacity={opacity}  attach="material" color='green'  roughness={0.7}
          metalness={0.1} />
      </mesh>
      </>
    )
  }

  const Douyin = ({people,opacity,posY})=>{
   
    const mesh1 = useRef()
  
    const handleClick=()=>{
      console.log(1);
      window.open(`${people.douyin}`,'_self')
    }
  
    return (
      <>
      <mesh rotation={[0,0,Math.PI * 2.5]} position={[-0.5,0.2+posY,0.5]} onClick={(e)=>{ handleClick();     e.stopPropagation()
  }}   ref={mesh1}>
          <boxBufferGeometry  attach="geometry"  args={[0.1,1,1]}/>
          <meshStandardMaterial transparent={true} opacity={opacity}  attach="material" color='red'  roughness={0.7}
          metalness={0.1} />
      </mesh>
      </>
    )
  }


const Web = ({people,opacity,posY})=>{
 
  const mesh1 = useRef()

  const handleClick=()=>{
    window.open(`${people.website}`,'_self')
  }

  return (
    <>
    <mesh rotation={[0, 0, 0]} position={[-1.2,-1.5+posY,0.5]} onClick={(e)=>{ handleClick();     e.stopPropagation()
}}   ref={mesh1}>
        <boxBufferGeometry  attach="geometry"  args={[0.1,1,1]}/>
        <meshStandardMaterial transparent={true} opacity={opacity}  attach="material" color='red'  roughness={0.7}
        metalness={0.1} />

       


    </mesh>
    </>
  )
}






export default function Show() {

  const{ name } = useParams();
  const [people,setPeople] = useState({})

  const opacity=0

  const posY = 1

  useEffect(() => {
    const data = fetchSingle()

    const result = data.find(e=>e.id===name)
    setPeople(result)
    
  },[name])


  return (
    <div className="container-card" style={{backgroundImage:`url(${people.gif})`}}  >
     
     <Canvas  gl={{ alpha: true, antialias: true}}  className='canvas-wrap'>
    <CameraControls />
    {/* <Env background={background}/> */}
    {/* <Environment files={background} /> */}
      <ambientLight intensity={1}  position={[0,0,0]}/>
      {/* <pointLight  position={[0,0,0]} /> */}
      <Suspense fallback={null}>
      {/* <PresentationControls
       global
      > */}

        
      <Box people={people}   position={[0, 0, 0]} />
      

      <Cube position={[0, 0, 0]} />

      <Phone posY={posY} people={people} opacity={opacity}/>
      <Email posY={posY} people={people} opacity={opacity}/>
      {people.cell&&<Cell posY={posY} people={people} opacity={opacity}/>}

      {people.facebook&&<Facebook posY={posY} people={people} opacity={opacity}/>}
      {people.video&&<Youtube posY={posY} people={people} opacity={opacity}/>}
      {people.map&&<Map posY={posY} people={people} opacity={opacity}/>}
      {people.website&&<Web posY={posY} people={people} opacity={opacity}/>}
      {/* <Video isPlay={isPlay} setIsPlay={setIsPlay} position={[0, 0, 0]}  people={people}/> */}
      {people.linkedin&&<Linkedin posY={posY} people={people} opacity={opacity}/>}
      {people.douyin&&<Douyin posY={posY} people={people} opacity={opacity}/>}

    
        {/* </PresentationControls> */}

    
      </Suspense>

    </Canvas>
      
      {/* <div className="container-player">
        <Player/>
      </div> */}
      {/* <div>
        <video id='video' src={people.video}/>
      </div> */}
          <video playsInline loop   autoPlay id="video"/>

    </div>
  )
}
