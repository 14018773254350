import React,{useState,useEffect,useCallback}from 'react'
import axios from 'axios'
import {Link } from 'react-router-dom'

import './index.scss'

export default function AuthPlayer() {

    const apiUrl ='https://metaserverapp.com'

   

    const [cards,setCards]=useState([])
   
  

 
 

    const [page,setPage] = useState(1)
    const [total,setTotal]=useState(0)
    const [totalPage,setTotalPage] = useState(0)


  

    useEffect(()=>{

        const fetchNews = async()=>{
            const result = await axios.get(`${apiUrl}/player/`,{params:{page:1}})
            console.log(result)
            if(result.data.success){
        
                setCards(result.data.data[0].result)
      
              
            
              const temp = result.data.data[0].total[0].total
              setTotal(result.data.data[0].total[0].total)
              const temp2 = Math.ceil(temp/15)
              setTotalPage(temp2)
           
      
            }
            else{
              alert('Server Error! No data response received!')
            }
        }
        fetchNews()

    },[])

    useEffect(()=>{

        const fetchNews = async()=>{
            const result = await axios.get(`${apiUrl}/player/`,{params:{page:page}})
            console.log(result)
            if(result.data.success){
        
                setCards(result.data.data[0].result)
              const temp = result.data.data[0].total[0].total
              setTotal(result.data.data[0].total[0].total)
              const temp2 = Math.ceil(temp/15)
              setTotalPage(temp2)
           
            }
            else{
              alert('Server Error! No data response received!')
            }
        }
        fetchNews()

    },[page])

   


   

    
   
   

    const handleNextPage = ()=>{
        setPage(page+1)
        const wrap =document.getElementsByClassName('auth-posts-form')[0]
        wrap.scrollTop = 0
      }
      const handleLastPage = ()=>{
        setPage(page-1)
        const wrap =document.getElementsByClassName('auth-posts-form')[0]
        wrap.scrollTop = 0
    
      }

   
  return (
    <div className='auth-post-wrap'>
        <h2>参赛选手页</h2>
        
        <div className='auth-post-btns'>
            <Link to='/auth'> <button >返回</button></Link> 
           
        </div>

        <div className='auth-posts-form'>
        <table>
            <thead>
                <tr>
                    <th>
                        名字
                    </th>
                    <th>电话</th>
                    <th>
                        邮箱
                    </th>
                   
                    <th>
                        链接
                    </th>
                    <th>
                        自我介绍
                    </th>
                    <th>
                        日期
                    </th>
                   
                </tr>
            </thead>
            <tbody>
                {
                    cards.length>0&&cards.map((v,i)=>(
                        <tr key={i}>

                            <td>{v.name}</td>
                           
                            <td>{v.phone}</td>
                            <td>{v.email}</td>
                            <td>{v.url}</td>
                            <td>{v.content}</td>
                            <td>{v.date}</td>
                               
                        </tr>
                    )

                    )
                }
            </tbody>


        </table>


        </div>

        <div className='pagenation-wrap'>

<div> {page!==1&&<p onClick={()=>handleLastPage()}>上一页</p>}</div>

<div> <p>第{page}页</p> </div>

<div> {page !== totalPage&&<p onClick={()=>handleNextPage()}>下一页</p>}</div>

<div><p>共{totalPage}页</p></div>
<div>
<p>共{total}个结果</p>
</div>
</div>
     

     
  
        
        
    </div>
  )
}

