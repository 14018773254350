import React,{useState,useEffect} from 'react'
import { useNavigate } from "react-router-dom";

import axios from 'axios'
import './index.scss'



const apiUrl ='https://metaserverapp.com'


export default function Login({setAdminToken}) {
  const [account,setAccount] = useState('')
  const [password,setPassword] = useState('')
  const navigate = useNavigate();

  const handleAccount=(e)=>{
    setAccount(e.target.value)
  }

  const handlePassword=(e)=>{
    setPassword(e.target.value)
  }

  const handleSubmit=async()=>{
    if(!password){
      alert('Please enter a password')
    }
     if(!account){
      alert('Please enter an account')
    }

    const result = await axios.get(`${apiUrl}/auth/admin`,{params:{phone:account,password:password}})
    console.log(result.data);
    if(result.data.success){
      console.log('success');
      const {AdminToken,admin} = result.data
      window.localStorage.setItem('adminToken',AdminToken)
      window.localStorage.setItem('adminId',admin._id)

      navigate("/auth");


    }
    else{
      alert('Login Failed')
    }
    
  }



  return (
    <div className='auth-login-container'>
        <div className='auth-login-wrap'>
        <h2>管理员登录</h2>

          <div>
            <label >账户*</label>
            <input require='true' onChange={(e)=>handleAccount(e)} value={account} type="text" />
          </div>

          <div>
            <label >密码*</label>
            <input require='true' onChange={(e)=>handlePassword(e)} value={password} type="text" />
          </div>

          <button onClick={()=>handleSubmit()}>Login</button>

        </div>

    </div>
  )
}
