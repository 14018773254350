import React,{useState} from 'react'
import './index.scss'


const data = [
    {
      album:'周杰伦',
      name:'简单爱',
      track:'Jay', 
      url:'https://res.cloudinary.com/dvr397xda/video/upload/v1654551368/cube/music/jay/jiandanai_ndrdbk.mp3', 
      image:'https://res.cloudinary.com/dvr397xda/image/upload/v1654551483/cube/music/jay/51GtKSIkTBL._SY355__tluinj.jpg'
    },
    {
        album:'周杰伦',
        track:'Fantasy', 
        name:'爱在西元前',
        url:'https://res.cloudinary.com/dvr397xda/video/upload/v1654551712/cube/music/jay/%E7%88%B1%E5%9C%A8%E8%A5%BF%E5%85%83%E5%89%8D_sry6a0.mp3', 
        image:'https://res.cloudinary.com/dvr397xda/image/upload/v1654551483/cube/music/jay/%E4%B8%8B%E8%BD%BD_1_e2jq2i.jpg'
      },
      {
        album:'周杰伦',
        track:'七里香', 
        name:'七里香',
        url:'https://res.cloudinary.com/dvr397xda/video/upload/v1654551606/cube/music/jay/%E4%B8%83%E9%87%8C%E9%A6%99_qdy8xs.mp3', 
        image:'https://res.cloudinary.com/dvr397xda/image/upload/v1654551483/cube/music/jay/Jay_Chou-Common_Jasmin_Orange_2004_Cover_lebigm.jpg'
      },

]


export default function Player({data}) {

    

    const playImg = 'http://physical-authority.surge.sh/imgs/icon/play.svg'

    const pauseImg = 'http://physical-authority.surge.sh/imgs/icon/pause.svg'


    const [isPlay,setIsPlay] = useState(false)
    const [count,setCount] = useState(0)
    const [playIcon,setPlayIcon] = useState(playImg)



  

    const handlePlay = ()=>{
        const temp = document.querySelectorAll('.audio')
        const song = temp[count]
        // calculateTime(parseInt(song.duration)) 
        setIsPlay(!isPlay)

        // song.addEventListener("timeupdate" , ()=>progresUpdate(count));
        if(isPlay){
            song.pause();
            setPlayIcon(playImg)
        }
        else{
            song.play();
            // progresUpdate(count)
            setPlayIcon(pauseImg)
        }
        setIsPlay(!isPlay)
    }

    const handleNextSong = ()=>{
        setIsPlay(true)
        setPlayIcon(pauseImg)

        if (count === data.length-1) {
            setCount(0)
            const temp = document.querySelectorAll('.audio')
        const song = temp[0]
        const last = temp[count]
        last.pause()
        // calculateTime(parseInt(song.duration)) 
        // progresUpdate(0)
        song.play();
        }
     
        else{
            setCount(count + 1)
            const temp = document.querySelectorAll('.audio')
            const song = temp[count + 1]
            const last = temp[count]
            last.pause()
            // calculateTime(parseInt(song.duration)) 
            // progresUpdate(count + 1)
            song.play();
        }
    }

    const handleBackSong = ()=>{
        setIsPlay(true)
        setPlayIcon(pauseImg)

        if (count === 0) {
            setCount(data.length-1)
            const temp = document.querySelectorAll('.audio')
            const song = temp[data.length-1]
            // calculateTime(parseInt(song.duration)) 
            const last = temp[count]
            last.pause()
            song.play();

        }
        else{
            setCount(count - 1)
            const temp = document.querySelectorAll('.audio')
            const song = temp[count - 1]
            // calculateTime(parseInt(song.duration)) 
            const last = temp[count]
            last.pause()
            song.play();
        }
    }

  return (
    
<div className="player">
    <div className="player-wrap">
    {/* <button  className="player__button back">

    <img onClick={()=>handleBackSong()}  className="img" src="http://physical-authority.surge.sh/imgs/icon/back.svg" alt="back-icon"/>

    </button> */}
    <div className="player__img player__img--absolute slider">

    <button className="player__button player__button--absolute--nw playlist">

      <img src="http://physical-authority.surge.sh/imgs/icon/playlist.svg" alt="playlist-icon"/>

    </button>

    <button onClick={()=>handlePlay()}  className="player__button player__button--absolute--center play">

      <img src={playIcon} alt="play-icon"/>
     

    </button>

                <div  className="slider__content">

               
                <img  className={isPlay ? "img slider__img__rotation":"img slider__img"} src={data[count].image} alt="cover" />
                {/* <audio className='audio' src={data[count].url}/> */}
                {
                    data&&data.map((v,i)=>(
                        <audio  key={i} className='audio' src={v.url}/>

                    ))
                }
                </div>
               
  </div>
  {/* <button className="player__button next">

<img onClick={()=>handleNextSong()}  className="img" src="http://physical-authority.surge.sh/imgs/icon/next.svg" alt="next-icon"/>

</button> */}
    </div>
    {/* <div id="player-title">

{songTime&&<p className="player__song-time">{songTime}</p>}
    </div> */}   
    {/* <div className="progres">

      <div className="progres__filled"></div>
      <div className='des-player'>
      <p className="player__song-name">{data[count].name}</p>

<p className="player__title">{data[count].track}</p>
      </div>
  
    </div> */}
</div>

  )
}
