import React,{useState,useEffect} from 'react'
import './index.scss'
import styled from "styled-components";
import {

  Book,
  Person,
  Newspaper,
  ShopWindow,
  Tv,
  List,
  ChevronBarLeft,
  ChatSquareText
} from "react-bootstrap-icons";
import { NavLink } from "react-router-dom";

export default function Footer() {

    const [width,setWidth] = useState(40)
    const [isMenu,setIsMenu] = useState(false)
    const outlineColor = "#222327";
    const borderThickness = "2px";
    useEffect(() => {
        const windowWidth = window.innerWidth;
        console.log(windowWidth)
        if(windowWidth >450){
            setWidth(50)
        }
        else{
            const temp = window.innerWidth
            console.log(temp);
            const temp2 = temp/7
            setWidth(temp2)
        }
    },[])

    const handleMenu = ()=>{
      setIsMenu(!isMenu)
    }

    const StyledNav = styled.nav`
      position: relative;
      padding: 0px 10px;
      height: ${width}px;
      background-color: #fff;
      display: flex;
      align-items: center;
      border-radius: 10px;
      border: ${borderThickness} solid ${outlineColor};
      box-sizing: border-box;
      box-shadow: 0px 4px 7px 2px rgb(0 0 0 / 25%);
      display: inline-flex;
      align-content: center;
      flex-direction: row;
      flex-wrap: nowrap;
      margin: auto;
      & * {
        box-sizing: border-box;
      }
      & a {
        width: ${width}px;
        height: 100%;
        position: relative;
        display: flex;
        justify-content: center;
        align-content: center;
        flex-direction: column;
        text-align: center;
        font-weight: 500;
        z-index: 1;
      }
      & a svg {
        position: relative;
        display: block;
        line-height: 35px;
        font-size: 1.8em;
        text-align: center;
        transition: 0.5s;
        color: ${outlineColor};
        margin: auto;
        transform: translateY(-10px);
        fill: black;
        position: absolute;
        height: ${width/1.7}px;
        width: ${width}px;
        background: rgba(255, 255, 255,0);
        border-radius: 50%;
        // border: ${borderThickness} solid ${outlineColor};
      }
      & a.active svg {
        transform: translateY(-10px);
        fill: white;
        background: rgba(255, 255, 255,0);

      }
      & b {
        position: absolute;
        color: ${outlineColor};
        font-weight: bold;
        font-size: 0.55em;
        transition: 0.5s;
        opacity: 0;
        transform: translateY(15px);
        width: 100%;
        opacity: 1;

      }
      & a.active b {
        opacity: 1;
        /* transform: translateY(10px); */
      }
      & a:not(.active):hover svg {
        transform: scale(1.5);
      }
      & .indicator {
        position: absolute;
        height: ${width/1.5}px;
        width: ${width}px;
        background: #e66740;
        top: -5%;
        border-radius: 50%;
        transition: 0.4s;
        border: ${borderThickness} solid ${outlineColor};
      }
      & .indicator::before {
        content: "";
        position: absolute;
        top: 54%;
        left: -5px;
        width: 6px;
        height: 7px;
        background-color: transparent;
        border-top-right-radius: 28px;
        box-shadow: 0px -2px 0 0 ${outlineColor};
      }
      & .indicator::after {
        content: "";
        position: absolute;
        top: 54%;
        right: -5px;
        width: 6px;
        height: 7px;
        background-color: transparent;
        border-top-left-radius: 28px;
        box-shadow: 0px -2px 0 0 ${outlineColor};
      }
      a:nth-child(1).active ~ .indicator {
        transform: translateX(calc(${width}px*0));
      }
      a:nth-child(2).active ~ .indicator {
        transform: translateX(calc(${width}px*1));
      }
      a:nth-child(3).active ~ .indicator {
        transform: translateX(calc(${width}px*2));
      }
      a:nth-child(4).active ~ .indicator {
        transform: translateX(calc(${width}px*3));
      }
      a:nth-child(5).active ~ .indicator {
        transform: translateX(calc(${width}px*4));
      }
      a:nth-child(6).active ~ .indicator {
        transform: translateX(calc(${width}px*5));
      }
      a:nth-child(7).active ~ .indicator {
        transform: translateX(calc(${width}px*6));
      }
    `;
  return (
    <>
    <div style={{position:'absolute',bottom:'15vh',scale:isMenu? '1' :'0',transition:'1.2s'}} className='footer-wrap'><StyledNav>
    <NavLink to={"/"}>
      <  Book
 />
      <b>新闻</b>
    </NavLink>
    <NavLink to={"/post"}>
      <  ChatSquareText
 />
      <b>帖子</b>
    </NavLink>
   
    <NavLink to={"/coupon"}>
      <      ShopWindow
 />
      <b>商城</b>
    </NavLink>
    <NavLink to={"/entertainment"}>
      <  Tv
 />
      <b>电视台</b>
    </NavLink>
    <NavLink to={"/user/dashboard"}>
      <  Person
/>
      <b>用户</b>
    </NavLink>
    <div className="indicator"></div>
  </StyledNav>
  
  </div>
  <button onClick={()=>handleMenu()} id='footer-menu'>
      {
        isMenu ? < ChevronBarLeft/>
        :
        <List/>
      }
    </button>
  </>
  )
}
