import React,{useRef} from 'react'
import { useFrame } from '@react-three/fiber'

import Cube from '../Cube/index'
import './index.scss'



export default function Cubes() {
  const data=[
    {   
        id:'david',
        url:'/companys/david',
        name:'David Jiang',
        avatar:'https://res.cloudinary.com/dvr397xda/image/upload/v1657645206/cube/WechatIMG8972_vixopm.png',
    },
    { 
      id:'lily-zou',
      name:'Lily Zou',
      url:'/basic/lily-zou',
      avatar:'https://res.cloudinary.com/dvr397xda/image/upload/v1659111892/cube/basic/lily/WechatIMG9519_rxbycm.png',
  },
  { 
    id:'wingo',
    url:'/basic/wingo',
avatar:'https://res.cloudinary.com/dvr397xda/image/upload/v1659471006/cube/basic/wingo/WechatIMG9659_lvmp8b.png',
},
{ 
  id:'johnny',
  url:'/basic/johnny',
  avatar:'https://res.cloudinary.com/dvr397xda/image/upload/v1659548115/cube/WechatIMG9669_iqddcs.png',
},
{ 
  id:'star-chen',
  url:'/basic2/star-chen',
  avatar:'https://res.cloudinary.com/dvr397xda/image/upload/v1659132272/cube/basic/yuxin/WechatIMG9537_maonje.png',
},
{ 
  id:'groupchop',
  url:'/johnny/groupchop',
  avatar:'https://res.cloudinary.com/dvr397xda/image/upload/v1658522322/cube/%E4%B8%80%E8%B5%B7%E5%88%80358e21_cr2mtd.png',
},
{ 
  id:'huoli',
  url:'/johnny/huoli',
  avatar:'https://res.cloudinary.com/dvr397xda/image/upload/v1658522322/cube/%E6%B4%BB%E5%8A%9B8e7321_u84bpm.png',
},
{ 
  id:'meta',
  url:'/johnny/meta',
  avatar:'https://res.cloudinary.com/dvr397xda/image/upload/v1658522322/cube/%E5%85%83%E5%AE%87%E5%AE%99%E5%90%8D%E7%89%870072ff_xwc9v3.png',
},
{ 
  id:'nicrg',
  url:'/johnny/nicrg',
  avatar:'https://res.cloudinary.com/dvr397xda/image/upload/v1658531041/cube/WechatIMG9355_vxjodt.png',
},
{ 
  id:'weiiz',
  url:'/johnny/weiiz',
  avatar:'https://res.cloudinary.com/dvr397xda/image/upload/v1658531780/cube/WechatIMG9358_wcptqy.png',
},
{ 
  id:'world',
  url:'/johnny/world',
  avatar:'https://res.cloudinary.com/dvr397xda/image/upload/v1658522322/cube/%E5%BE%AE%E4%B8%96%E7%95%8Ca7a7a7_ck75jp.png',
},
{ 
  id:'xintian',
  url:'/johnny/xintian',
  avatar:'https://res.cloudinary.com/dvr397xda/image/upload/v1658599200/cube/WechatIMG9367_vxqtwh.png',
},
{ 
  id:'boss',
  url:'/boss/johnny',
  avatar:'https://res.cloudinary.com/dvr397xda/image/upload/v1659557107/cube/boss_ktttz5.png',
},
{ 
  id:'watch',
  url:'/case',
  avatar:'https://res.cloudinary.com/dvr397xda/image/upload/v1659557246/cube/watch_d1u2sr.png',
},
{ 
  id:'lambo',
  url:'/car/lambo',
  avatar:'https://res.cloudinary.com/dvr397xda/image/upload/v1659557443/cube/lambo_iieqc1.png',
},

{ 
  id:'porche',
  url:'/car/porche',
  avatar:'https://res.cloudinary.com/dvr397xda/image/upload/v1659557442/cube/porche_uqhtq8.png',
},
{ 
  id:'porche',
  url:'/car/porche1',
  website2:'https://xiaopodun.com/',
  avatar:'https://res.cloudinary.com/dvr397xda/image/upload/v1659557444/cube/porche1_bmnygw.png',
},
{   
  id:'yanxiong',
  url:'/profile/yanxiong',
  avatar:'https://res.cloudinary.com/dvr397xda/image/upload/v1653337703/cube/%E8%A8%80png_smtizs.png',
},
{   
  id:'massage-palace',
  url:'/basic3/massage-palace',
  avatar:'https://res.cloudinary.com/dvr397xda/image/upload/v1659449114/cube/basic/massage%20palace/WechatIMG9644_e5fy4r.png',
},
{   
  id:'philip',
  url:'/person/philip',
  avatar:'https://res.cloudinary.com/dvr397xda/image/upload/v1655308640/cube/WechatIMG8423_dxymep.jpg',
},
{
  id:'michael-lin',
  avatar:'https://res.cloudinary.com/dvr397xda/image/upload/v1659644318/cube/hunqing/WechatIMG9691_fkyu0h.png',
  url:'/pro/michael-lin',
},
{
  id:'happytown',
  avatar:'https://res.cloudinary.com/dvr397xda/image/upload/v1659649112/cube/Happy%20Town/WechatIMG9663_odwa1y.png',
  url:'/restaurant/happytown',
},
{
  name:'business-card',
  avatar:'https://res.cloudinary.com/dvr397xda/image/upload/v1660075871/cube/business%20card/WechatIMG9781_isnmra.jpg',
  url:'/business/lily-zou',
},
{
name:'business-card',
avatar:'https://res.cloudinary.com/dvr397xda/image/upload/v1661288152/cube/basic/ben/WechatIMG10131_limsj0.png',
url:'/basic/ben',
},
{
name:'business-card',
avatar:'https://res.cloudinary.com/dvr397xda/image/upload/v1661461585/cube/hunqing/WechatIMG10184_szrmxw.png',
url:'/pro/lily-zou',
},
{
name:'business-card',
avatar:'https://res.cloudinary.com/dvr397xda/image/upload/v1661537165/cube/wanghong/WechatIMG10263_ki1rbe.png',
url:'/poster/tj',
},
{
name:'happytown',
avatar:'https://res.cloudinary.com/dvr397xda/image/upload/v1661980476/cube/kinha%20sushi/WechatIMG10334_o4jmvf.png',
url:'/restaurant/kinhasushi',
},
   
]



    const group = useRef()

    useFrame((state,delta)=>(group.current.rotation.y+=0.001))

  return (
  
  
     

      <group ref={group}>

     
        {data&&data.map((v,i)=>(
            <Cube key={i} map={v.avatar} people={v} />
        ))}

        </group>
    
 
   
       

  )
}
