import React,{useState,useEffect} from 'react'
import Select from 'react-select'
import {Link} from 'react-router-dom'
import axios from 'axios'
import './index.scss'

export default function Create() {

    const initial = {
        id:'',
        name:'',
        tel:'',
        email:'',
        address:'',
        map:'',
        video:'',
        website:'',
        images:['','','','','',''],
        gif:'',
        loadImg:'',
        qrCode:'',
        model:'',
        living:'',
        isMusic:false,
        music:[{
          album:'周杰伦',
          name:'简单爱',
          track:'Jay', 
          url:'', 
          image:'https://res.cloudinary.com/dvr397xda/image/upload/v1660938134/music/cd-player_1_nxixox.png'
      }],
      lng:'',
      lat:'',
      location:'',
      category:'',
      title:'',
      color:'',
      isGallery:'0',
      gallery:[],
      webCode:'',
      psCode:'',
      country:''
    }
    const isGallerys = [
        { value: '1', label: '1' },
        { value: '2', label: '2' },
        { value: '3', label: '3' },
        { value: '4', label: '4' },
        { value: '5', label: '5' },
        { value: '6', label: '6' },
        { value: '7', label: '7' },
        { value: '8', label: '8' },
        { value: '9', label: '9' },
       
    ]
    
      const [isCreate,setIsCreate]= useState(false)
      const [value,setValue]=useState(initial)
      const [gallerys,setGallerys] = useState([])
    
    
      const handleCreate=()=>{
        setValue(initial)
        setIsCreate(!isCreate)
      }

    const options =[
        { value: '地球', label: '地球' },
        { value: '玫瑰花', label: '玫瑰花' },
        { value: '电脑', label: '电脑' },
        { value: '摄像机', label: '摄像机' },
        { value: '相机', label: '相机' },
        { value: '房子', label: '房子' },
        { value: '日式', label: '日式' },
      
        { value: '钥匙', label: '钥匙' },
      ]
    const locations =[
        {
            value:'曼哈顿',
            label:'曼哈顿',
        },
        {
            value:'布鲁克林',
            label:'布鲁克林',
        },
        {
            value:'法拉盛',
            label:'法拉盛',
        },
        {
            value:'Elmhurst',
            label:'Elmhurst',
        },
        {
            value:'Staten Island',
            label:'Staten Island',
        },
        {
            value:'Long Island City',
            label:'Long Island City',
        },
        {
            value:'Long Island',
            label:'Long Island',
        },
       {
            value:'其它地区',
            label:'其它地区',

        }
    ]

    const categorys = [
        {
            value:'餐饮',
            label:'餐饮',
        },
        {
            value:'地产经纪',
            label:'地产经纪',
        },
        {
            value:'律师',
            label:'律师',
        },
        {
            value:'汽车买卖',
            label:'汽车买卖',
        },
        {
            value:'保险',
            label:'保险',
        },
        {
            value:'贷款',
            label:'贷款',
        },
        {
            value:'会计',
            label:'会计',
        },
        {
            value:'搬家',
            label:'搬家',
        },
        {
            value:'旅行',
            label:'旅行',
        },
        {
            value:'驾校',
            label:'驾校',
        },
        {
            value:'装修',
            label:'装修',
            

        },
        {
            value:'医生',
            label:'医生',
        },
        {
            value:'美容',
            label:'美容',
        },
        {
            value:'按摩',
            label:'按摩',
        },
        {
            value:'美甲',
            label:'美甲',
        },
        {
            value:'移民',
            label:'移民',
        },
        {
            value:'印刷',
            label:'印刷',
        },
        {
            value:'婚庆',
            label:'婚庆',
        },
        {
            value:'摄影',
            label:'摄影',
        },
        {
            value:'设计',
            label:'设计',
        },
        {
            value:'物流',
            label:'物流',
        },
        {
            value:'其它',
            label:'其它',
        },
    ]

    const countrys = [
        { value: 'USA', label: 'USA' },
        { value: 'CN', label: 'CN' },

    ]

  

    const colors = [
        { value: 'white', label: '白色' },
        { value: 'black', label: '黑色' },
        { value: '#00249a', label: '蓝色' },
        { value: '#00ff42', label: '绿色' },
        { value: '#ba00ff', label: '紫色' },
    ]

  

     const handleModel=(e)=>{
         console.log(e);

         let temp ={...value}
         temp.model=e.value
         setValue(temp)
     }

     const handleColor=(e)=>{
        console.log(e);

        let temp ={...value}
        temp.color=e.value
        setValue(temp)
    }

     const handleLocation = (e)=>{
        console.log(e);

        let temp ={...value}
        temp.location=e.value
        setValue(temp)
     }

     const handleCategory = (e)=>{
        console.log(e);

        let temp ={...value}
        temp.category=e.value
        setValue(temp)
     }

     const handleCountry = (e)=>{
        console.log(e);

        let temp ={...value}
        temp.country=e.value
        setValue(temp)
     }

     const handleId=(e)=>{ 
         let temp ={...value}
         temp.id = e.target.value
         setValue(temp)
     }

     const handleName=(e)=>{ 
        let temp ={...value}
        temp.name = e.target.value
        setValue(temp)
     }

     const handleTel =(e)=>{
        let temp ={...value}
        temp.tel = e.target.value
        setValue(temp)
     }

     const handleEmail =(e)=>{
        let temp ={...value}
        temp.email = e.target.value
        setValue(temp)
     }

     const handleAddressLink =(e)=>{
        let temp ={...value}
        temp.map = e.target.value
        setValue(temp)
     }

     const handleWebsite =(e)=>{
        let temp ={...value}
        temp.website = e.target.value
        setValue(temp)
     }

     const handleFront = (e)=>{
        let temp ={...value}
        temp.images[4] = e.target.value
        setValue(temp)
     }
     const handleBack = (e)=>{
        let temp ={...value}
        temp.images[5] = e.target.value
        setValue(temp)
     }
     const handleTop = (e)=>{
        let temp ={...value}
        temp.images[2] = e.target.value
        setValue(temp)
     }
     const handleBottom = (e)=>{
        let temp ={...value}
        temp.images[3] = e.target.value
        setValue(temp)
     }
     const handleRight = (e)=>{
        let temp ={...value}
        temp.images[0] = e.target.value
        setValue(temp)
     }
     const handleLeft = (e)=>{
        let temp ={...value}
        temp.images[1] = e.target.value
        setValue(temp)
     }

     const handleAddress = (e)=>{
        let temp ={...value}
        temp.address = e.target.value
        setValue(temp)
     }

     const handleBackLink = (e)=>{
        let temp ={...value}
        temp.video = e.target.value
        setValue(temp)
     }

     const handleBackground = (e)=>{
        let temp ={...value}
        temp.gif = e.target.value
        setValue(temp)
     }

     const handleLoadImg = (e)=>{
        let temp ={...value}
        temp.loadImg = e.target.value
        setValue(temp)
     }

     const handleWechat = (e)=>{
        let temp ={...value}
        temp.qrCode = e.target.value
        setValue(temp)
     }

     const handleLeftSideLink=(e)=>{
        let temp ={...value}
        temp.living = e.target.value
        setValue(temp)
     }

     const handleLng = (e)=>{
        let temp ={...value}
        temp.lng = e.target.value
        setValue(temp)
     }

     const handleLat =(e)=>{
        let temp ={...value}
        temp.lat = e.target.value
        setValue(temp)
     }

     const handleTitle =(e)=>{
        let temp ={...value}
        temp.title = e.target.value
        setValue(temp)
     }

     const handleMusic = (e)=>{
        let temp ={...value}
        
        if(e.target.value){
            temp.isMusic=true
            temp.music[0].url = e.target.value
            setValue(temp)
        }
     }

     const handleGallery =(e)=>{
        console.log(e);

        let temp ={...value}
        temp.isGallery=e.value
        let arr = [];
        if(e.value){
            for(let i=0;i<e.value;i++){
                arr.push('')
            }
            setGallerys(arr)
        }
        temp.gallery = arr
        setValue(temp)
     }

     const handleChangeGallery = (e,i)=>{
        console.log(e);

        let temp ={...value}
        temp.gallery[i]=e.target.value
        setValue(temp)

     }

     const apiUrl ='https://metaserverapp.com'


     const handleSubmit = async()=>{

        const data = await axios.post(`${apiUrl}/basic/`,{
            id:value.id,
            name:value.name,
            tel:value.tel,
            email:value.email,
            address:value.address,
            map:value.map,
            video:value.video,
            website:value.website,
            images:value.images,
            gif:value.gif,
            loadImg:value.loadImg,
            qrCode:value.qrCode,
            model:value.model,
            living:value.living,
            isMusic:value.isMusic,
            music:value.music,
            location:value.location,
            category:value.category,
            lng:value.lng,
            lat:value.lat,
            title:value.title,
            color:value.color,
            isGallery:value.isGallery,
            gallery:value.gallery,
            webCode:value.webCode,
            psCode:'',
            country:value.country,
        })

        console.log(data);
        if(data.data.success){
            setValue(initial)
            alert("创建成功！")
        }
        else{
            alert(data.data.errors)
        }

     }

    
console.log(value)

  return (
    <div className="create-item">

        <div className='a-tag'>
        <h2>创建名片</h2>
        <Link  to='/auth'>
            <h3>
            返回管理首页

            </h3>
        </Link>
        
        </div>
        <div className="create-group-wrap">

       
       
           
            <div className="create-input-wrap">
                <label>生成地址*:</label>
                <input placeholder='https://meta11w.com/basic/' value={value.id} onChange={(e)=>handleId(e)} type="text" />
            </div>

            <div className="create-input-wrap">
                <label>Name*:</label>
                <input value={value.name} onChange={(e)=>handleName(e)} type="text" />
            </div>

            <div className="create-input-wrap">
                <label>Tel*:</label>
                <input value={value.tel} onChange={(e)=>handleTel(e)} type="text" />
            </div>

            <div className="create-input-wrap">
                <label>Email:</label>
                <input value={value.email} onChange={(e)=>handleEmail(e)} type="text" />
            </div>

            <div className="create-input-wrap">
                <label>Address链接:</label>
                <input value={value.map} onChange={(e)=>handleAddressLink(e)}  type="text" />
            </div>

            <div className="create-input-wrap">
                <label>网址:</label>
                <input value={value.website} onChange={(e)=>handleWebsite(e)} type="text" />
            </div>
           
            <div className="create-input-wrap">
                <h6>立方体贴图链接*:</h6>
                <div>
                <label >正面:</label>
                <input value={value.images[4]} onChange={(e)=>handleFront(e)} type="text" />
                </div>
                <div>
                <label >背面:</label>
                <input value={value.images[5]} onChange={(e)=>handleBack(e)} type="text" />
                </div>
                <div>
                <label >左面:</label>
                <input value={value.images[1]} onChange={(e)=>handleLeft(e)} type="text" />
                </div>
                <div>
                <label >右面:</label>
                <input value={value.images[0]} onChange={(e)=>handleRight(e)}  type="text" />
                </div>
                <div>
                <label >上面:</label>
                <input value={value.images[2]} onChange={(e)=>handleTop(e)}  type="text" />
                </div>
                <div>
                <label >下面:</label>
                <input value={value.images[3]} onChange={(e)=>handleBottom(e)} type="text" />
                </div>
             
              
            </div>
         

            <div className="create-input-wrap">
                <label>Address:</label>
                <input value={value.address} onChange={(e)=>handleAddress(e)} type="text" />
            </div>

          

            <div className="create-input-wrap">
                <label>背面链接:</label>
                <input value={value.video} onChange={(e)=>handleBackLink(e)} type="text" />
            </div>

          

           

           

            <div className="create-input-wrap">
                <label>背景图链接(可Gif)*:</label>
                <input value={value.gif} onChange={(e)=>handleBackground(e)} type="text" />
            </div>

            <div className="create-input-wrap">
                <label>载入图片:</label>
                <input value={value.loadImg} onChange={(e)=>handleLoadImg(e)} type="text" />
            </div>

            <div className="create-input-wrap">
                <label>微信二维码:</label>
                <input value={value.qrCode} onChange={(e)=>handleWechat(e)} type="text" />
            </div>

           

            <div className="create-input-wrap">
                <label>侧面链接:</label>
                <input value={value.living} onChange={(e)=>handleLeftSideLink(e)} type="text" />
            </div>

            <div className="create-input-wrap">
                <label>职业(Title)*:</label>
                <input value={value.title} onChange={(e)=>handleTitle(e)} type="text" />
            </div>

            <div className="create-input-wrap">
                <label>经度:</label>
                <input value={value.lng} onChange={(e)=>handleLng(e)} type="text" />
            </div>

            <div className="create-input-wrap">
                <label>纬度:</label>
                <input value={value.lat} onChange={(e)=>handleLat(e)} type="text" />
            </div>

            <div className="create-input-wrap">
                <label>音乐链接:</label>
                <input value={value.music[0].url} onChange={(e)=>handleMusic(e)} type="text" />
            </div>
            <div className="create-input-wrap">
        <label>轮播图(右面)*</label>
        <Select    
         onChange={(e)=>handleGallery(e)}
         options={isGallerys}             
           menuPortalTarget={document.body}
        menuPosition="fixed"       maxMenuHeight='30vh'           
        />
    </div>
    {
        value.isGallery&&gallerys.map((v,i)=>(
            <div key={i} className="create-input-wrap">
            <label>轮播图{i+1}</label>
            <input value={value.gallery[i]} onChange={(e)=>handleChangeGallery(e,i)} type="text" />
        </div>
        ))
    }
           

        </div>

        <div className='select'>

<div className="create-input-wrap">
        <label>模型*</label>
        <Select                
        onChange={(e)=>handleModel(e)}
        options={options}       menuPortalTarget={document.body}
        menuPosition="fixed"       maxMenuHeight='30vh'           
        />
    </div>

    <div className="create-input-wrap">
        <label>地区*</label>
        <Select           
        onChange={(e)=>handleLocation(e)}
        options={locations} menuPortalTarget={document.body}
        menuPosition="fixed"       maxMenuHeight='30vh'  />
    </div>

    <div className="create-input-wrap">
        <label>分类*</label>
        <Select
        onChange={(e)=>handleCategory(e)}
        options={categorys} menuPortalTarget={document.body}
        menuPosition="fixed"       maxMenuHeight='30vh'  />
    </div>
    <div className="create-input-wrap">
        <label>国家*</label>
        <Select
        onChange={(e)=>handleCountry(e)}
        options={countrys} menuPortalTarget={document.body}
        menuPosition="fixed"       maxMenuHeight='30vh'  />
    </div>
    <div className="create-input-wrap">
        <label>边框颜色</label>
        <Select
        onChange={(e)=>handleColor(e)}
        options={colors} menuPortalTarget={document.body}
        menuPosition="fixed"       maxMenuHeight='30vh'  />
    </div>
</div>

        <button onClick={()=>handleSubmit()}>提交上传</button>
    
    </div>
  )
}


