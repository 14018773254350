import React,{useState,useEffect,useCallback}from 'react'
import { GoogleMap, useJsApiLoader, Marker, Circle} from '@react-google-maps/api';

import axios from 'axios'
import Select from 'react-select'
import fetchLocation from '../../../locations'
import fetchCategory from '../../../category'
import {Navigate} from 'react-router-dom'
import Nav from '../../../components/Nav/index'
import Footer from '../../../components/Footer/index'
import './index.scss'

const containerStyle = {
    width: '100%',
    height: '300px'
  };

export default function UserPostNew() {

    const apiUrl ='https://metaserverapp.com'
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: "AIzaSyC3mQehLcFpdCISwzYNkak2iXuLfNhtq-E"
      })
    
      const [map, setMap] = useState(null)

    const initial = {
        title:'',
        content:'',
        userId:'',
        images:[],
        area:'',
        location:'',
        city:'',
        category:'',
        tel:'',
        isAd:false,
        isMap:false,
        lat:'',
        lng:'',
        address:'',
    }
    const locations = fetchLocation()

    const token = window.localStorage.getItem('token')
    const userId = window.localStorage.getItem('userId')
    const [post,setPost] = useState(initial)
    const [city,setCity] = useState(locations[0].location)

    const categorys = fetchCategory()
   
    const [preview,setPreview] = useState([])
    const [photos,setPhotos]=useState([])
    const [address,setAddress] = useState('')
    const [search,setSearch] = useState('')
    const [center,setCenter]=useState({
        lat: -3.745,
        lng: -38.523
      })
    const [isMap,setIsMap]=useState(false)
    const [isPick,setIsPick] = useState(false)

   
    


    const mapOnLoad = React.useCallback(function callback(map) {
        const bounds = new window.google.maps.LatLngBounds(center);
        map.fitBounds(bounds);
        setMap(map)
      }, [])
    
      const mapoOnUnmount = React.useCallback(function callback(map) {
        setMap(null)
      }, [])

    const handleAddress = async(e)=>{

        setAddress(e.target.value)

        const  result = await axios.get(`${apiUrl}/google/search`,{
            params: {address: e.target.value},
        })
        // console.log(result.data.data)
        if(result.data.success){
            setSearch(result.data.data.candidates[0].formatted_address)
            setCenter(result.data.data.candidates[0].geometry.location)
            let temp = {...post}
            temp.lat = result.data.data.candidates[0].geometry.location.lat
            temp.lng = result.data.data.candidates[0].geometry.location.lng
            temp.address = result.data.data.candidates[0].formatted_address
            setPost(temp)
        }
    }

    const handleClickAddress=()=>{
        setIsMap(true)
        setIsPick(true)
        setAddress(search)
    }

    const handleFileUpload = (e)=>{ 

        const {files} = e.target;
  
        if(files.length>9){
            alert('不能上传超过九张图')
        }
        else{
            setPhotos(files)
            const formData = new FormData();
            const arr = [...preview]
            for(let i=0;i<files.length;i++) {
                formData.append('photos',files[i])
                const link = URL.createObjectURL(files[i])
                arr.push(link)
            }
            setPreview(arr)
            // console.log(formData)
    
        }
       
    }


    
    const handleCity = (e)=>{
        console.log(e);
        let temp = {...post}
        temp.city = e.value
        setPost(temp)
    }
    const handleCategory = (e)=>{
        console.log(e);
        let temp = {...post}
        temp.category = e.value
        setPost(temp)
    }

    const handleTitle = (e)=>{
        let temp = {...post}
        if(e.target.value.length>18){
            alert('标题不能超过18个字！')
        }
        else{
            temp.title = e.target.value
            setPost(temp)
        }
      
    }

    const handleContent = (e)=>{
        let temp = {...post}
        temp.content = e.target.value
        setPost(temp)
    }
    const handleTel = (e)=>{ 
        let temp = {...post}
        temp.tel = e.target.value
        setPost(temp)
    }

    const handleDelete = (i)=>{
        let temp = [...preview]
        let temp2 = [...photos]
        temp.splice(i,1)
        temp2.splice(i,1)
        setPreview(temp)
        setPhotos(temp2)
     }

     const handleCheck = ()=>{
         setIsMap(!isMap)
         let temp = {...post}
         temp.isMap =!isMap
         setPost(temp)
     }

    const handleSubmit = async()=>{
      


        // if(!post.area){
        //     alert('请选择地区')
        // }
        // if(!post.location){
        //     alert('请选择州')
        // }
        if(!post.city){
            alert('请选择城市')
        }
        if(!post.category){
            alert('请选择分类')
        }
        if(!post.title){
            alert('请写下帖子标题')
        }
        if(!post.tel){
            alert('请留下联系方式')
        }
        if(!post.content){
            alert('请留下帖子内容')
        }

        const publisher = window.localStorage.getItem('publisher')
        const now = new Date()
        console.log(now.getTime());
        const item = JSON.parse(publisher)


        if(!publisher){
            console.log('No publisher, We can post new post directly!');

            const temp =[ ...photos]
        
            const formData = new FormData();
            for(let i=0;i<temp.length;i++) {
                formData.append('photos',temp[i])
            }
         
            const uploadResult = await axios.post(`${apiUrl}/file/array`,formData)
        
    
            if(uploadResult.data.success){
    
                const result = await axios.post(`${apiUrl}/post/create`,{
                   userId:userId ? userId : '游客',
                   title:post.title,
                   content:post.content,
                   location:'纽约',
                   city:post.city,
                   category:post.category,
                   tel:post.tel, 
                   images:uploadResult.data.data,
                   isAd:false,
                   isMap:post.isMap,
                   lat:Number(post.lat),
                   lng:Number(post.lng),
                   address:post.address,
                })
                console.log(result)
                if(result.data.success){
                    alert('发帖成功！')
                    let ttl = {
                        value:'posted',
                        expiry:now.getTime()+300000
                    }
                    window.localStorage.setItem('publisher', JSON.stringify(ttl));
                    window.location.reload()
                }
                else{
                    alert(result.date.data)
                }
    
            }
            else{
                alert('文件上传错误！请重新选择文件')
            }



        
        }
        else{

            if(now.getTime()>item.expiry){
                console.log('could post again');
                const temp =[ ...photos]
        
                const formData = new FormData();
                for(let i=0;i<temp.length;i++) {
                    formData.append('photos',temp[i])
                }
             
                const uploadResult = await axios.post(`${apiUrl}/file/array`,formData)
            
        
                if(uploadResult.data.success){
        
                    const result = await axios.post(`${apiUrl}/post/create`,{
                       userId:userId,
                       title:post.title,
                       content:post.content,
                       area:post.area,
                       location:post.location,
                       city:post.city,
                       category:post.category,
                       tel:post.tel, 
                       images:uploadResult.data.data,
                       isAd:false,
                       isMap:post.isMap,
                       lat:Number(post.lat),
                       lng:Number(post.lng),
                       address:post.address,
                    })
                    console.log(result)
                    if(result.data.success){
                        alert('发帖成功！')
                        let ttl = {
                            value:'posted',
                            expiry:now.getTime()+300000
                        }
                        window.localStorage.setItem('publisher', JSON.stringify(ttl));
                        window.location.reload()
                    }
                    else{
                        alert(result.date.data)
                    }
        
                }
                else{
                    alert('文件上传错误！请重新选择文件')
                }
    
            }
            else{
                alert('五分钟内只能发送一次贴!');
            }


        }



      

    }

    const handleTestSubmit = ()=>{

        const publisher = window.localStorage.getItem('publisher')
        const now = new Date()
        console.log(now.getTime());
        const item = JSON.parse(publisher)
        if(!publisher){
            console.log('No publisher, We can post new post directly!');
            let ttl = {
                value:'posted',
                expiry:now.getTime()+300000
            }
            window.localStorage.setItem('publisher', JSON.stringify(ttl));
        }
        else{

            if(now.getTime()>item.expiry){
                console.log('could post again');
                let ttl = {
                    value:'posted',
                    expiry:now.getTime()+300000
                }
                window.localStorage.setItem('publisher', JSON.stringify(ttl));
            }
            else{
                console.log('五分钟后可以再次发帖');
            }


        }

    }

    // console.log(search,center,post)

    // const onLoad = marker => {
    //     console.log('marker: ', marker)
       
    //   }


    const options = {
        strokeColor: '#FF0000',
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: '#FF0000',
        fillOpacity: 0.35,
        clickable: false,
        draggable: false,
        editable: false,
        visible: true,
        radius: 800,
        zIndex: 1
      }
      
      const onLoad = circle => {
        console.log('Circle onLoad circle: ', circle)
      }
      
      const onUnmount = circle => {
        console.log('Circle onUnmount circle: ', circle)
      }
    
  return (
<>
  <div className='user-wrap'>
       <Nav/>
       
       <div className='post-content2'>

                   <div className='publish-content'>
                       <h2>发帖</h2>

                       <div className="publish-wrap">

                            {/* <div className="create-input-wrap">
                                <label>地区*</label>
                                <Select    
                                onChange={(e)=>handleArea(e)}
                                options={locations}             
                                menuPortalTarget={document.body}
                                menuPosition="fixed"       maxMenuHeight='30vh'           
                                />
                            </div>

                            <div className="create-input-wrap">
                                <label>州*</label>
                                <Select    
                                onChange={(e)=>handleLocation(e)}
                                options={location}             
                                menuPortalTarget={document.body}
                                menuPosition="fixed"       maxMenuHeight='30vh'           
                                />
                            </div> */}

                            <div className="create-input-wrap">
                                <label>城市*</label>
                                <Select    
                                onChange={(e)=>handleCity(e)}
                                options={city}             
                                menuPortalTarget={document.body}
                                menuPosition="fixed"       maxMenuHeight='30vh'           
                                />
                            </div>
                       </div>

                       <div className="create-input-wrap">
                                <label>分类*</label>
                                <Select    
                                onChange={(e)=>handleCategory(e)}
                                options={categorys}             
                                menuPortalTarget={document.body}
                                menuPosition="fixed"       maxMenuHeight='30vh'           
                                />
                        </div>

                        <div className="create-content">

                            <div>
                                <h4>标题*</h4>
                                <input type="text" value={post.title} onChange={(e)=>handleTitle(e)} />
                            </div>
                            <div>
                                <h4>联系电话*</h4>
                                <input type="text" value={post.tel} onChange={(e)=>handleTel(e)} />
                            </div>
                            <div>
                                <h4>内容*</h4>
                                <textarea type="text" value={post.content} onChange={(e)=>handleContent(e)} />
                            </div>

                            <div>
                                <h4>上传图片(可选:最多九张)</h4>
                                <input type="file" multiple onChange={(e)=>handleFileUpload(e)}  />
                            </div>
                            <div className='preview'> 
                            <div>
                            {
                                preview[0]&&preview.map((v,i)=>(
                                    <div key={i}>
                                    <img  src={v} alt="" />
                                    <img onClick={()=>handleDelete(i)} id='delete-image' src="https://res.cloudinary.com/dvr397xda/image/upload/v1666643028/remove_dywpuk.png" alt="" />
                            </div>
                                ))
                            }
                            </div>


                          
                            {/* <div>
                                <h2>是否提供地址(可选)</h2>
                            <label className="switch">
                            <input onChange={()=>handleCheck()} type="checkbox" checked={isMap}/>
                            <span className="slider round"></span>
                            </label>
                            </div>
                           */}
                          

                            </div>


                           { isMap&&<div>

                            <div >
                                <h4>地址*</h4>
                                <div className='address-check'>

                                <input type="text" value={address} onChange={(e)=>handleAddress(e)} />
                                {search&&<button onClick={()=>handleClickAddress()}>{search}</button>}
                                

                                </div>
                            </div>

                            <div>
                                {isLoaded&&(
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={center}
        zoom={12}
  
      >
           {isPick&&<Circle
      
  
      // optional
      onLoad={onLoad}
      // optional
      onUnmount={onUnmount}
      // required
      center={center}
      // required
      options={options}
    
     
    />}
      </GoogleMap>)}
  
                            </div>

                            </div>}

                         

                        </div>

                        <button id='post-submit-btn' onClick={()=>handleSubmit()}>提交</button>

                   </div>
              

           </div>
           <Footer/>
    </div> 

</>
  )
}


