import React,{useState,useEffect,useRef,Suspense,useCallback}from 'react'
import { Canvas, useFrame,extend,useThree,useLoader,} from '@react-three/fiber'
import {Link,useNavigate} from 'react-router-dom'
import * as THREE from "three";
import { Helmet } from 'react-helmet';
// import { Helmet,} from 'react-helmet-async';

import HTMLFlipBook from "react-pageflip";
import {useParams} from 'react-router-dom'
import axios from "axios";
import { TextureLoader } from 'three/src/loaders/TextureLoader.js';
import {useGLTF,useFBX,useProgress,Html,PresentationControls,Line} from '@react-three/drei'
import Player from '../../components/Player/index'
import {
  Star,
  StarFill,
  Share
} from "react-bootstrap-icons";
import './index.scss'


function Loader({people}) {
  // console.log(people,1)
  const { progress } = useProgress()
  return <Html center> <h2 id='loadText'>
        Loading ...{progress.toFixed(0)}%
        <img id='loadImage' src="https://res.cloudinary.com/dvr397xda/image/upload/v1660580851/cube/output-onlinegiftools_rgnpbb.gif" alt="" />

        </h2>
        {/* <img src={people.loadImg} alt="" /> */}

        </Html>
}

function Earth(){
  const earthMaterial = 'https://res.cloudinary.com/dvr397xda/image/upload/v1650905847/cube/earth/2k_earth_daymap_habyjo.jpg';

    const colorMap = useLoader(THREE.TextureLoader, earthMaterial)
    const mesh = useRef()

    useFrame((state,delta)=>(mesh.current.rotation.y+=0.01))

    return (
        <mesh ref={mesh}>
        <sphereBufferGeometry args={[1.2, 30, 30]} attach="geometry" />
        <meshBasicMaterial  map={colorMap} attach="material" metalness={1} />
      </mesh>
    )

}

function Rose(){

  const group = useRef();
  const { nodes, materials } = useGLTF("https://res.cloudinary.com/dvr397xda/image/upload/v1659630975/cube/hunqing/rose_jzkfup.glb");


  useFrame((state, delta) => {
      
    
    group.current.rotation.y += 0.005
  
  })
  return (
    <group ref={group}  dispose={null}>
      <group rotation={[-Math.PI / 2, 0, 0]}>
        <group rotation={[Math.PI / 2, 0, 0]}>
          <group
            position={[0, -1.5, 0]}
            rotation={[0.17, -0.1, 0]}
            scale={[0.8, 0.8, 0.8]}
          >
            <mesh
              geometry={nodes.mesh_0.geometry}
              material={materials.material}
            />
          </group>
        </group>
      </group>
    </group>
  );
}
function Macbook(){
  const mesh = useRef()

  const { nodes, materials } = useGLTF('https://res.cloudinary.com/dvr397xda/image/upload/v1657212110/cube/museum/mac-draco_cvwbu1.glb')

  useFrame((state) => {
      mesh.current.rotation.y += 0.01
  
  })
  // Events and spring animations were added afterwards
  return (
    <group
      ref={mesh}
      position={[0,-0.5,0]}
      scale={0.16}
      >
     
        <group position={[0, 2.96, 0]} rotation={[Math.PI *2.5, 0, 0]}>
          <mesh material={materials.aluminium} geometry={nodes['Cube008'].geometry} />
          <mesh material={materials['matte.001']} geometry={nodes['Cube008_1'].geometry} />
          <mesh material={materials['screen.001']} geometry={nodes['Cube008_2'].geometry} />
        </group>
    
      <mesh material={materials.keys} geometry={nodes.keyboard.geometry} position={[1.79, 0, 3.45]} />
      <group position={[0, -0.1, 3.39]}>
        <mesh material={materials.aluminium} geometry={nodes['Cube002'].geometry} />
        <mesh material={materials.trackpad} geometry={nodes['Cube002_1'].geometry} />
      </group>
      <mesh material={materials.touchbar} geometry={nodes.touchbar.geometry} position={[0, -0.03, 1.2]} />
    </group>
  )
}
function VideoCamera(){
  const mesh = useRef()
  const model = {
    name:'camera',
    url:'https://res.cloudinary.com/dvr397xda/raw/upload/v1660169514/cube/basic/wingo/OldCamera_ibq4gj.fbx',
    metalMap:'https://res.cloudinary.com/dvr397xda/image/upload/v1659468224/cube/basic/wingo/CAM0001_Textures_METL_2k_z9glb8.png',
    normalMap:'https://res.cloudinary.com/dvr397xda/image/upload/v1659468230/cube/basic/wingo/CAM0001_Textures_NRML_2k_ou6yul.png',
    roughnessMap:'https://res.cloudinary.com/dvr397xda/image/upload/v1659468224/cube/basic/wingo/CAM0001_Textures_ROUGH_2k_rx6oey.png',
    colorMap:'https://res.cloudinary.com/dvr397xda/image/upload/v1659468228/cube/basic/wingo/CAM0001_Textures_COL_2k_wrktl1.png',
    aoMap:'https://res.cloudinary.com/dvr397xda/image/upload/v1659468222/cube/basic/wingo/CAM0001_Textures_AO_2k_ilwxtp.png',
    scale:0.018,
    position:[0,-0.5,0],
    rotation:[0,0,0],
}
  let key = useFBX(model.url)
  const [colorMap, metalMap, normalMap, roughnessMap, aoMap] = useLoader(THREE.TextureLoader, [
   model.colorMap,
   model.metalMap,
    model.normalMap,
    model.roughnessMap,
    model.aoMap
  ])
  key.traverse( function ( child ) {

    if ( child.isMesh ) {
        
        child.material.map = colorMap;
       
        child.material.aoMap =aoMap;
     
        child.material.normalMap = normalMap;
        
        child.material.metalMap = metalMap; 
     
        child.material.roughnessMap = roughnessMap; 
      }
  
  } );
  useFrame((state, delta) => {
  

    mesh.current.rotation.y += 0.005
  
  })

  return (
    <mesh scale={model.scale} position={model.position} rotation={model.rotation}  ref={mesh}>
        <primitive   object={key} />

    </mesh>
  )


}
function Camera(){
  const mesh = useRef()
  const model = {
    name:'camera',
    url:'https://res.cloudinary.com/dvr397xda/raw/upload/v1659468154/cube/basic/wingo/Canon_AT-1_o5weeb.fbx',
    metalMap:'https://res.cloudinary.com/dvr397xda/image/upload/v1659468224/cube/basic/wingo/CAM0001_Textures_METL_2k_z9glb8.png',
    normalMap:'https://res.cloudinary.com/dvr397xda/image/upload/v1659468230/cube/basic/wingo/CAM0001_Textures_NRML_2k_ou6yul.png',
    roughnessMap:'https://res.cloudinary.com/dvr397xda/image/upload/v1659468224/cube/basic/wingo/CAM0001_Textures_ROUGH_2k_rx6oey.png',
    colorMap:'https://res.cloudinary.com/dvr397xda/image/upload/v1659468228/cube/basic/wingo/CAM0001_Textures_COL_2k_wrktl1.png',
    aoMap:'https://res.cloudinary.com/dvr397xda/image/upload/v1659468222/cube/basic/wingo/CAM0001_Textures_AO_2k_ilwxtp.png',
    scale:14,
    position:[0,-0.5,0],
    rotation:[0,0,0],
}
  let key = useFBX(model.url)
  const [colorMap, metalMap, normalMap, roughnessMap, aoMap] = useLoader(THREE.TextureLoader, [
   model.colorMap,
   model.metalMap,
    model.normalMap,
    model.roughnessMap,
    model.aoMap
  ])
  key.traverse( function ( child ) {

    if ( child.isMesh ) {
        
        child.material.map = colorMap;
       
        child.material.aoMap =aoMap;
     
        child.material.normalMap = normalMap;
        
        child.material.metalMap = metalMap; 
     
        child.material.roughnessMap = roughnessMap; 
      }
  
  } );
  useFrame((state, delta) => {
  

    mesh.current.rotation.y += 0.005
  
  })

  return (
    <mesh scale={model.scale} position={model.position} rotation={model.rotation}  ref={mesh}>
        <primitive   object={key} />

    </mesh>
  )


}
function Keys(){
  const mesh = useRef()
  const model = {
    name:'key',
    url:'https://res.cloudinary.com/dvr397xda/raw/upload/v1659109343/cube/basic/lily/KeyChain_sgoo39.FBX',
    metalMap:'https://res.cloudinary.com/dvr397xda/image/upload/v1659109362/cube/basic/lily/KeyChainMetallic_hipbo4.png',
    normalMap:'https://res.cloudinary.com/dvr397xda/image/upload/v1659109365/cube/basic/lily/KeyChainNormal_jp5gqw.png',
    roughnessMap:'https://res.cloudinary.com/dvr397xda/image/upload/v1659109360/cube/basic/lily/KeyChainRough_m2zisg.png',
    colorMap:'https://res.cloudinary.com/dvr397xda/image/upload/v1659109363/cube/basic/lily/KeyChainAlbedo_kavfgg.png',
    aoMap:'https://res.cloudinary.com/dvr397xda/image/upload/v1659109363/cube/basic/lily/KeyChainAOC_gdexfb.png',
    scale:8,
    position:[0,-0.5,0],
    rotation:[0,0,Math.PI / 2.5],
}
  let key = useFBX(model.url)
  const [colorMap, metalMap, normalMap, roughnessMap, aoMap] = useLoader(THREE.TextureLoader, [
   model.colorMap,
   model.metalMap,
    model.normalMap,
    model.roughnessMap,
    model.aoMap
  ])
  key.traverse( function ( child ) {

    if ( child.isMesh ) {
        
        child.material.map = colorMap;
       
        child.material.aoMap =aoMap;
     
        child.material.normalMap = normalMap;
        
        child.material.metalMap = metalMap; 
     
        child.material.roughnessMap = roughnessMap; 
      }
  
  } );
  useFrame((state, delta) => {
  

    mesh.current.rotation.y += 0.005
  
  })

  return (
    <mesh scale={model.scale} position={model.position} rotation={model.rotation}  ref={mesh}>
        <primitive   object={key} />

    </mesh>
  )


}

function House(){
  const mesh = useRef()
  const model = {
    name:'house',
    url:'https://res.cloudinary.com/dvr397xda/raw/upload/v1661194031/cube/basic/ben/High_Poly_House_Full2_gqcfiv.fbx',
 
    scale:0.00125,
    position:[0,-1,0],
    rotation:[0,0,0],
}
  let key = useFBX(model.url)
 

  useFrame((state, delta) => {
  

    mesh.current.rotation.y += 0.005
  
  })

  return (
    <mesh scale={model.scale} position={model.position} rotation={model.rotation}  ref={mesh}>
        <primitive   object={key} />

    </mesh>
  )


}


function Japan(){
  const mesh = useRef()
  const model = {
    name:'japan',
    url:'https://res.cloudinary.com/dvr397xda/raw/upload/v1662070813/cube/kinha%20sushi/Japanese_Garden_Scene_kkqvcn.fbx',
    metalMap:'',
    normalMap:'https://res.cloudinary.com/dvr397xda/image/upload/v1659726267/cube/Happy%20Town/model_normal_tsbl8o.jpg',
    roughnessMap:'',
    colorMap:'https://res.cloudinary.com/dvr397xda/image/upload/v1659726193/cube/Happy%20Town/model_avhsp5.jpg',
    aoMap:'',
    scale:0.0015,
    position:[0,-1,0],
    rotation:[0,0,0],
}
  let key = useFBX(model.url)
  const [colorMap,  normalMap] = useLoader(THREE.TextureLoader, [
   model.colorMap,
  
    model.normalMap,
   
  ])
  key.traverse( function ( child ) {

    if ( child.isMesh ) {
        
        child.material.map = colorMap;
       
    
     
        child.material.normalMap = normalMap;
        
     
      }
  
  } );
  useFrame((state, delta) => {
  

    mesh.current.rotation.y += 0.005
  
  })

  return (
    <mesh scale={model.scale} position={model.position} rotation={model.rotation}  ref={mesh}>
        <primitive   object={key} />

    </mesh>
  )


}


const  Model=({people})=>{
   
  if(people.model==='地球'){
    return <Earth/>
  }
  else if(people.model==='玫瑰花'){
    return <Rose/>

  }
  else if(people.model==='电脑'){
    return <Macbook/>
  }
  else if(people.model==='摄像机'){
    return <VideoCamera/>
  }
  else if(people.model==='相机'){
    return <Camera/>
  }
  else if(people.model==='房子'){
    return <House/> 
  }
  else if(people.model==='日式'){
    return <Japan/>
  }
  else if(people.model==='钥匙'){
    return <Keys/>
  }
  
  else{
    return <Earth/>
  }
}


function Book({people}){

  const onFlip = useCallback((e) => {
    console.log('Current page: ' + e.data);
}, []);

  return (

  <HTMLFlipBook className="wrapper" 
  size='stretch'  width={195}  height={338}        maxShadowOpacity={0.5}
  onFlip={onFlip}
  showCover
 >
  
  {
      people.show&&people.show.map((v,i)=>(
          <div id='page-item' className="demoPage" key={i}>
            
            <h2>
                    {v.name}
                </h2>
                {v.title&&<h2>
                    {v.title}
                </h2>}
               
             <img src={v.image} alt="" />
               
          </div>
      ))
  }
</HTMLFlipBook>

)
}

const Gallery = ({gallery})=>{
  const mesh = useRef()
  const [index,setIndex] = useState(0)

 useEffect(() => {
    const interval = setInterval(() => {

        if(index ===gallery.length-1){
            setIndex(0)
        }
        else{
            setIndex(index+1)
        }
    }, 2500);
    return () => clearInterval(interval);
  }, [index,gallery.length]);
  let texture  = useLoader(THREE.TextureLoader,gallery[index])
  texture.minFilter = texture.magFilter = THREE.LinearFilter;

  texture.format= THREE.RGBAFormat;

  return (
     
    <mesh position={[1.3,0,0]} rotation={[0,Math.PI * 2.5,0]} onClick={(e)=>{     e.stopPropagation()
}}   ref={mesh}>
        <planeGeometry  attach="geometry"  args={[2.4,2.4]}/>
        <meshStandardMaterial map={texture}  side={THREE.DoubleSide}   />
    </mesh>

  )
}


function Box(props) {
  const {people,position} = props
  const mesh = useRef()
  const [index,setIndex] = useState(0)
 
  // setInterval(handleChangeIndex,1000)
  // useEffect(() => {
  //   const interval = setInterval(() => {

  //       if(index ===people.gallery.length-1){
  //           setIndex(0)
  //       }
  //       else{
  //           setIndex(index+1)
  //       }
  //   }, 2500);
  //   return () => clearInterval(interval);
  // }, [index,people.gallery.length]);

  let textures;
  
textures = useLoader(THREE.TextureLoader,[people.images[0],people.images[1],people.images[2],people.images[3],people.images[4],people.images[5]])
 

textures.minFilter = textures.magFilter = THREE.LinearFilter;

textures.format= THREE.RGBAFormat;

  function Map (){
    return(
    
      textures.map((texture, idx) =>(
        idx ===2||idx===3 || idx===0?<meshBasicMaterial key={idx} attach={`material-${idx}`} map={texture}   transparent={true} opacity={1}/>
        :<meshBasicMaterial opacity={1} transparent={true}  key={idx} attach={`material-${idx}`} map={texture}  />
        )
)
    )
  } 
  

  return (
  
    <mesh
      onClick={(e)=>{
        e.stopPropagation()
      }}
      ref={mesh}
     position={position}
     
      >
          
      <boxBufferGeometry attach="geometry"  args={[2.5, 2.5, 2.5]}/>
        <Map/>
       
      

    </mesh>


   
  )
}



const Phone = ({people,opacity,posY,visible})=>{

  const mesh1 = useRef()
 

  const handleClick=()=>{
    if(people.country==='USA'){

      window.open(`tel:+1${people.tel}`,'_self')
    }
    else{
      window.open(`tel:+86${people.tel}`,'_self')
    }
  }


  return (
   
    <mesh position={[0.8,posY-0.2,1.3]} rotation={[0,Math.PI * 2.5,0]} onClick={(e)=>{ handleClick();     e.stopPropagation()
}}   ref={mesh1}>
        <boxBufferGeometry  attach="geometry"  args={[0.1,0.8,0.8]}/>
        <meshStandardMaterial visible={visible} transparent={true} opacity={opacity}   color='green'  />

       


    </mesh>

  )
}



const Email = ({people,opacity,posY,visible})=>{

  const mesh1 = useRef()
 

  const handleClick=()=>{
 
    window.open(`mailto:+${people.email}`,'_self')
  }

  return (
  
    <mesh position={[0,posY-1.8,1.3]} rotation={[0,Math.PI * 2.5,0]} onClick={(e)=>{ handleClick();     e.stopPropagation()
}}   ref={mesh1}>
        <boxBufferGeometry  attach="geometry"  args={[0.1,0.8,0.8]}/>
        <meshStandardMaterial visible={visible} transparent={true} opacity={opacity}  color='#fff' />

       


    </mesh>
   
  )
}

const Map = ({people,opacity,posY,visible})=>{
 
  const mesh1 = useRef()


  const handleClick=()=>{

    window.open(`${people.map}`,'_self')
  }

  return (
    
    <mesh rotation={[0,Math.PI * 2.5,0]} position={[-0.8,-1.8+posY,1.3]} onClick={(e)=>{ handleClick();     e.stopPropagation()
}}   ref={mesh1}>
        <boxBufferGeometry  attach="geometry"  args={[0.1,0.8,0.8]}/>
        <meshStandardMaterial visible={visible} transparent={true} opacity={opacity}   color='blue'   />

       


    </mesh>
   
  )
}

const Youtube = ({people,opacity,posY,visible})=>{

    const mesh1 = useRef()
   
  
    const handleClick=()=>{
  
      window.open(`${people.video}`,'_self')
    }
  
    return (
  
      <mesh rotation={[0, Math.PI * 2.5, 0]} position={[0,-1+posY,-1.3]} onClick={(e)=>{ handleClick();     e.stopPropagation()
  }}   ref={mesh1}>
          <boxBufferGeometry  attach="geometry"  args={[0.1,2.5,2.5]}/>
          <meshStandardMaterial visible={visible} transparent={true} opacity={opacity}  attach="material" color='green'  roughness={0.7}
          metalness={0.1} />
  
         
  
  
      </mesh>
   
    )
  }

  const QR = ({opacity,posY,handleOpenQr,visible})=>{
  
    const mesh1 = useRef()
  
  
  
    return (
  
      <mesh rotation={[0, Math.PI * 2.5, 0]} position={[0.8,-1+posY,1.3]} onClick={(e)=>{  handleOpenQr();     e.stopPropagation()
  }}   ref={mesh1}>
          <boxBufferGeometry  attach="geometry"  args={[0.1,0.8,0.8]}/>
          <meshStandardMaterial visible={visible} transparent={true} opacity={opacity}   color='green'   />
  
         
  
  
      </mesh>
    
    )
  }



const Web = ({people,opacity,posY,visible})=>{
 
  const mesh1 = useRef()

  const handleClick=()=>{
    window.open(`${people.website}`,'_self')
  }

  return (
   
    <mesh rotation={[0, Math.PI * 2.5, 0]} position={[0.8,-1.8+posY,1.3]} onClick={(e)=>{ handleClick();     e.stopPropagation()
}}   ref={mesh1}>
        <boxBufferGeometry  attach="geometry"  args={[0.1,0.8,0.8]}/>
        <meshStandardMaterial visible={visible} transparent={true} opacity={opacity}   color='#fff'   />

       


    </mesh>

  )
}

const Living = ({people,opacity,posY,visible})=>{
    const mesh1 = useRef()

    const handleClick=()=>{
      window.open(`${people.living}`,'_self')
    }
  
    return (
   
      <mesh position={[-1.3,0,0]} rotation={[0,0,0]} onClick={(e)=>{ handleClick();     e.stopPropagation()
  }}   ref={mesh1}>
          <boxBufferGeometry  attach="geometry"  args={[0.1,2.4,2.4]}/>
          <meshStandardMaterial visible={visible} transparent={true} opacity={opacity}   color='#fff'   />
  
         
  
  
      </mesh>
     
    )
}



const Group = ({people,opacity,posY,visible,handleOpenQr})=>{

  const Points= [
    {
      point:[
      [0.45, 1.2, 1.3],
      [1.2, 1.2, 1.3],
      [1.2, 0.5, 1.3],
      [0.45, 0.5, 1.3],
      [0.45, 1.2, 1.3],
    ],
    },
    {
      point:[
      [0.45, 0.35, 1.3],
      [1.2, 0.35, 1.3],
      [1.2, -0.35, 1.3],
      [0.45, -0.35, 1.3],
      [0.45, 0.35, 1.3],
    ],
    },
    {
      point:[
      [0.45, -0.45, 1.3],
      [1.2, -0.45, 1.3],
      [1.2, -1.15, 1.3],
      [0.45, -1.15, 1.3],
      [0.45, -0.45, 1.3],
    ],
    },
    {
      point:[
      [-0.35, -0.45, 1.3],
      [0.35, -0.45, 1.3],
      [0.35, -1.15, 1.3],
      [-0.35, -1.15, 1.3],
      [-0.35, -0.45, 1.3],
    ],
    },
    {
      point:[
      [-1.2, -0.45, 1.3],
      [-0.45, -0.45, 1.3],
      [-0.45, -1.15, 1.3],
      [-1.2, -1.15, 1.3],
      [-1.2, -0.45, 1.3],
    ],
    },
    {
      point:[
      [-1.3, 1.3, 1.3],
      [1.3, 1.3, 1.3],
      [1.3, -1.3, 1.3],
      [-1.3, -1.3, 1.3],
      [-1.3, 1.3, 1.3],
    ],
    },
    {
      point:[
      [-1.3, 1.3, -1.3],
      [1.3, 1.3, -1.3],
      [1.3, -1.3, -1.3],
      [-1.3, -1.3, -1.3],
      [-1.3, 1.3, -1.3],
    ],
    },
    {
      point:[
      [-1.3,-1.3, 1.3 ],
      [-1.3,1.3, 1.3],
      [-1.3,1.3, -1.3],
      [-1.3,-1.3, -1.3],
      [-1.3,-1.3, 1.3],
    ],
    },
    {
      point:[
      [1.3,-1.3, 1.3 ],
      [1.3,1.3, 1.3],
      [1.3,1.3, -1.3],
      [1.3,-1.3, -1.3],
      [1.3,-1.3, 1.3],
    ],
    },

  ]

  const Points2 =[
    {
      point:[
      [-1.3, 1.3, 1.3],
      [1.3, 1.3, 1.3],
      [1.3, -1.3, 1.3],
      [-1.3, -1.3, 1.3],
      [-1.3, 1.3, 1.3],
    ],
    },
    {
      point:[
      [-1.3, 1.3, -1.3],
      [1.3, 1.3, -1.3],
      [1.3, -1.3, -1.3],
      [-1.3, -1.3, -1.3],
      [-1.3, 1.3, -1.3],
    ],
    },
    {
      point:[
      [-1.3,-1.3, 1.3 ],
      [-1.3,1.3, 1.3],
      [-1.3,1.3, -1.3],
      [-1.3,-1.3, -1.3],
      [-1.3,-1.3, 1.3],
    ],
    },
    {
      point:[
      [1.3,-1.3, 1.3 ],
      [1.3,1.3, 1.3],
      [1.3,1.3, -1.3],
      [1.3,-1.3, -1.3],
      [1.3,-1.3, 1.3],
    ],
    },
  ]

  const mesh = useRef()
  const [v,setV]= useState(1)
  const [POINT,setPOINT] = useState(Points2)

  useEffect(()=>{
    if(people.map&&people.email&&people.website&&people.qrCode){
      setPOINT(Points)
    }
    else{
      setPOINT(Points2)

    }


  },[people,Points,Points2])

 
 


  useFrame(({clock}) => {
      
    setV(Math.abs(Math.sin(clock.elapsedTime)))

    mesh.current.rotation.y += 0.001
  
  })

  return(<group ref={mesh}>
<Box people={people}   position={[0, 0, 0]} />
{/* <Model people={people}/> */}

   <Phone visible={visible} posY={posY} people={people} opacity={opacity}/>
   {people.email&&<Email visible={visible} posY={posY} people={people} opacity={opacity}/>}
   {people.qrCode&&<QR visible={visible} handleOpenQr={handleOpenQr} posY={posY} people={people} opacity={opacity}/>}
   {people.map&&<Map visible={visible} posY={posY} people={people} opacity={opacity}/>}
  { people.website&&<Web visible={visible} posY={posY} people={people} opacity={opacity}/>}
  {people.isGallery !== '0'&&<Gallery visible={visible} posY={posY} gallery={people.gallery} />}

    {/* <Line  points={points}  color='#0af741' linewidth={5} position={[0,0,0]} dashed
      dashSize={v * 1.5}
      gapSize={2 - v * 2}/> */}
    {
      POINT.map((m,i)=>(
        <Line key={i}  points={m.point} color={people.color}  linewidth={2} position={[0,0,0]} dashed
        dashSize={v * 1.5}
        gapSize={2 - v * 2}/>
      ))
    }
    {/* <Html  transform occlude onOcclude={occlude} position={[0.8,0.5,1.25]} style={{  opacity: occluded ? 0 : 1, }}>
    <div className='click-container'>
    <img className='btn--shockwave is-active' src="https://res.cloudinary.com/dvr397xda/image/upload/v1663022801/cube/tap_wbwwme.png" alt="" />
  </div>
    </Html> */}
  { people.video&&<Youtube visible={visible} posY={posY} people={people} opacity={opacity}/>}
  {people.living&&<Living visible={visible} posY={posY} people={people} opacity={opacity}/>}
  </group>)

}




export default function High() {

  const navigate = useNavigate();

    const{ name } = useParams();
    const token = window.localStorage.getItem('token')
    const userId = window.localStorage.getItem('userId')

    const apiUrl ='https://metaserverapp.com'

    const [isLoaded,setIsLoaded] = useState(false)
    const [isLiked,setIsLiked]= useState(false)
   
    const [people,setPeople]=useState({})
    const [gallery,setGallery]=useState([])
    
  console.log(name)
    useEffect(() => {
      const fetchData = async () => {
        const data = await axios.get(`${apiUrl}/basic/all`)
        console.log(data.data);

        const result = data.data.data.filter(e=>e.id===name)
        console.log(result)
        setPeople(result[0])
        setGallery(result[0].images)
        const temp = result[0].like.includes(userId)
        console.log(temp);
        if(temp){
          setIsLiked(true)
        }
        else{
          setIsLiked(false)
        }
        if(data.data.success){
          setIsLoaded(true)

         await axios.post(`${apiUrl}/basic/view`,{id:result[0]._id})
            
        }

      }
      fetchData()
  
    },[name])
   
   



  const [isTeam,setIsTeam] = useState(false)
  const [isQR,setIsQR] = useState(false)
  const opacity=0
  const posY = 1
  const visible = false

  const [load,setLoad] = useState(true)

  const handleOpenQr = ()=>{
      setIsQR(true)
  }




  const handleClickTeam = ()=>{
    setIsTeam(true)
  }
  const handleCloseTeam = ()=>{
    setIsTeam(false)
    setIsQR(false)
  }
  
  useEffect(() => {

    if(isLoaded){
      if(people.loadImg){
        const timer = setTimeout(()=>{
            setLoad(false)
          },4500)
          return ()=>clearTimeout(timer)
    }
    else{
        setLoad(false)
    }
    }
 
  },[people,isLoaded])

  const handleClickLike= async()=>{

    if(userId){
      const result = await axios.post(`${apiUrl}/basic/like`,{userId:userId,basicId:people._id})
      if(result.data.success){
        setIsLiked(!isLiked)
      }
    }
    else{
      navigate("/user/login");

    }

    

  }

  const handleShare = async()=>{
 await navigator.clipboard.writeText(window.location.href)

   console.log(window.location.href);
    alert(`已复制当前页面网址，可转发！`)
  }

  


  return (
    <div className="container-card" style={{backgroundImage:`url(${people.gif})`}}  >
      <Link id='return-home' to='/recommand'><img src="https://res.cloudinary.com/dvr397xda/image/upload/v1664834364/cube/Free/metalogo_ctuhtw.png" alt="" /></Link>

      
<Share onClick={()=>handleShare()} id='share-btn'/>


      {/* <Helmet helmetData={helmetData}>
  <title>{helmetData.name}</title>
 

</Helmet> */}
     <Helmet
      title={people.name}
      meta={[
        {
          property:'og:image',
          content:gallery[4]
        },
        {
          name: `description`,
          content: people.title,
        },
      ]}
    
    />
    { isLoaded&&<Canvas camera={{ position: [0, 0, 5] }}   gl={{ alpha: true, antialias: true}}  className='canvas-wrap'>
   
    <ambientLight/>

  <PresentationControls polar={[-Math.PI / 2.5, Math.PI / 2.5]} global rotation={[0, 0.3, 0]}  azimuth={[-Infinity, Infinity]}>
      <Suspense fallback={<Loader people={people} />}>

      <Group visible={visible} posY={posY} people={people} opacity={opacity} handleOpenQr={handleOpenQr}/>
      {/* <Earth/> */}
      {/* <Rose/> */}
      {/* <Macbook/> */}
      {/* <VideoCamera/> */}
      {/* <Camera/> */}
      {/* <Keys/> */}
      {/* <House/> */}
      {/* <Japan/> */}
      <Model people={people}/>
    </Suspense>
    </PresentationControls>
    </Canvas>}
    {people.show&&<div style={isTeam ? {top:'20%',transition:'1.5s'}:{top:'200%',transition:'1.5s'}}  className="wrapper">
          <Book people={people}/>
          <img onClick={()=>handleCloseTeam()} id='close' src="https://res.cloudinary.com/dvr397xda/image/upload/v1656101942/cube/cancel_dmukx0.png" alt="" />
    </div>}
         

          <div style={load ?{ zIndex:'100000',opacity:'1'} :{zIndex:'-10',opacity:'0',transition:'2.5s' }} className='load-image'>
                <img src={people.loadImg} alt="" />
          </div>

          <div style={isQR ? {top:'20%',transition:'1.5s',opacity:'1'}:{top:'200%',transition:'1.5s',opacity:'0'}}  className="wrapper">
          <img id='qr' src={people.qrCode} alt="" />
          <h3>长按识别图中二维码</h3>

          <img onClick={()=>handleCloseTeam()} id='close' src="https://res.cloudinary.com/dvr397xda/image/upload/v1656101942/cube/cancel_dmukx0.png" alt="" />
        </div>


        {/* {
            people.isMusic&&<div id='cd-player'>
            <Player  data={people.music} />
    </div>
          } */}
       {isLoaded&&<div className='view-wrap'>
         <img src="https://res.cloudinary.com/dvr397xda/image/upload/v1663183172/cube/basic/view_yc4xlq.png" alt="" />
         <p>
           {people.views}
         </p>
       </div>}
       {/* {
         isLoaded&&<div className='card-like-wrap'>
            {isLiked? <StarFill onClick={()=>handleClickLike()} style={{color:'#e3b80e'}}/>:<Star onClick={()=>handleClickLike()} style={{color:'#e3b80e'}}/>}
         </div>
       } */}

    </div>
  )
}

