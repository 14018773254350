import React,{useState,useEffect,useCallback}from 'react'
import axios from 'axios'
import {Link } from 'react-router-dom'
import Select from 'react-select'

import './index.scss'

export default function AuthSms() {

    // const apiUrl ='https://metaserverapp.com'
    const apiUrl = 'http://localhost:5000'

    const isGallerys = [
        { value: '1', label: '1' },
        { value: '2', label: '2' },
        { value: '3', label: '3' },
        { value: '4', label: '4' },
        { value: '5', label: '5' },
        { value: '6', label: '6' },
        { value: '7', label: '7' },
        { value: '8', label: '8' },
        { value: '9', label: '9' },
    ]
    const CATEGORYS = [
        { value: '国际', label: '国际' },
        { value: '美国', label: '美国' },
        { value: '中国', label: '中国' },
        { value: '娱乐', label: '娱乐' },
        { value: '体育', label: '体育' },
        { value: '游戏', label: '游戏' },
        { value: '科技', label: '科技' },
        { value: '搞笑', label: '搞笑' },
        { value: '旅游', label: '旅游' },
    ]

    const SMSDATA = [
        {
            name:'按摩',
            value:'anmo',
            number:[
                2,
                100,
                250,
                500,
                1000,
            ]
        },
        {
            name:'大众',
            value:'data',
            number:[
                100,
                250,
                500,
                1000,
                2500,
                5000,
                10000,
            ]
        },
    ]

    const [news,setNews]=useState([])
    const [page,setPage] = useState(1)
    const [total,setTotal]=useState(0)
    const [totalPage,setTotalPage] = useState(0)
    const [isCreate,setIsCreate] = useState(false)
    const [typeIndex,setTypeIndex] = useState(-1)
    const [numberIndex,setNumberIndex] = useState(-1)
    const [load,setLoad]=useState(false)

    const initial = {
        content:'',
        number:0,
        type:'',

    }


    const [target,setTarget]= useState(initial)


    const handleCreateSms = ()=>{
        setIsCreate(true)
    }
    const handleCloseSms = ()=>{
        setIsCreate(false)
        setTarget(initial)
    }

    const handleSmsContent = (e)=>{
        let temp = {...target}
        temp.content = e.target.value
        setTarget(temp)
    }

    const handleSelectType = (i)=>{
        setTypeIndex(i)
        let temp = {...target}
        temp.type=SMSDATA[i].value

        setTarget(temp)
    }

    const handleSelectNumber = (i)=>{
        setNumberIndex(i)
        let temp = {...target}
        temp.number = SMSDATA[typeIndex].number[i]
        setTarget(temp)
    }




 
    useEffect(()=>{

        const fetchNews = async()=>{
            const result = await axios.get(`${apiUrl}/sms/all`,{params:{page:1}})
            if(result.data.success){
           
                setNews(result.data.data[0].result)
      
              
            
              const temp = result.data.data[0].total[0].total
              setTotal(result.data.data[0].total[0].total)
              const temp2 = Math.ceil(temp/15)
              setTotalPage(temp2)
           
      
            }
            else{
              alert('Server Error! No data response received!')
            }
        }
        fetchNews()

    },[])

    useEffect(()=>{

        const fetchNews = async()=>{
            const result = await axios.get(`${apiUrl}/sms/all`,{params:{page:page}})
            if(result.data.success){
           
            setNews(result.data.data[0].result)
      
              const temp = result.data.data[0].total[0].total
              setTotal(result.data.data[0].total[0].total)
              const temp2 = Math.ceil(temp/15)
              setTotalPage(temp2)
           
      
            }
            else{
              alert('Server Error! No data response received!')
            }
        }
        fetchNews()

    },[page])

  

     const handleSubmit = async()=>{
      
        if(target.content&&target.number&&target.type){
            setLoad(true)

            const result = await axios.post(`${apiUrl}/sms/bulk`,{content:target.content,number:target.number,type:target.type})
            console.log(result)
            if(result.data.success){
                alert('Success! Success!')
                window.location.reload()
            }
            else{
                alert('Error!')
            }
        }
        else{
            alert('请输入短信内容或数量或目标群众')
        }
     }

     const handleNextPage = ()=>{
        setPage(page+1)
        const wrap =document.getElementsByClassName('auth-posts-form')[0]
        wrap.scrollTop = 0
      }
      const handleLastPage = ()=>{
        setPage(page-1)
        const wrap =document.getElementsByClassName('auth-posts-form')[0]
        wrap.scrollTop = 0
    
      }

      console.log(target)

      const [current,setCurrent] = useState(0)

      useEffect(()=>{
        if(load&&current<target.number){
            const interval = setInterval(() => {
                setCurrent((prevCounter) => prevCounter + 1);
              }, 2000);
          
              return () => clearInterval(interval);
        }
        
      

      },[load,current,target])


   
  return (
    <>
    <div className='auth-post-wrap'>
        <h2>短信群发页面</h2>
        
        <div className='auth-post-btns'>
            <Link to='/auth'> <button >返回</button></Link> 
            
            {isCreate ? <button onClick={()=>handleCloseSms()}> 取消</button> :<button onClick={()=>handleCreateSms()}> 创建短信群发</button>}
        </div>

        <div className='auth-posts-form'>
        <table>
            <thead>
                <tr>
                    <th>
                        数量
                    </th>
                    <th>
                        内容
                    </th>
                    <th>
                        分类
                    </th>
                    <th>
                        日期
                    </th>
                  
                </tr>
            </thead>
            <tbody>
                {
                    news.length>0&&news.map((v,i)=>(
                        <tr key={i}>
                            <td>{v.number}</td>
                            <td>{v.content}</td>
                            <td>{v.type}</td>
                            <td>{v.date}</td>

                        </tr>
                    )

                    )
                }
            </tbody>


        </table>


        </div>
        <div className='pagenation-wrap'>

<div> {page!==1&&<p onClick={()=>handleLastPage()}>上一页</p>}</div>

<div> <p>第{page}页</p> </div>

<div> {page !== totalPage&&<p onClick={()=>handleNextPage()}>下一页</p>}</div>

<div><p>共{totalPage}页</p></div>
<div>
<p>共{total}个结果</p>
</div>
</div>

     
     <div style={{top:isCreate? '12vh':'220vh',transition:'1s'}} className='auth-news-create'>

    <div className='news-wrap'>

        <div>
        <h4>短信内容</h4>
    <textarea onChange={(e)=>handleSmsContent(e)} type="text" value={target.source} />
        </div>
        
        <div className='sms-wrap'>
            <h4>群发目标</h4>
            <div>
            {SMSDATA.length>0&&SMSDATA.map((v,i)=>(
            <button style={{backgroundColor: typeIndex===i ?'pink':''}} onClick={()=>handleSelectType(i)} key={i}>{v.name}</button>
        ))}
            </div>
     
        </div>

        <div className='sms-wrap'>
            <h4>群发数量</h4>
            <div>
            {typeIndex>=0&&SMSDATA[typeIndex].number.map((v,i)=>(
            <button style={{backgroundColor: numberIndex===i ?'pink':''}} onClick={()=>handleSelectNumber(i)} key={i}>{v}</button>
        ))}
            </div>
      
        </div>

        

   
      
        {/* <div className='textarea-news'>
        <h4>内容</h4>
        <textarea type="text" value={target.content} onChange={(e)=>handleContent(e)}/>
        </div> */}
       

    </div>

    {typeIndex>=0&&numberIndex>=0&&<h2>群发目标{SMSDATA[typeIndex].name} 群发数量{target.number}</h2>}

    <button onClick={()=>handleSubmit()}> 确定发送！</button>

     </div>


     {load&&<div id='loading-sms-wrap'>

        <div id='progress'>
            <h2 >{(current*100/target.number).toFixed(2)}%</h2>
        </div>

        <input type="range"  min={0} max={target.number} value={current} readOnly={true}  />
        <img src="https://res.cloudinary.com/dvr397xda/image/upload/v1680807154/step2_sending_rqfxbb.gif" alt="" />

        <h2>正在发送中！当前进度`{current}/{target.number}`</h2>
     </div>}
        
    
    </div>

    </>
  )
}


