import React,{useState,useEffect,useRef,Suspense,useCallback}from 'react'
import { Canvas, useFrame,extend,useThree,useLoader,} from '@react-three/fiber'
import * as THREE from "three";
import {Link} from 'react-router-dom'
import HTMLFlipBook from "react-pageflip";

import Player from '../../components/Player/index'

import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import {useProgress,Html} from '@react-three/drei'
import './index.scss'



function Loader() {
    const { progress } = useProgress()
    return <Html center> <h2 id='loadText'>
          Loading ...{progress.toFixed(0)}%
          <img id='loadImage' src="https://res.cloudinary.com/dvr397xda/image/upload/v1660580851/cube/output-onlinegiftools_rgnpbb.gif" alt="" />
          </h2></Html>
  }

  function Model(){
    const earthMaterial = 'https://res.cloudinary.com/dvr397xda/image/upload/v1650905847/cube/earth/2k_earth_daymap_habyjo.jpg';

    const colorMap = useLoader(THREE.TextureLoader, earthMaterial)
    const mesh = useRef()

    useFrame((state,delta)=>(mesh.current.rotation.y+=0.01))

    return (
        <mesh ref={mesh}>
        <sphereBufferGeometry args={[1.2, 30, 30]} attach="geometry" />
        <meshBasicMaterial  map={colorMap} attach="material" metalness={1} />
      </mesh>
    )

}



  function Book(){

    const onFlip = useCallback((e) => {
      console.log('Current page: ' + e.data);
  }, []);

  const data = [
    {
        name:'星天元宇宙名片',
        title:'团队介绍',
        image:'https://res.cloudinary.com/dvr397xda/image/upload/v1659561205/cube/galaxy_slgsga.png'
    },
    {
        name:'Johnny Chen',
        title:'Founder Ceo',
        image:'https://res.cloudinary.com/dvr397xda/image/upload/v1662139658/cube/example/johnny-FOUNDER_aujgs0.jpg'
    },
    {
        name:'Star',
        title:'UI Designer',
        image:'https://res.cloudinary.com/dvr397xda/image/upload/v1662139658/cube/example/yuxin-DESIGNER_uq5moi.jpg'
    },
    {
        name:'Hao',
        title:'UI Designer',
        image:'https://res.cloudinary.com/dvr397xda/image/upload/v1662139658/cube/example/george-DESIGNER_cenirc.jpg'
    },
    {
        name:'David',
        title:'Marketing',
        image:'https://res.cloudinary.com/dvr397xda/image/upload/v1662139658/cube/example/david-marketing_au30g3.jpg'
    },
    {
        name:'Lily',
        title:'Marketing',
        image:'https://res.cloudinary.com/dvr397xda/image/upload/v1652213427/cube/lily/73931652212308_.pic_ks55ga.jpg'
    },
    {
        name:'Susan',
        title:'Marketing',
        image:'https://res.cloudinary.com/dvr397xda/image/upload/v1662139658/cube/example/susan-marketing_xpblfv.jpg'
    },
    {
        name:'Tom',
        title:'UI Designer',
        image:'https://res.cloudinary.com/dvr397xda/image/upload/v1662139658/cube/example/tom-marketing_dqgger.jpg'
    },
    {
        name:'Shapr',
        title:'Marketing',
        image:'https://res.cloudinary.com/dvr397xda/image/upload/v1662139658/cube/example/shapr-marketing_eocvn2.jpg'
    },
     {
        name:'Paul',
        title:'Marketing',
        image:'https://res.cloudinary.com/dvr397xda/image/upload/v1662139658/cube/example/paul-marketing_ywqmfu.jpg'
    },
    {
        name:'星天元宇宙名片',
        title:'团队介绍',
        image:'https://res.cloudinary.com/dvr397xda/image/upload/v1659561205/cube/galaxy_slgsga.png'
    },
  ]
  
    return (
    <HTMLFlipBook className="wrapper" 
    size='stretch'  width={195}  height={338}        maxShadowOpacity={0.5}
    onFlip={onFlip}
    showCover
   >
    {
        data&&data.map((v,i)=>(
            <div id='page-item' className="demoPage" key={i}>
              
              <h2>
                      {v.name}
                  </h2>
                  {v.title&&<h2>
                      {v.title}
                  </h2>}
                 
               <img src={v.image} alt="" />
                 
            </div>
        ))
    }
  </HTMLFlipBook>
  
  )
  }

  function Box(props) {
    const {position} = props
    const mesh = useRef()
    
  
   
  
   
    // setInterval(handleChangeIndex,1000)
  
    
  
  let texture = useLoader(THREE.TextureLoader,'https://res.cloudinary.com/dvr397xda/image/upload/v1658519035/cube/WechatIMG9330_gm5mez_dpifk5.png')
  // textures = useLoader(TextureLoader,[people.images[0],people.images[1],people.images[2],people.images[3],people.images[4],people.images[5]])
  
  texture.minFilter = texture.magFilter = THREE.LinearFilter;
  
  
  
  texture.format= THREE.RGBAFormat;
  
  
  
  
    
  
    return (
      <>
      <mesh
        onClick={(e)=>{
          e.stopPropagation()
        }}
        ref={mesh}
       position={position}
       
        >
            
        <boxBufferGeometry attach="geometry"  args={[2.5, 2.5, 2.5]}/>
        <meshBasicMaterial opacity={1} transparent={true}    map={texture}  />
         
        
  
      </mesh>
  
  
      </>
    )
  }

extend({ OrbitControls });

const CameraControls = () => {

  const {
    camera,
    gl: { domElement },
  } = useThree();
  const controls = useRef();
  useFrame(() => controls.current.update());
  return (
    <orbitControls
      ref={controls}
      args={[camera, domElement]}
      autoRotate={false}
      enableZoom={true}
      maxDistance={5.5}
      minDistance={2}
    />
  );
};

const Group = ({SELECT,handleOpenQr,handleOpenTeam})=>{

    const mesh = useRef()

    useFrame((state, delta) => {
      
        if(SELECT[0].select){

            mesh.current.rotation.y += 0.001
        }
      
      })

      return(
          <group ref={mesh}>

            <Box position={[0,0,0]}/>
            {  SELECT[1].select&&<Avatar item={SELECT[1]} />}

          {  SELECT[2].select&&<Phone item={SELECT[2]} />}
          {  SELECT[3].select&&<Wechat item={SELECT[3]} handleOpenQr={handleOpenQr}/>}
          {  SELECT[4].select&&<Website item={SELECT[4]} />}
          {  SELECT[5].select&&<Map item={SELECT[5]} />}
          {  SELECT[6].select&&<Email item={SELECT[6]} />}
          {  SELECT[8].select&&<Team item={SELECT[8]} handleOpenTeam={handleOpenTeam}/>}
          {  SELECT[9].select&&<Video item={SELECT[9]} />}
           {  SELECT[7].select&&<Article item={SELECT[7]} />}
           {  SELECT[11].select&&<Gallery item={SELECT[11]} />}

          </group>
      )
}

const Phone = ({item})=>{

    const mesh1 = useRef()
   
  
    const handleClick=()=>{
      window.open(`tel:+1${item.phone}`,'_self')
    }
    let texture = useLoader(THREE.TextureLoader,item.img)
  
    return (
     
      <mesh position={item.position} rotation={item.rotaion} onClick={(e)=>{ handleClick();     e.stopPropagation()
  }}   ref={mesh1}>
          <planeGeometry  attach="geometry"  args={[0.8,0.8]}/>
          <meshStandardMaterial map={texture}  side={THREE.DoubleSide}   />
      </mesh>
  
    )
  }

  const Wechat = ({item,handleOpenQr})=>{

    const mesh1 = useRef()
   
  
  
    let texture = useLoader(THREE.TextureLoader,item.img)
  
    return (
     
      <mesh position={item.position} rotation={item.rotaion} onClick={(e)=>{ handleOpenQr();     e.stopPropagation()
  }}   ref={mesh1}>
          <planeGeometry  attach="geometry"  args={[0.8,0.8]}/>
          <meshStandardMaterial map={texture}  side={THREE.DoubleSide}   />
      </mesh>
  
    )
  }

  const Team = ({item,handleOpenTeam})=>{

    const mesh1 = useRef()
   
  
  
    let texture = useLoader(THREE.TextureLoader,item.img)
  
    return (
     
      <mesh position={item.position} rotation={item.rotaion} onClick={(e)=>{ handleOpenTeam();     e.stopPropagation()
  }}   ref={mesh1}>
          <planeGeometry  attach="geometry"  args={[1.1,1.1]}/>
          <meshStandardMaterial map={texture}  side={THREE.DoubleSide}   />
      </mesh>
  
    )
  }

  const Website = ({item})=>{

    const mesh1 = useRef()
   
  
    const handleClick=()=>{
      window.open(`${item.website}`,'_self')
    }
    let texture = useLoader(THREE.TextureLoader,item.img)
  
    return (
     
      <mesh position={item.position} rotation={item.rotaion} onClick={(e)=>{ handleClick();     e.stopPropagation()
  }}   ref={mesh1}>
          <planeGeometry  attach="geometry"  args={[0.8,0.8]}/>
          <meshStandardMaterial map={texture}  side={THREE.DoubleSide}   />
      </mesh>
  
    )
  }

  const Video = ({item})=>{

    const mesh1 = useRef()
   
  
    const handleClick=()=>{
      window.open(`${item.website}`,'_self')
    }
    let texture = useLoader(THREE.TextureLoader,item.img)
  
    return (
     
      <mesh position={item.position} rotation={item.rotaion} onClick={(e)=>{ handleClick();     e.stopPropagation()
  }}   ref={mesh1}>
          <planeGeometry  attach="geometry"  args={[1.1,1.1]}/>
          <meshStandardMaterial map={texture}  side={THREE.DoubleSide}   />
      </mesh>
  
    )
  }

  const Article = ({item})=>{

    const mesh1 = useRef()
   
    const handleClick=()=>{
        window.open(`${item.website}`,'_self')
      }
   
    let texture = useLoader(THREE.TextureLoader,item.img)
  
    return (
     
      <mesh position={item.position} rotation={item.rotaion} onClick={(e)=>{ handleClick();     e.stopPropagation()
      }}  ref={mesh1}>
          <planeGeometry  attach="geometry"  args={[2.3,2.3]}/>
          <meshStandardMaterial map={texture}  side={THREE.DoubleSide}   />
      </mesh>
  
    )
  }

  const Map = ({item})=>{

    const mesh1 = useRef()
   
  
    const handleClick=()=>{
      window.open(`${item.website}`,'_self')
    }
    let texture = useLoader(THREE.TextureLoader,item.img)
  
    return (
     
      <mesh position={item.position} rotation={item.rotaion} onClick={(e)=>{ handleClick();     e.stopPropagation()
  }}   ref={mesh1}>
          <planeGeometry  attach="geometry"  args={[0.8,0.8]}/>
          <meshStandardMaterial map={texture}  side={THREE.DoubleSide}   />
      </mesh>
  
    )
  }

  const Email = ({item})=>{

    const mesh1 = useRef()
   
  
    const handleClick=()=>{
      window.open(`mailto:+${item.email}`,'_self')
    }
    let texture = useLoader(THREE.TextureLoader,item.img)
  
    return (
     
      <mesh position={item.position} rotation={item.rotaion} onClick={(e)=>{ handleClick();     e.stopPropagation()
  }}   ref={mesh1}>
          <planeGeometry  attach="geometry"  args={[0.8,0.8]}/>
          <meshStandardMaterial map={texture}  side={THREE.DoubleSide}   />
      </mesh>
  
    )
  }


  const Avatar = ({item})=>{

    const mesh1 = useRef()
   
  
    
    let texture = useLoader(THREE.TextureLoader,item.img)
  
    return (
     
      <mesh position={item.position} rotation={item.rotaion} onClick={(e)=>{     e.stopPropagation()
  }}   ref={mesh1}>
          <planeGeometry  attach="geometry"  args={[1.5,1.5]}/>
          <meshStandardMaterial map={texture}  side={THREE.DoubleSide}   />
      </mesh>
  
    )
  }

  const Gallery = ({item})=>{

    const mesh1 = useRef()
    const [index,setIndex] = useState(0)

    const gallery=[
        'https://res.cloudinary.com/dvr397xda/image/upload/v1660934015/cube/example/man_1_go3zgb.png',
        'https://res.cloudinary.com/dvr397xda/image/upload/v1660934015/cube/example/woman_lmyvd2.png',
        'https://res.cloudinary.com/dvr397xda/image/upload/v1660934015/cube/example/man_d6rnb4.png',
        'https://res.cloudinary.com/dvr397xda/image/upload/v1660934014/cube/example/profile_agwr2l.png',
        'https://res.cloudinary.com/dvr397xda/image/upload/v1660934015/cube/example/gamer_hpk51z.png'
    ]

    useEffect(() => {
        const interval = setInterval(() => {
    
            if(index ===gallery.length-1){
                setIndex(0)
            }
            else{
                setIndex(index+1)
            }
        }, 2500);
        return () => clearInterval(interval);
      }, [index,gallery.length]);

  
    
      let texture;
      texture =useLoader(THREE.TextureLoader,gallery[index])
      texture.format= THREE.RGBAFormat;
      texture.minFilter = THREE.LinearFilter;
      texture.magFilter = THREE.LinearFilter;
   
  
    
  
  
    return (
     
      <mesh position={item.position} rotation={item.rotaion} onClick={(e)=>{     e.stopPropagation()
  }}   ref={mesh1}>
          <boxBufferGeometry  attach="geometry"  args={[0.01,2.4,2.4]}/>
          <meshStandardMaterial map={texture}  side={THREE.DoubleSide}   />
      </mesh>
  
    )
  }




const SELECT = [
    {
        name:'旋转',
        select:true,
    },
    {
        name:'头像',
        select:true,
        img:'https://res.cloudinary.com/dvr397xda/image/upload/v1660919293/cube/example/500x500%E8%81%8C%E4%BD%8D_skadku.png',
        position:[-0.4,0.4,1.3],
        rotation:[0,Math.PI * 2.5,0],
        
    },
    {
        name:'电话',
        select:true,
        img:'https://res.cloudinary.com/dvr397xda/image/upload/v1660919293/cube/example/500x500%E7%94%B5%E8%AF%9D_ny6enb.png',
        position:[0.8,0.8,1.3],
        rotation:[0,Math.PI * 2.5,0],
        phone:'3015200518'
    },
    {
        name:'微信',
        select:true,
        img:'https://res.cloudinary.com/dvr397xda/image/upload/v1660919293/cube/example/500x500%E5%BE%AE%E4%BF%A1_aahq3j.png',
        position:[-0.8,-0.8,1.3],
        rotation:[0,Math.PI * 2.5,0],
        website:'https://xingtianstudio.com'
    },
    {
        name:'网站',
        select:true,
       
        img:'https://res.cloudinary.com/dvr397xda/image/upload/v1662144372/cube/example/WechatIMG10423_mrarjh.png',
        position:[0.8,0,1.3],
        rotation:[0,Math.PI * 2.5,0],
        website:'https://meta11w.com/information'
    },
    {
        name:'地址',
        select:true,
        img:'https://res.cloudinary.com/dvr397xda/image/upload/v1660919293/cube/example/500x500%E5%9C%B0%E5%9D%80_okwdwv.png',
        position:[0,-0.8,1.3],
        rotation:[0,Math.PI * 2.5,0],
        website:'https://www.google.com/maps/place/43-06+Main+St,+Flushing,+NY+11355/@40.7522664,-73.8315613,17z/data=!3m1!4b1!4m5!3m4!1s0x89c2606cd640cb53:0x141db878e9815fe!8m2!3d40.7522665!4d-73.8270766'
    },
    {
        name:'邮箱',
        select:true,
        img:'https://res.cloudinary.com/dvr397xda/image/upload/v1660919293/cube/example/500x500%E9%82%AE%E7%AE%B1_hrpxt5.png',
        position:[0.8,-0.8,1.3],
        rotation:[0,Math.PI * 2.5,0],
        email:'liu.shany2018@gmail.com'
    },
     {
        name:'文案',
        select:true,
        img:'https://res.cloudinary.com/dvr397xda/image/upload/v1662144372/cube/example/WechatIMG10424_u0cllk.png',
        position:[0,0,-1.28],
        rotation:[0,Math.PI * 2.5,0],
        website:'https://xingtianstudio.com/'
    },
    {
        name:'团队',
        select:true,
        img:'https://res.cloudinary.com/dvr397xda/image/upload/v1660936350/cube/example/WechatIMG10022_b5clob.png',
        position:[0.6,0.6,-1.31],
        rotation:[0,Math.PI * 2.5,0],
    },
    {
        name:'视频',
        select:true,
        img:'https://res.cloudinary.com/dvr397xda/image/upload/v1660936350/cube/example/WechatIMG10023_oyrcwh.png',
        position:[-0.6,0.6,-1.31],
        rotation:[0,Math.PI * 2.5,0],
        website:'https://res.cloudinary.com/dvr397xda/video/upload/v1663277786/cube/example/1663277712119328_leszc3.mp4'
    },
   
    {
        name:'音乐',
        select:true,
    },
    {
        name:'轮播图',
        select:true,
        position:[1.3,0,0],
        rotation:[0,0,Math.PI * 2.5],
    },
    
  
    {
        name:'背景',
        select:true,
    },
    {
        name:'模型',
        select:true,
    },
]


export default function Example() {

    const bg = 'https://res.cloudinary.com/dvr397xda/image/upload/v1658594211/cube/01Techfix-print-superJumbo_qdib0g.gif'

    const [menu,setMenu]=useState(SELECT)

    const handleChangeSelect =(i)=>{
        let temp=[...menu]
        temp[i].select=!menu[i].select
        setMenu(temp)
    }

    const [isTeam,setIsTeam] = useState(false)
  const [isQR,setIsQR] = useState(false)


    const handleOpenQr = ()=>{
    setIsQR(true)
}

    const handleOpenTeam = ()=>{
    setIsTeam(true)
}
const handleCloseTeam = ()=>{
    setIsTeam(false)
}

    const handleCloseQr =()=>{
        setIsQR(false)
    }

    const music = [{
        album:'周杰伦',
        name:'简单爱',
        track:'Jay', 
        url:'https://res.cloudinary.com/dvr397xda/video/upload/v1660937875/music/xia-tian-summer-wan-zheng-ban-mv_fkqhbv.mp3', 
        image:'https://res.cloudinary.com/dvr397xda/image/upload/v1660938134/music/cd-player_1_nxixox.png'
    }]



  return (
    <div className="container-card" style={SELECT[12].select?{backgroundImage:`url(${bg})`} : null}>
        
        <h2 className='example-title'>
            元宇宙名片示例
        </h2>
        <div className='select-wrap'>
            <div>
                <table>
                    <thead>
                        <tr>
                            {
                                menu&&menu.map((v,i)=>(
                                    <th key={i}>{v.name}</th>
                                ))
                            }
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            {
                                menu&&menu.map((v,i)=>(
                                    <td key={i}>
<input onChange={()=>handleChangeSelect(i)}  type="checkbox" checked={v.select}  />
                                    </td>
                                ))
                            }
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

        <Canvas  gl={{ alpha: true, antialias: true}}  className='canvas-wrap'>
    <CameraControls />
    <ambientLight  />
        {/* <pointLight position={[3, 3, 3]} intensity={1} color='#f0f0f0'/>
        <pointLight position={[3, -3, 3]} intensity={1} color='#f0f0f0'/>

        <pointLight position={[-3, 3, 3]} intensity={1} color='#f0f0f0'/>

        <pointLight position={[3, 3, -3]} intensity={1} color='#f0f0f0'/>
        <pointLight position={[-3, -3, -3]} intensity={1} color='#f0f0f0'/>
        <pointLight position={[-3, -3, 3]} intensity={1} color='#f0f0f0'/> */}

      <Suspense fallback={<Loader/>}>
   
  

      
        
    
        
    
      {  SELECT[13].select&&<Model />}
      {/* <Cube position={[0, 0, 0]} /> */}
      
      <Group SELECT={SELECT} handleOpenQr={handleOpenQr} handleOpenTeam={handleOpenTeam}/>
    

      
    
    </Suspense>

    </Canvas>


    

          <div style={isQR ? {top:'30%',transition:'1.5s'}:{top:'200%',transition:'1.5s'}}  className="wrapper">
          <img id='qr' src='https://res.cloudinary.com/dvr397xda/image/upload/v1659997040/cube/basic/lily/WechatIMG124_l0rvpj.jpg' alt="" />
          <h3>长按识别图中二维码</h3>

          <img onClick={()=>handleCloseQr()} id='close' src="https://res.cloudinary.com/dvr397xda/image/upload/v1656101942/cube/cancel_dmukx0.png" alt="" />
        </div>

        <div style={isTeam ? {top:'30%',transition:'1.5s'}:{top:'200%',transition:'1.5s'}}  className="wrapper">
          <Book />
          <img onClick={()=>handleCloseTeam()} id='close' src="https://res.cloudinary.com/dvr397xda/image/upload/v1656101942/cube/cancel_dmukx0.png" alt="" />
    </div>


        {
            SELECT[10].select&&<div id='cd-player'>
            <Player  data={music} />
    </div>
        }

        <div id='meta-btn'>
            <Link to='/meta'>
            <img src="https://res.cloudinary.com/dvr397xda/image/upload/v1659561205/cube/galaxy_slgsga.png" alt="" />
            </Link>
        </div>


    </div>
  )
}




