import React, { useState,useEffect } from 'react'
import axios from 'axios'
import {Link,useNavigate} from 'react-router-dom'
import './index.scss'
import {
   Camera
  } from "react-bootstrap-icons";


  export default function Nav() {
    const token = window.localStorage.getItem('token')
    const userId = window.localStorage.getItem('userId')
    const apiUrl ='https://metaserverapp.com'
    const [user,setUser] = useState({})
    const navigate = useNavigate();

    useEffect(() => {
        const fetchUser = async()=>{
            const result = await axios.get(`${apiUrl}/auth/user/id`,
            {params:{id:userId}}
            )
            console.log(result.data.data)
            setUser(result.data.data)
        }
        fetchUser()

    },[userId])

    // const handleLogout = ()=>{
    //     window.localStorage.setItem('userId','')
    //     window.localStorage.setItem('token','')
    //     navigate("/user/login");
    // }

    const handleRemove = ()=>{
        window.localStorage.removeItem('userId');
        window.localStorage.removeItem('token');
    }

    return (
    
        <div className="nav-container">
            <div className='nav-left'>
                <Link to='/'>
                
                <img src="https://res.cloudinary.com/dvr397xda/image/upload/v1666123203/cube/WechatIMG12253_ro3cie.png" alt="" />
                <h2>元生活</h2>
                </Link>
            </div>

            <div className='nav-right'>
<Link to='/user/post/new'><Camera/>发布</Link>
               

            </div>
            {/* <button onClick={()=>handleRemove()}>Remove</button> */}

        </div>
     
    );
  }


