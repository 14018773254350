const data = [

    { 
        id:'1',
       
        images:[
            'https://res.cloudinary.com/dvr397xda/image/upload/v1663962180/cube/gallery/meixian/WechatIMG11154_tz7idw.jpg',
            'https://res.cloudinary.com/dvr397xda/image/upload/v1663962179/cube/gallery/meixian/WechatIMG11149_ogssz3.jpg',
            'https://res.cloudinary.com/dvr397xda/image/upload/v1663962176/cube/gallery/meixian/WechatIMG11145_uwwlt2.jpg',
            'https://res.cloudinary.com/dvr397xda/image/upload/v1663962176/cube/gallery/meixian/WechatIMG11151_heqewp.jpg',
            'https://res.cloudinary.com/dvr397xda/image/upload/v1663962173/cube/gallery/meixian/WechatIMG11152_nmiyle.jpg',
            'https://res.cloudinary.com/dvr397xda/image/upload/v1663962173/cube/gallery/meixian/WechatIMG11150_bl91kh.jpg',
            'https://res.cloudinary.com/dvr397xda/image/upload/v1663962171/cube/gallery/meixian/WechatIMG11148_flfcmx.jpg',
            'https://res.cloudinary.com/dvr397xda/image/upload/v1663962171/cube/gallery/meixian/WechatIMG11160_l28tin.jpg',
            'https://res.cloudinary.com/dvr397xda/image/upload/v1663962168/cube/gallery/meixian/WechatIMG11156_s4raao.jpg',
            'https://res.cloudinary.com/dvr397xda/image/upload/v1663962166/cube/gallery/meixian/WechatIMG11158_jgtlam.jpg',
            'https://res.cloudinary.com/dvr397xda/image/upload/v1663962166/cube/gallery/meixian/WechatIMG11157_n1gqvy.jpg',
            'https://res.cloudinary.com/dvr397xda/image/upload/v1663962165/cube/gallery/meixian/WechatIMG11159_uehanm.jpg',
            'https://res.cloudinary.com/dvr397xda/image/upload/v1663962164/cube/gallery/meixian/WechatIMG11144_cbroyy.jpg',
            'https://res.cloudinary.com/dvr397xda/image/upload/v1663962164/cube/gallery/meixian/WechatIMG11146_xuvwhj.jpg',
            'https://res.cloudinary.com/dvr397xda/image/upload/v1663962161/cube/gallery/meixian/WechatIMG11147_uavruf.jpg',
            
    ],

    },

   
  




]

const fetchGallery = ()=>{
    return data;
}


export default fetchGallery