import React,{useState,useEffect} from 'react'
import {Link} from 'react-router-dom'
import Login from './Login/index'
import Admin from './Admin/index'

import './index.scss'

export default function Auth() {

    const [token,setToken] = useState(true)

  return (
    <div className='auth-container'>
        <h2>元宇宙科技后台</h2>

        {
            token ? <Admin/> : <Login setToken={setToken}/>
        }

    </div>
  )
}
