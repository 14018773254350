import React,{useState,useEffect} from 'react'
import {Link} from 'react-router-dom'
import HTMLFlipBook from "react-pageflip";
import './book.scss'
import fetchData from '../../data'

let data = fetchData()
const first = {
    name:'元宇宙名片集',
    images:[
        '',
        '',
        '',
        '',
        'https://res.cloudinary.com/dvr397xda/image/upload/v1655323913/cube/WechatIMG8430_xf24k1.png',
    ]
}
const last = {

}
data.unshift(first)
data.push(first)

export default function BookPage() {

   
    console.log(data)
  return (
      <div className="book-page">

   
    <HTMLFlipBook           maxShadowOpacity={0.5}
 showCover width={180} height={300}>
    
    {
        data&&data.map((v,i)=>(
            <div id='page-item' className="demoPage" key={i}>
                <h2>
                    {v.name}
                </h2>
                {v.title&&<h2>
                    {v.title}
                </h2>}
                {v.tel&&<h3>
                    Tel: <a href={`tel:+1${v.tel}`}>{v.tel}</a> 
                </h3>}
               {(i === 0 || i ===data.length-1) ?<img src={v.images[4]} alt="" />
                : 
<Link to={`/profile/${v.id}`}>
               
               <img src={v.images[4]} alt="" />
                  </Link>
            }
            </div>
        ))
    }
  </HTMLFlipBook>
  </div>
  )
}
