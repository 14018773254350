import React,{useState,useEffect} from 'react'
import Nav from '../../components/Nav/index'
import Footer from '../../components/Footer'
import fetchLocation from '../../locations'
import fetchCategory from '../../category'
import {
  XSquare,
  FileImage,
  Calendar2,
  Search,
    BadgeAd
} from "react-bootstrap-icons";
import axios from "axios";
import { Helmet } from 'react-helmet';
import {Link} from 'react-router-dom'


import './index.scss'

export default function HomePage() {

  const apiUrl ='https://metaserverapp.com'


  const LOCATIONS = fetchLocation()
  const CATEGORY = fetchCategory()


  const [currentCity,setCurrentCity] = useState('')
  const [city,setCity] = useState(LOCATIONS[0].location)
  const [cityIndex,setCityIndex]=useState(-1)
  const [category,setCategory] = useState('')
  const [categoryIndex,setCategoryIndex]=useState(-1)
  const [posts,setPosts]=useState([])
  const [page,setPage] = useState(1)
  const [total,setTotal]=useState(0)
  const [openPost,setOpenPost] = useState(false)
  const [currentPostIndex,setCurrentPostIndex]=useState(-1)
  const [totalPage,setTotalPage] = useState(0)
  const [keyword,setKeyword]=useState('')
  const [isSearch,setIsSearch] = useState(false)
  const [loading,setLoading]= useState('载入中...')
  const [cards,setCards] = useState([])

  useEffect(() => {
    const fetchData = async () => {
      const data = await axios.get(`${apiUrl}/basic/all`)
      // console.log(data);
      if(data.data.success){
        setCards(data.data.data)
    
      }
    }
    fetchData()

  },[])
  console.log(cards)

  useEffect(() => {
    const fetchAllPost = async()=>{
      const result =await axios.get(`${apiUrl}/post`,{params:{page:1}})
      const result2 =await axios.get(`${apiUrl}/post/ad`,{params:{page:1}})
      if(result.data.success){
        if(result2.data.success){
          let temp3 = result2.data.data[0].result
          setPosts(temp3.concat(result.data.data[0].result))
        }
        else{
          setPosts(result.data.data[0].result)

        }
      
        const temp = result.data.data[0].total[0].total
        setTotal(result.data.data[0].total[0].total)
        const temp2 = Math.ceil(temp/50)
        setTotalPage(temp2)
    

      }
      else{
        alert('Server Error! No data response received!')
        setLoading('无相关帖子')
      }
    }
    fetchAllPost()
    

  },[])


  

  const handleOpenPost=async(v,i)=>{

    console.log(v,i)


    if(openPost){
      if(i!==currentPostIndex){
        setCurrentPostIndex(i)
        console.log('success')
        await axios.post(`${apiUrl}/post/view`,{postId:v._id})
      }
      else{
        setOpenPost(!openPost)
        setCurrentPostIndex(-1)
      }
    }
    else{
      console.log('success')
      await axios.post(`${apiUrl}/post/view`,{postId:v._id})

      setCurrentPostIndex(i)
      setOpenPost(!openPost)
    }

  }





  

  const handleCity=(v,i)=>{
    setCurrentCity(v.value)
    setCityIndex(i)
  }
 
  const handleRemoveCity=()=>{
    setCurrentCity('')
    setCityIndex('')
  }

  const handleCategory = (v,i)=>{
    setCategory(v.value)
    setCategoryIndex(i)
    console.log(v,i)
    setIsSearch(false)
    setKeyword('')

  }

  const handleRemoveCategory = ()=>{
    setCategory('')
    setCategoryIndex(-1)
  }

 
  const handleNextPage = ()=>{
    setPage(page+1)
    const wrap =document.getElementsByClassName('home-container')[0]
    console.log(wrap);
    wrap.scrollTop = 0
  }
  const handleLastPage = ()=>{
    setPage(page-1)
    const wrap =document.getElementsByClassName('home-container')[0]
    console.log(wrap);
    wrap.scrollTop = 0

  }
  const handleKeyword= (e)=>{
    setKeyword(e.target.value)
    e.target.addEventListener("keyup", (event) => {
      if (event.keyCode === 13) {
        handleSearch()
      }
    })
  }
  const handleSearch=async()=>{

    setIsSearch(true)
  }

  const handleCancelSearch = ()=>{
    setIsSearch(false)
    setKeyword('')
  }

  useEffect(()=>{
    if(!category&&!isSearch){


      if(currentCity){
        const fetchAllPost = async()=>{
          const result =await axios.get(`${apiUrl}/post/city`,{params:{page:page,city:currentCity,location:'纽约'}},)
          const result2 =await axios.get(`${apiUrl}/post/ad`,{params:{page:page}})
          console.log(result)
          if(result.data.success){
            if(result2.data.total>0){
              let temp3 = result2.data.data[0].result
              setPosts(temp3.concat(result.data.data[0].result))
            }
            else{
              setPosts(result.data.data[0].result)
    
            }
          
            const temp = result.data.data[0].total[0].total
            setTotal(result.data.data[0].total[0].total)
            const temp2 = Math.ceil(temp/50)
            setTotalPage(temp2)
         
    
          }
          else{
            alert('Server Error! No data response received!')
            setLoading('无相关帖子')
          }
        }
        fetchAllPost()

      }
      else{
        const fetchAllPost = async()=>{
          const result =await axios.get(`${apiUrl}/post/`,{params:{page:page}},)
          const result2 =await axios.get(`${apiUrl}/post/ad`,{params:{page:page}})
          console.log(result.data)
          if(result.data.success){
            if(result2.data.total>0){
              let temp3 = result2.data.data[0].result
              setPosts(temp3.concat(result.data.data[0].result))
            }
            else{
              setPosts(result.data.data[0].result)
    
            }
          
            const temp = result.data.data[0].total[0].total
            setTotal(result.data.data[0].total[0].total)
            const temp2 = Math.ceil(temp/50)
            setTotalPage(temp2)
         
    
          }
          else{
            alert('Server Error! No data response received!')
            setLoading('无相关帖子')
          }
        }
        fetchAllPost()
      }

    }
    else if(isSearch){
      const fetchAllPost = async()=>{
        const result =await axios.get(`${apiUrl}/post/keyword`,{params:{page:page,keyword:keyword}},)
     
        console.log(result)
        if(result.data.success){
          
            setPosts(result.data.data[0].result)
  
          
        
          const temp = result.data.data[0].total[0].total
          setTotal(result.data.data[0].total[0].total)
          const temp2 = Math.ceil(temp/50)
          setTotalPage(temp2)
         
  
        }
        else{
          alert('Server Error! No data response received!')
          setLoading('无相关帖子')
        }
      }
      fetchAllPost()

    }
    else{

      if(currentCity){
        const fetchAllPost = async()=>{
          const result =await axios.get(`${apiUrl}/post/city/category`,{params:{page:page,city:currentCity,location:'纽约',category:category}},)
          const result2 =await axios.get(`${apiUrl}/post/ad`,{params:{page:page}})
          console.log(result)
          if(result.data.success){
            if(result2.data.total>0){
              let temp3 = result2.data.data[0].result
              setPosts(temp3.concat(result.data.data[0].result))
            }
            else{
              setPosts(result.data.data[0].result)
    
            }
          
            const temp = result.data.data[0].total[0].total
            setTotal(result.data.data[0].total[0].total)
            const temp2 = Math.ceil(temp/50)
            setTotalPage(temp2)
           
    
          }
          else{
            alert('Server Error! No data response received!')
            setLoading('无相关帖子')
          }
        }
        fetchAllPost()

      }
      else{
        console.log(10);
        const fetchAllPost = async()=>{
          const result =await axios.get(`${apiUrl}/post/category`,{params:{page:page,category:category}},)
          const result2 =await axios.get(`${apiUrl}/post/ad`,{params:{page:page}})
          console.log(result.data)
          if(result.data.success){
            if(result2.data.total>0){
              let temp3 = result2.data.data[0].result
              setPosts(temp3.concat(result.data.data[0].result))
            }
            else{
              setPosts(result.data.data[0].result)
    
            }
          
            const temp = result.data.data[0].total[0].total
            setTotal(result.data.data[0].total[0].total)
            const temp2 = Math.ceil(temp/50)
            setTotalPage(temp2)
         
    
          }
          else{
            alert('Server Error! No data response received!')
            setLoading('无相关帖子')
          }
        }
        fetchAllPost()
      }

    }






  },[page,currentCity,category,isSearch,keyword])

  const handleClickCard = (v) => {
    window.open(`https://meta11w.com/cards/pro/${v.id}`,'_self')

  }

  return (

    <div className='home-wrap'>
        <Helmet
      title={'元生活'}
      meta={[
        {
          property:'og:image',
          content:'https://res.cloudinary.com/dvr397xda/image/upload/v1674853518/logo/WechatIMG15357_kp2gvq.png'
        },
        {
          name: '元生活',
          content: '美国华人信息交流分享平台',
        },
      ]}
    
    />
        <Nav/>
        <div className='home-scroll'>
        
       
        <div className='home-city-wrap'>
          <div>
          {
              city.length>0&&city.map((v,i)=>(
                (i===cityIndex ?<button style={{borderBottom:'2px solid #de2c2c'}} key={i} onClick={()=>handleRemoveCity()}>
                {v.value}

              </button> :
              <button  key={i} onClick={()=>handleCity(v,i)}>
              {v.value}
            </button>)
              ))
            }
          </div>
          
        </div>
        

      
        <div className="home-category-wrap">
      
          <div>

          {
            CATEGORY&&CATEGORY.map((v,i)=>(
              (i===categoryIndex?
                <button style={{borderBottom:'2px solid #de2c2c'}}  onClick={()=>handleRemoveCategory()}  key={i}>
                {v.value}
                {/* <XSquare/> */}
              </button>
              :<button onClick={()=>handleCategory(v,i)}   key={i}>
               {v.value}
              </button>)
            ))
          }

          </div>
        
        
          </div>
        </div>


        <div className="home-container-mid">

          <div className='slide-container'>
          <div className='slider'>
          <div className='slide-track' >
          <div className='slide' >
                </div>
            {
              cards.length > 0 && cards.map((v,i)=>(
                <div onClick={()=>handleClickCard(v)} className='slide' key={i}>
                    <img width='80'  src={v.images[4]} alt="" />
                    <p>{v.name} </p>
                    {/* <p>{v.category}</p> */}
                </div>
              ))
            }
            
           </div>

          </div>
          </div>
         
          <Link to='/recommand'><h3>更多3D名片</h3></Link>

        
        </div>


        <div className='home-container'>
     
      

        <div className="home-post-wrap">

          <div className='home-post-container'>
            {
              posts.length>0?posts.map((v,i)=>(

               ( i===currentPostIndex ?  
                <div onClick={()=>handleOpenPost(v,i)} key={i} className="home-post-item">
                  <div className='home-post-top2'>
                  <h3>{v.isAd&&<BadgeAd/>}{v.title}</h3>
                  
                  <p>点击量:{v.views}</p>
                
                  </div>
                  <div className='home-post-mid'>

                  <p>{v.content}</p>
                  {v.images[0]&&<div className='home-post-mid-img'>
                    <div >
                    {v.images.map((v,i)=>(
                          <img key={i} src={v} alt="" />
                        ))}
                    </div>
                      
                    </div>}
                    <div >
                    <p>分类:{v.category}</p>
                  <p>电话: <a onClick={(e)=>e.stopPropagation(()=>handleOpenPost(v,i))} href={`tel:+1${v.tel}`}>{v.tel}</a>   </p>
                  </div>
                  </div>

                  <div className='home-post-bottom'>
                    <div className='home-post-bottom-left'>
                      {v.location&&<p>{v.location}</p>}
                      {v.city&&<p>/{v.city}</p>}
                    </div>
                    <div className='home-post-bottom-right'>
                      <  Calendar2/>
                      <p>{v.date.substring(0,10)}</p>

                    </div>
                  </div>
                </div>
                :
               <div onClick={()=>handleOpenPost(v,i)} key={i} className="home-post-item">
                  <div className='home-post-top'>
                  <h3>{v.isAd&&<BadgeAd/>}{v.title.length>13 ? v.title.substring(0,13)+'...' : v.title}</h3>
                  
                  {v.images[0]&&<div>
                    <  FileImage/>
                    </div>}

                  <p>点击量:{v.views}</p>
                
                  </div>
                  <div className='home-post-mid'>

                  <p>{v.content.length>35 ? v.content.substring(0,35)+'...' : v.content}</p>
               
                  </div>

                  <div className='home-post-bottom'>
                    <div className='home-post-bottom-left'>
                      {v.location&&<p>{v.location}</p>}
                      {v.city&&<p>/{v.city}</p>}
                    </div>
                    <div className='home-post-bottom-right'>
                      <  Calendar2/>
                      <p>{v.date.substring(0,10)}</p>

                    </div>
                  </div>
                </div>)
              )):
              <h2>{posts.length>0 ? loading : '暂无帖子' }</h2>
            }

          </div>

          <div>

          </div>



        </div>

        </div>

    
        <div className='pagenation-wrap'>

          <div> {page!==1&&<p onClick={()=>handleLastPage()}>上一页</p>}</div>

          <div> <p>第{page}页</p> </div>

          <div> {page !== totalPage&&<p onClick={()=>handleNextPage()}>下一页</p>}</div>

          <div><p>共{totalPage}页</p></div>
          <div>
          <p>共{total}个结果</p>
          </div>
        </div>

        <Footer/>

    </div>
  )
}
