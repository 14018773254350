import React,{useRef}from 'react'
import { Canvas,extend,useThree,useFrame } from '@react-three/fiber'
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import './index.scss'
import Plane from '../../components/Plane/index'

extend({ OrbitControls });

const CameraControls = () => {
    // Get a reference to the Three.js Camera, and the canvas html element.
    // We need these to setup the OrbitControls class.
    // https://threejs.org/docs/#examples/en/controls/OrbitControls
  
    const {
      camera,
      gl: { domElement },
    } = useThree();
  
    // Ref to the controls, so that we can update them on every frame with useFrame
    const controls = useRef();
    useFrame(() => controls.current.update());
    return (
      <orbitControls
        ref={controls}
        args={[camera, domElement]}
        enableZoom={true}
      />
    );
  };


export default function Test() {
   

  return (

    
    <Canvas className='canvas-wrap'>
        <CameraControls />
        <ambientLight />

        <Plane rotateZ={Math.PI * 90}   position={[0,0,0]} color='red'  />
        

    </Canvas>

  )
}
