import React,{useState,useEffect,useRef,Suspense}from 'react'
import { Canvas, useFrame,extend,useThree,useLoader,} from '@react-three/fiber'
import * as THREE from "three";
import {useParams} from 'react-router-dom'
import Player from '../../components/Player/index'

import fetchWedding from '../../wedding'
import {OrbitControls,useProgress,Html,Text3D,Center} from '@react-three/drei'

import './index.scss'




function Loader() {
    const { progress } = useProgress()
    return <Html center> <h2 id='loadText'>
          Loading ...{progress.toFixed(0)}%
          <img id='loadImage' src="https://res.cloudinary.com/dvr397xda/image/upload/v1659635547/cube/hunqing/loader_wqff7g.gif" alt="" />
          </h2></Html>
  }


  function Box({img,position,isPhone}) {
    const mesh = useRef()
  const texture = useLoader(THREE.TextureLoader,img)
  texture.minFilter = texture.magFilter = THREE.LinearFilter;
  texture.format= THREE.RGBAFormat;
    return (
      <>
      <mesh
        onClick={(e)=>{
          e.stopPropagation()
        }}
        ref={mesh}
       position={position}
        >   
        <planeGeometry attach="geometry"  args={isPhone ?[3,2]:[6,4]}/>
          <meshBasicMaterial map={texture} side={THREE.DoubleSide} />
      </mesh>
      </>
    )
  }

  function Box2({img,position,isPhone}) {
    const mesh = useRef()
  const texture = useLoader(THREE.TextureLoader,img)
  texture.minFilter = texture.magFilter = THREE.LinearFilter;
  texture.format= THREE.RGBAFormat;
    return (
      <>
      <mesh
        onClick={(e)=>{
          e.stopPropagation()
        }}
        ref={mesh}
       position={position}
        >   
        <planeGeometry attach="geometry"  args={isPhone ?[3,4.5]:[3.6,5.4]}/>
          <meshBasicMaterial map={texture} side={THREE.DoubleSide} />
      </mesh>
      </>
    )
  }

  function Frame({position,isPhone}) {
    const mesh = useRef()
    const img='https://res.cloudinary.com/dvr397xda/image/upload/v1661805249/cube/hunqing/WechatIMG10302_kckfj7.png'
    const glass='https://res.cloudinary.com/dvr397xda/image/upload/v1658518055/cube/weiiz/WechatIMG9330_gm5mez.png'
    let textures;

textures = useLoader(THREE.TextureLoader,[glass,glass,glass,glass,img,img])
// textures = useLoader(TextureLoader,[people.images[0],people.images[1],people.images[2],people.images[3],people.images[4],people.images[5]])

textures.minFilter = textures.magFilter = THREE.LinearFilter;



textures.format= THREE.RGBAFormat;




  function Map (){
    return(
    
      textures.map((texture, idx) =>(
        idx ===2||idx===3 || idx===0?<meshBasicMaterial key={idx} attach={`material-${idx}`} map={texture}   transparent={true} opacity={1}/>
        :<meshBasicMaterial opacity={1} transparent={true}  key={idx} attach={`material-${idx}`} map={texture}  />
        )
)
    )
  } 
      return (
        <>
        <mesh
          onClick={(e)=>{
            e.stopPropagation()
          }}
          ref={mesh}
         position={position}
          >   
          <boxBufferGeometry attach="geometry"  args={isPhone?[3.1,2.1,0.1]:[6.4,4.4,0.1]}/>
            <Map/>
        </mesh>
        </>
      )
      
  }

  function Frame2({position,isPhone}) {
    const mesh = useRef()
    const img='https://res.cloudinary.com/dvr397xda/image/upload/v1661805249/cube/hunqing/WechatIMG10301_cbvcho.png'
    const glass='https://res.cloudinary.com/dvr397xda/image/upload/v1658518055/cube/weiiz/WechatIMG9330_gm5mez.png'
    let textures;

textures = useLoader(THREE.TextureLoader,[glass,glass,glass,glass,img,img])
// textures = useLoader(TextureLoader,[people.images[0],people.images[1],people.images[2],people.images[3],people.images[4],people.images[5]])

textures.minFilter = textures.magFilter = THREE.LinearFilter;



textures.format= THREE.RGBAFormat;




  function Map (){
    return(
    
      textures.map((texture, idx) =>(
        idx ===2||idx===3 || idx===0?<meshBasicMaterial key={idx} attach={`material-${idx}`} map={texture}   transparent={true} opacity={1}/>
        :<meshBasicMaterial opacity={1} transparent={true}  key={idx} attach={`material-${idx}`} map={texture}  />
        )
)
    )
  } 

      return (
        <>
        <mesh
          onClick={(e)=>{
            e.stopPropagation()
          }}
          ref={mesh}
         position={position}
          >   
          <boxBufferGeometry attach="geometry"  args={isPhone?[3.1,4.6,0.1]:[3.7,5.5,0.1]}/>
            <Map/>
        </mesh>
        </>
      )
      
  }


const music = [
  {
    album:'周杰伦',
    name:'简单爱',
    track:'Jay', 
    url:'https://res.cloudinary.com/dvr397xda/video/upload/v1659647081/cube/hunqing/jie-hun-jin-xing-qu-hua-ge-na-gang-qin-andle-tuan_jjzqpd.mp3', 
    image:'https://res.cloudinary.com/dvr397xda/image/upload/v1659646417/cube/hunqing/wedding-couple_1_seqrc3.png'
  },
  {
      album:'周杰伦',
      track:'Fantasy', 
      name:'爱在西元前',
      url:'https://res.cloudinary.com/dvr397xda/video/upload/v1659647079/cube/hunqing/gang-qin-ban-chun-yin-le_dizify.mp3', 
      image:'https://res.cloudinary.com/dvr397xda/image/upload/v1659646417/cube/hunqing/wedding-couple_2_igs4cd.png'
    },
    {
      album:'周杰伦',
      track:'七里香', 
      name:'七里香',
      url:'https://res.cloudinary.com/dvr397xda/video/upload/v1659646130/cube/hunqing/jin-tian-nai-yao-jia-gei-wo-marry-me-today-feat-cai-yi-lin-jolin-tsai-guan-fang-wan-zheng-ban-mv_slfbuz.mp3', 
      image:'https://res.cloudinary.com/dvr397xda/image/upload/v1659646417/cube/hunqing/wedding-couple_2_igs4cd.png'
    },

]

const Title = ({isPhone,name})=>{
    return (
        <>
          
          <Center top rotation={[0.25,0, 0]} position={[0,3,isPhone?11:12]}>
        <Text3D
       
          curveSegments={32}
          bevelEnabled
          bevelSize={0.04}
          bevelThickness={0.1}
          height={0.5}
          lineHeight={0.5}
          letterSpacing={-0.06}
          size={0.4}
          font="https://res.cloudinary.com/dvr397xda/raw/upload/v1661803592/cube/hunqing/Inter_Bold_i7pe4c.json">
          {name}
          <meshNormalMaterial />
        </Text3D>
     
      </Center>
        </>
      )
}

export default function Wedding() {
    const{ name } = useParams();
    
   
    const [people,setPeople]=useState({})
    const [index,setIndex]=useState(0)
    const [current,setCurrent]=useState({})
    const [isPhone,setIsPhone]=useState(true)

   useEffect(()=>{

    const data = fetchWedding();
    const result = data.filter(e=>e.id===name)
    console.log(result,data)
    setPeople(result[0])
    setCurrent(result[0].images[0])
    const width = window.innerWidth
   
    if(width>500){
        setIsPhone(false)
    }

   },[name])

 
   useEffect(()=>{
    const interval = setInterval(() => {

        if(index ===people.images.length-1){
            setIndex(0)
            setCurrent(people.images[0])
        }
        else{
            setIndex(index+1)
            setCurrent(people.images[index+1])

        }
    }, 10000);
    return () => clearInterval(interval);

   },[index,people])

 const handleNext=()=>{
     if(index+1 === people.images.length){
         setIndex(0)
         setCurrent(people.images[0])

     }
     else{
         setIndex(index+1)
         setCurrent(people.images[index+1])

     }
 }
 const handleLast=()=>{
    if(index === 0){
        setIndex(people.images.length-1)
        setCurrent(people.images[people.images.length-1])
    }
    else{
        setCurrent(people.images[index-1])
        setIndex(index-1)
    }
}


  return (
    <div className="container-card"  >

        {people.name&&<div id='gous-bg' style={{backgroundImage:`url(${current.url})`}}>

        </div>}
     
     <Canvas camera={{ position: [0, -2, 4.5] }}  gl={{ alpha: true, antialias: true}}  className='canvas-wrap'>
             <pointLight position={[10, 10, 10]} />
           

     <OrbitControls minPolarAngle={Math.PI / 2.2} maxPolarAngle={Math.PI / 2.5} />
    <ambientLight  />
 {/* <pointLight position={[3, 3, 3]} intensity={1} color='#f0f0f0'/>
        <pointLight position={[3, -3, 3]} intensity={0.5} color='#f0f0f0'/>

        <pointLight position={[-3, 3, 3]} intensity={0.5} color='#f0f0f0'/>

        <pointLight position={[3, 3, -3]} intensity={0.5} color='#f0f0f0'/>
        <spotLight  position={[0, 0, 0]} intensity={0.5} color='#f0f0f0'/> */}

      <Suspense fallback={<Loader/>}>

      <Title isPhone={isPhone} name={people.name}/>

    {people.name&&current.isRotate ?
    
    <Box2 isPhone={isPhone} img={current.url}  position={[0, 0, 0]} /> 
        :   <Box isPhone={isPhone} img={current.url}  position={[0, 0, 0]} /> 
    }

{people.name&&current.isRotate ?
    
    <Frame2 isPhone={isPhone}   position={[0, 0, 0]} /> 
        :   <Frame isPhone={isPhone}  position={[0, 0, 0]} /> 
    }
     

    
    </Suspense>

    </Canvas>


    <div id='mp3-player'>
            <Player  data={music} />
    </div>

    <div id='control-bt'>
        <img onClick={()=>handleLast()} src="https://res.cloudinary.com/dvr397xda/image/upload/v1661801680/cube/hunqing/back_1_llnnlf.png" alt="" />
        <img onClick={()=>handleNext()} src="https://res.cloudinary.com/dvr397xda/image/upload/v1661801718/cube/hunqing/forward_iqtsmj.png" alt="" />
    </div>

    </div>
  )
}

