import React,{useState,useEffect,useCallback}from 'react'
import axios from 'axios'
import {Link } from 'react-router-dom'
import Select from 'react-select'
import { EditorState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import { convertToHTML } from 'draft-convert';
import DOMPurify from 'dompurify';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import './index.scss'

export default function AuthNews() {

    const apiUrl ='https://metaserverapp.com'
    const isGallerys = [
        { value: '1', label: '1' },
        { value: '2', label: '2' },
        { value: '3', label: '3' },
        { value: '4', label: '4' },
        { value: '5', label: '5' },
        { value: '6', label: '6' },
        { value: '7', label: '7' },
        { value: '8', label: '8' },
        { value: '9', label: '9' },
       
    ]
    const CATEGORYS = [
        { value: '国际', label: '国际' },
        { value: '美国', label: '美国' },
        { value: '中国', label: '中国' },
        { value: '娱乐', label: '娱乐' },
        { value: '体育', label: '体育' },
        { value: '游戏', label: '游戏' },
        { value: '科技', label: '科技' },
        { value: '搞笑', label: '搞笑' },
        { value: '旅游', label: '旅游' },

    ]

    const [news,setNews]=useState([])
    const [page,setPage] = useState(1)
    const [total,setTotal]=useState(0)
    const [totalPage,setTotalPage] = useState(0)
    const [gallerys,setGallerys] = useState([])
    const initial = {
        title:'',
        content:'',
        images:[],
        source:'',
        tags:[],
        tag:'',
        keyword:'',
        link:'',
        category:'',
        video:''
    }

 
    const [isDelete,setIsDelete] = useState(false)
    const [isCreate,setIsCreate] = useState(false)
    const [target,setTarget]= useState(initial)

    const [editorState, setEditorState] = useState(
        () => EditorState.createEmpty()
      );

    useEffect(()=>{

        const fetchNews = async()=>{
            const result = await axios.get(`${apiUrl}/news`,{params:{page:1}})
            if(result.data.success){
           
                setNews(result.data.data[0].result)
      
              
            
              const temp = result.data.data[0].total[0].total
              setTotal(result.data.data[0].total[0].total)
              const temp2 = Math.ceil(temp/15)
              setTotalPage(temp2)
           
      
            }
            else{
              alert('Server Error! No data response received!')
            }
        }
        fetchNews()

    },[])

    useEffect(()=>{

        const fetchNews = async()=>{
            const result = await axios.get(`${apiUrl}/news`,{params:{page:page}})
            if(result.data.success){
           
            setNews(result.data.data[0].result)
      
              const temp = result.data.data[0].total[0].total
              setTotal(result.data.data[0].total[0].total)
              const temp2 = Math.ceil(temp/15)
              setTotalPage(temp2)
           
      
            }
            else{
              alert('Server Error! No data response received!')
            }
        }
        fetchNews()

    },[page])

    const handleTitle =(e)=>{
        let temp = {...target}
        temp.title = e.target.value
        setTarget(temp)
    }
    const handleContent =(e)=>{
        let temp = {...target}
        temp.content = e.target.value
        setTarget(temp)
    }
    const handleSource =(e)=>{
        let temp = {...target}
        temp.source = e.target.value
        setTarget(temp)
    }
    const handleVideo = (e)=>{
        let temp = {...target}
        temp.video = e.target.value
        setTarget(temp)
    }
    const handleTags = (e)=>{
        let temp = {...target}
        temp.tag = e.target.value
        temp.tags = e.target.value.split('-')
        setTarget(temp)
    }
    const handleKeyword = (e)=>{
        let temp = {...target}
        temp.keyword = e.target.value
        setTarget(temp)
    }
    const handleLink = (e)=>{
        let temp = {...target}
        temp.link = e.target.value
        setTarget(temp)
    }
    const handleCategory = (e)=>{
        let temp = {...target}
        temp.category = e.value
        setTarget(temp)
    }


      const handleNextPage = ()=>{
        setPage(page+1)
        const wrap =document.getElementsByClassName('auth-posts-form')[0]
        wrap.scrollTop = 0
      }
      const handleLastPage = ()=>{
        setPage(page-1)
        const wrap =document.getElementsByClassName('auth-posts-form')[0]
        wrap.scrollTop = 0
    
      }

     

    const handleDelete=()=>{
        setIsDelete(true)
    }
    const handleCancelDelete=()=>{
        setIsDelete(false)

    }

    const handleDeleteNews=async(v)=>{
        console.log(v)
        const result = await axios.post(`${apiUrl}/news/delete`,{newsId:v._id})
        console.log(result)
        if(result.data.success){
            window.location.reload()
        }
        else{
            alert('something went wrong when delete News!')
        }
    }

    const handleCreateNews = ()=>{
        setIsCreate(!isCreate)
    }

    const handleGallery =(e)=>{
        console.log(e);

        let temp ={...target}
        temp.images=e.value
        let arr = [];
        if(e.value){
            for(let i=0;i<e.value;i++){
                arr.push('')
            }
            setGallerys(arr)
        }
        temp.images = arr
        setTarget(temp)
     }

     const handleChangeGallery = (e,i)=>{
        console.log(e);

        let temp ={...target}
        temp.images[i]=e.target.value
        setTarget(temp)

     }

     const handleSubmit = async()=>{
        if(!convertedContent||!target.title){
            alert('Missing content')
        }
        else{
            const data = await axios.post(`${apiUrl}/news/create`,{
                title:target.title,
                content:convertedContent,
                images:target.images,
                tags:target.tags,
                source:target.source,
                category:target.category,
                keyword:target.keyword,
                link:target.link,
                video:target.video,
            })
            console.log(data)
            if(data.data.success){
                
                alert("创建成功！")
                window.location.reload()
            }
            else{
                alert(data.data.errors)
            }
        }

     }
     const [convertedContent, setConvertedContent] = useState(null);

     useEffect(() => {
       let html = convertToHTML(editorState.getCurrentContent());
       setConvertedContent(html);
     }, [editorState]);
   
     console.log(convertedContent);

console.log(editorState)
   
  return (
    <div className='auth-post-wrap'>
        <h2>新闻管理页面</h2>
        
        <div className='auth-post-btns'>
            <Link to='/auth'> <button >返回</button></Link> 
            <button onClick={()=>handleCreateNews()}> {isCreate ? '取消创建' :' 创建新闻'}</button>
        </div>

        <div className='auth-posts-form'>
        <table>
            <thead>
                <tr>
                    <th>
                        标题
                    </th>
                    <th>
                        内容
                    </th>
                    <th>
                        分类
                    </th>
                    <th>
                        来源
                    </th>
                  
                   
                    <th>
                        日期
                    </th>
                    <th>
                        浏览量
                    </th>
                    
                    <th>
                        删除
                    </th>
                </tr>
            </thead>
            <tbody>
                {
                    news.length>0&&news.map((v,i)=>(
                        <tr key={i}>
                            <td>{v.title}</td>
                            <td>{v.content.substring(0,30)}...</td>
                            <td>{v.category}</td>
                            <td>{v.source}</td>

                           
                         
                            <td>{v.date.substring(0,10)}</td>
                            <td>{v.views}</td>
                            
                            {isDelete ?<td className='post-delete-group'><button onClick={()=>handleDeleteNews(v)}>确定</button><button onClick={()=>handleCancelDelete()}>取消</button></td> :<td><button onClick={()=>handleDelete()}>删除</button></td>}
                        </tr>
                    )

                    )
                }
            </tbody>


        </table>


        </div>
        <div className='pagenation-wrap'>

<div> {page!==1&&<p onClick={()=>handleLastPage()}>上一页</p>}</div>

<div> <p>第{page}页</p> </div>

<div> {page !== totalPage&&<p onClick={()=>handleNextPage()}>下一页</p>}</div>

<div><p>共{totalPage}页</p></div>
<div>
<p>共{total}个结果</p>
</div>
</div>

     
     <div style={{top:isCreate? '12vh':'220vh',transition:'1s'}} className='auth-news-create'>

    <div className='news-wrap'>

        <div>
        <h4>新闻来源</h4>
    <input type="text" value={target.source} onChange={(e)=>handleSource(e)}/>
        </div>
        
        <div>
        <h4>标题</h4>
        <input type="text" value={target.title} onChange={(e)=>handleTitle(e)}/>
        </div>


     <div className='draft-container'>
     <h4>内容</h4>

     <Editor
        editorState={editorState}
        onEditorStateChange={setEditorState}
        wrapperClassName="wrapper-class"
        editorClassName="editor-class"
        toolbarClassName="toolbar-class"
        toolbar={{
            options: ['inline', 'blockType']
          }}
      />
     </div>
     <div
    className="preview"
    dangerouslySetInnerHTML={createMarkup(convertedContent)}>
  </div>
      
        {/* <div className='textarea-news'>
        <h4>内容</h4>
        <textarea type="text" value={target.content} onChange={(e)=>handleContent(e)}/>
        </div> */}
        <div>
        <h4>链接词条</h4>
        <input type="text" value={target.keyword} onChange={(e)=>handleKeyword(e)}/>
        </div>
        <div>
        <h4>链接网址</h4>
        <input type="text" value={target.link} onChange={(e)=>handleLink(e)}/>
        </div>
        
        <div>
        <h4>新闻标签</h4>
        <input placeholder='以-为分界 比如 纽约 疫情 请写:纽约-疫情' type="text" value={target.tag} onChange={(e)=>handleTags(e)}/>
        </div>
        <div>
        <h4>视频链接</h4>
        <input type="text" value={target.video} onChange={(e)=>handleVideo(e)}/>
        </div>
        <div className="create-news-wrap">
        <label>新闻轮播图</label>
        <Select    
         onChange={(e)=>handleGallery(e)}
         options={isGallerys}             
           menuPortalTarget={document.body}
        menuPosition="fixed"       maxMenuHeight='20vh'           
        />
    </div>

    {
        gallerys.length>0&&gallerys.map((v,i)=>(
            <div key={i} className="create-input-wrap">
            <label>轮播图{i+1}</label>
            <input value={target.images[i]} onChange={(e)=>handleChangeGallery(e,i)} type="text" />
        </div>
        ))
    }
    <div className="create-news-wrap">
        <label>分类</label>
        <Select    
         onChange={(e)=>handleCategory(e)}
         options={CATEGORYS}             
           menuPortalTarget={document.body}
        menuPosition="fixed"       maxMenuHeight='20vh'           
        />
    </div>
  
       

    </div>

    <button onClick={()=>handleSubmit()}>Submit</button>

     </div>
        
        
    </div>
  )
}


function createMarkup(html) {
    return {
      __html: DOMPurify.sanitize(html)
    }
  }