import React,{useRef} from "react";
import { BackSide } from "three";
import { useLoader,useFrame } from '@react-three/fiber'


import * as THREE from "three";


export default function Env(){

    const uni = 'https://res.cloudinary.com/dvr397xda/image/upload/v1650895142/cube/pexels-pixabay-207529_wob0x3.jpg'

    const colorMap = useLoader(THREE.TextureLoader, uni)
    const group = useRef()

    useFrame((state,delta)=>(group.current.rotation.y+=0.001))

  return (
    <mesh ref={group}>
      <sphereBufferGeometry args={[5, 20, 20]} attach="geometry" />
      <meshStandardMaterial
        map={colorMap}
        attach="material"
        side={BackSide}
        metalness={1}
      />
    </mesh>
  );
};
