import React,{useState,useEffect,useCallback}from 'react'
import axios from 'axios'
import Select from 'react-select'
import fetchLocation from '../../../../locations'
import fetchCategory from '../../../../category'
import './index.scss'

export default function AuthPost() {

    const apiUrl ='https://metaserverapp.com'
    const locations = fetchLocation()
    const categorys = fetchCategory()
    const [posts,setPosts]=useState([])
    const [page,setPage] = useState(1)
    const [total,setTotal]=useState(0)
    const [totalPage,setTotalPage] = useState(0)
    const [loading,setLoading]= useState('载入中...')
    const [isAd,setIsAd]=useState(false)
    const [isAll,setIsAll]=useState(true)
    const [isCategory,setIsCategory]=useState(false)
    const [isUpdate,setIsUpdate] = useState(false)
    const [target,setTarget]=useState({})
    const [isDelete,setIsDelete] = useState(false)

    const ISAD = [
        {
            value:true,
            label:'是',
        },
        {
            value:false,
            label:'否',
        },
    ]
    const POINTS = [
        {
            value:0,
            label:'0',
        },
        {
            value:1,
            label:'1',
        },
        {
            value:2,
            label:'2',
        },
        {
            value:3,
            label:'3',
        },
        {
            value:4,
            label:'4',
        },
        {
            value:5,
            label:'5',
        },
    ]




    useEffect(() => {
        const fetchAllPost = async()=>{
          const result =await axios.get(`${apiUrl}/post`,{params:{page:1}})
         
          if(result.data.success){
           
              setPosts(result.data.data[0].result)
    
            
          
            const temp = result.data.data[0].total[0].total
            setTotal(result.data.data[0].total[0].total)
            const temp2 = Math.ceil(temp/50)
            setTotalPage(temp2)
         
    
          }
          else{
            alert('Server Error! No data response received!')
            setLoading('无相关帖子')
          }
        }
        fetchAllPost()
        
    
      },[])


      const handleNextPage = ()=>{
        setPage(page+1)
        const wrap =document.getElementsByClassName('auth-posts-form')[0]
        wrap.scrollTop = 0
      }
      const handleLastPage = ()=>{
        setPage(page-1)
        const wrap =document.getElementsByClassName('auth-posts-form')[0]
        wrap.scrollTop = 0
    
      }

      useEffect(()=>{

        if(isAll){
            const fetchAllPost = async()=>{
                const result =await axios.get(`${apiUrl}/post/`,{params:{page:page}},)
               
                console.log(result.data)
                if(result.data.success){
                
                    setPosts(result.data.data[0].result)
  
                  const temp = result.data.data[0].total[0].total
                  setTotal(result.data.data[0].total[0].total)
                  const temp2 = Math.ceil(temp/50)
                  setTotalPage(temp2)
               
          
                }
                else{
                  alert('Server Error! No data response received!')
                  setLoading('无相关帖子')
                }
              }
              fetchAllPost()
        }
        else if(isCategory){
            const fetchAllPost = async()=>{
                const result =await axios.get(`${apiUrl}/post/category`,{params:{page:page,category:''}},)
               
                console.log(result.data)
                if(result.data.success){
                
                    setPosts(result.data.data[0].result)
  
                  const temp = result.data.data[0].total[0].total
                  setTotal(result.data.data[0].total[0].total)
                  const temp2 = Math.ceil(temp/50)
                  setTotalPage(temp2)
               
          
                }
                else{
                  alert('Server Error! No data response received!')
                  setLoading('无相关帖子')
                }
              }
              fetchAllPost()
        }
        else if(isAd){
            const fetchAllPost = async()=>{
                const result =await axios.get(`${apiUrl}/post/ad`,{params:{page:page,isAd:true}},)
               
                console.log(result.data)
                if(result.data.success){
                
                    setPosts(result.data.data[0].result)
  
                  const temp = result.data.total
                  setTotal(result.data.total)
                  const temp2 = Math.ceil(temp/50)
                  setTotalPage(temp2)
               
                }
                else{
                  alert('Server Error! No data response received!')
                  setLoading('无相关帖子')
                }
              }
              fetchAllPost()
        }
       else{

       }
          
      },[page,isAll,isAd,isCategory])

      const handleAllPost = ()=>{
          setIsAll(true)
          setIsAd(false)
          setIsCategory(false)
      }
      const handleAllCategory = ()=>{
        setIsAll(false)
        setIsAd(false)
        setIsCategory(true)
      }
      const handleAllAd = ()=>{
          setIsAd(true)
          setIsAll(false)
          setIsCategory(false)

      }

      const handleUpdate=(v)=>{
          setIsUpdate(true)
          setTarget(v)
      }

      const handleCategory = (e)=>{
        console.log(e);
        let temp = {...target}
        temp.category = e.value
        setTarget(temp)
    }
    const handleAd = (e)=>{
        console.log(e);
        let temp = {...target}
        temp.isAd = e.value
        setTarget(temp)
    }
    const handlePoint = (e)=>{
        console.log(e);
        let temp = {...target}
        temp.point = e.value
        setTarget(temp)
    }

    const handleSubmit = async()=>{

        const result =await axios.post(`${apiUrl}/post/category`,{
            postId:target._id,
            category:target.category,
            isAd:target.isAd,
            point:target.point,

        })
        // console.log(result);
        if(result.data.success) {
            setIsUpdate(false)
            window.location.reload()
        }
        else{
            alert('Error！小兄弟你又干鸡儿了！ ')
        }

    }

    const handleCancel=()=>{
        setIsUpdate(false)
    }

    const handleDelete=()=>{
        setIsDelete(true)
    }
    const handleCancelDelete=()=>{
        setIsDelete(false)

    }

    const handleDeletePost=async(v)=>{
        console.log(v)
        const result = await axios.post(`${apiUrl}/post/delete`,{postId:v._id})
        console.log(result)
        if(result.data.success){
            window.location.reload()
        }
        else{
            alert('something went wrong when delete post!')
        }
    }

console.log(target);
  return (
    <div className='auth-post-wrap'>
        <h2>发帖管理页面</h2>
        
        <div className='auth-post-btns'>
            <button onClick={()=>handleAllPost()}>所有帖子</button>
            <button onClick={()=>handleAllCategory()}>尚未分类</button>
            <button onClick={()=>handleAllAd()}> 广告贴</button>
        </div>

        <div className='auth-posts-form'>
        <table>
            <thead>
                <tr>
                    <th>
                        标题
                    </th>
                    <th>
                        内容
                    </th>
                    <th>
                        地区
                    </th>
                    <th>
                        分类
                    </th>
                    <th>
                        日期
                    </th>
                    <th>
                        浏览量
                    </th>
                    <th>
                        广告贴
                    </th>
                    <th>
                        修改
                    </th>
                    <th>
                        删除
                    </th>
                </tr>
            </thead>
            <tbody>
                {
                    posts.length>0&&posts.map((v,i)=>(
                        <tr key={i}>
                            <td>{v.title}</td>
                            <td>{v.content}</td>
                            <td>{v.area}{v.location? v.location:''}{v.city?v.city:''}</td>
                            <td>{v.category}</td>
                            <td>{v.date}</td>
                            <td>{v.views}</td>
                            <td>{v.isAd}</td>
                            <td><button onClick={()=>handleUpdate(v)}>修改</button></td>
                            {isDelete ?<td className='post-delete-group'><button onClick={()=>handleDeletePost(v)}>确定</button><button onClick={()=>handleCancelDelete()}>取消</button></td> :<td><button onClick={()=>handleDelete()}>删除</button></td>}
                        </tr>
                    )

                    )
                }
            </tbody>


        </table>


        </div>
        <div className='pagenation-wrap'>

<div> {page!==1&&<p onClick={()=>handleLastPage()}>上一页</p>}</div>

<div> <p>第{page}页</p> </div>

<div> {page !== totalPage&&<p onClick={()=>handleNextPage()}>下一页</p>}</div>

<div><p>共{totalPage}页</p></div>
<div>
<p>共{total}个结果</p>
</div>
</div>

        <div style={{top:isUpdate? '10vh':'220vh',transition:'1s'}} className='auth-post-update'>

                <h4>标题:{target.title}</h4>
                <p>内容:{target.content}</p>
                <label>分类*</label>
                                <Select    
                                onChange={(e)=>handleCategory(e)}
                                options={categorys}             
                                menuPortalTarget={document.body}
                                menuPosition="fixed"       maxMenuHeight='30vh'           
                                />
                                 <label>广告贴*</label>
                                <Select    
                                onChange={(e)=>handleAd(e)}
                                options={ISAD}          
                                menuPortalTarget={document.body}
                                menuPosition="fixed"       maxMenuHeight='30vh'           
                                />
                                { target.isAd&&
                                <div>
                                                                     <label>分值(默认0分 分值越高越靠前)*</label>
                                                                     <Select    
                                onChange={(e)=>handlePoint(e)}
                                options={POINTS}          
                                menuPortalTarget={document.body}
                                menuPosition="fixed"       maxMenuHeight='30vh'           
                                />
                                </div>
                                }
            <div className='sub-wrap'>
                <button onClick={()=>handleSubmit()}>修改</button>
                <button onClick={()=>handleCancel()}>取消</button>
            </div>
            
        </div>
        
        
    </div>
  )
}
