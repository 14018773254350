import React,{useState,useEffect,useCallback}from 'react'
import axios from 'axios'
import {Link } from 'react-router-dom'
import Select from 'react-select'

import './index.scss'

export default function AuthNews() {

    const apiUrl ='https://metaserverapp.com'


    const [news,setNews]=useState([])
    const [page,setPage] = useState(1)
    const [total,setTotal]=useState(0)
    const [totalPage,setTotalPage] = useState(0)
    const initial = {
        name:'',
        price:'',
        sale:'',
        image:'',
        code:'',
        discount:'',
        url:''
    }

 
    const [isDelete,setIsDelete] = useState(false)
    const [isCreate,setIsCreate] = useState(false)
    const [target,setTarget]= useState(initial)

    useEffect(()=>{

        const fetchNews = async()=>{
            const result = await axios.get(`${apiUrl}/coupon`,{params:{page:1}})
            if(result.data.success){
           
                setNews(result.data.data[0].result)
      
              
            
              const temp = result.data.data[0].total[0].total
              setTotal(result.data.data[0].total[0].total)
              const temp2 = Math.ceil(temp/15)
              setTotalPage(temp2)
           
      
            }
            else{
              alert('Server Error! No data response received!')
            }
        }
        fetchNews()

    },[])

    useEffect(()=>{

        const fetchNews = async()=>{
            const result = await axios.get(`${apiUrl}/coupon`,{params:{page:page}})
            if(result.data.success){
           
                setNews(result.data.data[0].result)
      
              
            
              const temp = result.data.data[0].total[0].total
              setTotal(result.data.data[0].total[0].total)
              const temp2 = Math.ceil(temp/15)
              setTotalPage(temp2)
           
      
            }
            else{
              alert('Server Error! No data response received!')
            }
        }
        fetchNews()

    },[page])

    const handleName =(e)=>{
        let temp = {...target}
        temp.name = e.target.value
        setTarget(temp)
    }
    const handlePrice =(e)=>{
        let temp = {...target}
        temp.price = e.target.value
        setTarget(temp)
    }
    const handleSale =(e)=>{
        let temp = {...target}
        temp.sale = e.target.value
        setTarget(temp)
    }
    const handleImage = (e)=>{
        let temp = {...target}
        temp.image = e.target.value
        setTarget(temp)
    }
    const handleCode = (e)=>{
        let temp = {...target}
        temp.code = e.target.value
        setTarget(temp)
    }
    const handleDiscount = (e)=>{
        let temp = {...target}
        temp.discount = e.target.value
        setTarget(temp)
    }
    const handleUrl = (e)=>{
        let temp = {...target}
        temp.url = e.target.value
        setTarget(temp)
    }


      const handleNextPage = ()=>{
        setPage(page+1)
        const wrap =document.getElementsByClassName('auth-posts-form')[0]
        wrap.scrollTop = 0
      }
      const handleLastPage = ()=>{
        setPage(page-1)
        const wrap =document.getElementsByClassName('auth-posts-form')[0]
        wrap.scrollTop = 0
    
      }

     

    const handleDelete=()=>{
        setIsDelete(true)
    }
    const handleCancelDelete=()=>{
        setIsDelete(false)

    }

    const handleDeleteNews=async(v)=>{
        console.log(v)
        const result = await axios.post(`${apiUrl}/coupon/delete`,{id:v._id})
        console.log(result)
        if(result.data.success){
            window.location.reload()
        }
        else{
            alert('something went wrong when delete News!')
        }
    }

    const handleCreateNews = ()=>{
        setIsCreate(!isCreate)
    }


   

     const handleSubmit = async()=>{
        if(!target.name||!target.price){
            alert('Missing content')
        }
        else{
            const data = await axios.post(`${apiUrl}/coupon/create`,{
                name:target.name,
                price:target.price,
                image:target.image,
                sale:target.sale,
                code:target.code,
                discount:target.discount,
                url:target.url,
            })
            console.log(data)
            if(data.data.success){
                
                alert("创建成功！")
                window.location.reload()
            }
            else{
                alert(data.data.errors)
            }
        }

     }



   
    console.log(target,news,total);
  return (
    <div className='auth-post-wrap'>
        <h2>折扣管理页面</h2>
        
        <div className='auth-post-btns'>
            <Link to='/auth'> <button >返回</button></Link> 
            <button onClick={()=>handleCreateNews()}> {isCreate ? '取消创建' :' 创建折扣'}</button>
        </div>

        <div className='auth-posts-form'>
        <table>
            <thead>
                <tr>
                    <th>
                        产品
                    </th>
                    <th>
                        图片
                    </th>
                   
                    <th>
                        原价
                    </th>
                    <th>
                        折扣价
                    </th>
                    
                    <th>
                        点击量
                    </th>
                    <th>
                        折扣码
                    </th>
                    <th>
                        折扣额度
                    </th>
                    <th>
                        链接
                    </th>
                    <th>
                        删除
                    </th>
                </tr>
            </thead>
            <tbody>
                {
                    news.length>0&&news.map((v,i)=>(
                        <tr key={i}>
                            <td>{v.name}</td>
                            <td><img src={v.image} alt="" /></td>
                         
                            <td>{v.price}</td>
                            <td>{v.sale}</td>
                            <td>{v.views}</td>
                            <td>{v.code ? v.code :''}</td>
                            <td>{v.discount ? v.discount :''}</td>
                            <td>{v.url}</td>

                            {isDelete ?<td className='post-delete-group'><button onClick={()=>handleDeleteNews(v)}>确定</button><button onClick={()=>handleCancelDelete()}>取消</button></td> :<td><button onClick={()=>handleDelete()}>删除</button></td>}
                        </tr>
                    )

                    )
                }
            </tbody>


        </table>


        </div>
        <div className='pagenation-wrap'>

<div> {page!==1&&<p onClick={()=>handleLastPage()}>上一页</p>}</div>

<div> <p>第{page}页</p> </div>

<div> {page !== totalPage&&<p onClick={()=>handleNextPage()}>下一页</p>}</div>

<div><p>共{totalPage}页</p></div>
<div>
<p>共{total}个结果</p>
</div>
</div>

     
     <div style={{top:isCreate? '12vh':'220vh',transition:'1s'}} className='auth-product-create'>

    <div className='news-wrap'>

        <div>
        <h4>产品名字</h4>
    <input type="text" value={target.name} onChange={(e)=>handleName(e)}/>
        </div>
        
        <div>
        <h4>产品原价</h4>
        <input type="text" value={target.price} onChange={(e)=>handlePrice(e)}/>
        </div>

        <div>
        <h4>产品现价</h4>
        <input type="text" value={target.sale} onChange={(e)=>handleSale(e)}/>
        </div>

        <div>
        <h4>产品图片</h4>
        <input type="text" value={target.image} onChange={(e)=>handleImage(e)}/>
        </div>

        <div>
        <h4>产品折扣码</h4>
        <input type="text" value={target.code} onChange={(e)=>handleCode(e)}/>
        </div>

        <div>
        <h4>折扣码减免</h4>
        <input type="text" value={target.discount} onChange={(e)=>handleDiscount(e)}/>
        </div>

        <div>
        <h4>产品链接</h4>
        <input type="text" value={target.url} onChange={(e)=>handleUrl(e)}/>
        </div>

       
        
        
     
       

    </div>

    <button onClick={()=>handleSubmit()}>Submit</button>

     </div>
        
        
    </div>
  )
}
