const data = [

    { 
        name:'johnny',
        fullName:'Johnny Chen',
        description: 'Johnny Chen在美国餐饮行业和按摩行业有多年创业经验，致力于结合现代互联网技术开创新的行业标准',
        image:'https://res.cloudinary.com/dvr397xda/image/upload/v1654810515/cube/13_w8qusw.gif', 
        title:'创始人',
        stage:'https://res.cloudinary.com/dlapk94rx/image/upload/v1644614268/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/q_q_nlwuh1.png'
    }





]

const fetchBoss = ()=>{
    return data
}

export default fetchBoss;