import React,{useState,useEffect,useRef,} from 'react'
import {useParams,Link} from 'react-router-dom'

import fetchBoss from '../../boss'
import fetchSingle from '../../single'
import Room from '../../components/Room/index'
import './index.scss'


export default function Boss() {
    const { name } = useParams();
    // const [people,setPeople] = useState([])
    const [ob,setOb] = useState({})
    const [bg,setBg] = useState('https://res.cloudinary.com/dlapk94rx/image/upload/v1644614268/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/q_q_nlwuh1.png')
  

    const people = [
      {
        name:'XinTian inc',
        route:'xintian',
        avatar:'https://res.cloudinary.com/dvr397xda/image/upload/v1654544385/cube/4_ps8jf5.png'
      },
      {
        name:'Weiiz',
        route:'weiiz',
        avatar:'https://res.cloudinary.com/dvr397xda/image/upload/v1654294846/cube/weiiz_iojznp.png'
      },
      {
        name:'EZ Market',
        route:'groupchop',
        avatar:'https://res.cloudinary.com/dvr397xda/image/upload/v1674839518/logo/WechatIMG15575_kmksmp.png'
      },
      {
        name:'美达传媒',
        route:'huoli',
        avatar:'https://res.cloudinary.com/dvr397xda/image/upload/v1674839518/logo/WechatIMG15574_pfbtbk.png'
      },
      {
        name:'Nova智库',
        route:'nicrg',
        avatar:'https://res.cloudinary.com/dvr397xda/image/upload/v1674839518/logo/WechatIMG15569_kzbt2d.png'
      },
      {
        name:'元社区',
        route:'meta',
        avatar:'https://res.cloudinary.com/dvr397xda/image/upload/v1674853518/logo/WechatIMG15357_kp2gvq.png'
      },
      {
        name:'微视界',
        route:'world',
        avatar:'https://res.cloudinary.com/dvr397xda/image/upload/v1674839518/logo/WechatIMG15570_smque7.png'
      },
    ]


    useEffect(() => {
        const companyData = fetchBoss()
        const target = companyData.filter(e=>e.name===name)
        setOb(target[0])
      
        
      
      },[name])



      useEffect(()=>{
        if(people){
        var radius = 240; // how big of the radius
        var radius2 = 255; // how big of the radius
        var radiusX = 180;
        
        var autoRotate = true; // auto rotate or not
        var rotateSpeed = -60; // unit: seconds/360 degrees
        var imgWidth = 120; // width of images (unit: px)
        var imgHeight = 170; // height of images (unit: px)
        
        // Link of background music - set 'null' if you dont want to play background music
        var bgMusicURL = '';
        var bgMusicControls = true; // Show UI music control
        
        
        
        
        // ===================== start =======================
        // animation start after 1000 miliseconds
        setTimeout(init, 1000);
        var odrag = document.getElementById('drag-container');
        var ospin = document.getElementById('spin-container');
        var aImg = ospin.getElementsByTagName('img');
        var pTag = ospin.getElementsByTagName('p');
        var aVid = ospin.getElementsByTagName('video');
        var aEle = [...aImg, ...aVid]; // combine 2 arrays
        var pEle = [...pTag]
        pEle.length = pEle.length-1
        // aEle.length = aEle.length-1
        // console.log(pEle)
        // Size of images
        ospin.style.width = imgWidth + "px";
        ospin.style.height = imgHeight + "px";
        
        // Size of ground - depend on radius
        var ground = document.getElementById('ground');
        ground.style.width = radius * 3 + "px";
        ground.style.height = radius * 3 + "px";
        function init(delayTime) {
          for (var i = 0; i < aEle.length; i++) {
            aEle[i].style.transform = "rotateY(" + (i * (360 / aEle.length)) + "deg) translateZ(" + radius + "px)";
            aEle[i].style.transition = "transform 1s";
            aEle[i].style.transitionDelay = delayTime || (aEle.length - i) / 4 + "s";
            pEle[i].style.transform = "rotateY(" + (i * (360 / aEle.length)) + "deg) translateY(" + radiusX + "px) translateZ(" + radius2 + "px) ";
            pEle[i].style.transition = "transform 1s";
            pEle[i].style.transitionDelay = delayTime || (aEle.length - i) / 4 + "s";
          }
        }
        
        function applyTranform(obj) {
          // Constrain the angle of camera (between 0 and 180)
          if(tY > 180) tY = 180;
          if(tY < 0) tY = 0;
        
          // Apply the angle
          obj.style.transform = "rotateX(" + (-tY) + "deg) rotateY(" + (tX) + "deg)";
        }
        
        function playSpin(yes) {
          ospin.style.animationPlayState = (yes?'running':'paused');
        }
        
        var sX, sY, nX, nY, desX = 0,
            desY = 0,
            tX = 0,
            tY = 10;
        
        // auto spin
        if (autoRotate) {
          var animationName = (rotateSpeed > 0 ? 'spin' : 'spinRevert');
          ospin.style.animation = `${animationName} ${Math.abs(rotateSpeed)}s infinite linear`;
        }
        
        // add background music
        if (bgMusicURL) {
          document.getElementById('music-container').innerHTML += `
        <audio src="${bgMusicURL}" ${bgMusicControls? 'controls': ''} autoplay loop>    
        <p>If you are reading this, it is because your browser does not support the audio element.</p>
        </audio>
        `;
        }
        
        // setup events
        document.onpointerdown = function (e) {
          clearInterval(odrag.timer);
          e = e || window.event;
          var sX = e.clientX,
              sY = e.clientY;
        
          this.onpointermove = function (e) {
            e = e || window.event;
            var nX = e.clientX,
                nY = e.clientY;
            desX = nX - sX;
            desY = nY - sY;
            tX += desX * 0.1;
            tY += desY * 0.1;
            applyTranform(odrag);
            sX = nX;
            sY = nY;
          };
        
          this.onpointerup = function (e) {
            odrag.timer = setInterval(function () {
              desX *= 0.95;
              desY *= 0.95;
              tX += desX * 0.1;
              tY += desY * 0.1;
              applyTranform(odrag);
              playSpin(false);
              if (Math.abs(desX) < 0.5 && Math.abs(desY) < 0.5) {
                clearInterval(odrag.timer);
                playSpin(true);
              }
            }, 17);
            this.onpointermove = this.onpointerup = null;
          };
        
          return false;
        };
        
        document.onmousewheel = function(e) {
          e = e || window.event;
          var d = e.wheelDelta / 20 || -e.detail;
          radius += d;
          init(1);
        };
       
    
    }
    },[people])
    
  return (
    <div className="card-container">
    <div id="drag-container">
        <div id="spin-container">
         
          {
              people&&people.map((v,i)=>(
                  <p key={i}>{v.name}</p>
              ))
          }

          
          {
              people&&people.map((v,i)=>(
               v.avatar ? <Link key={i} to={`/johnny/${v.route}`}>
                <img src={v.avatar} alt=""/>
               
              </Link>
                    :
                    <Link className='disabled-link'  key={i} to={`/profile/${v.id}`} >
                    <img src={v.image} alt=""/>
                   
                  </Link>
                
              ))
          }

         
        
        

        
          {/* <div id='house'>

          <Room/>
          </div> */}
         
          <p id='warp-p'>{ob.fullName}</p>
          {/* <p id='company'>{ob.fullName}</p> */}
        </div>
        <div id="ground" style={{backgroundImage:`url(${bg})`}}>
        </div>
      </div>
      
      <div id="music-container"></div>
      <div className="ob">
          <p id='company-chinese'>{ob.fullName}</p>

      </div>
      {/* <p id='company-des'>{ob.description}</p>
      <p id='company-title'>职务：{ob.title}</p> */}
        <img id='light' src='https://res.cloudinary.com/dlapk94rx/image/upload/v1654275310/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/Pngtree_sunlight_rays_glowing_effect_with_6485433_tjw7tf.png' alt="" />
    </div>
  )
}
