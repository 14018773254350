import React,{useState,useEffect}from 'react'
import {
    Star,
    CashCoin,
    Wechat,
    Fire,
    BadgeAd,
    Receipt,
    ChevronLeft,
    CardText
  } from "react-bootstrap-icons";

import axios from 'axios'
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from './CheckoutForm/CheckoutForm'
import {Navigate,useNavigate,Link} from 'react-router-dom'
import Nav from '../../components/Nav/index'
import Footer from '../../components/Footer/index'
import './index.scss'

const stripePromise = loadStripe("pk_test_51LX3TIEQ6Jizg69Re3M0PrASobmhWYZIZsSX60DwFTo9x0hZfYAwtfYxIM41mTGh3KEqULtPRxNQcMV9GsobKWAB00R6PU3UQm");

export default function UserPage() {

    const apiUrl ='https://metaserverapp.com'
   

    const token = window.localStorage.getItem('token')
    const userId = window.localStorage.getItem('userId')

    const [clientSecret, setClientSecret] = useState("");
    const [success, setSuccess] = useState(false);
    const [paymentId,setPaymentId] = useState('');
    const [next,setNext] = useState(false)
    const [email, setEmail] = useState('');
    const [amount,setAmount] = useState(30)


    const [target,setTarget]=useState({})
    const [isOpen,setIsOpen]=useState(false)
    const [user,setUser] = useState({
        name:''
    })
    const [showIndex,setShowIndex]=useState(-1)

    const [chargeIndex,setChargeIndex]=useState(0)
 
      const navigate = useNavigate();
        const handleLogout = ()=>{
        window.localStorage.setItem('userId','')
        window.localStorage.setItem('token','')
        navigate("/user/login");

    }
    useEffect(()=>{

        const fetchUser = async()=>{
            

            const result = await axios.get(`${apiUrl}/auth/user/id`,{
                params:{id:userId},
            })
            console.log(result);
            if(result.data.success){

                setUser(result.data.data)
            }
            else{
                alert('Something went wrong');
            }

        }
        if(userId){

            fetchUser()
        }

    },[userId])

    const data = [
   
        {
            name:'我的帖子',
            icon:<CardText/>,
        },
        {
            name:'我的收藏',
            icon:<Star/>,
        },
        {
            name:'我要充值',
            icon:<CashCoin/>,
        },
        {
            name:'我要置顶',
            icon:<Fire/>,
        },
        {
            name:'我的广告贴',
            icon:<BadgeAd/>
        },
        {
            name:'历史账单',
            icon:<Receipt/>
        },
        {
            name:'联系客服',
            icon:<Wechat/>,
        },
      
    ]

    const PRICE = [
        {
            name:30,
            price:30
        },
        {
            name:60,
            price:58
        },
        {
            name:90,
            price:86
        },
        {
            name:120,
            price:112
        },

    ]

    const handleOpenLife = (v)=>{
        setIsOpen(true)
        setTarget(v)
    }
    const handleCloseLife = ()=>{
        setIsOpen(false)

    }
    const handleShow=(i)=>{

        setShowIndex(i)
    }
    const handleBack=()=>{
        setShowIndex(-1)

    }

    const handleChangeCharge=(i)=>{
        setChargeIndex(i)
        setAmount(PRICE[i].price)
    }


    const appearance = {
        theme: 'stripe',
      };
      const options = {
        clientSecret,
        appearance,
      };

      const validateEmail = (i) => {
        return String(i)
          .toLowerCase()
          .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          );
      };

      const handleNext = async()=>{

      
        const valid = validateEmail(email)
        if(!valid){
            return alert('Please enter an valid email address');
        }
       
       
        const data =await axios.post(`${apiUrl}/order`,{
            amount:amount
        })
        console.log(data)
        setClientSecret(data.data.clientSecret);
        if(data.status===200){

            setNext(true)
        }
        else{
            alert('Error! Something went wrong!')
        }
      }

      const handleEmail= (e)=>{
          setEmail(e.target.value)
      }

console.log(amount,email);
    
  return (
<>
    <div className='user-wrap'>
       <Nav/>
       <div className='user-content2'>
            
            {user.name ?<div className='user-name-wrap'>
                <h3>欢迎回来 {user.name ?user.name : ''}!</h3>
                <h4>您的余额为:${user.point}</h4>
                </div> :
                <div className='user-name-wrap'>
                    <Link to='/user/login'><h3>现在登录/注册</h3></Link>
                </div>
                }
                
            {/* {
                data&&data.map((v,i)=>(
                    <button onClick={()=>handleOpenLife(v)} key={i}>
                        {v.name}
                    </button>
                ))
            } */}
            {showIndex===-1&&<div className='game-video-wrap'>
                {
                    data.map((v,i)=>(
                        <button onClick={()=>handleShow(i)} key={i}>
                            {v.icon}
                            <h4>{v.name}</h4>
                        </button>
                    ))
                }
            </div>}
            {
                showIndex===0&&<div className='ad-post-wrap'>
                    <h3 onClick={()=>handleBack()}><ChevronLeft/>  返回</h3>
                    <div>
                    <h2>我的帖子</h2>
                    {userId ? <div>

                    </div>:<h2>登录后可查看个人帖子</h2>}
                    </div>
                  
               
                </div>
            }

{
                showIndex===1&&<div className='ad-post-wrap'>
                    <h3 onClick={()=>handleBack()}><ChevronLeft/>  返回</h3>
                    <div>
                    <h2>我的收藏</h2>
                    {userId ? <div>

</div>:<h2>登录后可查看个人收藏</h2>}
                    </div>
                  
               
                </div>
            }
            {
                showIndex===2&&<div className='charge-wrap'>
                    <h3 onClick={()=>handleBack()}><ChevronLeft/>  返回</h3>
                    <div className='price-select'>
                    <h4>请选择充值金额</h4>
                        <div >
                        {
                        PRICE.map((v,i)=>(
                            <button onClick={()=>handleChangeCharge(i)} style={{backgroundColor:i===chargeIndex? 'white' :'ButtonShadow',color:'black',border:i===chargeIndex? '1px solid blue':''}} key={i}>
                               ${v.name}
                            </button>
  ))
                    }
                        </div>
                    </div>
                    <div className='select-price-wrap'>
                        <h4>您选择的充值金额为:${PRICE[chargeIndex].name}</h4>
                        <h4>折扣为:-${PRICE[chargeIndex].name-PRICE[chargeIndex].price}</h4>
                        <h4>实际支付:${PRICE[chargeIndex].price}</h4>
                        <div>
                        <label >邮箱*</label>
                        <input placeholder='请输入邮箱地址接收订单明细' type="text" value={email} onChange={(e)=>handleEmail(e)}/>
                        </div>
                     
                    </div>

                    {  success ? 
       <div className='success-wrap'>
           <div id='payment-success-wrap'>
           <h2>Payment Success!</h2>
           <h2>您的订单号为{paymentId}</h2>
           <h2>订单已发送至您的email信箱</h2>
           </div>
         
       </div> :
        ((next? (clientSecret && <Elements options={options} stripe={stripePromise}>
            <CheckoutForm amount={amount}  email={email} setSuccess={setSuccess} setUser={setUser} setPaymentId={setPaymentId} point={PRICE[chargeIndex].name} user={user} />
          </Elements>
        ): <button onClick={()=>handleNext()}>下一步</button>)
    )
    }
                  
               
                </div>
            }

{
                showIndex===3&&<div className='top-wrap'>
                    <h3 onClick={()=>handleBack()}><ChevronLeft/>  返回</h3>
                    <div>
                    <h2>置顶帖子30天/$30</h2>
                    <h4>选择你想置顶的帖子点击置顶即可</h4>
                    </div>
                  
               
                </div>
            }
            {
                showIndex===4&&<div className='ad-post-wrap'>
                    <h3 onClick={()=>handleBack()}><ChevronLeft/>  返回</h3>
                    <div>
                    <h2>我的广告贴</h2>
                    
                    </div>
                  
               
                </div>
            }
            {
                showIndex===5&&<div className='order-bill-wrap'>
                    <h3 onClick={()=>handleBack()}><ChevronLeft/>  返回</h3>
                    <div>
                    <h2>我的消费记录</h2>
                    
                    </div>
                  
               
                </div>
            }
             {
                showIndex===6&&<div className='wechat-wrap'>
                    <h3 onClick={()=>handleBack()}><ChevronLeft/>  返回</h3>
                    <div >
                    <h2>联系客服</h2>
                    <h4>电话:<a href='tel:+13015200518'>301-520-0518</a></h4>
                    <h4>微信号:122321172</h4>
                    <img src="https://res.cloudinary.com/dlapk94rx/image/upload/v1669748328/WechatIMG13454_bswwbu.jpg" alt="" />
                    </div>
            
                </div>
            }

          <div id='logout'>

          {showIndex===-1&&<button  onClick={()=>handleLogout()}>
                退出登录
          </button>}
          </div>

          

       </div>

       {isOpen&&<div style={{top:isOpen? '0':'220vh',transition:'1s'}} className='iframe-wrap'>
       <iframe title='metaLife' width={'100vw'} height='90vh' src={target.url}  frameBorder="0"></iframe>

       </div>}
     
           {isOpen ? <button style={{position:'absolute',bottom:'0'}} onClick={()=>handleCloseLife()}>关闭{target.name}页面</button>   :<Footer/>}
    </div> 
    
</>
  )
}


