import React,{useState,useEffect,useRef,Suspense,useCallback}from 'react'
import { Canvas,useLoader,useFrame} from '@react-three/fiber'
import * as THREE from "three";
import {useParams} from 'react-router-dom'
import {Link} from 'react-router-dom'
import {
  TelephoneFill,
  GeoAlt,
  Wechat,
  Envelope,
  Globe2
} from "react-bootstrap-icons";
import axios from 'axios';


import {OrbitControls,Line,useFBX} from '@react-three/drei'

import './index.scss'

function Japan(){
  const mesh = useRef()
  const model = {
    name:'japan',
    url:'https://res.cloudinary.com/dvr397xda/raw/upload/v1662070813/cube/kinha%20sushi/Japanese_Garden_Scene_kkqvcn.fbx',
    metalMap:'',
    normalMap:'https://res.cloudinary.com/dvr397xda/image/upload/v1659726267/cube/Happy%20Town/model_normal_tsbl8o.jpg',
    roughnessMap:'',
    colorMap:'https://res.cloudinary.com/dvr397xda/image/upload/v1659726193/cube/Happy%20Town/model_avhsp5.jpg',
    aoMap:'',
    scale:0.0015,
    position:[0,-1,0],
    rotation:[0,0,0],
}
  let key = useFBX(model.url)
  const [colorMap,  normalMap] = useLoader(THREE.TextureLoader, [
   model.colorMap,
  
    model.normalMap,
   
  ])
  key.traverse( function ( child ) {

    if ( child.isMesh ) {
        
        child.material.map = colorMap;
       
    
     
        child.material.normalMap = normalMap;
        
     
      }
  
  } );
  useFrame((state, delta) => {
  

    mesh.current.rotation.y += 0.005
  
  })

  return (
    <mesh scale={model.scale} position={model.position} rotation={model.rotation}  ref={mesh}>
        <primitive   object={key} />

    </mesh>
  )


}

function Cookie(){
    const mesh = useRef()
    
    let key = useFBX('https://res.cloudinary.com/dvr397xda/raw/upload/v1659726272/cube/Happy%20Town/model_kjh542.fbx')

  
    const [colorMap,  normalMap] = useLoader(THREE.TextureLoader, [
        'https://res.cloudinary.com/dvr397xda/image/upload/v1659726193/cube/Happy%20Town/model_avhsp5.jpg',
        'https://res.cloudinary.com/dvr397xda/image/upload/v1659726267/cube/Happy%20Town/model_normal_tsbl8o.jpg'
      ])
    


    key.traverse( function ( child ) {

        if ( child.isMesh ) {
        
            child.material.map = colorMap;
         
         
            child.material.normalMap = normalMap;
         
          }
      
      } );
      useFrame((state, delta) => {
      
    
        mesh.current.rotation.y += 0.005
      
      })

      return (
        <mesh scale={3} position={[0,0,0]} rotation={[0,0,Math.PI / 2.5]}  ref={mesh}>
            <primitive   object={key} />
    
        </mesh>
      )

}

const  Model=({people})=>{
   
  
   if(people.model==='japan'){
    return <Japan/>
  }

  
  else{
    return <Cookie/>
  }
}


function Box(props) {
  const {people,position} = props
  const mesh = useRef()

  let textures;

textures = useLoader(THREE.TextureLoader,[people.images[0],people.images[1],people.images[2],people.images[3],people.images[4],people.images[5]])


textures.minFilter = textures.magFilter = THREE.LinearFilter;



textures.format= THREE.RGBAFormat;




  function Map (){
    return(
    
      textures.map((texture, idx) =>(
        idx ===2||idx===3 || idx===0?<meshBasicMaterial key={idx} attach={`material-${idx}`} map={texture}   transparent={true} opacity={1}/>
        :<meshBasicMaterial opacity={1} transparent={true}  key={idx} attach={`material-${idx}`} map={texture}  side={THREE.DoubleSide}  />
        )
)
    )
  } 

  return (
    <>
    <mesh
      onClick={(e)=>{
        e.stopPropagation()
      }}
      ref={mesh}
     position={position}
     
      >
          
      <boxBufferGeometry attach="geometry"  args={[2.5, 2.5, 2.5]}/>
      <Map/>
       
      

    </mesh>


    </>
  )
}

const Phone = ({people,opacity,posY})=>{

  const mesh1 = useRef()


  const handleClick=()=>{
    window.open(`tel:+1${people.tel}`,'_self')
  }


  return (
    <>
    <mesh position={[-0.8,posY-1.65,1.3]} rotation={[0,Math.PI * 2.5,0]} onClick={(e)=>{ handleClick();     e.stopPropagation()
}}   ref={mesh1}>
        <boxBufferGeometry  attach="geometry"  args={[0.1,1.1,0.8]}/>
        <meshStandardMaterial transparent={true} opacity={opacity}   color='green'  />

       


    </mesh>
    </>
  )
}

const Map = ({people,opacity,posY})=>{
 
  const mesh1 = useRef()


  const handleClick=()=>{

    window.open(`${people.address}`,'_self')
  }

  return (
    <>
    <mesh rotation={[0,Math.PI * 2.5,0]} position={[0,-1.65+posY,1.3]} onClick={(e)=>{ handleClick();     e.stopPropagation()
}}   ref={mesh1}>
        <boxBufferGeometry  attach="geometry"  args={[0.1,1.1,0.8]}/>
        <meshStandardMaterial transparent={true} opacity={opacity}   color='blue'   />

       


    </mesh>
    </>
  )
}



  const QR = ({opacity,posY,handleOpenQr})=>{
  
    const mesh1 = useRef()
  
  
  
    return (
      <>
      <mesh rotation={[0, Math.PI * 2.5, 0]} position={[0,-1+posY,-1.3]} onClick={(e)=>{  handleOpenQr();     e.stopPropagation()
  }}   ref={mesh1}>
          <boxBufferGeometry  attach="geometry"  args={[0.1,2.5,2.5]}/>
          <meshStandardMaterial transparent={true} opacity={opacity}   color='green'   />
  
         
  
  
      </mesh>
      </>
    )
  }



const Web = ({people,opacity,posY})=>{
 
  const mesh1 = useRef()

  const handleClick=()=>{
    window.open(`${people.website}`,'_self')
  }

  return (
    <>
    <mesh rotation={[0, Math.PI * 2.5, 0]} position={[0.8,-1.65+posY,1.3]} onClick={(e)=>{ handleClick();     e.stopPropagation()
}}   ref={mesh1}>
        <boxBufferGeometry  attach="geometry"  args={[0.1,1.1,0.8]}/>
        <meshStandardMaterial transparent={true} opacity={opacity}   color='#fff'   />

       


    </mesh>
    </>
  )
}

const Web2 = ({people,opacity,posY})=>{
 
  const mesh1 = useRef()

  const handleClick=()=>{
    window.open(`${people.website2}`,'_self')
  }

  return (
    <>
    <mesh rotation={[0, 0, 0]} position={[-1.3,posY-1,0]} onClick={(e)=>{ handleClick();     e.stopPropagation()
}}   ref={mesh1}>
        <boxBufferGeometry  attach="geometry"  args={[0.1,2.5,2.5]}/>
        <meshStandardMaterial transparent={true} opacity={opacity}   color='#fff'   />
    </mesh>
    </>
  )
}


const Gallery = ({gallery})=>{

  const mesh1 = useRef()
  const [index,setIndex] = useState(0)



  useEffect(() => {
      const interval = setInterval(() => {
  
          if(index ===gallery.length-1){
              setIndex(0)
          }
          else{
              setIndex(index+1)
          }
      }, 2500);
      return () => clearInterval(interval);
    }, [index,gallery.length]);


  
    let texture;
    texture =useLoader(THREE.TextureLoader,gallery[index])
    texture.format= THREE.RGBAFormat;
    texture.minFilter = THREE.LinearFilter;
    texture.magFilter = THREE.LinearFilter;
 

  


  return (
   
    <mesh position={[1.26,0,0]} rotation={[0,0,0]} onClick={(e)=>{     e.stopPropagation()
}}   ref={mesh1}>
        <boxBufferGeometry  attach="geometry"  args={[0.01,2.4,2.4]}/>
        <meshStandardMaterial map={texture}  side={THREE.DoubleSide}   />
    </mesh>

  )
}

const Group = ({people,handleOpenQr})=>{

   
    
      const mesh = useRef()
      const [v,setV]= useState(1)
      const opacity=0
      const posY = 1
      useFrame(({clock}) => {
      
        setV(Math.abs(Math.sin(clock.elapsedTime)/0.8))
    
        mesh.current.rotation.y += 0.001
      
      })

      return(<group ref={mesh}>
       
       <Box people={people}   position={[0, 0, 0]} />
       <Phone posY={posY} people={people} opacity={opacity}/>

   
{people.menu&&<QR handleOpenQr={handleOpenQr} posY={posY} people={people} opacity={opacity}/>}
<Map posY={posY} people={people} opacity={opacity}/>
{people.website&&<Web posY={posY} people={people} opacity={opacity}/>}
{people.website2&&<Web2 posY={posY} people={people} opacity={opacity}/>}

{
  people.gallery.length>0&&<Gallery gallery={people.gallery} />
}
            </group>)

}

export default function Merchant() {
    const{ name } = useParams();
    const apiUrl ='https://metaserverapp.com'

    const initial = {
        id:''
    }
    
    const [people,setPeople]=useState(initial)

   useEffect(()=>{

   

    const fetchCard = async()=>{
        const result = await axios.get(`${apiUrl}/merchant/id`,{
            params:{id:name}
        })
        console.log(result)
        if(result.data.success){
            setPeople(result.data.data)
        }
    }
    fetchCard()
   },[])





 
  const [isQR,setIsQR] = useState(false)

 
  const handleCloseTeam = ()=>{
    setIsQR(false)
  }

  const handleOpenQr = ()=>{
    setIsQR(true)
  }


 


  return (
    <div className="container-card" style={{backgroundImage:`url(${people.bgImage})`}}  >
           {people.isFree&&<Link id='return-home' to='/recommand'><img src="https://res.cloudinary.com/dvr397xda/image/upload/v1664834364/cube/Free/metalogo_ctuhtw.png" alt="" /></Link>}
     
     <Canvas camera={{ position: [0, 0, 4.5] }}  gl={{ alpha: true, antialias: true}}  className='canvas-wrap'>
     <OrbitControls minPolarAngle={Math.PI / 2} maxPolarAngle={Math.PI / 2.5} />
     <ambientLight  />


      <Suspense fallback={null}>
   
      <Model people={people}/>

        
      
    
      {people.id&&<Group handleOpenQr={handleOpenQr} people={people}  position={[0, 0, 0]}/>}
    
    </Suspense>

    </Canvas>

          <div style={isQR ? {top:'0',transition:'1.5s'}:{top:'200%',transition:'1.5s'}} id='menu-wrap'  className="wrapper">
          <img id='qr' src={people.menu} alt="" />
          {/* <h3>长按识别图中二维码</h3> */}

          <img onClick={()=>handleCloseTeam()} id='close' src="https://res.cloudinary.com/dvr397xda/image/upload/v1656101942/cube/cancel_dmukx0.png" alt="" />
    </div>

   

    </div>
  )
}

