import React,{useState,useEffect} from 'react'
import {Link,useNavigate } from 'react-router-dom'
import axios from 'axios'
import Select from 'react-select'
import Nav from '../../../components/Nav/index'
import Footer from '../../../components/Footer/index'

import './index.scss'


export default function Login() {


    const apiUrl ='https://metaserverapp.com'
    const navigate = useNavigate();

    const [phone,setPhone] = useState('')
    const [password,setPassword] = useState('')

   


    const handlePhone =(e)=>{ 
        setPhone(e.target.value)
    }
    const handlePassword =(e)=>{ 
        setPassword(e.target.value)
    }


    const handleLogin =async()=>{
        if(!password){
            alert('Please enter a password')
        }
        if(!phone){
            alert('Please enter a phone number')
        }
        const result = await axios.get(`${apiUrl}/auth/user`,{params: {password,phone}})
        // console.log(result);
       
        if(result.data.success){
            window.localStorage.setItem('token',result.data.token)
            window.localStorage.setItem('userId',result.data.user._id)
            navigate("/user/dashboard");
            window.sessionStorage.setItem('token',result.data.token)

        }
        else{
            alert('Invalid phone or password!')
        }
    }

  return (
      <> <div className="user-wrap">
          <Nav/>
    <div className="user-login-wrap2">
    
<div className="form-structor">
	<div className="signup">
		<h2 className="form-title" id="signup"><span></span>用户登录</h2>
        <Link to='/user/register' ><h3>
        没有账户，现在注册</h3></Link>
		<div className="form-holder">
		
            <input required={true} value={phone} onChange={(e)=>handlePhone(e)} type="text" className="input" placeholder="Phone" />

			<input required={true} value={password} onChange={(e)=>handlePassword(e)} type="password" className="input" placeholder="Password" />

           


		</div>
    
		<button onClick={()=>handleLogin()} className="submit-btn">Login</button>
	</div>

</div>
    </div>
    </div></>
  )
}





function UserSignUp({handleSelectLogin}) {

    const apiUrl ='https://metaserverapp.com'
    const navigate = useNavigate();


    const [location,setLocation] = useState('')
    const [category,setCategory] = useState('')
    const [phone,setPhone] = useState('')
    const [email,setEmail] = useState('')
    const [password,setPassword] = useState('')
    const [gender,setGender] = useState('')
    const [name,setName] =useState('')

    const locations =[
        {
            value:'曼哈顿',
            label:'曼哈顿',
        },
        {
            value:'布鲁克林',
            label:'布鲁克林',
        },
        {
            value:'法拉盛',
            label:'法拉盛',
        },
        {
            value:'Elmhurst',
            label:'Elmhurst',
        },
        {
            value:'Staten Island',
            label:'Staten Island',
        },
        {
            value:'Long Island City',
            label:'Long Island City',
        },
        {
            value:'Long Island',
            label:'Long Island',
        },
       {
            value:'其它地区',
            label:'其它地区',
    
        }
    ]
    
    const categorys = [
        {
            value:'餐饮',
            label:'餐饮',
        },
        {
            value:'地产经纪',
            label:'地产经纪',
        },
        {
            value:'律师',
            label:'律师',
        },
        {
            value:'汽车买卖',
            label:'汽车买卖',
        },
        {
            value:'保险',
            label:'保险',
        },
        {
            value:'贷款',
            label:'贷款',
        },
        {
            value:'会计',
            label:'会计',
        },
        {
            value:'搬家',
            label:'搬家',
        },
        {
            value:'旅行',
            label:'旅行',
        },
        {
            value:'驾校',
            label:'驾校',
        },
        {
            value:'装修',
            label:'装修',
            
    
        },
        {
            value:'医生',
            label:'医生',
        },
        {
            value:'美容',
            label:'美容',
        },
        {
            value:'按摩',
            label:'按摩',
        },
        {
            value:'美甲',
            label:'美甲',
        },
        {
            value:'移民',
            label:'移民',
        },
        {
            value:'印刷',
            label:'印刷',
        },
        {
            value:'婚庆',
            label:'婚庆',
        },
        {
            value:'摄影',
            label:'摄影',
        },
        {
            value:'设计',
            label:'设计',
        },
        {
            value:'物流',
            label:'物流',
        },
        {
            value:'其它',
            label:'其它',
        },
    ]
    
    const genders = [
        { value: '男', label: '男' },
        { value: '女', label: '女' },
        { value: '不想回答', label: '不想回答' },
    
    ]

    const handleLocation = (e)=>{
        setLocation(e.value)
    }

    const handleCategory = (e)=>{
        setCategory(e.value)
    }

    const handleGender = (e)=>{
        setGender(e.value)
    }

    const handlePhone=(e)=>{ 
        setPhone(e.target.value)
    }
    const handleEmail = (e)=>{ 
        setEmail(e.target.value)
    }
    const handlePassword = (e)=>{ 
        setPassword(e.target.value)
    }
    const handleName=(e)=>{ 
        setName(e.target.value)
    }

   

    console.log(phone,password,email,gender,location,category,name)

    const handleSubmit =async()=>{
        if(!phone){
            alert('Please enter a phone number')
        }
        if(!name){
            alert('Please enter a  name')
        }
        
        if(!email){
            alert('Please enter a email')
        }
        if(!gender){
            alert('Please enter a gender')
        }
        if(!category){
            alert('Please enter a category')
        }
        if(!location){
            alert('Please enter a location')
        }
        if(!password){
            alert('Please enter a Password')
        }

        const result = await axios.post(`${apiUrl}/auth/user`,{
            phone,name,email,gender,category,location,password
        })
        // console.log(result);

        if(result.data.success){
            window.localStorage.setItem('token',result.data.token)
            window.localStorage.setItem('userId',result.data._id)
            navigate("/user/dashboard");
        }
        else{
            alert(`error ${result.data.data}`)
        }

    }

  

  return (
      <>
      <div className="user-wrap">
        <Nav/>
     
    <div className="user-login-wrap">

<div className="form-structor">
	<div className="signup">
		<h2 className="form-title" id="signup"><span></span>注册账户</h2>
        <h3 onClick={()=>handleSelectLogin()} className="form-title2">已有账户，现在登录</h3>
        <div className='login-select-wrap'>
            <div className="create-input-wrap">
        <label>地区*</label>
        <Select           
        onChange={(e)=>handleLocation(e)}
        options={locations} 
        maxMenuHeight='20vh'
        menuPortalTarget={document.body}
        menuPosition="fixed"
        />
    </div>

    <div className="create-input-wrap">
        <label>分类*</label>
        <Select
        onChange={(e)=>handleCategory(e)}
        options={categorys}    menuPortalTarget={document.body}
        menuPosition="fixed"       maxMenuHeight='20vh'
        />
        
    </div>
    <div className="create-input-wrap">
        <label>性别*</label>
        <Select
        onChange={(e)=>handleGender(e)}
        options={genders}      menuPortalTarget={document.body}
        menuPosition="fixed"     maxMenuHeight='20vh'
        />
    </div>
            </div>
		<div className="form-holder">
			<input value={name} onChange={(e)=>handleName(e)} type="text" className="input" placeholder="昵称" />
          
            <input value={phone} onChange={(e)=>handlePhone(e)}  type="text" className="input" placeholder="Phone" />

			<input value={email} onChange={(e)=>handleEmail(e)} type="email" className="input" placeholder="Email" />
			<input value={password} onChange={(e)=>handlePassword(e)} type="password" className="input" placeholder="Password" />

           


		</div>
      
		<button onClick={()=>handleSubmit()} className="submit-btn">Sign up</button>
	</div>

</div>

    </div>
    </div>
    </>
  )
}