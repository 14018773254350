import React from 'react'


export default function PrivacyPage(){

    return(
        <div style={{'backgroundColor':'white'}}>
            <h2>
            隐私政策 <br />
            </h2>
            <p style={{'backgroundColor':'white','color':'black','textAlign':'left','padding':'20px'}}>
            感谢您使用我们的应用程序元社区！本隐私政策旨在向您解释我们如何收集、使用、披露、共享、保留和删除用户和设备数据。 <br />

数据收集与使用 <br /> <br />
我们可能会收集以下类型的用户和设备数据：<br /> <br />

设备信息：我们可能会收集您的设备标识符、操作系统版本、设备设置和其他与您的设备相关的信息，以便提供和改进我们的服务。<br /> <br />

我们的应用程序利用TrueDepth API访问某些功能。我们收集和处理TrueDepth数据的目的如下： <br /> <br />
当用户在AR模式下查看名片时，我们使用TrueDepth API使虚拟卡片位于摄像头视图的中心。 为此，我们需要在用户打开摄像头时准确呈现位置。我们保证不会将TrueDepth数据存储和共享给任何人. <br /> <br />

日志信息：当您使用我们的应用时，我们可能会自动收集和存储某些信息，包括您的互动活动、错误日志和其他系统活动日志。<br /> <br />

个人身份信息：我们的应用程序不会直接收集您的个人身份信息，如姓名、地址或电子邮件地址。然而，某些功能可能需要您提供个人信息，例如在注册或使用特定功能时。<br /><br />

数据处理目的<br /><br />
我们收集和使用用户和设备数据的目的包括但不限于以下方面：<br /> <br />

提供和改进服务：我们使用收集的数据来提供和改进我们的应用功能和服务，并为您提供个性化的体验。<br /> <br />

分析和研究：我们可能使用收集的数据进行统计分析和研究，以了解用户行为和趋势，从而改进我们的产品和服务。<br /> <br />

安全与保护：我们采取适当的安全措施来保护用户和设备数据，并防止未经授权的访问、使用或披露。<br /> <br />

数据披露与共享<br /> <br />
我们不会将您的用户和设备数据出售、出租或交易给第三方。并且，我们不会与以下实体共享数据：<br /> <br />

合作伙伴和服务提供商：我们不会和合作伙伴和第三方服务提供商共享数据. <br /> <br />

法定要求：我们可能根据适用的法律法规、法庭命令或政府要求披露数据。<br /> <br />

数据保留与删除 <br /> <br />
我们将根据业务需要和适用的法律要求保留您的用户和设备数据。一旦不再需要，我们将采取合理的步骤将数据安全地删除或匿名化。<br /> <br />

用户权利与选择 <br /> <br />
您可以根据适用的法律要求行使以下权利：<br /> <br />

访问、更正和更新您的个人信息。<br /> <br />

撤回同意：您可以随时撤回您对收集和使用您的数据的同意。 <br /> <br />

数据删除：在符合适用法律的情况下，您可以要求删除您的用户数据。 <br /> <br />


隐私政策的更新 <br /> <br />
我们可能会不时更新隐私政策。在我们进行重大更改时，我们将通过适当的方式通知您，并要求您重新阅读和同意更新的隐私政策。<br /> <br />

联系我们 <br /> <br />
如果您对我们的隐私政策或数据处理有任何疑问、意见或投诉，请通过以下联系方式与我们联系：weiizappweiiz@gmail.com <br /> <br />

元社区可能会不时更新或修订本政策。您同意定期审查本政策。如果我们对本政策进行任何更改，我们将更改上面的“上次更新”日期。您可以自由决定是否接受本政策的修改版本，但接受修改后的本政策是您继续使用我们网站所必需的。如果您不同意本政策的条款或本政策的任何修改版本，您唯一的追索权是终止您对我们网站的使用。
            </p>
        </div>
    )
}