import React,{useState} from 'react'
import {Link} from 'react-router-dom'
import Home from '../home/index'
import './index.scss'

export default function Damn() {
    
    const data = [
        {
           name:'david',
           img:'https://res.cloudinary.com/dvr397xda/image/upload/v1657645206/cube/WechatIMG8972_vixopm.png',
           url:'/companys/david', 
            
        },
        {
            name:'Lily Zou',
            img:'https://res.cloudinary.com/dvr397xda/image/upload/v1659111892/cube/basic/lily/WechatIMG9519_rxbycm.png',
            url:'/basic/lily-zou', 
             
         },
         {
            name:'wingo',
            img:'https://res.cloudinary.com/dvr397xda/image/upload/v1659471006/cube/basic/wingo/WechatIMG9659_lvmp8b.png',
            url:'/basic/wingo', 
             
         },
         {
            name:'johnny',
            img:'https://res.cloudinary.com/dvr397xda/image/upload/v1659548115/cube/WechatIMG9669_iqddcs.png',
            url:'/basic/johnny', 
             
         },
         {
            name:'Ben',
            img:'https://res.cloudinary.com/dvr397xda/image/upload/v1661192021/cube/basic/ben/WechatIMG10090_supum2.png',
            url:'/realty/ben', 
             
         },
         {
             name:'star',
             img:'https://res.cloudinary.com/dvr397xda/image/upload/v1659132272/cube/basic/yuxin/WechatIMG9537_maonje.png',
             url:'/basic2/star-chen', 
              
          },
          {
             name:'groupchop',
             img:'https://res.cloudinary.com/dvr397xda/image/upload/v1658522322/cube/%E4%B8%80%E8%B5%B7%E5%88%80358e21_cr2mtd.png',
             url:'/johnny/groupchop', 
              
          },
          {
            name:'huoli',
            img:'https://res.cloudinary.com/dvr397xda/image/upload/v1658522322/cube/%E6%B4%BB%E5%8A%9B8e7321_u84bpm.png',
            url:'/johnny/huoli', 
             
         },
         {
             name:'meta',
             img:'https://res.cloudinary.com/dvr397xda/image/upload/v1658522322/cube/%E5%85%83%E5%AE%87%E5%AE%99%E5%90%8D%E7%89%870072ff_xwc9v3.png',
             url:'/johnny/meta', 
              
          },
          {
             name:'nicrg',
             img:'https://res.cloudinary.com/dvr397xda/image/upload/v1658531041/cube/WechatIMG9355_vxjodt.png',
             url:'/johnny/nicrg', 
              
          },
          {
            name:'weiiz',
            img:'https://res.cloudinary.com/dvr397xda/image/upload/v1658531780/cube/WechatIMG9358_wcptqy.png',
            url:'/johnny/weiiz', 
             
         },
         {
             name:'world',
             img:'https://res.cloudinary.com/dvr397xda/image/upload/v1658522322/cube/%E5%BE%AE%E4%B8%96%E7%95%8Ca7a7a7_ck75jp.png',
             url:'/johnny/world', 
              
          },
          {
             name:'xintian',
             img:'https://res.cloudinary.com/dvr397xda/image/upload/v1658599200/cube/WechatIMG9367_vxqtwh.png',
             url:'/johnny/xintian', 
              
          },
          {
            name:'boss',
            img:'https://res.cloudinary.com/dvr397xda/image/upload/v1659557107/cube/boss_ktttz5.png',
            url:'/boss/johnny', 
             
         },
         {
             name:'watch',
             img:'https://res.cloudinary.com/dvr397xda/image/upload/v1659557246/cube/watch_d1u2sr.png',
             url:'/case', 
              
          },
          {
             name:'lambo',
             img:'https://res.cloudinary.com/dvr397xda/image/upload/v1659557443/cube/lambo_iieqc1.png',
             url:'/car/lambo', 
              
          },
          {
            name:'porche',
            img:'https://res.cloudinary.com/dvr397xda/image/upload/v1659557442/cube/porche_uqhtq8.png',
            url:'/car/porche', 
             
         },
         {
             name:'porche',
             img:'https://res.cloudinary.com/dvr397xda/image/upload/v1659557444/cube/porche1_bmnygw.png',
             url:'/car/porche1', 
              
          },
          {
            name:'massage-palace',
            img:'https://res.cloudinary.com/dvr397xda/image/upload/v1659449114/cube/basic/massage%20palace/WechatIMG9644_e5fy4r.png',
            url:'/basic3/massage-palace', 
             
         },
         {
            name:'yanxiong',
            img:'https://res.cloudinary.com/dvr397xda/image/upload/v1653337703/cube/%E8%A8%80png_smtizs.png',
            url:'/profile/yanxiong', 
         },
         {
            name:'philip',
            img:'https://res.cloudinary.com/dvr397xda/image/upload/v1655308640/cube/WechatIMG8423_dxymep.jpg',
            url:'/person/philip',
        },
         {
            name:'michael-lin',
            img:'https://res.cloudinary.com/dvr397xda/image/upload/v1659644318/cube/hunqing/WechatIMG9691_fkyu0h.png',
            url:'/pro/michael-lin',
        },
        {
            name:'happytown',
            img:'https://res.cloudinary.com/dvr397xda/image/upload/v1659649112/cube/Happy%20Town/WechatIMG9663_odwa1y.png',
            url:'/restaurant/happytown',
        },
        {
         name:'happytown',
         img:'https://res.cloudinary.com/dvr397xda/image/upload/v1659649112/cube/Happy%20Town/WechatIMG9663_odwa1y.png',
         url:'/restaurant2/happytown',
     },
     {
      name:'business-card',
      img:'https://res.cloudinary.com/dvr397xda/image/upload/v1660075871/cube/business%20card/WechatIMG9781_isnmra.jpg',
      url:'/business/lily-zou',
  },
  {
   name:'business-card',
   img:'https://res.cloudinary.com/dvr397xda/image/upload/v1661288152/cube/basic/ben/WechatIMG10131_limsj0.png',
   url:'/basic/ben',
},
{
   name:'business-card',
   img:'https://res.cloudinary.com/dvr397xda/image/upload/v1661461585/cube/hunqing/WechatIMG10184_szrmxw.png',
   url:'/pro/lily-zou',
},
{
   name:'business-card',
   img:'https://res.cloudinary.com/dvr397xda/image/upload/v1661537165/cube/wanghong/WechatIMG10263_ki1rbe.png',
   url:'/poster/tj',
},
{
   name:'happytown',
   img:'https://res.cloudinary.com/dvr397xda/image/upload/v1661980476/cube/kinha%20sushi/WechatIMG10334_o4jmvf.png',
   url:'/restaurant/kinhasushi',
},
         
    ]

    const [isList,setIsList] = useState(true)

    const handleChangeList = () =>{

        setIsList(!isList)
    }

    const list = 'https://res.cloudinary.com/dvr397xda/image/upload/v1659561205/cube/forms_wg0tej.png'

    const earth = 'https://res.cloudinary.com/dvr397xda/image/upload/v1659561205/cube/galaxy_slgsga.png'

  return (


    <>
    <img onClick={()=>handleChangeList()} id='switch' src={isList ? earth : list} alt="" />
        {
            isList ? <div id='checkList'>
                <div>
                {
                    data&&data.map((v,i)=>(
                        <Link key={i} to={v.url}>
                         
                                <img src={v.img} alt="" />
                        </Link>
                    ))
                }
                </div>
              
            </div>
            :
            <Home/>
        }
    </>


  )
}
