const location = [
  
            { 
                value:'纽约',
                label:'纽约',

                location:[
                    {
                        value:'法拉盛',
                        label:'法拉盛',
                    },
                    {
                        value:'皇后区',
                        label:'皇后区',
                    },
                    {
                        value:'曼哈顿',
                        label:'曼哈顿',
                    },
                    {
                        value:'布鲁伦',
                        label:'布鲁伦',
                    },
                    {
                        value:'长岛',
                        label:'长岛',
                    },
                   
                    {
                        value:'史登岛',
                        label:'史登岛',
                    },
                ]
            },
        
    

]

const fetchLocation = ()=>{
    return location
}

export default fetchLocation;
