const data = [
    { 
        id:'tj',
        name:'tj',
        tel:'7187108171',
        address:'33-70 Prince St, Queens, NY 11354',
        map:'https://goo.gl/maps/hZHX6p26RY1tySnZA',
        video:'https://res.cloudinary.com/dvr397xda/video/upload/v1661533399/cube/wanghong/title-artist_up6t5m.mp4',
        title:'梦幻传媒',
        title2:'网红公司',
        website:'https://nyjewelry.net/',
        website2:'https://www.calerie.com/',
        website3:'https://usosu.org/',
        website4:'https://dyf-entertainment.net/en/',
        website5:'https://metalive888.com/',
        images:[
            'https://res.cloudinary.com/dvr397xda/image/upload/v1658518055/cube/weiiz/WechatIMG9330_gm5mez.png', //right
            'https://res.cloudinary.com/dvr397xda/image/upload/v1658518055/cube/weiiz/WechatIMG9330_gm5mez.png', //left
            'https://res.cloudinary.com/dvr397xda/image/upload/v1658518055/cube/weiiz/WechatIMG9330_gm5mez.png', //glass
            'https://res.cloudinary.com/dvr397xda/image/upload/v1658518055/cube/weiiz/WechatIMG9330_gm5mez.png', //glass
            'https://res.cloudinary.com/dvr397xda/image/upload/v1664568528/cube/wanghong/WechatIMG11470_rjs3p8_stfiv5.png', //front
            'https://res.cloudinary.com/dvr397xda/image/upload/v1664568504/cube/wanghong/WechatIMG10263_ki1rbe_gakgsu.png'//back
    ],
    qrCode:'https://res.cloudinary.com/dvr397xda/image/upload/v1661525475/cube/wanghong/WechatIMG10259_sv5gdy.png',
    isMusic:true,
    music:[{
        album:'周杰伦',
        name:'简单爱',
        track:'Jay', 
        url:'https://res.cloudinary.com/dvr397xda/video/upload/v1661202691/cube/basic/ben/official-music-video_nsd8gr.mp3', 
        image:'https://res.cloudinary.com/dvr397xda/image/upload/v1660938134/music/cd-player_1_nxixox.png'
    }],
    isGallery:true,
    gallery:[
        'https://res.cloudinary.com/dvr397xda/image/upload/v1661464133/cube/wanghong/WechatIMG755_numjx4.jpg',
        'https://res.cloudinary.com/dvr397xda/image/upload/v1661466406/cube/wanghong/1-2111261125561P_caccdg.jpg',
        'https://res.cloudinary.com/dvr397xda/image/upload/v1661464138/cube/wanghong/WechatIMG756_gypkbn.jpg',
    ],
    gif:'https://res.cloudinary.com/dvr397xda/image/upload/v1664561314/cube/wanghong/girl-g0af5adf35_1920_apncjh.jpg',
    videoImg:'https://res.cloudinary.com/dvr397xda/image/upload/v1667327045/cube/wanghong/WechatIMG12566_yxsev4.jpg'
    },
    { 
        id:'johnny',
        name:'intro',
        title:'Johnny Chen',
        title2:'Xintian CEO',

        tel:'8453036348',
        address:'4306 Main St,Flushing,NY,11355',
        map:'https://www.google.com/maps/place/43-06+Main+St,+Flushing,+NY+11355/@40.7522664,-73.8315613,17z/data=!3m1!4b1!4m5!3m4!1s0x89c2606cd640cb53:0x141db878e9815fe!8m2!3d40.7522665!4d-73.8270766',
        video:'https://res.cloudinary.com/dvr397xda/video/upload/v1662755302/cube/poster-intro/1662754939768031_epmg77.mp4',
        website:'https://meta11w.com/johnny/xintian',
        website2:'https://meta11w.com/basic/lily-zou',
        website3:'https://meta11w.com/basic2/star-chen',
        website4:'https://meta11w.com/companys/david',
        website5:'',

        images:[
            'https://res.cloudinary.com/dvr397xda/image/upload/v1658518055/cube/weiiz/WechatIMG9330_gm5mez.png', //right
            'https://res.cloudinary.com/dvr397xda/image/upload/v1658518055/cube/weiiz/WechatIMG9330_gm5mez.png', //left
            'https://res.cloudinary.com/dvr397xda/image/upload/v1658518055/cube/weiiz/WechatIMG9330_gm5mez.png', //glass
            'https://res.cloudinary.com/dvr397xda/image/upload/v1658518055/cube/weiiz/WechatIMG9330_gm5mez.png', //glass
            'https://res.cloudinary.com/dvr397xda/image/upload/v1662757075/cube/poster-intro/WechatIMG10537_o1zmkb.jpg', //front
            'https://res.cloudinary.com/dvr397xda/image/upload/v1662757075/cube/poster-intro/WechatIMG10535_tpgspu.jpg'//back
    ],
    qrCode:'https://res.cloudinary.com/dvr397xda/image/upload/v1662755295/cube/poster-intro/WechatIMG10518_dwgejf.png',
    isMusic:true,
    music:[{
        album:'周杰伦',
        name:'简单爱',
        track:'Jay', 
        url:'https://res.cloudinary.com/dvr397xda/video/upload/v1661202691/cube/basic/ben/official-music-video_nsd8gr.mp3', 
        image:'https://res.cloudinary.com/dvr397xda/image/upload/v1660938134/music/cd-player_1_nxixox.png'
    }],
    isGallery:true,
    gallery:[
        'https://res.cloudinary.com/dvr397xda/image/upload/v1662755998/cube/poster-intro/WechatIMG10531_eylbdb.jpg',
        'https://res.cloudinary.com/dvr397xda/image/upload/v1662755998/cube/poster-intro/WechatIMG10530_kpeoyz.jpg',
        'https://res.cloudinary.com/dvr397xda/image/upload/v1662755998/cube/poster-intro/WechatIMG10532_it65m7.jpg',
        'https://res.cloudinary.com/dvr397xda/image/upload/v1662755998/cube/poster-intro/WechatIMG10529_xie5c3.jpg'
    ],

    gif:'https://res.cloudinary.com/dvr397xda/image/upload/v1662755525/cube/poster-intro/WechatIMG10528_h3txjs.jpg',
    videoImg:'https://res.cloudinary.com/dvr397xda/image/upload/v1662756254/cube/poster-intro/WechatIMG10534_ksyert.jpg'
    },
    { 
        id:'lily',
        name:'intro',
        title:'Lily Zou',
        tel:'3478371813',
        title2:'地产经纪',

        address:'4306 Main St,Flushing,NY,11355',
        map:'https://www.google.com/maps/place/43-06+Main+St,+Flushing,+NY+11355/@40.7522664,-73.8315613,17z/data=!3m1!4b1!4m5!3m4!1s0x89c2606cd640cb53:0x141db878e9815fe!8m2!3d40.7522665!4d-73.8270766',
        video:'https://res.cloudinary.com/dvr397xda/video/upload/v1662755302/cube/poster-intro/1662754939768031_epmg77.mp4',
        website:'https://meta11w.com/johnny/xintian',
        website2:'https://meta11w.com/basic/lily-zou',
        website3:'https://meta11w.com/basic2/star-chen',
        website4:'https://meta11w.com/companys/david',
        website5:'',
        images:[
            'https://res.cloudinary.com/dvr397xda/image/upload/v1658518055/cube/weiiz/WechatIMG9330_gm5mez.png', //right
            'https://res.cloudinary.com/dvr397xda/image/upload/v1658518055/cube/weiiz/WechatIMG9330_gm5mez.png', //left
            'https://res.cloudinary.com/dvr397xda/image/upload/v1658518055/cube/weiiz/WechatIMG9330_gm5mez.png', //glass
            'https://res.cloudinary.com/dvr397xda/image/upload/v1658518055/cube/weiiz/WechatIMG9330_gm5mez.png', //glass
            'https://res.cloudinary.com/dvr397xda/image/upload/v1662757075/cube/poster-intro/WechatIMG10537_o1zmkb.jpg', //front
            'https://res.cloudinary.com/dvr397xda/image/upload/v1662757075/cube/poster-intro/WechatIMG10536_ex49u9.jpg'//back
    ],
    qrCode:'https://res.cloudinary.com/dvr397xda/image/upload/v1659997040/cube/basic/lily/WechatIMG124_l0rvpj.jpg',
    isMusic:true,
    music:[{
        album:'周杰伦',
        name:'简单爱',
        track:'Jay', 
        url:'https://res.cloudinary.com/dvr397xda/video/upload/v1661202691/cube/basic/ben/official-music-video_nsd8gr.mp3', 
        image:'https://res.cloudinary.com/dvr397xda/image/upload/v1660938134/music/cd-player_1_nxixox.png'
    }],
    isGallery:true,
    gallery:[
        'https://res.cloudinary.com/dvr397xda/image/upload/v1662755998/cube/poster-intro/WechatIMG10531_eylbdb.jpg',
        'https://res.cloudinary.com/dvr397xda/image/upload/v1662755998/cube/poster-intro/WechatIMG10530_kpeoyz.jpg',
        'https://res.cloudinary.com/dvr397xda/image/upload/v1662755998/cube/poster-intro/WechatIMG10532_it65m7.jpg',
        'https://res.cloudinary.com/dvr397xda/image/upload/v1662755998/cube/poster-intro/WechatIMG10529_xie5c3.jpg'
    ],
  
    gif:'https://res.cloudinary.com/dvr397xda/image/upload/v1662755525/cube/poster-intro/WechatIMG10528_h3txjs.jpg',
    videoImg:'https://res.cloudinary.com/dvr397xda/image/upload/v1662756254/cube/poster-intro/WechatIMG10534_ksyert.jpg'
    },
    { 
        id:'intro',
        name:'intro',
        tel:'8453036348',
        title:'Meta',
        title2:'数字媒体工作室',

        address:'4306 Main St,Flushing,NY,11355',
        map:'https://www.google.com/maps/place/43-06+Main+St,+Flushing,+NY+11355/@40.7522664,-73.8315613,17z/data=!3m1!4b1!4m5!3m4!1s0x89c2606cd640cb53:0x141db878e9815fe!8m2!3d40.7522665!4d-73.8270766',
        video:'https://res.cloudinary.com/dvr397xda/video/upload/v1662755302/cube/poster-intro/1662754939768031_epmg77.mp4',
        website:'https://meta11w.com/johnny/xintian',
        website2:'https://meta11w.com/basic/lily-zou',
        website3:'https://meta11w.com/basic2/star-chen',
        website4:'https://meta11w.com/companys/david',
        website5:'',

        images:[
            'https://res.cloudinary.com/dvr397xda/image/upload/v1658518055/cube/weiiz/WechatIMG9330_gm5mez.png', //right
            'https://res.cloudinary.com/dvr397xda/image/upload/v1658518055/cube/weiiz/WechatIMG9330_gm5mez.png', //left
            'https://res.cloudinary.com/dvr397xda/image/upload/v1658518055/cube/weiiz/WechatIMG9330_gm5mez.png', //glass
            'https://res.cloudinary.com/dvr397xda/image/upload/v1658518055/cube/weiiz/WechatIMG9330_gm5mez.png', //glass
            'https://res.cloudinary.com/dvr397xda/image/upload/v1662757075/cube/poster-intro/WechatIMG10537_o1zmkb.jpg', //front
            'https://res.cloudinary.com/dvr397xda/image/upload/v1662757413/cube/poster-intro/WechatIMG10538_cvhpil.jpg'//back
    ],
    qrCode:'https://res.cloudinary.com/dvr397xda/image/upload/v1662755295/cube/poster-intro/WechatIMG10518_dwgejf.png',
    isMusic:true,
    music:[{
        album:'周杰伦',
        name:'简单爱',
        track:'Jay', 
        url:'https://res.cloudinary.com/dvr397xda/video/upload/v1661202691/cube/basic/ben/official-music-video_nsd8gr.mp3', 
        image:'https://res.cloudinary.com/dvr397xda/image/upload/v1660938134/music/cd-player_1_nxixox.png'
    }],
    isGallery:true,
    gallery:[
        'https://res.cloudinary.com/dvr397xda/image/upload/v1662755998/cube/poster-intro/WechatIMG10531_eylbdb.jpg',
        'https://res.cloudinary.com/dvr397xda/image/upload/v1662755998/cube/poster-intro/WechatIMG10530_kpeoyz.jpg',
        'https://res.cloudinary.com/dvr397xda/image/upload/v1662755998/cube/poster-intro/WechatIMG10532_it65m7.jpg',
        'https://res.cloudinary.com/dvr397xda/image/upload/v1662755998/cube/poster-intro/WechatIMG10529_xie5c3.jpg'
    ],

    gif:'https://res.cloudinary.com/dvr397xda/image/upload/v1662755525/cube/poster-intro/WechatIMG10528_h3txjs.jpg',
    videoImg:'https://res.cloudinary.com/dvr397xda/image/upload/v1662756254/cube/poster-intro/WechatIMG10534_ksyert.jpg'
    },
    { 
        id:'meta',
        name:'intro',
        tel:'8453036348',
        title:'Meta',
        title2:'数字媒体工作室',

        address:'4306 Main St,Flushing,NY,11355',
        map:'https://www.google.com/maps/place/43-06+Main+St,+Flushing,+NY+11355/@40.7522664,-73.8315613,17z/data=!3m1!4b1!4m5!3m4!1s0x89c2606cd640cb53:0x141db878e9815fe!8m2!3d40.7522665!4d-73.8270766',
        video:'https://res.cloudinary.com/dvr397xda/video/upload/v1662755302/cube/poster-intro/1662754939768031_epmg77.mp4',
        website:'https://meta11w.com/example',
        website2:'https://meta11w.com/information',
        website3:'https://meta11w.com/meta',
        website4:'https://meta11w.com/basic2/star-chen',
        website5:'',
        images:[
            'https://res.cloudinary.com/dvr397xda/image/upload/v1658518055/cube/weiiz/WechatIMG9330_gm5mez.png', //right
            'https://res.cloudinary.com/dvr397xda/image/upload/v1658518055/cube/weiiz/WechatIMG9330_gm5mez.png', //left
            'https://res.cloudinary.com/dvr397xda/image/upload/v1658518055/cube/weiiz/WechatIMG9330_gm5mez.png', //glass
            'https://res.cloudinary.com/dvr397xda/image/upload/v1658518055/cube/weiiz/WechatIMG9330_gm5mez.png', //glass
            'https://res.cloudinary.com/dvr397xda/image/upload/v1662758008/cube/poster-intro/WechatIMG563_l18zex.jpg', //front
            'https://res.cloudinary.com/dvr397xda/image/upload/v1662757413/cube/poster-intro/WechatIMG10538_cvhpil.jpg'//back
    ],
    qrCode:'https://res.cloudinary.com/dvr397xda/image/upload/v1662755295/cube/poster-intro/WechatIMG10518_dwgejf.png',
    isMusic:true,
    music:[{
        album:'周杰伦',
        name:'简单爱',
        track:'Jay', 
        url:'https://res.cloudinary.com/dvr397xda/video/upload/v1661202691/cube/basic/ben/official-music-video_nsd8gr.mp3', 
        image:'https://res.cloudinary.com/dvr397xda/image/upload/v1660938134/music/cd-player_1_nxixox.png'
    }],
    isGallery:true,
    gallery:[
        'https://res.cloudinary.com/dvr397xda/image/upload/v1662755998/cube/poster-intro/WechatIMG10531_eylbdb.jpg',
        'https://res.cloudinary.com/dvr397xda/image/upload/v1662755998/cube/poster-intro/WechatIMG10530_kpeoyz.jpg',
        'https://res.cloudinary.com/dvr397xda/image/upload/v1662755998/cube/poster-intro/WechatIMG10532_it65m7.jpg',
        'https://res.cloudinary.com/dvr397xda/image/upload/v1662755998/cube/poster-intro/WechatIMG10529_xie5c3.jpg'
    ],

    gif:'https://res.cloudinary.com/dvr397xda/image/upload/v1662755525/cube/poster-intro/WechatIMG10528_h3txjs.jpg',
    videoImg:'https://res.cloudinary.com/dvr397xda/image/upload/v1662756254/cube/poster-intro/WechatIMG10534_ksyert.jpg'
    },

  




]

const fetchPoster = ()=>{
    return data;
}


export default fetchPoster