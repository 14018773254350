const data=[
    {   
        id:'johnny',
        name:'Johnny Chen',
        company:'xintian',
        tel:'3015200518',
        email:'johnny@xintian.com',
        title:'星天创始人',
        website:{name:'星天工作室',url:'https://xingtianstudio.com/'},
        website2:{name:'Weiiz预约平台',url:'https://www.weiiz.com/#/homepage'},
        website3:{name:'线上商城',url:'https://www.groupchop.com/'},
        website4:{name:'Weiiz SPA连锁',url:'https://www.weiizspa.com/'},
        address:'4306 Main St,Flushing,NY,11355',
        map:'https://www.google.com/maps/place/43-06+Main+St,+Flushing,+NY+11355/@40.7522664,-73.8315613,17z/data=!3m1!4b1!4m5!3m4!1s0x89c2606cd640cb53:0x141db878e9815fe!8m2!3d40.7522665!4d-73.8270766',
        avatar:'https://res.cloudinary.com/dvr397xda/image/upload/v1653339341/cube/johnny_o9orlx.jpg',
        images:[
            'https://res.cloudinary.com/dvr397xda/image/upload/v1650661462/cube/43_juk7zq.jpg',
             //right
            'https://res.cloudinary.com/dvr397xda/image/upload/v1650661462/cube/44_aqae7l.jpg', //left
            'https://res.cloudinary.com/dvr397xda/image/upload/v1650920511/cube/%E7%8E%BB%E7%92%832_ukdjz2.jpg', //up
           
            'https://res.cloudinary.com/dvr397xda/image/upload/v1650920511/cube/%E7%8E%BB%E7%92%832_ukdjz2.jpg', //down
            'https://res.cloudinary.com/dvr397xda/image/upload/v1655150127/cube/WechatIMG8392_lfrxly.jpg',//front
            'https://res.cloudinary.com/dvr397xda/image/upload/v1650661461/cube/42_bvfzoi.jpg', //back
        ],
        video:'https://res.cloudinary.com/dvr397xda/video/upload/v1649697476/samples/elephants.mp4',
        background:'https://res.cloudinary.com/dvr397xda/image/upload/v1653927826/cube/1921iq6-a-room-that-has-a-umbrella-in-her-hand_rainyw.jpg',
    },
    {   
        id:'lily',
        name:'Lily Zou',
        company:'360realty',
        email:'johnny@xintian.com',
        map:'https://www.google.com/maps/place/43-06+Main+St,+Flushing,+NY+11355/@40.7522664,-73.8315613,17z/data=!3m1!4b1!4m5!3m4!1s0x89c2606cd640cb53:0x141db878e9815fe!8m2!3d40.7522665!4d-73.8270766',
        tel:'3478371813',
        title:'房产Agent',
        address:'4306 Main St,Flushing,NY,11355',
        website:{name:'星天工作室',url:'https://xingtianstudio.com/'},
        website2:{name:'Weiiz预约平台',url:'https://www.weiiz.com/#/homepage'},
        website3:{name:'Group Chop线上商城',url:'https://www.groupchop.com/'},
        website4:{name:'Weiiz SPA连锁',url:'https://www.weiizspa.com/'},
        avatar:'https://res.cloudinary.com/dvr397xda/image/upload/v1653339340/cube/lily_dbjmp9.jpg',
        images:[
            'https://res.cloudinary.com/dvr397xda/image/upload/v1652213427/cube/lily/73921652212307_.pic_y5ixyn.jpg',
             //right
            'https://res.cloudinary.com/dvr397xda/image/upload/v1652213427/cube/lily/73911652212307_.pic_o5mgki.jpg', //left
            'https://res.cloudinary.com/dvr397xda/image/upload/v1650920511/cube/%E7%8E%BB%E7%92%832_ukdjz2.jpg', //up
           
            'https://res.cloudinary.com/dvr397xda/image/upload/v1650920511/cube/%E7%8E%BB%E7%92%832_ukdjz2.jpg', //down
            'https://res.cloudinary.com/dvr397xda/image/upload/v1652213427/cube/lily/73931652212308_.pic_ks55ga.jpg',//front
            'https://res.cloudinary.com/dvr397xda/image/upload/v1652213428/cube/lily/73941652212308_.pic_okkaiw.jpg', //back
        ],
        video:'https://res.cloudinary.com/dvr397xda/video/upload/v1649697476/samples/elephants.mp4',
        background:'https://res.cloudinary.com/dvr397xda/image/upload/v1654270103/cube/b750lis-360-panorama-view-kitchen_rpigxu.jpg',
    },
    {   
        id:'star',
        name:'Star Chen',
        company:'xintian',
        email:'johnny@xintian.com',
        map:'https://www.google.com/maps/place/43-06+Main+St,+Flushing,+NY+11355/@40.7522664,-73.8315613,17z/data=!3m1!4b1!4m5!3m4!1s0x89c2606cd640cb53:0x141db878e9815fe!8m2!3d40.7522665!4d-73.8270766',
        tel:'3015200518',
        title:'UI Designer',
        address:'4306 Main St,Flushing,NY,11355',
        website:{name:'星天工作室',url:'https://xingtianstudio.com/'},
        website2:{name:'Weiiz预约平台',url:'https://www.weiiz.com/#/homepage'},
        website3:{name:'线上商城',url:'https://www.groupchop.com/'},
        website4:{name:'Weiiz SPA连锁',url:'https://www.weiizspa.com/'},
        avatar:'https://res.cloudinary.com/dvr397xda/image/upload/v1653338926/cube/%E6%88%91_baj18u.jpg',
        images:[
            'https://res.cloudinary.com/dvr397xda/image/upload/v1652195354/cube/yuxin/73751652194996_.pic_zn8aem.jpg',
            //right
            'https://res.cloudinary.com/dvr397xda/image/upload/v1652195354/cube/yuxin/73741652194995_.pic_oiovuz.jpg', //left
            'https://res.cloudinary.com/dvr397xda/image/upload/v1650920511/cube/%E7%8E%BB%E7%92%832_ukdjz2.jpg', //up
           
            'https://res.cloudinary.com/dvr397xda/image/upload/v1650920511/cube/%E7%8E%BB%E7%92%832_ukdjz2.jpg', //down
            'https://res.cloudinary.com/dvr397xda/image/upload/v1655150127/cube/WechatIMG8395_dfgfjt.jpg', //front
            'https://res.cloudinary.com/dvr397xda/image/upload/v1652195355/cube/yuxin/73731652194995_.pic_b4icfm.jpg', //back
        ],
        video:'https://res.cloudinary.com/dvr397xda/video/upload/v1649697476/samples/elephants.mp4',
        background:'https://res.cloudinary.com/dvr397xda/image/upload/v1654268860/cube/737400_fawgdh.jpg'
    },
    {   
        id:'yanxiong',
        name:'Yanxiong',
        company:'360realty',
        email:'johnny@xintian.com',
        map:'https://www.google.com/maps/place/43-06+Main+St,+Flushing,+NY+11355/@40.7522664,-73.8315613,17z/data=!3m1!4b1!4m5!3m4!1s0x89c2606cd640cb53:0x141db878e9815fe!8m2!3d40.7522665!4d-73.8270766',
        tel:'3015200518',
        title:'政治家',
        address:'4306 Main St,Flushing,NY,11355',
        website:{name:'星天工作室',url:'https://xingtianstudio.com/'},
        website2:{name:'Weiiz预约平台',url:'https://www.weiiz.com/#/homepage'},
        website3:{name:'Group Chop线上商城',url:'https://www.groupchop.com/'},
        website4:{name:'Weiiz SPA连锁',url:'https://www.weiizspa.com/'},
        avatar:'https://res.cloudinary.com/dvr397xda/image/upload/v1653337703/cube/%E8%A8%80png_smtizs.png',
        images:[
            'https://res.cloudinary.com/dvr397xda/image/upload/v1651165569/cube/xiongyan/I_will_fight_to_rebuild_our_economy_and_incentivize_local_businesses_to_open_in_our_neighborhoods._2_qa0uxs.png',
             //right
            'https://res.cloudinary.com/dvr397xda/image/upload/v1651164730/cube/xiongyan/273280837_104901442121039_6211710868776190112_n_owxs5m.jpg', //left
            'https://res.cloudinary.com/dvr397xda/image/upload/v1650920511/cube/%E7%8E%BB%E7%92%832_ukdjz2.jpg', //up
           
            'https://res.cloudinary.com/dvr397xda/image/upload/v1650920511/cube/%E7%8E%BB%E7%92%832_ukdjz2.jpg', //down
            'https://res.cloudinary.com/dvr397xda/image/upload/v1651164612/cube/xiongyan/273649028_104901915454325_4909119831129653754_n_rxwwjs.jpg',//front
            'https://res.cloudinary.com/dvr397xda/image/upload/v1652215070/cube/xiongyan/2291652215008_.pic_cx8xdp.jpg', //back
        ],
        video:'https://res.cloudinary.com/dvr397xda/video/upload/v1652214128/cube/xiongyan/1652214075589597_sjmv05.mp4',
        background:'https://res.cloudinary.com/dvr397xda/image/upload/v1654270034/cube/65l1n89-360-image-american-airlines-arena_njqgpp.jpg',
    },
    {   
        id:'david',
        name:'David Jiang',
        company:'xintian',
        email:'davidjiang@gmail.com',
        map:'https://www.google.com/maps/place/43-06+Main+St,+Flushing,+NY+11355/@40.7522664,-73.8315613,17z/data=!3m1!4b1!4m5!3m4!1s0x89c2606cd640cb53:0x141db878e9815fe!8m2!3d40.7522665!4d-73.8270766',
        tel:'3013269795',
        title:'Marketing',
        address:'4306 Main St,Flushing,NY,11355',
        website:{name:'星天工作室',url:'https://xingtianstudio.com/'},
        website2:{name:'Weiiz预约平台',url:'https://www.weiiz.com/#/homepage'},
        website3:{name:'线上商城',url:'https://www.groupchop.com/'},
        website4:{name:'Weiiz SPA连锁',url:'https://www.weiizspa.com/'},
        avatar:'https://res.cloudinary.com/dlapk94rx/image/upload/v1602192537/webwxgetmsgimg_10_rwrwt4.png',
        images:[
            'https://res.cloudinary.com/dlapk94rx/image/upload/v1591496953/bg_k1g6ek.jpg',
            //right
            'https://res.cloudinary.com/dlapk94rx/image/upload/v1591496953/bg_k1g6ek.jpg', //left
            'https://res.cloudinary.com/dvr397xda/image/upload/v1650920511/cube/%E7%8E%BB%E7%92%832_ukdjz2.jpg', //up
           
            'https://res.cloudinary.com/dvr397xda/image/upload/v1650920511/cube/%E7%8E%BB%E7%92%832_ukdjz2.jpg', //down
            'https://res.cloudinary.com/dvr397xda/image/upload/v1655150127/cube/WechatIMG8393_n2lckn.jpg', //front
            'https://res.cloudinary.com/dlapk94rx/image/upload/v1602195870/webwxgetmsgimg_10_vhegau.jpg', //back
        ],
        video:'https://res.cloudinary.com/dvr397xda/video/upload/v1649697476/samples/elephants.mp4',
        background:'https://res.cloudinary.com/dvr397xda/image/upload/v1654270222/cube/wb9e1c1e-plose-winter-mountain-view_nbisil.jpg'
    },
    
    {   
        id:'hao',
        name:'Hoo Dong',
        company:'xintian',
        email:'johnny@xintian.com',
        map:'https://www.google.com/maps/place/43-06+Main+St,+Flushing,+NY+11355/@40.7522664,-73.8315613,17z/data=!3m1!4b1!4m5!3m4!1s0x89c2606cd640cb53:0x141db878e9815fe!8m2!3d40.7522665!4d-73.8270766',
        tel:'3015200518',
        title:' UI Designer',
        address:'4306 Main St,Flushing,NY,11355',
        website:{name:'星天工作室',url:'https://xingtianstudio.com/'},
        website2:{name:'Weiiz预约平台',url:'https://www.weiiz.com/#/homepage'},
        website3:{name:'线上商城',url:'https://www.groupchop.com/'},
        website4:{name:'Weiiz SPA连锁',url:'https://www.weiizspa.com/'},
        avatar:'https://res.cloudinary.com/dvr397xda/image/upload/v1655150127/cube/WechatIMG8398_kx1bny.jpg',
        images:[
            'https://res.cloudinary.com/dlapk94rx/image/upload/v1591496953/bg_k1g6ek.jpg',
            //right
            'https://res.cloudinary.com/dlapk94rx/image/upload/v1591496953/bg_k1g6ek.jpg', //left
            'https://res.cloudinary.com/dvr397xda/image/upload/v1650920511/cube/%E7%8E%BB%E7%92%832_ukdjz2.jpg', //up
           
            'https://res.cloudinary.com/dvr397xda/image/upload/v1650920511/cube/%E7%8E%BB%E7%92%832_ukdjz2.jpg', //down
            'https://res.cloudinary.com/dvr397xda/image/upload/v1655150127/cube/WechatIMG8398_kx1bny.jpg', //front
            'https://res.cloudinary.com/dlapk94rx/image/upload/v1602019812/webwxgetmsgimg_5_ibqaix.jpg', //back
        ],
        video:'https://res.cloudinary.com/dvr397xda/video/upload/v1649697476/samples/elephants.mp4',
        background:'https://res.cloudinary.com/dvr397xda/image/upload/v1654270222/cube/el31auf-times-square-nyc-at-night_lkowtq.jpg'
    },
    


    
  
    
   
]


const fetchData = ()=>{
    return data;
}

export default fetchData;