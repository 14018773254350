const data = [

    { 
        id:'michael-lin',
        name:'linji',
        tel:'4124033316',
        cell:'',
        email:'jilin520520@gmail.com',
        address:'59east Broadway 2fl New York ny 10002',
        map:'https://g.page/ol-the-one-wedding-plaza?share',
        video:'',
        facebook:'',
        background:'https://res.cloudinary.com/dvr397xda/image/upload/v1653927826/cube/1921iq6-a-room-that-has-a-umbrella-in-her-hand_rainyw.jpg',
        linkedin:'',
        website:'https://theoneweddingstudio.com/',
        images:[
            'https://res.cloudinary.com/dvr397xda/image/upload/v1658518055/cube/weiiz/WechatIMG9330_gm5mez.png', //right
            'https://res.cloudinary.com/dvr397xda/image/upload/v1658518055/cube/weiiz/WechatIMG9330_gm5mez.png', //left
            'https://res.cloudinary.com/dvr397xda/image/upload/v1658518055/cube/weiiz/WechatIMG9330_gm5mez.png', //glass
            'https://res.cloudinary.com/dvr397xda/image/upload/v1658518055/cube/weiiz/WechatIMG9330_gm5mez.png', //glass
            'https://res.cloudinary.com/dvr397xda/image/upload/v1659640001/cube/hunqing/WechatIMG9684_qzvxvl.png', //front
            'https://res.cloudinary.com/dvr397xda/image/upload/v1659631783/cube/hunqing/WechatIMG9679_q7jp1a.png'//back
    ],

  
    gif:'https://res.cloudinary.com/dvr397xda/image/upload/v1659535822/cube/hunqing/Background_-_13950_AdobeExpress_AdobeExpress_w3dicc.gif',
    loadImg:'https://res.cloudinary.com/dvr397xda/image/upload/v1659538103/cube/hunqing/804a7b8872de5db7d3dee11a94a89449_gokedj.gif',
    qrCode:'https://res.cloudinary.com/dvr397xda/image/upload/v1659636124/cube/hunqing/WechatIMG572_dirqcn.jpg',
    model:{
        name:'camera',
        url:'https://res.cloudinary.com/dvr397xda/raw/upload/v1659468154/cube/basic/wingo/Canon_AT-1_o5weeb.fbx',
        metalMap:'https://res.cloudinary.com/dvr397xda/image/upload/v1659468224/cube/basic/wingo/CAM0001_Textures_METL_2k_z9glb8.png',
        normalMap:'https://res.cloudinary.com/dvr397xda/image/upload/v1659468230/cube/basic/wingo/CAM0001_Textures_NRML_2k_ou6yul.png',
        roughnessMap:'https://res.cloudinary.com/dvr397xda/image/upload/v1659468224/cube/basic/wingo/CAM0001_Textures_ROUGH_2k_rx6oey.png',
        colorMap:'https://res.cloudinary.com/dvr397xda/image/upload/v1659468228/cube/basic/wingo/CAM0001_Textures_COL_2k_wrktl1.png',
        aoMap:'https://res.cloudinary.com/dvr397xda/image/upload/v1659468222/cube/basic/wingo/CAM0001_Textures_AO_2k_ilwxtp.png',
        scale:14,
        position:[0,-0.5,0],
        rotation:[0,0,0],
    },
    gallery:[
        'https://res.cloudinary.com/dvr397xda/image/upload/v1659540496/cube/hunqing/couple-37v_ah2irj_l7yxed.jpg',
        'https://res.cloudinary.com/dvr397xda/image/upload/v1659540496/cube/hunqing/couple-40v_kgluhl_vbgahs.jpg',
        'https://res.cloudinary.com/dvr397xda/image/upload/v1659540496/cube/hunqing/couple-22v_kptiuk_juohbs.jpg',
        'https://res.cloudinary.com/dvr397xda/image/upload/v1659540496/cube/hunqing/couple-38v_xxjqbf_dala6p.jpg',
        'https://res.cloudinary.com/dvr397xda/image/upload/v1659540496/cube/hunqing/couple-17v_jma8zv_l0fgtx.jpg',
        'https://res.cloudinary.com/dvr397xda/image/upload/v1659540495/cube/hunqing/couple-13v_kimyyj_zsujjt.jpg',
        'https://res.cloudinary.com/dvr397xda/image/upload/v1659540495/cube/hunqing/couple-16v_cpgkui_ja4oto.jpg',
     
    ],
    gallery2:[
        'https://res.cloudinary.com/dvr397xda/image/upload/v1659540495/cube/hunqing/couple-14v_vudwq5_zi1gga.jpg',
        'https://res.cloudinary.com/dvr397xda/image/upload/v1659540495/cube/hunqing/couple-15v_vir0jj_vn5xks.jpg',
        'https://res.cloudinary.com/dvr397xda/image/upload/v1659540495/cube/hunqing/couple-9v_x7wdbj_iq2mot.jpg',
        'https://res.cloudinary.com/dvr397xda/image/upload/v1659540495/cube/hunqing/couple-12v_gu9jvl_ojzr0n.jpg',
        'https://res.cloudinary.com/dvr397xda/image/upload/v1659540495/cube/hunqing/couple-8v_kkplq9_wptxpp.jpg',
        'https://res.cloudinary.com/dvr397xda/image/upload/v1659540494/cube/hunqing/couple-7v_ck6akb_d78anj.jpg',
       
    ],
    show:[
        {
            name:'The ONE Wedding Studio',
            title:'',
            image:'https://res.cloudinary.com/dvr397xda/image/upload/v1659640001/cube/hunqing/oulu-logo_ge1reh.png',
        },
        {
            name:'Michael Lin',
            title:'Co-Founders',
            image:'https://res.cloudinary.com/dvr397xda/image/upload/v1659640000/cube/hunqing/Michael_Lin_hmvbf9.png',
        },
        {
            name:'Nancy Liu',
            title:'Co-Founders',
            image:'https://res.cloudinary.com/dvr397xda/image/upload/v1659640001/cube/hunqing/Nancy_Liu_cirzus.png',
        },
        {
            name:'Lily Zou',
            title:'Business Director',
            image:'https://res.cloudinary.com/dvr397xda/image/upload/v1661356378/cube/hunqing/WechatIMG10138_byfwqn.jpg',
        },
        {
            name:'Amos Leo',
            title:'Hosts',
            image:'https://res.cloudinary.com/dvr397xda/image/upload/v1659640000/cube/hunqing/Amos_Leo_ekd0hs.png',
        },
        {
            name:'Jiejie Yang',
            title:'Hosts',
            image:'https://res.cloudinary.com/dvr397xda/image/upload/v1659640000/cube/hunqing/jiejie_yang_h9coea.png',
        },
        {
            name:'Sisi',
            title:'Hosts',
            image:'https://res.cloudinary.com/dvr397xda/image/upload/v1659640001/cube/hunqing/sisi_kdnjd8.jpg',
        },
        {
            name:'Zaozao Wang',
            title:'Hosts',
            image:'https://res.cloudinary.com/dvr397xda/image/upload/v1659640460/cube/hunqing/zaozao-min-eb7e184584be6b5f9001754db31e5ddb_gnr2p3.png',
        },
        {
            name:'Cindy',
            title:'Hosts',
            image:'https://res.cloudinary.com/dvr397xda/image/upload/v1659640000/cube/hunqing/cindy_izr60g.png',
        },
        {
            name:'Lisa',
            title:'Hosts',
            image:'https://res.cloudinary.com/dvr397xda/image/upload/v1659640000/cube/hunqing/lisa_rl0qwu.png',
        },
        {
            name:'Feng',
            title:'Hosts',
            image:'https://res.cloudinary.com/dvr397xda/image/upload/v1659640000/cube/hunqing/feng_jmflta.png',
        },
        {
            name:'Miss Tsang',
            title:'Hosts',
            image:'https://res.cloudinary.com/dvr397xda/image/upload/v1659640001/cube/hunqing/Miss_Tsang_y0rpry.png',
        },
        {
            name:'Andy Cheng',
            title:'Hosts',
            image:'https://res.cloudinary.com/dvr397xda/image/upload/v1659640000/cube/hunqing/Andy_Chen_f6excn.png',
        },
        {
            name:'Elaine',
            title:'Hosts',
            image:'https://res.cloudinary.com/dvr397xda/image/upload/v1659640000/cube/hunqing/Elaine_r9obxv.png',
        },
        {
            name:'Mr',
            title:'Photographer',
            image:'https://res.cloudinary.com/dvr397xda/image/upload/v1659640703/cube/hunqing/WechatIMG9687_fnios2.jpg',
        },
        {
            name:'Mr',
            title:'Photographer',
            image:'https://res.cloudinary.com/dvr397xda/image/upload/v1659640703/cube/hunqing/WechatIMG9686_bnwqqr.jpg',
        },
        {
            name:'Mr',
            title:'Photographer',
            image:'https://res.cloudinary.com/dvr397xda/image/upload/v1659640703/cube/hunqing/WechatIMG9689_wfpaqq.jpg',
        },
        {
            name:'Mr',
            title:'Photographer',
            image:'https://res.cloudinary.com/dvr397xda/image/upload/v1659640703/cube/hunqing/WechatIMG9688_lriuy0.jpg',
        },
        {
            name:'The ONE Wedding Studio',
            title:'',
            image:'https://res.cloudinary.com/dvr397xda/image/upload/v1659640001/cube/hunqing/oulu-logo_ge1reh.png',
        },
    ],
    isAvatar: true,
    avatar:[
        'https://res.cloudinary.com/dvr397xda/image/upload/v1659735786/cube/hunqing/WechatIMG9749_xsvsu0.png',
        'https://res.cloudinary.com/dvr397xda/image/upload/v1659735786/cube/hunqing/WechatIMG9750_wjakft.png',
        'https://res.cloudinary.com/dvr397xda/image/upload/v1659735786/cube/hunqing/WechatIMG9752_hiuwu6.png',
        'https://res.cloudinary.com/dvr397xda/image/upload/v1659735786/cube/hunqing/WechatIMG9753_vaeg7m.png', 
        'https://res.cloudinary.com/dvr397xda/image/upload/v1659735785/cube/hunqing/WechatIMG9754_srjyhb.png',
        
        'https://res.cloudinary.com/dvr397xda/image/upload/v1659735785/cube/hunqing/WechatIMG9751_ilykog.png'      
    ]

    },
    { 
        id:'lily-zou',
        name:'Lily-zou',
        tel:'3478371813',
        cell:'',
        email:'Lzou58@yahoo.com',
        address:'4306 Main Street,Flushing,NY,11355',
        map:'https://goo.gl/maps/Jg2e6uHgY5x84k8L9',
        video:'',
        facebook:'',
        background:'https://res.cloudinary.com/dvr397xda/image/upload/v1653927826/cube/1921iq6-a-room-that-has-a-umbrella-in-her-hand_rainyw.jpg',
        linkedin:'',
        website:'https://theoneweddingstudio.com/',
        images:[
            'https://res.cloudinary.com/dvr397xda/image/upload/v1661463656/cube/hunqing/WechatIMG10186_ghypxt.png', //right
            'https://res.cloudinary.com/dvr397xda/image/upload/v1661461585/cube/hunqing/WechatIMG10183_taiyjn.png', //left
            'https://res.cloudinary.com/dvr397xda/image/upload/v1658518055/cube/weiiz/WechatIMG9330_gm5mez.png', //glass
            'https://res.cloudinary.com/dvr397xda/image/upload/v1658518055/cube/weiiz/WechatIMG9330_gm5mez.png', //glass
            'https://res.cloudinary.com/dvr397xda/image/upload/v1661461585/cube/hunqing/WechatIMG10184_szrmxw.png', //front
            'https://res.cloudinary.com/dvr397xda/image/upload/v1659631783/cube/hunqing/WechatIMG9679_q7jp1a.png'//back
    ],

  
    gif:'https://res.cloudinary.com/dvr397xda/image/upload/v1659535822/cube/hunqing/Background_-_13950_AdobeExpress_AdobeExpress_w3dicc.gif',
    loadImg:'https://res.cloudinary.com/dvr397xda/image/upload/v1659538103/cube/hunqing/804a7b8872de5db7d3dee11a94a89449_gokedj.gif',
    qrCode:'https://res.cloudinary.com/dvr397xda/image/upload/v1659997040/cube/basic/lily/WechatIMG124_l0rvpj.jpg',
    model:{
        name:'camera',
        url:'https://res.cloudinary.com/dvr397xda/raw/upload/v1659468154/cube/basic/wingo/Canon_AT-1_o5weeb.fbx',
        metalMap:'https://res.cloudinary.com/dvr397xda/image/upload/v1659468224/cube/basic/wingo/CAM0001_Textures_METL_2k_z9glb8.png',
        normalMap:'https://res.cloudinary.com/dvr397xda/image/upload/v1659468230/cube/basic/wingo/CAM0001_Textures_NRML_2k_ou6yul.png',
        roughnessMap:'https://res.cloudinary.com/dvr397xda/image/upload/v1659468224/cube/basic/wingo/CAM0001_Textures_ROUGH_2k_rx6oey.png',
        colorMap:'https://res.cloudinary.com/dvr397xda/image/upload/v1659468228/cube/basic/wingo/CAM0001_Textures_COL_2k_wrktl1.png',
        aoMap:'https://res.cloudinary.com/dvr397xda/image/upload/v1659468222/cube/basic/wingo/CAM0001_Textures_AO_2k_ilwxtp.png',
        scale:14,
        position:[0,-0.5,0],
        rotation:[0,0,0],
    },
    gallery:[
        'https://res.cloudinary.com/dvr397xda/image/upload/v1659540496/cube/hunqing/couple-37v_ah2irj_l7yxed.jpg',
        'https://res.cloudinary.com/dvr397xda/image/upload/v1659540496/cube/hunqing/couple-40v_kgluhl_vbgahs.jpg',
        'https://res.cloudinary.com/dvr397xda/image/upload/v1659540496/cube/hunqing/couple-22v_kptiuk_juohbs.jpg',
        'https://res.cloudinary.com/dvr397xda/image/upload/v1659540496/cube/hunqing/couple-38v_xxjqbf_dala6p.jpg',
        'https://res.cloudinary.com/dvr397xda/image/upload/v1659540496/cube/hunqing/couple-17v_jma8zv_l0fgtx.jpg',
        'https://res.cloudinary.com/dvr397xda/image/upload/v1659540495/cube/hunqing/couple-13v_kimyyj_zsujjt.jpg',
        'https://res.cloudinary.com/dvr397xda/image/upload/v1659540495/cube/hunqing/couple-16v_cpgkui_ja4oto.jpg',
     
    ],
    gallery2:[
        'https://res.cloudinary.com/dvr397xda/image/upload/v1659540495/cube/hunqing/couple-14v_vudwq5_zi1gga.jpg',
        'https://res.cloudinary.com/dvr397xda/image/upload/v1659540495/cube/hunqing/couple-15v_vir0jj_vn5xks.jpg',
        'https://res.cloudinary.com/dvr397xda/image/upload/v1659540495/cube/hunqing/couple-9v_x7wdbj_iq2mot.jpg',
        'https://res.cloudinary.com/dvr397xda/image/upload/v1659540495/cube/hunqing/couple-12v_gu9jvl_ojzr0n.jpg',
        'https://res.cloudinary.com/dvr397xda/image/upload/v1659540495/cube/hunqing/couple-8v_kkplq9_wptxpp.jpg',
        'https://res.cloudinary.com/dvr397xda/image/upload/v1659540494/cube/hunqing/couple-7v_ck6akb_d78anj.jpg',
       
    ],
    show:[
        {
            name:'The ONE Wedding Studio',
            title:'',
            image:'https://res.cloudinary.com/dvr397xda/image/upload/v1659640001/cube/hunqing/oulu-logo_ge1reh.png',
        },
        {
            name:'Michael Lin',
            title:'Co-Founders',
            image:'https://res.cloudinary.com/dvr397xda/image/upload/v1659640000/cube/hunqing/Michael_Lin_hmvbf9.png',
        },
        {
            name:'Nancy Liu',
            title:'Co-Founders',
            image:'https://res.cloudinary.com/dvr397xda/image/upload/v1659640001/cube/hunqing/Nancy_Liu_cirzus.png',
        },
        {
            name:'Lily Zou',
            title:'Business Director',
            image:'https://res.cloudinary.com/dvr397xda/image/upload/v1661356378/cube/hunqing/WechatIMG10138_byfwqn.jpg',
        },
        {
            name:'Amos Leo',
            title:'Hosts',
            image:'https://res.cloudinary.com/dvr397xda/image/upload/v1659640000/cube/hunqing/Amos_Leo_ekd0hs.png',
        },
        {
            name:'Jiejie Yang',
            title:'Hosts',
            image:'https://res.cloudinary.com/dvr397xda/image/upload/v1659640000/cube/hunqing/jiejie_yang_h9coea.png',
        },
        {
            name:'Sisi',
            title:'Hosts',
            image:'https://res.cloudinary.com/dvr397xda/image/upload/v1659640001/cube/hunqing/sisi_kdnjd8.jpg',
        },
        {
            name:'Zaozao Wang',
            title:'Hosts',
            image:'https://res.cloudinary.com/dvr397xda/image/upload/v1659640460/cube/hunqing/zaozao-min-eb7e184584be6b5f9001754db31e5ddb_gnr2p3.png',
        },
        {
            name:'Cindy',
            title:'Hosts',
            image:'https://res.cloudinary.com/dvr397xda/image/upload/v1659640000/cube/hunqing/cindy_izr60g.png',
        },
        {
            name:'Lisa',
            title:'Hosts',
            image:'https://res.cloudinary.com/dvr397xda/image/upload/v1659640000/cube/hunqing/lisa_rl0qwu.png',
        },
        {
            name:'Feng',
            title:'Hosts',
            image:'https://res.cloudinary.com/dvr397xda/image/upload/v1659640000/cube/hunqing/feng_jmflta.png',
        },
        {
            name:'Miss Tsang',
            title:'Hosts',
            image:'https://res.cloudinary.com/dvr397xda/image/upload/v1659640001/cube/hunqing/Miss_Tsang_y0rpry.png',
        },
        {
            name:'Andy Cheng',
            title:'Hosts',
            image:'https://res.cloudinary.com/dvr397xda/image/upload/v1659640000/cube/hunqing/Andy_Chen_f6excn.png',
        },
        {
            name:'Elaine',
            title:'Hosts',
            image:'https://res.cloudinary.com/dvr397xda/image/upload/v1659640000/cube/hunqing/Elaine_r9obxv.png',
        },
        {
            name:'Mr',
            title:'Photographer',
            image:'https://res.cloudinary.com/dvr397xda/image/upload/v1659640703/cube/hunqing/WechatIMG9687_fnios2.jpg',
        },
        {
            name:'Mr',
            title:'Photographer',
            image:'https://res.cloudinary.com/dvr397xda/image/upload/v1659640703/cube/hunqing/WechatIMG9686_bnwqqr.jpg',
        },
        {
            name:'Mr',
            title:'Photographer',
            image:'https://res.cloudinary.com/dvr397xda/image/upload/v1659640703/cube/hunqing/WechatIMG9689_wfpaqq.jpg',
        },
        {
            name:'Mr',
            title:'Photographer',
            image:'https://res.cloudinary.com/dvr397xda/image/upload/v1659640703/cube/hunqing/WechatIMG9688_lriuy0.jpg',
        },
        {
            name:'The ONE Wedding Studio',
            title:'',
            image:'https://res.cloudinary.com/dvr397xda/image/upload/v1659640001/cube/hunqing/oulu-logo_ge1reh.png',
        },
    ],
    isAvatar: false,
    avatar:[
        'https://res.cloudinary.com/dvr397xda/image/upload/v1659735786/cube/hunqing/WechatIMG9749_xsvsu0.png',
        'https://res.cloudinary.com/dvr397xda/image/upload/v1659735786/cube/hunqing/WechatIMG9750_wjakft.png',
        'https://res.cloudinary.com/dvr397xda/image/upload/v1659735786/cube/hunqing/WechatIMG9752_hiuwu6.png',
        'https://res.cloudinary.com/dvr397xda/image/upload/v1659735786/cube/hunqing/WechatIMG9753_vaeg7m.png', 
        'https://res.cloudinary.com/dvr397xda/image/upload/v1659735785/cube/hunqing/WechatIMG9754_srjyhb.png',
        
        'https://res.cloudinary.com/dvr397xda/image/upload/v1659735785/cube/hunqing/WechatIMG9751_ilykog.png'      
    ],
    isVideo:true,
    videoUrl:'https://res.cloudinary.com/dvr397xda/video/upload/v1661376172/cube/hunqing/pexels-rostislav-uzunov-8252781_awj5q8.mp4',
    pano:'https://app.cloudpano.com/tours/JUPA2v3BI'

    },
   

  




]

const fetchBasic = ()=>{
    return data;
}


export default fetchBasic