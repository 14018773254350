import "./index.css";
import React from "react";
import {BrowserRouter ,Route,Routes} from "react-router-dom"

import Home from './pages/home/index'
import Card from './pages/Card/Card'
import Test from './pages/Test/index'
import CompanyPage from './pages/Company/index'
import ScenePage from './pages/Scene/index'
import NotFound from './pages/404/index'
import Office from './pages/Office/index'
import Office2 from './pages/Office2/index'

import Boss from './pages/Boss/index'
import Single from './pages/Single/index'
import Player from './components/Player/index'
import Room from './components/Room/index'
import Show from './pages/Show/index'
import Person from './pages/Person/index'
import ProductPage from './pages/Product/index'
import Case from './pages/Case/index'
import Cloth from './pages/Cloth/index'
import Book from './pages/Book/index'
import BookPage from './pages/Book/book'
import Album from './pages/Album/index'
import Porsche from './pages/Cars/Porsche/index'
import Porsche1 from './pages/Cars/Porsche2/index'
import Lambo from './pages/Cars/Lambo/index'
import Admin from './pages/Admin/index'
import XinTian from './pages/Companys/xintian'
import GroupChop from './pages/Companys/groupchop'
import Huoli from './pages/Companys/huoli'
import Nicrg from './pages/Companys/nicrg'
import Weiiz from './pages/Companys/weiiz'
import Meta from './pages/Companys/meta'
import Panorama from './pages/Panorama/index'
import World from './pages/Companys/world'
import David from './pages/Companys/david'
import Video from './pages/video/index'
import XinTian1 from './pages/Johnny/xintian'
import Weiiz1 from './pages/Johnny/weiiz'
import GroupChop1 from './pages/Johnny/groupchop'
import Huoli1 from './pages/Johnny/huoli'
import Nicrg1 from './pages/Johnny/nicrg'
import Meta1 from './pages/Johnny/meta'
import World1 from './pages/Johnny/world'
import LandingPage from './pages/Landing/index'
import Basic from './pages/Basic/index'
import Basic2 from './pages/Basic2/index'
import Basic3 from './pages/Basic3/index'
import Pro from './pages/Pro/index'
import Damn from './pages/Meta/index'
import Restaurant from './pages/Restaurant/index'
import Restaurant2 from './pages/Restaurant2/index'
import Business from './pages/Business/index'
import Information from './pages/Information/index'
import Success from './pages/Information/Success'
import Example from './pages/Example/index'
import Realty from './pages/Realty/index'
import Poster from './pages/Poster/index'
import Wedding from './pages/Wedding/index'
import Wedding2 from './pages/Wedding2/index'
import Auth from './pages/Auth/index'
import Create from './pages/Auth/Admin/Create/index'
import High from './pages/High/index'
import Login from './pages/Auth/Login/index'
import UserPage from './pages/User/index'
import UserLogin from './pages/User/UserLogin/index'
import UserSignUp from './pages/User/UserSignUp/index'
import HomePage from './pages/HomePage/index'
import Gallery from './pages/Gallery/index'
import AuthView from './pages/AuthView/index'
import UserPost from './pages/User/UserPosts/index'
import UserPostNew from './pages/User/UserPostNew/index'
import UserLike from './pages/User/UserLike/index'
import AuthPost from './pages/Auth/Admin/AuthPosts/index'
import AuthSms from "./pages/Auth/Admin/AuthSms";
import AuthNews from './pages/Auth/Admin/AuthNews/index'
import AuthCoupon from './pages/Auth/Admin/AuthCoupon/index'
import AuthCard from './pages/Auth/Admin/AuthCard/index'
import AuthMerchant from './pages/Auth/Admin/AuthMerchant/index'
import AuthPlayer from "./pages/Auth/Admin/AuthPlayers";

import Entertainment from './pages/Entertainment/index'
import NewsPage from './pages/News/index'
import News from './pages/NewsPage/index'
import CouponPage from './pages/Coupon/index'
import Card3d from "./pages/3dCard/index";
import Merchant from './pages/Merchant/index'
import ModelPage from './pages/Model/index'
import ModelCase from './pages/Model/model'
import OrgPage  from "./pages/Org/index";
import DesignerLogin from "./pages/Designer/Login";
import DesignerDashboard from "./pages/Designer/Dashboard";
import AuthAds from './pages/Auth/Admin/AuthAds'
import PrivacyPage from "./pages/privacy";
import UserViewer from "./pages/3dpano";
import Crystal from "./pages/activities/crystal";
import Profession from './pages/professional/index';
import KangNaiXin from './pages/professional/kangnaixin';
import AuthCube from './pages/Auth/Admin/AuthCube/index';
import CubeNews from './pages/professional/cube';
function App() {

  return (
    
   <BrowserRouter>
      <Routes>

      {/* <Route path='/' element={<ScenePage/>}/> */}
      {/* <Route path='/' element={<LandingPage/>} /> */}
      <Route path='/post' element={<HomePage/>} />
      <Route path='/' element={<NewsPage/>}  />
    <Route path='/news/:id' element={<News/>}  />
      <Route path='/museum' element={<Office/>}/>
      <Route path='/example' element={<Example/>}/>
      <Route path='/museum2' element={<Office2/>}/>

      <Route path='/org' element={<OrgPage/>}/>

      <Route path="/home"  element={<Home  />} /> 
      <Route path="/test"   element={<Test  />} /> 
      <Route path="/model"   element={<ModelPage  />} /> 
      <Route path="/model/:name"   element={<ModelCase  />} /> 

      <Route path="/company/:company"   element={<CompanyPage/>}/>
      <Route path="/profile/:name"   element={<Card/>} /> 

      <Route path='/boss/:name' element={<Boss/>}/>
      <Route path='/single/:name' element={<Single/>}/>
      <Route path='/player' element={<Player/>}/> 
      <Route path='/room' element={<Room/>}/> 
      <Route path='/show/:name' element={<Show/>} />
      <Route path='/person/:name' element={<Person/>} />
      <Route path='/product' element={<ProductPage/>} />
      <Route path='/case' element={<Case/>} />
      <Route path='/cloth' element={<Cloth/>} />
      <Route path='/book' element={<Book/>} />
      <Route path='/bookpage' element={<BookPage/>} />
      <Route path='/album/:name' element={<Album/>} />

      <Route path='/car/porsche' element={<Porsche/>} />
      <Route path='/car/porsche1' element={<Porsche1/>} />
       <Route path='/car/lambo' element={<Lambo/>} />
       <Route path='/admin' element={<Admin/>}/>    

       <Route path='/companys/xintian' element={<XinTian/>}/>    
       <Route path='/companys/groupchop' element={<GroupChop/>}/>  
       <Route path='/companys/nicrg' element={<Nicrg/>}/>  
       <Route path='/companys/huoli' element={<Huoli/>}/>  
       <Route path='/companys/weiiz' element={<Weiiz/>}/>  
   <Route path='/companys/meta' element={<Meta/>}/>  
   <Route path='/companys/world' element={<World/>}/>  
      <Route path='/companys/david' element={<David/>}/>  

   <Route path='/panorama/:id' element={<Panorama/>}/>  
   <Route path='/video/:id' element={<Video/>}/>  

   <Route path='/johnny/xintian' element={<XinTian1/>} /> 
   <Route path='/johnny/weiiz' element={<Weiiz1/>} /> 
   <Route path='/johnny/groupchop' element={<GroupChop1/>} /> 
   <Route path='/johnny/world' element={<World1/>} /> 
   <Route path='/johnny/nicrg' element={<Nicrg1/>} /> 
   <Route path='/johnny/huoli' element={<Huoli1/>} /> 
   <Route path='/johnny/meta' element={<Meta1/>} /> 
   <Route path='/basic/:name' element={<Basic/>} /> 
   <Route path='/realty/:name' element={<Realty/>} /> 
   <Route path='/gallery/:name' element={<Gallery/>} /> 

   <Route path='/basic2/:name' element={<Basic2/>} /> 
   <Route path='/basic3/:name' element={<Basic3/>} /> 
   <Route path='/pro/:name' element={<Pro/>} /> 
   <Route path='/profession' element={<Profession/>} /> 
   <Route path='/kangnaixin' element={<KangNaiXin/>} /> 

    <Route path='/meta' element={<Damn/>} />
    <Route path='/restaurant/:name' element={<Restaurant/>} />
    <Route path='/restaurant2/:name' element={<Restaurant2/>} />
    <Route path='/business/:name' element={<Business/>} />
    <Route path='/information' element={<Information/>} />
    <Route path='/information/success' element={<Success/>} />
    <Route path='/poster/:name' element={<Poster/>} />
    <Route path='/wedding/:name' element={<Wedding/>} />
    <Route path='/wedding2/:name' element={<Wedding2/>} />
    <Route path='/auth' element={<Auth/>} />
    <Route path='/auth/create' element={<Create/>} />
    <Route path='/cards/pro/:name' element={<High/>} />
    <Route path='/3dnews/:name' element={<CubeNews/>} />

    <Route path='/auth/login' element={<Login/>} />
    <Route path='/auth/post' element={<AuthPost/>} />
    <Route path='/auth/news' element={<AuthNews/>} />
    <Route path='/auth/coupon' element={<AuthCoupon/>} />
    <Route path='/auth/card' element={<AuthCard/>} />
    <Route path='/auth/merchant' element={<AuthMerchant/>} />
    <Route path='/auth/sms' element={<AuthSms/>} />
    <Route path='/auth/ads' element={<AuthAds/>} />
    <Route path='/auth/player' element={<AuthPlayer/>} />
    <Route path='/auth/cube' element={<AuthCube/>} />

    {/* <Route path='/' element={<AuthView/>} /> */}

    <Route path='/user/dashboard' element={<UserPage/>} />
    <Route path='/user/login' element={<UserLogin/>} />
    <Route path='/user/register' element={<UserSignUp/>} />
    <Route path='/user/post/history' element={<UserPost/>} />
    <Route path='/user/post/new' element={<UserPostNew/>} />
    <Route path='/recommand' element={<UserLike/>} />
    <Route path='/entertainment' element={<Entertainment/>} />

    <Route path='/coupon' element={<CouponPage/>}  />
    <Route path='/3dcard/:name' element={<Card3d/>}  />
    <Route path='/merchant/:name' element={<Merchant/>}  />
    <Route path='/designer/login' element={<DesignerLogin/>} />   
    <Route path='/designer/dashboard' element={<DesignerDashboard/>} />   
    <Route path='/privacy' element={<PrivacyPage/>} />   
    <Route path='/viewer' element={<UserViewer/>} />   
    <Route path='/activities/crystal' element={<Crystal/>} />   

      <Route path="*" element={<NotFound />} />



      

     
     
    </Routes>

    </BrowserRouter>
      
       
        
  );

}




export default App;
