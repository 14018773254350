import React,{useState,useEffect,useRef,Suspense}from 'react'
import { Canvas, useFrame,extend,useThree,useLoader,} from '@react-three/fiber'
import * as THREE from "three";

import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { TextureLoader } from 'three/src/loaders/TextureLoader.js';
import {useGLTF,Html, useProgress} from '@react-three/drei'
import {useParams} from 'react-router-dom'
import fetchSingle from '../../single'
import './index.scss'

const  Cube = ({position})=>{

  const image = 'https://res.cloudinary.com/dvr397xda/image/upload/v1653324529/cube/%E8%BE%B9%E6%A1%862_b3lfk1.png'
  const mesh = useRef()
  const map = useLoader(THREE.TextureLoader,image)
  useFrame((state, delta) => {
    mesh.current.scale.x = 1 + (1 + Math.sin(state.clock.elapsedTime * 1)) * 0.03
    mesh.current.scale.y = 1 + (1 + Math.sin(state.clock.elapsedTime * 1)) * 0.03
    mesh.current.scale.z = 1 + (1 + Math.sin(state.clock.elapsedTime * 1)) * 0.03
  })

  return (
    <>
    <mesh position={position} ref={mesh}>
        <boxBufferGeometry  attach="geometry"  args={[2.2, 2.2, 2.2]}/>
        <meshStandardMaterial transparent={true} opacity={1}  attach="material" map={map}  roughness={0.7}
        metalness={0.1} />

       


    </mesh>
    </>
  )
}


function Box(props) {
  const {people,position} = props
  const mesh = useRef()

  let textures;
textures = useLoader(TextureLoader,[people.images[0],people.images[1],people.images[2],people.images[3],people.images[4],people.images[5]])
//  textures[0].minFilter = THREE.LinearFilter;
//   textures[0].magFilter = THREE.LinearFilter;
//   textures[0].format = THREE.RGBAFormat;
textures.mapping = THREE.EquirectangularReflectionMapping

 


  function Map (){
    return(
    
      textures.map((texture, idx) =>(
        idx ===2||idx===3?<meshBasicMaterial key={idx} attach={`material-${idx}`} map={texture} transparent={true} opacity={0.2} />
        :<meshBasicMaterial  key={idx} attach={`material-${idx}`} map={texture}  />
        )
)
    )
  } 

  return (
    <>
    <mesh
      onClick={(e)=>{
        e.stopPropagation()
      }}
      ref={mesh}
     position={position}
     
      >
          
      <boxBufferGeometry attach="geometry"  args={[2, 2, 2]}/>
        <Map/>
       

    </mesh>


    </>
  )
}

extend({ OrbitControls });

const CameraControls = () => {

  const {
    camera,
    gl: { domElement },
  } = useThree();
  const controls = useRef();
  useFrame(() => controls.current.update());
  return (
    <orbitControls
      ref={controls}
      args={[camera, domElement]}
      autoRotate={false}
      enableZoom={true}
      maxDistance={5.5}
      minDistance={2}
    />
  );
};

const Phone = ({people})=>{
  const image = 'https://res.cloudinary.com/dvr397xda/image/upload/v1650920511/cube/%E7%8E%BB%E7%92%832_ukdjz2.jpg'
  const mesh1 = useRef()
  const map = useLoader(TextureLoader,image)

  const handleClick=()=>{
    window.open(`tel:+${people.tel}`,'_self')
  }

  return (
    <>
    <mesh position={[-1.2,-0.5,0]} onClick={(e)=>{ handleClick();     e.stopPropagation()
}}   ref={mesh1}>
        <boxBufferGeometry  attach="geometry"  args={[0.1,1,2]}/>
        <meshStandardMaterial transparent={true} opacity={0}  attach="material" map={map}  roughness={0.7}
        metalness={0.1} />

       


    </mesh>
    </>
  )
}

const Email = ({people})=>{
  const image = 'https://res.cloudinary.com/dvr397xda/image/upload/v1650920511/cube/%E7%8E%BB%E7%92%832_ukdjz2.jpg'
  const mesh1 = useRef()
  const map = useLoader(TextureLoader,image)

  const handleClick=()=>{
    console.log(1);
    window.open(`mailto:+${people.email}`,'_self')
  }

  return (
    <>
    <mesh position={[-1.2,-1.5,-0.5]} onClick={(e)=>{ handleClick();     e.stopPropagation()
}}   ref={mesh1}>
        <boxBufferGeometry  attach="geometry"  args={[0.1,1,1]}/>
        <meshStandardMaterial transparent={true} opacity={0}  attach="material" map={map}  roughness={0.7}
        metalness={0.1} />

       


    </mesh>
    </>
  )
}

const Map = ({people})=>{
  const image = 'https://res.cloudinary.com/dvr397xda/image/upload/v1650920511/cube/%E7%8E%BB%E7%92%832_ukdjz2.jpg'
  const mesh1 = useRef()
  const map = useLoader(TextureLoader,image)

  const handleClick=()=>{
    console.log(1);
    window.open(`${people.map}`,'_self')
  }

  return (
    <>
    <mesh position={[-1.2,-1.5,0.5]} onClick={(e)=>{ handleClick();     e.stopPropagation()
}}   ref={mesh1}>
        <boxBufferGeometry  attach="geometry"  args={[0.1,1,1]}/>
        <meshStandardMaterial transparent={true} opacity={0}  attach="material" map={map}  roughness={0.7}
        metalness={0.1} />

       


    </mesh>
    </>
  )
}

const Web = ({people})=>{
  const image = 'https://res.cloudinary.com/dvr397xda/image/upload/v1650920511/cube/%E7%8E%BB%E7%92%832_ukdjz2.jpg'
  const mesh1 = useRef()
  const map = useLoader(TextureLoader,image)

  const handleClick=()=>{
    window.open(`${people.website}`,'_self')
  }

  return (
    <>
    <mesh rotation={[0, Math.PI * 2.5, 0]} position={[0,-1,1.2]} onClick={(e)=>{ handleClick();     e.stopPropagation()
}}   ref={mesh1}>
        <boxBufferGeometry  attach="geometry"  args={[0.1,2,2]}/>
        <meshStandardMaterial transparent={true} opacity={0}  attach="material" map={map}  roughness={0.7}
        metalness={0.1} />

       


    </mesh>
    </>
  )
}

const Video = ({people,position,rotation,scale,isPlay,setIsPlay})=>{

  const image = 'https://res.cloudinary.com/dvr397xda/image/upload/v1654804804/cube/play-button_1_k5h0a8.png'
  const mesh1 = useRef()
  const map = useLoader(TextureLoader,image)

  const video = document.getElementById('video');
  const  texture = new THREE.VideoTexture(video);
  // texture.needsUpdate;
  texture.minFilter = THREE.LinearFilter;
  texture.magFilter = THREE.LinearFilter;
  texture.format = THREE.RGBAFormat;
  texture.crossOrigin = 'anonymous';

  const handleClick=()=>{
    // const vid = document.createElement("video");
    // vid.src = people.video;
    // vid.crossOrigin = "Anonymous";
    // vid.loop = true;
    // vid.muted = false;
    // vid.play();
    // setVideo(vid) ;
      // setOpacity(1)
    // window.open(`${people.website}`,'_self')
    video.src = people.video;
    video.crossOrigin = "Anonymous";
  video.load();
  video.play();
    video.muted = false;
    setIsPlay(true)
  }
  const { nodes } = useGLTF('/tv.gltf');

  // const [video] = useState(() => {
  //   const vid = document.createElement("video");
  //   vid.src = people.video;
  //   vid.crossOrigin = "Anonymous";
  //   vid.loop = true;
  //   vid.muted = true;
  //   vid.play();
  //   return vid;
  // });
  return (
    <group position={position} scale={scale}  rotation={rotation}>
    <mesh  scale={0.8} rotation={[0, Math.PI * 5, 0]} position={[0,1.5,1]} geometry={nodes.TV.geometry}>
        <meshStandardMaterial color="grey" />
      </mesh>
    <mesh scale={0.8}  rotation={[0, 0, 0]} position={[0,1.5,0.2]} onClick={(e)=>{ handleClick();     e.stopPropagation()
}}   ref={mesh1}>
        {/* <boxBufferGeometry  attach="geometry"  args={[0.1,2,2]}/>
        <meshStandardMaterial transparent={true} opacity={opacity}  attach="material" map={map}  roughness={0.7}
        metalness={0.1} /> */}
      <planeGeometry args={[3.2, 1.9]} />
        <meshStandardMaterial map={texture}  side={THREE.DoubleSide}/>
          
      
        
    </mesh>

    <mesh scale={0.8}  rotation={[0, 0, 0]} position={[0,1.5,0.21]} 
  >
        {/* <boxBufferGeometry  attach="geometry"  args={[0.1,2,2]}/>
        <meshStandardMaterial transparent={true} opacity={opacity}  attach="material" map={map}  roughness={0.7}
        metalness={0.1} /> */}
      <planeGeometry args={[3.2, 1.9]} />
        <meshStandardMaterial  map={map} transparent={true} opacity={isPlay ? 0 : 1}  side={THREE.DoubleSide}/>
          
      
        
    </mesh>
    </group>
 
  )
}

export default function Single() {

  const{ name } = useParams();
  const [people,setPeople] = useState({})
  const [isPlay,setIsPlay] = useState(false)

  useEffect(() => {
    const data = fetchSingle()

    const result = data.find(e=>e.id===name)
    setPeople(result)
    console.log(result);
  },[name])


  return (
    <div className="container-card">
     
     <Canvas  gl={{ alpha: true, antialias: true}}  className='canvas-wrap'>
    <CameraControls />
    {/* <Env background={background}/> */}
    {/* <Environment files={background} /> */}
      <ambientLight intensity={1}  position={[0,0,0]}/>
      <pointLight  position={[0,0,0]} />
      <Suspense fallback={<Loader />}>
      {/* <PresentationControls
       global
      > */}

        
      <Box people={people}   position={[0, -1, 0]} />
      

      <Cube position={[0, -1, 0]} />

      <Phone people={people}/>
      <Email people={people}/>
      <Map people={people}/>
      <Web people={people} />
      {/* <Video isPlay={isPlay} setIsPlay={setIsPlay} position={[0, 0, 0]}  people={people}/> */}
      <Video isPlay={isPlay} setIsPlay={setIsPlay} position={[0, 0, 1]} rotation={[0,0,0]} scale={0.8}  people={people}/>

      <Video isPlay={isPlay} setIsPlay={setIsPlay} position={[0, 0, -1]} rotation={[0,Math.PI * 5,0]} scale={0.8}  people={people}/>
      <Video isPlay={isPlay} setIsPlay={setIsPlay} position={[1, 0, 0]} rotation={[0,Math.PI * 2.5,0]} scale={0.8}  people={people}/>
      <Video isPlay={isPlay} setIsPlay={setIsPlay} position={[-1, 0, 0]} rotation={[0,-Math.PI * 2.5,0]} scale={0.8}  people={people}/>
        {/* </PresentationControls> */}

    
      </Suspense>

    </Canvas>
      
      {/* <div className="container-player">
        <Player/>
      </div> */}
      {/* <div>
        <video id='video' src={people.video}/>
      </div> */}
          <video playsInline loop   autoPlay id="video"/>

    </div>
  )
}
function Loader() {
  const { progress } = useProgress()
  return <Html center>Loading ...{progress.toFixed(0)}%</Html>
}