import React, { useEffect, useState } from "react";
import {
  PaymentElement,
  useStripe,
  useElements
} from "@stripe/react-stripe-js";
import axios from 'axios'

import './index.scss'

const apiUrl ='https://metaserverapp.com'


export default function CheckoutForm({email,setSuccess,amount,userId,setPaymentId,setUser,point,user}) {
  const stripe = useStripe();
  const elements = useElements();

console.log(stripe,elements)
  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [loaded,setLoaded] = useState(false);

  useEffect(() => {
      if(elements){
        setLoaded(true)
      }

  },[elements])

  useEffect(() => {
    if (!stripe) {
      return;
    }
   

    const clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    );

    if (!clientSecret) {
      return;
    }
  

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      switch (paymentIntent.status) {
        case "succeeded":
            // create Order
          setMessage("Payment succeeded!");
          break;
        case "processing":
          setMessage("Your payment is processing.");
          break;
        case "requires_payment_method":
          setMessage("Your payment was not successful, please try again.");
          break;
        default:
          setMessage("Something went wrong.");
          break;
      }
    });
  }, [stripe]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }
   

    setIsLoading(true);

    const result = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        // return_url: `${window.location.origin}/information/success`,
        
        receipt_email: email,

      },
      redirect:'if_required'
    });
    console.log(result);
    if(result.paymentIntent.status ==='succeeded') {

        const temp =result.paymentIntent.id
        setPaymentId(temp)

        const data = await axios.post(`${apiUrl}/order/create`,{
            
            email:email.toLowerCase(),
            userId:user._id,
            amount,
            price:point,
            paymentId:temp,
            
        })
        if(data.data.success) {

          const result2 = await axios.post(`${apiUrl}/auth/user/point`,{userId:user._id,point:point+user.point})
          console.log(result2);
          if(result2.data.success) {
            let temp2 = {...user}
            temp2.point = point+temp2.point
          setUser(temp2)
          setSuccess(true)
          window.location.reload()

          }
          else{
            alert('Payment Failed! Please try again!')
            window.location.reload()
          }

        }

        // create Order
    }

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    // if (error.type === "card_error" || error.type === "validation_error") {
    //   setMessage(error.message);
    // } else {
    //   setMessage("An unexpected error occurred.");
    // }

    setIsLoading(false);
  };

  return (
      <div className="payment-wrap">

    { loaded ?<form id="payment-form" onSubmit={handleSubmit}>
        
      <div>
        <PaymentElement id="payment-element" />
      <button disabled={isLoading || !stripe || !elements} id="submit">
        <span id="button-text">
          {isLoading ? <div className="spinner" id="spinner"></div> : "Pay now"}
        </span>
      </button>
        </div> 
     
      {/* Show any error or success messages */}
      {message && <div id="payment-message">
          <h2>
          {message}
          </h2>
      </div>}
    </form> :  <div className="spinner" id="spinner"></div>  }
    </div>
  );
}