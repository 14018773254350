import React,{useState,useEffect,useRef,Suspense,useCallback}from 'react'
import { Canvas, useFrame,extend,useThree,useLoader,} from '@react-three/fiber'
import * as THREE from "three";
import HTMLFlipBook from "react-pageflip";
import {useParams} from 'react-router-dom'
import {Link,useNavigate} from 'react-router-dom'
import {
  TelephoneFill,
  GeoAlt,
  Wechat,
  Envelope,
  Globe2
} from "react-bootstrap-icons";

import { TextureLoader } from 'three/src/loaders/TextureLoader.js';
import fetchBusiness from '../../business'
import {useGLTF,useFBX,OrbitControls} from '@react-three/drei'

import './index.scss'


const  Model=({people})=>{
    const mesh = useRef()
    const defaultMap ='https://res.cloudinary.com/dvr397xda/image/upload/v1659113641/cube/basic/yuxin/Mew_Diff_tg9hgf.png'
    let key = useFBX(people.model.url)

  
    const [colorMap, metalMap, normalMap, roughnessMap, aoMap] = useLoader(THREE.TextureLoader, [
        people.model.colorMap||defaultMap,
        people.model.metalMap||defaultMap,
        people.model.normalMap||defaultMap,
        people.model.roughnessMap||defaultMap,
        people.model.aoMap||defaultMap,
      ])
    


    key.traverse( function ( child ) {

        if ( child.isMesh ) {
            console.log(child);
            if(people.model.colorMap)
            child.material.map = colorMap;
            if(people.model.aoMap)
            child.material.aoMap =aoMap;
            if(people.model.normalMap)
            child.material.normalMap = normalMap;
            if(people.model.metalMap)
            child.material.metalMap = metalMap; 
            if(people.model.roughnessMap)
            child.material.roughnessMap = roughnessMap; 
          }
      
      } );
      useFrame((state, delta) => {
      
    
        // mesh.current.rotation.y += 0.005
      
      })

      return (
        <mesh scale={people.model.scale} position={people.model.position} rotation={people.model.rotation}  ref={mesh}>
            <primitive   object={key} />
    
        </mesh>
      )

}






function Book({people}){



  const onFlip = useCallback((e) => {
    console.log('Current page: ' + e.data);
}, []);

  return (


 
  <HTMLFlipBook className="wrapper" 
  size='stretch'  width={195}  height={338}        maxShadowOpacity={0.5}
  onFlip={onFlip}
  showCover
 >
  
  {
      people.show&&people.show.map((v,i)=>(
          <div id='page-item' className="demoPage" key={i}>
            
            <h2>
                    {v.name}
                </h2>
                {v.title&&<h2>
                    {v.title}
                </h2>}
               
             <img src={v.image} alt="" />
               
          </div>
      ))
  }
</HTMLFlipBook>

)
}


function Box(props) {
  const {people,position} = props
  const mesh = useRef()
  const [index,setIndex] = useState(0)

 

 
  // setInterval(handleChangeIndex,1000)

  let textures;

textures = useLoader(THREE.TextureLoader,[people.images[0],people.images[1],people.images[2],people.images[3],people.images[4],people.images[5]])
// textures = useLoader(TextureLoader,[people.images[0],people.images[1],people.images[2],people.images[3],people.images[4],people.images[5]])

textures.minFilter = textures.magFilter = THREE.LinearFilter;



textures.format= THREE.RGBAFormat;




  function Map (){
    return(
    
      textures.map((texture, idx) =>(
        idx ===2||idx===3 || idx===0?<meshBasicMaterial key={idx} attach={`material-${idx}`} map={texture}   transparent={true} opacity={1}/>
        :<meshBasicMaterial opacity={1} transparent={true}  key={idx} attach={`material-${idx}`} map={texture}  side={THREE.DoubleSide}  />
        )
)
    )
  } 

  return (
    <>
    <mesh
      onClick={(e)=>{
        e.stopPropagation()
      }}
      ref={mesh}
     position={position}
     
      >
          
      <boxBufferGeometry attach="geometry"  args={[3.5, 1.75, 0.1]}/>
        <Map/>
       
      

    </mesh>


    </>
  )
}



const Phone = ({people,opacity,posY})=>{

  const mesh1 = useRef()


  const handleClick=()=>{
    window.open(`tel:+1${people.tel}`,'_self')
  }


  return (
    <>
    <mesh position={people.phonePos} rotation={[0,Math.PI * 2.5,0]} onClick={(e)=>{ handleClick();     e.stopPropagation()
}}   ref={mesh1}>
        <boxBufferGeometry  attach="geometry"  args={[0.1,0.4,1.5]}/>
        <meshStandardMaterial transparent={true} opacity={opacity}   color='green'  visible={false} />

       


    </mesh>
    </>
  )
}

const Cell = ({people,opacity,posY})=>{
   
    const mesh1 = useRef()
 
  
    const handleClick=()=>{
      window.open(`tel:+1${people.cell}`,'_self')
    }
  
  
    return (
      <>
      <mesh position={people.cellPos} rotation={[0,Math.PI * 2.5,0]} onClick={(e)=>{ handleClick();     e.stopPropagation()
  }}   ref={mesh1}>
          <boxBufferGeometry  attach="geometry"  args={[0.1,0.2,1.3]}/>
          <meshStandardMaterial transparent={true} opacity={opacity}  color='red'  visible={false}/>
  
         
  
  
      </mesh>
      </>
    )
  }

const Email = ({people,opacity,posY})=>{

  const mesh1 = useRef()
 

  const handleClick=()=>{
 
    window.open(`mailto:+${people.email}`,'_self')
  }

  return (
    <>
    <mesh position={people.emailPos} rotation={[0,Math.PI * 2.5,0]} onClick={(e)=>{ handleClick();     e.stopPropagation()
}}   ref={mesh1}>
        <boxBufferGeometry  attach="geometry"  args={[0.1,0.2,1.3]}/>
        <meshStandardMaterial transparent={true} opacity={opacity}  color='#fff'  visible={false}/>

       


    </mesh>
    </>
  )
}

const Map = ({people,opacity,posY})=>{
 
  const mesh1 = useRef()


  const handleClick=()=>{

    window.open(`${people.map}`,'_self')
  }

  return (
    <>
    <mesh rotation={[0,Math.PI * 2.5,0]} position={people.mapPos} onClick={(e)=>{ handleClick();     e.stopPropagation()
}}   ref={mesh1}>
        <boxBufferGeometry  attach="geometry"  args={[0.1,0.2,1.3]}/>
        <meshStandardMaterial transparent={true} opacity={opacity}   color='blue'    visible={false}/>

       


    </mesh>
    </>
  )
}

const Youtube = ({people,opacity,posY})=>{

    const mesh1 = useRef()
   
  
    const handleClick=()=>{
  
      window.open(`${people.video}`,'_self')
    }
  
    return (
      <>
      <mesh position={[-1.4,-1+posY,0.6]} onClick={(e)=>{ handleClick();     e.stopPropagation()
  }}   ref={mesh1}>
          <boxBufferGeometry  attach="geometry"  args={[0.1,0.7,1.2]}/>
          <meshStandardMaterial transparent={true} opacity={opacity}  attach="material" color='green'  roughness={0.7}
          metalness={0.1} />
  
         
  
  
      </mesh>
      </>
    )
  }

  const QR = ({opacity,posY,handleOpenQr,people})=>{
  
    const mesh1 = useRef()
  
  
  
    return (
      <>
      <mesh rotation={[0, Math.PI * 2.5, 0]} position={people.qrPos} onClick={(e)=>{  handleOpenQr();     e.stopPropagation()
  }}   ref={mesh1}>
          <boxBufferGeometry  attach="geometry"  args={[0.15,0.8,0.8]}/>
          <meshStandardMaterial transparent={true} opacity={opacity}   color='green' visible={false}  />
  
         
  
  
      </mesh>
      </>
    )
  }



const Web = ({people,opacity,posY})=>{
 
  const mesh1 = useRef()

  const handleClick=()=>{
    window.open(`${people.website}`,'_self')
  }

  return (
    <>
    <mesh rotation={[0, Math.PI * 2.5, 0]} position={people.webPos} onClick={(e)=>{ handleClick();     e.stopPropagation()
}}   ref={mesh1}>
        <boxBufferGeometry  attach="geometry"  args={[0.1,0.8,0.8]}/>
        <meshStandardMaterial transparent={true} opacity={opacity}   color='#fff'   visible={false} />

       


    </mesh>
    </>
  )
}

const Living = ({people,opacity,posY})=>{
    const mesh1 = useRef()

    const handleClick=()=>{
      window.open(`${people.living}`,'_self')
    }
  
    return (
      <>
      <mesh position={[-1.3,0,0]} rotation={[0,0,0]} onClick={(e)=>{ handleClick();     e.stopPropagation()
  }}   ref={mesh1}>
          <boxBufferGeometry  attach="geometry"  args={[0.1,2.4,2.4]}/>
          <meshStandardMaterial transparent={true} opacity={opacity}   color='#fff'   />
  
         
  
  
      </mesh>
      </>
    )
}


export default function Business() {
    const{ name } = useParams();
    
    const [people,setPeople]=useState({})

   useEffect(()=>{

    const data = fetchBusiness();
    const result = data.filter(e=>e.id===name)
 
    setPeople(result[0])

   },[])



  const [isTeam,setIsTeam] = useState(false)
  const [isQR,setIsQR] = useState(false)
  const opacity=0
  const posY = 1


  const handleOpenQr = ()=>{
      setIsQR(true)
  }




  const handleClickTeam = ()=>{
    setIsTeam(true)
  }
  const handleCloseTeam = ()=>{
    setIsTeam(false)
    setIsQR(false)
  }

  const handleClickAddress=()=>{

    window.open(`${people.map}`,'_self')
  }

  const handleClickPhone=()=>{

    window.open(`tel:+1${people.tel}`,'_self')
  }

  const handleClickWebsite=()=>{

    window.open(`${people.website}`,'_self')
  }

  const handleClickEmail=()=>{

    window.open(`mailto:+${people.email}`,'_self')
  }
  const handleWechat = ()=>{
    setIsQR(true)
  }


  return (
    <div className="container-card" style={{backgroundImage:`url(${people.gif})`}}  >
           <Link id='return-home' to='/recommand'><img src="https://res.cloudinary.com/dvr397xda/image/upload/v1664834364/cube/Free/metalogo_ctuhtw.png" alt="" /></Link>
     
     <Canvas camera={{ position: [0, 0, 4.5] }}  gl={{ alpha: true, antialias: true}}  className='canvas-wrap'>
     <OrbitControls minPolarAngle={Math.PI / 2} maxPolarAngle={Math.PI / 2.5} />
     <ambientLight  />


      <Suspense fallback={null}>
   

        
      <Box people={people}   position={[0, 0, 0]} />
    
        {/* <Model people={people}/> */}
      {/* <Cube position={[0, 0, 0]} /> */}
      

      <Phone posY={posY} people={people} opacity={opacity}/>
      {people.cell&&<Cell posY={posY} people={people} opacity={opacity}/>}

      <Email posY={posY} people={people} opacity={opacity}/>
      <QR handleOpenQr={handleOpenQr} posY={posY} people={people} opacity={opacity}/>
      <Map posY={posY} people={people} opacity={opacity}/>
      <Web posY={posY} people={people} opacity={opacity}/>
     { people.video&&<Youtube posY={posY} people={people} opacity={opacity}/>}
     {people.living&&<Living posY={posY} people={people} opacity={opacity}/>}

      {/* <Linkedin posY={posY} people={people} opacity={opacity}/> */}
      {/* <VideoBtn people={people} opacity={opacity}/> */}

      {/* <mesh rotation={[0,0,0]} position={[-1.4,-1.8+posY,-0.6]} onClick={(e)=>{ handleClickTeam();     e.stopPropagation()
}}   ref={mesh2}>
        <boxBufferGeometry  attach="geometry"  args={[0.1,0.7,1.2]}/>
        <meshStandardMaterial transparent={true} opacity={opacity}    color='red'   />

       
    </mesh> */}
    
    </Suspense>

    </Canvas>
    {people.show&&<div style={isTeam ? {top:'20%',transition:'1.5s'}:{top:'200%',transition:'1.5s'}}  className="wrapper">
          <Book people={people}/>
          <img onClick={()=>handleCloseTeam()} id='close' src="https://res.cloudinary.com/dvr397xda/image/upload/v1656101942/cube/cancel_dmukx0.png" alt="" />
    </div>}
         

         

          <div style={isQR ? {top:'20%',transition:'1.5s'}:{top:'200%',transition:'1.5s'}}  className="wrapper">
          <img id='qr' src={people.qrCode} alt="" />
          <h3>长按识别图中二维码</h3>

          <img onClick={()=>handleCloseTeam()} id='close' src="https://res.cloudinary.com/dvr397xda/image/upload/v1656101942/cube/cancel_dmukx0.png" alt="" />
    </div>

    <div className='bottom-icon-wrap'>

      <div>
        <TelephoneFill onClick={()=>handleClickPhone()}/>
        <GeoAlt onClick={()=>handleClickAddress()}/>
        <Wechat onClick={()=>handleWechat()}/>
        <Globe2 onClick={()=>handleClickWebsite()}/>
        <Envelope onClick={()=>handleClickEmail()}/>
      </div>

    </div>

    </div>
  )
}

