import React from 'react'

export default function Success() {
    console.log(window.location)
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
   
    const payment_intent = urlParams.get('payment_intent')
    const payment_intent_client_secret = urlParams.get('payment_intent_client_secret')
  return (
    <div>
        <h2>支付成功</h2>
        
        <h6>{payment_intent}</h6>
        <h6>{payment_intent_client_secret}</h6>
    </div>
  )
}

