import React,{useState,useEffect} from 'react'
import {Link,Navigate,useNavigate} from 'react-router-dom'
import axios from 'axios'
import Update from './Update/index'
import './index.scss'

export default function Admin() {

  const apiUrl ='https://metaserverapp.com'
  const [cards,setCards]=useState([])
  const [isLoaded,setIsLoaded] = useState(false)
  const [isDeleted,setIsDeleted] = useState([])
  const [isUpdated,setIsUpdated] = useState(false)
  const [target,setTarget] = useState({})
  const [admin,setAdmin] = useState({})

  const adminToken = window.localStorage.getItem('adminToken')
  const adminId = window.localStorage.getItem('adminId')
  const navigate = useNavigate();


// console.log(adminToken)

  useEffect(() => {

      const fetchAdmin = async()=>{
        const data =await axios.get(`${apiUrl}/auth/admin/id`,{params:{id:adminId}})
        console.log(data)
        setAdmin(data.data.data)
      }
      fetchAdmin()

  },[])

  useEffect(() => {
    const fetchData = async () => {
      const data = await axios.get(`${apiUrl}/basic/all`)
      console.log(data);
      if(data.data.success){
        setCards(data.data.data)
        let temp =[]
        for(let i = 0; i < data.data.data.length;i++) {
            temp.push(false)
        }
        setIsDeleted(temp)
        setIsLoaded(true)
      }
    }
    fetchData()
  },[])

  const handleView = (v)=>{
    window.open(`https://meta11w.com/cards/pro/${v.id}`,'_self')
    // console.log(v)
  }

  const handleDelete=(i)=>{
    let temp = [...isDeleted]
    temp[i]=true
    setIsDeleted(temp)
  }

  const handleDeleteSure=async(v)=>{
    const data = await axios.post(`${apiUrl}/basic/delete`,{id:v._id})
      if(data.data.success){
        window.location.reload()
      }
   
  }
  const handleDeleteStop=(i)=>{
    let temp = [...isDeleted]
    temp[i]=false
    setIsDeleted(temp)
  }

  const handleUpDate=(v)=>{
    setIsUpdated(true)
    setTarget(v)
    console.log(v)
  }

  const handleCancelUpdate =()=>{
    setIsUpdated(false)

  }

  const handleLogout =()=>{
    window.localStorage.setItem('adminId','')
    window.localStorage.setItem('adminToken','')
    navigate("/auth/login");

  }

// console.log(cards[0],isDeleted);
  return (
    <>
    {adminToken ?<div className="auth-admin-container">
      {!isUpdated &&<div className='admin-dashboard'>
          <div>
            {admin.phone&&<div>

                <h4>管理员:{admin.firstName}</h4>
                <div>
                <img src={admin.avatar} alt="" />
                  <button onClick={()=>handleLogout()}>退出登录</button>
                </div>

            </div>}
          <h2>所有名片</h2>
          <Link to='/auth/card'><h3>所有3d名片</h3></Link>
          <Link to='/auth/cube'><h3>所有3d新闻</h3></Link>

          <Link to='/auth/merchant'><h3>所有餐饮名片</h3></Link>

          <Link to='/auth/post'> <h3>所有帖子</h3> </Link>
          <Link to='/auth/news'> <h3>所有新闻</h3> </Link>
          <Link to='/auth/coupon'> <h3>所有折扣</h3> </Link>
          <Link to='/auth/sms'> <h3>群发短信</h3> </Link>
          <Link to='/auth/create'><h3>创建名片</h3></Link>
          <Link to='/auth/ads'><h3>创建广告</h3></Link>
          <Link to='/auth/cube'><h3>所有3d新闻</h3></Link>

          {/* <Link to='/auth/player'><h3>参赛选手</h3></Link> */}

          </div>
          <table>
          <thead>
            <tr>
                  <th>名字</th>
                  <th>网址</th>
                  <th>职业</th>
                  <th>地点</th>
                  <th>分类</th>
                  <th>地址</th>
                  <th>电话</th>
                  <th>二维码</th>
                  <th>终极二维码</th>
                  <th>缩略图</th>
                  <th>点击量</th>
                  <th>点击观看</th>
                  <th>修改</th>

                  <th>删除</th>
            </tr>
          </thead>
          <tbody>
            {
              isLoaded&&cards.map((v,i)=>(
                <tr key={i}>
                  <td>{v.name}</td>
                  <td>{`https://meta11w.com/cards/pro/${v.id}`}</td>
                  <td>{v.title}</td>
                  <td>{v.location}</td>
                  <td>{v.category}</td>
                  <td>{v.address}</td>
                  <td>{v.tel}</td>
                  <td> <img src={v.webCode} alt="" /> </td>
                  <td> <img src={v.psCode} alt="" /> </td>

                  <td> <img src={v.images[4]} alt="" /> </td>
                  <td>{v.views}</td>
                  <td> <button onClick={()=>handleView(v)}>View</button> </td>
                  <td> <button onClick={()=>handleUpDate(v)}>修改</button> </td>
                  {isDeleted[i] ?<td> <button onClick={()=>handleDeleteSure(v)}>确定</button> <button onClick={()=>handleDeleteStop(i)}>取消</button></td> :<td> <button onClick={()=>handleDelete(i)}>Delete</button></td>}
                </tr>
              ))
            }
          </tbody>



      </table>
           
      </div>}

     

      {isUpdated&&<div className="container-update">

            <div className='update-top'>
              <h4>修改{target.name}的名片</h4>
              <button onClick={()=>handleCancelUpdate()}>取消修改</button>
            </div>


            <Update target={target}/>

   
      </div>}

      
       
        
    

    </div> : <Navigate to='/auth/login' />}

    </>
  )
}
