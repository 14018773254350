import React ,{useState}from 'react'
import './index.scss'
import {Link} from 'react-router-dom'
import { Helmet } from 'react-helmet';

export default function ModelPage() {


    const CASE = [
        {
            name:'还原内部构造',
            des:'技术/材质优势体现',
            url:'https://res.cloudinary.com/dvr397xda/image/upload/v1675095297/meta-video/2_AdobeExpress_gyl6o9.gif'
        },
        {
            name:'工作原理视频',
            des:'科技带来信任',
            url:'https://res.cloudinary.com/dvr397xda/image/upload/v1675095299/meta-video/3_AdobeExpress_yzn6wn.gif'
        },
        {
            name:'使用操作视频',
            des:'更直观/视觉冲击力强',
            url:'https://res.cloudinary.com/dvr397xda/image/upload/v1675095300/meta-video/4_AdobeExpress_veytpx.gif'
        },
        {
            name:'人声解说',
            des:'同步解说，更易懂',
            url:'https://res.cloudinary.com/dvr397xda/image/upload/v1675095298/meta-video/5_AdobeExpress_zev4iv.gif'
        },
    ]

    const MODEL = [
      
        {
            name:'保时捷',
            img:'https://res.cloudinary.com/dvr397xda/image/upload/v1675100822/meta-model/WechatIMG442_uureof.jpg',
            url:'/car/porsche',
            category:''
        },
        {
            name:'保时捷',
            img:'https://res.cloudinary.com/dvr397xda/image/upload/v1675100822/meta-model/WechatIMG443_ag29y8.jpg',
            url:'/car/porsche1',
            category:''
        },
        {
            name:'兰博基尼',
            img:'https://res.cloudinary.com/dvr397xda/image/upload/v1675100822/meta-model/WechatIMG444_fvq9ns.jpg',
            url:'/car/lambo',
            category:''
        },
        {
            name:'博物馆',
            img:'https://res.cloudinary.com/dvr397xda/image/upload/v1675100822/meta-model/WechatIMG445_my4gdq.jpg',
            url:'/museum2',
            category:''
        },
        {
            name:'衣服',
            img:'https://res.cloudinary.com/dvr397xda/image/upload/v1675100822/meta-model/WechatIMG446_ytis9o.jpg',
            url:'/cloth',
            category:''
        },
        {
            name:'地球',
            img:'https://res.cloudinary.com/dvr397xda/image/upload/v1675103008/meta-model/WechatIMG447_e8doas.jpg',
            url:'/model/earth',
            category:''
        },
        {
            name:'玫瑰',
            img:'https://res.cloudinary.com/dvr397xda/image/upload/v1675103008/meta-model/WechatIMG448_awt8fh.jpg',
            url:'/model/rose',
            category:''
        },
        {
            name:'电脑',
            img:'https://res.cloudinary.com/dvr397xda/image/upload/v1675103525/meta-model/WechatIMG449_bmw1ln.jpg',
            url:'/model/computer',
            category:''
        },
        {
            name:'录影机',
            img:'https://res.cloudinary.com/dvr397xda/image/upload/v1675103525/meta-model/WechatIMG450_h3fqzq.jpg',
            url:'/model/recorder',
            category:''
        },
        {
            name:'相机',
            img:'https://res.cloudinary.com/dvr397xda/image/upload/v1675103525/meta-model/WechatIMG451_ex45kz.jpg',
            url:'/model/camera',
            category:''
        },
        {
            name:'房子',
            img:'https://res.cloudinary.com/dvr397xda/image/upload/v1675103525/meta-model/WechatIMG452_lsnj7x.jpg',
            url:'/model/house',
            category:''
        },
        {
            name:'日式庭院',
            img:'https://res.cloudinary.com/dvr397xda/image/upload/v1675103525/meta-model/WechatIMG453_ysidpt.jpg',
            url:'/model/japan',
            category:''
        },
        {
            name:'钥匙',
            img:'https://res.cloudinary.com/dvr397xda/image/upload/v1675103525/meta-model/WechatIMG454_xreejy.jpg',
            url:'/model/key',
            category:''
        },
        {
            name:'古董花瓶',
            img:'https://res.cloudinary.com/dvr397xda/image/upload/v1675193053/model/vase/WechatIMG463_dxmchf.jpg',
            url:'/model/vase',
            category:''
        },
        {
            name:'AJ运动鞋',
            img:'https://res.cloudinary.com/dvr397xda/image/upload/v1675194635/model/aj/WechatIMG464_selopa.jpg',
            url:'/model/shoe',
            category:''
        },
        {
            name:'金属面具',
            img:'https://res.cloudinary.com/dvr397xda/image/upload/v1675284438/model/mask/WechatIMG467_maoh0q.jpg',
            url:'/model/mask',
            category:''
        },
        // {
        //     name:'运动内衣',
        //     img:'https://res.cloudinary.com/dvr397xda/image/upload/v1675206437/model/sport/WechatIMG465_pfuvmt.jpg',
        //     url:'/model/sport',
        //     category:''
        // },
      
    ]

    const LOGOS = [
        {
            url:'https://res.cloudinary.com/dvr397xda/image/upload/v1675461763/logo/3d/autodesk_autocad-logo_zp3k0r.png',
            name:'CAD'
        },
        {
            url:'https://res.cloudinary.com/dvr397xda/image/upload/v1675461646/logo/3d/BlenderDesktopLogo_crwoa3.png',
            name:'Blender'
        },
        {
            url:'https://res.cloudinary.com/dvr397xda/image/upload/v1675461646/logo/3d/vectary_167063_orwqzh.webp',
            name:'Vectary'
        },
        {
            url:'https://res.cloudinary.com/dvr397xda/image/upload/v1675461646/logo/3d/unreal-engine-optmizations-logo-759458_n5qhzs.png',
            name:'Unreal Engine'
        },
        {
            url:'https://res.cloudinary.com/dvr397xda/image/upload/v1675461645/logo/3d/C4D_Logo_fuzyg4.png',
            name:'Cinema 4D'
        },
        {
            url:'https://res.cloudinary.com/dvr397xda/image/upload/v1675461645/logo/3d/substance-logo_k4nptk.png',
            name:'Substance'
        },
        {
            url:'https://res.cloudinary.com/dvr397xda/image/upload/v1675461645/logo/3d/autodesk_maya-logo_hu8wys.png',
            name:'MAYA'
        },
        {
            url:'https://res.cloudinary.com/dvr397xda/image/upload/v1675461645/logo/3d/Houdini3D_icon_mx9kzz.png',
            name:'Houdini'
        },
        {
            url:'https://res.cloudinary.com/dvr397xda/image/upload/v1675461645/logo/3d/277761194_10159248902707851_8638248800977563498_n_ttgare.jpg',
            name:'3dMax'
        },
        {
            url:'https://res.cloudinary.com/dvr397xda/image/upload/v1675461645/logo/3d/62e131df7fe3599fdd46ecb3_e2m5e0.png',
            name:'Unity'
        },
        {
            url:'https://res.cloudinary.com/dvr397xda/image/upload/v1675461645/logo/3d/%E4%B8%8B%E8%BD%BD_1_jewbuu.jpg',
            name:'Modo'
        },
        {
            url:'https://res.cloudinary.com/dvr397xda/image/upload/v1675461645/logo/3d/1890559_h2we75.webp',
            name:'ZBrush'
        },
      
      
    ]

  return (
    <div className="model-container">
           <Helmet
      title={'元生活模型专区'}
      meta={[
       
        {
          name: `description`,
          content: '3D建模的案例与优势',
        },
        {
            property:'og:image',
            content:'https://res.cloudinary.com/dvr397xda/image/upload/v1675103525/meta-model/WechatIMG453_ysidpt.jpg'
          },
      ]}
    
    />
        <div className="model-container-scroll">
        <div className='model-top'>
            <div>
            <img src="https://res.cloudinary.com/dvr397xda/image/upload/v1674853518/logo/WechatIMG15357_kp2gvq.png" alt="" />
            <h4>元生活3D模型专区</h4>
            <p>互联网的传统形态一直以来都是2D模式的，但是随着3D技术的不断进步，将会有越来越多的互联网应用以3D的方式呈现给用户，包括网络视讯、电子阅读、网络游戏、虚拟社区、电子商务、远程教育等等。甚至对于旅游业，3D互联网也能够起到推动的作用，一些世界名胜、雕塑、古董将在互联网上以3D的形式来让用户体验，这种体验的真实震撼程度要远超2D环境 </p>
            </div>
         
        </div>
        
        <div className='model-middle'>
            <h2>3D建模/视频有哪些优势？</h2>

            <div className='model-grid'>

             
                {
                    CASE.map((v,i)=>(
                        <div key={i}>
                            <img src={v.url} alt="" />
                            <h3>{v.name}</h3>
                            <p>{v.des}</p>
                        </div>
                    ))
                }

            </div>

        </div>
        <div className='model-show'>
                <h2>3D打印案例</h2>
                <img src="https://res.cloudinary.com/dvr397xda/image/upload/v1675460025/logo/3dprint_wv7t4d.gif" alt="" />
        </div>
        <div className='model-show'>
                <h2>模型创建案例</h2>
                <img src="https://res.cloudinary.com/dvr397xda/image/upload/v1675460024/logo/jianmo_uskwyq.gif" alt="" />
        </div>

        <div className='model-show'>
                <h2>7D全息图技术</h2>
                <img src="https://res.cloudinary.com/dvr397xda/image/upload/v1675464300/logo/ezgif-1-cb6cd5d0d1_ptwffc.gif" alt="" />
        </div>

        <div className='model-sample'>
            <h2>3D建模案例</h2>
            <div className='model-sample-grid'>
                <a href="https://2belowus.com/fandigang/index.html">
                   
                    <img src="https://res.cloudinary.com/dvr397xda/image/upload/v1675291433/logo/pano_u_krkxa6.jpg" alt="" />
                    <h3>梵蒂冈博物馆</h3>
                </a>
                <a href="https://2belowus.com/museum/index.html">
                   
                    <img src="https://res.cloudinary.com/dvr397xda/image/upload/v1675445906/logo/pano_u_sgtatw.jpg" alt="" />
                    <h3>梵蒂冈博物馆</h3>
                </a>
                {
                    MODEL.map((v,i)=>(
                        <Link key={i} to={v.url}>
                            <img src={v.img} alt="" />
                            <h3>{v.name}</h3>
                        </Link>
                    ))
                }
            </div>
        </div>

        <div className="model-logos">
            <h2>3D技术栈</h2>
            <div className="model-logos-wrap">
                {
                    LOGOS.map((v,i)=>(
                        <div key={i}>
                            <img src={v.url} alt="" />
                            <h4>{v.name}</h4>
                        </div>
                    ))
                }

            </div>

        </div>

        </div>
       
     

    </div>
  )
}
