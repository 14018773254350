import React ,{useRef}from "react";
import { useLoader,useFrame } from '@react-three/fiber'
import * as THREE from "three";



export default function Light ()  {

    const earthMaterial = 'https://res.cloudinary.com/dvr397xda/image/upload/v1650905847/cube/earth/2k_earth_daymap_habyjo.jpg';

    const colorMap = useLoader(THREE.TextureLoader, earthMaterial)
    const group = useRef()

    useFrame((state,delta)=>(group.current.rotation.y+=0.01))

  const FakeSphere = () => {
    return (
      <mesh>
        <sphereBufferGeometry args={[0.7, 30, 30]} attach="geometry" />
        <meshBasicMaterial  map={colorMap} attach="material" metalness={1} />
      </mesh>
    );
  };

  return (
    <group ref={group}>
      <FakeSphere />
      <ambientLight intensity={0.5} />
      <pointLight intensity={1.12} position={[0, 0, 0]} />
    </group>
  );
};
