import React from 'react'
import './index.scss'

export default function Book() {

    

    const data = [
        '1',
        '2',
        '3',
        '4',
        '5',
        '6'
    ]

    const turnPage = (i)=>{
        const target = document.getElementsByClassName(`page-${i+1}`)
        console.log(target,i);
        target[0].classList.add('turn-page')

    }

    const backPage = (i)=>{
        const target = document.getElementsByClassName(`page-${i+1}`)
        console.log(target,i);
        target[0].classList.remove('turn-page')
    }
  
const target = document.getElementsByClassName("page-1")
console.log(target);
  return (
      <>
<div className="notebook">

    {
        data&&data.map((v,i) => (
            <div  onClick={()=>turnPage(i)} key={i} className={`page page-${i+1}`} >
                PAGE {v}
            </div>
        ))
    }
  
</div>
<button type="button" className="turn-btn">
  Turn Page
</button>
<button type="button" className="reset-btn">
  Reset Page
</button>
    
    </>
    
  )
}
