const data = [
    { 
        id:'johnny',
        name:'Johnny Chen',
        tel:'2034569888',
        email:'Johnnychen@nicyg.com',
        address:'4306 Main St,Flushing,NY,11355',
        map:'https://www.google.com/maps/place/43-06+Main+St,+Flushing,+NY+11355/@40.7522664,-73.8315613,17z/data=!3m1!4b1!4m5!3m4!1s0x89c2606cd640cb53:0x141db878e9815fe!8m2!3d40.7522665!4d-73.8270766',
        video:'https://www.youtube.com/watch?v=DYb8dudXY9U&list=PLid5kTu-xpFIg5gLDt17SblFa_fCfvwmT',
        facebook:'https://www.facebook.com/johnny.chen.792197',
        background:'https://res.cloudinary.com/dvr397xda/image/upload/v1653927826/cube/1921iq6-a-room-that-has-a-umbrella-in-her-hand_rainyw.jpg',
        linkedin:'https://www.linkedin.com/in/johnny-chen-358b971bb/',
        avatar:'https://res.cloudinary.com/dvr397xda/image/upload/v1654544385/cube/4_ps8jf5.png',
        website:'https://nicrg.com/',
        images:[
            'https://res.cloudinary.com/dvr397xda/image/upload/v1656084440/cube/WechatIMG8668_jc6odx.jpg', //right
            'https://res.cloudinary.com/dvr397xda/image/upload/v1656084440/cube/WechatIMG8664_vzoheg.jpg', //left
            'https://res.cloudinary.com/dvr397xda/image/upload/v1655154770/cube/WechatIMG8403_z2nmor.jpg', 
            'https://res.cloudinary.com/dvr397xda/image/upload/v1656084440/cube/WechatIMG8666_rqu56l.jpg', 
            'https://res.cloudinary.com/dvr397xda/image/upload/v1656084440/cube/WechatIMG8663_fwaph4.jpg', //front
            'https://res.cloudinary.com/dvr397xda/image/upload/v1656084440/cube/WechatIMG8665_gsgtlk.jpg'//back
    ],
    show:[
        {
            name:'恒星国际商业智库',
            title:'团队介绍',
            image:'https://res.cloudinary.com/dvr397xda/image/upload/v1656102436/cube/%E6%99%BA%E5%BA%93_%E8%93%9D%E8%89%B2%E5%9C%86%E8%A7%92_logo_ayowz4.png'
        },
        {
            name:'Johnny Chen',
            title:'Founder Ceo',
            image:'https://res.cloudinary.com/dvr397xda/image/upload/v1655150127/cube/WechatIMG8392_lfrxly.jpg'
        },
        {
            name:'Star',
            title:'UI Designer',
            image:'https://res.cloudinary.com/dvr397xda/image/upload/v1655150127/cube/WechatIMG8395_dfgfjt.jpg'
        },
        {
            name:'Hao',
            title:'UI Designer',
            image:'https://res.cloudinary.com/dvr397xda/image/upload/v1655150127/cube/WechatIMG8398_kx1bny.jpg'
        },
        {
            name:'David',
            title:'Marketing',
            image:'https://res.cloudinary.com/dvr397xda/image/upload/v1655150127/cube/WechatIMG8393_n2lckn.jpg'
        },
        {
            name:'Lily',
            title:'Marketing',
            image:'https://res.cloudinary.com/dvr397xda/image/upload/v1656085726/cube/WechatIMG8679_w8kk3r.jpg'
        },
        {
            name:'恒星国际商业智库',
            title:'欢迎您的到来',
            image:'https://res.cloudinary.com/dvr397xda/image/upload/v1656102436/cube/%E6%99%BA%E5%BA%93_%E8%93%9D%E8%89%B2%E5%9C%86%E8%A7%92_logo_ayowz4.png'
        },

    ],
    gif:'https://res.cloudinary.com/dvr397xda/image/upload/v1650903783/cube/ezgif.com-gif-maker_b2xoms.gif'

    }

];


const fetchData = ()=>{
    return data
}

export default fetchData