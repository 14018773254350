import React,{useState,useEffect,useCallback}from 'react'
import Nav from '../../../components/Nav/index'
import Footer from '../../../components/Footer/index'
import {Link,Navigate,useNavigate} from 'react-router-dom'
import fetchPost from '../../../poster'
import fetchBusiness from '../../../business'
import axios from 'axios'
import './index.scss'


export default function UserPostNew() {


    const apiUrl ='https://metaserverapp.com'
    const token = window.localStorage.getItem('token')
    const userId = window.localStorage.getItem('userId')
    const [cards,setCards]=useState([])
    const [isCard,setIsCard]=useState(true)
    const [isPost,setIsPost]=useState(false)
    const [isBus,setIsBus]=useState(false)

  
    const [business,setBusiness]=useState([])
    const [merchant,setMerchant]=useState([])
    const [page,setPage] = useState(1)
    const [total,setTotal]=useState(0)
    const [totalPage,setTotalPage] = useState(0)

    const [index,setIndex] = useState(0)

    useEffect(() => {
      const interval = setInterval(() => {
  
          if(index ===1){
              setIndex(0)
          }
          else{
              setIndex(index+1)
          }
      }, 2500);
      return () => clearInterval(interval);
    }, [index]);

    useEffect(()=>{
      const fetchNews = async()=>{
        const result = await axios.get(`${apiUrl}/merchant/page`,{params:{page:1}})
        console.log(result)
        if(result.data.success){
    
          setMerchant(result.data.data[0].result)
  
          
        
          const temp = result.data.data[0].total[0].total
          setTotal(result.data.data[0].total[0].total)
          const temp2 = Math.ceil(temp/15)
          setTotalPage(temp2)
       
  
        }
        else{
          alert('Server Error! No data response received!')
        }
    }
    fetchNews()
    },[])



    useEffect(()=>{

      const fetchNews = async()=>{
          const result = await axios.get(`${apiUrl}/card/all`,{params:{page:1}})
          console.log(result)
          if(result.data.success){
      
            setBusiness(result.data.data[0].result)
    
            
          
            const temp = result.data.data[0].total[0].total
            setTotal(result.data.data[0].total[0].total)
            const temp2 = Math.ceil(temp/15)
            setTotalPage(temp2)
         
    
          }
          else{
            alert('Server Error! No data response received!')
          }
      }
      fetchNews()

  },[])
  useEffect(()=>{

    if(isPost){
      const fetchNews = async()=>{
        const result = await axios.get(`${apiUrl}/merchant/page`,{params:{page:1}})
        console.log(result)
        if(result.data.success){
    
          setMerchant(result.data.data[0].result)
  
          
        
          const temp = result.data.data[0].total[0].total
          setTotal(result.data.data[0].total[0].total)
          const temp2 = Math.ceil(temp/15)
          setTotalPage(temp2)
       
  
        }
        else{
          alert('Server Error! No data response received!')
        }
    }
    fetchNews()
    }
    if(isBus){
      const fetchNews = async()=>{
        const result = await axios.get(`${apiUrl}/card/all`,{params:{page:page}})
        console.log(result)
        if(result.data.success){
    
          setBusiness(result.data.data[0].result)
  
          
        
          const temp = result.data.data[0].total[0].total
          setTotal(result.data.data[0].total[0].total)
          const temp2 = Math.ceil(temp/15)
          setTotalPage(temp2)
       
  
        }
        else{
          alert('Server Error! No data response received!')
        }
    }
    fetchNews()
    }

   

},[page,isPost,isBus])

const handleNextPage = ()=>{
  setPage(page+1)
  const wrap =document.getElementsByClassName('auth-posts-form')[0]
  wrap.scrollTop = 0
}
const handleLastPage = ()=>{
  setPage(page-1)
  const wrap =document.getElementsByClassName('auth-posts-form')[0]
  wrap.scrollTop = 0

}

    useEffect(() => {
        const fetchData = async () => {
          const data = await axios.get(`${apiUrl}/basic/all`)
          // console.log(data);
          if(data.data.success){
            setCards(data.data.data)
        
          }
        }
        fetchData()
    
      },[])
      console.log(cards)

      const handleClickCard = (v) => {
        window.open(`https://meta11w.com/cards/pro/${v.id}`,'_self')

      }
      const handleClickMerchant = (v) => {
        window.open(`https://meta11w.com/merchant/${v.id}`,'_self')

      }

      const handleClickBus =(v)=>{
        window.open(`https://meta11w.com/3dcard/${v.id}`,'_self')

      }

      const handleCard = ()=>{
        setIsCard(true)
        setIsPost(false)
        setIsBus(false)
      }
      const handlePost = ()=>{
        setIsCard(false)
        setIsPost(true)
        setIsBus(false)

      }

      const handleBus = ()=>{
        setIsCard(false)
        setIsPost(false)
        setIsBus(true)
      }


    return (
        <>
         <div className='user-wrap'>
       <Nav/>
       
       <div className='recommand-content'>

               

                 <div className='user-like-check'>
                    <button onClick={()=>handleCard()} style={{backgroundColor:isCard ?'#2aa165':''}}>元名介</button>
                    <button onClick={()=>handlePost()} style={{backgroundColor:isPost ?'#2aa165':''}}>元餐饮</button>
                    <button onClick={()=>handleBus()} style={{backgroundColor:isBus ?'#2aa165':''}}>元片</button>
                 </div>
                 <h4>
                   客服电话<a href="tel:+13015200518">301-520-0518</a>
                 </h4>
                 <h4>客服微信号:122321172</h4>

            <div className='recommand-card'>

                {isCard&&<div className='cards-wrap'>
                    {cards&&cards.map((v,i)=>(

                        (v.isActive&&<div onClick={()=>handleClickCard(v)} key={i} className='cards-item'>
                           
                            <div className='cards-item-right'>
                                <img src={v.images[4]} alt="" />
                            </div>
                            <div className='cards-item-left'>
                                <h5>{v.id}</h5>
                                <h5>{v.title}</h5>
                            </div>
                        </div>)

                    ))}
                </div>}

                {isPost&&<div className='cards-wrap3'>
                {merchant&&merchant.map((v,i)=>(

<div key={i} className='cards-item' onClick={()=>handleClickMerchant (v)}>
<div className='cards-item-right'>
        
          <img style={{transform: index===0?'rotateX(-180deg)':'rotateX(0deg)',transition: '1s',zIndex:index===0?'0':'2'}} src={v.images[4]} alt="" />
            <img style={{transform: index===1?'rotateX(-180deg)':'rotateX(0deg)',transition: '1s',zIndex:index===1?'0':'2'}}  src={v.images[5]} alt="" />
        
    </div>
    <div className='cards-item-left'>
        <h5>{v.title}</h5>
    </div>
</div>
))}
     <div className='pagenation-wrap'>

<div> {page!==1&&<p onClick={()=>handleLastPage()}>上一页</p>}</div>

<div> <p>第{page}页</p> </div>

<div> {page !== totalPage&&<p onClick={()=>handleNextPage()}>下一页</p>}</div>

<div><p>共{totalPage}页</p></div>
<div>
<p>共{total}个结果</p>
</div>
</div>

                </div>}

                {isBus&&<div className='cards-wrap2'>
                    {business&&business.map((v,i)=>(

                    <div key={i} className='cards-item' onClick={()=>handleClickBus(v)}>
                    <div className='cards-item-right'>
                            
                              <img style={{transform: index===0?'rotateX(-180deg)':'rotateX(0deg)',transition: '1s',zIndex:index===0?'0':'2'}} src={v.images[4]} alt="" />
                                <img style={{transform: index===1?'rotateX(-180deg)':'rotateX(0deg)',transition: '1s',zIndex:index===1?'0':'2'}}  src={v.images[5]} alt="" />
                            
                        </div>
                        <div className='cards-item-left'>
                            <h5>{v.title}</h5>
                        </div>
                    </div>
                    ))}
                         <div className='pagenation-wrap'>

<div> {page!==1&&<p onClick={()=>handleLastPage()}>上一页</p>}</div>

<div> <p>第{page}页</p> </div>

<div> {page !== totalPage&&<p onClick={()=>handleNextPage()}>下一页</p>}</div>

<div><p>共{totalPage}页</p></div>
<div>
<p>共{total}个结果</p>
</div>
</div>
     
                </div>}

            </div>
              

           </div>
           <Footer/>
    </div> 
    
        </>
    )
}