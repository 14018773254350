import React,{useState,useEffect,useCallback}from 'react'
import axios from 'axios'
import {Link } from 'react-router-dom'
import Select from 'react-select'

import './index.scss'

export default function AuthCube() {

    const apiUrl ='https://metaserverapp.com'

    const ROTATION = [
        {value:false,label:'横向 3.5:2.0 lily那种 '},
        {value:true,label:'竖向 2.0:3.5 Wingo那种'}
    ]
    const ISLOGO =[
        {value:true,label:'显示左上角logo'},
        {value:false,label:'不显示左上角logo '},
    ]

    const [cards,setCards]=useState([])
   
    const initial = {
        id:'',
        title:'',
        loadImg:'',
        bgImg:'',
        images:[
            '',
            '',
            '',
            '',
            '',
            '',

        ],
        logo:[
            'https://res.cloudinary.com/dvr397xda/image/upload/v1708272529/kangnaixin/%E7%A9%BA%E7%99%BD_pjkwek.png',
            'https://res.cloudinary.com/dvr397xda/image/upload/v1708272529/kangnaixin/%E7%A9%BA%E7%99%BD_pjkwek.png',
            'https://res.cloudinary.com/dvr397xda/image/upload/v1708272529/kangnaixin/%E7%A9%BA%E7%99%BD_pjkwek.png',
            'https://res.cloudinary.com/dvr397xda/image/upload/v1708272529/kangnaixin/%E7%A9%BA%E7%99%BD_pjkwek.png',
            '',
            '',
        ],
        gallery:[],
        frontLink:'',
        leftLink:'',
        backLink:'',
    }

    const isGallerys = [
        { value: '1', label: '1' },
        { value: '2', label: '2' },
        { value: '3', label: '3' },
        { value: '4', label: '4' },
        { value: '5', label: '5' },
        { value: '6', label: '6' },
        { value: '7', label: '7' },
        { value: '8', label: '8' },
        { value: '9', label: '9' },
       
    ]
    const [isDelete,setIsDelete] = useState(false)
    const [isCreate,setIsCreate] = useState(false)
    const [isUpdate,setIsUpdate] =useState(false)
    const [target,setTarget]= useState(initial)


    const [page,setPage] = useState(1)
    const [total,setTotal]=useState(0)
    const [totalPage,setTotalPage] = useState(0)
    const [gallerys,setGallerys] = useState([])


    const handleOpenUpdate=(v)=>{
        setIsUpdate(true)
        setTarget(v)
    }
    const handleCloseUpdate=()=>{
        setIsUpdate(false)
    }

    useEffect(()=>{

        const fetchNews = async()=>{
            const result = await axios.get(`${apiUrl}/cube/all`,{params:{page:1}})
            console.log(result)
            if(result.data.success){
        
                setCards(result.data.data[0].result)
      
              
            
              const temp = result.data.data[0].total[0].total
              setTotal(result.data.data[0].total[0].total)
              const temp2 = Math.ceil(temp/15)
              setTotalPage(temp2)
           
      
            }
            else{
              alert('Server Error! No data response received!')
            }
        }
        fetchNews()

    },[])

    useEffect(()=>{

        const fetchNews = async()=>{
            const result = await axios.get(`${apiUrl}/cube/all`,{params:{page:page}})
            console.log(result)
            if(result.data.success){
        
                setCards(result.data.data[0].result)
              const temp = result.data.data[0].total[0].total
              setTotal(result.data.data[0].total[0].total)
              const temp2 = Math.ceil(temp/15)
              setTotalPage(temp2)
           
            }
            else{
              alert('Server Error! No data response received!')
            }
        }
        fetchNews()

    },[page])

   

    const handleName =(e)=>{
        let temp = {...target}
        temp.id = e.target.value
        setTarget(temp)
    }

    const handleTitle =(e)=>{
        let temp = {...target}
        temp.title = e.target.value
        setTarget(temp)
    }
 
    const handleFront =(e)=>{
        let temp = {...target}
        temp.images[4] = e.target.value
        setTarget(temp)
    }

    const handleBack =(e)=>{
        let temp = {...target}
        temp.images[5] = e.target.value
        setTarget(temp)
    }
    const handleLeft =(e)=>{
        let temp = {...target}
        temp.images[1] = e.target.value
        setTarget(temp)
    }
    const handleRight =(e)=>{
        let temp = {...target}
        temp.images[0] = e.target.value
        setTarget(temp)
    }
    const handleTop =(e)=>{
        let temp = {...target}
        temp.images[2] = e.target.value
        setTarget(temp)
    }
    const handleDown =(e)=>{
        let temp = {...target}
        temp.images[3] = e.target.value
        setTarget(temp)
    }
    

    const handleBg =(e)=>{
        let temp = {...target}
        temp.bgImg = e.target.value
        setTarget(temp)
    }

    const handleLoadImg =(e)=>{
        let temp = {...target}
        temp.loadImg = e.target.value
        setTarget(temp)
    }

    const handleLogo =(e)=>{
        let temp = {...target}
        temp.logo[4] = e.target.value
        setTarget(temp)
    }
    const handleLogo2 =(e)=>{
        let temp = {...target}
        temp.logo[5] = e.target.value
        setTarget(temp)
    }
    const handleFrontLink = (e)=>{
        let temp = {...target}
        temp.frontLink = e.target.value
        setTarget(temp)
    }
    const handleLeftLink = (e)=>{
        let temp = {...target}
        temp.leftLink = e.target.value
        setTarget(temp)
    }
    const handleBackLink = (e)=>{
        let temp = {...target}
        temp.backLink = e.target.value
        setTarget(temp)
    }

    const handleGallery =(e)=>{
        console.log(e);

        let temp ={...target}
        temp.isGallery=e.value
        let arr = [];
        if(e.value){
            for(let i=0;i<e.value;i++){
                arr.push('')
            }
            setGallerys(arr)
        }
        temp.gallery = arr
        setTarget(temp)
     }

     const handleChangeGallery = (e,i)=>{
        console.log(e);

        let temp ={...target}
        temp.gallery[i]=e.target.value
        setTarget(temp)

     }

  

    const handleDelete=()=>{
        setIsDelete(true)
    }
    const handleCancelDelete=()=>{
        setIsDelete(false)
        setTarget(initial)
    }   

    const handleDeleteNews=async(v)=>{
        console.log(v)
        const result = await axios.post(`${apiUrl}/cube/delete`,{cardId:v._id})
        console.log(result)
        if(result.data.success){
            window.location.reload()
        }
        else{
            alert('something went wrong when delete Card!')
        }
    }

    const handleCreateNews = ()=>{
        setIsCreate(!isCreate)
    }


    const handleNextPage = ()=>{
        setPage(page+1)
        const wrap =document.getElementsByClassName('auth-posts-form')[0]
        wrap.scrollTop = 0
      }
      const handleLastPage = ()=>{
        setPage(page-1)
        const wrap =document.getElementsByClassName('auth-posts-form')[0]
        wrap.scrollTop = 0
    
      }

     const handleSubmit = async()=>{
        if(!target.id){
            alert('Missing content')
        }
        else{
            const data = await axios.post(`${apiUrl}/cube`,{
               id:target.id,
               title:target.title,
               
               frontLink:target.frontLink,
               backLink:target.backLink,
               leftLink:target.leftLink,
            loadImg:target.loadImg,
               bgImg:target.bgImg,
               images:target.images,
               gallery:target.gallery,
               logo:target.logo,
            })
            console.log(data)
            if(data.data.success){
                
                alert("创建成功！")
                window.location.reload()
            }
            else{
                alert(data.data.errors)
            }
        }

     }

     const handleUpdateCard= async()=>{
        if(!target._id){
            alert('Missing id')
        }
        else{
            const data = await axios.post(`${apiUrl}/cube/update`,{
                cardId:target._id,
                id:target.id,
                title:target.title,
                
                frontLink:target.frontLink,
                backLink:target.backLink,
                leftLink:target.leftLink,
             loadImg:target.loadImg,
                bgImg:target.bgImg,
                images:target.images,
                gallery:target.gallery,
                logo:target.logo,
            })
            console.log(data)
            if(data.data.success){
                
                alert("创建成功！")
                window.location.reload()
            }
            else{
                alert(data.data.errors)
            }
        }

     }

   
    console.log(target);
  return (
    <div className='auth-post-wrap'>
        <h2>3D名片管理</h2>
        
        <div className='auth-post-btns'>
            <Link to='/auth'> <button >返回</button></Link> 
            <button onClick={()=>handleCreateNews()}> {isCreate ? '取消创建' :' 创建名片'}</button>
        </div>

        <div className='auth-posts-form'>
        <table>
            <thead>
                <tr>
                    <th>
                        名字
                    </th>
                    <th>网址</th>
                    <th>
                        正面
                    </th>
                   
                    <th>
                        背面
                    </th>
                    <th>
                        微信
                    </th>
                    <th>
                        业务
                    </th>
                    
                    <th>
                       电话
                    </th>
                   <th>预览</th>
                   <th>修改</th>
                    <th>
                        删除
                    </th>
                </tr>
            </thead>
            <tbody>
                {
                    cards.length>0&&cards.map((v,i)=>(
                        <tr key={i}>

                            <td>{v.id}</td>
                            <td>https://meta11w.com/3dnews/{v.id}</td>
                            <td><img src={v.images[4]} alt="" /></td>
                            <td><img src={v.images[5]} alt="" /></td>
                            <td>{v.title}</td>
                          

                                <td>
                                    <Link to={`/3dnews/${v.id}`}>
                                    <button >View</button>
                                    </Link>
                                   
                                </td>
                                <td>
                                    <button onClick={()=>handleOpenUpdate(v)}>修改</button>
                                </td>

                            {isDelete ?<td className='post-delete-group'><button onClick={()=>handleDeleteNews(v)}>确定</button><button onClick={()=>handleCancelDelete()}>取消</button></td> :<td><button onClick={()=>handleDelete()}>删除</button></td>}
                        </tr>
                    )

                    )
                }
            </tbody>


        </table>


        </div>

        <div className='pagenation-wrap'>

<div> {page!==1&&<p onClick={()=>handleLastPage()}>上一页</p>}</div>

<div> <p>第{page}页</p> </div>

<div> {page !== totalPage&&<p onClick={()=>handleNextPage()}>下一页</p>}</div>

<div><p>共{totalPage}页</p></div>
<div>
<p>共{total}个结果</p>
</div>
</div>
     

     
     <div style={{top:isCreate? '12vh':'220vh',transition:'1s'}} className='auth-product2-create'>

    <div className='news-wrap'>

        <div>
        <h4>名字</h4>
    <input type="text" value={target.id} onChange={(e)=>handleName(e)} />
        </div>
        
        <div>
        <h4>标题</h4>
        <input type="text" value={target.title} onChange={(e)=>handleTitle(e)} />
        </div>



        <div>
        <h4>正面图</h4>
        <input type="text" value={target.images[4]} onChange={(e)=>handleFront(e)}/>
        </div>

        <div>
        <h4>背面图</h4>
        <input type="text" value={target.images[5]} onChange={(e)=>handleBack(e)}/>
        </div>

        <div>
        <h4>左面图</h4>
        <input type="text" value={target.images[1]} onChange={(e)=>handleLeft(e)}/>
        </div>

        <div>
        <h4>右面图</h4>
        <input type="text" value={target.images[0]} onChange={(e)=>handleRight(e)}/>
        </div>
        <div>
        <h4>上面图</h4>
        <input type="text" value={target.images[2]} onChange={(e)=>handleTop(e)}/>
        </div>
        <div>
        <h4>下面图</h4>
        <input type="text" value={target.images[3]} onChange={(e)=>handleDown(e)}/>
        </div>

       
        <div>
        <h4>Logo</h4>
        <input type="text" value={target.logo[4]} onChange={(e)=>handleLogo(e)}/>
        </div>
        <div>
        <h4>Logo2</h4>
        <input type="text" value={target.logo[5]} onChange={(e)=>handleLogo2(e)}/>
        </div>

        <div>
        <h4>背景图</h4>
        <input type="text" value={target.bgImage} onChange={(e)=>handleBg(e)}/>
        </div>

        <div>
        <h4>加载图</h4>
        <input type="text" value={target.loadImg} onChange={(e)=>handleLoadImg(e)}/>
        </div>

        <div>
        <h4>正面链接</h4>
        <input type="text" value={target.frontLink} onChange={(e)=>handleFrontLink(e)}/>
        </div>

        <div>
        <h4>左面链接</h4>
        <input type="text" value={target.leftLink} onChange={(e)=>handleLeftLink(e)}/>
        </div>

        <div>
        <h4>背面链接</h4>
        <input type="text" value={target.backLink} onChange={(e)=>handleBackLink(e)}/>
        </div>


        <div className="create-input-wrap">
        <label>轮播图(右面)*</label>
        <Select    
         onChange={(e)=>handleGallery(e)}
         options={isGallerys}             
           menuPortalTarget={document.body}
        menuPosition="fixed"       maxMenuHeight='30vh'           
        />
    </div>
    {
        gallerys.map((v,i)=>(
            <div key={i} className="create-input-wrap">
            <label>轮播图{i+1}</label>
            <input value={target.gallery[i]} onChange={(e)=>handleChangeGallery(e,i)} type="text" />
        </div>
        ))
    }
       
    </div>

    <button onClick={()=>handleSubmit()}>Submit</button>

     </div>

     <div style={{top:isUpdate? '12vh':'220vh',transition:'1s'}} className='auth-product2-create'>

<div className='news-wrap'>

            <button onClick={()=>handleCloseUpdate()} id='cancel-update'>取消</button>
    <div>
    <h4>名字</h4>
<input type="text" value={target.id} onChange={(e)=>handleName(e)} />
    </div>
    
    <div>
    <h4>业务</h4>
    <input type="text" value={target.title} onChange={(e)=>handleTitle(e)} />
    </div>

   

    

   

   

    

    <div>
    <h4>正面图</h4>
    <input type="text" value={target.images[4]} onChange={(e)=>handleFront(e)}/>
    </div>

    <div>
    <h4>背面图</h4>
    <input type="text" value={target.images[5]} onChange={(e)=>handleBack(e)}/>
    </div>

    <div>
    <h4>背景图</h4>
    <input type="text" value={target.bgImage} onChange={(e)=>handleBg(e)}/>
    </div>
   

   
</div>

<button onClick={()=>handleUpdateCard()}>更新</button>

 </div>


        
        
    </div>
  )
}

