import { useRef } from 'react'
import { Canvas, useFrame,extend,useThree,useLoader, } from '@react-three/fiber'
import { useGLTF, PresentationControls, Environment, ContactShadows, Html,useFBX ,useTexture} from '@react-three/drei'
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import './index.scss'

extend({ OrbitControls });

const CameraControls = () => {

  const {
    camera,
    gl: { domElement },
  } = useThree();
  const controls = useRef();
  useFrame(() => controls.current.update());
  return (
    <orbitControls
      ref={controls}
      args={[camera, domElement]}
      autoRotate={false}
      enableZoom={true}
      maxDistance={180}
      minDistance={120}
    />
  );
};

export default function Cloth() {
  return (
    <div className="container-card">
    <Canvas gl={{ alpha: true, antialias: true}}  className='canvas-wrap'  dpr={[1, 2]} >
            <CameraControls />

      <ambientLight intensity={1} />
      <spotLight position={[10, 10, 10]} angle={0.15} penumbra={1} shadow-mapSize={[512, 512]} castShadow />
     
     
    <Tshirt rotation={[0, 0, 0]} position={[0, 0.25, 0]} scale={1} />
      <ContactShadows rotation-x={Math.PI / 2} position={[0, -1.4, 0]} opacity={0.75} width={10} height={10} blur={2.6} far={2} />
      <Environment preset="city" />
    </Canvas>
    </div>
  )
}

const Tshirt = ()=>{
    const texture = useTexture('https://res.cloudinary.com/dvr397xda/image/upload/v1655745931/cube/AM_102_035_map_001_bump_wjlsga.jpg')


    let fbx = useFBX('https://res.cloudinary.com/dvr397xda/raw/upload/v1655741484/cube/shirt_qp6gsj.fbx')
    return <primitive object={fbx}/>
}



