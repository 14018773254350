import React from 'react'

export default function Contact() {
  return (
    <div className='designer-contact'>

      <h2>客服电话:3015200518</h2>
      <img width='250px' src="https://res.cloudinary.com/dvr397xda/image/upload/v1665008070/WechatIMG11917_qkgsnk.jpg" alt="" />
    </div>
  )
}
