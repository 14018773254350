import React,{useState,useEffect,useRef,Suspense}from 'react'
import { Canvas, useFrame,extend,useThree,useLoader,} from '@react-three/fiber'
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { TextureLoader } from 'three/src/loaders/TextureLoader.js';
import * as THREE from "three";

import {useParams} from 'react-router-dom'
import Env from '../../components/Environment/index'
import fetchData from '../../data'
import './index.scss'




const  Cube = ()=>{

  const image = 'https://res.cloudinary.com/dvr397xda/image/upload/v1653324529/cube/%E8%BE%B9%E6%A1%862_b3lfk1.png'
  const mesh = useRef()
  const map = useLoader(THREE.TextureLoader,image)
  useFrame((state, delta) => {
    mesh.current.scale.x = 1 + (1 + Math.sin(state.clock.elapsedTime * 1)) * 0.03
    mesh.current.scale.y = 1 + (1 + Math.sin(state.clock.elapsedTime * 1)) * 0.03
    mesh.current.scale.z = 1 + (1 + Math.sin(state.clock.elapsedTime * 1)) * 0.03
  })
  return (
    <>
    <mesh ref={mesh}>
        <boxBufferGeometry  attach="geometry"  args={[2.2, 2.2, 2.2]}/>
        <meshStandardMaterial transparent={true} opacity={1}  attach="material" map={map}  roughness={0.7}
        metalness={0.1} />

       


    </mesh>
    </>
  )
}

const Hide = ({ handleClickHide1})=>{
  const image = 'https://res.cloudinary.com/dvr397xda/image/upload/v1650920511/cube/%E7%8E%BB%E7%92%832_ukdjz2.jpg'
  const mesh1 = useRef()
  const map = useLoader(TextureLoader,image)

  return (
    <>
    <mesh position={[-1.3,0,0]} onClick={(e)=>handleClickHide1(e)}  ref={mesh1}>
        <boxBufferGeometry  attach="geometry"  args={[0.1,2,2]}/>
        <meshStandardMaterial transparent={true} opacity={0}  attach="material" map={map}  roughness={0.7}
        metalness={0.1} />

       


    </mesh>
    </>
  )
}

const Hide2 = ({ handleClickHide2})=>{
  const image = 'https://res.cloudinary.com/dvr397xda/image/upload/v1650920511/cube/%E7%8E%BB%E7%92%832_ukdjz2.jpg'
  const mesh2 = useRef()
  const map = useLoader(TextureLoader,image)

  return (
    <>
    <mesh  position={[1.3,0,0]} onClick={(e)=>handleClickHide2(e)}  ref={mesh2}>
        <boxBufferGeometry  attach="geometry"  args={[0.1,2,2]}/>
        <meshStandardMaterial transparent={true} opacity={0}  attach="material" map={map}  roughness={0.7}
        metalness={0.1} />

       


    </mesh>
    </>
  )
}

const Hide3 = ({ handleClickHide3})=>{
  const image = 'https://res.cloudinary.com/dvr397xda/image/upload/v1650920511/cube/%E7%8E%BB%E7%92%832_ukdjz2.jpg'
  const mesh3 = useRef()
  const map = useLoader(TextureLoader,image)

  return (
    <>
    <mesh position={[0,0,-1.3]} rotation={[0, Math.PI * 2.5, 0]} onClick={(e)=>handleClickHide3(e)}  ref={mesh3}>
        <boxBufferGeometry  attach="geometry"  args={[0.1,2,2]}/>
        <meshStandardMaterial transparent={true} opacity={0}  attach="material" map={map}  roughness={0.7}
        metalness={0.1} />

       


    </mesh>
    </>
  )
}

// const Marker = ({people,type,scale})=>{
//   const [occluded, occlude] = useState()
//   return (
//     <Html
//       // 3D-transform contents
//       transform
//       // Hide contents "behind" other meshes
//       occlude
//       // Tells us when contents are occluded (or not)
//       onOcclude={occlude}
//       // We just interpolate the visible state into css opacity and transforms
//       style={{ transition: 'all 0.2s', opacity: occluded ? 0 : 1, transform: `scale(${occluded ? 0.25 : scale? scale:0.8})` }}
//       >
//         {
//           type==='tel'&&<a href={`tel:+1${people.tel}`} style={{ position: 'absolute',fontSize: 8, letterSpacing: -0.2, }}>tel:{people.tel}</a>
//         }
//         {
//           type==='email'&&<a href={`mailto:${people.email}`} style={{ position: 'absolute',fontSize: 8, letterSpacing: -0.2, 
//         }}>email</a>
//         }
//         {
//           type==='map'&&<a href={people.map} style={{ position: 'absolute',fontSize: 8, letterSpacing: -0.2, }}>address</a>
//         }


//         {
//          type==='web'&&<a href={people.website.url} style={{ position: 'absolute',fontSize: 8, letterSpacing: -0.2, }}>{people.website.name}</a>
//         }
//         {
//          type==='web2'&&<a href={people.website2.url} style={{ position: 'absolute',fontSize: 8, letterSpacing: -0.2, }}>{people.website2.name}</a>
//         }
//         {
//          type==='web3'&&<a href={people.website3.url} style={{ position: 'absolute',fontSize: 8, letterSpacing: -0.2, }}>{people.website3.name}</a>
//         }
//         {
//          type==='web4'&&<a href={people.website4.url} style={{ position: 'absolute',fontSize: 8, letterSpacing: -0.2, }}>{people.website4.name}</a>
//         }
//         {
//          type==='video'&&<a href={people.video} style={{ position: 'absolute',fontSize: 10, letterSpacing: -0.2, }}>观看视频</a>
//         }
//         {
//           type==='name'&&<a style={{ position: 'absolute',fontSize: 8, letterSpacing: -0.2, }}>{people.name}</a>
//         }
//         {
//           type ==='title'&&<a style={{ position: 'absolute',fontSize: 8, letterSpacing: -0.2, }}>{people.title}</a>
//         }
//     </Html>
//   )
// }

function Box(props) {
  // This reference will give us direct access to the mesh
  const {people,handleClickHide1,handleClickHide2,handleClickHide3} = props
  // const [occluded, occlude] = useState()
  // const [occluded2, occlude2] = useState()
 

  const mesh = useRef()
  // Set up state for the hovered and active state
  
 
  // Subscribe this component to the render-loop, rotate the mesh every frame
  // useFrame((state, delta) => (mesh.current.rotation.y += 0.001))
  // Return view, these are regular three.js elements expressed in JSX
  let textures;
textures = useLoader(THREE.TextureLoader,[people.images[0],people.images[1],people.images[2],people.images[3],people.images[4],people.images[5]])



 
  function Map (){

    return(
      
      textures.map((texture, idx) =>(
        idx ===2||idx===3?<meshBasicMaterial key={idx} attach={`material-${idx}`} map={texture} transparent={true} opacity={0.2} />
        :<meshBasicMaterial key={idx} attach={`material-${idx}`} map={texture}  />
        )
)
    )
      
   
  } 




  

  // const loader = new CubeTextureLoader();

  // const texture = loader.load(people.people.images);

  // const [texture1, texture2, texture3, texture4, texture5, texture6] = useLoader(ImageLoader, people.people.images)
 
  return (
    <>
    <mesh
      onClick={(e)=>{
        e.stopPropagation()
      }}
      ref={mesh}
    //   scale={active ? 1.2 : 1}
    
     // see note 1
     
      >
          
      <boxBufferGeometry attach="geometry"  args={[2, 2, 2]}/>
        <Map/>
        






{/*      
        <group position={[1.2, 1.1, 0]} rotation={[0, Math.PI * 2.5, 0]}>
        <Marker people={people.people} type={'web'} />
        </group>
        {
          people.people.website2&&<group position={[1.2, 0.6, 0]} rotation={[0, Math.PI * 2.5, 0]}>
          <Marker people={people.people} type={'web2'} />
          </group>
        }
         {
          people.people.website3&&<group position={[1.2, 0.1, 0]} rotation={[0, Math.PI * 2.5, 0]}>
          <Marker people={people.people} type={'web3'} />
          </group>
        }
        {
          people.people.website4&&<group position={[1.2, -0.5, 0]} rotation={[0, Math.PI * 2.5, 0]}>
          <Marker people={people.people} type={'web4'} />
          </group>
        }

        {
          people.people.video&&<group position={[0, -0.4, -1.2]} rotation={[Math.PI * 4, Math.PI * 3, 0]}>
          <Marker people={people.people} type={'video'} />
          </group>
        } */}


        {/* <group position={[-1.2, 0, 0]} rotation={[0, Math.PI * 1.5, 0]}>
        <Html  transform
   occlude2
   onOcclude={occlude2}
   style={{ transition: 'all 0.2s', opacity: occluded2 ? 0 : 1, transform: `scale(${occluded2 ? 0.25 : 0.8})` }}>
                 <a  href={`tel:+1${people.people.tel}`} style={{ position: 'absolute',fontSize: 10, letterSpacing: -0.2, }}>tel:{people.people.tel}</a>

   </Html>
        </group> */}

        {/* <group position={[-1.2, -0.3, 0]} rotation={[0, Math.PI * 1.5, 0]}>
        <Html  transform
   occluded2
   onOcclude={occlude2}
   style={{ transition: 'all 0.2s', opacity: occluded2 ? 0 : 1, transform: `scale(${occluded2 ? 0.25 : 0.45})` }}>
                 <a  href={`mailto:${people.people.email}`} style={{ position: 'absolute',fontSize: 10, letterSpacing: -0.2, }}>email</a>

   </Html>
        </group> */}

        {/* <group position={[-1.2, -0.5, 0]} rotation={[0, Math.PI * 1.5, 0]}>
        <Html  transform
   occlude2
   onOcclude={occlude2}
   style={{ transition: 'all 0.2s', opacity: occluded2 ? 0 : 1, transform: `scale(${occluded2 ? 0.25 : 0.45})` }}>
                 <a  href={people.people.map} style={{ position: 'absolute',fontSize: 10, letterSpacing: -0.2,width:'150x' }}>address</a>

   </Html>
        </group> */}

       {/* <group position={[-1.2, 1, 0]} rotation={[0, Math.PI * 1.5, 0]}>
        <Marker people={people.people} type={'name'} />
        </group>

        {people.people.title&&<group position={[-1.2, 0.8, 0]} rotation={[0, Math.PI * 1.5, 0]}>
        <Marker people={people.people} type={'title'} scale={0.6}/>
        </group>}

        <group position={[-1.2, 0.4, 0]} rotation={[0, Math.PI * 1.5, 0]}>
        <Marker people={people.people} type={'tel'} />
        </group>

        <group position={[-1.2, 0, 0]} rotation={[0, Math.PI * 1.5, 0]}>
        <Marker people={people.people} type={'email'}  />

        </group>

        <group position={[-1.2, -0.4, 0]} rotation={[0, Math.PI * 1.5, 0]}>
        <Marker people={people.people} type={'map'} />

        </group> */}
        
    </mesh>

   
    <Hide handleClickHide1={handleClickHide1}  />

<Hide2 handleClickHide2={handleClickHide2}  />

<Hide3  handleClickHide3={handleClickHide3}  />
    </>
  )
}

extend({ OrbitControls });

const CameraControls = () => {
  // Get a reference to the Three.js Camera, and the canvas html element.
  // We need these to setup the OrbitControls class.
  // https://threejs.org/docs/#examples/en/controls/OrbitControls

  const {
    camera,
    gl: { domElement },
  } = useThree();

  // Ref to the controls, so that we can update them on every frame with useFrame
  const controls = useRef();
  useFrame(() => controls.current.update());
  return (
    <orbitControls
      ref={controls}
      args={[camera, domElement]}
      autoRotate={false}
      enableZoom={true}
      maxDistance={5.5}
      minDistance={2}
    />
  );
};




export default function Card() {
  const{ name } = useParams();
  const [people,setPeople] = useState({})
  const [background,setBackground] = useState('')
  const [left,setLeft] = useState(false)
  const [right,setRight] = useState(false)
  const [back,setBack] = useState(false)
  
  useEffect(() => {
      const data = fetchData()

      const result = data.find(e=>e.id===name)
      setPeople(result)
      
      setBackground(result.background)
    },[name])

    const handleClickHide1=(e)=>{
      setLeft(true)
      e.stopPropagation()
      setRight(false)
      setBack(false)
    }
    
    const handleClickHide2=(e)=>{
      e.stopPropagation()
      setRight(true)
      setLeft(false)
      setBack(false)
    }
    
    const handleClickHide3=(e)=>{
      e.stopPropagation()
      setBack(true)
      setLeft(false)
      setRight(false)
    }
    
    const handleClosePop = ()=>{
      setLeft(false)
      setRight(false)
      setBack(false)
    }

    // console.log(`left:${left},right;${right},back:${back}`)

  return (
    <div className="container-card">
     
    <Canvas dpr={Math.max(window.devicePixelRatio, 1) }  className='canvas-wrap'>
    <CameraControls />
    <Env background={background}/>
    {/* <Environment files={background} /> */}
      <ambientLight intensity={1}  position={[0,0,0]}/>
      <pointLight  position={[0,0,0]} />
      <Suspense fallback={null}>

      <Box people={people} handleClickHide1={handleClickHide1} handleClickHide2={handleClickHide2} handleClickHide3={handleClickHide3}  position={[0, 0, 0]} />
      <Cube position={[0, 0, 0]} />

      </Suspense>
    
    </Canvas>

      <div id="pop-left" style={{ top:left? '20vh' : '130vh'}}>
                <h2>
                  Name: {people.name}
                </h2>
                {people.title&&<h2>
                  Title: {people.title}
                </h2>}
               { people.tel&&<h2>
                  <a href={`tel:+1${people.tel}`}>Phone: {people.tel}</a>
                </h2>}
                {people.email&&<h2>
                  <a href={`mailto:${people.email}`}>Email: {people.email}</a>
                </h2>}
                {people.address&&<h2>
                  <a href={people.map}>Address: {people.address}</a>
                </h2>}
                <img id='close' onClick={()=>handleClosePop()} src="https://res.cloudinary.com/dvr397xda/image/upload/v1654531781/cube/cancel_h8fp3d.png" alt="" />
      </div>

     { people.name&&<div id="pop-right" style={{ top:right? '20vh' : '130vh'}}>
               
                {people.website.name&&<h2>
                  <a href={people.website.url}>Website: {people.website.name}</a>
                </h2>}
               { people.website2.name&&<h2>
                  <a href={people.website2.url}>Website2: {people.website2.name}</a>
                </h2>}
                { people.website3.name&&<h2>
                  <a href={people.website3.url}>Website3: {people.website3.name}</a>
                </h2>}
                { people.website4.name&&<h2>
                  <a href={people.website4.url}>Website4: {people.website4.name}</a>
                </h2>}
                
               
                <img id='close' onClick={()=>handleClosePop()} src="https://res.cloudinary.com/dvr397xda/image/upload/v1654531781/cube/cancel_h8fp3d.png" alt="" />
      </div>}

      <div id="pop-back" style={{ top:back? '20vh' : '130vh'}}>
               
               
                  <iframe title={people.name} src={people.video} frameBorder="0"></iframe>
               
              
               <img id='close' onClick={()=>handleClosePop()} src="https://res.cloudinary.com/dvr397xda/image/upload/v1654531781/cube/cancel_h8fp3d.png" alt="" />
     </div>


    </div>

  )
}
