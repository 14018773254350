import React,{useState,useEffect,useRef,Suspense,useCallback}from 'react'
import { Canvas, useFrame,extend,useThree,useLoader,} from '@react-three/fiber'
import * as THREE from "three";
import HTMLFlipBook from "react-pageflip";
import {useParams} from 'react-router-dom'
import axios from 'axios';

import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";

import fetchRestaurant from '../../restaurant2'
import {useGLTF,useFBX,useProgress,Html} from '@react-three/drei'

import './index.scss'

function Loader() {
  const { progress } = useProgress()
  return <Html center> <h2 id='loadText'>
        Loading ...{progress.toFixed(0)}%
        <img id='loadImage' src="https://res.cloudinary.com/dvr397xda/image/upload/v1659727126/cube/Happy%20Town/chop_kfqgd0.gif" alt="" />
        </h2></Html>
}

const  Model=({people})=>{
    const mesh = useRef()
    const defaultMap ='https://res.cloudinary.com/dvr397xda/image/upload/v1659113641/cube/basic/yuxin/Mew_Diff_tg9hgf.png'
    let key = useFBX(people.model.url)

  
    const [colorMap, metalMap, normalMap, roughnessMap, aoMap] = useLoader(THREE.TextureLoader, [
        people.model.colorMap||defaultMap,
        people.model.metalMap||defaultMap,
        people.model.normalMap||defaultMap,
        people.model.roughnessMap||defaultMap,
        people.model.aoMap||defaultMap,
      ])
    


    key.traverse( function ( child ) {

        if ( child.isMesh ) {
            console.log(child);
            if(people.model.colorMap)
            child.material.map = colorMap;
            if(people.model.aoMap)
            child.material.aoMap =aoMap;
            if(people.model.normalMap)
            child.material.normalMap = normalMap;
            if(people.model.metalMap)
            child.material.metalMap = metalMap; 
            if(people.model.roughnessMap)
            child.material.roughnessMap = roughnessMap; 
          }
      
      } );
      useFrame((state, delta) => {
      
    
        mesh.current.rotation.y += 0.005
      
      })

      return (
        <mesh scale={people.model.scale} position={people.model.position} rotation={people.model.rotation}  ref={mesh}>
            <primitive   object={key} />
    
        </mesh>
      )

}









function Box(props) {
  const {people,position} = props
  const mesh = useRef()
  const [index,setIndex] = useState(0)

 

 
  // setInterval(handleChangeIndex,1000)

  let textures;

textures = useLoader(THREE.TextureLoader,[people.images[0],people.images[1],people.images[2],people.images[3],people.images[4],people.images[5]])
// textures = useLoader(TextureLoader,[people.images[0],people.images[1],people.images[2],people.images[3],people.images[4],people.images[5]])

textures.minFilter = textures.magFilter = THREE.LinearFilter;



textures.format= THREE.RGBAFormat;




  function Map (){
    return(
    
      textures.map((texture, idx) =>(
        idx ===2||idx===3 || idx===0?<meshBasicMaterial key={idx} attach={`material-${idx}`} map={texture}   transparent={true} opacity={1}/>
        :<meshBasicMaterial opacity={1} transparent={true}  key={idx} attach={`material-${idx}`} map={texture}  />
        )
)
    )
  } 

  return (
    <>
    <mesh
      onClick={(e)=>{
        e.stopPropagation()
      }}
      ref={mesh}
     position={position}
     
      >
          
      <boxBufferGeometry attach="geometry"  args={[2.5, 2.5, 2.5]}/>
        <Map/>
       
      

    </mesh>


    </>
  )
}



extend({ OrbitControls });

const CameraControls = () => {

  const {
    camera,
    gl: { domElement },
  } = useThree();
  const controls = useRef();
  useFrame(() => controls.current.update());
  return (
    <orbitControls
      ref={controls}
      args={[camera, domElement]}
      autoRotate={false}
      enableZoom={true}
      maxDistance={5.5}
      minDistance={2}
    />
  );
};

const Phone = ({people,opacity,posY})=>{

  const mesh1 = useRef()


  const handleClick=()=>{
    window.open(`tel:+1${people.tel}`,'_self')
  }


  return (
    <>
    <mesh position={[-0.8,posY-1.65,1.3]} rotation={[0,Math.PI * 2.5,0]} onClick={(e)=>{ handleClick();     e.stopPropagation()
}}   ref={mesh1}>
        <boxBufferGeometry  attach="geometry"  args={[0.1,1.1,0.8]}/>
        <meshStandardMaterial transparent={true} opacity={opacity}   color='green'  />

       


    </mesh>
    </>
  )
}

const Cell = ({people,opacity,posY})=>{
   
    const mesh1 = useRef()
 
  
    const handleClick=()=>{
      window.open(`tel:+1${people.cell}`,'_self')
    }
  
  
    return (
      <>
      <mesh position={[0,posY-1.9,1.3]} rotation={[0,Math.PI * 2.5,0]} onClick={(e)=>{ handleClick();     e.stopPropagation()
  }}   ref={mesh1}>
          <boxBufferGeometry  attach="geometry"  args={[0.1,0.6,2.5]}/>
          <meshStandardMaterial transparent={true} opacity={opacity}  color='red' />
  
         
  
  
      </mesh>
      </>
    )
  }

const Email = ({people,opacity,posY})=>{

  const mesh1 = useRef()
 

  const handleClick=()=>{
 
    window.open(`mailto:+${people.email}`,'_self')
  }

  return (
    <>
    <mesh position={[0,posY-1.8,1.3]} rotation={[0,Math.PI * 2.5,0]} onClick={(e)=>{ handleClick();     e.stopPropagation()
}}   ref={mesh1}>
        <boxBufferGeometry  attach="geometry"  args={[0.1,0.8,0.8]}/>
        <meshStandardMaterial transparent={true} opacity={opacity}  color='#fff' />

       


    </mesh>
    </>
  )
}

const Map = ({people,opacity,posY})=>{
 
  const mesh1 = useRef()


  const handleClick=()=>{

    window.open(`${people.map}`,'_self')
  }

  return (
    <>
    <mesh rotation={[0,Math.PI * 2.5,0]} position={[0,-1.65+posY,1.3]} onClick={(e)=>{ handleClick();     e.stopPropagation()
}}   ref={mesh1}>
        <boxBufferGeometry  attach="geometry"  args={[0.1,1.1,0.8]}/>
        <meshStandardMaterial transparent={true} opacity={opacity}   color='blue'   />

       


    </mesh>
    </>
  )
}

const Youtube = ({people,opacity,posY})=>{

    const mesh1 = useRef()
   
  
    const handleClick=()=>{
  
      window.open(`${people.video}`,'_self')
    }
  
    return (
      <>
      <mesh position={[-1.4,-1+posY,0.6]} onClick={(e)=>{ handleClick();     e.stopPropagation()
  }}   ref={mesh1}>
          <boxBufferGeometry  attach="geometry"  args={[0.1,0.7,1.2]}/>
          <meshStandardMaterial transparent={true} opacity={opacity}  attach="material" color='green'  roughness={0.7}
          metalness={0.1} />
  
         
  
  
      </mesh>
      </>
    )
  }

  const QR = ({opacity,posY,handleOpenQr})=>{
  
    const mesh1 = useRef()
  
  
  
    return (
      <>
      <mesh rotation={[0, Math.PI * 2.5, 0]} position={[0,-1.6+posY,-1.3]} onClick={(e)=>{  handleOpenQr();     e.stopPropagation()
  }}   ref={mesh1}>
          <boxBufferGeometry  attach="geometry"  args={[0.1,1.3,2.5]}/>
          <meshStandardMaterial transparent={true} opacity={opacity}   color='green'   />
  
         
  
  
      </mesh>
      </>
    )
  }



const Web = ({people,opacity,posY})=>{
 
  const mesh1 = useRef()

  const handleClick=()=>{
    window.open(`${people.website}`,'_self')
  }

  return (
    <>
    <mesh rotation={[0, Math.PI * 2.5, 0]} position={[0.8,-1.65+posY,1.3]} onClick={(e)=>{ handleClick();     e.stopPropagation()
}}   ref={mesh1}>
        <boxBufferGeometry  attach="geometry"  args={[0.1,1.1,0.8]}/>
        <meshStandardMaterial transparent={true} opacity={opacity}   color='#fff'   />

       


    </mesh>
    </>
  )
}




export default function Restaurant2() {
    const{ name } = useParams();
    const CENTTODOLLAR = 100;

    const mesh2 = useRef()
    const [people,setPeople]=useState({})
    const [currentMenuIndex,setCurrentMenuIndex]= useState(0)
    const [currentCartgory,setCurrentCartgory]= useState(0)
    const [menu,setMenu] = useState([])
    const [cartgory,setCartory] = useState([])
    const [order,setOrder] = useState([])
    const [total,setTotal] = useState(0)
    const [name1,setName]=useState('')
    const [phone,setPhone] = useState('')

   useEffect(()=>{

    const data = fetchRestaurant();
    const result = data.filter(e=>e.id===name)

    setPeople(result[0])
    setMenu(result[0].menuList)
    setCartory(result[0].menuList[0].data[0].data)
   },[])



  const [isQR,setIsQR] = useState(true)
  const [isCart,setIsCart] = useState(false)

  const opacity=0
  const posY = 1



  const handleOpenQr = ()=>{
      setIsQR(true)
  }

  const handleName=(e)=>{
    console.log(e.target.value)
    setName(e.target.value)
  }

 const handlePhone=(e)=>{
    console.log(e.target.value)
    setPhone(e.target.value)
  }


  
  const handleCloseTeam = ()=>{
    setIsQR(false)
  }
  const handleCloseCart  = ()=>{
    setIsCart(false)
  }
  const handleOpenCart = ()=>{
    setIsCart(!isCart)
  }

  const handleAddCart=(v,n)=>{
    if(n){
  
      const temp ={
        name:v.name,
        english:v.english,
        spicy:v.spicy,
        price:(v.price[n-1].price),
        size:v.price[n-1].name
      }
      const arr = [...order];
      arr.push(temp)
      let value = 0;
      arr.forEach(v=>value +=Number(v.price))
      setTotal(value)
      setOrder(arr)
    }
    else{
   
      let temp =v
      temp.size=''
      const arr = [...order];
      arr.push(temp)
      let value = 0;
      arr.forEach(v=>value +=Number(v.price))
      setTotal(value)
      setOrder(arr)
    }
   
  }

  const handleRemove=(i)=>{
    let temp = [...order]
    temp.splice(i,1)
    let value = 0;
      temp.forEach(v=>value +=Number(v.price))
      setTotal(value)
    setOrder(temp)
  }

  const handleChangeMenuIndex=(i)=>{
    setCurrentMenuIndex(i)
    setCurrentCartgory(0)
    setCartory(people.menuList[i].data[0].data)
  }
  const handleChangeCartgoryIndex=(i)=>{
    setCurrentCartgory(i)
    setCartory(menu[currentMenuIndex].data[i].data)
  }

  const handleSubmit =()=>{
    if(!name1) alert('Please Leave Your Name')
    if(!phone) alert('Please Leave Your Phone')
      console.log('submit')
  }



  return (
    <div className="container-card" style={{backgroundImage:`url(${people.gif})`}}  >
     
     <Canvas  gl={{ alpha: true, antialias: true}}  className='canvas-wrap'>
    <CameraControls />
    {/* <ambientLight intensity={0.8} /> */}
 <pointLight position={[3, 3, 3]} intensity={1} color='#f0f0f0'/>
        <pointLight position={[3, -3, 3]} intensity={1} color='#f0f0f0'/>

        <pointLight position={[-3, 3, 3]} intensity={1} color='#f0f0f0'/>

        <pointLight position={[3, 3, -3]} intensity={1} color='#f0f0f0'/>
        <spotLight  position={[-3, -3, -3]} intensity={1} color='#f0f0f0'/>

      <Suspense fallback={<Loader/>}>
   

        
      <Box people={people}   position={[0, 0, 0]} />
    
        {/* <Model people={people}/> */}
      {/* <Cube position={[0, 0, 0]} /> */}

      <Phone posY={posY} people={people} opacity={opacity}/>
      {people.cell&&<Cell posY={posY} people={people} opacity={opacity}/>}

     { people.email&&<Email posY={posY} people={people} opacity={opacity}/>}
      {people.menu&&<QR handleOpenQr={handleOpenQr} posY={posY} people={people} opacity={opacity}/>}
      <Map posY={posY} people={people} opacity={opacity}/>
      {people.website&&<Web posY={posY} people={people} opacity={opacity}/>}
     { people.video&&<Youtube posY={posY} people={people} opacity={opacity}/>}
     {/* {people.menu&&<Menu posY={posY} people={people} opacity={opacity}/>} */}

      {/* <Linkedin posY={posY} people={people} opacity={opacity}/> */}
      {/* <VideoBtn people={people} opacity={opacity}/> */}

      {/* <mesh rotation={[0,0,0]} position={[-1.4,-1.8+posY,-0.6]} onClick={(e)=>{ handleClickTeam();     e.stopPropagation()
}}   ref={mesh2}>
        <boxBufferGeometry  attach="geometry"  args={[0.1,0.7,1.2]}/>
        <meshStandardMaterial transparent={true} opacity={opacity}    color='red'   />

       
    </mesh> */}
    
    </Suspense>

    </Canvas>
   
         

          {/* <div style={load ?{ zIndex:'100000',opacity:'1'} :{zIndex:'-10',opacity:'0',transition:'3.5s' }} className='load-image'>
                <img src={people.loadImg} alt="" />
          </div> */}

          <div style={isQR ? {top:'20vh',transition:'1.5s'}:{top:'200vh',transition:'1.5s'}}  className="wrapper5">
         {/* <img id='qr' src={people.menu} alt="" />
          <a href={`tel:+1${people.tel}`}>

          <button>
            <h3>
            Booking Now
            </h3>
            </button>
          </a> */}
          <div className="main-menu">
            {
              menu&&menu.map((v,i)=>(
                <button onClick={()=>handleChangeMenuIndex(i)} key={i} className={i===currentMenuIndex? 'click-btn' : 'default-btn'}>
                  {v.name}
                </button>
              ))
            }
          </div>
          <hr />
          <div className='overflow-menu'>
          <div className='cartgory-menu'>
            {
              menu[currentMenuIndex]&&menu[currentMenuIndex].data.map((v,i)=>(
                <button onClick={()=>handleChangeCartgoryIndex(i)} key={i} className={i===currentCartgory? 'click-btn' : 'default-btn'}>
                {v.name}
              </button>
              ))
            }
          </div>
          </div>
          <hr />
          
          <div className='dish-container'>
           {
             cartgory&&cartgory.map((v,i)=>(
               <div className='dish-item' key={i}>

                 <div className='dish-title'>
                 <h6>{v.name} 	{v.spicy ? '🌶 ':''}</h6>
                  <h6>{v.english}</h6>
                 </div>

                <div className='price-container'>
                {
                  v.multi ? <div className='multi'>
                        {
                          v.price&&v.price.map((m,n)=>(
                            <div key={n}>
                              <h6>{m.name}</h6>
                            <h6>
                              {`$${m.price}`}
                            </h6>
                            <button onClick={()=>handleAddCart(v,n+1)}>Add</button>
                            </div>
                           
                          ))
                        }
                  </div> :
                  <div className='single'>
                    <h5>Price:{`$${v.price}`}</h5>
                    <button onClick={()=>handleAddCart(v)}>Add</button>
                  </div>
                }
                </div>


                <hr />
               </div>
             ))
           }
          </div>

          <img onClick={()=>handleCloseTeam()} id='close' src="https://res.cloudinary.com/dvr397xda/image/upload/v1660238073/cube/Happy%20Town/cancel_kqamzy.png" alt="" />
    </div>

    <div className='cart'>
      <button onClick={()=>handleOpenCart()}>
        <img src="https://res.cloudinary.com/dvr397xda/image/upload/v1660312568/cube/Happy%20Town/shopping-cart_fxxo6v.png" alt="" />
           {order.length>0&&<p>
            {order.length}
           </p>}
      </button>

    </div>

    <div style={isCart ? {top:'20vh',transition:'1.5s'}:{top:'200vh',transition:'1.5s'}}  className="wrapper6">
    <img onClick={()=>handleCloseCart()} id='close' src="https://res.cloudinary.com/dvr397xda/image/upload/v1660238073/cube/Happy%20Town/cancel_kqamzy.png" alt="" />
    {
      order.length>0 ? <div className='cart-container'>
            <div className='cart-scroll'>
                  {
                    order&&order.map((v,i)=>(
                      <div key={i}>
                        <div className='order-wrap'>

                          <div>
                            <h6>{v.name}</h6>
                            <h6>{v.english}</h6>
                          </div>

                          <div >
                            <h6>{v.size}</h6>
                            <h6>{`$${v.price}`}</h6>
                          </div>

                          <div>
                            <button onClick={()=>handleRemove(i)}>
                              Remove
                            </button>
                          </div>

                        </div>
                      </div>
                    ))
                  }
            </div>
             <div>
                    <h2>Total:{`$${total}`}</h2>
                  </div>
                  <div className='info'>
                    <div>
                     
                      <input placeholder='Your Name:' type="text" value={name1} onChange={(e)=>handleName(e)} />
                    </div>
                    <div>
                   
                      <input placeholder='Your Phone:' type="text" value={phone} onChange={(e)=>handlePhone(e)}/>
                    </div>
                  </div>
           
                  <div className='sub-btn'>
                    <button onClick={()=>handleSubmit()}>
                      提交订单
                    </button>
                  </div>
      </div> : 
      <h2> Your Cart Was Empty</h2>   }

    </div>

    </div>
  )
}

