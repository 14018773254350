import { useRef } from 'react'
import { Canvas, useFrame,extend,useThree, } from '@react-three/fiber'
import {  Image, } from '@react-three/drei'
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import './index.scss'
extend({ OrbitControls });

const CameraControls = () => {

  const {
    camera,
    gl: { domElement },
  } = useThree();
  const controls = useRef();
  useFrame(() => controls.current.update());
  return (
    <orbitControls
      ref={controls}
      args={[camera, domElement]}
      autoRotate={false}
      enableZoom={true}
      maxDistance={5.5}
      minDistance={2}
    />
  );
};

export default function Pop({picture}) {
    const mesh = useRef()
  return (
     <Canvas   className='canvas-wrap'>
    <CameraControls />
    <ambientLight intensity={0.5} />
     <Image ref={mesh}  scale={[2,4,2]} position={[0,0,0]}  url={picture.map}/>
      
    </Canvas>
  )
}
