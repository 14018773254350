import React from 'react'

export default function Logout() {
  return (
    <div>
        
        Logout
        <button>
        </button>
            
        <button>

        </button>
    
    </div>
  )
}
