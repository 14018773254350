import React,{useState,useEffect} from 'react'
import Nav from '../../components/Nav/index'
import Footer from '../../components/Footer'

import axios from "axios";
import { Helmet } from 'react-helmet';
import {Link} from 'react-router-dom'


import './index.scss'

export default function NewsPage() {

  const apiUrl ='https://metaserverapp.com'

  const CATEGORYS = [
    '全部',
    '国际',
    '美国',
    '中国', 
    '娱乐',
    '体育', 
    '游戏', 
    '科技', 
    '搞笑',
    '旅游', 
]

  const [news,setNews]=useState([])
  const [page,setPage] = useState(1)
  const [total,setTotal]=useState(0)
  const [totalPage,setTotalPage] = useState(0)
  const [index,setIndex] = useState(0)

  const handleCategory = (i)=>{
    setIndex(i)
  }

    useEffect(()=>{

        const fetchNews = async()=>{
            const result = await axios.get(`${apiUrl}/news`,{params:{page:1}})
            if(result.data.success){
           
                setNews(result.data.data[0].result)
      
              
            
              const temp = result.data.data[0].total[0].total
              setTotal(result.data.data[0].total[0].total)
              const temp2 = Math.ceil(temp/15)
              setTotalPage(temp2)
           
      
            }
            else{
              alert('Server Error! No data response received!')
            }
        }
        fetchNews()

    },[])

    useEffect(()=>{
        if(index){
            const fetchNews = async()=>{
                const result = await axios.get(`${apiUrl}/news/category`,{params:{page:page,category:CATEGORYS[index]}})
                if(result.data.success){
               
                    setNews(result.data.data[0].result)
          
                  const temp = result.data.data[0].total[0].total
                  setTotal(result.data.data[0].total[0].total)
                  const temp2 = Math.ceil(temp/15)
                  setTotalPage(temp2)
          
                }
                else{
                  alert('Server Error! No data response received!')
                }
            }
            fetchNews()

        }
        else{
            const fetchNews = async()=>{
                const result = await axios.get(`${apiUrl}/news`,{params:{page:page}})
                if(result.data.success){
               
                    setNews(result.data.data[0].result)
          
                  
                
                  const temp = result.data.data[0].total[0].total
                  setTotal(result.data.data[0].total[0].total)
                  const temp2 = Math.ceil(temp/15)
                  setTotalPage(temp2)
               
          
                }
                else{
                  alert('Server Error! No data response received!')
                }
            }
            fetchNews()
        }

    },[page,index])

    const handleNextPage = ()=>{
        setPage(page+1)
        const wrap =document.getElementsByClassName('auth-posts-form')[0]
        wrap.scrollTop = 0
      }

      const handleLastPage = ()=>{
        setPage(page-1)
        const wrap =document.getElementsByClassName('auth-posts-form')[0]
        wrap.scrollTop = 0
      }

console.log(news)

  return (

    <div className='home-wrap'>
        <Helmet
      title={'元生活每日新闻'}
      meta={[
        {
          name: `description`,
          content: '美国华人信息交流分享平台',
        },
      ]} />
   
        <Nav/>

      <div className='news-select-container'>
        <div className="news-category">
            <div>
            {
                CATEGORYS.map((v,i)=>(
                    (i===index?<button style={{borderBottom:'2px solid #de2c2c'}} onClick={()=>handleCategory(i)} key={i}>
                        {v}
                    </button>:<button onClick={()=>handleCategory(i)} key={i}>
                        {v}
                    </button>)
                ))
            }
            </div>
          
        </div>
       <div className='news-grid'>
       {
        news.length>0&&news.map((v,i)=>(
            <div className='news-item-wrap' key={i}>

                <Link to={`/news/${v._id}`} className='news-item'>
                    <div className='news-item-left'>
                        <h3>{v.title.substring(0,12)}...</h3>
                        <div className='news-item-left-tag'>
                        {
                            v.tags.length>0&&v.tags.map((n,m)=>(
                            <p key={m}>{n}</p>
                            ))
                        }
                        </div>
                        <div className='news-item-left-footer'>

                        <h6>{v.date.substring(0,10)} </h6>
                        <h6> 来源:{v.source}</h6>
                        </div>
                    </div>
                    <div className='news-item-right'>
                        <img src={v.images[0]} alt="" />
                    </div>
                </Link>

              <hr />

            </div>
        ))
      }
       </div>
    
      </div>

    
     
     <div className='pagenation-wrap'>

<div> {page!==1&&<p onClick={()=>handleLastPage()}>上一页</p>}</div>

<div> <p>第{page}页</p> </div>

<div> {page !== totalPage&&<p onClick={()=>handleNextPage()}>下一页</p>}</div>

<div><p>共{totalPage}页</p></div>
<div>
<p>共{total}个结果</p>
</div>
</div>
        <Footer/>

    </div>
  )
}
