import React,{useState,useEffect,useRef,Suspense,useCallback}from 'react'
import { Canvas, useFrame,extend,useThree,useLoader,} from '@react-three/fiber'
import * as THREE from "three";
import HTMLFlipBook from "react-pageflip";

import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { TextureLoader } from 'three/src/loaders/TextureLoader.js';
import {PresentationControls,useGLTF,ContactShadows,Html,useProgress} from '@react-three/drei'
import {useParams} from 'react-router-dom'
import Env from '../../components/Environment/index'
import fetchData from '../../nicrg'

import Player from '../../components/Player/index'
import './index.scss'



const  Cube = ({position})=>{

  const image = 'https://res.cloudinary.com/dvr397xda/image/upload/v1653324529/cube/%E8%BE%B9%E6%A1%862_b3lfk1.png'
  const mesh = useRef()
  const map = useLoader(THREE.TextureLoader,image)
  useFrame((state, delta) => {
    mesh.current.scale.x = 1 + (1 + Math.sin(state.clock.elapsedTime * 1)) * 0.03
    mesh.current.scale.y = 1 + (1 + Math.sin(state.clock.elapsedTime * 1)) * 0.03
    mesh.current.scale.z = 1 + (1 + Math.sin(state.clock.elapsedTime * 1)) * 0.03
  })

  return (
    <>
    <mesh position={position} ref={mesh}>
        <boxBufferGeometry  attach="geometry"  args={[2.2, 2.2, 2.2]}/>
        <meshStandardMaterial transparent={true} opacity={1}  attach="material" map={map}  roughness={0.7}
        metalness={0.1} />

       


    </mesh>
    </>
  )
}

function Book({people}){



  const onFlip = useCallback((e) => {
    console.log('Current page: ' + e.data);
}, []);

  return (


 
  <HTMLFlipBook className="wrapper" 
  size='stretch'  width={195}  height={338}        maxShadowOpacity={0.5}
  onFlip={onFlip}
  showCover
 >
  
  {
      people.show&&people.show.map((v,i)=>(
          <div id='page-item' className="demoPage" key={i}>
            
            <h2>
                    {v.name}
                </h2>
                {v.title&&<h2>
                    {v.title}
                </h2>}
               
             <img src={v.image} alt="" />
               
          </div>
      ))
  }
</HTMLFlipBook>

)
}


function Box(props) {
  const {people,position} = props
  const mesh = useRef()
  const [index,setIndex] = useState(0)

 

  useEffect(() => {
    const interval = setInterval(() => {

        if(index ===people.show.length-1){
            setIndex(0)
        }
        else{
            setIndex(index+1)
        }
    }, 2500);
    return () => clearInterval(interval);
  }, [index,people]);
  
  // setInterval(handleChangeIndex,1000)

  let textures;

textures = useLoader(THREE.TextureLoader,[people.images[0],people.images[1],people.show[index].image,people.images[3],people.images[4],people.images[5]])
// textures = useLoader(TextureLoader,[people.images[0],people.images[1],people.images[2],people.images[3],people.images[4],people.images[5]])

textures.minFilter = textures.magFilter = THREE.LinearFilter;



textures.format= THREE.RGBAFormat;




  function Map (){
    return(
    
      textures.map((texture, idx) =>(
        idx ===2||idx===3?<meshBasicMaterial key={idx} attach={`material-${idx}`} map={texture}  />
        :<meshBasicMaterial opacity={1} transparent={true}  key={idx} attach={`material-${idx}`} map={texture}  />
        )
)
    )
  } 

  return (
    <>
    <mesh
      onClick={(e)=>{
        e.stopPropagation()
      }}
      ref={mesh}
     position={position}
     
      >
          
      <boxBufferGeometry attach="geometry"  args={[2, 2, 2]}/>
        <Map/>
       
      

    </mesh>


    </>
  )
}

extend({ OrbitControls });

const CameraControls = () => {

  const {
    camera,
    gl: { domElement },
  } = useThree();
  const controls = useRef();
  useFrame(() => controls.current.update());
  return (
    <orbitControls
      ref={controls}
      args={[camera, domElement]}
      autoRotate={false}
      enableZoom={true}
      maxDistance={5.5}
      minDistance={2}
    />
  );
};

const Phone = ({people,opacity,posY})=>{
  const image = 'https://res.cloudinary.com/dvr397xda/image/upload/v1650920511/cube/%E7%8E%BB%E7%92%832_ukdjz2.jpg'
  const mesh1 = useRef()
  const map = useLoader(TextureLoader,image)

  const handleClick=()=>{
    window.open(`tel:+1${people.tel}`,'_self')
  }


  return (
    <>
    <mesh position={[1.2,-1+posY,0]} onClick={(e)=>{ handleClick();     e.stopPropagation()
}}   ref={mesh1}>
        <boxBufferGeometry  attach="geometry"  args={[0.1,0.7,2]}/>
        <meshStandardMaterial transparent={true} opacity={opacity}   color='green'  />

       


    </mesh>
    </>
  )
}

const Cell = ({people,opacity,posY})=>{
    const image = 'https://res.cloudinary.com/dvr397xda/image/upload/v1650920511/cube/%E7%8E%BB%E7%92%832_ukdjz2.jpg'
    const mesh1 = useRef()
    const map = useLoader(TextureLoader,image)
  
    const handleClick=()=>{
      window.open(`tel:+${people.cell}`,'_self')
    }
  
  
    return (
      <>
      <mesh position={[1.2,-1.5+posY,0.5]} onClick={(e)=>{ handleClick();     e.stopPropagation()
  }}   ref={mesh1}>
          <boxBufferGeometry  attach="geometry"  args={[0.1,1,1]}/>
          <meshStandardMaterial transparent={true} opacity={opacity}  attach="material" map={map}  roughness={0.7}
          metalness={0.1} />
  
         
  
  
      </mesh>
      </>
    )
  }

const Email = ({people,opacity,posY})=>{
  const image = 'https://res.cloudinary.com/dvr397xda/image/upload/v1650920511/cube/%E7%8E%BB%E7%92%832_ukdjz2.jpg'
  const mesh1 = useRef()
  const map = useLoader(TextureLoader,image)

  const handleClick=()=>{
    console.log(1);
    window.open(`mailto:+${people.email}`,'_self')
  }

  return (
    <>
    <mesh position={[1.2,-1.7+posY,0]} onClick={(e)=>{ handleClick();     e.stopPropagation()
}}   ref={mesh1}>
        <boxBufferGeometry  attach="geometry"  args={[0.1,0.7,2]}/>
        <meshStandardMaterial transparent={true} opacity={opacity}  attach="material" map={map}  roughness={0.7}
        metalness={0.1} />

       


    </mesh>
    </>
  )
}

const Map = ({people,opacity,posY})=>{
  const image = 'https://res.cloudinary.com/dvr397xda/image/upload/v1650920511/cube/%E7%8E%BB%E7%92%832_ukdjz2.jpg'
  const mesh1 = useRef()
  const map = useLoader(TextureLoader,image)

  const handleClick=()=>{
    console.log(1);
    window.open(`${people.map}`,'_self')
  }

  return (
    <>
    <mesh position={[-1.2,-1.5+posY,-0.5]} onClick={(e)=>{ handleClick();     e.stopPropagation()
}}   ref={mesh1}>
        <boxBufferGeometry  attach="geometry"  args={[0.1,1,1]}/>
        <meshStandardMaterial transparent={true} opacity={opacity}  attach="material" color='green'  roughness={0.7}
        metalness={0.1} />

       


    </mesh>
    </>
  )
}

const Youtube = ({people,opacity,posY})=>{
    const image = 'https://res.cloudinary.com/dvr397xda/image/upload/v1650920511/cube/%E7%8E%BB%E7%92%832_ukdjz2.jpg'
    const mesh1 = useRef()
    const map = useLoader(TextureLoader,image)
  
    const handleClick=()=>{
      console.log(1);
      window.open(`${people.video}`,'_self')
    }
  
    return (
      <>
      <mesh position={[-1.2,-1.5+posY,0.5]} onClick={(e)=>{ handleClick();     e.stopPropagation()
  }}   ref={mesh1}>
          <boxBufferGeometry  attach="geometry"  args={[0.1,1,1]}/>
          <meshStandardMaterial transparent={true} opacity={opacity}  attach="material" color='green'  roughness={0.7}
          metalness={0.1} />
  
         
  
  
      </mesh>
      </>
    )
  }

  const Facebook = ({people,opacity,posY})=>{
    const image = 'https://res.cloudinary.com/dvr397xda/image/upload/v1650920511/cube/%E7%8E%BB%E7%92%832_ukdjz2.jpg'
    const mesh1 = useRef()
    const map = useLoader(TextureLoader,image)
  
    const handleClick=()=>{
      console.log(1);
      window.open(`${people.linkedin}`,'_self')
    }
  
    return (
      <>
      <mesh position={[-1.2,-0.5+posY,-0.5]} onClick={(e)=>{ handleClick();     e.stopPropagation()
  }}   ref={mesh1}>
          <boxBufferGeometry  attach="geometry"  args={[0.1,1,1]}/>
          <meshStandardMaterial transparent={true} opacity={opacity}  attach="material" color='green'  roughness={0.7}
          metalness={0.1} />
  
         
  
  
      </mesh>
      </>
    )
  }

  const Linkedin = ({people,opacity,posY})=>{
    const image = 'https://res.cloudinary.com/dvr397xda/image/upload/v1650920511/cube/%E7%8E%BB%E7%92%832_ukdjz2.jpg'
    const mesh1 = useRef()
    const map = useLoader(TextureLoader,image)
  
    const handleClick=()=>{
      console.log(1);
      window.open(`${people.linkedin}`,'_self')
    }
  
    return (
      <>
      <mesh rotation={[0,0,Math.PI * 2.5]} position={[0.5,0.2+posY,0.5]} onClick={(e)=>{ handleClick();     e.stopPropagation()
  }}   ref={mesh1}>
          <boxBufferGeometry  attach="geometry"  args={[0.1,1,1]}/>
          <meshStandardMaterial transparent={true} opacity={opacity}  attach="material" color='green'  roughness={0.7}
          metalness={0.1} />
      </mesh>
      </>
    )
  }

  const Douyin = ({people,opacity,posY})=>{
    const image = 'https://res.cloudinary.com/dvr397xda/image/upload/v1650920511/cube/%E7%8E%BB%E7%92%832_ukdjz2.jpg'
    const mesh1 = useRef()
    const map = useLoader(TextureLoader,image)
  
    const handleClick=()=>{
      console.log(1);
      window.open(`${people.douyin}`,'_self')
    }
  
    return (
      <>
      <mesh rotation={[0,0,Math.PI * 2.5]} position={[-0.5,0.2+posY,0.5]} onClick={(e)=>{ handleClick();     e.stopPropagation()
  }}   ref={mesh1}>
          <boxBufferGeometry  attach="geometry"  args={[0.1,1,1]}/>
          <meshStandardMaterial transparent={true} opacity={opacity}  attach="material" color='red'  roughness={0.7}
          metalness={0.1} />
      </mesh>
      </>
    )
  }


const Web = ({people,opacity,posY})=>{
 
  const mesh1 = useRef()

  const handleClick=()=>{
    window.open(`${people.website}`,'_self')
  }

  return (
    <>
    <mesh rotation={[0, 0, 0]} position={[-1.2,-0.5+posY,0.5]} onClick={(e)=>{ handleClick();     e.stopPropagation()
}}   ref={mesh1}>
        <boxBufferGeometry  attach="geometry"  args={[0.1,1,1]}/>
        <meshStandardMaterial transparent={true} opacity={opacity}  attach="material" color='red'  roughness={0.7}
        metalness={0.1} />

       


    </mesh>
    </>
  )
}


const VideoBtn = ({people,opacity})=>{

  const mesh1 = useRef()

  const handleClick=()=>{
    window.open(`${people.video}`,'_self')
  }

  return (
    <>
    <mesh rotation={[0, Math.PI * 2.5, 0]} position={[0,0,-1.2]} onClick={(e)=>{ handleClick();     e.stopPropagation()
}}   ref={mesh1}>
        <boxBufferGeometry  attach="geometry"  args={[0.1,2,2]}/>
        <meshStandardMaterial transparent={true} opacity={opacity}   attach="material" color='red'  roughness={0.7}
        metalness={0.1} />

       


    </mesh>
    </>
  )
}



export default function Album() {
  const mesh2 = useRef()

  const{ name } = useParams();
  const [people,setPeople] = useState({})
  const [isTeam,setIsTeam] = useState(false)
  const opacity=0
  const posY = 1

  const [load,setLoad] = useState(true)
  const loadImg = 'https://res.cloudinary.com/dvr397xda/image/upload/v1656958563/cube/tenor_2_ngzbpe.gif'

  useEffect(() => {
    const data = fetchData()

    const result = data.find(e=>e.id===name)
    setPeople(result)
    
  },[name])


  const handleClickTeam = ()=>{
    setIsTeam(true)
  }
  const handleCloseTeam = ()=>{
    setIsTeam(false)
  }
  
  useEffect(() => {

    const timer = setTimeout(()=>{
      setLoad(false)
    },2000)
    return ()=>clearTimeout(timer)
  },[])

  return (
    <div className="container-card" style={{backgroundImage:`url(${people.gif})`}}  >
     
     <Canvas  gl={{ alpha: true, antialias: true}}  className='canvas-wrap'>
    <CameraControls />
 
      <ambientLight intensity={0.5}  position={[0,0,0]}/>
      {/* <pointLight  position={[0,0,0]} /> */}
      <Suspense fallback={null}>
   

        
      <Box people={people}   position={[0, 0, 0]} />
      {/* <Html className="content" rotation-x={-Math.PI / 2} position={[0, 0.05, -0.09]} >

        <div className="wrapper">
          <BookPage/>
        </div>

      </Html> */}

      <Cube position={[0, 0, 0]} />

      <Phone posY={posY} people={people} opacity={opacity}/>
      <Email posY={posY} people={people} opacity={opacity}/>
      {/* <Cell posY={posY} people={people} opacity={opacity}/> */}

      <Facebook posY={posY} people={people} opacity={opacity}/>
      <Youtube posY={posY} people={people} opacity={opacity}/>
      <Map posY={posY} people={people} opacity={opacity}/>
      <Web posY={posY} people={people} opacity={opacity}/>
  
      {/* <Linkedin posY={posY} people={people} opacity={opacity}/> */}
      {/* <Douyin posY={posY} people={people} opacity={opacity}/> */}

     

      <VideoBtn people={people} opacity={opacity}/>

      <mesh rotation={[Math.PI * 2.5, Math.PI * 2.5, 0]} position={[0,1.2,0]} onClick={(e)=>{ handleClickTeam();     e.stopPropagation()
}}   ref={mesh2}>
        <boxBufferGeometry  attach="geometry"  args={[0.1,2,2]}/>
        <meshStandardMaterial transparent={true} opacity={opacity}   attach="material" color='red'  roughness={0.7}
        metalness={0.1} />

       
    </mesh>
    
    </Suspense>

    </Canvas>
    <div style={isTeam ? {top:'20%',transition:'1.5s'}:{top:'200%',transition:'1.5s'}}  className="wrapper">
          <Book people={people}/>
          <img onClick={()=>handleCloseTeam()} id='close' src="https://res.cloudinary.com/dvr397xda/image/upload/v1656101942/cube/cancel_dmukx0.png" alt="" />
    </div>
          <video playsInline loop   autoPlay id="video"/>

          <div style={load ?{ zIndex:'100000',opacity:'1'} :{zIndex:'-10',opacity:'0',transition:'4.5s' }} className='load-image'>
                <img src={loadImg} alt="" />
          </div>
    </div>
  )
}

