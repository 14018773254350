const data = [

    { 
        id:'test',
        name:'Star&Chen',
        images:[
        {
            isRotate:false,
            url:'https://res.cloudinary.com/dvr397xda/image/upload/v1659540496/cube/hunqing/couple-37v_ah2irj_l7yxed.jpg'
        },
        {
            isRotate:false,
            url:'https://res.cloudinary.com/dvr397xda/image/upload/v1659540496/cube/hunqing/couple-40v_kgluhl_vbgahs.jpg'
        },
        {
            isRotate:true,
            url:'https://res.cloudinary.com/dvr397xda/image/upload/v1661800969/cube/hunqing/couple-52h_dlj95o_l6g5ne.webp'
        },
        {
            isRotate:true,
            url:'https://res.cloudinary.com/dvr397xda/image/upload/v1661800969/cube/hunqing/couple-2h_up2lnw_hu0rv0.webp'
        },
        {
            isRotate:true,
            url:'https://res.cloudinary.com/dvr397xda/image/upload/v1661800969/cube/hunqing/couple-8h_xu3k4m_syo0du.webp'
        },
        {
            isRotate:true,
            url:'https://res.cloudinary.com/dvr397xda/image/upload/v1661800969/cube/hunqing/couple-49h_f988wt_i3gklh.webp'
        },
        {
            isRotate:false,
            url:'https://res.cloudinary.com/dvr397xda/image/upload/v1659540496/cube/hunqing/couple-38v_xxjqbf_dala6p.jpg'
        },
        {
            isRotate:false,
            url:'https://res.cloudinary.com/dvr397xda/image/upload/v1661801201/cube/hunqing/couple-9v_x7wdbj_ju9aho.webp'
        },
        {
            isRotate:false,
            url:'https://res.cloudinary.com/dvr397xda/image/upload/v1661801201/cube/hunqing/couple-8v_kkplq9_owxwwr.webp'
        },
      
          
    ],
    },

]

const fetchWedding = ()=>{
    return data;
}


export default fetchWedding