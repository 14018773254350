import React,{useState,useEffect,useRef,Suspense,useCallback}from 'react'
import { Canvas, useFrame,extend,useThree,useLoader,} from '@react-three/fiber'
import {Link,useNavigate} from 'react-router-dom'
import * as THREE from "three";
import { Helmet } from 'react-helmet';
// import { Helmet,} from 'react-helmet-async';

import HTMLFlipBook from "react-pageflip";
import {useParams} from 'react-router-dom'
import axios from "axios";
import { TextureLoader } from 'three/src/loaders/TextureLoader.js';
import {useGLTF,useFBX,useProgress,Html,PresentationControls,Line} from '@react-three/drei'
import Player from '../../components/Player/index'
import {
  Star,
  StarFill,
  Share,
  Images
} from "react-bootstrap-icons";
import './index.scss'


function Loader({people}) {
  // console.log(people,1)
  const { progress } = useProgress()
  return <Html center> <h2 id='loadText'>
        Loading ...{progress.toFixed(0)}%
        <img id='loadImage' src="https://res.cloudinary.com/dvr397xda/image/upload/v1660580851/cube/output-onlinegiftools_rgnpbb.gif" alt="" />

        </h2>
        {/* <img src={people.loadImg} alt="" /> */}

        </Html>
}






const Gallery = ({gallery})=>{
  const mesh = useRef()
  const [index,setIndex] = useState(0)

 useEffect(() => {
    const interval = setInterval(() => {

        if(index ===gallery.length-1){
            setIndex(0)
        }
        else{
            setIndex(index+1)
        }
    }, 2500);
    return () => clearInterval(interval);
  }, [index,gallery.length]);
  let texture  = useLoader(THREE.TextureLoader,gallery[index])
  texture.minFilter = texture.magFilter = THREE.LinearFilter;

  texture.format= THREE.RGBAFormat;

  return (
     
    <mesh position={[1.3,0,0]} rotation={[0,Math.PI * 2.5,0]} onClick={(e)=>{     e.stopPropagation()
}}   ref={mesh}>
        <planeGeometry  attach="geometry"  args={[2.4,2.4]}/>
        <meshStandardMaterial map={texture}  side={THREE.DoubleSide}   />
    </mesh>

  )
}


function Box(props) {
  const {people,position} = props
  const mesh = useRef()
  const [index,setIndex] = useState(0)


  let textures;
  
textures = useLoader(THREE.TextureLoader,[people.images[0],people.images[1],people.images[2],people.images[3],people.images[4],people.images[5]])
 

textures.minFilter = textures.magFilter = THREE.LinearFilter;

textures.format= THREE.RGBAFormat;

  function Map (){
    return(
    
      textures.map((texture, idx) =>(
        idx ===2||idx===3 || idx===0?<meshBasicMaterial key={idx} attach={`material-${idx}`} map={texture}   transparent={true} opacity={1}/>
        :<meshBasicMaterial opacity={1} transparent={true}  key={idx} attach={`material-${idx}`} map={texture}  />
        )
)
    )
  } 
  

  return (
  
    <mesh
      onClick={(e)=>{
        e.stopPropagation()
      }}
      ref={mesh}
     position={position}
     
      >
          
      <boxBufferGeometry attach="geometry"  args={[2.5, 2.5, 2.5]}/>
        <Map/>
       
      

    </mesh>


   
  )
}

function Box2(){
  const mesh = useRef()

  const images = [
    'https://res.cloudinary.com/dvr397xda/image/upload/v1708027324/3d-cards/yousicai/%E7%A9%BA%E7%99%BD_u6z7gr.png',
    'https://res.cloudinary.com/dvr397xda/image/upload/v1708027324/3d-cards/yousicai/%E7%A9%BA%E7%99%BD_u6z7gr.png',
    'https://res.cloudinary.com/dvr397xda/image/upload/v1708034280/3d-cards/yousicai/WechatIMG23818_zhk9sf.png', // up
    'https://res.cloudinary.com/dvr397xda/image/upload/v1708034280/3d-cards/yousicai/WechatIMG23818_zhk9sf.png', // down
    'https://res.cloudinary.com/dvr397xda/image/upload/v1708027324/3d-cards/yousicai/%E7%A9%BA%E7%99%BD_u6z7gr.png',
    'https://res.cloudinary.com/dvr397xda/image/upload/v1708027324/3d-cards/yousicai/%E7%A9%BA%E7%99%BD_u6z7gr.png',
  ]
  let textures = useLoader(THREE.TextureLoader,images)
  textures.minFilter = textures.magFilter = THREE.LinearFilter;

textures.format= THREE.RGBAFormat;

function Map (){
  return(
  
    textures.map((texture, idx) =>(
      idx ===2||idx===3 || idx===0?<meshBasicMaterial key={idx} attach={`material-${idx}`} map={texture}   transparent={true} opacity={1}/>
      :<meshBasicMaterial opacity={1} transparent={true}  key={idx} attach={`material-${idx}`} map={texture}  />
      )
)
  )
} 
return (
  
  <mesh
    onClick={(e)=>{
      e.stopPropagation()
    }}
    ref={mesh}
   position={[0,-3,0]}
   
    >
        
    <boxBufferGeometry attach="geometry"  args={[3, 0.01, 2]}/>
      <Map/>
     
    

  </mesh>


 
)

}


function Logo(){
  const mesh = useRef()

  const images = [
    'https://res.cloudinary.com/dvr397xda/image/upload/v1708032313/3d-cards/yousicai/cropped-%E6%80%BB%E4%BC%9A%E6%97%97%E5%B8%9C%E6%89%93%E5%8D%B0PNG-e1646506573787-plfkf6tb1q5hzz9ibesdxg0uuy28vqanlaxq8zm0gm_hvhipo.png',
    'https://res.cloudinary.com/dvr397xda/image/upload/v1708032313/3d-cards/yousicai/cropped-%E6%80%BB%E4%BC%9A%E6%97%97%E5%B8%9C%E6%89%93%E5%8D%B0PNG-e1646506573787-plfkf6tb1q5hzz9ibesdxg0uuy28vqanlaxq8zm0gm_hvhipo.png',
    'https://res.cloudinary.com/dvr397xda/image/upload/v1708032313/3d-cards/yousicai/cropped-%E6%80%BB%E4%BC%9A%E6%97%97%E5%B8%9C%E6%89%93%E5%8D%B0PNG-e1646506573787-plfkf6tb1q5hzz9ibesdxg0uuy28vqanlaxq8zm0gm_hvhipo.png', // up
    'https://res.cloudinary.com/dvr397xda/image/upload/v1708032313/3d-cards/yousicai/cropped-%E6%80%BB%E4%BC%9A%E6%97%97%E5%B8%9C%E6%89%93%E5%8D%B0PNG-e1646506573787-plfkf6tb1q5hzz9ibesdxg0uuy28vqanlaxq8zm0gm_hvhipo.png', // down
    'https://res.cloudinary.com/dvr397xda/image/upload/v1708032313/3d-cards/yousicai/cropped-%E6%80%BB%E4%BC%9A%E6%97%97%E5%B8%9C%E6%89%93%E5%8D%B0PNG-e1646506573787-plfkf6tb1q5hzz9ibesdxg0uuy28vqanlaxq8zm0gm_hvhipo.png',
    'https://res.cloudinary.com/dvr397xda/image/upload/v1708032313/3d-cards/yousicai/cropped-%E6%80%BB%E4%BC%9A%E6%97%97%E5%B8%9C%E6%89%93%E5%8D%B0PNG-e1646506573787-plfkf6tb1q5hzz9ibesdxg0uuy28vqanlaxq8zm0gm_hvhipo.png',
  ]
  let textures = useLoader(THREE.TextureLoader,images)
  textures.minFilter = textures.magFilter = THREE.LinearFilter;

textures.format= THREE.RGBAFormat;

function Map (){
  return(
  
    textures.map((texture, idx) =>(
     <meshBasicMaterial key={idx} attach={`material-${idx}`} map={texture} transparent={true}    side={THREE.DoubleSide} opacity={1}/>
     
      )
)
  )
} 
return (
  
  <mesh
    onClick={(e)=>{
      e.stopPropagation()
    }}
    ref={mesh}
   position={[0,0,0]}
   
    >
        
    <boxBufferGeometry attach="geometry"  args={[2, 2, 2]}/>
      <Map/>
     
    

  </mesh>


 
)

}




const BackLink = ({people,opacity,posY,visible})=>{

    const mesh1 = useRef()
   
  
    const handleClick=()=>{
  
      window.open(`${people.backLink}`,'_self')
    }
  
    return (
  
      <mesh rotation={[0, Math.PI * 2.5, 0]} position={[0,-1+posY,-1.3]} onClick={(e)=>{ handleClick();     e.stopPropagation()
  }}   ref={mesh1}>
          <boxBufferGeometry  attach="geometry"  args={[0.1,2.5,2.5]}/>
          <meshStandardMaterial visible={visible} transparent={true} opacity={opacity}  attach="material" color='green'  roughness={0.7}
          metalness={0.1} />
  
         
  
  
      </mesh>
   
    )
  }





const FrontLink = ({people,opacity,posY,visible})=>{
 
  const mesh1 = useRef()

  const handleClick=()=>{
    window.open(`${people.frontLink}`,'_self')
  }

  return (
   
    <mesh rotation={[0, Math.PI * 2.5, 0]} position={[0,-1+posY,1.3]} onClick={(e)=>{ handleClick();     e.stopPropagation()
}}   ref={mesh1}>
        <boxBufferGeometry  attach="geometry"  args={[0.1,2.4,2.4]}/>
        <meshStandardMaterial visible={visible} transparent={true} opacity={opacity}   color='red'   />

       


    </mesh>

  )
}

const LeftLink = ({people,opacity,posY,visible})=>{
    const mesh1 = useRef()

    const handleClick=()=>{
      window.open(`${people.leftLink}`,'_self')
    }
  
    return (
   
      <mesh position={[-1.3,0,0]} rotation={[0,0,0]} onClick={(e)=>{ handleClick();     e.stopPropagation()
  }}   ref={mesh1}>
          <boxBufferGeometry  attach="geometry"  args={[0.1,2.4,2.4]}/>
          <meshStandardMaterial visible={visible} transparent={true} opacity={opacity}   color='#fff'   />
  
         
  
  
      </mesh>
     
    )
}



const Group = ({people,opacity,posY,visible})=>{

  const Points= [
    {
      point:[
      [0.45, 1.2, 1.3],
      [1.2, 1.2, 1.3],
      [1.2, 0.5, 1.3],
      [0.45, 0.5, 1.3],
      [0.45, 1.2, 1.3],
    ],
    },
    {
      point:[
      [0.45, 0.35, 1.3],
      [1.2, 0.35, 1.3],
      [1.2, -0.35, 1.3],
      [0.45, -0.35, 1.3],
      [0.45, 0.35, 1.3],
    ],
    },
    {
      point:[
      [0.45, -0.45, 1.3],
      [1.2, -0.45, 1.3],
      [1.2, -1.15, 1.3],
      [0.45, -1.15, 1.3],
      [0.45, -0.45, 1.3],
    ],
    },
    {
      point:[
      [-0.35, -0.45, 1.3],
      [0.35, -0.45, 1.3],
      [0.35, -1.15, 1.3],
      [-0.35, -1.15, 1.3],
      [-0.35, -0.45, 1.3],
    ],
    },
    {
      point:[
      [-1.2, -0.45, 1.3],
      [-0.45, -0.45, 1.3],
      [-0.45, -1.15, 1.3],
      [-1.2, -1.15, 1.3],
      [-1.2, -0.45, 1.3],
    ],
    },
    {
      point:[
      [-1.3, 1.3, 1.3],
      [1.3, 1.3, 1.3],
      [1.3, -1.3, 1.3],
      [-1.3, -1.3, 1.3],
      [-1.3, 1.3, 1.3],
    ],
    },
    {
      point:[
      [-1.3, 1.3, -1.3],
      [1.3, 1.3, -1.3],
      [1.3, -1.3, -1.3],
      [-1.3, -1.3, -1.3],
      [-1.3, 1.3, -1.3],
    ],
    },
    {
      point:[
      [-1.3,-1.3, 1.3 ],
      [-1.3,1.3, 1.3],
      [-1.3,1.3, -1.3],
      [-1.3,-1.3, -1.3],
      [-1.3,-1.3, 1.3],
    ],
    },
    {
      point:[
      [1.3,-1.3, 1.3 ],
      [1.3,1.3, 1.3],
      [1.3,1.3, -1.3],
      [1.3,-1.3, -1.3],
      [1.3,-1.3, 1.3],
    ],
    },

  ]

  const Points2 =[
    {
      point:[
      [-1.3, 1.3, 1.3],
      [1.3, 1.3, 1.3],
      [1.3, -1.3, 1.3],
      [-1.3, -1.3, 1.3],
      [-1.3, 1.3, 1.3],
    ],
    },
    {
      point:[
      [-1.3, 1.3, -1.3],
      [1.3, 1.3, -1.3],
      [1.3, -1.3, -1.3],
      [-1.3, -1.3, -1.3],
      [-1.3, 1.3, -1.3],
    ],
    },
    {
      point:[
      [-1.3,-1.3, 1.3 ],
      [-1.3,1.3, 1.3],
      [-1.3,1.3, -1.3],
      [-1.3,-1.3, -1.3],
      [-1.3,-1.3, 1.3],
    ],
    },
    {
      point:[
      [1.3,-1.3, 1.3 ],
      [1.3,1.3, 1.3],
      [1.3,1.3, -1.3],
      [1.3,-1.3, -1.3],
      [1.3,-1.3, 1.3],
    ],
    },
  ]

  const mesh = useRef()
  const [v,setV]= useState(1)
  const [POINT,setPOINT] = useState(Points2)

  useEffect(()=>{
    if(people.map&&people.email&&people.website&&people.qrCode){
      setPOINT(Points)
    }
    else{
      setPOINT(Points2)

    }


  },[people,Points,Points2])

 
 


  useFrame(({clock}) => {
      
    setV(Math.abs(Math.sin(clock.elapsedTime)))

    mesh.current.rotation.y += 0.001
  
  })

  return(<group ref={mesh}>
          <Logo />

<Box people={people}   position={[0, 0, 0]} />
{/* <Model people={people}/> */}


 

  {<FrontLink visible={visible} posY={posY} people={people} opacity={opacity}/>}
  {<Gallery visible={visible} posY={posY} gallery={people.gallery} />}

    {/* <Line  points={points}  color='#0af741' linewidth={5} position={[0,0,0]} dashed
      dashSize={v * 1.5}
      gapSize={2 - v * 2}/> */}
    {
      POINT.map((m,i)=>(
        <Line key={i}  points={m.point} color={people.color}  linewidth={2} position={[0,0,0]} dashed
        dashSize={v * 1.5}
        gapSize={2 - v * 2}/>
      ))
    }
    {/* <Html  transform occlude onOcclude={occlude} position={[0.8,0.5,1.25]} style={{  opacity: occluded ? 0 : 1, }}>
    <div className='click-container'>
    <img className='btn--shockwave is-active' src="https://res.cloudinary.com/dvr397xda/image/upload/v1663022801/cube/tap_wbwwme.png" alt="" />
  </div>
    </Html> */}
  {<BackLink visible={visible} posY={posY} people={people} opacity={opacity}/>}
  {<LeftLink visible={visible} posY={posY} people={people} opacity={opacity}/>}
  </group>)

}




export default function Profession() {



  const [isLoaded,setIsLoaded] = useState(true)
   
  const people = {
    images:[
      'https://res.cloudinary.com/dvr397xda/image/upload/v1708286463/3d-cards/yousicai/Pngtree_chinese_new_year_lantern_and_5816221_heo9iq_s7pvdr.png',// right
      'https://res.cloudinary.com/dvr397xda/image/upload/v1708037163/3d-cards/yousicai/%E4%BE%A7%E9%9D%A2_2_cohh2l.png', // left
      'https://res.cloudinary.com/dvr397xda/image/upload/v1708286463/3d-cards/yousicai/Pngtree_chinese_new_year_lantern_and_5816221_heo9iq_s7pvdr.png', // up
      'https://res.cloudinary.com/dvr397xda/image/upload/v1708286463/3d-cards/yousicai/Pngtree_chinese_new_year_lantern_and_5816221_heo9iq_s7pvdr.png', //down
      'https://res.cloudinary.com/dvr397xda/image/upload/v1708037332/3d-cards/yousicai/WechatIMG23820_p6ow4n.png', //front
      'https://res.cloudinary.com/dvr397xda/image/upload/v1708037162/3d-cards/yousicai/%E5%90%8E%E9%9D%A2_2_h7f0cv.png', //back

    ],
    frontLink:'https://xingtianstudio.com/yousicai',
    gallery:[
      'https://res.cloudinary.com/dvr397xda/image/upload/v1708027326/3d-cards/yousicai/%E5%9B%BE%E7%89%877_puc0af.png',
      'https://res.cloudinary.com/dvr397xda/image/upload/v1708027325/3d-cards/yousicai/%E5%9B%BE%E7%89%876_ldjzbt.png',
      'https://res.cloudinary.com/dvr397xda/image/upload/v1708027325/3d-cards/yousicai/%E5%9B%BE%E7%89%873_v8hhyl.png',
      'https://res.cloudinary.com/dvr397xda/image/upload/v1708027325/3d-cards/yousicai/%E5%9B%BE%E7%89%874_t1jm7e.png',
      'https://res.cloudinary.com/dvr397xda/image/upload/v1708027325/3d-cards/yousicai/%E5%9B%BE%E7%89%875_ai7ijq.png',
      'https://res.cloudinary.com/dvr397xda/image/upload/v1708027324/3d-cards/yousicai/%E5%9B%BE%E7%89%872_bqfe6b.png',
    ],
    backLink:'https://usaacua.org/',
    leftLink:'https://www.youtube.com/playlist?app=desktop&list=PLzzsCOlToJufwc42b8JLvF_Gy9ikzOdQ4&si=_Xzu87Ek0-Udb3vM',
    loadImg:'https://res.cloudinary.com/dvr397xda/image/upload/v1708274992/kangnaixin/images_ununel.jpg',
    gif:'https://res.cloudinary.com/dvr397xda/image/upload/v1708032951/3d-cards/yousicai/1c98ac0675e2b13949592be9b417ce99_ykl64q.gif',
    name:'优思才2024龙年春晚',
    title:'优思才2024龙年春晚',
  }






  const opacity=0
  const posY = 1
  const visible = false

  const [load,setLoad] = useState(true)








  useEffect(() => {

    if(isLoaded){
      if(people.loadImg){
        const timer = setTimeout(()=>{
            setLoad(false)
          },4500)
          return ()=>clearTimeout(timer)
    }
    else{
        setLoad(false)
    }
    }
 
  },[people,isLoaded])

  const handleShare = async()=>{
 await navigator.clipboard.writeText(window.location.href)

   console.log(window.location.href);
    alert(`已复制当前页面网址，可转发！`)
  }

  


  return (
    <div className="container-card" style={{backgroundImage:`url(${people.gif})`}}  >
      <Link id='return-home' to='/recommand'><img src="https://res.cloudinary.com/dvr397xda/image/upload/v1664834364/cube/Free/metalogo_ctuhtw.png" alt="" /></Link>

      
<Share onClick={()=>handleShare()} id='share-btn'/>


      {/* <Helmet helmetData={helmetData}>
  <title>{helmetData.name}</title>
 

</Helmet> */}
     <Helmet
      title={people.name}
      meta={[
        {
          property:'og:image',
          content:people.images[4]
        },
        {
          name: `description`,
          content: people.title,
        },
      ]}
    
    />
    { <Canvas camera={{ position: [0, 0, 5] }}   gl={{ alpha: true, antialias: true,sortObjects:false}}   className='canvas-wrap'>
   
    <ambientLight/>

  <PresentationControls polar={[-Math.PI / 2.5, Math.PI / 2.5]} global rotation={[0, 0.3, 0]}  azimuth={[-Infinity, Infinity]}>
      <Suspense fallback={<Loader people={people} />}>

      <Group visible={visible} posY={posY} people={people} opacity={opacity} />
      <Box2/>
    </Suspense>
    </PresentationControls>
    </Canvas>}
  
         

          <div style={load ?{ zIndex:'100000',opacity:'1'} :{zIndex:'-10',opacity:'0',transition:'2.5s' }} className='load-image'>
                <img src={people.loadImg} alt="" />
          </div>

     


    

    </div>
  )
}

