import React,{useState,useRef,Suspense,}from 'react'
import { Canvas, useFrame,extend,useThree,} from '@react-three/fiber'
import * as THREE from "three";

import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";

import {useProgress,Html} from '@react-three/drei'
import {useParams} from 'react-router-dom'
import fetchVideo from '../../video'

import './index.scss'

const data= fetchVideo()

function Loader() {
    const { progress } = useProgress()
    return <Html center> <h2 id='loadText'>
          Loading ...{progress.toFixed(0)}%
          <img id='loadImage' src="https://res.cloudinary.com/dvr397xda/image/upload/v1657653955/cube/company/David/delivery_dgsqn3.gif" alt="" />
          </h2></Html>
  }


extend({ OrbitControls });

const CameraControls = () => {

  const {
    camera,
    gl: { domElement },
  } = useThree();
  const controls = useRef();
  useFrame(() => controls.current.update());
  return (
    <orbitControls
      ref={controls}
      args={[camera, domElement]}
      autoRotate={false}
      enableZoom={true}
      maxDistance={5.5}
      minDistance={2}
    />
  );
};


export default function Video() {

    const {id} = useParams();

    const [isPlay,setIsPlay]= useState(false)
    const [texture,setTexture] = useState()

   
  

    const handleClick = ()=>{
        const url = data[id]
 
        const video = document.createElement('video')
        video.src=url
        video.crossOrigin = 'Anonymous';
        video.loop=true;
        video.muted=false;
        video.play()
    
        const temp = new THREE.VideoTexture( video );
        setTexture(temp)
        setIsPlay(true)



    }


 

  return (
    <div className="container-card2"  >
     
     <Canvas  gl={{ alpha: true, antialias: true}}  className='canvas-wrap'>
    <CameraControls />
        {/* <pointLight position={[3, 3, 3]} intensity={1} color='#f0f0f0'/>
        <pointLight position={[3, -3, 3]} intensity={1} color='#f0f0f0'/>

        <pointLight position={[-3, 3, 3]} intensity={1} color='#f0f0f0'/>

        <pointLight position={[3, 3, -3]} intensity={1} color='#f0f0f0'/>
        <spotLight  position={[0, 0, 0]} intensity={1} color='#f0f0f0'/> */}
    <ambientLight intensity={1} />

      <Suspense fallback={<Loader/>}>
   

        
    {isPlay&&<mesh>
    
        <sphereGeometry args={[100,16,16]} />
        <meshStandardMaterial map={texture} side={THREE.DoubleSide}  />
    
    </mesh>}
    
       
  
   
       

    
    </Suspense>

    </Canvas>
   
         

   { !isPlay&&<img onClick={()=>handleClick()} id='play-btn' src="https://res.cloudinary.com/dvr397xda/image/upload/v1654804804/cube/play-button_1_k5h0a8.png" alt="" />}
       

    </div>
  )
}

