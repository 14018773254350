import React,{useState,useEffect,useRef} from 'react'

import Star from './components/Star/index'
import StarFront from './components/StarFront/index'
import Shine from './components/Shine/index'
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { Canvas, useFrame,extend,useThree,} from '@react-three/fiber'
import Select from "react-select";

import './index.scss'


extend({ OrbitControls });

const CameraControls = () => {

  const {
    camera,
    gl: { domElement },
  } = useThree();
  const controls = useRef();
  useFrame(() => controls.current.update());
  return (
    <orbitControls
      ref={controls}
      args={[camera, domElement]}
      autoRotate={false}
      enableZoom={true}
      maxDistance={5.5}
      minDistance={2}
    />
  );
};


export default function LandingPage() {

    


    const XINGTIAN = useRef();



   


    return (
        <div className='container'>



       
        <div className='container' ref={XINGTIAN}>

          
        

            <Star className='star-container'/>
           

            <div  className='logo'>
                <img  src="https://res.cloudinary.com/dvr397xda/image/upload/v1658784028/cube/WechatIMG25_i55f4k_1_zqpfac.png" alt=""/>
            </div>
            
            <StarFront className='starFront'/>
         

            <div className='bot-bar'>
                <img  src="https://res.cloudinary.com/dlapk94rx/image/upload/v1609885844/%E7%BB%84%E4%BB%B6_3_1_fbw5pn.png" alt=""/>
            </div>
           
         
            <div className='left'>
                <img src="https://res.cloudinary.com/dlapk94rx/image/upload/v1609885844/%E5%B7%A6%E8%BE%B9%E6%A1%86_hsmytd.png" alt=""/>
            </div>
            <div className='right'>
                <img src="https://res.cloudinary.com/dlapk94rx/image/upload/v1609885844/%E5%8F%B3%E8%BE%B9%E6%A1%86_fotbgq.png" alt=""/>
            </div>
        
        <Shine />
   
      
       
        <div className='xingtian'>
            <img src="https://res.cloudinary.com/dvr397xda/image/upload/v1659534440/cube/WechatIMG9666_auksgf.png" alt=""/>
        </div>

        </div>
  
        <Canvas  gl={{ alpha: true, antialias: true}}  id='space'>
        <CameraControls />
        <ambientLight intensity={1} />

           
            <mesh position={[0,0,0]}>
            <boxBufferGeometry  attach="geometry"  args={[2,2,2]}/>
        <meshStandardMaterial    color='red'   />
            </mesh>            
      </Canvas>

        </div>
    )
}

