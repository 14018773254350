import React,{useState,useEffect,useRef,Suspense,useCallback}from 'react'
import { Canvas, useFrame,extend,useThree,useLoader,} from '@react-three/fiber'
import * as THREE from "three";

import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { TextureLoader } from 'three/src/loaders/TextureLoader.js';
import {useFBX,useProgress,Html} from '@react-three/drei'

import './index.scss'

function Loader() {
    const { progress } = useProgress()
    return <Html center> <h2 id='loadText'>
          Loading ...{progress.toFixed(0)}%
          <img id='loadImage' src="https://res.cloudinary.com/dvr397xda/image/upload/v1657653955/cube/company/David/delivery_dgsqn3.gif" alt="" />
          </h2></Html>
  }

function Model({position}){

    const mesh = useRef()

    let Car = useFBX('https://res.cloudinary.com/dvr397xda/raw/upload/v1657652136/cube/company/David/Loader_car_qztak1.fbx')
    const [colorMap, metalMap, normalMap, roughnessMap, aoMap] = useLoader(THREE.TextureLoader, [
      'https://res.cloudinary.com/dvr397xda/image/upload/v1657652143/cube/company/David/Loader_car_Material_Base_Color_mprppp.png',
      'https://res.cloudinary.com/dvr397xda/image/upload/v1657652245/cube/company/David/Loader_car_Material_Metallic_ejtnrb.png',
      'https://res.cloudinary.com/dvr397xda/image/upload/v1657652245/cube/company/David/Loader_car_Material_Normal_OpenGL1_q3qyvm.png',
      'https://res.cloudinary.com/dvr397xda/image/upload/v1657652245/cube/company/David/Loader_car_Material_Roughness_n1ts2p.png',
      'https://res.cloudinary.com/dvr397xda/image/upload/v1657652245/cube/company/David/Loader_car_Material_Mixed_AO1_cwjeef.png',
    ])


    Car.traverse( function ( child ) {

        if ( child.isMesh ) {
      
          console.log( child.material );
          child.material.map = colorMap;
          child.material.aoMap =aoMap;
          child.material.normalMap = normalMap;
          child.material.metalMap = metalMap; 
          child.material.roughnessMap = roughnessMap; 
        }
      
      } );

      useFrame((state, delta) => {
      
    
        mesh.current.rotation.y += 0.005
      
      })

      return (
        <mesh scale={0.006} position={position}  ref={mesh}>
            <primitive scale={0.8}  object={Car} />
    
        </mesh>
      )

}





const  Cube = ({position})=>{

  const image = 'https://res.cloudinary.com/dvr397xda/image/upload/v1653324529/cube/%E8%BE%B9%E6%A1%862_b3lfk1.png'
  const mesh = useRef()
  const map = useLoader(THREE.TextureLoader,image)
  useFrame((state, delta) => {
    mesh.current.scale.x = 1 + (1 + Math.sin(state.clock.elapsedTime * 1)) * 0.03
    mesh.current.scale.y = 1 + (1 + Math.sin(state.clock.elapsedTime * 1)) * 0.03
    mesh.current.scale.z = 1 + (1 + Math.sin(state.clock.elapsedTime * 1)) * 0.03
  })

  return (
    <>
    <mesh position={position} ref={mesh}>
        <boxBufferGeometry  attach="geometry"  args={[2.2, 2.2, 2.2]}/>
        <meshStandardMaterial transparent={true} opacity={1}  attach="material" map={map}  roughness={0.1}
         />

       


    </mesh>
    </>
  )
}


function Box(props) {
  const {people,position} = props
  const mesh = useRef()
  const [index,setIndex] = useState(0)

 

  useEffect(() => {
    const interval = setInterval(() => {

        if(index ===people.show.length-1){
            setIndex(0)
        }
        else{
            setIndex(index+1)
        }
    }, 2500);
    return () => clearInterval(interval);
  }, [index,people]);
  // setInterval(handleChangeIndex,1000)

  let textures;

textures = useLoader(THREE.TextureLoader,[people.images[0],people.images[1],people.images[2],people.images[3],people.images[4],people.images[5]])
// textures = useLoader(TextureLoader,[people.images[0],people.images[1],people.images[2],people.images[3],people.images[4],people.images[5]])

textures.minFilter = textures.magFilter = THREE.LinearFilter;



textures.format= THREE.RGBAFormat;




  function Map (){
    return(
    
      textures.map((texture, idx) =>(
        idx ===2||idx===3 || idx===0||idx===1 ?<meshBasicMaterial key={idx} attach={`material-${idx}`} map={texture}   transparent={true} opacity={0.2}/>
        :<meshBasicMaterial opacity={1} transparent={true}  key={idx} attach={`material-${idx}`} map={texture}  />
        )
)
    )
  } 

  return (
    <>
    <mesh
      onClick={(e)=>{
        e.stopPropagation()
      }}
      ref={mesh}
     position={position}
     
      >
          
      <boxBufferGeometry attach="geometry"  args={[2, 2, 2]}/>
        <Map/>
       
      

    </mesh>


    </>
  )
}

extend({ OrbitControls });

const CameraControls = () => {

  const {
    camera,
    gl: { domElement },
  } = useThree();
  const controls = useRef();
  useFrame(() => controls.current.update());
  return (
    <orbitControls
      ref={controls}
      args={[camera, domElement]}
      autoRotate={false}
      enableZoom={true}
      maxDistance={5.5}
      minDistance={2}
    />
  );
};

const Phone = ({people,opacity,posY})=>{

  const mesh1 = useRef()


  const handleClick=()=>{
    window.open(`tel:+1${people.tel}`,'_self')
  }


  return (
    <>
    <mesh position={[0,posY-0.8,1.2]} rotation={[0,Math.PI * 2.5,0]} onClick={(e)=>{ handleClick();     e.stopPropagation()
}}   ref={mesh1}>
        <boxBufferGeometry  attach="geometry"  args={[0.1,0.5,2]}/>
        <meshStandardMaterial transparent={true} opacity={opacity}   color='green'  />

       


    </mesh>
    </>
  )
}


const Email = ({people,opacity,posY})=>{
 
  const mesh1 = useRef()
 

  const handleClick=()=>{
    console.log(1);
    window.open(`mailto:+${people.email}`,'_self')
  }

  return (
    <>
    <mesh position={[0,posY-1.3,1.2]} rotation={[0,Math.PI * 2.5,0]} onClick={(e)=>{ handleClick();     e.stopPropagation()
}}   ref={mesh1}>
        <boxBufferGeometry  attach="geometry"  args={[0.1,0.45,2]}/>
        <meshStandardMaterial transparent={true} opacity={opacity}  color='#fff' />

    </mesh>
    </>
  )
}

const Map = ({people,opacity,posY})=>{
  const image = 'https://res.cloudinary.com/dvr397xda/image/upload/v1650920511/cube/%E7%8E%BB%E7%92%832_ukdjz2.jpg'
  const mesh1 = useRef()
  const map = useLoader(TextureLoader,image)

  const handleClick=()=>{
    console.log(1);
    window.open(`${people.map}`,'_self')
  }

  return (
    <>
    <mesh position={[0,-1.75+posY,1.2]} rotation={[0,Math.PI * 2.5,0]} onClick={(e)=>{ handleClick();     e.stopPropagation()
}}   ref={mesh1}>
        <boxBufferGeometry  attach="geometry"  args={[0.1,0.5,2]}/>
        <meshStandardMaterial transparent={true} opacity={opacity}   color='blue'   />

       


    </mesh>
    </>
  )
}

const Youtube = ({people,opacity,posY})=>{
  
    const mesh1 = useRef()
  
  
    const handleClick=()=>{
      console.log(1);
      window.open(`${people.video}`,'_self')
    }
  
    return (
      <>
      <mesh rotation={[0, Math.PI * 2.5, 0]} position={[0,0,-1.2]} onClick={(e)=>{ handleClick();     e.stopPropagation()
  }}   ref={mesh1}>
          <boxBufferGeometry  attach="geometry"  args={[0.1,2,2]}/>
          <meshStandardMaterial transparent={true} opacity={opacity}  attach="material" color='green'  roughness={0.7}
          metalness={0.1} />
  
         
  
  
      </mesh>
      </>
    )
  }






export default function David() {
  const mesh2 = useRef()

  const people = { 
    id:'david',
    name:'David Jiang',
    tel:'6462071303',
    cell:'2034569888',
    email:'0708jiang@gmail.com',
    address:'15 Tarkett Dr Cornwall, NY 12553 ',
    map:'https://goo.gl/maps/oAEVy8jD7LjqfPxN7',
    video:'https://youtube.com/shorts/y4_ltkFpeR4',
    facebook:'https://www.facebook.com/johnny.chen.792197',
    background:'https://res.cloudinary.com/dvr397xda/image/upload/v1653927826/cube/1921iq6-a-room-that-has-a-umbrella-in-her-hand_rainyw.jpg',
    linkedin:'https://www.linkedin.com/in/johnny-chen-358b971bb/',
    avatar:'https://res.cloudinary.com/dvr397xda/image/upload/v1654294846/cube/weiiz_iojznp.png',
    website:'https://www.weiiz.com/',
    images:[
        'https://res.cloudinary.com/dvr397xda/image/upload/v1657213417/cube/company/WechatIMG8880_dk6chp.jpg', //right
        'https://res.cloudinary.com/dvr397xda/image/upload/v1657213417/cube/company/WechatIMG8880_dk6chp.jpg', //left
        'https://res.cloudinary.com/dvr397xda/image/upload/v1657213417/cube/company/WechatIMG8880_dk6chp.jpg', //glass
        'https://res.cloudinary.com/dvr397xda/image/upload/v1657213417/cube/company/WechatIMG8880_dk6chp.jpg', //glass
        'https://res.cloudinary.com/dvr397xda/image/upload/v1657645206/cube/WechatIMG8972_vixopm.png', //front
        'https://res.cloudinary.com/dvr397xda/image/upload/v1657645206/cube/WechatIMG8973_cpdf2u.png'//back
],
show:[
    {
        name:'恒星国际商业智库',
        title:'团队介绍',
        image:'https://res.cloudinary.com/dvr397xda/image/upload/v1656102436/cube/%E6%99%BA%E5%BA%93_%E8%93%9D%E8%89%B2%E5%9C%86%E8%A7%92_logo_ayowz4.png'
    },
    {
        name:'Johnny Chen',
        title:'Founder Ceo',
        image:'https://res.cloudinary.com/dvr397xda/image/upload/v1655150127/cube/WechatIMG8392_lfrxly.jpg'
    },
    {
        name:'Star',
        title:'UI Designer',
        image:'https://res.cloudinary.com/dvr397xda/image/upload/v1655150127/cube/WechatIMG8395_dfgfjt.jpg'
    },
    {
        name:'Hao',
        title:'UI Designer',
        image:'https://res.cloudinary.com/dvr397xda/image/upload/v1655150127/cube/WechatIMG8398_kx1bny.jpg'
    },
    {
        name:'David',
        title:'Marketing',
        image:'https://res.cloudinary.com/dvr397xda/image/upload/v1655150127/cube/WechatIMG8393_n2lckn.jpg'
    },
    {
        name:'Lily',
        title:'Marketing',
        image:'https://res.cloudinary.com/dvr397xda/image/upload/v1656085726/cube/WechatIMG8679_w8kk3r.jpg'
    },
    {
        name:'恒星国际商业智库',
        title:'欢迎您的到来',
        image:'https://res.cloudinary.com/dvr397xda/image/upload/v1656102436/cube/%E6%99%BA%E5%BA%93_%E8%93%9D%E8%89%B2%E5%9C%86%E8%A7%92_logo_ayowz4.png'
    },

],
gif:'https://res.cloudinary.com/dvr397xda/image/upload/v1657653207/cube/company/David/future-trailer_bkda90.gif',
loadImg:'https://res.cloudinary.com/dvr397xda/image/upload/v1656958563/cube/tenor_2_ngzbpe.gif',
qrCode:'https://res.cloudinary.com/dvr397xda/image/upload/v1657220590/cube/company/WechatIMG8887_alcgdr.png'

}

  const [isTeam,setIsTeam] = useState(false)
  const [isQR,setIsQR] = useState(false)
  const opacity=0
  const posY = 1

  const [load,setLoad] = useState(false)

  const handleOpenQr = ()=>{
      setIsQR(true)
  }




  const handleClickTeam = ()=>{
    setIsTeam(true)
  }
  const handleCloseTeam = ()=>{
    setIsTeam(false)
    setIsQR(false)
  }
  
  useEffect(() => {

    const timer = setTimeout(()=>{
      setLoad(false)
    },2000)
    return ()=>clearTimeout(timer)
  },[])

  return (
    <div className="container-card" style={{backgroundImage:`url(${people.gif})`}}  >
     
     <Canvas  gl={{ alpha: true, antialias: true}}  className='canvas-wrap'>
    <CameraControls />
        {/* <pointLight position={[3, 3, 3]} intensity={1} color='#f0f0f0'/>
        <pointLight position={[3, -3, 3]} intensity={1} color='#f0f0f0'/>

        <pointLight position={[-3, 3, 3]} intensity={1} color='#f0f0f0'/>

        <pointLight position={[3, 3, -3]} intensity={1} color='#f0f0f0'/>
        <spotLight  position={[0, 0, 0]} intensity={1} color='#f0f0f0'/> */}
  <ambientLight intensity={1} />

      <Suspense fallback={<Loader/>}>
   

        
      <Box people={people}   position={[0, 0, 0]} />
    

      <Cube position={[0, 0, 0]} />
      <Model position={[0, -0.5, 0]}/>
      

      <Phone posY={posY} people={people} opacity={opacity}/>
      <Email posY={posY} people={people} opacity={opacity}/>
      {/* <Cell posY={posY} people={people} opacity={opacity}/> */}

      {/* <QR handleOpenQr={handleOpenQr} posY={posY} people={people} opacity={opacity}/> */}
      <Map posY={posY} people={people} opacity={opacity}/>
      {/* <Web posY={posY} people={people} opacity={opacity}/> */}
      <Youtube posY={posY} people={people} opacity={opacity}/>


      {/* <VideoBtn people={people} opacity={opacity}/> */}

      {/* <mesh rotation={[0, Math.PI * 2.5, 0]} position={[0,0,-1.2]} onClick={(e)=>{ handleClickTeam();     e.stopPropagation()
}}   ref={mesh2}>
        <boxBufferGeometry  attach="geometry"  args={[0.1,1,1]}/>
        <meshStandardMaterial transparent={true} opacity={opacity}    color='red'   />

       
    </mesh> */}
    
    </Suspense>

    </Canvas>
   
         

      


    </div>
  )
}

