import * as THREE from 'three'
import { useMemo, Suspense } from 'react'
import { Canvas, applyProps } from '@react-three/fiber'
import { Environment, Lightformer, Float, useGLTF, BakeShadows, ContactShadows,useProgress,Html,OrbitControls } from '@react-three/drei'
import { LayerMaterial, Color, Depth } from 'lamina'

export default function Porsche(){

    return (

  <Canvas shadows dpr={[1, 2]} camera={{ position: [-10, 0, 15], fov: 30 }}>
     
     <Suspense fallback={<Loader />}>
    <PorscheModel scale={1.6} position={[-0.5, -0.18, 0]} rotation={[0, Math.PI / 5, 0]} />
    
    <spotLight position={[0, 15, 0]} angle={0.3} penumbra={1} castShadow intensity={2} shadow-bias={-0.0001} />
    <ambientLight intensity={0.2} />
    <ContactShadows resolution={1024} frames={1} position={[0, -1.16, 0]} scale={10} blur={3} opacity={1} far={10} />

    {/* Renders contents "live" into a HDRI environment (scene.environment). */}
    <Environment frames={Infinity} resolution={256}>
      {/* Ceiling */}
      <Lightformer intensity={0.75} rotation-x={Math.PI / 2} position={[0, 5, -9]} scale={[10, 10, 1]} />
     
      {/* Sides */}
      <Lightformer intensity={4} rotation-y={Math.PI / 2} position={[-5, 1, -1]} scale={[20, 0.1, 1]} />
      <Lightformer rotation-y={Math.PI / 2} position={[-5, -1, -1]} scale={[20, 0.5, 1]} />
      <Lightformer rotation-y={-Math.PI / 2} position={[10, 1, 0]} scale={[20, 1, 1]} />
      {/* Accent (red) */}
      <Float speed={5} floatIntensity={2} rotationIntensity={2}>
        <Lightformer form="ring" color="red" intensity={1} scale={10} position={[-15, 4, -18]} target={[0, 0, 0]} />
      </Float>
      {/* Background */}
      <mesh scale={100}>
        <sphereGeometry args={[1, 64, 64]} />
        <LayerMaterial side={THREE.BackSide}>
          <Color color="#444" alpha={1} mode="normal" />
          <Depth colorA="blue" colorB="black" alpha={0.5} mode="normal" near={0} far={300} origin={[100, 100, 100]} />
        </LayerMaterial>
      </mesh>
    </Environment>
    </Suspense>
    <BakeShadows />
    <OrbitControls  />

  </Canvas>

    )
}

function PorscheModel(props) {
  const { scene, nodes, materials } = useGLTF('https://res.cloudinary.com/dvr397xda/image/upload/v1656533858/cube/museum/911-transformed_clzfcz.glb')
  useMemo(() => {
    Object.values(nodes).forEach((node) => node.isMesh && (node.receiveShadow = node.castShadow = true))
    applyProps(materials.rubber, { color: '#222', roughness: 0.6, roughnessMap: null, normalScale: [4, 4] })
    applyProps(materials.window, { color: 'black', roughness: 0, clearcoat: 0.1 })
    applyProps(materials.coat, { envMapIntensity: 4, roughness: 0.5, metalness: 1 })
    applyProps(materials.paint, { roughness: 0.5, metalness: 0.8, color: '#555', envMapIntensity: 2 })
  }, [nodes, materials])
  return <primitive object={scene} {...props} />
}



// useGLTF.preload('https://res.cloudinary.com/dvr397xda/image/upload/v1656533858/cube/museum/911-transformed_clzfcz.glb')

function Loader() {
    const { progress } = useProgress()
    return <Html center>Loading ...{progress.toFixed(0)}%</Html>
  }