import React,{useState,useEffect,useRef,Suspense,useCallback}from 'react'
import { Canvas, useFrame,extend,useThree,useLoader,} from '@react-three/fiber'
import * as THREE from "three";
import HTMLFlipBook from "react-pageflip";

import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { TextureLoader } from 'three/src/loaders/TextureLoader.js';
import {useGLTF} from '@react-three/drei'

import './index.scss'


function Model({ position }){
    const mesh = useRef()

    const { nodes, materials } = useGLTF('https://res.cloudinary.com/dvr397xda/image/upload/v1657212110/cube/museum/mac-draco_cvwbu1.glb')
 
    useFrame((state) => {
        mesh.current.rotation.y += 0.01
    
    })
    // Events and spring animations were added afterwards
    return (
      <group
        ref={mesh}
        position={position}
        scale={0.13}
        >
       
          <group position={[0, 2.96, 0]} rotation={[Math.PI *2.5, 0, 0]}>
            <mesh material={materials.aluminium} geometry={nodes['Cube008'].geometry} />
            <mesh material={materials['matte.001']} geometry={nodes['Cube008_1'].geometry} />
            <mesh material={materials['screen.001']} geometry={nodes['Cube008_2'].geometry} />
          </group>
      
        <mesh material={materials.keys} geometry={nodes.keyboard.geometry} position={[1.79, 0, 3.45]} />
        <group position={[0, -0.1, 3.39]}>
          <mesh material={materials.aluminium} geometry={nodes['Cube002'].geometry} />
          <mesh material={materials.trackpad} geometry={nodes['Cube002_1'].geometry} />
        </group>
        <mesh material={materials.touchbar} geometry={nodes.touchbar.geometry} position={[0, -0.03, 1.2]} />
      </group>
    )
  }



const  Cube = ({position})=>{

  const image = 'https://res.cloudinary.com/dvr397xda/image/upload/v1653324529/cube/%E8%BE%B9%E6%A1%862_b3lfk1.png'
  const mesh = useRef()
  const map = useLoader(THREE.TextureLoader,image)
  useFrame((state, delta) => {
    mesh.current.scale.x = 1 + (1 + Math.sin(state.clock.elapsedTime * 1)) * 0.03
    mesh.current.scale.y = 1 + (1 + Math.sin(state.clock.elapsedTime * 1)) * 0.03
    mesh.current.scale.z = 1 + (1 + Math.sin(state.clock.elapsedTime * 1)) * 0.03
  })

  return (
    <>
    <mesh position={position} ref={mesh}>
        <boxBufferGeometry  attach="geometry"  args={[2.2, 2.2, 2.2]}/>
        <meshStandardMaterial transparent={true} opacity={1}  attach="material" map={map}  roughness={0.1}
         />

       


    </mesh>
    </>
  )
}

function Book({people}){



  const onFlip = useCallback((e) => {
    console.log('Current page: ' + e.data);
}, []);

  return (


 
  <HTMLFlipBook className="wrapper" 
  size='stretch'  width={195}  height={338}        maxShadowOpacity={0.5}
  onFlip={onFlip}
  showCover
 >
  
  {
      people.show&&people.show.map((v,i)=>(
          <div id='page-item' className="demoPage" key={i}>
            
            <h2>
                    {v.name}
                </h2>
                {v.title&&<h2>
                    {v.title}
                </h2>}
               
             <img src={v.image} alt="" />
               
          </div>
      ))
  }
</HTMLFlipBook>

)
}


function Box(props) {
  const {people,position} = props
  const mesh = useRef()
  const [index,setIndex] = useState(0)

 

  useEffect(() => {
    const interval = setInterval(() => {

        if(index ===people.show.length-1){
            setIndex(0)
        }
        else{
            setIndex(index+1)
        }
    }, 2500);
    return () => clearInterval(interval);
  }, [index,people]);
  // setInterval(handleChangeIndex,1000)

  let textures;

textures = useLoader(THREE.TextureLoader,[people.images[0],people.images[1],people.images[2],people.images[3],people.images[4],people.images[5]])
// textures = useLoader(TextureLoader,[people.images[0],people.images[1],people.images[2],people.images[3],people.images[4],people.images[5]])

textures.minFilter = textures.magFilter = THREE.LinearFilter;



textures.format= THREE.RGBAFormat;




  function Map (){
    return(
    
      textures.map((texture, idx) =>(
        idx ===2||idx===3 || idx===0?<meshBasicMaterial key={idx} attach={`material-${idx}`} map={texture}   transparent={true} opacity={0.2}/>
        :<meshBasicMaterial opacity={1} transparent={true}  key={idx} attach={`material-${idx}`} map={texture}  />
        )
)
    )
  } 

  return (
    <>
    <mesh
      onClick={(e)=>{
        e.stopPropagation()
      }}
      ref={mesh}
     position={position}
     
      >
          
      <boxBufferGeometry attach="geometry"  args={[2, 2, 2]}/>
        <Map/>
       
      

    </mesh>


    </>
  )
}

extend({ OrbitControls });

const CameraControls = () => {

  const {
    camera,
    gl: { domElement },
  } = useThree();
  const controls = useRef();
  useFrame(() => controls.current.update());
  return (
    <orbitControls
      ref={controls}
      args={[camera, domElement]}
      autoRotate={false}
      enableZoom={true}
      maxDistance={5.5}
      minDistance={2}
    />
  );
};

const Phone = ({people,opacity,posY})=>{
  const image = 'https://res.cloudinary.com/dvr397xda/image/upload/v1650920511/cube/%E7%8E%BB%E7%92%832_ukdjz2.jpg'
  const mesh1 = useRef()
  const map = useLoader(TextureLoader,image)

  const handleClick=()=>{
    window.open(`tel:+1${people.tel}`,'_self')
  }


  return (
    <>
    <mesh position={[0,posY-1.2,1.2]} rotation={[0,Math.PI * 2.5,0]} onClick={(e)=>{ handleClick();     e.stopPropagation()
}}   ref={mesh1}>
        <boxBufferGeometry  attach="geometry"  args={[0.1,0.5,2]}/>
        <meshStandardMaterial transparent={true} opacity={opacity}   color='green'  />

       


    </mesh>
    </>
  )
}

const Cell = ({people,opacity,posY})=>{
    const image = 'https://res.cloudinary.com/dvr397xda/image/upload/v1650920511/cube/%E7%8E%BB%E7%92%832_ukdjz2.jpg'
    const mesh1 = useRef()
 
  
    const handleClick=()=>{
      window.open(`tel:+1${people.cell}`,'_self')
    }
  
  
    return (
      <>
      <mesh position={[0,posY-0.7,1.2]} rotation={[0,Math.PI * 2.5,0]} onClick={(e)=>{ handleClick();     e.stopPropagation()
  }}   ref={mesh1}>
          <boxBufferGeometry  attach="geometry"  args={[0.1,0.5,2]}/>
          <meshStandardMaterial transparent={true} opacity={opacity}  color='red' />
  
         
  
  
      </mesh>
      </>
    )
  }

const Email = ({people,opacity,posY})=>{
  const image = 'https://res.cloudinary.com/dvr397xda/image/upload/v1650920511/cube/%E7%8E%BB%E7%92%832_ukdjz2.jpg'
  const mesh1 = useRef()
 

  const handleClick=()=>{
    console.log(1);
    window.open(`mailto:+${people.email}`,'_self')
  }

  return (
    <>
    <mesh position={[0,posY-1.7,1.2]} rotation={[0,Math.PI * 2.5,0]} onClick={(e)=>{ handleClick();     e.stopPropagation()
}}   ref={mesh1}>
        <boxBufferGeometry  attach="geometry"  args={[0.1,0.5,2]}/>
        <meshStandardMaterial transparent={true} opacity={opacity}  color='#fff' />

       


    </mesh>
    </>
  )
}

const Map = ({people,opacity,posY})=>{
  const image = 'https://res.cloudinary.com/dvr397xda/image/upload/v1650920511/cube/%E7%8E%BB%E7%92%832_ukdjz2.jpg'
  const mesh1 = useRef()
  const map = useLoader(TextureLoader,image)

  const handleClick=()=>{
    console.log(1);
    window.open(`${people.map}`,'_self')
  }

  return (
    <>
    <mesh position={[-1.2,-1.5+posY,-0.5]} onClick={(e)=>{ handleClick();     e.stopPropagation()
}}   ref={mesh1}>
        <boxBufferGeometry  attach="geometry"  args={[0.1,1,1]}/>
        <meshStandardMaterial transparent={true} opacity={opacity}   color='blue'   />

       


    </mesh>
    </>
  )
}

const Youtube = ({people,opacity,posY})=>{
    const image = 'https://res.cloudinary.com/dvr397xda/image/upload/v1650920511/cube/%E7%8E%BB%E7%92%832_ukdjz2.jpg'
    const mesh1 = useRef()
    const map = useLoader(TextureLoader,image)
  
    const handleClick=()=>{
      console.log(1);
      window.open(`${people.video}`,'_self')
    }
  
    return (
      <>
      <mesh position={[-1.2,-1.5+posY,0.5]} onClick={(e)=>{ handleClick();     e.stopPropagation()
  }}   ref={mesh1}>
          <boxBufferGeometry  attach="geometry"  args={[0.1,1,1]}/>
          <meshStandardMaterial transparent={true} opacity={opacity}  attach="material" color='green'  roughness={0.7}
          metalness={0.1} />
  
         
  
  
      </mesh>
      </>
    )
  }

  const QR = ({opacity,posY,handleOpenQr})=>{
  
    const mesh1 = useRef()
  
  
  
    return (
      <>
      <mesh position={[-1.2,-0.5+posY,-0.5]} onClick={(e)=>{  handleOpenQr();     e.stopPropagation()
  }}   ref={mesh1}>
          <boxBufferGeometry  attach="geometry"  args={[0.1,1,1]}/>
          <meshStandardMaterial transparent={true} opacity={opacity}   color='green'   />
  
         
  
  
      </mesh>
      </>
    )
  }



const Web = ({people,opacity,posY})=>{
 
  const mesh1 = useRef()

  const handleClick=()=>{
    window.open(`${people.website}`,'_self')
  }

  return (
    <>
    <mesh rotation={[0, 0, 0]} position={[-1.2,-0.5+posY,0.5]} onClick={(e)=>{ handleClick();     e.stopPropagation()
}}   ref={mesh1}>
        <boxBufferGeometry  attach="geometry"  args={[0.1,1,1]}/>
        <meshStandardMaterial transparent={true} opacity={opacity}   color='#fff'   />

       


    </mesh>
    </>
  )
}


export default function Huoli() {
  const mesh2 = useRef()

  const people = { 
    id:'johnny',
    name:'Johnny Chen',
    tel:'8453036348',
    cell:'2034569888',
    email:'Johnnychen@nicyg.com',
    address:'4306 Main St,Flushing,NY,11355',
    map:'https://www.google.com/maps/place/43-06+Main+St,+Flushing,+NY+11355/@40.7522664,-73.8315613,17z/data=!3m1!4b1!4m5!3m4!1s0x89c2606cd640cb53:0x141db878e9815fe!8m2!3d40.7522665!4d-73.8270766',
    video:'https://www.youtube.com/watch?v=DYb8dudXY9U&list=PLid5kTu-xpFIg5gLDt17SblFa_fCfvwmT',
    facebook:'https://www.facebook.com/johnny.chen.792197',
    background:'https://res.cloudinary.com/dvr397xda/image/upload/v1653927826/cube/1921iq6-a-room-that-has-a-umbrella-in-her-hand_rainyw.jpg',
    linkedin:'https://www.linkedin.com/in/johnny-chen-358b971bb/',
    avatar:'https://res.cloudinary.com/dvr397xda/image/upload/v1654543127/cube/WechatIMG291_g0cny7.jpg',
    website:'https://hwoly.com',
    images:[
        'https://res.cloudinary.com/dvr397xda/image/upload/v1657213417/cube/company/WechatIMG8880_dk6chp.jpg', //right
        'https://res.cloudinary.com/dvr397xda/image/upload/v1657213802/cube/company/WechatIMG8885_wawdpe.jpg', //left
        'https://res.cloudinary.com/dvr397xda/image/upload/v1657213417/cube/company/WechatIMG8880_dk6chp.jpg', //glass
        'https://res.cloudinary.com/dvr397xda/image/upload/v1657213417/cube/company/WechatIMG8880_dk6chp.jpg', //glass
        'https://res.cloudinary.com/dvr397xda/image/upload/v1657213417/cube/company/WechatIMG8882_p5nnua.jpg', //front
        'https://res.cloudinary.com/dvr397xda/image/upload/v1657321666/cube/company/WechatIMG8934_io6h4d.png'//back
],
show:[
    {
        name:'恒星国际商业智库',
        title:'团队介绍',
        image:'https://res.cloudinary.com/dvr397xda/image/upload/v1656102436/cube/%E6%99%BA%E5%BA%93_%E8%93%9D%E8%89%B2%E5%9C%86%E8%A7%92_logo_ayowz4.png'
    },
    {
        name:'Johnny Chen',
        title:'Founder Ceo',
        image:'https://res.cloudinary.com/dvr397xda/image/upload/v1655150127/cube/WechatIMG8392_lfrxly.jpg'
    },
    {
        name:'Star',
        title:'UI Designer',
        image:'https://res.cloudinary.com/dvr397xda/image/upload/v1655150127/cube/WechatIMG8395_dfgfjt.jpg'
    },
    {
        name:'Hao',
        title:'UI Designer',
        image:'https://res.cloudinary.com/dvr397xda/image/upload/v1655150127/cube/WechatIMG8398_kx1bny.jpg'
    },
    {
        name:'David',
        title:'Marketing',
        image:'https://res.cloudinary.com/dvr397xda/image/upload/v1655150127/cube/WechatIMG8393_n2lckn.jpg'
    },
    {
        name:'Lily',
        title:'Marketing',
        image:'https://res.cloudinary.com/dvr397xda/image/upload/v1656085726/cube/WechatIMG8679_w8kk3r.jpg'
    },
    {
        name:'恒星国际商业智库',
        title:'欢迎您的到来',
        image:'https://res.cloudinary.com/dvr397xda/image/upload/v1656102436/cube/%E6%99%BA%E5%BA%93_%E8%93%9D%E8%89%B2%E5%9C%86%E8%A7%92_logo_ayowz4.png'
    },

],
gif:'https://res.cloudinary.com/dvr397xda/image/upload/v1650903783/cube/ezgif.com-gif-maker_b2xoms.gif',
loadImg:'https://res.cloudinary.com/dvr397xda/image/upload/v1656958563/cube/tenor_2_ngzbpe.gif',
qrCode:'https://res.cloudinary.com/dvr397xda/image/upload/v1657220590/cube/company/WechatIMG8887_alcgdr.png'

}

  const [isTeam,setIsTeam] = useState(false)
  const [isQR,setIsQR] = useState(false)
  const opacity=0
  const posY = 1

  const [load,setLoad] = useState(false)

  const handleOpenQr = ()=>{
      setIsQR(true)
  }




  const handleClickTeam = ()=>{
    setIsTeam(true)
  }
  const handleCloseTeam = ()=>{
    setIsTeam(false)
    setIsQR(false)
  }
  
  useEffect(() => {

    const timer = setTimeout(()=>{
      setLoad(false)
    },2000)
    return ()=>clearTimeout(timer)
  },[])

  return (
    <div className="container-card" style={{backgroundImage:`url(${people.gif})`}}  >
     
     <Canvas  gl={{ alpha: true, antialias: true}}  className='canvas-wrap'>
    <CameraControls />
        <pointLight position={[3, 3, 3]} intensity={2} color='#f0f0f0'/>
        <pointLight position={[3, -3, 3]} intensity={2} color='#f0f0f0'/>

        <pointLight position={[-3, 3, 3]} intensity={2} color='#f0f0f0'/>

        <pointLight position={[3, 3, -3]} intensity={2} color='#f0f0f0'/>

      <Suspense fallback={null}>
   

        
      <Box people={people}   position={[0, 0, 0]} />
    

      <Cube position={[0, 0, 0]} />
      <Model position={[0,-0.5,0]} />
      

      <Phone posY={posY} people={people} opacity={opacity}/>
      <Email posY={posY} people={people} opacity={opacity}/>
      <Cell posY={posY} people={people} opacity={opacity}/>

      <QR handleOpenQr={handleOpenQr} posY={posY} people={people} opacity={opacity}/>
      <Map posY={posY} people={people} opacity={opacity}/>
      <Web posY={posY} people={people} opacity={opacity}/>
      <Youtube posY={posY} people={people} opacity={opacity}/>


      {/* <VideoBtn people={people} opacity={opacity}/> */}

      <mesh rotation={[0, Math.PI * 2.5, 0]} position={[0,0,-1.2]} onClick={(e)=>{ handleClickTeam();     e.stopPropagation()
}}   ref={mesh2}>
        <boxBufferGeometry  attach="geometry"  args={[0.1,1,1]}/>
        <meshStandardMaterial transparent={true} opacity={opacity}    color='red'   />

       
    </mesh>
    
    </Suspense>

    </Canvas>
    <div style={isTeam ? {top:'20%',transition:'1.5s'}:{top:'200%',transition:'1.5s'}}  className="wrapper">
          <Book people={people}/>
          <img onClick={()=>handleCloseTeam()} id='close' src="https://res.cloudinary.com/dvr397xda/image/upload/v1656101942/cube/cancel_dmukx0.png" alt="" />
    </div>
         

          <div style={load ?{ zIndex:'100000',opacity:'1'} :{zIndex:'-10',opacity:'0',transition:'4.5s' }} className='load-image'>
                <img src={people.loadImg} alt="" />
          </div>

          <div style={isQR ? {top:'20%',transition:'1.5s'}:{top:'200%',transition:'1.5s'}}  className="wrapper">
          <img id='qr' src={people.qrCode} alt="" />
          <h3>长按识别图中二维码</h3>

          <img onClick={()=>handleCloseTeam()} id='close' src="https://res.cloudinary.com/dvr397xda/image/upload/v1656101942/cube/cancel_dmukx0.png" alt="" />
    </div>

    </div>
  )
}

