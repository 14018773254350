import * as THREE from "three";
import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
import { Canvas, useFrame,useLoader } from "@react-three/fiber";
import { OrbitControls, Html,PerspectiveCamera } from "@react-three/drei";
import fetchWedding from '../../wedding'
import {useParams} from 'react-router-dom'


function randomPos() {
  const min = 5;
  const max = -5;
  return Math.random() * (max - min) + min;
}

function Moment({ data, setPosition}) {
  const meshRef = useRef();

  const [hover, setHover] = useState(false);
  const [clicked, setClicked] = useState(false);

  useEffect(() => {
    document.body.style.cursor = hover ? "pointer" : "grab";
  }, [hover]);

  useLayoutEffect(() => {
    meshRef.current.position.x = data.position[0];
    meshRef.current.position.y = data.position[1];
    meshRef.current.position.z = data.position[2];
  });
  const texture = useLoader(THREE.TextureLoader,data.color)

  const zoomToView = (focusRef) => {
    console.log(focusRef);
    setPosition([focusRef.current.position.x, focusRef.current.position.y, focusRef.current.position.z+2])
};

  return (
    <mesh
      ref={meshRef}
      onPointerOver={() => {
        setHover(true);
      }}
      onPointerOut={() => {
        setHover(false);
      }}
      onClick={() => {
        setClicked(!clicked);
        zoomToView(meshRef);
      }}
    >
        {
            data.isRotate ? <planeGeometry attach="geometry" args={[0.9, 1.6]} />
           

            :<planeGeometry attach="geometry" args={[1.6, 0.9]} />
            
        }
                    <meshStandardMaterial map={texture}  side={THREE.DoubleSide}/>

     {hover || clicked ? (
              <Html distanceFactor={1}>
                <div className="content">
                  hello <br />
                  world
                </div>
              </Html>
            ) : (
              ""
            )}
    </mesh>
  );
}

function Cloud({ gallery,isLoad,setPosition }) {
//   const [zoom, setZoom] = useState(false);
//   const [focus, setFocus] = useState([0,0,0]);
//   useFrame((state) => {
   
   

   

//     //
//     zoom ? setPosition([focus.x, focus.y, focus.z]):setPosition([0, 0, 5]);
//     zoom ? state.camera.lookAt(focus.x, focus.y, focus.z): state.camera.lookAt(0, 0, 0);

//     state.camera.updateProjectionMatrix();
//   });

 

//   const zoomToView = (focusRef) => {
//       console.log(focusRef)
//     setZoom(!zoom);
//     setFocus(focusRef.current.position);
  
//   };

  return (
    <instancedMesh>
      {isLoad&&gallery.map((moment, i) => (
        // Set position here so it isn't reset on state change
        // for individual moment.
       <Moment key={i} data={moment} setPosition={setPosition}  />
      ))}
    </instancedMesh>
  );
}

function Wedding2() {
  /*
  momentsArray is data that comes from elsewhere in the app
  but for this demo I just generate it here.
  */
  const{ name } = useParams();
  const [gallery,setGallery]=useState([])
  const [position,setPosition] = useState([0,0,5])
const [isLoad,setIsLoad] = useState(false);
  useEffect(()=>{

    const data = fetchWedding();
    const result = data.filter(e=>e.id===name)

    const images =result[0].images
    const repeat = (arr, n) => Array(n).fill(arr).flat();

    const  momentsArray = repeat(images,5);
    let temp =[]
    for (let i = 0; i < momentsArray.length; i++) {
        const item ={
            color: momentsArray[i].url,
            position: [randomPos(), randomPos(), randomPos()],
            isRotate: momentsArray[i].isRotate
        }
      temp.push(item);
    }
    setGallery(temp);
 
    setIsLoad(true)

   },[name])
 

  

  return (
    <Canvas
     
      
    >
              <PerspectiveCamera makeDefault  position={position}/>

      <ambientLight />
      <directionalLight position={[150, 150, 150]} intensity={0.55} />
      {isLoad&&gallery[0].color&&<Cloud setPosition={setPosition} gallery={gallery} isLoad={isLoad} />}
      <OrbitControls enableZoom={true} />
    </Canvas>
  );
}

export default Wedding2;
