import React,{useState,useEffect}from 'react'
import {
    CameraReels,
    Controller,
  
    ChevronLeft
  } from "react-bootstrap-icons";


import Nav from '../../components/Nav/index'
import Footer from '../../components/Footer/index'
import './index.scss'


export default function Entertainment() {

   
  
    const [email, setEmail] = useState('');
    const [amount,setAmount] = useState(30)


    const [target,setTarget]=useState({})
    const [isOpen,setIsOpen]=useState(false)
  
    const [showIndex,setShowIndex]=useState(-1)


   

    const data = [
        {
            name:'影视专区',
            icon:<CameraReels/>,
            data:[
                {
                    name:'爱壹f帆TV',
                    url:'https://www.iyf.tv/',
                    type:'tv'
                },
                {
                    name:'努努影院',
                    url:'https://www.nunuyy3.org/',
                    type:'tv',
        
                },
            ]
        },
        {
            name:'游戏专区',
            icon:<Controller/>,

            data:[
                {
                    name:'人生重开模拟器',
                    url:'https://liferestart.syaro.io/view/index.html',
                    type:'game',
        
                },
                {
                    name:'3D泡泡龙',
                    url:'https://games.cdn.famobi.com/html5games/b/bubble-tower-3d/v050/?fg_domain=play.famobi.com&fg_aid=A1000-100&fg_uid=1385d98a-b5f2-4339-bce7-b99a8dd2e8b0&fg_pid=5a106c0b-28b5-48e2-ab01-ce747dda340f&fg_beat=897&original_ref=https%3A%2F%2Fhtml5games.com%2F',
                    type:'game',
        
                },
                {
                    name:'跑到底',
                    url:'https://games.cdn.famobi.com/html5games/o/om-nom-run/v1240/?fg_domain=play.famobi.com&fg_aid=A1000-100&fg_uid=abe80572-560a-444d-baf7-2fa4a7b2c02f&fg_pid=5a106c0b-28b5-48e2-ab01-ce747dda340f&fg_beat=898&original_ref=https%3A%2F%2Fhtml5games.com%2F',
                    type:'game',
        
                },
                {
                    name:'桌球',
                    url:'https://games.cdn.famobi.com/html5games/0/8-ball-billiards-classic/v250/?fg_domain=play.famobi.com&fg_aid=A1000-100&fg_uid=82038e98-d4e1-46dd-8de9-1460d1395eab&fg_pid=5a106c0b-28b5-48e2-ab01-ce747dda340f&fg_beat=901&original_ref=https%3A%2F%2Fhtml5games.com%2F',
                },
                {
                    name:'连连看',
                    url:'https://games.cdn.famobi.com/html5games/o/onet-connect-classic/v380/?fg_domain=play.famobi.com&fg_aid=A1000-100&fg_uid=d6173a60-1b41-4b34-b4c3-aa4c5fc9ce35&fg_pid=5a106c0b-28b5-48e2-ab01-ce747dda340f&fg_beat=901&original_ref=https%3A%2F%2Fhtml5games.com%2F',
                },
                {
                    name:'扑克',
                    url:'https://games.cdn.famobi.com/html5games/s/solitaire-klondike/v480/?fg_domain=play.famobi.com&fg_aid=A1000-100&fg_uid=5b597140-77c2-4f7c-9abf-4f149d6dcbac&fg_pid=5a106c0b-28b5-48e2-ab01-ce747dda340f&fg_beat=902&original_ref=https%3A%2F%2Fhtml5games.com%2F',
                    type:'game',
        
                },
                {
                    name:'大家来找茬',
                    url:'https://games.cdn.famobi.com/html5games/f/find-500-differences/v090/?fg_domain=play.famobi.com&fg_aid=A1000-100&fg_uid=b0894d32-6b8f-4dfe-927e-83460129f090&fg_pid=5a106c0b-28b5-48e2-ab01-ce747dda340f&fg_beat=912&original_ref=https%3A%2F%2Fhtml5games.com%2F',
                    type:'game',
        
                },
                {
                    name:'加农炮',
                    url:'https://games.cdn.famobi.com/html5games/c/cannon-balls-3d/v190/?fg_domain=play.famobi.com&fg_aid=A1000-100&fg_uid=3ffa11c2-61da-4974-892b-473582253371&fg_pid=5a106c0b-28b5-48e2-ab01-ce747dda340f&fg_beat=914&original_ref=https%3A%2F%2Fhtml5games.com%2F',
                },
                {
                    name:'水果忍者',
                    url:'https://games.cdn.famobi.com/html5games/f/fruit-ninja/v010/?fg_domain=play.famobi.com&fg_aid=A1000-100&fg_uid=5450f1ec-af9e-4859-832a-69da3715c378&fg_pid=5a106c0b-28b5-48e2-ab01-ce747dda340f&fg_beat=916&original_ref=https%3A%2F%2Fhtml5games.com%2F',
                    type:'game',
        
                },
            ]
        },
     
      
    ]



    const handleOpenLife = (v)=>{
        setIsOpen(true)
        setTarget(v)
    }
    const handleCloseLife = ()=>{
        setIsOpen(false)

    }
    const handleShow=(i)=>{

        setShowIndex(i)
    }
    const handleBack=()=>{
        setShowIndex(-1)

    }



  
   

   

   

console.log(amount,email);
    
  return (
<>
    <div className='user-wrap'>
       <Nav/>
       <div className='user-content3'>

        <a href='http://metamedia668.com/' className='tv-wrap'>
           <img src="https://res.cloudinary.com/dvr397xda/image/upload/v1671641418/WechatIMG14222_vzcpro.png" alt="" />
            <h2>美西电视台</h2>
            <button>观看电视台</button>
        </a>
<hr />
        <div className='tv-bot'>
        {showIndex===-1&&<div className='game-video-wrap'>
                {
                    data.map((v,i)=>(
                        <button onClick={()=>handleShow(i)} key={i}>
                            {v.icon}
                            <h4>{v.name}</h4>
                        </button>
                    ))
                }
            </div>}
        </div>
            
           
            {/* {
                data&&data.map((v,i)=>(
                    <button onClick={()=>handleOpenLife(v)} key={i}>
                        {v.name}
                    </button>
                ))
            } */}
          
            {
                showIndex===0&&<div className='video-wrap'>
                    <h3 onClick={()=>handleBack()}><ChevronLeft/>  返回</h3>
                    {data&&data[0].data.map((v,i)=>(
                    <button onClick={()=>handleOpenLife(v)} key={i}>
                        {v.name}
                    </button>
                ))}
                </div>
            }

            {
                showIndex===1&&<div className='game-wrap'>
                    <h3 onClick={()=>handleBack()}><ChevronLeft/>  返回</h3>
                    <div>
                    {data&&data[1].data.map((v,i)=>(
                    <button onClick={()=>handleOpenLife(v)} key={i}>
                        {v.name}
                    </button>
                     ))}
                    </div>
                  
               
                </div>
            }
         

        
          

       </div>

       {isOpen&&<div style={{top:isOpen? '0':'220vh',transition:'1s'}} className='iframe-wrap'>
       <iframe title='metaLife' width={'100vw'} height='90vh' src={target.url}  frameBorder="0"></iframe>

       </div>}
     
           {isOpen ? <button style={{position:'absolute',bottom:'0'}} onClick={()=>handleCloseLife()}>关闭{target.name}页面</button>   :<Footer/>}
    </div> 
    
</>
  )
}


