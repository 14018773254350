import React,{useRef,Suspense} from 'react'
import { Canvas,extend,useThree,useFrame } from '@react-three/fiber'
import {Html, useProgress,} from '@react-three/drei'
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import Cubes from '../Cubes/index'
import './index.scss'
import Env from '../Environment/index'
import Light from '../Light/index'

extend({ OrbitControls });



const CameraControls = () => {
    // Get a reference to the Three.js Camera, and the canvas html element.
    // We need these to setup the OrbitControls class.
    // https://threejs.org/docs/#examples/en/controls/OrbitControls
  
    const {
      camera,
      gl: { domElement },
    } = useThree();
  
    // Ref to the controls, so that we can update them on every frame with useFrame
    const controls = useRef();
    useFrame(() => controls.current.update());
    return (
      <orbitControls
        ref={controls}
        args={[camera, domElement]}
        autoRotate={false}
        enableZoom={true}
        maxDistance={8}
      minDistance={2}
      />
    );
  };

export default function Group() {



  return (
  
  
     
<Canvas dpr={Math.max(window.devicePixelRatio, 2)}>
<CameraControls />
<Suspense fallback={<Loader />}>

    <Env />
    <Cubes/>
    <Light/>
    </Suspense>

</Canvas>
 
   
       

  )
}

function Loader() {
  const { progress } = useProgress()
  return <Html center> <h2 id='loadText'>
        Loading ...{progress.toFixed(0)}%
        <img id='loadImage' src="https://res.cloudinary.com/dvr397xda/image/upload/v1657312449/cube/output-onlinegiftools_i29wl2.gif" alt="" />
        </h2></Html>
}