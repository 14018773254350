import React, {useState,useEffect} from 'react'
import {useParams} from 'react-router-dom'


import fetchGallery from '../../gallery'
import './index.scss'


const CARDS = 10;
const MAX_VISIBILITY = 3;

const Card = ({url}) => (
  <div className='card'>
    <img src={url} alt="" />
  </div>
);

const Carousel = ({children}) => {
  const [active, setActive] = useState(2);
  const count = React.Children.count(children);
  
  return (
    <div className='carousel'>
      {active > 0 && <button className='nav left' onClick={() => setActive(i => i - 1)}>左</button>}
      {React.Children.map(children, (child, i) => (
        <div className='cards-container' style={{
            '--active': i === active ? 1 : 0,
            '--offset': (active - i) / 3,
            '--direction': Math.sign(active - i),
            '--abs-offset': Math.abs(active - i) / 3,
            'pointerEvents': active === i ? 'auto' : 'none',
            'opacity': Math.abs(active - i) >= MAX_VISIBILITY ? '0' : '1',
            'display': Math.abs(active - i) > MAX_VISIBILITY ? 'none' : 'block',
          }}>
          {child}
        </div>
      ))}
      {active < count - 1 && <button className='nav right' onClick={() => setActive(i => i + 1)}>右</button>}
    </div>
  );
};



export default function Gallery(){

    const{ name } = useParams();
    const [people,setPeople]=useState({})

    useEffect(()=>{
 
     const data = fetchGallery();
     const result = data.filter(e=>e.id===name)
  
     setPeople(result[0])
 
    },[name])
    console.log(people)
    return(
<div className='gallery-wrap'>
<Carousel>
      {people.id&&people.images.map((v, i) => (
        <Card key={i}  url={v}/>
      ))}
    </Carousel>
  </div>
    )
}