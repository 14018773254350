const data = [

    { 
        id:'lily-zou',
        name:'Lily Zou',
        tel:'3478371813',
        email:'Lucksky8777@gmail.com',
        address:'4306 Main St,Flushing,NY,11355',
        map:'https://www.google.com/maps/place/43-06+Main+St,+Flushing,+NY+11355/@40.7522664,-73.8315613,17z/data=!3m1!4b1!4m5!3m4!1s0x89c2606cd640cb53:0x141db878e9815fe!8m2!3d40.7522665!4d-73.8270766',
        video:'',
        website:'https://360realty.online/#/sample/1',
        images:[
            'https://res.cloudinary.com/dvr397xda/image/upload/v1660145233/cube/basic/lily/WechatIMG9790_tsg8ra.png', //right
            'https://res.cloudinary.com/dvr397xda/image/upload/v1659113406/cube/basic/lily/WechatIMG9523_sqg75q.png', //left
            'https://res.cloudinary.com/dvr397xda/image/upload/v1658518055/cube/weiiz/WechatIMG9330_gm5mez.png', //glass
            'https://res.cloudinary.com/dvr397xda/image/upload/v1658518055/cube/weiiz/WechatIMG9330_gm5mez.png', //glass
            'https://res.cloudinary.com/dvr397xda/image/upload/v1660145233/cube/basic/lily/WechatIMG9789_iedult.png', //front
            'https://res.cloudinary.com/dvr397xda/image/upload/v1660145233/cube/basic/lily/WechatIMG9791_ikwiyk.png'//back
    ],

  
    gif:'https://res.cloudinary.com/dvr397xda/image/upload/v1659106966/cube/basic/lily/Espw_olktz5.gif',
    loadImg:'https://res.cloudinary.com/dvr397xda/image/upload/v1661281581/cube/example/360_1_ryuy49.gif',
    qrCode:'https://res.cloudinary.com/dvr397xda/image/upload/v1659997040/cube/basic/lily/WechatIMG124_l0rvpj.jpg',
    model:{
        name:'key',
        url:'https://res.cloudinary.com/dvr397xda/raw/upload/v1659109343/cube/basic/lily/KeyChain_sgoo39.FBX',
        metalMap:'https://res.cloudinary.com/dvr397xda/image/upload/v1659109362/cube/basic/lily/KeyChainMetallic_hipbo4.png',
        normalMap:'https://res.cloudinary.com/dvr397xda/image/upload/v1659109365/cube/basic/lily/KeyChainNormal_jp5gqw.png',
        roughnessMap:'https://res.cloudinary.com/dvr397xda/image/upload/v1659109360/cube/basic/lily/KeyChainRough_m2zisg.png',
        colorMap:'https://res.cloudinary.com/dvr397xda/image/upload/v1659109363/cube/basic/lily/KeyChainAlbedo_kavfgg.png',
        aoMap:'https://res.cloudinary.com/dvr397xda/image/upload/v1659109363/cube/basic/lily/KeyChainAOC_gdexfb.png',
        scale:8,
        position:[0,-0.5,0],
        rotation:[0,0,Math.PI / 2.5],
    }
    },

    { 
        id:'wingo',
        name:'Wingo',
        tel:'3478783092',
        email:'Wingoagency@gmail.com',
        address:'4306 Main St,Flushing,NY,11355',
        map:'https://www.google.com/maps/place/43-06+Main+St,+Flushing,+NY+11355/@40.7522664,-73.8315613,17z/data=!3m1!4b1!4m5!3m4!1s0x89c2606cd640cb53:0x141db878e9815fe!8m2!3d40.7522665!4d-73.8270766',
        video:'https://www.youtube.com/watch?v=tBOVNZxuR_I&ab_channel=TaiwanPopularSongs',
        background:'https://res.cloudinary.com/dvr397xda/image/upload/v1653927826/cube/1921iq6-a-room-that-has-a-umbrella-in-her-hand_rainyw.jpg',
        website:'http://metamedia668.com/',
        images:[
            'https://res.cloudinary.com/dvr397xda/image/upload/v1658518055/cube/weiiz/WechatIMG9330_gm5mez.png', //right
            'https://res.cloudinary.com/dvr397xda/image/upload/v1673537060/3d-cards/wingo/WechatIMG15022_l8vbek.jpg', //left
            'https://res.cloudinary.com/dvr397xda/image/upload/v1658518055/cube/weiiz/WechatIMG9330_gm5mez.png', //glass
            'https://res.cloudinary.com/dvr397xda/image/upload/v1658518055/cube/weiiz/WechatIMG9330_gm5mez.png', //glass
            'https://res.cloudinary.com/dvr397xda/image/upload/v1660319725/cube/basic/wingo/WechatIMG9812_ltbb1z.png', //front
            'https://res.cloudinary.com/dvr397xda/image/upload/v1660319725/cube/basic/wingo/WechatIMG9810_bulm7n.png'//back
    ],
  
    gif:'https://res.cloudinary.com/dvr397xda/image/upload/v1659712913/cube/Intro_-_51803_AdobeExpress_yqbxdf.gif',
    loadImg:'https://res.cloudinary.com/dvr397xda/image/upload/v1661281581/cube/example/360_1_ryuy49.gif',
    qrCode:'https://res.cloudinary.com/dvr397xda/image/upload/v1659463931/cube/basic/wingo/WechatIMG9649_kppivv.jpg',
    model:{
        name:'camera',
        url:'https://res.cloudinary.com/dvr397xda/raw/upload/v1660169514/cube/basic/wingo/OldCamera_ibq4gj.fbx',
        metalMap:'https://res.cloudinary.com/dvr397xda/image/upload/v1659468224/cube/basic/wingo/CAM0001_Textures_METL_2k_z9glb8.png',
        normalMap:'https://res.cloudinary.com/dvr397xda/image/upload/v1659468230/cube/basic/wingo/CAM0001_Textures_NRML_2k_ou6yul.png',
        roughnessMap:'https://res.cloudinary.com/dvr397xda/image/upload/v1659468224/cube/basic/wingo/CAM0001_Textures_ROUGH_2k_rx6oey.png',
        colorMap:'https://res.cloudinary.com/dvr397xda/image/upload/v1659468228/cube/basic/wingo/CAM0001_Textures_COL_2k_wrktl1.png',
        aoMap:'https://res.cloudinary.com/dvr397xda/image/upload/v1659468222/cube/basic/wingo/CAM0001_Textures_AO_2k_ilwxtp.png',
        scale:0.018,
        position:[0,-0.5,0],
        rotation:[0,0,0],
    },
    living:'https://mp.weixin.qq.com/s/HJxflSaNCBYP8J-XwZ2dIA'
    },
    { 
        id:'johnny',
        name:'Johnny',
        tel:'8453036348',
        email:'Johnnychen@nicyg.com',
        address:'4306 Main St,Flushing,NY,11355',
        map:'https://www.google.com/maps/place/43-06+Main+St,+Flushing,+NY+11355/@40.7522664,-73.8315613,17z/data=!3m1!4b1!4m5!3m4!1s0x89c2606cd640cb53:0x141db878e9815fe!8m2!3d40.7522665!4d-73.8270766',
        video:'',
        background:'https://res.cloudinary.com/dvr397xda/image/upload/v1653927826/cube/1921iq6-a-room-that-has-a-umbrella-in-her-hand_rainyw.jpg',
        website:'https://www.linkedin.com/in/johnny-chen-358b971bb/',
        images:[
            'https://res.cloudinary.com/dvr397xda/image/upload/v1658518055/cube/weiiz/WechatIMG9330_gm5mez.png', //right
            'https://res.cloudinary.com/dvr397xda/image/upload/v1657226745/cube/company/WechatIMG8892_nltf0t.png', //left
            'https://res.cloudinary.com/dvr397xda/image/upload/v1658518055/cube/weiiz/WechatIMG9330_gm5mez.png', //glass
            'https://res.cloudinary.com/dvr397xda/image/upload/v1658518055/cube/weiiz/WechatIMG9330_gm5mez.png', //glass
            'https://res.cloudinary.com/dvr397xda/image/upload/v1659548115/cube/WechatIMG9669_iqddcs.png', //front
            'https://res.cloudinary.com/dvr397xda/image/upload/v1659471006/cube/basic/wingo/WechatIMG9658_ilwejb.png'//back
    ],
  
    gif:'https://res.cloudinary.com/dvr397xda/image/upload/v1660084015/cube/basic/johnny/0A6A276D-A748-4DCB-93D9-13BA39670F9D_MOV_AdobeExpress_blbym5.gif',
    loadImg:'https://res.cloudinary.com/dvr397xda/image/upload/v1661281581/cube/example/360_1_ryuy49.gif',
    qrCode:'https://res.cloudinary.com/dvr397xda/image/upload/v1657220590/cube/company/WechatIMG8887_alcgdr.png',
    model:{
        name:'camera',
        url:'https://res.cloudinary.com/dvr397xda/raw/upload/v1659468154/cube/basic/wingo/Canon_AT-1_o5weeb.fbx',
        metalMap:'https://res.cloudinary.com/dvr397xda/image/upload/v1659468224/cube/basic/wingo/CAM0001_Textures_METL_2k_z9glb8.png',
        normalMap:'https://res.cloudinary.com/dvr397xda/image/upload/v1659468230/cube/basic/wingo/CAM0001_Textures_NRML_2k_ou6yul.png',
        roughnessMap:'https://res.cloudinary.com/dvr397xda/image/upload/v1659468224/cube/basic/wingo/CAM0001_Textures_ROUGH_2k_rx6oey.png',
        colorMap:'https://res.cloudinary.com/dvr397xda/image/upload/v1659468228/cube/basic/wingo/CAM0001_Textures_COL_2k_wrktl1.png',
        aoMap:'https://res.cloudinary.com/dvr397xda/image/upload/v1659468222/cube/basic/wingo/CAM0001_Textures_AO_2k_ilwxtp.png',
        scale:14,
        position:[0,-0.5,0],
        rotation:[0,0,0],
    },
    living:'https://www.youtube.com/watch?v=tBOVNZxuR_I&ab_channel=TaiwanPopularSongs'
    },
    { 
        id:'ben',
        name:'Xiao Feng Pan',
        tel:'7188863200',
        email:'info@winzonere.com',
        address:'81-15 Queens Blvd 2nd FL Elmhurst,NY,11373',
        map:'https://goo.gl/maps/HrFbYuTnHaQjbRoh9',
        video:'https://youtu.be/1Qd-nAlOLIY',
        background:'https://res.cloudinary.com/dvr397xda/image/upload/v1653927826/cube/1921iq6-a-room-that-has-a-umbrella-in-her-hand_rainyw.jpg',
        website:'https://www.winzonerealty.com/Home',
        images:[
            'https://res.cloudinary.com/dvr397xda/image/upload/v1658518055/cube/weiiz/WechatIMG9330_gm5mez.png', //right
            'https://res.cloudinary.com/dvr397xda/image/upload/v1658518055/cube/weiiz/WechatIMG9330_gm5mez.png', //left
            'https://res.cloudinary.com/dvr397xda/image/upload/v1658518055/cube/weiiz/WechatIMG9330_gm5mez.png', //glass
            'https://res.cloudinary.com/dvr397xda/image/upload/v1658518055/cube/weiiz/WechatIMG9330_gm5mez.png', //glass
            'https://res.cloudinary.com/dvr397xda/image/upload/v1661192021/cube/basic/ben/WechatIMG10090_supum2.png', //front
            'https://res.cloudinary.com/dvr397xda/image/upload/v1661202373/cube/basic/ben/WechatIMG10095_vcm63t.png'//back
    ],
  
    gif:'https://res.cloudinary.com/dvr397xda/image/upload/v1661201236/cube/basic/ben/06_dribble_acvary.gif',
    loadImg:'',
    qrCode:'https://res.cloudinary.com/dvr397xda/image/upload/v1661192637/cube/basic/ben/WechatIMG686_chvqu1.jpg',
    model:{
        name:'house',
        url:'https://res.cloudinary.com/dvr397xda/raw/upload/v1661194031/cube/basic/ben/High_Poly_House_Full2_gqcfiv.fbx',
        metalMap:'',
        normalMap:'',
        roughnessMap:'',
        colorMap:'https://res.cloudinary.com/dvr397xda/image/upload/v1661194069/cube/basic/ben/House_Texture_otevwq.png',
        aoMap:'',
        scale:0.00125,
        position:[0,-1,0],
        rotation:[0,0,0],
    },
    living:'',
    isMusic:true,
    music:[{
        album:'周杰伦',
        name:'简单爱',
        track:'Jay', 
        url:'https://res.cloudinary.com/dvr397xda/video/upload/v1661202691/cube/basic/ben/official-music-video_nsd8gr.mp3', 
        image:'https://res.cloudinary.com/dvr397xda/image/upload/v1660938134/music/cd-player_1_nxixox.png'
    }],
    isGallery:true,
    gallery:[
        'https://res.cloudinary.com/dvr397xda/image/upload/v1661203405/cube/basic/ben/WechatIMG10100_wnktol.png',
        'https://res.cloudinary.com/dvr397xda/image/upload/v1661203405/cube/basic/ben/WechatIMG10098_dfcfra.png',
        'https://res.cloudinary.com/dvr397xda/image/upload/v1661203405/cube/basic/ben/WechatIMG10099_zinnlf.png',
        'https://res.cloudinary.com/dvr397xda/image/upload/v1661203405/cube/basic/ben/WechatIMG10101_xwrvpp.png'
    ]
    },

  




]

const fetchBasic = ()=>{
    return data;
}


export default fetchBasic