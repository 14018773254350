import React, { useState } from 'react'

import ImageGallery from "react-image-gallery";

import './index.scss'
import axios from 'axios';

export default function Crystal() {
    const IMG = [
        {
            original:  'https://res.cloudinary.com/dvr397xda/image/upload/v1697492839/meta-activities/WechatIMG22674_wo8dqn.jpg',
            thumbnail:  'https://res.cloudinary.com/dvr397xda/image/upload/v1697492839/meta-activities/WechatIMG22674_wo8dqn.jpg',
        },
        {
            original: 'https://res.cloudinary.com/dvr397xda/image/upload/v1697492839/meta-activities/WechatIMG22673_yk6dp7.jpg',
            thumbnail: 'https://res.cloudinary.com/dvr397xda/image/upload/v1697492839/meta-activities/WechatIMG22673_yk6dp7.jpg',
        },
     
    ];
    const initial = {
        name:'',
        phone:'',
        email:'',
        content:'',
        url:'',
    }
    const apiUrl ='https://metaserverapp.com'

    const [value,setValue] = useState(initial)

    const handleName= (e)=>{
        let temp = {...value}
        temp.name = e.target.value
        setValue(temp)
    }

    const handleEmail= (e)=>{
        let temp = {...value}
        temp.email = e.target.value
        setValue(temp)
    }

    const handleUrl= (e)=>{
        let temp = {...value}
        temp.url = e.target.value
        setValue(temp)
    }

    const handlePhone= (e)=>{
        let temp = {...value}
        temp.phone = e.target.value
        setValue(temp)
    }
    const handleContent= (e)=>{
        let temp = {...value}
        temp.content = e.target.value
        setValue(temp)
    }
    const [isSuccess,setIsSuccess]= useState(false)

    const handleSubmit = async()=>{
        console.log(apiUrl)

          if(!value.phone || !value.name){
            alert('请留下你的电话和名字')
          }  
          else{
            const data = await axios.post(`${apiUrl}/player/create`,{
                name: value.name,
                phone: value.phone,
                email: value.email,
                content:value.content,
                url: value.url,
            })
            console.log(data);

            if(data.data.success) {
                setIsSuccess(true)
                setValue(initial)
            }
            else{
                alert(data.data.error)
            }

          }

    }

    console.log(value)


  return (
    <div id='activity'>
        <ImageGallery items={IMG} />
        <div>

        <h2>才艺争霸! <br />蓝菱勋章大赛</h2>
        <p>Details Coming soon!</p>
        </div>

        <div id='contact-form'>

        <div className="container">  

  <form id="contact" onSubmit={(e)=>e.preventDefault()}  >
    <h3>{isSuccess ?  '报名成功!':'现在报名!'}</h3>
    <h4>填写报名信息后,工作人员稍后会联系您!</h4>
    <fieldset>
      <input placeholder="你的名字" type="text" tabIndex="1" value={value.name} onChange={(e)=>handleName(e)} />
    </fieldset>
    <fieldset>
      <input placeholder="你的邮箱" type="email" tabIndex="2" value={value.email} onChange={(e)=>handleEmail(e)}/>
    </fieldset>
    <fieldset>
      <input placeholder="你的联系电话" type="text" tabIndex="3" value={value.phone} onChange={(e)=>handlePhone(e)}  />
    </fieldset>
    <fieldset>
      <input placeholder="你的作品链接网址" type="text" tabIndex="4" value={value.url} onChange={(e)=>handleUrl(e)} />
    </fieldset>
    <fieldset>
      <textarea placeholder="简短的自我介绍" tabIndex="5" value={value.content} onChange={(e)=>handleContent(e)} ></textarea>
    </fieldset>
    <fieldset>
      <button style={{backgroundColor: isSuccess? 'green' :'blueviolet' }} onClick={()=>handleSubmit()} disabled={isSuccess}   >{isSuccess ? '提交成功' :'上传'}</button>
    </fieldset>
  </form>
 
  
</div>

        </div>
    </div>
  )
}
