import React,{useState,useEffect,useRef,Suspense,useCallback}from 'react'
import { Canvas, useFrame,extend,useThree,useLoader,} from '@react-three/fiber'
import {Link} from 'react-router-dom'
import * as THREE from "three";

import {useParams} from 'react-router-dom'

import {useGLTF,useFBX,useProgress,Html,PresentationControls,Environment, ContactShadows} from '@react-three/drei'

import './index.scss'


function Loader() {
  const { progress } = useProgress()
  return <Html center> <h2 id='loadText'>
        Loading ...{progress.toFixed(0)}%
        <img id='loadImage' src="https://res.cloudinary.com/dvr397xda/image/upload/v1660580851/cube/output-onlinegiftools_rgnpbb.gif" alt="" />
        </h2></Html>
}

function Earth(){
  const earthMaterial = 'https://res.cloudinary.com/dvr397xda/image/upload/v1650905847/cube/earth/2k_earth_daymap_habyjo.jpg';

    const colorMap = useLoader(THREE.TextureLoader, earthMaterial)
    const mesh = useRef()

    useFrame((state,delta)=>(mesh.current.rotation.y+=0.01))

    return (
        <mesh ref={mesh}>
        <sphereBufferGeometry args={[1.2, 30, 30]} attach="geometry" />
        <meshBasicMaterial  map={colorMap} attach="material" metalness={1} />
      </mesh>
    )

}

function Rose(){

  const group = useRef();
  const { nodes, materials } = useGLTF("https://res.cloudinary.com/dvr397xda/image/upload/v1659630975/cube/hunqing/rose_jzkfup.glb");


  useFrame((state, delta) => {
      
    
    group.current.rotation.y += 0.005
  
  })
  return (
    <group ref={group}  dispose={null}>
      <group rotation={[-Math.PI / 2, 0, 0]}>
        <group rotation={[Math.PI / 2, 0, 0]}>
          <group
            position={[0, -1.5, 0]}
            rotation={[0.17, -0.1, 0]}
            scale={[0.8, 0.8, 0.8]}
          >
            <mesh
              geometry={nodes.mesh_0.geometry}
              material={materials.material}
            />
          </group>
        </group>
      </group>
    </group>
  );
}
function Macbook(){
  const mesh = useRef()

  const { nodes, materials } = useGLTF('https://res.cloudinary.com/dvr397xda/image/upload/v1657212110/cube/museum/mac-draco_cvwbu1.glb')

  useFrame((state) => {
      mesh.current.rotation.y += 0.01
  
  })
  // Events and spring animations were added afterwards
  return (
    <group
      ref={mesh}
      position={[0,-0.5,0]}
      scale={0.16}
      >
     
        <group position={[0, 2.96, 0]} rotation={[Math.PI *2.5, 0, 0]}>
          <mesh material={materials.aluminium} geometry={nodes['Cube008'].geometry} />
          <mesh material={materials['matte.001']} geometry={nodes['Cube008_1'].geometry} />
          <mesh material={materials['screen.001']} geometry={nodes['Cube008_2'].geometry} />
        </group>
    
      <mesh material={materials.keys} geometry={nodes.keyboard.geometry} position={[1.79, 0, 3.45]} />
      <group position={[0, -0.1, 3.39]}>
        <mesh material={materials.aluminium} geometry={nodes['Cube002'].geometry} />
        <mesh material={materials.trackpad} geometry={nodes['Cube002_1'].geometry} />
      </group>
      <mesh material={materials.touchbar} geometry={nodes.touchbar.geometry} position={[0, -0.03, 1.2]} />
    </group>
  )
}
function VideoCamera(){
  const mesh = useRef()
  const model = {
    name:'camera',
    url:'https://res.cloudinary.com/dvr397xda/raw/upload/v1660169514/cube/basic/wingo/OldCamera_ibq4gj.fbx',
    metalMap:'https://res.cloudinary.com/dvr397xda/image/upload/v1659468224/cube/basic/wingo/CAM0001_Textures_METL_2k_z9glb8.png',
    normalMap:'https://res.cloudinary.com/dvr397xda/image/upload/v1659468230/cube/basic/wingo/CAM0001_Textures_NRML_2k_ou6yul.png',
    roughnessMap:'https://res.cloudinary.com/dvr397xda/image/upload/v1659468224/cube/basic/wingo/CAM0001_Textures_ROUGH_2k_rx6oey.png',
    colorMap:'https://res.cloudinary.com/dvr397xda/image/upload/v1659468228/cube/basic/wingo/CAM0001_Textures_COL_2k_wrktl1.png',
    aoMap:'https://res.cloudinary.com/dvr397xda/image/upload/v1659468222/cube/basic/wingo/CAM0001_Textures_AO_2k_ilwxtp.png',
    scale:0.018,
    position:[0,-0.5,0],
    rotation:[0,0,0],
}
  let key = useFBX(model.url)
  const [colorMap, metalMap, normalMap, roughnessMap, aoMap] = useLoader(THREE.TextureLoader, [
   model.colorMap,
   model.metalMap,
    model.normalMap,
    model.roughnessMap,
    model.aoMap
  ])
  key.traverse( function ( child ) {

    if ( child.isMesh ) {
        
        child.material.map = colorMap;
       
        child.material.aoMap =aoMap;
     
        child.material.normalMap = normalMap;
        
        child.material.metalMap = metalMap; 
     
        child.material.roughnessMap = roughnessMap; 
      }
  
  } );
  useFrame((state, delta) => {
  

    mesh.current.rotation.y += 0.05
  
  })

  return (
    <mesh scale={model.scale} position={model.position} rotation={model.rotation}  ref={mesh}>
        <primitive   object={key} />

    </mesh>
  )


}


function Camera(){
  const mesh = useRef()
  const model = {
    name:'camera',
    url:'https://res.cloudinary.com/dvr397xda/raw/upload/v1659468154/cube/basic/wingo/Canon_AT-1_o5weeb.fbx',
    metalMap:'https://res.cloudinary.com/dvr397xda/image/upload/v1659468224/cube/basic/wingo/CAM0001_Textures_METL_2k_z9glb8.png',
    normalMap:'https://res.cloudinary.com/dvr397xda/image/upload/v1659468230/cube/basic/wingo/CAM0001_Textures_NRML_2k_ou6yul.png',
    roughnessMap:'https://res.cloudinary.com/dvr397xda/image/upload/v1659468224/cube/basic/wingo/CAM0001_Textures_ROUGH_2k_rx6oey.png',
    colorMap:'https://res.cloudinary.com/dvr397xda/image/upload/v1659468228/cube/basic/wingo/CAM0001_Textures_COL_2k_wrktl1.png',
    aoMap:'https://res.cloudinary.com/dvr397xda/image/upload/v1659468222/cube/basic/wingo/CAM0001_Textures_AO_2k_ilwxtp.png',
    scale:14,
    position:[0,-0.5,0],
    rotation:[0,0,0],
}
  let key = useFBX(model.url)
  const [colorMap, metalMap, normalMap, roughnessMap, aoMap] = useLoader(THREE.TextureLoader, [
   model.colorMap,
   model.metalMap,
    model.normalMap,
    model.roughnessMap,
    model.aoMap
  ])
  key.traverse( function ( child ) {

    if ( child.isMesh ) {
        
        child.material.map = colorMap;
       
        child.material.aoMap =aoMap;
     
        child.material.normalMap = normalMap;
        
        child.material.metalMap = metalMap; 
     
        child.material.roughnessMap = roughnessMap; 
      }
  
  } );
  useFrame((state, delta) => {
  

    mesh.current.rotation.y += 0.005
  
  })

  return (
    <mesh scale={model.scale} position={model.position} rotation={model.rotation}  ref={mesh}>
        <primitive   object={key} />

    </mesh>
  )


}

function Vase(){
    const mesh = useRef()
    const model = {
      name:'vase',
      url:'https://res.cloudinary.com/dvr397xda/raw/upload/v1675192221/model/vase/Egyptian_Lotus_Vase_fynb36.fbx',
     
      normalMap:'https://res.cloudinary.com/dvr397xda/image/upload/v1675192351/model/vase/Egyptian_Lotus_Vase_NormalMap_wzupm9.png',
      
      colorMap:'https://res.cloudinary.com/dvr397xda/image/upload/v1675192350/model/vase/Egyptian_Lotus_Vase_Diffuse_n5mhxq.png',
    
      scale:0.02,
      position:[0,-2,0],
      rotation:[0,0,0],
  }
    let key = useFBX(model.url)
    const [colorMap, normalMap] = useLoader(THREE.TextureLoader, [
     model.colorMap,
    
      model.normalMap,
   
    ])
    key.traverse( function ( child ) {
  
      if ( child.isMesh ) {
          
          child.material.map = colorMap;
         
          
       
          child.material.normalMap = normalMap;
          
        
        }
    
    } );
    useFrame((state, delta) => {
    
  
      mesh.current.rotation.y += 0.005
    
    })
  
    return (
      <mesh scale={model.scale} position={model.position} rotation={model.rotation}  ref={mesh}>
          <primitive   object={key} />
  
      </mesh>
    )
  
  
  }

function Keys(){
  const mesh = useRef()
  const model = {
    name:'key',
    url:'https://res.cloudinary.com/dvr397xda/raw/upload/v1659109343/cube/basic/lily/KeyChain_sgoo39.FBX',
    metalMap:'https://res.cloudinary.com/dvr397xda/image/upload/v1659109362/cube/basic/lily/KeyChainMetallic_hipbo4.png',
    normalMap:'https://res.cloudinary.com/dvr397xda/image/upload/v1659109365/cube/basic/lily/KeyChainNormal_jp5gqw.png',
    roughnessMap:'https://res.cloudinary.com/dvr397xda/image/upload/v1659109360/cube/basic/lily/KeyChainRough_m2zisg.png',
    colorMap:'https://res.cloudinary.com/dvr397xda/image/upload/v1659109363/cube/basic/lily/KeyChainAlbedo_kavfgg.png',
    aoMap:'https://res.cloudinary.com/dvr397xda/image/upload/v1659109363/cube/basic/lily/KeyChainAOC_gdexfb.png',
    scale:10,
    position:[0,-0.5,0],
    rotation:[0,0,Math.PI / 2.5],
}
  let key = useFBX(model.url)
  const [colorMap, metalMap, normalMap, roughnessMap, aoMap] = useLoader(THREE.TextureLoader, [
   model.colorMap,
   model.metalMap,
    model.normalMap,
    model.roughnessMap,
    model.aoMap
  ])
  key.traverse( function ( child ) {

    if ( child.isMesh ) {
        
        child.material.map = colorMap;
       
        child.material.aoMap =aoMap;
     
        child.material.normalMap = normalMap;
        
        child.material.metalMap = metalMap; 
     
        child.material.roughnessMap = roughnessMap; 
      }
  
  } );
  useFrame((state, delta) => {
  

    mesh.current.rotation.y += 0.005
  
  })

  return (
    <mesh scale={model.scale} position={model.position} rotation={model.rotation}  ref={mesh}>
        <primitive   object={key} />

    </mesh>
  )


}

function Shoe(){
    const mesh = useRef()
    const model = {
      name:'shoe',
      url:'https://res.cloudinary.com/dvr397xda/raw/upload/v1675194369/model/aj/sketchfab_shoe_ymwvdq.fbx',
    
      normalMap:'https://res.cloudinary.com/dvr397xda/image/upload/v1675194386/model/aj/shoe_Normal_o5jlt9.png',
      roughnessMap:'https://res.cloudinary.com/dvr397xda/image/upload/v1675194380/model/aj/shoe_Roughness_l5b4m4.png',
      colorMap:'https://res.cloudinary.com/dvr397xda/image/upload/v1675194384/model/aj/shoe_BaseColor_lgyfec.png',
     
      scale:0.08,
      position:[0,-0.5,0],
      rotation:[0,0,0],
  }
    let key = useFBX(model.url)
    const [colorMap,  normalMap, roughnessMap] = useLoader(THREE.TextureLoader, [
     model.colorMap,
      model.normalMap,
      model.roughnessMap,
    ])
    key.traverse( function ( child ) {
  
      if ( child.isMesh ) {
          
          child.material.map = colorMap;
         
       
          child.material.normalMap = normalMap;
          
       
          child.material.roughnessMap = roughnessMap; 
        }
    
    } );
    useFrame((state, delta) => {
    
  
      mesh.current.rotation.y += 0.005
    
    })
  
    return (
      <mesh scale={model.scale} position={model.position} rotation={model.rotation}  ref={mesh}>
          <primitive   object={key} />
  
      </mesh>
    )
  
  
  }

  function Mask(){
    const mesh = useRef()
    const model = {
      name:'mask',
      url:'https://res.cloudinary.com/dvr397xda/raw/upload/v1675283634/model/mask/model_1_fl4jcz.fbx',
      metalMap:'https://res.cloudinary.com/dvr397xda/image/upload/v1675283692/model/mask/Mask_metallic_lvk3x2.jpg',
      normalMap:'https://res.cloudinary.com/dvr397xda/image/upload/v1675283696/model/mask/Mask_normal_avoux7.png',
      roughnessMap:'https://res.cloudinary.com/dvr397xda/image/upload/v1675283692/model/mask/Mask_roughness_a11djq.jpg',
      colorMap:'https://res.cloudinary.com/dvr397xda/image/upload/v1675283693/model/mask/Mask_albedo_quofs8.jpg',
      aoMap:'https://res.cloudinary.com/dvr397xda/image/upload/v1675283692/model/mask/Mask_AO_ityzvb.jpg',
      scale:1.5,
      position:[0,0,0],
      rotation:[0,0,0],
  }
    let key = useFBX(model.url)
    const [colorMap, metalMap, normalMap, roughnessMap, aoMap] = useLoader(THREE.TextureLoader, [
     model.colorMap,
     model.metalMap,
      model.normalMap,
      model.roughnessMap,
      model.aoMap
    ])
    key.traverse( function ( child ) {
  
      if ( child.isMesh ) {
          
          child.material.map = colorMap;
         
          child.material.aoMap =aoMap;
       
          child.material.normalMap = normalMap;
          
          child.material.metalMap = metalMap; 
       
          child.material.roughnessMap = roughnessMap; 
        }
    
    } );
    useFrame((state, delta) => {
    
  
      mesh.current.rotation.y += 0.005
    
    })
  
    return (
      <mesh scale={model.scale} position={model.position} rotation={model.rotation}  ref={mesh}>
          <primitive   object={key} />
  
      </mesh>
    )
  
  
  }

  function Sport(){
    const mesh = useRef()
    const model = {
      name:'key',
      url:'https://res.cloudinary.com/dvr397xda/raw/upload/v1675205644/model/sport/Sport_cexpfe.fbx',
      metalMap:'https://res.cloudinary.com/dvr397xda/image/upload/v1675205641/model/sport/Sport_metalness_1011_zrhkud.png',
      normalMap:'https://res.cloudinary.com/dvr397xda/image/upload/v1675205641/model/sport/Sport_normal_1011_wdvryv.png',
      roughnessMap:'https://res.cloudinary.com/dvr397xda/image/upload/v1675205641/model/sport/Sport_roughness_1011_br5rrn.png',
      colorMap:'https://res.cloudinary.com/dvr397xda/image/upload/v1675205641/model/sport/Sport_diffuse_1011_bauego.png',
      aoMap:'https://res.cloudinary.com/dvr397xda/image/upload/v1675205641/model/sport/Sport_opacity_1011_doe13b.png',

      scale:0.005,
      position:[0,-6,0],
      rotation:[0,0,0],
  }
    let key = useFBX(model.url)
    const [colorMap, metalMap, normalMap, roughnessMap,    aoMap,
] = useLoader(THREE.TextureLoader, [
     model.colorMap,
     model.metalMap,
      model.normalMap,
      model.roughnessMap,
      model.aoMap
    ])
    key.traverse( function ( child ) {
  
      if ( child.isMesh ) {
          
          child.material.map = colorMap;
         
       
          child.material.normalMap = normalMap;
          
          child.material.metalMap = metalMap; 
       
          child.material.roughnessMap = roughnessMap; 
          child.material.aoMap = aoMap; 

        }
    
    } );
    useFrame((state, delta) => {
    
  
      mesh.current.rotation.y += 0.005
    
    })
  
    return (
      <mesh scale={model.scale} position={model.position} rotation={model.rotation}  ref={mesh}>
          <primitive   object={key} />
  
      </mesh>
    )
  
  
  }


function House(){
  const mesh = useRef()
  const model = {
    name:'house',
    url:'https://res.cloudinary.com/dvr397xda/raw/upload/v1661194031/cube/basic/ben/High_Poly_House_Full2_gqcfiv.fbx',
 
    scale:0.00125,
    position:[0,-1,0],
    rotation:[0,0,0],
}
  let key = useFBX(model.url)
 

  useFrame((state, delta) => {
  

    mesh.current.rotation.y += 0.005
  
  })

  return (
    <mesh scale={model.scale} position={model.position} rotation={model.rotation}  ref={mesh}>
        <primitive   object={key} />

    </mesh>
  )


}


function Japan(){
  const mesh = useRef()
  const model = {
    name:'japan',
    url:'https://res.cloudinary.com/dvr397xda/raw/upload/v1662070813/cube/kinha%20sushi/Japanese_Garden_Scene_kkqvcn.fbx',
    metalMap:'',
    normalMap:'https://res.cloudinary.com/dvr397xda/image/upload/v1659726267/cube/Happy%20Town/model_normal_tsbl8o.jpg',
    roughnessMap:'',
    colorMap:'https://res.cloudinary.com/dvr397xda/image/upload/v1659726193/cube/Happy%20Town/model_avhsp5.jpg',
    aoMap:'',
    scale:0.002,
    position:[0,-1,0],
    rotation:[0,0,0],
}
  let key = useFBX(model.url)
  const [colorMap,  normalMap] = useLoader(THREE.TextureLoader, [
   model.colorMap,
  
    model.normalMap,
   
  ])
  key.traverse( function ( child ) {

    if ( child.isMesh ) {
        
        child.material.map = colorMap;
       
    
     
        child.material.normalMap = normalMap;
        
     
      }
  
  } );
  useFrame((state, delta) => {
  

    mesh.current.rotation.y += 0.005
  
  })

  return (
    <mesh scale={model.scale} position={model.position} rotation={model.rotation}  ref={mesh}>
        <primitive   object={key} />

    </mesh>
  )


}


const  Model=({name})=>{
   
  if(name==='earth'){
    return <Earth/>
  }
  else if(name==='rose'){
    return <Rose/>

  }
  else if(name==='computer'){
    return <Macbook/>
  }
  else if(name==='recorder'){
    return <VideoCamera/>
  }
  else if(name==='camera'){
    return <Camera/>
  }
  else if(name==='house'){
    return <House/> 
  }
  else if(name==='japan'){
    return <Japan/>
  }
  else if(name==='key'){
    return <Keys/>
  }
  else if(name==='vase'){
    return <Vase/>
  }
  else if(name==='shoe'){
    return <Shoe/>
  }
  else if(name==='sport'){
    return <Sport/>
  }
  else if(name==='mask'){
    return <Mask/>
  }

  
  else{
    return <Earth/>
  }
}









export default function ModelCase() {


    const{ name } = useParams();
  


  return (
    <div className="container-card2" style={{backgroundImage:`url(${name.gif})`}}  >
      <Link id='return-home' to='/model'><img src="https://res.cloudinary.com/dvr397xda/image/upload/v1664834364/cube/Free/metalogo_ctuhtw.png" alt="" /></Link>

    <Canvas shadows camera={{ position: [0, 0, 5] }}   gl={{ alpha: true, antialias: true}}  className='canvas-wrap'>
   
    <ambientLight intensity={0.8} />
      <spotLight position={[10, 10, 10]} angle={0.15} penumbra={1} shadow-mapSize={[512, 512]} castShadow />
    {/* <pointLight position={[0,200,200]} intensity={0.3} /> */}
    {/* <spotLight position={[0,10,0]}/> */}

  <PresentationControls polar={[-Math.PI / 2.5, Math.PI / 2.5]} global rotation={[0, 0.3, 0]}  azimuth={[-Infinity, Infinity]}>
      <Suspense fallback={<Loader/>}>

  
      <Model name={name}/>
      <ContactShadows rotation-x={Math.PI / 2} position={[0, -1.4, 0]} opacity={0.75} width={10} height={10} blur={2.6} far={2} />
      {/* <Environment preset="city" /> */}
    </Suspense>
    </PresentationControls>
    </Canvas>

    </div>
  )
}

