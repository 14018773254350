const data = [
    { 
        id:'xintian',
        boss:'johnny',
        name:'Xintian inc',
        tel:'3015200518',
        cell:'2034569888',
        email:'johnny@xintian.com',
        douyin:'https://v.douyin.com/YMuSdCe/',
        website:'https://xingtianstudio.com',
        address:'4306 Main St,Flushing,NY,11355',
        map:'https://www.google.com/maps/place/43-06+Main+St,+Flushing,+NY+11355/@40.7522664,-73.8315613,17z/data=!3m1!4b1!4m5!3m4!1s0x89c2606cd640cb53:0x141db878e9815fe!8m2!3d40.7522665!4d-73.8270766',
        avatar:'https://res.cloudinary.com/dvr397xda/image/upload/v1654544385/cube/4_ps8jf5.png',
        images:[
            'https://res.cloudinary.com/dvr397xda/image/upload/v1655154053/cube/WechatIMG8400_r9snzm.jpg', //right
            'https://res.cloudinary.com/dvr397xda/image/upload/v1655154053/cube/WechatIMG8399_nbnojk.jpg', //left
            'https://res.cloudinary.com/dvr397xda/image/upload/v1655154770/cube/WechatIMG8403_z2nmor.jpg', 
            'https://res.cloudinary.com/dvr397xda/image/upload/v1650920511/cube/%E7%8E%BB%E7%92%832_ukdjz2.jpg', 
            'https://res.cloudinary.com/dvr397xda/image/upload/v1655154053/cube/WechatIMG8401_odpkgf.jpg', //front
            'https://res.cloudinary.com/dvr397xda/image/upload/v1655220643/cube/WechatIMG326_mqmiea.jpg'//back
    ],
    background:'https://res.cloudinary.com/dvr397xda/image/upload/v1653927826/cube/1921iq6-a-room-that-has-a-umbrella-in-her-hand_rainyw.jpg',
    mp3:'https://res.cloudinary.com/dvr397xda/video/upload/v1654542301/cube/f3123acb3ac425e2ff43452046ec6b23_ip7i0c.mp3',
    video:'https://www.youtube.com/watch?v=DYb8dudXY9U&list=PLid5kTu-xpFIg5gLDt17SblFa_fCfvwmT',
    facebook:'https://www.facebook.com/johnny.chen.792197',
    linkedin:'https://www.linkedin.com/in/johnny-chen-358b971bb/',
    show:[
        'https://res.cloudinary.com/dvr397xda/image/upload/v1655150127/cube/WechatIMG8392_lfrxly.jpg',
        'https://res.cloudinary.com/dvr397xda/image/upload/v1655150127/cube/WechatIMG8393_n2lckn.jpg',
        'https://res.cloudinary.com/dvr397xda/image/upload/v1655150127/cube/WechatIMG8398_kx1bny.jpg',
        'https://res.cloudinary.com/dvr397xda/image/upload/v1655150127/cube/WechatIMG8395_dfgfjt.jpg',
        'https://res.cloudinary.com/dvr397xda/image/upload/v1655150127/cube/WechatIMG8394_elkax7.jpg',

    ],
    gif:'https://res.cloudinary.com/dvr397xda/image/upload/v1650923631/cube/tenor_o9dreg.gif'

    },
    { 
        id:'weiiz',
        boss:'johnny',
        name:'Weiiz inc',
        tel:'3015200518',
        email:'johnny@xintian.com',
        website:'https://www.weiiz.com/#/homepage',
        address:'4306 Main St,Flushing,NY,11355',
        map:'https://www.google.com/maps/place/43-06+Main+St,+Flushing,+NY+11355/@40.7522664,-73.8315613,17z/data=!3m1!4b1!4m5!3m4!1s0x89c2606cd640cb53:0x141db878e9815fe!8m2!3d40.7522665!4d-73.8270766',
        avatar:'https://res.cloudinary.com/dvr397xda/image/upload/v1654294846/cube/weiiz_iojznp.png',
        images:[
            'https://res.cloudinary.com/dvr397xda/image/upload/v1654543127/cube/WechatIMG292_s0vtyp.jpg',
            'https://res.cloudinary.com/dvr397xda/image/upload/v1654541482/cube/WechatIMG285_d2frox.jpg', 
            'https://res.cloudinary.com/dvr397xda/image/upload/v1650920511/cube/%E7%8E%BB%E7%92%832_ukdjz2.jpg', 
            'https://res.cloudinary.com/dvr397xda/image/upload/v1650920511/cube/%E7%8E%BB%E7%92%832_ukdjz2.jpg', 
            'https://res.cloudinary.com/dvr397xda/image/upload/v1654294846/cube/weiiz_iojznp.png', 
            'https://res.cloudinary.com/dvr397xda/image/upload/v1650920511/cube/%E7%8E%BB%E7%92%832_ukdjz2.jpg'
    ],
    background:'https://res.cloudinary.com/dvr397xda/image/upload/v1653927826/cube/1921iq6-a-room-that-has-a-umbrella-in-her-hand_rainyw.jpg',
    mp3:'https://res.cloudinary.com/dvr397xda/video/upload/v1654542301/cube/f3123acb3ac425e2ff43452046ec6b23_ip7i0c.mp3',
    gif:'https://res.cloudinary.com/dvr397xda/image/upload/v1654810515/cube/13_w8qusw.gif'
    },
    { 
        id:'groupchop',
        boss:'johnny',
        name:'Group Chop',
        tel:'3015200518',
        email:'johnny@xintian.com',
        website:'https://www.groupchop.com',
        address:'4306 Main St,Flushing,NY,11355',
        map:'https://www.google.com/maps/place/43-06+Main+St,+Flushing,+NY+11355/@40.7522664,-73.8315613,17z/data=!3m1!4b1!4m5!3m4!1s0x89c2606cd640cb53:0x141db878e9815fe!8m2!3d40.7522665!4d-73.8270766',
        avatar:'https://res.cloudinary.com/dvr397xda/image/upload/v1654543427/cube/3_wazeoc.png',
        images:[
            'https://res.cloudinary.com/dvr397xda/image/upload/v1654543127/cube/WechatIMG292_s0vtyp.jpg',
            'https://res.cloudinary.com/dvr397xda/image/upload/v1654541482/cube/WechatIMG285_d2frox.jpg', 
            'https://res.cloudinary.com/dvr397xda/image/upload/v1650920511/cube/%E7%8E%BB%E7%92%832_ukdjz2.jpg', 
            'https://res.cloudinary.com/dvr397xda/image/upload/v1650920511/cube/%E7%8E%BB%E7%92%832_ukdjz2.jpg', 
            'https://res.cloudinary.com/dvr397xda/image/upload/v1654543427/cube/3_wazeoc.png', 
            'https://res.cloudinary.com/dvr397xda/image/upload/v1650920511/cube/%E7%8E%BB%E7%92%832_ukdjz2.jpg'
    ],
    background:'https://res.cloudinary.com/dvr397xda/image/upload/v1653927826/cube/1921iq6-a-room-that-has-a-umbrella-in-her-hand_rainyw.jpg',
    mp3:'https://res.cloudinary.com/dvr397xda/video/upload/v1654542301/cube/f3123acb3ac425e2ff43452046ec6b23_ip7i0c.mp3',
    gif:'https://res.cloudinary.com/dvr397xda/image/upload/v1650903783/cube/ezgif.com-gif-maker_b2xoms.gif'
    },
    { 
        id:'xintianprint',
        boss:'johnny',
        name:'Xingtian Print',
        tel:'3015200518',
        email:'johnny@xintian.com',
        website:'https://www.groupchop.com',
        address:'4306 Main St,Flushing,NY,11355',
        map:'https://www.google.com/maps/place/43-06+Main+St,+Flushing,+NY+11355/@40.7522664,-73.8315613,17z/data=!3m1!4b1!4m5!3m4!1s0x89c2606cd640cb53:0x141db878e9815fe!8m2!3d40.7522665!4d-73.8270766',
        avatar:'https://res.cloudinary.com/dlapk94rx/image/upload/v1609885844/Logo_dgkrtv.png',
        images:[
            'https://res.cloudinary.com/dvr397xda/image/upload/v1654543127/cube/WechatIMG292_s0vtyp.jpg',
            'https://res.cloudinary.com/dvr397xda/image/upload/v1654541482/cube/WechatIMG285_d2frox.jpg', 
            'https://res.cloudinary.com/dvr397xda/image/upload/v1650920511/cube/%E7%8E%BB%E7%92%832_ukdjz2.jpg', 
            'https://res.cloudinary.com/dvr397xda/image/upload/v1650920511/cube/%E7%8E%BB%E7%92%832_ukdjz2.jpg', 
            'https://res.cloudinary.com/dlapk94rx/image/upload/v1609885844/Logo_dgkrtv.png', 
            'https://res.cloudinary.com/dvr397xda/image/upload/v1650920511/cube/%E7%8E%BB%E7%92%832_ukdjz2.jpg'
    ],
    background:'https://res.cloudinary.com/dvr397xda/image/upload/v1653927826/cube/1921iq6-a-room-that-has-a-umbrella-in-her-hand_rainyw.jpg',
    mp3:'https://res.cloudinary.com/dvr397xda/video/upload/v1654542301/cube/f3123acb3ac425e2ff43452046ec6b23_ip7i0c.mp3',
    gif:'https://res.cloudinary.com/dvr397xda/image/upload/v1654807215/cube/giphy_pc3396.gif'
    },
    { 
        id:'ankesppa',
        boss:'johnny',
        name:'Anke Spa',
        tel:'3015200518',
        email:'johnny@xintian.com',
        website:'https://www.weiizspa.com/',
        address:'4306 Main St,Flushing,NY,11355',
        map:'https://www.google.com/maps/place/43-06+Main+St,+Flushing,+NY+11355/@40.7522664,-73.8315613,17z/data=!3m1!4b1!4m5!3m4!1s0x89c2606cd640cb53:0x141db878e9815fe!8m2!3d40.7522665!4d-73.8270766',
        avatar:'https://res.cloudinary.com/dvr397xda/image/upload/v1654543429/cube/1_d2txto.png',
        images:[
            'https://res.cloudinary.com/dvr397xda/image/upload/v1654543127/cube/WechatIMG292_s0vtyp.jpg',
            'https://res.cloudinary.com/dvr397xda/image/upload/v1654541482/cube/WechatIMG285_d2frox.jpg', 
            'https://res.cloudinary.com/dvr397xda/image/upload/v1650920511/cube/%E7%8E%BB%E7%92%832_ukdjz2.jpg', 
            'https://res.cloudinary.com/dvr397xda/image/upload/v1650920511/cube/%E7%8E%BB%E7%92%832_ukdjz2.jpg', 
            'https://res.cloudinary.com/dvr397xda/image/upload/v1654543429/cube/1_d2txto.png', 
            'https://res.cloudinary.com/dvr397xda/image/upload/v1650920511/cube/%E7%8E%BB%E7%92%832_ukdjz2.jpg'
    ],
    background:'https://res.cloudinary.com/dvr397xda/image/upload/v1653927826/cube/1921iq6-a-room-that-has-a-umbrella-in-her-hand_rainyw.jpg',
    mp3:'https://res.cloudinary.com/dvr397xda/video/upload/v1654542301/cube/f3123acb3ac425e2ff43452046ec6b23_ip7i0c.mp3',
    gif:'https://res.cloudinary.com/dvr397xda/image/upload/v1651098336/cube/fireworks-united-states-of-america_c2vp0o.gif'
    }
]

const fetchSingle = ()=>{
    return data
}

export default fetchSingle