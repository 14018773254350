import { Suspense ,useLayoutEffect} from 'react'
import { Canvas } from '@react-three/fiber'
import { Environment, ContactShadows, OrbitControls, PerspectiveCamera, Plane, BakeShadows ,Html, useProgress,useGLTF} from '@react-three/drei'
import * as THREE from 'three'

function Museum(props) {
  const { scene } = useGLTF('/NFTSHOW_adjust.glb')
  useLayoutEffect(() => {
    scene.traverse((o) => {
      console.log(o)
      // if (o.isMesh) {
      //   applyProps(o, { castShadow: true, receiveShadow: true, 'material-envMapIntensity': 0.3 })
       
      // }
   
    })
  }, [scene])
  return <primitive  object={scene} {...props} />
}
useGLTF.preload('/NFTSHOW_adjust.glb')

export default function Lambo() {
  return (
    <>
      <Canvas shadows gl={{ toneMappingExposure: 0.7 }}>
      <Suspense fallback={<Loader />}>
          <Environment files={'https://res.cloudinary.com/dvr397xda/raw/upload/v1656600404/cube/museum/old_depot_2k_q5bpht.hdr'} exposure={1} ground={{ height: 32, radius: 130 }} />
          <spotLight angle={0.5} castShadow position={[-80, 200, -100]} intensity={1} shadow-mapSize={[515, 128]} />

         <Car/>
         {/* <Museum scale={2}/> */}

          <Plane receiveShadow position={[0, 0.1, 0]} rotation-x={-Math.PI / 2} args={[500, 500]}>
            <shadowMaterial opacity={0.65} />
          </Plane>
          <ContactShadows frames={1} resolution={1024} scale={110} blur={0.5} opacity={0.2} far={100} />
          <BakeShadows />
        </Suspense>

        <OrbitControls minDistance={60} maxDistance={160} autoRotate autoRotateSpeed={0.5} enableZoom={true} enablePan={false} minPolarAngle={0} maxPolarAngle={Math.PI / 2.15} makeDefault maxAzimuthAngle={-Math.PI/2,Math.PI/2}/>
        <PerspectiveCamera makeDefault position={[-30, 100, 120]} fov={30} />
      </Canvas>
    
    </>
  )
}
function Loader() {
    const { progress } = useProgress()
    return <Html center>Loading ...{progress.toFixed(0)}%</Html>
  }




function Car() {
  

    const { scene, nodes, materials } = useGLTF('https://res.cloudinary.com/dvr397xda/image/upload/v1656530386/cube/museum/lambo_mnijaz.glb')
    useLayoutEffect(() => {
      scene.traverse((obj) => obj.type === 'Mesh' && (obj.receiveShadow = obj.castShadow = true))
      Object.assign(nodes.wheel003_020_2_Chrome_0.material, { metalness: 0.9, roughness: 0.4, color: new THREE.Color('#020202') })
      Object.assign(materials.WhiteCar, { roughness: 0.0, metalness: 0.3, emissive: new THREE.Color('#500000'), envMapIntensity: 0.5 })
    }, [scene, nodes, materials])
  
    return<primitive position={[0,11,0]} object={scene} rotation={[0, -Math.PI/2,0]} scale={0.15}/>
  
  
  }
  
  
//   useGLTF.preload('https://res.cloudinary.com/dvr397xda/image/upload/v1656530386/cube/museum/lambo_mnijaz.glb')