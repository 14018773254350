import React from 'react'
import Rotate from '../../components/Rotate/index'
import './index.scss'

export default function ProductPage() {
  
  return (
    <div className="product-page">
        
        
        <h2>

         ProductPage
   
        </h2>

        <Rotate/>
         </div>
  )
}
