const data = [

 
    { 
        id:'ben',
        name:'Xiao Feng Pan',
        tel:'9176788888',
        cell:'',
        email:'info@winzonere.com',
        address:'81-15 Queens Blvd 2nd FL Elmhurst,NY,11373',
        map:'https://goo.gl/maps/HrFbYuTnHaQjbRoh9',
        video:'https://youtu.be/1Qd-nAlOLIY',
        facebook:'',
        background:'https://res.cloudinary.com/dvr397xda/image/upload/v1653927826/cube/1921iq6-a-room-that-has-a-umbrella-in-her-hand_rainyw.jpg',
        linkedin:'',
        website:'https://www.winzonerealty.com/Home',
        images:[
            'https://res.cloudinary.com/dvr397xda/image/upload/v1658518055/cube/weiiz/WechatIMG9330_gm5mez.png', //right
            'https://res.cloudinary.com/dvr397xda/image/upload/v1658518055/cube/weiiz/WechatIMG9330_gm5mez.png', //left
            'https://res.cloudinary.com/dvr397xda/image/upload/v1658518055/cube/weiiz/WechatIMG9330_gm5mez.png', //glass
            'https://res.cloudinary.com/dvr397xda/image/upload/v1658518055/cube/weiiz/WechatIMG9330_gm5mez.png', //glass
            'https://res.cloudinary.com/dvr397xda/image/upload/v1661288152/cube/basic/ben/WechatIMG10131_limsj0.png', //front
            'https://res.cloudinary.com/dvr397xda/image/upload/v1661202373/cube/basic/ben/WechatIMG10095_vcm63t.png'//back
    ],
  
    gif:'https://res.cloudinary.com/dvr397xda/image/upload/v1661201236/cube/basic/ben/06_dribble_acvary.gif',
    loadImg:'',
    qrCode:'https://res.cloudinary.com/dvr397xda/image/upload/v1661192637/cube/basic/ben/WechatIMG686_chvqu1.jpg',
    model:{
        name:'house',
        url:'https://res.cloudinary.com/dvr397xda/raw/upload/v1661194031/cube/basic/ben/High_Poly_House_Full2_gqcfiv.fbx',
        metalMap:'',
        normalMap:'',
        roughnessMap:'',
        colorMap:'https://res.cloudinary.com/dvr397xda/image/upload/v1661194069/cube/basic/ben/House_Texture_otevwq.png',
        aoMap:'',
        scale:0.00125,
        position:[0,-1,0],
        rotation:[0,0,0],
    },
    living:'',
    isMusic:true,
    music:[{
        album:'周杰伦',
        name:'简单爱',
        track:'Jay', 
        url:'https://res.cloudinary.com/dvr397xda/video/upload/v1661202691/cube/basic/ben/official-music-video_nsd8gr.mp3', 
        image:'https://res.cloudinary.com/dvr397xda/image/upload/v1660938134/music/cd-player_1_nxixox.png'
    }],
    isGallery:true,
    gallery:[
        'https://res.cloudinary.com/dvr397xda/image/upload/v1661207186/cube/basic/ben/WechatIMG10104_moae1c.png',
        'https://res.cloudinary.com/dvr397xda/image/upload/v1661207186/cube/basic/ben/WechatIMG10105_ntjg6b.png',
        'https://res.cloudinary.com/dvr397xda/image/upload/v1661207186/cube/basic/ben/WechatIMG10103_npa3ve.png',
        'https://res.cloudinary.com/dvr397xda/image/upload/v1661207186/cube/basic/ben/WechatIMG10106_jltv0v.png'
    ]
    },

  




]

const fetchRealty = ()=>{
    return data;
}


export default fetchRealty