const data = [

    { 
        id:'lily-zou',
        name:'Lily Zou',
        tel:'3478371813',
        cell:'3478371813',
        email:'Lucksky8777@gmail.com',
        address:'4306 Main St,Flushing,NY,11355',
        map:'https://www.google.com/maps/place/43-06+Main+St,+Flushing,+NY+11355/@40.7522664,-73.8315613,17z/data=!3m1!4b1!4m5!3m4!1s0x89c2606cd640cb53:0x141db878e9815fe!8m2!3d40.7522665!4d-73.8270766',
        video:'',
        facebook:'',
        background:'https://res.cloudinary.com/dvr397xda/image/upload/v1653927826/cube/1921iq6-a-room-that-has-a-umbrella-in-her-hand_rainyw.jpg',
        linkedin:'',
        website:'https://360realty.online/#/',
        images:[
            'https://res.cloudinary.com/dvr397xda/image/upload/v1658519035/cube/WechatIMG9330_gm5mez_dpifk5.png', //right
            'https://res.cloudinary.com/dvr397xda/image/upload/v1658519035/cube/WechatIMG9330_gm5mez_dpifk5.png', //left
            'https://res.cloudinary.com/dvr397xda/image/upload/v1658519035/cube/WechatIMG9330_gm5mez_dpifk5.png', //glass
            'https://res.cloudinary.com/dvr397xda/image/upload/v1658519035/cube/WechatIMG9330_gm5mez_dpifk5.png', //glass
            'https://res.cloudinary.com/dvr397xda/image/upload/v1660075871/cube/business%20card/WechatIMG9781_isnmra.jpg', //front
            'https://res.cloudinary.com/dvr397xda/image/upload/v1660075871/cube/business%20card/WechatIMG9782_abdiop.jpg'//back
    ],

  
    gif:'https://res.cloudinary.com/dvr397xda/image/upload/v1659106966/cube/basic/lily/Espw_olktz5.gif',
    loadImg:'https://res.cloudinary.com/dvr397xda/image/upload/v1659108260/cube/basic/lily/home-for-sale-in-florida-home_yby2wf.gif',
    qrCode:'https://res.cloudinary.com/dvr397xda/image/upload/v1659997040/cube/basic/lily/WechatIMG124_l0rvpj.jpg',
    model:{
        name:'key',
        url:'https://res.cloudinary.com/dvr397xda/raw/upload/v1659109343/cube/basic/lily/KeyChain_sgoo39.FBX',
        metalMap:'https://res.cloudinary.com/dvr397xda/image/upload/v1659109362/cube/basic/lily/KeyChainMetallic_hipbo4.png',
        normalMap:'https://res.cloudinary.com/dvr397xda/image/upload/v1659109365/cube/basic/lily/KeyChainNormal_jp5gqw.png',
        roughnessMap:'https://res.cloudinary.com/dvr397xda/image/upload/v1659109360/cube/basic/lily/KeyChainRough_m2zisg.png',
        colorMap:'https://res.cloudinary.com/dvr397xda/image/upload/v1659109363/cube/basic/lily/KeyChainAlbedo_kavfgg.png',
        aoMap:'https://res.cloudinary.com/dvr397xda/image/upload/v1659109363/cube/basic/lily/KeyChainAOC_gdexfb.png',
        scale:8,
        position:[0,-2,0],
        rotation:[0,0,Math.PI / 2.5],
    },
    phonePos:[0,-0.4,0.05],
    cellPos:[0.8,0,-0.05],
    webPos:[0,0.2,0.05],
    mapPos:[0.8,-0.4,-0.05],
    qrPos:[-1,-0.1,-0.05],
    emailPos:[0.8,-0.2,-0.05]
    },


  




]

const fetchBusiness = ()=>{
    return data;
}


export default fetchBusiness