import React,{useState,useEffect,useRef,Suspense,useCallback,useMemo}from 'react'
import { Canvas, useFrame,extend,useThree,useLoader,} from '@react-three/fiber'
import * as THREE from "three";
import HTMLFlipBook from "react-pageflip";
import {useParams} from 'react-router-dom'
import Player from '../../components/Player/index'

// import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { TextureLoader } from 'three/src/loaders/TextureLoader.js';
import fetchBasic from '../../pro'
import {useGLTF,useFBX,OrbitControls,Sky,useProgress,Html,useAspect} from '@react-three/drei'
import Grass from "./Grass"

import './index.scss'

function Video({people,position}) {
  const scale = useAspect(1920, 1080, 2)
  // Video texture by: https://www.pexels.com/@rostislav/
  const [video] = useState(() =>
    Object.assign(document.createElement('video'), { src:people.videoUrl , crossOrigin: 'Anonymous', loop: true, muted: true })
  )
  useEffect(() => void video.play(), [video])
  return (
    <mesh scale={scale} position={position}>
      <planeGeometry />
      <meshBasicMaterial toneMapped={true} side={THREE.DoubleSide}>
        <videoTexture attach="map" args={[video]} encoding={THREE.sRGBEncoding} />
      </meshBasicMaterial>
    </mesh>
  )
}


function Loader() {
    const { progress } = useProgress()
    return <Html center> <h2 id='loadText'>
          Loading ...{progress.toFixed(0)}%
          <img id='loadImage' src="https://res.cloudinary.com/dvr397xda/image/upload/v1659635547/cube/hunqing/loader_wqff7g.gif" alt="" />
          </h2></Html>
  }

function Rose(){

  const group = useRef();
  const { nodes, materials } = useGLTF("https://res.cloudinary.com/dvr397xda/image/upload/v1659630975/cube/hunqing/rose_jzkfup.glb");


  useFrame((state, delta) => {
      
    
    group.current.rotation.y += 0.005
  
  })
  return (
    <group ref={group}  dispose={null}>
      <group rotation={[-Math.PI / 2, 0, 0]}>
        <group rotation={[Math.PI / 2, 0, 0]}>
          <group
            position={[0, -1.5, 0]}
            rotation={[0.17, -0.1, 0]}
            scale={[0.8, 0.8, 0.8]}
          >
            <mesh
              geometry={nodes.mesh_0.geometry}
              material={materials.material}
            />
          </group>
        </group>
      </group>
    </group>
  );
}








function Book({people}){



  const onFlip = useCallback((e) => {
    console.log('Current page: ' + e.data);
}, []);

  return (


 
  <HTMLFlipBook className="wrapper3" 
  size='stretch'  width={195}  height={338}        maxShadowOpacity={0.5}
  onFlip={onFlip}
  showCover
 >
  
  {
      people.show&&people.show.map((v,i)=>(
          <div id='page-item1' className="demoPage1" key={i}>
            
            <h2 id='title-name'>
                    {v.name}
                </h2>
                {v.title&&<h2>
                    {v.title}
                </h2>}
               
             <img src={v.image} alt="" />
               
          </div>
      ))
  }
</HTMLFlipBook>

)
}

function Gallery({gallery,position,rotation}){

  const mesh = useRef()
  const [index,setIndex] = useState(0)


  useEffect(() => {
    const interval = setInterval(() => {

        if(index ===gallery.length-1){
            setIndex(0)
        }
        else{
            setIndex(index+1)
        }
    }, 2500);
    return () => clearInterval(interval);
  }, [index,gallery]);

  let texture;
  texture =useLoader(THREE.TextureLoader,gallery[index])
  texture.format= THREE.RGBAFormat;
  texture.minFilter = THREE.LinearFilter;
  texture.magFilter = THREE.LinearFilter;
  return(
    <>
    
    <mesh   onClick={(e)=>{
        e.stopPropagation()
      }}
      ref={mesh}
      position={position}
      rotation={rotation}
      >

        <planeGeometry args={[12, 8]} />
        <meshStandardMaterial  map={texture}   side={THREE.DoubleSide}/>
      

    </mesh>

    </>
  )

}


function Case({position,rotation}){

  const mesh = useRef()

  const map = 'https://res.cloudinary.com/dvr397xda/image/upload/v1659632537/cube/hunqing/WechatIMG9681_eilgud.png'

  let texture = useLoader(THREE.TextureLoader,map)
  texture.format= THREE.RGBAFormat;
  texture.minFilter = THREE.LinearFilter;
  texture.magFilter = THREE.LinearFilter;

  return(
    <>
     <mesh   onClick={(e)=>{
        e.stopPropagation()
      }}
      ref={mesh}
      position={position}
      rotation={rotation}
      >

        <planeGeometry args={[14, 10]} />
        <meshStandardMaterial  map={texture} transparent={true}    side={THREE.DoubleSide}/>
      

    </mesh>
    </>
  )
}


function Box(props) {
  const {people,position} = props
  const mesh = useRef()
  const [index,setIndex] = useState(0)

 

 
  useEffect(() => {
    const interval = setInterval(() => {

        if(index ===people.avatar.length-1){
            setIndex(0)
        }
        else{
            setIndex(index+1)
        }
    }, 2500);
    return () => clearInterval(interval);
  }, [index,people]);



  let textures;

textures = useLoader(THREE.TextureLoader,[people.images[0],people.images[1],people.images[2],people.images[3],people.avatar[index],people.images[5]])
// textures = useLoader(TextureLoader,[people.images[0],people.images[1],people.images[2],people.images[3],people.images[4],people.images[5]])

textures.minFilter = textures.magFilter = THREE.LinearFilter;



textures.format= THREE.RGBAFormat;




  function Map (){
    return(
    
      textures.map((texture, idx) =>(
        idx ===2||idx===3 || idx===0?<meshBasicMaterial key={idx} attach={`material-${idx}`} map={texture}   transparent={true} opacity={1}/>
        :<meshBasicMaterial opacity={1} transparent={true}  key={idx} attach={`material-${idx}`} map={texture}  />
        )
)
    )
  } 

  return (
    <>
    <mesh
      onClick={(e)=>{
        e.stopPropagation()
      }}
      ref={mesh}
     position={position}
     
      >
          
      <boxBufferGeometry attach="geometry"  args={[2.5, 2.5, 2.5]}/>
        <Map/>
       
      

    </mesh>


    </>
  )
}

function Box2(props) {
  const {people,position} = props
  const mesh = useRef()
 
  let textures;


textures = useLoader(THREE.TextureLoader,[people.images[0],people.images[1],people.images[2],people.images[3],people.images[4],people.images[5]])

textures.minFilter = textures.magFilter = THREE.LinearFilter;



textures.format= THREE.RGBAFormat;




  function Map (){
    return(
    
      textures.map((texture, idx) =>(
        idx ===2||idx===3 || idx===0?<meshBasicMaterial key={idx} attach={`material-${idx}`} map={texture}   transparent={true} opacity={1}/>
        :<meshBasicMaterial opacity={1} transparent={true}  key={idx} attach={`material-${idx}`} map={texture}  />
        )
)
    )
  } 

  return (
    <>
    <mesh
      onClick={(e)=>{
        e.stopPropagation()
      }}
      ref={mesh}
     position={position}
     
      >
          
      <boxBufferGeometry attach="geometry"  args={[2.5, 2.5, 2.5]}/>
        <Map/>
       
      

    </mesh>


    </>
  )
}

// extend({ OrbitControls });

// const CameraControls = () => {

//   const {
//     camera,
//     gl: { domElement },
//   } = useThree();
//   const controls = useRef();
//   useFrame(() => controls.current.update());
//   return (
//     <orbitControls
//       ref={controls}
//       args={[camera, domElement]}
//       autoRotate={false}
//       enableZoom={true}
//       maxDistance={5.5}
//       minDistance={2}
//     />
//   );
// };

const Phone = ({people,opacity,posY})=>{

  const mesh1 = useRef()


  const handleClick=()=>{
    window.open(`tel:+1${people.tel}`,'_self')
  }


  return (
    <>
    <mesh position={[0.8,posY-1,1.3]} rotation={[0,Math.PI * 2.5,0]} onClick={(e)=>{ handleClick();     e.stopPropagation()
}}   ref={mesh1}>
        <boxBufferGeometry  attach="geometry"  args={[0.1,0.8,0.8]}/>
        <meshStandardMaterial transparent={true} opacity={opacity}   color='green'  />

       


    </mesh>
    </>
  )
}

const Cell = ({people,opacity,posY})=>{
   
    const mesh1 = useRef()
 
  
    const handleClick=()=>{
      window.open(`tel:+1${people.cell}`,'_self')
    }
  
  
    return (
      <>
      <mesh position={[0,posY-1.9,1.3]} rotation={[0,Math.PI * 2.5,0]} onClick={(e)=>{ handleClick();     e.stopPropagation()
  }}   ref={mesh1}>
          <boxBufferGeometry  attach="geometry"  args={[0.1,0.6,2.5]}/>
          <meshStandardMaterial transparent={true} opacity={opacity}  color='red' />
  
         
  
  
      </mesh>
      </>
    )
  }

const Email = ({people,opacity,posY})=>{

  const mesh1 = useRef()
 

  const handleClick=()=>{
 
    window.open(`mailto:+${people.email}`,'_self')
  }

  return (
    <>
    <mesh position={[-0.8,posY-1.8,1.3]} rotation={[0,Math.PI * 2.5,0]} onClick={(e)=>{ handleClick();     e.stopPropagation()
}}   ref={mesh1}>
        <boxBufferGeometry  attach="geometry"  args={[0.1,0.8,0.8]}/>
        <meshStandardMaterial transparent={true} opacity={opacity}  color='#fff' />

       


    </mesh>
    </>
  )
}

const Map = ({people,opacity,posY})=>{
 
  const mesh1 = useRef()


  const handleClick=()=>{

    window.open(`${people.map}`,'_self')
  }

  return (
    <>
    <mesh rotation={[0,Math.PI * 2.5,0]} position={[0,-1.8+posY,1.3]} onClick={(e)=>{ handleClick();     e.stopPropagation()
}}   ref={mesh1}>
        <boxBufferGeometry  attach="geometry"  args={[0.1,0.8,0.8]}/>
        <meshStandardMaterial transparent={true} opacity={opacity}   color='blue'   />

       


    </mesh>
    </>
  )
}

const Youtube = ({people,opacity,posY})=>{

    const mesh1 = useRef()
   
  
    const handleClick=()=>{
  
      window.open(`${people.video}`,'_self')
    }
  
    return (
      <>
      <mesh position={[-1.4,-1+posY,0.6]} onClick={(e)=>{ handleClick();     e.stopPropagation()
  }}   ref={mesh1}>
          <boxBufferGeometry  attach="geometry"  args={[0.1,0.7,1.2]}/>
          <meshStandardMaterial transparent={true} opacity={opacity}  attach="material" color='green'  roughness={0.7}
          metalness={0.1} />
  
         
  
  
      </mesh>
      </>
    )
  }

  const QR = ({opacity,posY,handleOpenQr})=>{
  
    const mesh1 = useRef()
  
  
  
    return (
      <>
      <mesh rotation={[0, Math.PI * 2.5, 0]} position={[0.8,-1.8+posY,1.3]} onClick={(e)=>{  handleOpenQr();     e.stopPropagation()
  }}   ref={mesh1}>
          <boxBufferGeometry  attach="geometry"  args={[0.1,0.8,0.8]}/>
          <meshStandardMaterial transparent={true} opacity={opacity}   color='red'   />
  
         
  
  
      </mesh>
      </>
    )
  }



const Web = ({people,opacity,posY})=>{
 
  const mesh1 = useRef()

  const handleClick=()=>{
    window.open(`${people.website}`,'_self')
  }

  return (
    <>
    <mesh rotation={[0, Math.PI * 2.5, 0]} position={[0,0,-1.3]} onClick={(e)=>{ handleClick();     e.stopPropagation()
}}   ref={mesh1}>
        <boxBufferGeometry  attach="geometry"  args={[0.1,2.4,2.4]}/>
        <meshStandardMaterial transparent={true} opacity={opacity}   color='#fff'   />

       


    </mesh>
    </>
  )
}

const Living = ({people,opacity,posY})=>{
    const mesh1 = useRef()

    const handleClick=()=>{
      window.open(`${people.living}`,'_self')
    }
  
    return (
      <>
      <mesh position={[-1.3,0,0]} rotation={[0,0,0]} onClick={(e)=>{ handleClick();     e.stopPropagation()
  }}   ref={mesh1}>
          <boxBufferGeometry  attach="geometry"  args={[0.1,2.4,2.4]}/>
          <meshStandardMaterial transparent={true} opacity={opacity}   color='#fff'   />
  
         
  
  
      </mesh>
      </>
    )
}

const Pano = ({people,opacity,posY})=>{
  const mesh1 = useRef()

  const handleClick=()=>{
    window.open(`${people.pano}`,'_self')
  }

  return (
    <>
    <mesh position={[1.3,0,0]} rotation={[0,Math.PI*2.5,0]} onClick={(e)=>{ handleClick();     e.stopPropagation()
}}   ref={mesh1}>
        <boxBufferGeometry  attach="geometry"  args={[2.4,2.4,0.1]}/>
        <meshStandardMaterial transparent={true} opacity={opacity}   color='#fff'   />

       


    </mesh>
    </>
  )
}


const music = [
  {
    album:'周杰伦',
    name:'简单爱',
    track:'Jay', 
    url:'https://res.cloudinary.com/dvr397xda/video/upload/v1659647081/cube/hunqing/jie-hun-jin-xing-qu-hua-ge-na-gang-qin-andle-tuan_jjzqpd.mp3', 
    image:'https://res.cloudinary.com/dvr397xda/image/upload/v1659646417/cube/hunqing/wedding-couple_1_seqrc3.png'
  },
  {
      album:'周杰伦',
      track:'Fantasy', 
      name:'爱在西元前',
      url:'https://res.cloudinary.com/dvr397xda/video/upload/v1659647079/cube/hunqing/gang-qin-ban-chun-yin-le_dizify.mp3', 
      image:'https://res.cloudinary.com/dvr397xda/image/upload/v1659646417/cube/hunqing/wedding-couple_2_igs4cd.png'
    },
    {
      album:'周杰伦',
      track:'七里香', 
      name:'七里香',
      url:'https://res.cloudinary.com/dvr397xda/video/upload/v1659646130/cube/hunqing/jin-tian-nai-yao-jia-gei-wo-marry-me-today-feat-cai-yi-lin-jolin-tsai-guan-fang-wan-zheng-ban-mv_slfbuz.mp3', 
      image:'https://res.cloudinary.com/dvr397xda/image/upload/v1659646417/cube/hunqing/wedding-couple_2_igs4cd.png'
    },

]

export default function Pro() {
    const{ name } = useParams();
    
    const mesh2 = useRef()
    const [people,setPeople]=useState({})

   useEffect(()=>{

    const data = fetchBasic();
    const result = data.filter(e=>e.id===name)
 
    setPeople(result[0])

   },[])



  const [isTeam,setIsTeam] = useState(false)
  const [isQR,setIsQR] = useState(false)
  const opacity=0
  const posY = 1

  const [load,setLoad] = useState(true)

  const handleOpenQr = ()=>{
      setIsQR(true)
  }


  const handleClickTeam = ()=>{
    console.log(2)
    setIsTeam(true)
  }
  const handleCloseTeam = ()=>{
    setIsTeam(false)
    setIsQR(false)
  }
  
  // useEffect(() => {
  //   if(people.loadImg){
  //       const timer = setTimeout(()=>{
  //           setLoad(false)
  //         },4000)
  //         return ()=>clearTimeout(timer)
  //   }
  //   else{
  //       setLoad(false)
  //   }
 
  // },[people])

  return (
    <div className="container-card"  >
     
     <Canvas camera={{ position: [0, -2, 4.5] }}  gl={{ alpha: false, antialias: true}}  className='canvas-wrap'>
             <Sky azimuth={1} inclination={0.6} distance={1000} />
             <pointLight position={[10, 10, 10]} />

     <OrbitControls minPolarAngle={Math.PI / 2.2} maxPolarAngle={Math.PI / 2.5} />
    <ambientLight  />
 {/* <pointLight position={[3, 3, 3]} intensity={1} color='#f0f0f0'/>
        <pointLight position={[3, -3, 3]} intensity={0.5} color='#f0f0f0'/>

        <pointLight position={[-3, 3, 3]} intensity={0.5} color='#f0f0f0'/>

        <pointLight position={[3, 3, -3]} intensity={0.5} color='#f0f0f0'/>
        <spotLight  position={[0, 0, 0]} intensity={0.5} color='#f0f0f0'/> */}

      <Suspense fallback={<Loader/>}>
     { people.isAvatar ?<Box people={people}   position={[0, 0, 0]} /> 
     :
     <Box2 people={people}   position={[0, 0, 0]} />
     }
      <Grass position={[0,-4,0]}  rotation={[0,0,0]} />
     {/* {people.isVideo&&<Video people={people}   position={[0, 5, -20]}/>} */}

    {people.pano&&<Pano posY={posY} people={people} opacity={opacity}/>}
        
      <Gallery gallery={people.gallery} position={[6,2,0]}
      rotation={[0,Math.PI * 2.5,0]}/>
      <Case position={[5.9,2,0]} rotation={[0,Math.PI * 2.5,0]} />

      <Gallery gallery={people.gallery2} position={[-6,2,0]}
      rotation={[0,Math.PI * 2.5,0]}/>
      <Case position={[-5.9,2,0]} rotation={[0,Math.PI * 2.5,0]} />
    <Rose />

        {/* <Model people={people}/> */}
      {/* <Cube position={[0, 0, 0]} /> */}
      

      <Phone posY={posY} people={people} opacity={opacity}/>
      {people.cell&&<Cell posY={posY} people={people} opacity={opacity}/>}

      {people.email&&<Email posY={posY} people={people} opacity={opacity}/>}
      <QR handleOpenQr={handleOpenQr} posY={posY} people={people} opacity={opacity}/>
      <Map posY={posY} people={people} opacity={opacity}/>
      <Web posY={posY} people={people} opacity={opacity}/>
     { people.video&&<Youtube posY={posY} people={people} opacity={opacity}/>}
     {people.living&&<Living posY={posY} people={people} opacity={opacity}/>}

      {/* <Linkedin posY={posY} people={people} opacity={opacity}/> */}
      {/* <VideoBtn people={people} opacity={opacity}/> */}

      <mesh rotation={[0,Math.PI * 2.5,0]} position={[0.8,-0.2+posY,1.3]} onClick={(e)=>{ handleClickTeam();     e.stopPropagation()
}}   ref={mesh2}>
        <boxBufferGeometry  attach="geometry"  args={[0.1,0.8,0.8]}/>
        <meshStandardMaterial transparent={true} opacity={opacity}    color='white'   />

       
    </mesh>
    
    </Suspense>

    </Canvas>
    <div style={isTeam ? {top:'20%',transition:'1.5s'}:{top:'200%',transition:'1.5s'}}  className="wrapper">
          <Book people={people}/>
          <img onClick={()=>handleCloseTeam()} id='close' src="https://res.cloudinary.com/dvr397xda/image/upload/v1656101942/cube/cancel_dmukx0.png" alt="" />
    </div>
         

          {/* <div style={load ?{ zIndex:'100000',opacity:'1'} :{zIndex:'-10',opacity:'0',transition:'2.5s' }} className='load-image'>
                <img src={people.loadImg} alt="" />
          </div> */}

          <div style={isQR ? {top:'20%',transition:'1.5s'}:{top:'200%',transition:'1.5s'}}  className="wrapper">
          <img id='qr' src={people.qrCode} alt="" />
          <h3>长按识别图中二维码</h3>

          <img onClick={()=>handleCloseTeam()} id='close' src="https://res.cloudinary.com/dvr397xda/image/upload/v1656101942/cube/cancel_dmukx0.png" alt="" />
    </div>

    <div id='mp3-player'>
            <Player  data={music} />
    </div>

    </div>
  )
}

