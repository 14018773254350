import React,{useState,useEffect,useCallback}from 'react'
import axios from 'axios'
import {Link } from 'react-router-dom'
import Select from 'react-select'

import './index.scss'

export default function AuthCard() {

    const apiUrl ='https://metaserverapp.com'

    const ROTATION = [
        {value:false,label:'横向 3.5:2.0 lily那种 '},
        {value:true,label:'竖向 2.0:3.5 Wingo那种'}
    ]
    const ISLOGO =[
        {value:true,label:'显示左上角logo'},
        {value:false,label:'不显示左上角logo '},
    ]

    const [cards,setCards]=useState([])
   
    const initial = {
        id:'',
        tel:'',
        email:'',
        title:'',
        website:'',
        weChat:'',
        address:'',
        bgImage:'',
        images:[
            'https://res.cloudinary.com/dvr397xda/image/upload/v1658519035/cube/WechatIMG9330_gm5mez_dpifk5.png',
            'https://res.cloudinary.com/dvr397xda/image/upload/v1658519035/cube/WechatIMG9330_gm5mez_dpifk5.png',
            'https://res.cloudinary.com/dvr397xda/image/upload/v1658519035/cube/WechatIMG9330_gm5mez_dpifk5.png',
            'https://res.cloudinary.com/dvr397xda/image/upload/v1658519035/cube/WechatIMG9330_gm5mez_dpifk5.png',
            '',
            '',
        ],
        rotation:false,
        isLogo:true,
    }

 
    const [isDelete,setIsDelete] = useState(false)
    const [isCreate,setIsCreate] = useState(false)
    const [isUpdate,setIsUpdate] =useState(false)
    const [target,setTarget]= useState(initial)


    const [page,setPage] = useState(1)
    const [total,setTotal]=useState(0)
    const [totalPage,setTotalPage] = useState(0)


    const handleOpenUpdate=(v)=>{
        setIsUpdate(true)
        setTarget(v)
    }
    const handleCloseUpdate=()=>{
        setIsUpdate(false)
    }

    useEffect(()=>{

        const fetchNews = async()=>{
            const result = await axios.get(`${apiUrl}/card/all`,{params:{page:1}})
            console.log(result)
            if(result.data.success){
        
                setCards(result.data.data[0].result)
      
              
            
              const temp = result.data.data[0].total[0].total
              setTotal(result.data.data[0].total[0].total)
              const temp2 = Math.ceil(temp/15)
              setTotalPage(temp2)
           
      
            }
            else{
              alert('Server Error! No data response received!')
            }
        }
        fetchNews()

    },[])

    useEffect(()=>{

        const fetchNews = async()=>{
            const result = await axios.get(`${apiUrl}/card/all`,{params:{page:page}})
            console.log(result)
            if(result.data.success){
        
                setCards(result.data.data[0].result)
              const temp = result.data.data[0].total[0].total
              setTotal(result.data.data[0].total[0].total)
              const temp2 = Math.ceil(temp/15)
              setTotalPage(temp2)
           
            }
            else{
              alert('Server Error! No data response received!')
            }
        }
        fetchNews()

    },[page])

   

    const handleName =(e)=>{
        let temp = {...target}
        temp.id = e.target.value
        setTarget(temp)
    }

    const handleTitle =(e)=>{
        let temp = {...target}
        temp.title = e.target.value
        setTarget(temp)
    }

    const handleTel =(e)=>{
        let temp = {...target}
        temp.tel = e.target.value
        setTarget(temp)
    }

    const handleWebsite =(e)=>{
        let temp = {...target}
        temp.website = e.target.value
        setTarget(temp)
    }

    const handleEmail =(e)=>{
        let temp = {...target}
        temp.email = e.target.value
        setTarget(temp)
    }

    const handleAddress =(e)=>{
        let temp = {...target}
        temp.address = e.target.value
        setTarget(temp)
    }

    const handleWechat =(e)=>{
        let temp = {...target}
        temp.weChat = e.target.value
        setTarget(temp)
    }

    const handleFront =(e)=>{
        let temp = {...target}
        temp.images[4] = e.target.value
        setTarget(temp)
    }

    const handleBack =(e)=>{
        let temp = {...target}
        temp.images[5] = e.target.value
        setTarget(temp)
    }

    const handleBg =(e)=>{
        let temp = {...target}
        temp.bgImage = e.target.value
        setTarget(temp)
    }

    const handleRotation = (e)=>{
        let temp = {...target}
        temp.rotation = e.value
        setTarget(temp)
    }
    const handleLogo = (e)=>{
        let temp = {...target}
        temp.isLogo = e.value
        setTarget(temp)
    }

    const handleDelete=()=>{
        setIsDelete(true)
    }
    const handleCancelDelete=()=>{
        setIsDelete(false)
        setTarget(initial)
    }   

    const handleDeleteNews=async(v)=>{
        console.log(v)
        const result = await axios.post(`${apiUrl}/card/delete`,{cardId:v._id})
        console.log(result)
        if(result.data.success){
            window.location.reload()
        }
        else{
            alert('something went wrong when delete Card!')
        }
    }

    const handleCreateNews = ()=>{
        setIsCreate(!isCreate)
    }


    const handleNextPage = ()=>{
        setPage(page+1)
        const wrap =document.getElementsByClassName('auth-posts-form')[0]
        wrap.scrollTop = 0
      }
      const handleLastPage = ()=>{
        setPage(page-1)
        const wrap =document.getElementsByClassName('auth-posts-form')[0]
        wrap.scrollTop = 0
    
      }

     const handleSubmit = async()=>{
        if(!target.id){
            alert('Missing content')
        }
        else{
            const data = await axios.post(`${apiUrl}/card`,{
               id:target.id,
               title:target.title,
               tel:target.tel,
               email:target.email,
               website:target.website,
               address:target.address,
               weChat:target.weChat,
               bgImage:target.bgImage,
               images:target.images,
               rotation:target.rotation,
               isLogo:target.isLogo,
            })
            console.log(data)
            if(data.data.success){
                
                alert("创建成功！")
                window.location.reload()
            }
            else{
                alert(data.data.errors)
            }
        }

     }

     const handleUpdateCard= async()=>{
        if(!target._id){
            alert('Missing id')
        }
        else{
            const data = await axios.post(`${apiUrl}/card/update`,{
                cardId:target._id,
               id:target.id,
               title:target.title,
               tel:target.tel,
               email:target.email,
               website:target.website,
               address:target.address,
               weChat:target.weChat,
               bgImage:target.bgImage,
               images:target.images,
               rotation:target.rotation,
               isLogo:target.isLogo,
            })
            console.log(data)
            if(data.data.success){
                
                alert("创建成功！")
                window.location.reload()
            }
            else{
                alert(data.data.errors)
            }
        }

     }

   
    console.log(target);
  return (
    <div className='auth-post-wrap'>
        <h2>3D名片管理</h2>
        
        <div className='auth-post-btns'>
            <Link to='/auth'> <button >返回</button></Link> 
            <button onClick={()=>handleCreateNews()}> {isCreate ? '取消创建' :' 创建名片'}</button>
        </div>

        <div className='auth-posts-form'>
        <table>
            <thead>
                <tr>
                    <th>
                        名字
                    </th>
                    <th>网址</th>
                    <th>
                        正面
                    </th>
                   
                    <th>
                        背面
                    </th>
                    <th>
                        微信
                    </th>
                    <th>
                        业务
                    </th>
                    
                    <th>
                       电话
                    </th>
                   <th>预览</th>
                   <th>修改</th>
                    <th>
                        删除
                    </th>
                </tr>
            </thead>
            <tbody>
                {
                    cards.length>0&&cards.map((v,i)=>(
                        <tr key={i}>

                            <td>{v.id}</td>
                            <td>https://meta11w.com/3dcard/{v.id}</td>
                            <td><img src={v.images[4]} alt="" /></td>
                            <td><img src={v.images[5]} alt="" /></td>
                            <td><img src={v.weChat} alt="" /></td>
                            <td>{v.title}</td>
                            <td>{v.tel}</td>

                                <td>
                                    <Link to={`/3dcard/${v.id}`}>
                                    <button >View</button>
                                    </Link>
                                   
                                </td>
                                <td>
                                    <button onClick={()=>handleOpenUpdate(v)}>修改</button>
                                </td>

                            {isDelete ?<td className='post-delete-group'><button onClick={()=>handleDeleteNews(v)}>确定</button><button onClick={()=>handleCancelDelete()}>取消</button></td> :<td><button onClick={()=>handleDelete()}>删除</button></td>}
                        </tr>
                    )

                    )
                }
            </tbody>


        </table>


        </div>

        <div className='pagenation-wrap'>

<div> {page!==1&&<p onClick={()=>handleLastPage()}>上一页</p>}</div>

<div> <p>第{page}页</p> </div>

<div> {page !== totalPage&&<p onClick={()=>handleNextPage()}>下一页</p>}</div>

<div><p>共{totalPage}页</p></div>
<div>
<p>共{total}个结果</p>
</div>
</div>
     

     
     <div style={{top:isCreate? '12vh':'220vh',transition:'1s'}} className='auth-product2-create'>

    <div className='news-wrap'>

        <div>
        <h4>名字</h4>
    <input type="text" value={target.id} onChange={(e)=>handleName(e)} />
        </div>
        
        <div>
        <h4>业务</h4>
        <input type="text" value={target.title} onChange={(e)=>handleTitle(e)} />
        </div>

        <div>
        <h4>电话</h4>
        <input type="text" value={target.tel} onChange={(e)=>handleTel(e)}/>
        </div>

        <div>
        <h4>Email</h4>
        <input type="text" value={target.email} onChange={(e)=>handleEmail(e)}/>
        </div>

        <div>
        <h4>网站</h4>
        <input type="text" value={target.website} onChange={(e)=>handleWebsite(e)}/>
        </div>

        <div>
        <h4>地址链接</h4>
        <input type="text" value={target.address} onChange={(e)=>handleAddress(e)}/>
        </div>

        <div>
        <h4>微信二维码</h4>
        <input type="text" value={target.weChat} onChange={(e)=>handleWechat(e)}/>
        </div>

        <div>
        <h4>正面图</h4>
        <input type="text" value={target.images[4]} onChange={(e)=>handleFront(e)}/>
        </div>

        <div>
        <h4>背面图</h4>
        <input type="text" value={target.images[5]} onChange={(e)=>handleBack(e)}/>
        </div>

        <div>
        <h4>背景图</h4>
        <input type="text" value={target.bgImage} onChange={(e)=>handleBg(e)}/>
        </div>
        <div className="create-news-wrap">
        <label>名片方向</label>
        <Select    
         onChange={(e)=>handleRotation(e)}
         options={ROTATION}             
           menuPortalTarget={document.body}
        menuPosition="fixed"       maxMenuHeight='20vh'           
        />
    </div>
    <div className="create-news-wrap">
        <label>是否显示logo</label>
        <Select    
         onChange={(e)=>handleLogo(e)}
         options={ISLOGO}             
           menuPortalTarget={document.body}
        menuPosition="fixed"       maxMenuHeight='20vh'           
        />
    </div>
       
    </div>

    <button onClick={()=>handleSubmit()}>Submit</button>

     </div>

     <div style={{top:isUpdate? '12vh':'220vh',transition:'1s'}} className='auth-product2-create'>

<div className='news-wrap'>

            <button onClick={()=>handleCloseUpdate()} id='cancel-update'>取消</button>
    <div>
    <h4>名字</h4>
<input type="text" value={target.id} onChange={(e)=>handleName(e)} />
    </div>
    
    <div>
    <h4>业务</h4>
    <input type="text" value={target.title} onChange={(e)=>handleTitle(e)} />
    </div>

    <div>
    <h4>电话</h4>
    <input type="text" value={target.tel} onChange={(e)=>handleTel(e)}/>
    </div>

    <div>
    <h4>Email</h4>
    <input type="text" value={target.email} onChange={(e)=>handleEmail(e)}/>
    </div>

    <div>
    <h4>网站</h4>
    <input type="text" value={target.website} onChange={(e)=>handleWebsite(e)}/>
    </div>

    <div>
    <h4>地址链接</h4>
    <input type="text" value={target.address} onChange={(e)=>handleAddress(e)}/>
    </div>

    <div>
    <h4>微信二维码</h4>
    <input type="text" value={target.weChat} onChange={(e)=>handleWechat(e)}/>
    </div>

    <div>
    <h4>正面图</h4>
    <input type="text" value={target.images[4]} onChange={(e)=>handleFront(e)}/>
    </div>

    <div>
    <h4>背面图</h4>
    <input type="text" value={target.images[5]} onChange={(e)=>handleBack(e)}/>
    </div>

    <div>
    <h4>背景图</h4>
    <input type="text" value={target.bgImage} onChange={(e)=>handleBg(e)}/>
    </div>
    <div className="create-news-wrap">
    <label>名片方向</label>
    <Select    
     onChange={(e)=>handleRotation(e)}
     options={ROTATION}             
       menuPortalTarget={document.body}
    menuPosition="fixed"       maxMenuHeight='20vh'           
    />
</div>
<div className="create-news-wrap">
    <label>是否显示logo</label>
    <Select    
     onChange={(e)=>handleLogo(e)}
     options={ISLOGO}             
       menuPortalTarget={document.body}
    menuPosition="fixed"       maxMenuHeight='20vh'           
    />
</div>
   
</div>

<button onClick={()=>handleUpdateCard()}>更新</button>

 </div>


        
        
    </div>
  )
}

