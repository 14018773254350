import React,{useState,useEffect,useRef,Suspense,useCallback}from 'react'
import { Canvas, useFrame,useLoader,} from '@react-three/fiber'
import * as THREE from "three";
import {useParams} from 'react-router-dom'
import {Link} from 'react-router-dom'
import {
  Star,
  StarFill,
  Share
} from "react-bootstrap-icons";

import fetchPoster from '../../poster'
import {useProgress,Html,OrbitControls,Line} from '@react-three/drei'

import './index.scss'


function Loader() {
  const { progress } = useProgress()
  return <Html center> <h2 id='loadText'>
        载入中！ ...{progress.toFixed(0)}%
        <img id='loadImage' src="https://res.cloudinary.com/dvr397xda/image/upload/v1660580851/cube/output-onlinegiftools_rgnpbb.gif" alt="" />
        </h2></Html>
}

function Box(props) {
  const {people,position} = props
  const mesh = useRef()
  const [index,setIndex] = useState(0)
 
  let textures;
  
textures = useLoader(THREE.TextureLoader,[people.images[0],people.images[1],people.images[2],people.images[3],people.images[4],people.images[5]])
 

textures.minFilter = textures.magFilter = THREE.LinearFilter;

textures.format= THREE.RGBAFormat;


  function Map (){
    return(
    
      textures.map((texture, idx) =>(
        idx ===2||idx===3 || idx===0?<meshBasicMaterial key={idx} attach={`material-${idx}`} map={texture}   transparent={true} opacity={1}/>
        :<meshBasicMaterial opacity={1} transparent={true}  key={idx} attach={`material-${idx}`} map={texture}  />
        )
)
    )
  } 
  
  return (
  
    <mesh
      onClick={(e)=>{
        e.stopPropagation()
      }}
      ref={mesh}
     position={position}
     
      >
          
      <boxBufferGeometry attach="geometry"  args={[2.5, 5, 0.1]}/>
        <Map/>
       
    </mesh>


  )
}


const Phone = ({people,opacity,posY,visible})=>{

  const mesh1 = useRef()
 

  const handleClick=()=>{
    window.open(`tel:+1${people.tel}`,'_self')
  }


  return (
   
    <mesh position={[-0.85,posY-3.2,-0.1]} rotation={[0,Math.PI * 2.5,0]} onClick={(e)=>{ handleClick();     e.stopPropagation()
}}   ref={mesh1}>
        <boxBufferGeometry  attach="geometry"  args={[0.1,0.6,0.8]}/>
        <meshStandardMaterial visible={visible} transparent={true} opacity={opacity}   color='green'  />

       


    </mesh>

  )
}

const Map = ({people,opacity,posY,visible})=>{
 
  const mesh1 = useRef()


  const handleClick=()=>{

    window.open(`${people.map}`,'_self')
  }

  return (
    
    <mesh rotation={[0,Math.PI * 2.5,0]} position={[0.85,posY-3.2,-0.1]}onClick={(e)=>{ handleClick();     e.stopPropagation()
}}   ref={mesh1}>
        <boxBufferGeometry  attach="geometry"  args={[0.1,0.6,0.8]}/>
        <meshStandardMaterial visible={visible} transparent={true} opacity={opacity}   color='blue'   />

       


    </mesh>
   
  )
}



  const QR = ({opacity,posY,handleOpenQr,visible})=>{
  
    const mesh1 = useRef()
  
  
  
    return (
  
      <mesh rotation={[0, Math.PI * 2.5, 0]} position={[0,posY-3.2,-0.1]} onClick={(e)=>{  handleOpenQr();     e.stopPropagation()
  }}   ref={mesh1}>
          <boxBufferGeometry  attach="geometry"  args={[0.1,0.6,0.8]}/>
          <meshStandardMaterial visible={visible} transparent={true} opacity={opacity}   color='green'   />
  
         
  
  
      </mesh>
    
    )
  }

  const Web5 = ({people,opacity,posY,visible})=>{
 
    const mesh1 = useRef()
  
    const handleClick=()=>{
      window.open(`${people.website5}`,'_self')
    }
  
    return (
     
      <mesh rotation={[0, Math.PI * 2.5, 0]} position={[0,1.2+posY,0.1]} onClick={(e)=>{ handleClick();     e.stopPropagation()
  }}   ref={mesh1}>
          <boxBufferGeometry  attach="geometry"  args={[0.1,0.5,2.5]}/>
          <meshStandardMaterial visible={visible} transparent={true} opacity={opacity}   color='#fff'   />
  
         
  
  
      </mesh>
  
    )
  }



const Web = ({people,opacity,posY,visible})=>{
 
  const mesh1 = useRef()

  const handleClick=()=>{
    window.open(`${people.website}`,'_self')
  }

  return (
   
    <mesh rotation={[0, Math.PI * 2.5, 0]} position={[0,0.6+posY,0.1]} onClick={(e)=>{ handleClick();     e.stopPropagation()
}}   ref={mesh1}>
        <boxBufferGeometry  attach="geometry"  args={[0.1,0.5,2.5]}/>
        <meshStandardMaterial visible={visible} transparent={true} opacity={opacity}   color='#fff'   />

       


    </mesh>

  )
}

const Web2 = ({people,opacity,posY,visible})=>{
 
    const mesh1 = useRef()
  
    const handleClick=()=>{
      window.open(`${people.website2}`,'_self')
    }
  
    return (
     
      <mesh rotation={[0, Math.PI * 2.5, 0]} position={[0,posY,0.1]} onClick={(e)=>{ handleClick();     e.stopPropagation()
  }}   ref={mesh1}>
          <boxBufferGeometry  attach="geometry"  args={[0.1,0.5,2.5]}/>
          <meshStandardMaterial visible={visible} transparent={true} opacity={opacity}   color='#fff'   />
  
         
  
  
      </mesh>
  
    )
  }

  const Web3 = ({people,opacity,posY,visible})=>{
 
    const mesh1 = useRef()
  
    const handleClick=()=>{
      window.open(`${people.website3}`,'_self')
    }
  
    return (
     
      <mesh rotation={[0, Math.PI * 2.5, 0]} position={[0,-0.6+posY,0.1]} onClick={(e)=>{ handleClick();     e.stopPropagation()
  }}   ref={mesh1}>
          <boxBufferGeometry  attach="geometry"  args={[0.1,0.5,2.5]}/>
          <meshStandardMaterial visible={visible} transparent={true} opacity={opacity}   color='#fff'   />
  
         
  
  
      </mesh>
  
    )
  }

  const Web4 = ({people,opacity,posY,visible})=>{
 
    const mesh1 = useRef()
  
    const handleClick=()=>{
      window.open(`${people.website4}`,'_self')
    }
  
    return (
     
      <mesh rotation={[0, Math.PI * 2.5, 0]} position={[0,-1.2+posY,0.1]} onClick={(e)=>{ handleClick();     e.stopPropagation()
  }}   ref={mesh1}>
          <boxBufferGeometry  attach="geometry"  args={[0.1,0.5,2.5]}/>
          <meshStandardMaterial visible={visible} transparent={true} opacity={opacity}   color='#fff'   />
  
         
  
  
      </mesh>
  
    )
  }

  function Gallery({people,posY}){

    const mesh = useRef()
    const [index,setIndex] = useState(0)
  
  
    useEffect(() => {
      const interval = setInterval(() => {
  
          if(index ===people.gallery.length-1){
              setIndex(0)
          }
          else{
              setIndex(index+1)
          }
      }, 2500);
      return () => clearInterval(interval);
    }, [index,people]);
  
    let texture;
    texture =useLoader(THREE.TextureLoader,people.gallery[index])
    texture.format= THREE.RGBAFormat;
    texture.minFilter = THREE.LinearFilter;
    texture.magFilter = THREE.LinearFilter;
    return(
      <>
      
      <mesh   onClick={(e)=>{
          e.stopPropagation()
        }}
        ref={mesh}
        rotation={[0, 0, 0]} position={[0,-2.6+posY,0.06]} 
        >
  
          <planeGeometry args={[2.5, 1.9]} />
          <meshStandardMaterial  map={texture}   side={THREE.DoubleSide}/>
        
  
      </mesh>
  
      </>
    )
  
  }

  function Video({people,posY,video}) {
     
     
    // Video texture by: https://www.pexels.com/@rostislav/
    const [posZ,setPosZ]= useState(0)
    useEffect(() => {
      
        video.addEventListener('playing', (event) => {
            setPosZ(-0.02)
          
        })
        video.addEventListener('pause', (event) => {
            setPosZ(0)
            
        })

    },[video])
  
    return (
      <mesh  rotation={[0, Math.PI / 1, 0]} position={[0,-1.1+posY,-0.06+posZ]}  onClick={(e)=>{     e.stopPropagation()
      }} >
        <planeGeometry args={[2, 3.4]}/>
        <meshBasicMaterial  toneMapped={true} side={THREE.DoubleSide}>
          <videoTexture  attach="map" args={[video]} encoding={THREE.sRGBEncoding} />
        </meshBasicMaterial>
      </mesh>
    )
  }

  function Video2({people,posY,handleClick}) {
  
  // Video texture by: https://www.pexels.com/@rostislav/
 
    const texture = useLoader(THREE.TextureLoader,people.videoImg)
 
  return (
    <mesh  rotation={[0, Math.PI / 1, 0]} position={[0,-1.1+posY,-0.07]}  onClick={(e)=>{ handleClick() ;   e.stopPropagation()
    }} >
      <planeGeometry args={[2, 3.4]}/>
      <meshStandardMaterial  map={texture} side={THREE.DoubleSide}>
        
      </meshStandardMaterial>
    </mesh>
  )
}



const Group = ({people,opacity,posY,visible,handleOpenQr})=>{
  const mesh = useRef()
  const [v,setV]= useState(1)

  const points= [
    { 
      point:[
      [1.28,2.6,0.15],
      [-1.28,2.6,0.15],
      [-1.28,-2.6,0.15],
      [1.28,-2.6,0.15],
      [1.28,2.6,0.15],
    ],
    color:'black'
    },
    { 
      point:[
      [1.28,2.6,-0.15],
      [-1.28,2.6,-0.15],
      [-1.28,-2.6,-0.15],
      [1.28,-2.6,-0.15],
      [1.28,2.6,-0.15],
    ],
    color:'black'
    },
    { 
      point:[
      [1.28,2.6,0.15],
      [1.28,2.6,-0.15],
      [1.28,-2.6,-0.15],
      [1.28,-2.6,0.15],
      [1.28,2.6,0.15],
    ],
    color:'black'

    },
     { 
      point:[
      [-1.28,2.6,0.15],
      [-1.28,2.6,-0.15],
      [-1.28,-2.6,-0.15],
      [-1.28,-2.6,0.15],
      [-1.28,2.6,0.15],
    ],
    color:'black'
    },
    { 
      point:[
      [1.1,2.3,0.15],
      [-1.1,2.3,0.15],
      [-1.1,1.9,0.15],
      [1.1,1.9,0.15],
      [1.1,2.3,0.15],
    ],
    color:'#fff'
    },
    { 
      point:[
      [1.1,1.7,0.15],
      [-1.1,1.7,0.15],
      [-1.1,1.3,0.15],
      [1.1,1.3,0.15],
      [1.1,1.7,0.15],
    ],
    color:'#fff'
    },
    { 
      point:[
      [1.1,1.1,0.15],
      [-1.1,1.1,0.15],
      [-1.1,0.7,0.15],
      [1.1,0.7,0.15],
      [1.1,1.1,0.15],
    ],
    color:'#fff'
    },
    { 
      point:[
      [1.1,0.5,0.15],
      [-1.1,0.5,0.15],
      [-1.1,0.1,0.15],
      [1.1,0.1,0.15],
      [1.1,0.5,0.15],
    ],
    color:'#fff'
    },
    { 
      point:[
      [1.1,-0.1,0.15],
      [-1.1,-0.1,0.15],
      [-1.1,-0.5,0.15],
      [1.1,-0.5,0.15],
      [1.1,-0.1,0.15],
    ],
    color:'#fff'
    },
    { 
      point:[
      [-1.15,-1.8,-0.15],
      [-0.4,-1.8,-0.15],
      [-0.4,-2.4,-0.15],
      [-1.15,-2.4,-0.15],
      [-1.15,-1.8,-0.15],
    ],
    color:'black'
    },
    { 
      point:[
      [-0.4,-1.8,-0.15],
      [0.4,-1.8,-0.15],
      [0.4,-2.4,-0.15],
      [-0.4,-2.4,-0.15],
      [-0.4,-1.8,-0.15],
    ],
    color:'black'
    },
    { 
      point:[
      [0.4,-1.8,-0.15],
      [1.15,-1.8,-0.15],
      [1.15,-2.4,-0.15],
      [0.4,-2.4,-0.15],
      [0.4,-1.8,-0.15],
    ],
    color:'black'
    },
    
  ]

  useFrame(({clock}) => {
      
    // setV(Math.abs(Math.sin(clock.elapsedTime)))

    mesh.current.rotation.y += 0.001
  
  })
    const [video] = useState(() =>
      Object.assign(document.createElement('video'), { src:people.video , crossOrigin: 'Anonymous', loop: true, })
    )
  
   

    const handleClick=()=>{
     

            video.play()
     
    }
   

  return(<group ref={mesh}>
<Box people={people}   position={[0, 0, 0]} />


   <Phone visible={visible} posY={posY} people={people} opacity={opacity}/>
 

   <QR visible={visible} handleOpenQr={handleOpenQr} posY={posY} people={people} opacity={opacity}/>
   <Map visible={visible} posY={posY} people={people} opacity={opacity}/>

   <Web visible={visible} posY={posY} people={people} opacity={opacity}/>
   <Web2 visible={visible} posY={posY} people={people} opacity={opacity}/>
   <Web3 visible={visible} posY={posY} people={people} opacity={opacity}/>
   <Web4 visible={visible} posY={posY} people={people} opacity={opacity}/>
   {people.website5&&<Web5 visible={visible} posY={posY} people={people} opacity={opacity}/>
}
   <Gallery  posY={posY} people={people} />
   <Video  posY={posY} people={people} video={video} />
   <Video2  posY={posY} people={people} handleClick={handleClick}/>
   {/* {
      points.map((m,i)=>(
        <Line key={i}  points={m.point} color={m.color}  linewidth={2} position={[0,0,0]} dashed
        dashSize={v * 1.5}
        gapSize={2 - v * 2}/>
      ))
    } */}
  </group>)

}




export default function Poster() {
    const{ name } = useParams();
    
    const [people,setPeople]=useState({})

   useEffect(()=>{

    const data = fetchPoster();
    const result = data.filter(e=>e.id===name)
 
    setPeople(result[0])

   },[name])



  const [isTeam,setIsTeam] = useState(false)
  const [isQR,setIsQR] = useState(false)
  const opacity=0
  const posY = 1
  const visible = false

  const [load,setLoad] = useState(true)

  const handleOpenQr = ()=>{
      setIsQR(true)
  }



 
  const handleCloseTeam = ()=>{
    setIsTeam(false)
    setIsQR(false)
  }
  
  useEffect(() => {
    if(people.loadImg){
        const timer = setTimeout(()=>{
            setLoad(false)
          },4000)
          return ()=>clearTimeout(timer)
    }
    else{
        setLoad(false)
    }
 
  },[people])

  const handleShare = async()=>{
    await navigator.clipboard.writeText(window.location.href)
   
      console.log(window.location.href);
       alert(`已复制当前页面网址，可转发！`)
     }

  return (
    <div className="container-card"  style={{backgroundImage:`url(${people.gif})`}} >

<Link id='return-home' to='/recommand'><img src="https://res.cloudinary.com/dvr397xda/image/upload/v1664834364/cube/Free/metalogo_ctuhtw.png" alt="" /></Link>
<Share onClick={()=>handleShare()} id='share-btn'/>

      {/* <Helmet
      title={people.title}
      meta={[
        {
          name: `description`,
          content: people.title,
        },
      ]}
    /> */}
     
     <Canvas camera={{ position: [0, 1, 4.2] }}  gl={{ alpha: true, antialias: true}}  className='canvas-wrap'>
    {/* <CameraControls /> */}
  
    <OrbitControls minPolarAngle={Math.PI / 2.2} maxPolarAngle={Math.PI / 2.5} />
    <ambientLight/>
 {/* <pointLight position={[3, 3, 3]} intensity={1} color='#f0f0f0'/>
        <pointLight position={[3, -3, 3]} intensity={1} color='#f0f0f0'/>

        <pointLight position={[-3, 3, 3]} intensity={1} color='#f0f0f0'/>

        <pointLight position={[3, 3, -3]} intensity={1} color='#f0f0f0'/>
        <spotLight  position={[0, 0, 0]} intensity={1} color='#f0f0f0'/> */}

      <Suspense fallback={<Loader/>}>
   
  

      
        
    
        
    
      {/* <Cube position={[0, 0, 0]} /> */}
      
      <Group visible={visible} posY={posY} people={people} opacity={opacity} handleOpenQr={handleOpenQr}/>
    

      {/* <Linkedin posY={posY} people={people} opacity={opacity}/> */}
      {/* <VideoBtn people={people} opacity={opacity}/> */}

      {/* <mesh rotation={[0,0,0]} position={[-1.4,-1.8+posY,-0.6]} onClick={(e)=>{ handleClickTeam();     e.stopPropagation()
}}   ref={mesh2}>
        <boxBufferGeometry  attach="geometry"  args={[0.1,0.7,1.2]}/>
        <meshStandardMaterial transparent={true} opacity={opacity}    color='red'   />

       
    </mesh> */}
    
    </Suspense>

    </Canvas>
   
         

          <div style={load ?{ zIndex:'100000',opacity:'1'} :{zIndex:'-10',opacity:'0',transition:'2.5s' }} className='load-image'>
                <img src={people.loadImg} alt="" />
          </div>

          <div style={isQR ? {top:'20%',transition:'1.5s'}:{top:'200%',transition:'1.5s'}}  className="wrapper4">
          <img id='qr' src={people.qrCode} alt="" />
          <h3>长按识别图中二维码</h3>

          <img onClick={()=>handleCloseTeam()} id='close' src="https://res.cloudinary.com/dvr397xda/image/upload/v1656101942/cube/cancel_dmukx0.png" alt="" />
        </div>


        {/* {
            people.isMusic&&<div id='cd-player'>
            <Player  data={people.music} />
    </div>
        } */}
      

    </div>
  )
}

