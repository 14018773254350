import {useState,Suspense, useEffect}from 'react'
import { Canvas, useLoader,} from '@react-three/fiber'
import {  useProgress,Html, OrbitControls  } from '@react-three/drei'
import * as THREE from 'three'
import './index.scss'

export default function UserViewer() {
  

const [link,setLink] = useState('');
  
    const handleFileUpload = (e)=>{ 

        const {files} = e.target;
  
        console.log(files)
        
            // const formData = new FormData();
           
           
                const temp = URL.createObjectURL(files[0])
          
            setLink(temp)
            
            // console.log(formData)
    
        
       
    }

    const Panorama = ({link})=>{
   

     
          
        const map = useLoader(THREE.TextureLoader,link)
     
    return  <>{map&&<mesh>
        <sphereBufferGeometry args={[200, 60, 40]} />
        <meshBasicMaterial map={map} side={THREE.BackSide} />
      </mesh>}</>
}
   

  return (
    <>
    
   <Canvas frameloop="demand" >

    <OrbitControls enableZoom={true} minDistance={0} maxDistance={6.5} zoomSpeed={2}  />
    <Suspense
      fallback={
        <Loader/>
      }>
   { link&&<Panorama link={link} />}
    </Suspense>
  </Canvas>
      
      <h2 id='pano-h2'>
      <div>
                                <h4>上传图片</h4>
                                <input type="file"  onChange={(e)=>handleFileUpload(e)}  />
                            </div>
      </h2>


    </>
  )
}

function Loader() {
    const { progress } = useProgress()
    return <Html center>
        <h2 id='loadText'>
        Loading ...{progress.toFixed(0)}%
        <img id='loadImage' src="https://res.cloudinary.com/dvr397xda/image/upload/v1657312449/cube/output-onlinegiftools_i29wl2.gif" alt="" />
        </h2>
        </Html>
  }