const category = [

    
    {
        value:'餐饮',
        label:'餐饮',
        img:'https://res.cloudinary.com/dvr397xda/image/upload/v1667255793/meta-lift-icon/%E9%A4%90%E9%A5%AE_v7egro.jpg',
    },
    {
        value:'租房',
        label:'租房',
        img:'https://res.cloudinary.com/dvr397xda/image/upload/v1667255792/meta-lift-icon/rent_qokf0u.jpg',
    },
    {
        value:'地产',
        label:'地产',
        img:'https://res.cloudinary.com/dvr397xda/image/upload/v1668187868/meta-lift-icon/WechatIMG12916_yy2soi.jpg',
    },
 
    {
        value:'二手',
        label:'二手',
        img:'https://res.cloudinary.com/dvr397xda/image/upload/v1667255795/meta-lift-icon/%E4%BA%8C%E6%89%8B_wiiseo.jpg',
    },
    {
        value:'按摩',
        label:'按摩',
        img:'https://res.cloudinary.com/dvr397xda/image/upload/v1667255792/meta-lift-icon/%E6%8C%89%E6%91%A9_xajnm5.jpg',
    },
    {
        value:'美容',
        label:'美容',
        img:'https://res.cloudinary.com/dvr397xda/image/upload/v1667255791/meta-lift-icon/%E7%BE%8E%E5%AE%B9_klqkkp.jpg',
    },
    {
        value:'美甲',
        label:'美甲',
        img:'https://res.cloudinary.com/dvr397xda/image/upload/v1667255791/meta-lift-icon/%E7%BE%8E%E7%94%B2_joggg9.jpg',
    },
    {
        value:'保险',
        label:'保险',
        img:'https://res.cloudinary.com/dvr397xda/image/upload/v1667255792/meta-lift-icon/%E4%BF%9D%E9%99%A9_elayts.jpg',
    },
    {
        value:'会计',
        label:'会计',
        img:'https://res.cloudinary.com/dvr397xda/image/upload/v1667255793/meta-lift-icon/%E4%BC%9A%E8%AE%A1_bkqskp.jpg',
    },
    {
        value:'律师',
        label:'律师',
        img:'https://res.cloudinary.com/dvr397xda/image/upload/v1667255791/meta-lift-icon/%E5%BE%8B%E5%B8%88_tjfqnq.jpg',
    },
    {
        value:'娱乐',
        label:'娱乐',
        img:'https://res.cloudinary.com/dvr397xda/image/upload/v1668187868/meta-lift-icon/WechatIMG12915_uzwxls.jpg',
    },
    {
        value:'驾校',
        label:'驾校',
        img:'https://res.cloudinary.com/dvr397xda/image/upload/v1667255791/meta-lift-icon/%E9%A9%BE%E6%A0%A1_quvjmf.jpg',
    },
    {
        value:'家政',
        label:'家政',
        img:'https://res.cloudinary.com/dvr397xda/image/upload/v1667255791/meta-lift-icon/%E5%AE%B6%E6%94%BF_gs8pm5.jpg',
    },
    {
        value:'诊所',
        label:'诊所',
        img:'https://res.cloudinary.com/dvr397xda/image/upload/v1667255792/meta-lift-icon/%E5%8C%BB%E7%94%9F_n0prdy.jpg',
    },
    {
        value:'物流',
        label:'物流',
        img:'https://res.cloudinary.com/dvr397xda/image/upload/v1667255792/meta-lift-icon/%E7%89%A9%E6%B5%81_cdyias.jpg',
    },
    {
        value:'印刷',
        label:'印刷',
        img:'https://res.cloudinary.com/dvr397xda/image/upload/v1667255795/meta-lift-icon/%E5%8D%B0%E5%88%B7_tq1pm3.jpg',
    },
    {
        value:'搬家',
        label:'搬家',
        img:'https://res.cloudinary.com/dvr397xda/image/upload/v1667255792/meta-lift-icon/%E6%90%AC%E5%AE%B6_qldp8k.jpg',
    },
    {
        value:'贷款',
        label:'贷款',
        img:'https://res.cloudinary.com/dvr397xda/image/upload/v1667255793/meta-lift-icon/%E8%B4%B7%E6%AC%BE_zm5znw.jpg',
    },
    {
        value:'旅游',
        label:'旅游',
        img:'https://res.cloudinary.com/dvr397xda/image/upload/v1667255791/meta-lift-icon/%E6%97%85%E6%B8%B8_m7xrew.jpg',
    },
    {
        value:'顺风车',
        label:'顺风车',
        img:'https://res.cloudinary.com/dvr397xda/image/upload/v1667255792/meta-lift-icon/%E9%A1%BA%E9%A3%8E%E8%BD%A6_stk5tj.jpg',
    },
    {
        value:'批发',
        label:'批发',
        img:'https://res.cloudinary.com/dvr397xda/image/upload/v1667255795/meta-lift-icon/%E6%89%B9%E5%8F%91_k97v1g.jpg',
    },
    {
        value:'婚庆',
        label:'婚庆',
        img:'https://res.cloudinary.com/dvr397xda/image/upload/v1667255793/meta-lift-icon/%E5%A9%9A%E5%BA%86_tg2858.jpg',
    },
    {
        value:'婚介',
        label:'婚介',
        img:'https://res.cloudinary.com/dvr397xda/image/upload/v1667255792/meta-lift-icon/%E6%91%84%E5%BD%B1_qpjag4.jpg',
    },
    {
        value:'装修',
        label:'装修',
        img:'https://res.cloudinary.com/dvr397xda/image/upload/v1667255792/meta-lift-icon/%E8%A3%85%E4%BF%AE_ougmlj.jpg',
    },
    {
        value:'电器',
        label:'电器',
        img:'https://res.cloudinary.com/dvr397xda/image/upload/v1667255792/meta-lift-icon/%E6%95%B0%E7%A0%81_lnfsjo.jpg',
    },
    {
        value:'汽车',
        label:'汽车',
        img:'https://res.cloudinary.com/dvr397xda/image/upload/v1667255792/meta-lift-icon/%E6%B1%BD%E8%BD%A6_m4pisl.jpg',
    },
    {
        value:'罚单',
        label:'罚单',
        img:'https://res.cloudinary.com/dvr397xda/image/upload/v1667255792/meta-lift-icon/%E6%8B%9B%E8%81%98_nbqc5y.jpg',
    },
    {
        value:'文职',
        label:'文职',
        img:'https://res.cloudinary.com/dvr397xda/image/upload/v1667255792/meta-lift-icon/%E6%89%BE%E5%B7%A5_clnsvx.jpg',
    },
    {
        value:'宠物',
        label:'宠物',
        img:'https://res.cloudinary.com/dvr397xda/image/upload/v1667255793/meta-lift-icon/%E5%AE%A0%E7%89%A9_mnozpa.jpg',
    },
    {
        value:'补习',
        label:'补习',
        img:'https://res.cloudinary.com/dvr397xda/image/upload/v1667255792/meta-lift-icon/%E8%A1%A5%E4%B9%A0_iumgwp.jpg',
    },
    {
        value:'托儿',
        label:'托儿',
        img:'https://res.cloudinary.com/dvr397xda/image/upload/v1667255792/meta-lift-icon/%E6%89%98%E5%84%BF_evjpip.jpg',
    },
    {
        value:'百货',
        label:'百货',
        img:'https://res.cloudinary.com/dvr397xda/image/upload/v1667255792/meta-lift-icon/%E6%89%98%E5%84%BF_evjpip.jpg',
    },
    {
        value:'福利',
        label:'福利',
        img:'https://res.cloudinary.com/dvr397xda/image/upload/v1667255792/meta-lift-icon/%E6%89%98%E5%84%BF_evjpip.jpg',
    },
    {
        value:'其它',
        label:'其它',
        img:'https://res.cloudinary.com/dvr397xda/image/upload/v1667255792/meta-lift-icon/%E5%85%B6%E4%BB%96_w1pgwd.jpg',
    },
    // {
    //     value:'树洞',
    //     label:'树洞',
    //     img:'',
    // },
   

]


const fetchCategory = ()=>{
    return category
}

export default fetchCategory