import {useState,Suspense, useEffect}from 'react'
import { Canvas, useLoader,} from '@react-three/fiber'
import {  useProgress,Html, OrbitControls  } from '@react-three/drei'
import * as THREE from 'three'
import {useParams} from 'react-router-dom'
import fetchPanorama from '../../panorama'
import './index.scss'



export default function Panorama() {
    // const [texture,setTexture] = useState('https://res.cloudinary.com/dvr397xda/image/upload/v1654270103/cube/b750lis-360-panorama-view-kitchen_rpigxu.jpg')
    const{ id} = useParams();

    const [images,setImages] = useState([])
    const [index,setIndex] = useState(0);
    const [file,setFile]=useState({})

    useEffect(()=>{

        const data = fetchPanorama()
        const target = data.filter((v=>v.id === id));
        setImages(target[0].data)
        setFile(target[0])

    },[id])

    const handleClick = (i) => {

        console.log(i)
        setIndex(i)
    }




    const Panorama = ({index,images})=>{
   

     
          
            const map = useLoader(THREE.TextureLoader,images[index].url)
         
        return  <>{map&&<mesh>
            <sphereBufferGeometry args={[200, 60, 40]} />
            <meshBasicMaterial map={map} side={THREE.BackSide} />
          </mesh>}</>
    }

  return (
    <>
    
   <Canvas frameloop="demand" >

    <OrbitControls enableZoom={true} minDistance={0} maxDistance={6.5} zoomSpeed={2}  />
    <Suspense
      fallback={
        <Loader/>
      }>
   { images&&<Panorama images={images} index={index}  />}
    </Suspense>
  </Canvas>
      
      <h2 id='pano-h2'>
      {file.name}
      </h2>

      <div className='scroll-wrap'> 
      <div>
      {
              images&&images.map((v,i)=>(
                <section key={i}>
                    {v.name&&<h6 id='pano-h6'>{v.name}</h6>}
                    <img onClick={()=>handleClick(i)}  src={v.url} alt="" />
                </section>
                
              ))
          }
      </div>
        
      </div>

    </>
  )
}


function Loader() {
    const { progress } = useProgress()
    return <Html center>
        <h2 id='loadText'>
        Loading ...{progress.toFixed(0)}%
        <img id='loadImage' src="https://res.cloudinary.com/dvr397xda/image/upload/v1657312449/cube/output-onlinegiftools_i29wl2.gif" alt="" />
        </h2>
        </Html>
  }