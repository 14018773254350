import React,{useState,useEffect,useCallback}from 'react'
import axios from 'axios'
import {Link } from 'react-router-dom'
import Select from 'react-select'

import './index.scss'


export default function AuthMerchant() {

    const isGallerys = [
        { value: '1', label: '1' },
        { value: '2', label: '2' },
        { value: '3', label: '3' },
        { value: '4', label: '4' },
        { value: '5', label: '5' },
        { value: '6', label: '6' },
        { value: '7', label: '7' },
        { value: '8', label: '8' },
        { value: '9', label: '9' },
       
    ]

    const MODELS = [
        { value: 'cookie', label: '曲奇' },
        { value: 'japan', label: '日本庭院' },
       

    ]
    const FREE = [
        { value: true, label: '免费版' },
        { value: false, label: '付费版' },
    ]

    const apiUrl ='https://metaserverapp.com'


    const [cards,setCards]=useState([])
   
    const initial = {
        id:'',
        tel:'',
        title:'',
        website:'',
        address:'',
        website2:'',
        bgImage:'',
        images:[
            'https://res.cloudinary.com/dvr397xda/image/upload/v1659726814/cube/Happy%20Town/WechatIMG9736_qkscq2.png',
            'https://res.cloudinary.com/dvr397xda/image/upload/v1659726814/cube/Happy%20Town/WechatIMG9736_qkscq2.png',
            'https://res.cloudinary.com/dvr397xda/image/upload/v1658519035/cube/WechatIMG9330_gm5mez_dpifk5.png',
            'https://res.cloudinary.com/dvr397xda/image/upload/v1658519035/cube/WechatIMG9330_gm5mez_dpifk5.png',
            '',
            '',
        ],
        menu:'',
        gallery:[],
        model:'',
        isFree:false
    }

 
    const [isDelete,setIsDelete] = useState(false)
    const [isCreate,setIsCreate] = useState(false)
    const [target,setTarget]= useState(initial)
    const [gallerys,setGallerys] = useState([])

    const [page,setPage] = useState(1)
    const [total,setTotal]=useState(0)
    const [totalPage,setTotalPage] = useState(0)

    useEffect(()=>{

        const fetchNews = async()=>{
            const result = await axios.get(`${apiUrl}/merchant/page`,{params:{page:1}})
            console.log(result)
            if(result.data.success){
        
                setCards(result.data.data[0].result)
      
              
            
              const temp = result.data.data[0].total[0].total
              setTotal(result.data.data[0].total[0].total)
              const temp2 = Math.ceil(temp/15)
              setTotalPage(temp2)
           
      
            }
            else{
              alert('Server Error! No data response received!')
            }
        }
        fetchNews()

    },[])

    useEffect(()=>{

        const fetchNews = async()=>{
            const result = await axios.get(`${apiUrl}/merchant/page`,{params:{page:page}})
            console.log(result)
            if(result.data.success){
        
                setCards(result.data.data[0].result)
      
              
            
              const temp = result.data.data[0].total[0].total
              setTotal(result.data.data[0].total[0].total)
              const temp2 = Math.ceil(temp/15)
              setTotalPage(temp2)
           
      
            }
            else{
              alert('Server Error! No data response received!')
            }
        }
        fetchNews()

    },[page])

   

    const handleName =(e)=>{
        let temp = {...target}
        temp.id = e.target.value
        setTarget(temp)
    }

    const handleTitle =(e)=>{
        let temp = {...target}
        temp.title = e.target.value
        setTarget(temp)
    }

    const handleTel =(e)=>{
        let temp = {...target}
        temp.tel = e.target.value
        setTarget(temp)
    }

    const handleWebsite =(e)=>{
        let temp = {...target}
        temp.website = e.target.value
        setTarget(temp)
    }

    const handleWebsite2 =(e)=>{
        let temp = {...target}
        temp.website2 = e.target.value
        setTarget(temp)
    }

    const handleAddress =(e)=>{
        let temp = {...target}
        temp.address = e.target.value
        setTarget(temp)
    }

    const handleMenu =(e)=>{
        let temp = {...target}
        temp.menu = e.target.value
        setTarget(temp)
    }

    const handleFront =(e)=>{
        let temp = {...target}
        temp.images[4] = e.target.value
        setTarget(temp)
    }

    const handleBack =(e)=>{
        let temp = {...target}
        temp.images[5] = e.target.value
        setTarget(temp)
    }

    const handleLeft =(e)=>{
        let temp = {...target}
        temp.images[1] = e.target.value
        setTarget(temp)
    }

    const handleBg =(e)=>{
        let temp = {...target}
        temp.bgImage = e.target.value
        setTarget(temp)
    }

    const handleGallery =(e)=>{
        console.log(e);

        let temp ={...target}
        temp.gallery=e.value
        let arr = [];
        if(e.value){
            for(let i=0;i<e.value;i++){
                arr.push('')
            }
            setGallerys(arr)
        }
        temp.gallery = arr
        setTarget(temp)
     }

     const handleChangeGallery = (e,i)=>{
        console.log(e);

        let temp ={...target}
        temp.gallery[i]=e.target.value
        setTarget(temp)

     }

     

    const handleDelete=()=>{
        setIsDelete(true)
    }
    const handleCancelDelete=()=>{
        setIsDelete(false)
        setTarget(initial)
    }   

    const handleDeleteNews=async(v)=>{
        console.log(v)
        const result = await axios.post(`${apiUrl}/merchant/delete`,{merchantId:v._id})
        console.log(result)
        if(result.data.success){
            window.location.reload()
        }
        else{
            alert('something went wrong when delete Card!')
        }
    }

    const handleCreateNews = ()=>{
        setIsCreate(!isCreate)
    }

    const handleModel = (e)=>{
        let temp = {...target}
        temp.model = e.value
        setTarget(temp)
    }

    const handleFree = (e)=>{
        let temp = {...target}
        temp.isFree = e.value
        setTarget(temp)
    }


    const handleNextPage = ()=>{
        setPage(page+1)
        const wrap =document.getElementsByClassName('auth-posts-form')[0]
        wrap.scrollTop = 0
      }
      const handleLastPage = ()=>{
        setPage(page-1)
        const wrap =document.getElementsByClassName('auth-posts-form')[0]
        wrap.scrollTop = 0
    
      }

     const handleSubmit = async()=>{
        if(!target.id){
            alert('Missing content')
        }
        else{
            const data = await axios.post(`${apiUrl}/merchant/create`,{
               id:target.id,
               title:target.title,
               tel:target.tel,
               website2:target.website2,

               website:target.website,
               address:target.address,
               bgImage:target.bgImage,
               images:target.images,
               menu:target.menu,
               gallery:target.gallery,
               model:target.model,
               isFree:target.isFree
            })
            console.log(data)
            if(data.data.success){
                
                alert("创建成功！")
                window.location.reload()
            }
            else{
                alert(data.data.errors)
            }
        }

     }

   
    console.log(target);
  return (
    <div className='auth-post-wrap'>
        <h2>商家名片管理</h2>
        
        <div className='auth-post-btns'>
            <Link to='/auth'> <button >返回</button></Link> 
            <button onClick={()=>handleCreateNews()}> {isCreate ? '取消创建' :' 创建名片'}</button>
        </div>

        <div className='auth-posts-form'>
        <table>
            <thead>
                <tr>
                    <th>
                        名字
                    </th>
                    <th>网址</th>
                    <th>
                        正面
                    </th>
                   
                    <th>
                        背面
                    </th>
                    <th>
                        菜单
                    </th>
                    <th>
                        业务
                    </th>
                    
                    <th>
                       电话
                    </th>
                   <th>预览</th>
                    
                    <th>
                        删除
                    </th>
                </tr>
            </thead>
            <tbody>
                {
                    cards.length>0&&cards.map((v,i)=>(
                        <tr key={i}>

                            <td>{v.id}</td>
                            <td>https://meta11w.com/merchant/{v.id}</td>
                            <td><img src={v.images[4]} alt="" /></td>
                            <td><img src={v.images[5]} alt="" /></td>
                            <td><img src={v.menu} alt="" /></td>
                            <td>{v.title}</td>
                            <td>{v.tel}</td>

                                <td>
                                    <Link to={`/merchant/${v.id}`}>
                                    <button >View</button>
                                    </Link>
                                   
                                </td>

                            {isDelete ?<td className='post-delete-group'><button onClick={()=>handleDeleteNews(v)}>确定</button><button onClick={()=>handleCancelDelete()}>取消</button></td> :<td><button onClick={()=>handleDelete()}>删除</button></td>}
                        </tr>
                    )

                    )
                }
            </tbody>


        </table>


        </div>

        <div className='pagenation-wrap'>

<div> {page!==1&&<p onClick={()=>handleLastPage()}>上一页</p>}</div>

<div> <p>第{page}页</p> </div>

<div> {page !== totalPage&&<p onClick={()=>handleNextPage()}>下一页</p>}</div>

<div><p>共{totalPage}页</p></div>
<div>
<p>共{total}个结果</p>
</div>
</div>
     

     
     <div style={{top:isCreate? '12vh':'220vh',transition:'1s'}} className='auth-product3-create'>

    <div className='news-wrap'>

        <div>
        <h4>名字</h4>
    <input type="text" value={target.id} onChange={(e)=>handleName(e)} />
        </div>
        
        <div>
        <h4>业务</h4>
        <input type="text" value={target.title} onChange={(e)=>handleTitle(e)} />
        </div>

        <div>
        <h4>电话</h4>
        <input type="text" value={target.tel} onChange={(e)=>handleTel(e)}/>
        </div>

        <div>
        <h4>正面网站</h4>
        <input type="text" value={target.website} onChange={(e)=>handleWebsite(e)}/>
        </div>

        <div>
        <h4>左面网站2</h4>
        <input type="text" value={target.website2} onChange={(e)=>handleWebsite2(e)}/>
        </div>

        <div>
        <h4>地址</h4>
        <input type="text" value={target.address} onChange={(e)=>handleAddress(e)}/>
        </div>

        <div>
        <h4>菜单</h4>
        <input type="text" value={target.menu} onChange={(e)=>handleMenu(e)}/>
        </div>

        <div>
        <h4>正面图</h4>
        <input type="text" value={target.images[4]} onChange={(e)=>handleFront(e)}/>
        </div>

        <div>
        <h4>背面图</h4>
        <input type="text" value={target.images[5]} onChange={(e)=>handleBack(e)}/>
        </div>

        <div>
        <h4>左侧图</h4>
        <input type="text" value={target.images[1]} onChange={(e)=>handleLeft(e)}/>
        </div>

        <div>
        <h4>背景图</h4>
        <input type="text" value={target.bgImage} onChange={(e)=>handleBg(e)}/>
        </div>

        <div className="create-news-wrap">
        <label>右面轮播图数量</label>
        <Select    
         onChange={(e)=>handleGallery(e)}
         options={isGallerys}             
           menuPortalTarget={document.body}
        menuPosition="fixed"       maxMenuHeight='20vh'           
        />
    </div>

    {
        gallerys.length>0&&gallerys.map((v,i)=>(
            <div key={i} className="create-input-wrap">
            <label>轮播图{i+1}</label>
            <input value={target.gallery[i]} onChange={(e)=>handleChangeGallery(e,i)} type="text" />
        </div>
        ))
    }
    <div className="create-news-wrap">
        <label>模型</label>
        <Select    
         onChange={(e)=>handleModel(e)}
         options={MODELS}             
           menuPortalTarget={document.body}
        menuPosition="fixed"       maxMenuHeight='20vh'           
        />
    </div>
     <div className="create-news-wrap">
        <label>免费版或付费版</label>
        <Select    
         onChange={(e)=>handleFree(e)}
         options={FREE}             
           menuPortalTarget={document.body}
        menuPosition="fixed"       maxMenuHeight='20vh'           
        />
    </div>

       
    </div>

    <button onClick={()=>handleSubmit()}>Submit</button>

     </div>
        
        
    </div>
  )
}
