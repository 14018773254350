const data = [

    { 
        id:'happytown',
        name:'Happy Town',
        type:'Chinese Restaurant',
        tel:'7187621870',
        cell:'',
        email:'',
        address:'1803 College Point Blvd, Queens, NY 11356',
        map:'https://www.google.com/maps/place/Happy+Town+Chinese+Restaraunt/@40.7830606,-73.8461678,19z/data=!4m13!1m7!3m6!1s0x89c2f552455cc2d5:0x50f4b6215eb69bf8!2s18-03+College+Point+Blvd,+College+Point,+NY+11356!3b1!8m2!3d40.7830606!4d-73.8456206!3m4!1s0x89c2f552456312c3:0x6da73851c5c03942!8m2!3d40.783135!4d-73.8456216',
        video:'',
        facebook:'',
        background:'https://res.cloudinary.com/dvr397xda/image/upload/v1653927826/cube/1921iq6-a-room-that-has-a-umbrella-in-her-hand_rainyw.jpg',
        linkedin:'',
        website:'',
        images:[
            'https://res.cloudinary.com/dvr397xda/image/upload/v1659726814/cube/Happy%20Town/WechatIMG9736_qkscq2.png', //right
            'https://res.cloudinary.com/dvr397xda/image/upload/v1659726814/cube/Happy%20Town/WechatIMG9736_qkscq2.png', //left
            'https://res.cloudinary.com/dvr397xda/image/upload/v1659726814/cube/Happy%20Town/WechatIMG9736_qkscq2.png', //glass
            'https://res.cloudinary.com/dvr397xda/image/upload/v1659726814/cube/Happy%20Town/WechatIMG9736_qkscq2.png', //glass
            'https://res.cloudinary.com/dvr397xda/image/upload/v1659649112/cube/Happy%20Town/WechatIMG9663_odwa1y.png', //front
            'https://res.cloudinary.com/dvr397xda/image/upload/v1659649113/cube/Happy%20Town/WechatIMG9662_jipixn.png'//back
    ],

  
    gif:'https://res.cloudinary.com/dvr397xda/image/upload/v1659726289/cube/Happy%20Town/WechatIMG9734_xtvw40.jpg',
    loadImg:'',
    qrCode:'https://res.cloudinary.com/dvr397xda/image/upload/v1659103934/cube/basic/lily/WechatIMG9503_hqjx5y.jpg',
    model:{
        name:'cookie',
        url:'https://res.cloudinary.com/dvr397xda/raw/upload/v1659726272/cube/Happy%20Town/model_kjh542.fbx',
        metalMap:'',
        normalMap:'https://res.cloudinary.com/dvr397xda/image/upload/v1659726267/cube/Happy%20Town/model_normal_tsbl8o.jpg',
        roughnessMap:'',
        colorMap:'https://res.cloudinary.com/dvr397xda/image/upload/v1659726193/cube/Happy%20Town/model_avhsp5.jpg',
        aoMap:'',
        scale:3,
        position:[0,0,0],
        rotation:[0,0,Math.PI / 2.5],
    },
    isMenu:true,

    menu:'https://res.cloudinary.com/dvr397xda/image/upload/v1659726384/cube/Happy%20Town/WechatIMG9733_agd6us.png',
    pano:'',
    isGallery:false,

    },
    { 
        id:'kinhasushi',
        name:'Kinha Sushi',
        type:'Japanese Restaurant',
        tel:'5168770888',
        cell:'',
        
        address:'910 Franklin Ave, Garden City, NY 11530',
        map:'https://goo.gl/maps/tf6aeD1d1W1CgEWL9',
        video:'',
        facebook:'',
        background:'https://res.cloudinary.com/dvr397xda/image/upload/v1662416602/cube/kinha%20sushi/WechatIMG10445_xrknsr.jpg',
        linkedin:'',
        website:'http://www.kinhasushi988.com/',
        images:[
            'https://res.cloudinary.com/dvr397xda/image/upload/v1661979677/cube/kinha%20sushi/WechatIMG10332_ippyxu.png', //right
            'https://res.cloudinary.com/dvr397xda/image/upload/v1661979677/cube/kinha%20sushi/WechatIMG10332_ippyxu.png', //left
            'https://res.cloudinary.com/dvr397xda/image/upload/v1661979677/cube/kinha%20sushi/WechatIMG10332_ippyxu.png', //glass
            'https://res.cloudinary.com/dvr397xda/image/upload/v1661979677/cube/kinha%20sushi/WechatIMG10332_ippyxu.png', //glass
            'https://res.cloudinary.com/dvr397xda/image/upload/v1661980476/cube/kinha%20sushi/WechatIMG10334_o4jmvf.png', //front
            'https://res.cloudinary.com/dvr397xda/image/upload/v1662398650/cube/kinha%20sushi/WechatIMG10439_bumksr.png'//back
    ],
    gif:'https://res.cloudinary.com/dvr397xda/image/upload/v1662500579/cube/kinha%20sushi/WechatIMG10448_jfcsc0.jpg',
    loadImg:'',
    qrCode:'https://res.cloudinary.com/dvr397xda/image/upload/v1659103934/cube/basic/lily/WechatIMG9503_hqjx5y.jpg',
    model:{
        name:'cookie',
        url:'https://res.cloudinary.com/dvr397xda/raw/upload/v1662070813/cube/kinha%20sushi/Japanese_Garden_Scene_kkqvcn.fbx',
        metalMap:'',
        normalMap:'https://res.cloudinary.com/dvr397xda/image/upload/v1659726267/cube/Happy%20Town/model_normal_tsbl8o.jpg',
        roughnessMap:'',
        colorMap:'https://res.cloudinary.com/dvr397xda/image/upload/v1659726193/cube/Happy%20Town/model_avhsp5.jpg',
        aoMap:'',
        scale:0.0015,
        position:[0,-1,0],
        rotation:[0,0,0],
    },
    isMenu:false,
    menu:'https://res.cloudinary.com/dvr397xda/image/upload/v1659726384/cube/Happy%20Town/WechatIMG9733_agd6us.png',
    pano:'https://2belowus.com/kinhasushi/index.html',
    isGallery:true,
    gallery:[
        'https://res.cloudinary.com/dvr397xda/image/upload/v1662415943/cube/kinha%20sushi/13_jit1mk.png',
        'https://res.cloudinary.com/dvr397xda/image/upload/v1662415943/cube/kinha%20sushi/6_ixgdki.png',
        'https://res.cloudinary.com/dvr397xda/image/upload/v1662415943/cube/kinha%20sushi/7_asaweh.png',
        'https://res.cloudinary.com/dvr397xda/image/upload/v1662415943/cube/kinha%20sushi/10_jdsxrb.png',
        'https://res.cloudinary.com/dvr397xda/image/upload/v1662415943/cube/kinha%20sushi/12_djmikf.png',
        'https://res.cloudinary.com/dvr397xda/image/upload/v1662415943/cube/kinha%20sushi/9_ibnjix.png',
        'https://res.cloudinary.com/dvr397xda/image/upload/v1662415942/cube/kinha%20sushi/8_mtegxz.png',
        'https://res.cloudinary.com/dvr397xda/image/upload/v1662415942/cube/kinha%20sushi/5_vtagij.png',
        'https://res.cloudinary.com/dvr397xda/image/upload/v1662415942/cube/kinha%20sushi/4_w4g4qn.png',
        'https://res.cloudinary.com/dvr397xda/image/upload/v1662415942/cube/kinha%20sushi/0_ndaqle.png',
        'https://res.cloudinary.com/dvr397xda/image/upload/v1662415942/cube/kinha%20sushi/3_mburya.png',
        'https://res.cloudinary.com/dvr397xda/image/upload/v1662415942/cube/kinha%20sushi/2_mrwbdc.png',
        'https://res.cloudinary.com/dvr397xda/image/upload/v1662415942/cube/kinha%20sushi/1_p907es.png',
        

    ]
    },
  




]

const fetchRestaurant = ()=>{
    return data;
}


export default fetchRestaurant
