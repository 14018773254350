import React,{useState,useEffect,useRef,Suspense,useCallback}from 'react'
import { Canvas,useLoader,useFrame} from '@react-three/fiber'
import * as THREE from "three";
import {useParams} from 'react-router-dom'
import {Link} from 'react-router-dom'
import FileSaver from "file-saver";

import {
  TelephoneFill,
  GeoAlt,

  Envelope,
  Globe2,
  PeopleFill
} from "react-bootstrap-icons";
import axios from 'axios';
import { Helmet } from 'react-helmet';

import {OrbitControls,Line} from '@react-three/drei'

import './index.scss'

function Box(props) {
  const {people,position} = props
  const mesh = useRef()
  let textures;

textures = useLoader(THREE.TextureLoader,[people.images[0],people.images[1],people.images[2],people.images[3],people.images[4],people.images[5]])
textures.minFilter = textures.magFilter = THREE.LinearFilter;
textures.format= THREE.RGBAFormat;

  function Map (){
    return(
      textures.map((texture, idx) =>(
        idx ===2||idx===3 || idx===0?<meshBasicMaterial key={idx} attach={`material-${idx}`} map={texture}   transparent={true} opacity={1}/>
        :<meshBasicMaterial opacity={1} transparent={true}  key={idx} attach={`material-${idx}`} map={texture}  side={THREE.DoubleSide}  />
        )
)
    )
  }
  return (
    <>
    <mesh
      onClick={(e)=>{
        e.stopPropagation()
      }}
      ref={mesh}
     position={position}
     scale={0.8}
      >
       {
        people.rotation ?  <boxBufferGeometry attach="geometry"  args={[3, 5.25, 0.1]}/>
        : <boxBufferGeometry attach="geometry"  args={[3.5, 2, 0.1]}/>
      
       }   
        <Map/>
    </mesh>
    </>
  )
}

const Group = ({people})=>{

      const mesh = useRef()
      const [v,setV]= useState(1)

      useFrame(({clock}) => {
        setV(Math.abs(Math.sin(clock.elapsedTime)/0.8))
        mesh.current.rotation.y += 0.001
      })

      return(<group ref={mesh}>
       <Box people={people}   position={people.rotation?[0, 0.5, 0]:[0, 0, 0]} />
            {/* {
              POINT.map((m,i)=>(
                <Line key={i}  points={m.point} color='black'  linewidth={2} position={[0,0,0]} dashed
                dashSize={v * 1.5}
                gapSize={2 - v * 2}/>
              ))
            } */}
            </group>)
}
export default function Card3d() {
    const{ name } = useParams();
    const apiUrl ='https://metaserverapp.com'

    const initial = {
        id:''
    }
    const [people,setPeople]=useState(initial)
    const [gallery,setGallery] = useState([])

   useEffect(()=>{

    const fetchCard = async()=>{
        const result = await axios.get(`${apiUrl}/card/id`,{
            params:{id:name}
        })
        if(result.data.success){
            setPeople(result.data.data)
            setGallery(result.data.data.images)
        }
    }
    fetchCard()
   },[name])

useEffect(()=>{

  const viewCard = async()=>{
    const result = await axios.post(`${apiUrl}/card/view`,{cardId:people._id})
  }
  if(people._id){
    viewCard()
  }

},[people])

  const [isQR,setIsQR] = useState(false)

  const handleCloseTeam = ()=>{
    setIsQR(false)
  }

  const handleClickAddress=()=>{

    window.open(`${people.address}`,'_self')
  }

  const handleClickPhone=()=>{

    window.open(`tel:+1${people.tel}`,'_self')
  }

  const handleClickWebsite=()=>{

    window.open(`${people.website}`,'_self')
  }

  const handleClickEmail=()=>{

    window.open(`mailto:${people.email}`,'_self')
  }
  const handleWechat = ()=>{
    setIsQR(true)
  }

  const handleClickSave=(e)=>{
    e.preventDefault();

    const temp = {...people}
    let tel = temp.tel
    console.log(tel)
    const moblie = '('+tel.slice(0,3)+')'+' '+tel.slice(3,6)+'-'+tel.slice(6,10)
    console.log(moblie)
    // ADR;type=WORK;type=pref:;;;${`4306 Main Street,Flushing,NY,11355`};;;

    let file = new Blob(
      [
        `BEGIN:VCARD
VERSION:3.0
N:${people.id};${people.id};;;
FN:${people.id} ${people.id}
TITLE:${people.title};
EMAIL;type=INTERNET;type=pref:${people.email}
TEL;type=MAIN:${moblie}
TEL;type=CELL;type=VOICE;type=pref:${moblie}
END:VCARD
`
      ],
      { type: "text/vcard;charset=utf-8" }
    );
    console.log(file)
    FileSaver.saveAs(
      file,
      `${people.id}.vcf`,
      true
    );
  }

  console.log(people)

  return (
    <div className="container-card" style={{backgroundImage:`url(${people.bgImage})`}}  >
         <Helmet
      title={people.title}
      meta={[
        {
          property:'og:image',
          content:gallery[5]
        },
       
        {
          name: people.title ?people.title:'' ,
          content: people.id ? people.id :'',
        },
      ]}
    />
           {people.isLogo&&<Link id='return-home' to='/recommand'><img src="https://res.cloudinary.com/dvr397xda/image/upload/v1664834364/cube/Free/metalogo_ctuhtw.png" alt="" /></Link>}
     
     <Canvas camera={{ position: [0, 0, 4.5] }}  gl={{ alpha: true, antialias: true}}  className='canvas-wrap'>
     <OrbitControls minPolarAngle={Math.PI / 2} maxPolarAngle={Math.PI / 2.5} />
     <ambientLight  />
      <Suspense fallback={null}>
      {people.id&&<Group people={people}  position={[0, 0, 0]}/>}
    </Suspense>
    </Canvas>

          <div style={isQR ? {top:'20%',transition:'1.5s'}:{top:'200%',transition:'1.5s'}}  className="wrapper">
          <img id='qr' src={people.weChat} alt="" />
          <h3>长按识别图中二维码到通讯录</h3>

          <img onClick={()=>handleCloseTeam()} id='close' src="https://res.cloudinary.com/dvr397xda/image/upload/v1656101942/cube/cancel_dmukx0.png" alt="" />
    </div>

    <div className='bottom-icon-wrap'>
      <div>
        {people.tel&&<TelephoneFill onClick={()=>handleClickPhone()}/>}
        {people.address&&<GeoAlt onClick={()=>handleClickAddress()}/>}
       { people.weChat&&<PeopleFill onClick={()=>handleWechat()}/>}
        {people.website&&<Globe2 onClick={()=>handleClickWebsite()}/>}
        {people.email&&<Envelope onClick={()=>handleClickEmail()}/>}
      </div>
    </div>
          <a  onClick={(e)=>handleClickSave(e)}><button id='save-btn'>保存名片</button></a>

    </div>
  )
}
