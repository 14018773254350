import React,{useState,useEffect,useCallback}from 'react'


import axios from 'axios'

import {Navigate} from 'react-router-dom'
import Nav from '../../../components/Nav/index'
import Footer from '../../../components/Footer/index'
import './index.scss'


export default function UserPost() {

    const apiUrl ='https://metaserverapp.com'
    const token = window.localStorage.getItem('token')
    const userId = window.localStorage.getItem('userId')

    const [allPost,setAllPost] = useState([])
    const [page,setPage] = useState(1)
    const [total,setTotal] = useState(0)
    const [isPost,setIsPost] = useState(true)
    const [isLike,setIsLike] = useState(false)
    const [totalPage,setTotalPage] = useState(0)
    const [like,setLike]= useState([])

    const handlePost=()=>{
        setIsPost(true)
        setIsLike(false)
    }
    const handleLike=()=>{
        setIsPost(false)
        setIsLike(true)
    }
    


    const [isDelete,setIsDelete] = useState(false)

    const handleClickDelete=()=>{
        setIsDelete(true)
    }
    const handleCancelDelete=()=>{
        setIsDelete(false)
    }

    const handleDeletePost = async(v,i)=>{

        console.log(v,i)
        let temp = [...allPost]
        temp.splice(i,1)
        const TOTAL = temp.length
        setAllPost(temp)
        setTotal(TOTAL)
        await axios.post(`${apiUrl}/post/delete`,{
            postId:v._id
        })

    }


    useEffect(() => {

        const fetchUserPost = async()=>{

            const result = await axios.get(`${apiUrl}/post/user`,
            {params:{userId,page:page}}
            )
            // console.log(result.data.data)
            if(result.data.success){
                setAllPost(result.data.data[0].result)
                const temp = result.data.data[0].total[0].total
            setTotal(result.data.data[0].total[0].total)
            const temp2 = Math.ceil(temp/50)
            setTotalPage(temp2)
          
            }
        }
        fetchUserPost()
    },[page,userId])

    useEffect(() => {
        const fetchLike = async()=>{
            const result = await axios.get(`${apiUrl}/basic/user`,{
                params:{userId:userId},
            })
            console.log(result.data.data);
            if(result.data.data.length > 0){
                setLike(result.data.data);
            }

        }
        fetchLike()
    },[userId])

    const handleNextPage = ()=>{
        setPage(page+1)
        const wrap =document.getElementsByClassName('home-post-wrap')[0]
        console.log(wrap);
        wrap.scrollTop = 0
      }
      const handleLastPage = ()=>{
        setPage(page-1)
        const wrap =document.getElementsByClassName('home-post-wrap')[0]
        console.log(wrap);
        wrap.scrollTop = 0
    
      }

      const handleClickCard = (v) => {
        window.open(`https://meta11w.com/cards/pro/${v.id}`,'_self')

      }

  return (
    <>

   <div className='user-wrap'>
        <Nav/>
    <div className='post-content'>

       <div className='posts-wrap'>
          
            <div className='user-like-check'>
                <button onClick={()=>handlePost()} style={{backgroundColor:isPost ?'#2aa165':''}}>
                    我的帖子
                </button>
                <button onClick={()=>handleLike()} style={{backgroundColor:isLike ?'#2aa165':''}}>
                    我的收藏
                </button>
            </div>

            {isPost&&<div  className='posts-container2'>
                {
                    total>0&&allPost.map((v,i)=>(
                        <div className='post-card' key={i}>
                            <div className='post-top'>
                                 {/* <h4>标题:{v.title.length>12 ? v.title.substring(0,12)+'...' : v.title}</h4> */}
                                 <h4>标题:{v.title.length>13 ? v.title.substring(0,13)+'...' : v.title}</h4>
                                 <div className='post-view-wrap'>
                                     <img src="https://res.cloudinary.com/dvr397xda/image/upload/v1663183172/cube/basic/view_yc4xlq.png" alt="" />
                                     <p>{v.views}</p>
                                 </div>
                            </div>
                            <hr />

                            <div className='post-mid'>
                                <div className='post-mid-category'>
                                 <p>分类:{v.category}</p>
                                 <p>{v.area}</p>
                                 <p>{v.location}</p>
                                 <p>{v.city}</p>
                                </div>
                                <p>
                                    {v.content.length>100 ? v.content.substring(0, 100) : v.content}
                                </p>

                            </div>
                            <div className='posts-btn'>
                                {
                                    isDelete ? <div>
                                        <button onClick={()=>handleDeletePost(v,i)}>确定删除</button>
                                        <button onClick={()=>handleCancelDelete()}>取消</button>
                                    </div> :<button onClick={()=>handleClickDelete()}>删除帖子</button> 
                                }

                            </div>


                        </div>
                    ))
                }

            </div>}

            {isLike&&<div className='like-container'>
            {<div className='cards-wrap'>
                    {like&&like.map((v,i)=>(

                        (v.isActive&&<div onClick={()=>handleClickCard(v)} key={i} className='cards-item'>
                           
                            <div className='cards-item-right'>
                                <img src={v.images[4]} alt="" />
                            </div>
                            <div className='cards-item-left'>
                                <h5>{v.id}</h5>
                                <h5>{v.title}</h5>
                            </div>
                        </div>)

                    ))}
                </div>}
            </div> }
        </div>
    


</div>
            {isPost&&<div className='pagenation-wrap'>

            <div> {page!==1&&<p onClick={()=>handleLastPage()}>上一页</p>}</div>

            <div> <p>第{page}页</p> </div>

            <div> {page !== totalPage&&<p onClick={()=>handleNextPage()}>下一页</p>}</div>

            <div><p>共{totalPage}页</p></div>
            <div>
            <p>共{total}个结果</p>
            </div>
            </div>}
<Footer/>

</div> 

</>
  )
}
