import React,{useState,useEffect} from 'react'
import Nav from '../../components/Nav/index'
import Footer from '../../components/Footer'
import {
    Eye
  } from "react-bootstrap-icons";
import axios from "axios";
import { Helmet } from 'react-helmet';


import './index.scss'

export default function CouponPage() {



  const apiUrl ='https://metaserverapp.com'

  const [news,setNews]=useState([])
    const [page,setPage] = useState(1)
    const [total,setTotal]=useState(0)
    const [totalPage,setTotalPage] = useState(0)

    useEffect(()=>{

        const fetchNews = async()=>{
            const result = await axios.get(`${apiUrl}/coupon`,{params:{page:1}})
            if(result.data.success){
           
                setNews(result.data.data[0].result)
      
              
            
              const temp = result.data.data[0].total[0].total
              setTotal(result.data.data[0].total[0].total)
              const temp2 = Math.ceil(temp/15)
              setTotalPage(temp2)
           
      
            }
            else{
              alert('Server Error! No data response received!')
            }
        }
        fetchNews()

    },[])

    useEffect(()=>{

        const fetchNews = async()=>{
            const result = await axios.get(`${apiUrl}/coupon`,{params:{page:page}})
            if(result.data.success){
           
                setNews(result.data.data[0].result)
      
              
            
              const temp = result.data.data[0].total[0].total
              setTotal(result.data.data[0].total[0].total)
              const temp2 = Math.ceil(temp/15)
              setTotalPage(temp2)
           
      
            }
            else{
              alert('Server Error! No data response received!')
            }
        }
        fetchNews()

    },[page])


    const handleNextPage = ()=>{
        setPage(page+1)
        const wrap =document.getElementsByClassName('auth-posts-form')[0]
        wrap.scrollTop = 0
      }
      const handleLastPage = ()=>{
        setPage(page-1)
        const wrap =document.getElementsByClassName('auth-posts-form')[0]
        wrap.scrollTop = 0
    
      }

      const handleClickCoupon = async(v)=>{
        await axios.post(`${apiUrl}/coupon/view`,{id:v._id})

        window.open(v.url,'_self')

      }



console.log(news)

  return (

    <div className='home-wrap'>
        <Helmet
      title={'元生活'}
      meta={[
       
        {
          name: `description`,
          content: '美国华人信息交流分享平台',
        },
      ]}
    
    />
        <Nav/>

      <div className='coupon-select-container'>
        {
            news.length>0&&news.map((v,i)=>(
                <div onClick={()=>handleClickCoupon(v)}  className='coupon-item-wrap' key={i}>

                    <div  className='coupon-item-img'>
                        <img src={v.image} alt="" />
                    </div>

                    <div className='coupon-item-content'>
                        <h2>{v.name}</h2>
                        <div>
                            <h4>原价:$<span>{v.price}</span> </h4>
                            <h3>现价:$<span>{v.sale}</span></h3>
                        </div>
                        {
                            v.code&&<h6>使用折扣码<span>{v.code}</span>额外再优惠${v.discount}</h6>
                        }
                        <div>
                            <p><Eye/><span>{v.views}</span></p>
                            <p>{v.date.substring(0,10)}</p>
                        </div>
                    </div>
                        <hr />

                </div>
            ))
        }
       
      
      </div>

    
     
     <div className='pagenation-wrap'>

<div> {page!==1&&<p onClick={()=>handleLastPage()}>上一页</p>}</div>

<div> <p>第{page}页</p> </div>

<div> {page !== totalPage&&<p onClick={()=>handleNextPage()}>下一页</p>}</div>

<div><p>共{totalPage}页</p></div>
<div>
<p>共{total}个结果</p>
</div>
</div>
        <Footer/>

    </div>
  )
}
