import React,{useState,useEffect} from 'react'
import Nav from '../../components/Nav/index'
import Footer from '../../components/Footer'

import axios from "axios";
import { Helmet } from 'react-helmet';
import {useParams} from 'react-router-dom'
import {
  ArrowClockwise
} from "react-bootstrap-icons";
import DOMPurify from 'dompurify';

import './index.scss'

export default function NewsPage() {

  const apiUrl ='https://metaserverapp.com'
  const{ id } = useParams();

  const [news,setNews]=useState({})
  const [index,setIndex] = useState(0)
  const [gallery,setGallery] = useState([])
  const [allNews,setAllNews]=useState([])
  const [last,setLast]= useState(0)
  const [next,setNext]= useState(0)

  const ISODate = new Date();
  const EPOCH_CONVERT = 1000;


  const epoch = Math.floor(ISODate.getTime() / EPOCH_CONVERT);
  

  useEffect(()=>{
    if(gallery.length>1){
      const interval = setInterval(() => {

        if(index ===gallery.length-1){
            setIndex(0)
        }
        else{
            setIndex(index+1)
        }
    }, 3500);
    return () => clearInterval(interval);
    }

  },[index,gallery])

  const handleChange = ()=>{

    const temp = [...allNews]
    let lastTemp;
    let nextTemp
    do {lastTemp = Math.floor(Math.random() * temp.length);}
    while(lastTemp ===index)
    do {nextTemp = Math.floor(Math.random() * temp.length);}
    while(nextTemp ===index&&nextTemp ===lastTemp)
    console.log(lastTemp,nextTemp)
    setLast(lastTemp)
    setNext(nextTemp)

  }


  useEffect(()=>{
    const fetchAllNews = async()=>{
      const result = await axios.get(`${apiUrl}/news/all`)
      // console.log(result);
      if(result.data.success){

        const temp = result.data.data;
        let index;
        for(let i=0;i<temp.length;i++){
          if(temp[i]._id===id){
            index= i
          }
        }
        console.log(index)
        let lastTemp;
        let nextTemp
        do {lastTemp = Math.floor(Math.random() * temp.length);}
        while(lastTemp ===index)
        do {nextTemp = Math.floor(Math.random() * temp.length);}
        while(nextTemp ===index&&nextTemp ===lastTemp)
        console.log(lastTemp,nextTemp)
        setLast(lastTemp)
        setNext(nextTemp)
        setAllNews(result.data.data)

      }
    }
    fetchAllNews()

  },[])
  console.log(allNews);
   

    useEffect(()=>{

        const fetchNews = async()=>{
            const result = await axios.get(`${apiUrl}/news/one`,{params:{id:id}})
            // console.log(result);
            if(result.data.success){
              setNews(result.data.data)
              setGallery(result.data.data.images)
              
              await axios.post(`${apiUrl}/news/view`,{newsId:id})

            }
            else{
              alert('Server Error! No data response received!')
            }
        }
        fetchNews()

    },[id])


const handleClickNews = (index)=>{
  window.open(`https://meta11w.com/news/${allNews[index]._id}`)
}

console.log(id)
console.log(news)

const [ads,setAds]=useState([])
const [adIndex,setAdIndex]=useState(0)

useEffect(()=>{

  const fetchAllAds = async()=>{
    const result = await axios.get(`${apiUrl}/ads/all`)
    console.log(result)
    if(result.data.success){
      const temp = result.data.data
      let arr = [];
      for(let i=0; i<temp.length; i++) {
        if(temp[i].expire>epoch){
          arr.push(temp[i])
        }
      }
      
      setAds(arr)
    }
    else{
      alert('Missing Ads')
    }
  }
  fetchAllAds()

},[])

useEffect(()=>{
  if(ads.length>1){
    const interval = setInterval(() => {

      if(adIndex ===ads.length-1){
          setAdIndex(0)
      }
      else{
          setAdIndex(adIndex+1)
      }
  }, 3500);
  return () => clearInterval(interval);
  }

},[adIndex,ads])

console.log(ads)
  return (

    <div className='home-wrap'>
        <Helmet
      title={news.title}
      meta={[
        {
          property:'og:image',
          content:gallery[0]
        },
       
        {
          name: news.title ?news.title:'' ,
          content: news.content ? news.content :'',
        },
      ]}
    
    />
        <Nav/>

      <div className='news-pages-container'>
       { news.video? <div className='news-pages-video'>
        <iframe title='news' height={window.innerWidth/1.5}  src={news.video} ></iframe>
       </div> :<div  className='news-pages-top'>
              <img src={gallery[index]} alt="" />
        </div>}
        <div className='news-pages-middle'>
          {news.source&&<h6>新闻社:{news.source}</h6>}
          {news.date&&<h6>日期:{news.date.substring(0,10)}</h6>}

        </div>
     
        <div className='news-pages-title'>
              {news.title&&<h3>{news.title}</h3>}
        </div>
        <div className='news-pages-content'>
          {
            news.epoch-1679071600>0 ? <div
            className="preview"
            dangerouslySetInnerHTML={createMarkup(news.content)}>
          </div>:<p>{news.content}</p>
          }
        </div>
        <div className='ad-phone'>
          <h3> 打广告联系: <a href="tel:+13015200518">3015200518</a> </h3>
        </div>

       {ads.length>0&&<div className='ads-wrap'>
        <h3>{ads[adIndex].title}</h3>

          <a href={ ads[adIndex].link?ads[adIndex].link :`tel:+1${ads[adIndex].tel}` }  >
            <img src={ads[adIndex].img} alt="" />
          </a>
        </div>}

        <div className='news-pages-footer'>
          <h4>标签</h4>
              {news.tags&&news.tags.map((v,i)=>(
                <h6 key={i}>{v}</h6>
              ))}
        </div>
        {
          news.keyword&& <div className='news-pages-middle'>
          {news.link&&<a href={news.link}><h3>{news.keyword}:点击观看详情</h3></a>}
        </div>
        }
        <div className='news-page-others'>
          <div className='news-page-others-top'>
          <h3>推荐新闻</h3>
          <div onClick={()=>handleChange()}>
                  <h3>换一批</h3>
                  <  ArrowClockwise/>
          </div>
          </div>

         { allNews.length>0&&<div className='news-page-others-wrap'>
            <div onClick={()=>handleClickNews(last)}>
                  <img src={allNews[last].images[0]} alt="" />
                  <h4>{allNews[last].title.substring(0,14)}...</h4>
            </div>
            <div onClick={()=>handleClickNews(next)}>
            <img src={allNews[next].images[0]} alt="" />
                  <h4>{allNews[next].title.substring(0,14)}...</h4>
            </div>

          </div>}

        </div>
     
      </div>
        <Footer/>

    </div>
  )
}

function createMarkup(html) {
  return {
    __html: DOMPurify.sanitize(html)
  }
}