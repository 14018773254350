import {useState,Suspense, useEffect}from 'react'
import { Canvas, useThree, useLoader } from '@react-three/fiber'
import { Html, OrbitControls, Loader } from '@react-three/drei'
import * as THREE from 'three'

import './index.scss'




export default function Admin() {
    // const [texture,setTexture] = useState('https://res.cloudinary.com/dvr397xda/image/upload/v1654270103/cube/b750lis-360-panorama-view-kitchen_rpigxu.jpg')

    
    const [texture,setTexture] = useState('');

    const handleFileUpload =  (e) =>{
        const file = e.target.files[0];

		const link = URL.createObjectURL(file);
       

       console.log(file,link)
       setTexture(link)
    }
  

    const Panorama = ({texture})=>{
   

     
            const [map,setMap ]=useState(useLoader(THREE.TextureLoader,texture)) 
         
        
      

        return  <>{map&&<mesh>
            <sphereBufferGeometry args={[500, 60, 40]} />
            <meshBasicMaterial map={map} side={THREE.BackSide} />
          </mesh>}</>
    }

  return (
    <>
        <div className="file-container">
            <h2>上传文件</h2>
            <input type="file" name='file'   onChange={handleFileUpload} />

        </div>
   <Canvas frameloop="demand" camera={{ position: [0, 0, 0.1] }}>
    <OrbitControls enableZoom={true} minDistance={10} maxDistance={300} zoomSpeed={2}  />
    <Suspense
      fallback={
        null
      }>
   { texture&&<Panorama texture={texture}/>}
    </Suspense>
  </Canvas>
    </>
  )
}
