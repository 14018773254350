import React,{useState,useEffect,useRef,Suspense,useCallback}from 'react'
import { Canvas, useFrame,extend,useThree,useLoader,} from '@react-three/fiber'
import * as THREE from "three";
import HTMLFlipBook from "react-pageflip";
import {useParams} from 'react-router-dom'

// import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";

import fetchRestaurant from '../../restaurant'
import {useGLTF,useFBX,useProgress,Html,OrbitControls,PresentationControls} from '@react-three/drei'

import './index.scss'

function Loader() {
  const { progress } = useProgress()
  return <Html center> <h2 id='loadText'>
        Loading ...{progress.toFixed(0)}%
        <img id='loadImage' src="https://res.cloudinary.com/dvr397xda/image/upload/v1659727126/cube/Happy%20Town/chop_kfqgd0.gif" alt="" />
        </h2></Html>
}

const  Model=({people})=>{
    const mesh = useRef()
    const defaultMap ='https://res.cloudinary.com/dvr397xda/image/upload/v1659113641/cube/basic/yuxin/Mew_Diff_tg9hgf.png'
    let key = useFBX(people.model.url)

  
    const [colorMap, metalMap, normalMap, roughnessMap, aoMap] = useLoader(THREE.TextureLoader, [
        people.model.colorMap||defaultMap,
        people.model.metalMap||defaultMap,
        people.model.normalMap||defaultMap,
        people.model.roughnessMap||defaultMap,
        people.model.aoMap||defaultMap,
      ])
    


    key.traverse( function ( child ) {

        if ( child.isMesh ) {
            if(people.model.colorMap)
            child.material.map = colorMap;
            if(people.model.aoMap)
            child.material.aoMap =aoMap;
            if(people.model.normalMap)
            child.material.normalMap = normalMap;
            if(people.model.metalMap)
            child.material.metalMap = metalMap; 
            if(people.model.roughnessMap)
            child.material.roughnessMap = roughnessMap; 
          }
      
      } );
      useFrame((state, delta) => {
      
    
        mesh.current.rotation.y += 0.005
      
      })

      return (
        <mesh scale={people.model.scale} position={people.model.position} rotation={people.model.rotation}  ref={mesh}>
            <primitive   object={key} />
    
        </mesh>
      )

}

const Board =({position,rotation,scale})=>{
  const mesh = useRef()
  let key = useFBX('https://res.cloudinary.com/dvr397xda/raw/upload/v1659724464/cube/Happy%20Town/Signboard-EXp_fxklp0.fbx')

  const colorMap = useLoader(THREE.TextureLoader,'https://res.cloudinary.com/dvr397xda/image/upload/v1659724471/cube/Happy%20Town/Signboard_lp-a_TT_checker_2048x2048_UV_GRI_cxwmgy.png')

  key.traverse( function ( child ) {

    if ( child.isMesh ) {
      
        child.material.map = colorMap;
       
      }
  
  } );

  return (
    <mesh scale={scale} position={position} rotation={rotation}  ref={mesh}>
        <primitive   object={key} />

    </mesh>
  )

}








function Box(props) {
  const {people,position} = props
  const mesh = useRef()
  const [index,setIndex] = useState(0)

 

 
  // setInterval(handleChangeIndex,1000)

  let textures;

textures = useLoader(THREE.TextureLoader,[people.images[0],people.images[1],people.images[2],people.images[3],people.images[4],people.images[5]])
// textures = useLoader(TextureLoader,[people.images[0],people.images[1],people.images[2],people.images[3],people.images[4],people.images[5]])

textures.minFilter = textures.magFilter = THREE.LinearFilter;



textures.format= THREE.RGBAFormat;




  function Map (){
    return(
    
      textures.map((texture, idx) =>(
        idx ===2||idx===3 || idx===0?<meshBasicMaterial key={idx} attach={`material-${idx}`} map={texture}   transparent={true} opacity={1}/>
        :<meshBasicMaterial opacity={1} transparent={true}  key={idx} attach={`material-${idx}`} map={texture}  />
        )
)
    )
  } 

  return (
    <>
    <mesh
      onClick={(e)=>{
        e.stopPropagation()
      }}
      ref={mesh}
     position={position}
     
      >
          
      <boxBufferGeometry attach="geometry"  args={[2.5, 2.5, 2.5]}/>
        <Map/>
       
      

    </mesh>


    </>
  )
}

// extend({ OrbitControls });

// const CameraControls = () => {

//   const {
//     camera,
//     gl: { domElement },
//   } = useThree();
//   const controls = useRef();
//   useFrame(() => controls.current.update());
//   return (
//     <orbitControls
//       ref={controls}
//       args={[camera, domElement]}
//       autoRotate={false}
//       enableZoom={true}
//       maxDistance={5.5}
//       minDistance={2}
//     />
//   );
// };

const Phone = ({people,opacity,posY})=>{

  const mesh1 = useRef()


  const handleClick=()=>{
    window.open(`tel:+1${people.tel}`,'_self')
  }


  return (
    <>
    <mesh position={[-0.8,posY-1.65,1.3]} rotation={[0,Math.PI * 2.5,0]} onClick={(e)=>{ handleClick();     e.stopPropagation()
}}   ref={mesh1}>
        <boxBufferGeometry  attach="geometry"  args={[0.1,1.1,0.8]}/>
        <meshStandardMaterial transparent={true} opacity={opacity}   color='green'  />

       


    </mesh>
    </>
  )
}




const Map = ({people,opacity,posY})=>{
 
  const mesh1 = useRef()


  const handleClick=()=>{

    window.open(`${people.map}`,'_self')
  }

  return (
    <>
    <mesh rotation={[0,Math.PI * 2.5,0]} position={[0,-1.65+posY,1.3]} onClick={(e)=>{ handleClick();     e.stopPropagation()
}}   ref={mesh1}>
        <boxBufferGeometry  attach="geometry"  args={[0.1,1.1,0.8]}/>
        <meshStandardMaterial transparent={true} opacity={opacity}   color='blue'   />

       


    </mesh>
    </>
  )
}



  const QR = ({opacity,posY,handleOpenQr})=>{
  
    const mesh1 = useRef()
  
  
  
    return (
      <>
      <mesh rotation={[0, Math.PI * 2.5, 0]} position={[0,-1.6+posY,-1.3]} onClick={(e)=>{  handleOpenQr();     e.stopPropagation()
  }}   ref={mesh1}>
          <boxBufferGeometry  attach="geometry"  args={[0.1,1.3,2.5]}/>
          <meshStandardMaterial transparent={true} opacity={opacity}   color='green'   />
  
         
  
  
      </mesh>
      </>
    )
  }



const Web = ({people,opacity,posY})=>{
 
  const mesh1 = useRef()

  const handleClick=()=>{
    window.open(`${people.website}`,'_self')
  }

  return (
    <>
    <mesh rotation={[0, Math.PI * 2.5, 0]} position={[0.8,-1.65+posY,1.3]} onClick={(e)=>{ handleClick();     e.stopPropagation()
}}   ref={mesh1}>
        <boxBufferGeometry  attach="geometry"  args={[0.1,1.1,0.8]}/>
        <meshStandardMaterial transparent={true} opacity={opacity}   color='#fff'   />

       


    </mesh>
    </>
  )
}

const Pano = ({people,opacity,posY})=>{
 
  const mesh1 = useRef()

  const handleClick=()=>{
    window.open(`${people.pano}`,'_self')
  }

  return (
    <>
    <mesh rotation={[0, 0, 0]} position={[-1.3,-1+posY,0]} onClick={(e)=>{ handleClick();     e.stopPropagation()
}}   ref={mesh1}>
        <boxBufferGeometry  attach="geometry"  args={[0.1,2.4,2.4]}/>
        <meshStandardMaterial transparent={true} opacity={opacity}   color='#fff'   />

       


    </mesh>
    </>
  )
}

const Gallery = ({gallery})=>{

  const mesh1 = useRef()
  const [index,setIndex] = useState(0)



  useEffect(() => {
      const interval = setInterval(() => {
  
          if(index ===gallery.length-1){
              setIndex(0)
          }
          else{
              setIndex(index+1)
          }
      }, 2500);
      return () => clearInterval(interval);
    }, [index,gallery.length]);


  
    let texture;
    texture =useLoader(THREE.TextureLoader,gallery[index])
    texture.format= THREE.RGBAFormat;
    texture.minFilter = THREE.LinearFilter;
    texture.magFilter = THREE.LinearFilter;
 

  


  return (
   
    <mesh position={[1.26,0,0]} rotation={[0,0,0]} onClick={(e)=>{     e.stopPropagation()
}}   ref={mesh1}>
        <boxBufferGeometry  attach="geometry"  args={[0.01,2.4,2.4]}/>
        <meshStandardMaterial map={texture}  side={THREE.DoubleSide}   />
    </mesh>

  )
}




export default function Restaurant() {
    const{ name } = useParams();
    console.log(name)
    const mesh2 = useRef()
    const [people,setPeople]=useState({})

   useEffect(()=>{

    const data = fetchRestaurant();
    const result = data.filter(e=>e.id===name)
 
    setPeople(result[0])

   },[])



  const [isTeam,setIsTeam] = useState(false)
  const [isQR,setIsQR] = useState(false)
  const opacity=0
  const posY = 1



  const handleOpenQr = ()=>{
    if(people.isMenu){

      setIsQR(true)
    }
    else{
      window.open(`${people.pano}`,'_self')

    }
  }




  
  const handleCloseTeam = ()=>{
    setIsTeam(false)
    setIsQR(false)
  }
  
 
 

  return (
    <div className="container-card" style={{backgroundImage:`url(${people.gif})`}}  >
     
     <Canvas  camera={{ position: [0, 0, 4.5] }}    gl={{ alpha: true, antialias: true}}  className='canvas-wrap' >

     {/* <OrbitControls global  minPolarAngle={Math.PI / 2.2} maxPolarAngle={Math.PI / 2.5} /> */}
    {/* <CameraControls /> */}
    {/* <ambientLight intensity={0.8} /> */}
 <pointLight position={[3, 3, 3]} intensity={1} color='#f0f0f0'/>
        <pointLight position={[3, -3, 3]} intensity={1} color='#f0f0f0'/>

        <pointLight position={[-3, 3, 3]} intensity={1} color='#f0f0f0'/>

        <pointLight position={[3, 3, -3]} intensity={1} color='#f0f0f0'/>
        <spotLight  position={[-3, -3, -3]} intensity={1} color='#f0f0f0'/>
        <PresentationControls polar={[-Math.PI / 2.5, Math.PI / 2.5]} global rotation={[0, 0.3, 0]}  azimuth={[-Infinity, Infinity]}>
        <Suspense fallback={<Loader/>}>
   

        
      <Box people={people}   position={[0, 0, 0]} />
    
        <Model people={people}/>

      <Phone posY={posY} people={people} opacity={opacity}/>

   
      {people.menu&&<QR handleOpenQr={handleOpenQr} posY={posY} people={people} opacity={opacity}/>}
      <Map posY={posY} people={people} opacity={opacity}/>
      {people.website&&<Web posY={posY} people={people} opacity={opacity}/>}
      {people.pano&&<Pano posY={posY} people={people} opacity={opacity}/>}
      {
        people.isGallery&&<Gallery gallery={people.gallery} />
      }

    
    </Suspense>
      </PresentationControls>
     

    </Canvas>
   
         

         

          <div style={isQR ? {top:'20%',transition:'1.5s'}:{top:'200%',transition:'1.5s'}}  className="wrapper">
         <img id='qr' src={people.menu} alt="" />
          <a href={`tel:+1${people.tel}`}>

          <button>
            <h3>
            Booking Now
            </h3>
            </button>
          </a>

          <img onClick={()=>handleCloseTeam()} id='close' src="https://res.cloudinary.com/dvr397xda/image/upload/v1656101942/cube/cancel_dmukx0.png" alt="" />
    </div>

    </div>
  )
}

