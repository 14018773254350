import React,{useEffect,useState
} from 'react'
import {Navigate} from 'react-router-dom'
import axios from 'axios'
import './index.scss'
import Admin from './Admin'
import Create from './Create'
import Update from './Update'
import Delete from './Delete'
import Contact from './Contact'
import Logout from './Logout'


const apiUrl ='https://metaserverapp.com'


export default function DesignerDashboard() {

    const NAV = [
        '首页',
        '创建电子名片',
        '更改电子名片',
        '删除电子名片',
        '联系客服',
        '退出'
    ]

    const [index,setIndex] =useState(0)

    const handleIndex = (i)=>{
        setIndex(i)
    }

    const designerToken = window.localStorage.getItem('designerToken')
    const designerId = window.localStorage.getItem('designerId')
    const [user,setUser]=useState({})
    console.log(designerId)

    useEffect(()=>{

        const fetchUser = async()=>{
            const result  = await axios.get(`${apiUrl}/designer/id`,{params:{id:designerId}})
            console.log(result)
            if(result.data.success){
                setUser(result.data.data)
            }
        }
        fetchUser()

    },[])

  return (

    <>

   { designerToken?
   <div className='dashboard'>
   <div className="dashboard-nav">
       <header><div                                                    className="brand-logo"><i
               className="fas fa-anchor"></i> <img src="https://res.cloudinary.com/dvr397xda/image/upload/v1664834364/cube/Free/metalogo_ctuhtw.png" alt="" /></div></header>
       <nav className="dashboard-nav-list">
        
        {
            NAV.length>0&&NAV.map((v,i)=>(
               ( i===index ?<h3 onClick={()=>handleIndex(i)} className="dashboard-nav-item active" key={i}>
                    {v}
                </h3>:<h3 onClick={()=>handleIndex(i)} className="dashboard-nav-item " key={i}>
                {v}
            </h3>)
            ))
        }
     
       </nav>
       
   </div>
   <div className='dashboard-app'>
        <div className='dashboard-app-top'>
            <h2>用户名: {user.name}</h2>
            <h3>可用名片数量: {user.limit}</h3>
        </div>
        <div className='dashboard-app-content'>
           {
            index===0&&<Admin/>
           }
           {
            index===1&&<Create/>
           }
           {
            index===2&&<Update/>
           }
           {
            index===3&&<Delete/>
           }
           {
            index===4&&<Contact/>
           }
           {
            index===5&&<Logout/>
           }

        </div>
   </div>
  
  
</div>
   
    :<Navigate to='/designer/login' />
}
    </>
  )
}
