const data = [

    { 
        id:'happytown',
        name:'Happy Town',
        type:'Chinese Restaurant',
        tel:'7187621870',
        cell:'',
        email:'',
        address:'1803 College Point Blvd, Queens, NY 11356',
        map:'https://www.google.com/maps/place/Happy+Town+Chinese+Restaraunt/@40.7830606,-73.8461678,19z/data=!4m13!1m7!3m6!1s0x89c2f552455cc2d5:0x50f4b6215eb69bf8!2s18-03+College+Point+Blvd,+College+Point,+NY+11356!3b1!8m2!3d40.7830606!4d-73.8456206!3m4!1s0x89c2f552456312c3:0x6da73851c5c03942!8m2!3d40.783135!4d-73.8456216',
        video:'',
        facebook:'',
        background:'https://res.cloudinary.com/dvr397xda/image/upload/v1653927826/cube/1921iq6-a-room-that-has-a-umbrella-in-her-hand_rainyw.jpg',
        linkedin:'',
        website:'',
        images:[
            'https://res.cloudinary.com/dvr397xda/image/upload/v1659726814/cube/Happy%20Town/WechatIMG9736_qkscq2.png', //right
            'https://res.cloudinary.com/dvr397xda/image/upload/v1659726814/cube/Happy%20Town/WechatIMG9736_qkscq2.png', //left
            'https://res.cloudinary.com/dvr397xda/image/upload/v1659726814/cube/Happy%20Town/WechatIMG9736_qkscq2.png', //glass
            'https://res.cloudinary.com/dvr397xda/image/upload/v1659726814/cube/Happy%20Town/WechatIMG9736_qkscq2.png', //glass
            'https://res.cloudinary.com/dvr397xda/image/upload/v1659649112/cube/Happy%20Town/WechatIMG9663_odwa1y.png', //front
            'https://res.cloudinary.com/dvr397xda/image/upload/v1659649113/cube/Happy%20Town/WechatIMG9662_jipixn.png'//back
    ],

  
    gif:'https://res.cloudinary.com/dvr397xda/image/upload/v1659726289/cube/Happy%20Town/WechatIMG9734_xtvw40.jpg',
    loadImg:'',
    qrCode:'https://res.cloudinary.com/dvr397xda/image/upload/v1659103934/cube/basic/lily/WechatIMG9503_hqjx5y.jpg',
    model:{
        name:'cookie',
        url:'https://res.cloudinary.com/dvr397xda/raw/upload/v1659726272/cube/Happy%20Town/model_kjh542.fbx',
        metalMap:'',
        normalMap:'https://res.cloudinary.com/dvr397xda/image/upload/v1659726267/cube/Happy%20Town/model_normal_tsbl8o.jpg',
        roughnessMap:'',
        colorMap:'https://res.cloudinary.com/dvr397xda/image/upload/v1659726193/cube/Happy%20Town/model_avhsp5.jpg',
        aoMap:'https://res.cloudinary.com/dvr397xda/image/upload/v1659726192/cube/Happy%20Town/model_occlusion_hdh4ad.jpg',
        scale:3,
        position:[0,0,0],
        rotation:[0,0,Math.PI / 2.5],
    },
    menu:'https://res.cloudinary.com/dvr397xda/image/upload/v1659726384/cube/Happy%20Town/WechatIMG9733_agd6us.png',
    menuList:[
       { name:'LunchSpecial',
       data:[
           { name:'Chicken',
           with:'',

            data:[
                {
                    name:'芥兰鸡',
                    english:'Chicken w. Broccoli',
                    price:'12.00',
                    spicy:false

                },
                {
                    name:'蘑菇鸡片',
                    english:'Moo Goo Gai Pan',
                    price:'12.00',
                    spicy:false

                },
                {
                    name:'酸甜鸡',
                    english:'Sweet&Sour Chicken',
                    price:'12.00',
                    spicy:false

                },
                {
                    name:'鸡捞面',
                    english:'Chicken Lo Mein',
                    price:'12.00',
                    spicy:false

                },
                {
                    name:'鸡炒面',
                    english:'Chicken Chow Mein',
                    price:'12.00',
                    spicy:false

                },
                {
                    name:'鸡蓉蛋',
                    english:'Chicken Egg Foo Young',
                    price:'12.00',
                    spicy:false

                },
                {
                    name:'芥兰鸡',
                    english:'Chicken w. Broccoli',
                    price:'12.00',
                    spicy:false

                },
                {
                    name:'什菜鸡',
                    english:'Chicken w. Mixed Vegetables',
                    price:'12.00',
                    spicy:false

                },
                {
                    name:'四季豆鸡',
                    english:'Chicken w. String Beans.',
                    price:'12.00',
                    spicy:false

                },
                {
                    name:'豆豉鸡',
                    english:'chircan w. Back Bean Sauce',
                    price:'12.00',
                    spicy:false

                },
                {
                    name:'蘑菇鸡',
                    english:'Chirranw muchroom',
                    price:'12.00',
                    spicy:false

                },
                {
                    name:'鸡米粉或炒粉',
                    english:'Chicken Me fun or chow Fun',
                    price:'12.00',
                    spicy:false

                },
                {
                    name:'鱼香茄子鸡',
                    english:'Chicken w. Eggplant Garlic Sc',
                    price:'12.00',
                    spicy:true
                },
                {
                    name:'鱼香鸡',
                    english:'Chicken w.Garlic Sc',
                    price:'12.00',
                    spicy:true
                },
                {
                    name:'宫保鸡',
                    english:'Kung Pao Chicken',
                    price:'12.00',
                    spicy:true
                },
                {
                    name:'咖喱鸡',
                    english:'Curry Chicken',
                    price:'12.00',
                    spicy:true
                },
                {
                    name:'左宗鸡',
                    english:'General Tso‘s chicken.',
                    price:'12.50',
                    spicy:true
                },
                {
                    name:'芝麻鸡',
                    english:'Sesame Chicken',
                    price:'12.50',
                    spicy:true
                },
           
            ]},
           { name:'Vegetable',
           with:'',

           data:[
                {
                    name:'鱼香茄子',
                    english:'Eggplant w. Garlic sauce',
                    price:'11.00',
                    spicy:true
                },
                {
                    name:'鱼香芥蓝',
                    english:'Broccoli w. Garlic sauce',
                    price:'11.00',
                    spicy:true
                },
                {
                    name:'芝麻豆腐',
                    english:'Bean Curd Sesame Sauce',
                    price:'11.00',
                    spicy:true
                },
                {
                    name:'家常豆腐',
                    english:'Bean cure home style',
                    price:'11.00',
                    spicy:false
                },
                {
                    name:'素什锦',
                    english:'Vegetable Delight',
                    price:'11.00',
                    spicy:false
                },
                {
                    name:'素什锦',
                    english:'Vegetable Delight',
                    price:'11.00',
                    spicy:false
                },
                {
                    name:'四季豆',
                    english:'Sauteed String Beans',
                    price:'11.00',
                    spicy:false
                },
                {
                    name:'菜捞面',
                    english:'Vegetable Lo Mein',
                    price:'11.00',
                    spicy:false
                },
                {
                    name:'炒芥兰',
                    english:'Sauteed Broccoli',
                    price:'11.00',
                    spicy:false
                },
                {
                    name:'菜米粉',
                    english:'Vegetable Mai Fun',
                    price:'11.00',
                    spicy:false
                },
            ]},
            {name:'Beef',
            with:'',

            data:[
                {
                    name:'芥蓝牛',
                    english:'Beef Broccoli',
                    price:'12.50',
                    spicy:false
                },
                {
                    name:'青椒牛',
                    english:'Pepper Steak w. Onions',
                    price:'12.50',
                    spicy:false
                },
                {
                    name:'什菜牛',
                    english:'Beef w. Mixed Vegetables',
                    price:'12.50',
                    spicy:false
                },
                {
                    name:'牛捞面',
                    english:'Beef Lo Mein',
                    price:'12.50',
                    spicy:false
                },
                {
                    name:'茄椒牛',
                    english:'Beef w. Pepper Tomato',
                    price:'12.50',
                    spicy:false
                },
                {
                    name:'蘑菇牛',
                    english:'Beef w. Mushroom',
                    price:'12.50',
                    spicy:false
                },
                {
                    name:'四季豆牛',
                    english:'Beef w. String Beans',
                    price:'12.50',
                    spicy:false
                },
                {
                    name:'牛米粉或炒粉',
                    english:'Beef Mei Fun or Chow Fun',
                    price:'12.50',
                    spicy:false
                },
                {
                    name:'湖南茄子牛',
                    english:'Beef w. Eggplant Hunnan Style',
                    price:'12.50',
                    spicy:true
                },
                {
                    name:'四川牛肉丝',
                    english:'Shredded BeefSzechuan Style',
                    price:'12.50',
                    spicy:true
                },
                {
                    name:'湖南茄子牛',
                    english:'Hunnan Beef',
                    price:'12.50',
                    spicy:true
                },
            ]},
            {name:'Shrimp',
            with:'',

            data:[
                {
                    name:'虾炒面',
                    english:'Shrimp Chow Mein',
                    price:'12.50',
                    spicy:false
                },
                {
                    name:'虾捞面',
                    english:'Shrimp Lo Mein',
                    price:'12.50',
                    spicy:false
                },
                {
                    name:'虾米粉或炒粉',
                    english:'Shrimp Mei Fun or Chow Fun',
                    price:'12.50',
                    spicy:false
                },
                {
                    name:'星州米粉',
                    english:'Singapore Mei Fun',
                    price:'12.50',
                    spicy:true
                },
                {
                    name:'腰果虾',
                    english:'Shrimp w. Cashew Nuts',
                    price:'12.50',
                    spicy:false
                },
                {
                    name:'芥蓝虾',
                    english:'Shrimp w. Broccoli',
                    price:'12.50',
                    spicy:false
                },
                {
                    name:'龙虾糊',
                    english:'Shrimp w. Lobster Sauce',
                    price:'12.50',
                    spicy:false
                },
                {
                    name:'什菜虾',
                    english:'Shrimp w. Mixed Vegetables',
                    price:'12.50',
                    spicy:false
                },
                {
                    name:'茄椒虾',
                    english:'Shrimp w. Pepper Tomato',
                    price:'12.50',
                    spicy:false
                },
                {
                    name:'鱼香虾',
                    english:'Shrimp w. Garlic Sauce',
                    price:'12.50',
                    spicy:true
                },
                {
                    name:'湖南茄子虾',
                    english:'Shrimp w. Eggplant Hunan Style',
                    price:'12.50',
                    spicy:true
                },

            ]},
            {name:'Pork',
            with:'',

            data:[
                {
                    name:'芥兰叉烧',
                    english:'Roast Pork w. Broccoli',
                    price:'12.00',
                    spicy:false
                },
                {
                    name:'叉烧捞面',
                    english:'Roast Pork Lo Mein',
                    price:'12.00',
                    spicy:false
                },
                {
                    name:'什菜叉烧',
                    english:'Roast Pork w. Mixed Vegetables',
                    price:'12.00',
                    spicy:false
                },
                {
                    name:'叉烧蓉蛋',
                    english:'Roast Pork Egg Foo Young',
                    price:'12.00',
                    spicy:false
                },
                {
                    name:'甜酸肉',
                    english:'Sweet & Sour Pork',
                    price:'12.00',
                    spicy:false
                },
                {
                    name:'四季豆肉',
                    english:'Pork w. String Beans',
                    price:'12.00',
                    spicy:false
                },
                {
                    name:'肉米粉或炒粉',
                    english:'Pork Mei Fun or Chow Fun',
                    price:'12.00',
                    spicy:false
                },
                {
                    name:'回锅肉片',
                    english:'Double Sauteed Sliced Pork',
                    price:'12.00',
                    spicy:true
                },
                {
                    name:'鱼香肉丝',
                    english:'Shredded Pork w. Garlic Sauce',
                    price:'12.00',
                    spicy:true
                },
                {
                    name:'无骨排',
                    english:'Boneless Spare Ribs',
                    price:'13.50',
                    spicy:false
                },
            ]}
]},
       { name:'Meal',
       data:[
            {name:'Appetizers',
                with:'',

                data:[
                    {
                        name:'本楼春卷',
                        english:'Home Style Spring Roll(2)',
                        spicy:false,
                        multi:false, 
                        price:'3.75'
                    },
                    {
                        name:'春卷',
                        english:'Pork Egg Roll',
                        spicy:false,
                        multi:false, 
                        price:'2.75'
                    },
                    {
                        name:'菜卷',
                        english:'Vegetable Roll',
                        spicy:false,
                        multi:false, 
                        price:'2.75'
                    },
                    {
                        name:'虾卷',
                        english:'Shrimp Roll',
                        spicy:false,
                        multi:false, 
                        price:'3.00'
                    },
                    {
                        name:'凤尾虾',
                        english:'Fantail Shrimp(3)',
                        spicy:false,
                        multi:false, 
                        price:'6.50'
                    },
                    {
                        name:'虾吐司',
                        english:'Shrimp Toast',
                        spicy:false,
                        multi:false, 
                        price:'6.75'
                    },
                    {
                        name:'鸡肉串',
                        english:'Chicken Terriyaki(5)',
                        spicy:false,
                        multi:false, 
                        price:'7.75'
                    },
                    {
                        name:'牛肉串',
                        english:'Beef on the Stick',
                        spicy:false,
                        multi:true, 
                        price:[
                            {
                            name:'5pc',
                            price:'10.50'
                        },
                        {
                            name:'10pc',
                            price:'20.00'
                        },
    
                        ]
                    },
                    {
                        name:'锅贴或水饺',
                        english:'Pork Dumpling Fried or Steamed(8)',
                        spicy:false,
                        multi:false, 
                        price:'9.75'
                    },
                    {
                        name:'菜锅贴或水饺',
                        english:'Vegetable Dumpling Fried or Steamed(8)',
                        spicy:false,
                        multi:false, 
                        price:'9.75'
                    },
                    {
                        name:'四川云吞',
                        english:'Szechuan Wonton(10)',
                        spicy:true,
                        multi:false, 
                        price:'7.00'
                    },
                    {
                        name:'炸云吞',
                        english:'Fried Wonton(10)',
                        spicy:false,
                        multi:false, 
                        price:'7.50'
                    },
                    {
                        name:'炸芝士云吞',
                        english:'Fried Cheese Wonton(10)',
                        spicy:false,
                        multi:false, 
                        price:'8.00'
                    },
                    {
                        name:'叉烧',
                        english:'Roast Pork',
                        spicy:false,
                        multi:true, 
                        price:[
                            {
                            name:'S',
                            price:'10.50'
                        },
                        {
                            name:'L',
                            price:'18.00'
                        },
    
                        ]
                    },
                    {
                        name:'烤排骨',
                        english:'Bar-B-Q Spare Ribs',
                        spicy:false,
                        multi:true, 
                        price:[
                            {
                            name:'S',
                            price:'12.00'
                        },
                        {
                            name:'L',
                            price:'22.50'
                        },
    
                        ]
                    },
                    {
                        name:'无骨排',
                        english:'Boneless Spare Ribs',
                        spicy:false,
                        multi:true, 
                        price:[
                            {
                            name:'S',
                            price:'11.50'
                        },
                        {
                            name:'L',
                            price:'22.00'
                        },
    
                        ]
                    },
                    {
                        name:'寶寳盘',
                        english:'Pu Pu Platter(For Two)',
                        spicy:false,
                        multi:false, 
                        price:'22.50'
                    },
                ]
            },
            {name:'Soups',
                with:'w.Crispy Noodles',
                data:[
                    {
                        name:'云吞汤',
                        english:'Wonton Soup',
                        spicy:false,
                        multi:true, 
                        price:[
                            {
                            name:'S',
                            price:'4.50'
                        },
                        {
                            name:'L',
                            price:'8.00'
                        },
    
                        ]
                    },
                    {
                        name:'蛋花汤',
                        english:'Egg Drop Soup',
                        spicy:false,
                        multi:true, 
                        price:[
                            {
                            name:'S',
                            price:'4.50'
                        },
                        {
                            name:'L',
                            price:'8.00'
                        },
    
                        ]
                    },
                    {
                        name:'云吞蛋花汤',
                        english:'Wonton Egg Drop Mixed Soup',
                        spicy:false,
                        multi:true, 
                        price:[
                            {
                            name:'S',
                            price:'4.50'
                        },
                        {
                            name:'L',
                            price:'8.00'
                        },
    
                        ]
                    },
                    {
                        name:'酸辣汤',
                        english:'Hot & Sour Soup',
                        spicy:true,
                        multi:true, 
                        price:[
                            {
                            name:'S',
                            price:'4.50'
                        },
                        {
                            name:'L',
                            price:'8.00'
                        },
    
                        ]
                    },
                    {
                        name:'鸡面汤',
                        english:'Chicken Noodle Soup',
                        spicy:false,
                        multi:true, 
                        price:[
                            {
                            name:'S',
                            price:'4.50'
                        },
                        {
                            name:'L',
                            price:'8.00'
                        },
    
                        ]
                    },
                    {
                        name:'鸡饭汤',
                        english:'Chicken Rice Soup',
                        spicy:false,
                        multi:true, 
                        price:[
                            {
                            name:'S',
                            price:'4.50'
                        },
                        {
                            name:'L',
                            price:'8.00'
                        },
    
                        ]
                    },
                    {
                        name:'豆腐菜汤',
                        english:'Bean Curd Vegetable Soup',
                        spicy:false,
                        multi:true, 
                        price:[
                            {
                            name:'S',
                            price:'4.50'
                        },
                        {
                            name:'L',
                            price:'8.00'
                        },
    
                        ]
                    },
                    {
                        name:'窝云吞汤',
                        english:'Wor Wonton Soup',
                        spicy:false,
                        multi:true, 
                        price:[
                            {
                            name:'S',
                            price:'6.25'
                        },
                        {
                            name:'L',
                            price:'11.00'
                        },
    
                        ]
                    },
                    {
                        name:'本楼汤',
                        english:'House Special Soup',
                        spicy:false,
                        multi:false, 
                        price:'10.50'
                    },
                    {
                        name:'鸡或肉汤面',
                        english:'Chicken or Pork Yat Gaw Mein',
                        spicy:false,
                        multi:false, 
                        price:'8.00'
                    },
                    {
                        name:'海鲜汤',
                        english:'Seafood Soup',
                        spicy:false,
                        multi:false, 
                        price:'13.00'
                    },


                ]
            },
            {
                name:'Fried Rice',
                with:'',
                data:[
                    {
                        name:'叉烧炒饭',
                        english:'Roast Pork Fried Rice',
                        spicy:false,
                        multi:true, 
                        price:[
                            {
                            name:'S',
                            price:'8.75'
                        },
                        {
                            name:'L',
                            price:'13.25'
                        },
    
                        ]
                    },
                    {
                        name:'鸡炒饭',
                        english:'Chicken Fried Rice',
                        spicy:false,
                        multi:true, 
                        price:[
                            {
                            name:'S',
                            price:'8.75'
                        },
                        {
                            name:'L',
                            price:'13.25'
                        },
    
                        ]
                    },
                    {
                        name:'虾或牛炒饭',
                        english:'Shrimp or Beef Fried Rice',
                        spicy:false,
                        multi:true, 
                        price:[
                            {
                            name:'S',
                            price:'9.25'
                        },
                        {
                            name:'L',
                            price:'14.50'
                        },
    
                        ]
                    },
                    {
                        name:'素菜炒饭',
                        english:'Vegetable Fried Rice',
                        spicy:false,
                        multi:true, 
                        price:[
                            {
                            name:'S',
                            price:'6.50'
                        },
                        {
                            name:'L',
                            price:'11.00'
                        },
    
                        ]
                    },
                    {
                        name:'本楼炒饭',
                        english:'House Special Fried Rice',
                        spicy:false,
                        multi:true, 
                        price:[
                            {
                            name:'S',
                            price:'9.50'
                        },
                        {
                            name:'L',
                            price:'15.00'
                        },
    
                        ]
                    },
                    {
                        name:'扬州炒饭',
                        english:'Young Chow Fried Rice',
                        spicy:false,
                        multi:true, 
                        price:[
                            {
                            name:'S',
                            price:'9.50'
                        },
                        {
                            name:'L',
                            price:'15.00'
                        },
    
                        ]
                    },
                ]
            },
            {
                name:'Lo Mein',
                with:'Soft Noodles, Chinese Style',
                data:[
                    {
                        name:'叉烧或鸡捞面',
                        english:'Roast Pork or Chicken Lo Mein',
                        spicy:false,
                        multi:true, 
                        price:[
                            {
                            name:'S',
                            price:'9.75'
                        },
                        {
                            name:'L',
                            price:'14.00'
                        },
    
                        ]
                    },
                    {
                        name:'虾或牛捞面',
                        english:'Shrimp or Beef Lo Mein',
                        spicy:false,
                        multi:true, 
                        price:[
                            {
                            name:'S',
                            price:'10.00'
                        },
                        {
                            name:'L',
                            price:'14.00'
                        },
    
                        ]
                    },
                    {
                        name:'素材捞面',
                        english:'Vegetable Lo Mein',
                        spicy:false,
                        multi:true, 
                        price:[
                            {
                            name:'S',
                            price:'8.50'
                        },
                        {
                            name:'L',
                            price:'12.50'
                        },
    
                        ]
                    },
                  
                    {
                        name:'本楼捞面',
                        english:'House Special Lo Mein',
                        spicy:false,
                        multi:true, 
                        price:[
                            {
                            name:'S',
                            price:'10.50'
                        },
                        {
                            name:'L',
                            price:'15.00'
                        },
    
                        ]
                    },
                    {
                        name:'龙虾捞面',
                        english:'Lobster Meat Lo Mein',
                        spicy:false,
                        multi:true, 
                        price:[
                            {
                            name:'S',
                            price:'14.00'
                        },
                        {
                            name:'L',
                            price:'22.00'
                        },
    
                        ]
                    },
                    {
                        name:'净捞面',
                        english:'Plain Lo Mein',
                        spicy:false,
                        multi:true, 
                        price:[
                            {
                            name:'S',
                            price:'6.75'
                        },
                        {
                            name:'L',
                            price:'9.50'
                        },
    
                        ]
                    },
                ]
            },
            {
                name:'Chow Mein',
                with:'w. Crispy Noodles & White or Brown Rice',
                data:[
                    {
                        name:'叉烧炒面',
                        english:'Roast Pork Chow Mein',
                        spicy:false,
                        multi:true, 
                        price:[
                            {
                            name:'S',
                            price:'8.50'
                        },
                        {
                            name:'L',
                            price:'13.25'
                        },
    
                        ]
                    },
                    {
                        name:'鸡炒面',
                        english:'Chicken Chow Mein',
                        spicy:false,
                        multi:true, 
                        price:[
                            {
                            name:'S',
                            price:'8.50'
                        },
                        {
                            name:'L',
                            price:'13.25'
                        },
    
                        ]
                    },
                    {
                        name:'虾或牛炒面',
                        english:'Shrimp or Beef Chow Mein',
                        spicy:false,
                        multi:true, 
                        price:[
                            {
                            name:'S',
                            price:'9.00'
                        },
                        {
                            name:'L',
                            price:'14.50'
                        },
    
                        ]
                    },
                    {
                        name:'素菜炒面',
                        english:'Vegetable Chow Mein',
                        spicy:false,
                        multi:true, 
                        price:[
                            {
                            name:'S',
                            price:'8.00'
                        },
                        {
                            name:'L',
                            price:'12.25'
                        },
    
                        ]
                    },
                    {
                        name:'本楼炒面',
                        english:'House Special Chow Mein',
                        spicy:false,
                        multi:true, 
                        price:[
                            {
                            name:'S',
                            price:'9.50'
                        },
                        {
                            name:'L',
                            price:'15.00'
                        },
    
                        ]
                    },
                 
                 
                ]
            },
            {
                name:'Beef',
                with:'w. White Rice or Brown Rice',
                data:[
                    {
                        name:'芥兰牛',
                        english:'Beef w. Broccoli',
                        spicy:false,
                        multi:true, 
                        price:[
                            {
                            name:'S',
                            price:'11.50'
                        },
                        {
                            name:'L',
                            price:'19.50'
                        },
    
                        ]
                    },
                    {
                        name:'青椒牛',
                        english:'Pepper Steak w. Onion',
                        spicy:false,
                        multi:true, 
                        price:[
                            {
                            name:'S',
                            price:'11.50'
                        },
                        {
                            name:'L',
                            price:'19.50'
                        },
    
                        ]
                    },
                    {
                        name:'豆豉牛',
                        english:'Beef w. Black Bean Sauce',
                        spicy:false,
                        multi:true, 
                        price:[
                            {
                            name:'S',
                            price:'11.50'
                        },
                        {
                            name:'L',
                            price:'19.50'
                        },
    
                        ]
                    },
                    {
                        name:'白菜牛',
                        english:'Beef w. Chinese Vegetable',
                        spicy:false,
                        multi:true, 
                        price:[
                            {
                            name:'S',
                            price:'11.50'
                        },
                        {
                            name:'L',
                            price:'19.50'
                        },
    
                        ]
                    },
                    {
                        name:'雪豆牛',
                        english:'Beef w. Snow Peas',
                        spicy:false,
                        multi:true, 
                        price:[
                            {
                            name:'S',
                            price:'11.50'
                        },
                        {
                            name:'L',
                            price:'19.50'
                        },
    
                        ]
                    },
                    {
                        name:'耗油牛',
                        english:'Beef w. Oyster Sauce',
                        spicy:false,
                        multi:true, 
                        price:[
                            {
                            name:'S',
                            price:'11.50'
                        },
                        {
                            name:'L',
                            price:'19.50'
                        },
    
                        ]
                    },
                    {
                        name:'蘑菇牛',
                        english:'Beef w. Mushroom',
                        spicy:false,
                        multi:true, 
                        price:[
                            {
                            name:'S',
                            price:'11.50'
                        },
                        {
                            name:'L',
                            price:'19.50'
                        },
    
                        ]
                    },
                    {
                        name:'什菜牛',
                        english:'Beef w. Mixed Vegetables',
                        spicy:false,
                        multi:true, 
                        price:[
                            {
                            name:'S',
                            price:'11.50'
                        },
                        {
                            name:'L',
                            price:'19.50'
                        },
    
                        ]
                    },
                    {
                        name:'茄椒牛',
                        english:'Beef w. Pepper Tomato',
                        spicy:false,
                        multi:true, 
                        price:[
                            {
                            name:'S',
                            price:'11.50'
                        },
                        {
                            name:'L',
                            price:'19.50'
                        },
    
                        ]
                    },
                    {
                        name:'炒两样',
                        english:'Surf & Turf(Beef & Shrimp)',
                        spicy:false,
                        multi:true, 
                        price:[
                            {
                            name:'S',
                            price:'11.50'
                        },
                        {
                            name:'L',
                            price:'19.50'
                        },
    
                        ]
                    },
                    {
                        name:'四季豆牛',
                        english:'Beef w. String Bean',
                        spicy:false,
                        multi:true, 
                        price:[
                            {
                            name:'S',
                            price:'11.50'
                        },
                        {
                            name:'L',
                            price:'19.50'
                        },
    
                        ]
                    },
                    {
                        name:'鱼香牛',
                        english:'Beef w. Garlic sauce',
                        spicy:true,
                        multi:true, 
                        price:[
                            {
                            name:'S',
                            price:'11.50'
                        },
                        {
                            name:'L',
                            price:'19.50'
                        },
    
                        ]
                    },
                    {
                        name:'鱼香芥兰牛',
                        english:'Beef w. Broccoli in Garlic Sauce',
                        spicy:true,
                        multi:true, 
                        price:[
                            {
                            name:'S',
                            price:'11.50'
                        },
                        {
                            name:'L',
                            price:'19.50'
                        },
    
                        ]
                    },
                    {
                        name:'湖南牛',
                        english:'Hunan Style Beef',
                        spicy:true,
                        multi:true, 
                        price:[
                            {
                            name:'S',
                            price:'11.50'
                        },
                        {
                            name:'L',
                            price:'19.50'
                        },
    
                        ]
                    },
                    {
                        name:'宫保牛',
                        english:'Kong Po Beef',
                        spicy:true,
                        multi:true, 
                        price:[
                            {
                            name:'S',
                            price:'11.50'
                        },
                        {
                            name:'L',
                            price:'19.50'
                        },
    
                        ]
                    },
                    {
                        name:'咖喱牛',
                        english:'Curry Beef',
                        spicy:true,
                        multi:true, 
                        price:[
                            {
                            name:'S',
                            price:'11.50'
                        },
                        {
                            name:'L',
                            price:'19.50'
                        },
    
                        ]
                    },
                    {
                        name:'四川牛肉丝',
                        english:'Shredded Beef Szechuan Style',
                        spicy:true,
                        multi:true, 
                        price:[
                            {
                            name:'S',
                            price:'11.50'
                        },
                        {
                            name:'L',
                            price:'19.50'
                        },
    
                        ]
                    },
                    {
                        name:'湖南茄子牛',
                        english:'Beef w. Eggplant, Hunan Style',
                        spicy:true,
                        multi:false, 
                        price:'19.50'
                    },
                  
                 
                ]
            },
            {
                name:'Vegetables',
                with:'w. White Rice or Brown Rice',
                data:[
                    {
                        name:'炒芥兰',
                        english:'Sauteed Broccoli',
                        spicy:false,
                        multi:true, 
                        price:[
                            {
                            name:'S',
                            price:'9.00'
                        },
                        {
                            name:'L',
                            price:'13.00'
                        },
    
                        ]
                    },
                    {
                        name:'什菜',
                        english:'Mixed Vegetable',
                        spicy:false,
                        multi:true, 
                        price:[
                            {
                            name:'S',
                            price:'9.00'
                        },
                        {
                            name:'L',
                            price:'13.00'
                        },
    
                        ]
                    },
                    {
                        name:'炒四季豆',
                        english:'Sauteed String Bean',
                        spicy:false,
                        multi:true, 
                        price:[
                            {
                            name:'S',
                            price:'10.00'
                        },
                        {
                            name:'L',
                            price:'14.00'
                        },
    
                        ]
                    },
                    {
                        name:'鱼香芥兰',
                        english:'Broccoli w. Garlic Sauce',
                        spicy:true,
                        multi:true, 
                        price:[
                            {
                            name:'S',
                            price:'9.00'
                        },
                        {
                            name:'L',
                            price:'13.00'
                        },
    
                        ]
                    },
                    {
                        name:'鱼香茄子',
                        english:'Eggplant w. Garlic Sauce',
                        spicy:true,
                        multi:false, 
                        price:'14.50'
                    },
                    {
                        name:'麻婆豆腐',
                        english:'Ma Po Tufu',
                        spicy:true,
                        multi:false, 
                        price:'13.50'
                    },
                    {
                        name:'鱼香豆腐',
                        english:'Bean Crud w. Garlic Sauce',
                        spicy:true,
                        multi:false, 
                        price:'13.50'
                    },
                    {
                        name:'木须菜',
                        english:'Moo Shu Vegetable(w. 10 Pancakes)',
                        spicy:false,
                        multi:false, 
                        price:'15.00'
                    },
                  
                 
                ]
            },
            {
                name:'Chow Fun or Mai Fun',
                with:'Flat Noodles/Thin Rice Noodles',
                data:[
                    {
                        name:'炒鸡粉或米粉',
                        english:'Chicken Chow Fun or Mai Fun',
                        spicy:false,
                        multi:true, 
                        price:[
                            {
                            name:'S',
                            price:'10.00'
                        },
                        {
                            name:'L',
                            price:'14.00'
                        },
    
                        ]
                    },
                    {
                        name:'肉炒粉或米粉',
                        english:'Pork Chow Fun or Mai Fun',
                        spicy:false,
                        multi:true, 
                        price:[
                            {
                            name:'S',
                            price:'10.00'
                        },
                        {
                            name:'L',
                            price:'14.00'
                        },
    
                        ]
                    },
                    {
                        name:'牛炒粉或米粉',
                        english:'Beef Chow Fun or Mai Fun',
                        spicy:false,
                        multi:true, 
                        price:[
                            {
                            name:'S',
                            price:'10.75'
                        },
                        {
                            name:'L',
                            price:'14.75'
                        },
    
                        ]
                    },
                    {
                        name:'虾炒粉或米粉',
                        english:'Shrimp Chow Fun or Mai Fun',
                        spicy:false,
                        multi:true, 
                        price:[
                            {
                            name:'S',
                            price:'10.75'
                        },
                        {
                            name:'L',
                            price:'14.75'
                        },
    
                        ]
                    },
                    {
                        name:'素菜炒粉或米粉',
                        english:'Vegetable Chow Fun or Mai Fun',
                        spicy:false,
                        multi:true, 
                        price:[
                            {
                            name:'S',
                            price:'9.00'
                        },
                        {
                            name:'L',
                            price:'12.50'
                        },
    
                        ]
                    },
                    {
                        name:'星洲炒米粉',
                        english:'Singapore Chow Mai Fun',
                        spicy:true,
                        multi:false, 
                        price:'14.75'
                    },
                    {
                        name:'本楼米粉',
                        english:'House Special Mai Fun',
                        spicy:false,
                        multi:false, 
                        price:'15.00'
                    },
                ]
            },
            {
                name:'Egg Foo Young',
                with:'w. White or Brown Rice',
                data:[
                    {
                        name:'叉烧蓉蛋',
                        english:'Roast Pork Egg Foo Young',
                        spicy:false,
                        multi:true, 
                        price:[
                            {
                            name:'S',
                            price:'9.50'
                        },
                        {
                            name:'L',
                            price:'13.50'
                        },
    
                        ]
                    },
                    {
                        name:'鸡蓉蛋',
                        english:'Chicken Egg Foo Young',
                        spicy:false,
                        multi:true, 
                        price:[
                            {
                            name:'S',
                            price:'9.50'
                        },
                        {
                            name:'L',
                            price:'13.50'
                        },
    
                        ]
                    },
                    {
                        name:'素材蓉蛋',
                        english:'Vegetable Egg Foo Young',
                        spicy:false,
                        multi:true, 
                        price:[
                            {
                            name:'S',
                            price:'8.75'
                        },
                        {
                            name:'L',
                            price:'12.50'
                        },
    
                        ]
                    },
                    {
                        name:'牛蓉蛋',
                        english:'Beef Egg Foo Young',
                        spicy:false,
                        multi:true, 
                        price:[
                            {
                            name:'S',
                            price:'10.00'
                        },
                        {
                            name:'L',
                            price:'14.50'
                        },
    
                        ]
                    },
                    {
                        name:'虾蓉蛋',
                        english:'Shrimp Egg Foo Young',
                        spicy:false,
                        multi:true, 
                        price:[
                            {
                            name:'S',
                            price:'10.00'
                        },
                        {
                            name:'L',
                            price:'14.50'
                        },
    
                        ]
                    },
                  
                ]
            },
            {
                name:'Sweet & Sour',
                with:'w. White or Brown Rice',
                data:[
                    {
                        name:'甜酸肉',
                        english:'Sweet & Sour Pork',
                        spicy:false,
                        multi:true, 
                        price:[
                            {
                            name:'S',
                            price:'9.50'
                        },
                        {
                            name:'L',
                            price:'14.75'
                        },
    
                        ]
                    },
                    {
                        name:'甜酸鸡',
                        english:'Sweet & Sour Chicken',
                        spicy:false,
                        multi:true, 
                        price:[
                            {
                            name:'S',
                            price:'9.50'
                        },
                        {
                            name:'L',
                            price:'14.75'
                        },
    
                        ]
                    },
                    {
                        name:'甜酸虾',
                        english:'Sweet & Sour Shrimp',
                        spicy:false,
                        multi:true, 
                        price:[
                            {
                            name:'S',
                            price:'9.50'
                        },
                        {
                            name:'L',
                            price:'14.75'
                        },
    
                        ]
                    },
                    {
                        name:'本楼甜酸',
                        english:'Sweet & Sour Delight',
                        spicy:false,
                        multi:true, 
                        price:[
                            {
                            name:'S',
                            price:'10.00'
                        },
                        {
                            name:'L',
                            price:'15.50'
                        },
    
                        ]
                    },
                    
                   
                  
                  
                  
                ]
            },
            {
                name:'Chicken',
                with:'w. White or Brown Rice',
                data:[
                   
                    {
                        name:'芥兰鸡',
                        english:'Chicken w. Broccoli',
                        spicy:false,
                        multi:true, 
                        price:[
                            {
                            name:'S',
                            price:'11.00'
                        },
                        {
                            name:'L',
                            price:'18.75'
                        },
    
                        ]
                    },
                    {
                        name:'什菜鸡',
                        english:'Chicken w. Mixed Vegetable',
                        spicy:false,
                        multi:true, 
                        price:[
                            {
                            name:'S',
                            price:'11.00'
                        },
                        {
                            name:'L',
                            price:'18.75'
                        },
    
                        ]
                    },
                    {
                        name:'四季豆鸡',
                        english:'Chicken w. Spring Bean',
                        spicy:false,
                        multi:true, 
                        price:[
                            {
                            name:'S',
                            price:'11.00'
                        },
                        {
                            name:'L',
                            price:'18.75'
                        },
    
                        ]
                    },
                    {
                        name:'雪豆鸡',
                        english:'Chicken w. Snow Peas',
                        spicy:false,
                        multi:true, 
                        price:[
                            {
                            name:'S',
                            price:'11.00'
                        },
                        {
                            name:'L',
                            price:'18.75'
                        },
    
                        ]
                    },
                    {
                        name:'腰果鸡',
                        english:'Chicken w. Cashew Nuts',
                        spicy:false,
                        multi:true, 
                        price:[
                            {
                            name:'S',
                            price:'11.00'
                        },
                        {
                            name:'L',
                            price:'18.75'
                        },
    
                        ]
                    },
                    {
                        name:'蘑菇鸡',
                        english:'Chicken w. Mushroom',
                        spicy:false,
                        multi:true, 
                        price:[
                            {
                            name:'S',
                            price:'11.00'
                        },
                        {
                            name:'L',
                            price:'18.75'
                        },
    
                        ]
                    },
                    {
                        name:'蘑菇鸡片',
                        english:'Moo Goo Gai Pan',
                        spicy:false,
                        multi:true, 
                        price:[
                            {
                            name:'S',
                            price:'11.00'
                        },
                        {
                            name:'L',
                            price:'18.75'
                        },
    
                        ]
                    },
                    {
                        name:'白菜鸡',
                        english:'Chicken w. Chinese Vegetable',
                        spicy:false,
                        multi:true, 
                        price:[
                            {
                            name:'S',
                            price:'11.00'
                        },
                        {
                            name:'L',
                            price:'18.75'
                        },
    
                        ]
                    },
                    {
                        name:'湖南鸡',
                        english:'Hunan Chicken',
                        spicy:true,
                        multi:true, 
                        price:[
                            {
                            name:'S',
                            price:'11.00'
                        },
                        {
                            name:'L',
                            price:'18.75'
                        },
    
                        ]
                    },
                    {
                        name:'咖喱鸡',
                        english:'Curry Chicken',
                        spicy:true,
                        multi:true, 
                        price:[
                            {
                            name:'S',
                            price:'11.00'
                        },
                        {
                            name:'L',
                            price:'18.75'
                        },
    
                        ]
                    },
                    {
                        name:'鱼香鸡',
                        english:'Chicken Garlic Sauce',
                        spicy:true,
                        multi:true, 
                        price:[
                            {
                            name:'S',
                            price:'11.00'
                        },
                        {
                            name:'L',
                            price:'18.75'
                        },
    
                        ]
                    },
                    {
                        name:'宫保鸡',
                        english:'Kung Po Chicken',
                        spicy:true,
                        multi:true, 
                        price:[
                            {
                            name:'S',
                            price:'11.00'
                        },
                        {
                            name:'L',
                            price:'18.75'
                        },
    
                        ]
                    },
                    {
                        name:'四川鸡丝',
                        english:'Shredded Chicken Szechuan Style',
                        spicy:true,
                        multi:true, 
                        price:[
                            {
                            name:'S',
                            price:'11.00'
                        },
                        {
                            name:'L',
                            price:'18.75'
                        },
    
                        ]
                    },
                    {
                        name:'鱼香芥兰鸡',
                        english:'Chicken w. Broccoli in Garlic Sauce',
                        spicy:true,
                        multi:true, 
                        price:[
                            {
                            name:'S',
                            price:'11.00'
                        },
                        {
                            name:'L',
                            price:'18.75'
                        },
    
                        ]
                    },
                    {
                        name:'鱼香茄子鸡',
                        english:'Chicken w. Eggplant in Garlic Sauce',
                        spicy:true,
                        multi:true, 
                        price:[
                            {
                            name:'S',
                            price:'11.00'
                        },
                        {
                            name:'L',
                            price:'18.75'
                        },
    
                        ]
                    },
                    {
                        name:'柠檬鸡',
                        english:'Lemon Chicken',
                        spicy:false,
                        multi:true, 
                        price:'18.75'
                    },
                    {
                        name:'菠萝鸡',
                        english:'Pineapple Chicken',
                        spicy:false,
                        multi:true, 
                        price:'18.75'
                    },
                    {
                        name:'无骨鸡',
                        english:'Boneless Chicken',
                        spicy:false,
                        multi:true, 
                        price:'18.75'
                    },
                    {
                        name:'木须鸡',
                        english:'Moo Shu Chicken(10 Pancake)',
                        spicy:false,
                        multi:true, 
                        price:'19.75'
                    },
                    
                    
                   
                  
                  
                  
                ]
            },
            {
                name:'Seafood',
                with:'w. White or Brown Rice',
                data:[
                    {
                        name:'芥兰虾',
                        english:'Shrimp w. Broccoli',
                        spicy:false,
                        multi:true, 
                        price:[
                            {
                            name:'S',
                            price:'11.50'
                        },
                        {
                            name:'L',
                            price:'19.50'
                        },
    
                        ]
                    },
                    {
                        name:'什菜虾',
                        english:'Shrimp w. Mixed Vegetable',
                        spicy:false,
                        multi:true, 
                        price:[
                            {
                            name:'S',
                            price:'11.50'
                        },
                        {
                            name:'L',
                            price:'19.50'
                        },
    
                        ]
                    },
                    {
                        name:'豆豉虾',
                        english:'Shrimp w. Black Bean Sauce',
                        spicy:false,
                        multi:true, 
                        price:[
                            {
                            name:'S',
                            price:'11.50'
                        },
                        {
                            name:'L',
                            price:'19.50'
                        },
    
                        ]
                    },
                    {
                        name:'蘑菇虾',
                        english:'Shrimp w. Mushroom',
                        spicy:false,
                        multi:true, 
                        price:[
                            {
                            name:'S',
                            price:'11.50'
                        },
                        {
                            name:'L',
                            price:'19.50'
                        },
    
                        ]
                    },
                    {
                        name:'白菜虾',
                        english:'Shrimp w. Chinese Vegetable',
                        spicy:false,
                        multi:true, 
                        price:[
                            {
                            name:'S',
                            price:'11.50'
                        },
                        {
                            name:'L',
                            price:'19.50'
                        },
    
                        ]
                    },
                    {
                        name:'雪豆虾',
                        english:'Shrimp w. Snow Peas',
                        spicy:false,
                        multi:true, 
                        price:[
                            {
                            name:'S',
                            price:'11.50'
                        },
                        {
                            name:'L',
                            price:'19.50'
                        },
    
                        ]
                    },
                    {
                        name:'腰果虾仁',
                        english:'Baby Shrimp w. Cashew Nuts',
                        spicy:false,
                        multi:true, 
                        price:[
                            {
                            name:'S',
                            price:'11.50'
                        },
                        {
                            name:'L',
                            price:'19.50'
                        },
    
                        ]
                    },
                    {
                        name:'茄椒虾',
                        english:'Shrimp w. Pepper Tomato',
                        spicy:false,
                        multi:true, 
                        price:[
                            {
                            name:'S',
                            price:'11.50'
                        },
                        {
                            name:'L',
                            price:'19.50'
                        },
    
                        ]
                    },
                    {
                        name:'虾龙糊',
                        english:'Shrimp w. Lobster Sauce',
                        spicy:false,
                        multi:true, 
                        price:[
                            {
                            name:'S',
                            price:'11.50'
                        },
                        {
                            name:'L',
                            price:'19.50'
                        },
    
                        ]
                    },
                    {
                        name:'四季豆虾',
                        english:'Shrimp w. String Bean',
                        spicy:false,
                        multi:true, 
                        price:[
                            {
                            name:'S',
                            price:'11.50'
                        },
                        {
                            name:'L',
                            price:'19.50'
                        },
    
                        ]
                    },
                    {
                        name:'芽菜虾',
                        english:'Shrimp w. Bean Sprouts',
                        spicy:false,
                        multi:true, 
                        price:[
                            {
                            name:'S',
                            price:'11.50'
                        },
                        {
                            name:'L',
                            price:'19.50'
                        },
    
                        ]
                    },
                    {
                        name:'爆双丁',
                        english:'Sauteed Two Delicacies(Chicken & Shrimp)',
                        spicy:false,
                        multi:true, 
                        price:[
                            {
                            name:'S',
                            price:'11.50'
                        },
                        {
                            name:'L',
                            price:'19.50'
                        },
    
                        ]
                    },
                    {
                        name:'咖喱虾',
                        english:'Curry Shrimp',
                        spicy:true,
                        multi:true, 
                        price:[
                            {
                            name:'S',
                            price:'11.50'
                        },
                        {
                            name:'L',
                            price:'19.50'
                        },
    
                        ]
                    },
                    {
                        name:'鱼香虾',
                        english:'Curry Shrimp',
                        spicy:true,
                        multi:true, 
                        price:[
                            {
                            name:'S',
                            price:'11.50'
                        },
                        {
                            name:'L',
                            price:'19.50'
                        },
    
                        ]
                    },
                    {
                        name:'宫保虾',
                        english:'Kung Po Shrimp',
                        spicy:true,
                        multi:true, 
                        price:[
                            {
                            name:'S',
                            price:'11.50'
                        },
                        {
                            name:'L',
                            price:'19.50'
                        },
    
                        ]
                    },
                    {
                        name:'鱼香芥兰虾',
                        english:'Shrimp w. Broccolo in Garlic Sauce',
                        spicy:true,
                        multi:true, 
                        price:[
                            {
                            name:'S',
                            price:'11.50'
                        },
                        {
                            name:'L',
                            price:'19.50'
                        },
    
                        ]
                    },
                    {
                        name:'龙糊水',
                        english:'Lobster Sauce',
                        spicy:true,
                        multi:true, 
                        price:[
                            {
                            name:'S',
                            price:'5.00'
                        },
                        {
                            name:'L',
                            price:'9.00'
                        },
    
                        ]
                    },

                ]
            },
            {
                name:'Roast Pork',
                with:'w. White or Brown Rice',
                data:[
                    {
                        name:'芽菜肉',
                        english:'Pork w. Bean Sprouts',
                        spicy:false,
                        multi:true, 
                        price:[
                            {
                            name:'S',
                            price:'10.75'
                        },
                        {
                            name:'L',
                            price:'18.50'
                        },
    
                        ]
                    },
                    {
                        name:'芥兰叉烧',
                        english:'Roast Pork w. Broccoli',
                        spicy:false,
                        multi:true, 
                        price:[
                            {
                            name:'S',
                            price:'10.75'
                        },
                        {
                            name:'L',
                            price:'18.50'
                        },
    
                        ]
                    },
                    {
                        name:'白菜叉烧',
                        english:'Roast Pork w. Chinese Vegetable',
                        spicy:false,
                        multi:true, 
                        price:[
                            {
                            name:'S',
                            price:'10.75'
                        },
                        {
                            name:'L',
                            price:'18.50'
                        },
    
                        ]
                    },
                    {
                        name:'雪豆叉烧',
                        english:'Roast Pork w. Snow Peas',
                        spicy:false,
                        multi:true, 
                        price:[
                            {
                            name:'S',
                            price:'10.75'
                        },
                        {
                            name:'L',
                            price:'18.50'
                        },
    
                        ]
                    },
                    {
                        name:'蘑菇叉烧',
                        english:'Roast Pork w. Mushroom',
                        spicy:false,
                        multi:true, 
                        price:[
                            {
                            name:'S',
                            price:'10.75'
                        },
                        {
                            name:'L',
                            price:'18.50'
                        },
    
                        ]
                    },
                    {
                        name:'四季豆肉',
                        english:'Pork w. String Bean',
                        spicy:false,
                        multi:true, 
                        price:[
                            {
                            name:'S',
                            price:'10.75'
                        },
                        {
                            name:'L',
                            price:'18.50'
                        },
    
                        ]
                    },
                    {
                        name:'四季豆肉',
                        english:'Pork w. String Bean',
                        spicy:false,
                        multi:true, 
                        price:[
                            {
                            name:'S',
                            price:'10.75'
                        },
                        {
                            name:'L',
                            price:'18.50'
                        },
                        ]
                    },
                    {
                        name:'鱼香叉烧',
                        english:'Roast Pork w. Garlic Sauce',
                        spicy:true,
                        multi:true, 
                        price:[
                            {
                            name:'S',
                            price:'10.75'
                        },
                        {
                            name:'L',
                            price:'18.50'
                        },
                        ]
                    },
                    {
                        name:'四川肉丝',
                        english:'Shredded Pork Szechuan Style',
                        spicy:true,
                        multi:true, 
                        price:[
                            {
                            name:'S',
                            price:'10.75'
                        },
                        {
                            name:'L',
                            price:'18.50'
                        },
                        ]
                    },
                    {
                        name:'鱼香肉丝',
                        english:'Shredded Pork w. Garlic Sauce',
                        spicy:true,
                        multi:true, 
                        price:[
                            {
                            name:'S',
                            price:'10.75'
                        },
                        {
                            name:'L',
                            price:'18.50'
                        },
                        ]
                    },
                    {
                        name:'回锅肉',
                        english:'Double Sauteed Pork',
                        spicy:true,
                        multi:false, 
                        price:'18.50'
                    },
                    {
                        name:'木须肉',
                        english:'Moo Shu Pork(w. 10 Pancakes)',
                        spicy:false,
                        multi:false, 
                        price:'19.50'
                    },

                ]
            },
            {
                name:'Diet Health Food',
                with:'w. White or Brown Rice',
                data:[
                    {
                        name:'蒸芥兰',
                        english:'Steamed Broccoli',
                        spicy:false,
                        multi:true, 
                        price:[
                            {
                            name:'S',
                            price:'9.00'
                        },
                        {
                            name:'L',
                            price:'13.00'
                        },
    
                        ]
                    },
                    {
                        name:'蒸什菜',
                        english:'Steamed Mixed Vegetable',
                        spicy:false,
                        multi:true, 
                        price:[
                            {
                            name:'S',
                            price:'9.00'
                        },
                        {
                            name:'L',
                            price:'13.00'
                        },
    
                        ]
                    },
                    {
                        name:'蒸什菜鸡',
                        english:'Steamed Chicken w. Mixed Vegetable',
                        spicy:false,
                        multi:true, 
                        price:[
                            {
                            name:'S',
                            price:'11.00'
                        },
                        {
                            name:'L',
                            price:'18.75'
                        },
    
                        ]
                    },
                    {
                        name:'蒸芥兰鸡',
                        english:'Steamed Chicken w. Broccoli',
                        spicy:false,
                        multi:true, 
                        price:[
                            {
                            name:'S',
                            price:'11.00'
                        },
                        {
                            name:'L',
                            price:'18.75'
                        },
    
                        ]
                    },
                    {
                        name:'蒸芥兰虾',
                        english:'Steamed Shrimp w. Broccoli',
                        spicy:false,
                        multi:true, 
                        price:[
                            {
                            name:'S',
                            price:'11.50'
                        },
                        {
                            name:'L',
                            price:'19.50'
                        },
    
                        ]
                    },
                    {
                        name:'蒸什菜虾',
                        english:'Steamed Shrimp w. Mixed Vegetable',
                        spicy:false,
                        multi:true, 
                        price:[
                            {
                            name:'S',
                            price:'11.50'
                        },
                        {
                            name:'L',
                            price:'19.50'
                        },
    
                        ]
                    },
                    

                ]
            },
            {
                name:'Side',
                with:'',
                data:[
                    {
                        name:'白饭',
                        english:'White Rice',
                        spicy:false,
                        multi:true, 
                        price:[
                            {
                            name:'S',
                            price:'3.00'
                        },
                        {
                            name:'L',
                            price:'5.75'
                        },
    
                        ]
                    },
                    {
                        name:'黄饭',
                        english:'Brown Rice',
                        spicy:false,
                        multi:true, 
                        price:[
                            {
                            name:'S',
                            price:'3.00'
                        },
                        {
                            name:'L',
                            price:'5.75'
                        },
    
                        ]
                    },
                    {
                        name:'面干',
                        english:'Crispy Noodles',
                        spicy:false,
                        multi:false, 
                        price:'1.50'
                    },
                    {
                        name:'签语饼',
                        english:'Fortune Cookies(8)',
                        spicy:false,
                        multi:false, 
                        price:'1.50'
                    },
                    {
                        name:'汽水',
                        english:'Soda',
                        spicy:false,
                        multi:true, 
                        price:[
                            {
                            name:'Can',
                            price:'2.00'
                        },
                        {
                            name:'2 Liter',
                            price:'5.50'
                        },
    
                        ]
                    },
                    {
                        name:'冰茶',
                        english:'Homemade Ice Tea',
                        spicy:false,
                        multi:true, 
                        price:[
                            {
                            name:'S',
                            price:'2.00'
                        },
                        {
                            name:'L',
                            price:'3.75'
                        },
    
                        ]
                    },
                    {
                        name:'桃子水',
                        english:'Snapple',
                        spicy:false,
                        multi:false, 
                        price:'3.25'
                    },
                    {
                        name:'水',
                        english:'Water',
                        spicy:false,
                        multi:false, 
                        price:'2.75'
                    },
                ]
            },
            {
                name:'Special Dishes',
                with:'',
                data:[
                    {
                        name:'炸鸡翼',
                        english:'Fried Chicken Wings(4)',
                        spicy:false,
                        multi:true, 
                        price:[
                            {
                            name:'only Wings',
                            price:'10.50'
                        },
                        {
                            name:'w. Plantain ',
                            price:'12.75'
                        },
                        {
                            name:'w. French Fries ',
                            price:'12.75'
                        },
                        {
                            name:'w. Vegetable',
                            price:'12.75'
                        },
                        {
                            name:'w. Chicken Fried Rice',
                            price:'12.75'
                        },
                        {
                            name:'w. Pork Fried Rice',
                            price:'12.75'
                        },
                        {
                            name:'w. Shrimp Fried Rice',
                            price:'14.00'
                        },
                        {
                            name:'w. Beef Fried Rice',
                            price:'14.00'
                        },
                        {
                            name:'w. House Special Fried Rice',
                            price:'14.75'
                        },
    
                        ]
                    },
                    {
                        name:'炸半鸡',
                        english:'Fried Half Chicken',
                        spicy:false,
                        multi:true, 
                        price:[
                            {
                            name:'only Chicken',
                            price:'10.50'
                        },
                        {
                            name:'w. Plantain ',
                            price:'12.75'
                        },
                        {
                            name:'w. French Fries ',
                            price:'12.75'
                        },
                        {
                            name:'w. Vegetable',
                            price:'12.75'
                        },
                        {
                            name:'w. Chicken Fried Rice',
                            price:'12.75'
                        },
                        {
                            name:'w. Pork Fried Rice',
                            price:'12.75'
                        },
                        {
                            name:'w. Shrimp Fried Rice',
                            price:'14.00'
                        },
                        {
                            name:'w. Beef Fried Rice',
                            price:'14.00'
                        },
                        {
                            name:'w. House Special Fried Rice',
                            price:'14.75'
                        },
    
                        ]
                    },
                    {
                        name:'炸鸡块',
                        english:'Fried Chicken Nugget(12)',
                        spicy:false,
                        multi:true, 
                        price:[
                            {
                            name:'only Nugget',
                            price:'8.75'
                        },
                        {
                            name:'w. Plantain ',
                            price:'10.75'
                        },
                        {
                            name:'w. French Fries ',
                            price:'10.75'
                        },
                        {
                            name:'w. Vegetable',
                            price:'10.75'
                        },
                        {
                            name:'w. Chicken Fried Rice',
                            price:'10.75'
                        },
                        {
                            name:'w. Pork Fried Rice',
                            price:'10.75'
                        },
                        {
                            name:'w. Shrimp Fried Rice',
                            price:'12.00'
                        },
                        {
                            name:'w. Beef Fried Rice',
                            price:'12.00'
                        },
                        {
                            name:'w. House Special Fried Rice',
                            price:'13.00'
                        },
    
                        ]
                    },
                    {
                        name:'炸虾仁',
                        english:'Fried Baby Shrimp(15)',
                        spicy:false,
                        multi:true, 
                        price:[
                            {
                            name:'only Shrimp',
                            price:'10.50'
                        },
                        {
                            name:'w. Plantain ',
                            price:'12.75'
                        },
                        {
                            name:'w. French Fries ',
                            price:'12.75'
                        },
                        {
                            name:'w. Vegetable',
                            price:'12.75'
                        },
                        {
                            name:'w. Chicken Fried Rice',
                            price:'12.75'
                        },
                        {
                            name:'w. Pork Fried Rice',
                            price:'12.75'
                        },
                        {
                            name:'w. Shrimp Fried Rice',
                            price:'14.00'
                        },
                        {
                            name:'w. Beef Fried Rice',
                            price:'14.00'
                        },
                        {
                            name:'w. House Special Fried Rice',
                            price:'14.75'
                        },
    
                        ]
                    },
                    {
                        name:'蜜汁烤鸡翅',
                        english:'Honey B-B-Q Sauce Chicken Wings(4)',
                        spicy:false,
                        multi:true, 
                        price:[
                            {
                            name:'only Wings',
                            price:'11.00'
                        },
                        {
                            name:'w. Plantain ',
                            price:'13.25'
                        },
                        {
                            name:'w. French Fries ',
                            price:'13.25'
                        },
                        {
                            name:'w. Vegetable',
                            price:'13.25'
                        },
                        {
                            name:'w. Chicken Fried Rice',
                            price:'13.25'
                        },
                        {
                            name:'w. Pork Fried Rice',
                            price:'13.25'
                        },
                        {
                            name:'w. Shrimp Fried Rice',
                            price:'14.50'
                        },
                        {
                            name:'w. Beef Fried Rice',
                            price:'14.50'
                        },
                        {
                            name:'w. House Special Fried Rice',
                            price:'15.00'
                        },
    
                        ]
                    },
                    {
                        name:'鱼香鸡翼',
                        english:'Chicken Wings w. Garlic Sauce(4)',
                        spicy:false,
                        multi:true, 
                        price:[
                            {
                            name:'only Wings',
                            price:'11.00'
                        },
                        {
                            name:'w. Plantain ',
                            price:'13.25'
                        },
                        {
                            name:'w. French Fries ',
                            price:'13.25'
                        },
                        {
                            name:'w. Vegetable',
                            price:'13.25'
                        },
                        {
                            name:'w. Chicken Fried Rice',
                            price:'13.25'
                        },
                        {
                            name:'w. Pork Fried Rice',
                            price:'13.25'
                        },
                        {
                            name:'w. Shrimp Fried Rice',
                            price:'14.50'
                        },
                        {
                            name:'w. Beef Fried Rice',
                            price:'14.50'
                        },
                        {
                            name:'w. House Special Fried Rice',
                            price:'15.00'
                        },
    
                        ]
                    },
                    {
                        name:'辣鸡翼',
                        english:'Buffalo Chicken Wings(4)',
                        spicy:true,
                        multi:true, 
                        price:[
                            {
                            name:'only Wings',
                            price:'11.00'
                        },
                        {
                            name:'w. Plantain ',
                            price:'13.25'
                        },
                        {
                            name:'w. French Fries ',
                            price:'13.25'
                        },
                        {
                            name:'w. Vegetable',
                            price:'13.25'
                        },
                        {
                            name:'w. Chicken Fried Rice',
                            price:'13.25'
                        },
                        {
                            name:'w. Pork Fried Rice',
                            price:'13.25'
                        },
                        {
                            name:'w. Shrimp Fried Rice',
                            price:'14.50'
                        },
                        {
                            name:'w. Beef Fried Rice',
                            price:'14.50'
                        },
                        {
                            name:'w. House Special Fried Rice',
                            price:'15.00'
                        },
    
                        ]
                    },
                    {
                        name:'炸鱼',
                        english:'Fried Fish',
                        spicy:false,
                        multi:true, 
                        price:[
                            {
                            name:'only Fish',
                            price:'10.50'
                        },
                        {
                            name:'w. Plantain ',
                            price:'12.75'
                        },
                        {
                            name:'w. French Fries ',
                            price:'12.75'
                        },
                        {
                            name:'w. Vegetable',
                            price:'12.75'
                        },
                        {
                            name:'w. Chicken Fried Rice',
                            price:'12.75'
                        },
                        {
                            name:'w. Pork Fried Rice',
                            price:'12.75'
                        },
                        {
                            name:'w. Shrimp Fried Rice',
                            price:'14.00'
                        },
                        {
                            name:'w. Beef Fried Rice',
                            price:'14.00'
                        },
                        {
                            name:'w. House Special Fried Rice',
                            price:'14.75'
                        },
    
                        ]
                    },
                    {
                        name:'排骨尾',
                        english:'Spare Ribs Tips',
                        spicy:false,
                        multi:true, 
                        price:[
                            {
                            name:'only Ribs',
                            price:'8.75'
                        },
                        {
                            name:'w. Plantain ',
                            price:'10.75'
                        },
                        {
                            name:'w. French Fries ',
                            price:'10.75'
                        },
                        {
                            name:'w. Vegetable',
                            price:'10.75'
                        },
                        {
                            name:'w. Chicken Fried Rice',
                            price:'10.75'
                        },
                        {
                            name:'w. Pork Fried Rice',
                            price:'10.75'
                        },
                        {
                            name:'w. Shrimp Fried Rice',
                            price:'12.00'
                        },
                        {
                            name:'w. Beef Fried Rice',
                            price:'12.00'
                        },
                        {
                            name:'w. House Special Fried Rice',
                            price:'13.00'
                        },
    
                        ]
                    },
                    {
                        name:'炸干贝',
                        english:'Fried Scallops(14)',
                        spicy:false,
                        multi:true, 
                        price:[
                            {
                            name:'only Scallops',
                            price:'8.75'
                        },
                        {
                            name:'w. Plantain ',
                            price:'10.75'
                        },
                        {
                            name:'w. French Fries ',
                            price:'10.75'
                        },
                        {
                            name:'w. Vegetable',
                            price:'10.75'
                        },
                        {
                            name:'w. Chicken Fried Rice',
                            price:'10.75'
                        },
                        {
                            name:'w. Pork Fried Rice',
                            price:'10.75'
                        },
                        {
                            name:'w. Shrimp Fried Rice',
                            price:'12.00'
                        },
                        {
                            name:'w. Beef Fried Rice',
                            price:'12.00'
                        },
                        {
                            name:'w. House Special Fried Rice',
                            price:'13.00'
                        },
    
                        ]
                    },
                    {
                        name:'香蕉',
                        english:'Plantain',
                        spicy:false,
                        multi:true, 
                        price:[
                            {
                            name:'S',
                            price:'5.75'
                        },
                        {
                            name:'L',
                            price:'9.50'
                        },
                       
    
                        ]
                    },
                    {
                        name:'炸薯条',
                        english:'French Fries',
                        spicy:false,
                        multi:true, 
                        price:[
                            {
                            name:'S',
                            price:'5.00'
                        },
                        {
                            name:'L',
                            price:'9.00'
                        },
                       
    
                        ]
                    },

                ]
            },

            {
                name:`Chef's Specials`,
                with:'w. White or Brown Rice', 
                data:[
                    {
                        name:'全家福',
                        english:'Happy Famliy',
                        spicy:false,
                        multi:false, 
                        price:'22.50'
                    },
                    {
                        name:'左宗三宝',
                        english:`General Tso's Triple`,
                        spicy:true,
                        multi:false, 
                        price:'22.00'
                    },
                    {
                        name:'干贝牛',
                        english:`Scallop and Beef`,
                        spicy:false,
                        multi:false, 
                        price:'23.00'
                    },
                    {
                        name:'海鲜大会',
                        english:`Seafood Delight`,
                        spicy:false,
                        multi:false, 
                        price:'23.00'
                    },
                    {
                        name:'左宗鸡',
                        english:`General Tso's Chicken`,
                        spicy:true,
                        multi:false, 
                        price:'18.50'
                    },
                    {
                        name:'陈皮牛',
                        english:`Orange Beef`,
                        spicy:true,
                        multi:false, 
                        price:'22.00'
                    },
                    {
                        name:'芝麻牛',
                        english:`Sesame Beef`,
                        spicy:true,
                        multi:false, 
                        price:'22.00'
                    },
                    {
                        name:'陈皮鸡',
                        english:`Chicken w. Orange Flavor`,
                        spicy:true,
                        multi:false, 
                        price:'18.50'
                    },
                    {
                        name:'鱼香双鲜',
                        english:`Perfect Match`,
                        spicy:true,
                        multi:false, 
                        price:'24.00'
                    },
                    {
                        name:'芝麻鸡',
                        english:`Sesame Chicken`,
                        spicy:true,
                        multi:false, 
                        price:'18.50'
                    },
                    {
                        name:'什锦云吞',
                        english:`Subgum Wonton`,
                        spicy:false,
                        multi:false, 
                        price:'22.75'
                    },
                    {
                        name:'芝麻虾',
                        english:`Sesame Shrimp`,
                        spicy:true,
                        multi:false, 
                        price:'22.75'
                    },
                    {
                        name:'湖南三样',
                        english:`Hunan Triple`,
                        spicy:true,
                        multi:false, 
                        price:'21.00'
                    },
                    {
                        name:'芝麻豆腐',
                        english:`Bean Curd Sesame Sauce`,
                        spicy:true,
                        multi:false, 
                        price:'18.00'
                    },
                    {
                        name:'兰花鸡丝',
                        english:`Mandarin Chicken`,
                        spicy:true,
                        multi:false, 
                        price:'18.00'
                    },
                    {
                        name:'脆皮明虾',
                        english:`Crispy Shrimp`,
                        spicy:false,
                        multi:false, 
                        price:'22.50'
                    },
                    {
                        name:'海鲜两面黄',
                        english:`Seafood Pan Fried Noodles`,
                        spicy:false,
                        multi:false, 
                        price:'23.00'
                    },
                ]
            },
            {
                name:'Special Combination Platters',
                with:'All w.Egg Roll & Pork Fried Rice or White Rice', 
                data:[
                    {
                        name:'鸡或虾炒面',
                        english:'Chicken or Shrimp Chow Mein',
                        spicy:false,
                        multi:false, 
                        price:'14.00'
                    },
                    {
                        name:'芥兰鸡',
                        english:'Chicken w. Broccoli',
                        spicy:false,
                        multi:false, 
                        price:'14.00'
                    },
                    {
                        name:'蘑菇鸡片',
                        english:'Moo Goo Gai Pan',
                        spicy:false,
                        multi:false, 
                        price:'14.00'
                    },
                    {
                        name:'豆豉鸡',
                        english:'Chicken w. Black Bean Sauce',
                        spicy:false,
                        multi:false, 
                        price:'14.00'
                    },
                    {
                        name:'白菜叉烧',
                        english:'Roast Pork w. Chinese Vegetable',
                        spicy:false,
                        multi:false, 
                        price:'14.00'
                    },
                    {
                        name:'芥兰牛',
                        english:'Beef w. Broccoli',
                        spicy:false,
                        multi:false, 
                        price:'14.00'
                    },
                    {
                        name:'青椒牛',
                        english:'Pepper Steak w. Onion',
                        spicy:false,
                        multi:false, 
                        price:'14.00'
                    },
                    {
                        name:'蘑菇牛',
                        english:'Beef w. Mushroom',
                        spicy:false,
                        multi:false, 
                        price:'14.00'
                    },
                    {
                        name:'什菜牛',
                        english:'Beef w. Mixed Vegetable',
                        spicy:false,
                        multi:false, 
                        price:'14.00'
                    },

                    {
                        name:'叉烧捞面',
                        english:'Roast Pork Lo Mein',
                        spicy:false,
                        multi:false, 
                        price:'14.00'
                    },
                    {
                        name:'甜酸肉或鸡',
                        english:'Sweet & Sour Pork or Chicken',
                        spicy:false,
                        multi:false, 
                        price:'14.00'
                    },
                    {
                        name:'叉烧蓉蛋',
                        english:'Roast Pork Egg Foo Young',
                        spicy:false,
                        multi:false, 
                        price:'14.00'
                    },
                    {
                        name:'芥兰虾',
                        english:'Shrimp w. Broccoli',
                        spicy:false,
                        multi:false, 
                        price:'14.00'
                    },
                    {
                        name:'虾龙糊',
                        english:'Shrimp w. Lobster Sauce',
                        spicy:false,
                        multi:false, 
                        price:'14.00'
                    },
                    {
                        name:'鱼香虾',
                        english:'Shrimp w. Garlic Sauce',
                        spicy:true,
                        multi:false, 
                        price:'14.00'
                    },
                    {
                        name:'鱼香鸡',
                        english:'Chicken w. Garlic Sauce',
                        spicy:true,
                        multi:false, 
                        price:'14.00'
                    },
                    {
                        name:'宫保鸡',
                        english:'Kung Pao Chicken',
                        spicy:true,
                        multi:false, 
                        price:'14.00'
                    },
                    {
                        name:'湖南牛',
                        english:'Hunan Style Beef',
                        spicy:true,
                        multi:false, 
                        price:'14.00'
                    },
                    {
                        name:'芝麻鸡',
                        english:'Sesame Chicken',
                        spicy:true,
                        multi:false, 
                        price:'14.00'
                    },
                    {
                        name:'左宗鸡',
                        english:`General Tso's Chicken`,
                        spicy:true,
                        multi:false, 
                        price:'14.00'
                    },
                    {
                        name:'烤排骨',
                        english:`Bar-B-Q spare Ribs`,
                        spicy:false,
                        multi:false, 
                        price:'15.50'
                    },
                    {
                        name:'无骨排',
                        english:`Boneless spare Ribs`,
                        spicy:false,
                        multi:false, 
                        price:'15.50'
                    },
                    {
                        name:'牛捞面',
                        english:`Beef Lo Mein`,
                        spicy:false,
                        multi:false, 
                        price:'14.00'
                    },
                    {
                        name:'芝麻牛',
                        english:`Sesame Beef`,
                        spicy:false,
                        multi:false, 
                        price:'15.50'
                    },
                ]
            }

        ]},
    ]
    
   
    },

  




]

const fetchRestaurant = ()=>{
    return data;
}


export default fetchRestaurant