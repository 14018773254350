import React,{useState,useEffect,useCallback}from 'react'
import axios from 'axios'
import {Link } from 'react-router-dom'
import Select from 'react-select'

import './index.scss'
import { add } from 'lodash'

export default function AuthAds() {

    const apiUrl ='https://metaserverapp.com'
    const ISODate = new Date();
    const EPOCH_CONVERT = 1000;

    const EPOCH_DAY = 86400;

    const epoch = Math.floor(ISODate.getTime() / EPOCH_CONVERT);

    const [news,setNews]=useState([])

    const POINT = [
        {value:1,label:'1'},
        {value:2,label:'2'},
        {value:3,label:'3'},
        {value:4,label:'4'},
        {value:5,label:'5'},
    ]
    const TIME = [
        {value:EPOCH_DAY*30,label:'30天'},
        {value:EPOCH_DAY*60,label:'60天'},
        {value:EPOCH_DAY*90,label:'90天'},

    ]
  
    const initial = {
        title:'',
        link:'',
        img:'',
        tel:'',
        expire:'',
        point:1,
    }

 
    const [isAdd,setIsAdd] = useState(false)
    const [isCreate,setIsCreate] = useState(false)
    const [target,setTarget]= useState(initial)

    useEffect(()=>{

        const fetchNews = async()=>{
            const result = await axios.get(`${apiUrl}/ads/all`)
            console.log(result.data)
            if(result.data.success){
           
                setNews(result.data.data)
      
             
           
      
            }
            else{
              alert('Server Error! No data response received!')
            }
        }
        fetchNews()

    },[])

 
    const handleName =(e)=>{
        let temp = {...target}
        temp.title = e.target.value
        setTarget(temp)
    }
    const handleImg =(e)=>{
        let temp = {...target}
        temp.img = e.target.value
        setTarget(temp)
    }
    const handleLink =(e)=>{
        let temp = {...target}
        temp.link = e.target.value
        setTarget(temp)
    }
    const handleTel = (e)=>{
        let temp = {...target}
        temp.tel= e.target.value
        setTarget(temp)
    }
    
    const handlePoint = (e)=>{
        let temp = {...target}
        temp.point = e.value
        setTarget(temp)
    }

    const handleExpire = (e)=>{
        let temp = {...target}
        temp.expire = epoch+e.value
        setTarget(temp)

    }

   

    const handleCreateAd = ()=>{
        setIsCreate(!isCreate)
    }

   

    const [addTimeTarget,setAddTimeTarget]=useState({initial})

    const handleIsAddTime = (i)=>{
        setIsAdd(true)
        setAddTimeTarget(news[i])
    }

    const handleCancelAddTime =()=>{
        setIsAdd(false)
        setAddTimeTarget(initial)

    }

    const handleAddTime = (e)=>{
        let temp = {...addTimeTarget}
        if(temp.expire>epoch){
            temp.expire = Number(temp.expire)+e.value
        setAddTimeTarget(temp)
        }
        else{
            temp.expire = epoch+e.value
            setAddTimeTarget(temp)

        }
    }

    const handleSubmitAdd = async()=>{
        const result = await axios.post(`${apiUrl}/ads/add`,{
            id:addTimeTarget._id,
            expire:addTimeTarget.expire,
        })
        console.log(result)
        if(result.data.success){
            alert(`广告:${addTimeTarget.title}成功添加时间`)
            window.location.reload()

        }
        else{
            alert(result.data.errors)

        }
    }

    const handleSubmit = async()=>{

        const result = await axios.post(`${apiUrl}/ads/create`,{
            title:target.title,
            link:target.link,
            img:target.img,
            tel:target.tel,
            expire:target.expire,
            point:target.point,
        })
        console.log(result)
        if(result.data.success){
            alert('创建成功')
            window.location.reload()

        }
        else{
            alert(result.data.errors)

        }

    }
   

console.log(addTimeTarget)    
   
  return (
    <div className='auth-post-wrap'>
        <h2>广告管理页面</h2>
        
        <div className='auth-post-btns'>
            <Link to='/auth'> <button >返回</button></Link> 
            <button onClick={()=>handleCreateAd()} > {isCreate ? '取消创建' :' 创建广告'}</button>
        </div>

        <div className='auth-posts-form'>
        <table>
            <thead>
                <tr>
                    <th>
                        广告名
                    </th>
                    <th>
                        图片
                    </th>
                   
                    <th>
                        链接
                    </th>
                    <th>
                        电话（可选）
                    </th>
                    
                    <th>
                        点击量
                    </th>
                    <th>
                        过期时间
                    </th>
                    <th>
                        优先级
                    </th>
                    <th>
                        创建日期
                    </th>
                    <th>
                       操作
                    </th>
                   
                </tr>
            </thead>
            <tbody>
                {
                    news.length>0&&news.map((v,i)=>(
                        <tr key={i}>
                            <td>{v.title}</td>
                            <td><img src={v.img} alt="" /></td>
                         
                            <td>{v.link}</td>
                            <td>{v.tel}</td>
                            <td>{v.views}</td>
                           {v.expire>epoch ?<td>  {Math.floor((v.expire-epoch)/EPOCH_DAY)}天</td> :
                           <td>已过期</td>
                           }
                            <td>{v.point}</td>
                            <td>{v.date}</td>

                           <td><button onClick={()=>handleIsAddTime(i)} >添加时间</button></td>
                        </tr>
                    )

                    )
                }
            </tbody>


        </table>


        </div>
    
     
     <div style={{top:isCreate? '12vh':'220vh',transition:'1s'}} className='auth-product-create'>

    <div className='news-wrap'>

        <div>
        <h4>广告名字</h4>
    <input type="text" value={target.title} onChange={(e)=>handleName(e)}/>
        </div>
        
        <div>
        <h4>广告图片</h4>
        <input type="text" value={target.img} onChange={(e)=>handleImg(e)}/>
        </div>

        <div>
        <h4>广告链接</h4>
        <input type="text" value={target.link} onChange={(e)=>handleLink(e)}/>
        </div>

        <div>
        <h4>广告电话（无链接时电话生效）</h4>
        <input type="text" value={target.tel} onChange={(e)=>handleTel(e)}/>
        </div>

        <div className="create-news-wrap">
        <label>广告优先级(1-5 分值越高展示越靠前)</label>
        <Select    
                  onChange={(e)=>handlePoint(e)}

         options={POINT}             
           menuPortalTarget={document.body}
        menuPosition="fixed"       maxMenuHeight='20vh'           
        />
    </div>

    <div className="create-news-wrap">
        <label>广告有效时间</label>
        <Select    
                          onChange={(e)=>handleExpire(e)}

         options={TIME}             
           menuPortalTarget={document.body}
        menuPosition="fixed"       maxMenuHeight='20vh'           
        />
    </div>

    </div>

    <button onClick={()=>handleSubmit()}>Submit</button>

     </div>

     <div style={{top:isAdd? '12vh':'220vh',transition:'1s'}} className='auth-product-create'>

    <div className='news-wrap'>

        <div>
        <h4>广告名字:{addTimeTarget.title}</h4>
        </div>
        
        

    <div className="create-news-wrap">
        <label>增加广告时长</label>
        <Select    
                          onChange={(e)=>handleAddTime(e)}

         options={TIME}             
           menuPortalTarget={document.body}
        menuPosition="fixed"       maxMenuHeight='20vh'           
        />
    </div>

    </div>

    <button onClick={()=>handleSubmitAdd()}>Submit</button>
    <button onClick={()=>handleCancelAddTime()}>取消</button>

     </div>
        
        
    </div>
  )
}
