import { useRef, useState, useLayoutEffect, useMemo,Suspense,useEffect } from 'react'
import { Canvas, useFrame, useLoader } from '@react-three/fiber'
import { OrbitControls, ContactShadows, Sphere,Stars,useProgress,Html, } from '@react-three/drei'
import * as THREE from "three";
import {Link} from 'react-router-dom'
import {
    PlayCircleFill,
    StopCircleFill,
    Speedometer2,
    CardList,
    XCircle
  } from "react-bootstrap-icons";
  import { Helmet } from 'react-helmet';

import './index.scss'


function Loader() {
    const { progress } = useProgress()
    return <Html center> <h2 id='loadText'>
          Loading ...{progress.toFixed(0)}%
          <img id='loadImage' src="https://res.cloudinary.com/dvr397xda/image/upload/v1660580851/cube/output-onlinegiftools_rgnpbb.gif" alt="" />
          </h2></Html>
  }

export default function OrgPage() {
    
    const [stop,setStop]=useState(false)
    const [speed,setSpeed]= useState(0.0001)
    const [open,setOpen]=useState(false)

    const handleStop=()=>{
        setStop(!stop)
    }

    const ORG =[
        {
            name:'Gary',
            img:'https://res.cloudinary.com/dvr397xda/image/upload/v1675792120/logo/WechatIMG15861_ctu8da.png',
            position:[410.5, 261, 14],
            rotation:[Math.PI * 2.2,0,0],
            scale:2,
            url:'3dcard/Gary%20Kong'
        },
        {
            name:'Johnny',
            img:'https://res.cloudinary.com/dvr397xda/image/upload/v1675983654/logo/WechatIMG15896_hsddho.png',
            position:[422, 266, 7.5],
            rotation:[0,Math.PI *1.5,0],
            scale:2,
            url:'3dcard/johnnychen' 
        },
        {
            name:'Lv',
            img:'https://res.cloudinary.com/dvr397xda/image/upload/v1675983654/logo/WechatIMG15897_gsqbmx.png',
            position:[429.5, 257.5, 16],
            rotation:[Math.PI/6,Math.PI/1.3,Math.PI*1.95],
            scale:2,
            url:'3dcard/stevenlu' 
        },
         {
            name:'Lin',
            img:'https://res.cloudinary.com/dvr397xda/image/upload/v1675983654/logo/WechatIMG15898_c6lyb4.png',
            position:[421, 248, 27],
            rotation:[Math.PI/8,Math.PI/1.3,Math.PI*1.9],
            scale:2,
            url:'3dcard/jacklin' 
        },
          {
            name:'Gary1',
            img:'https://res.cloudinary.com/dvr397xda/image/upload/v1675890917/logo/WechatIMG15884_z9akn0.png',
            position:[416, 222, 27],
            rotation:[Math.PI*1.8,0,Math.PI*2],
            scale:2,
            url:'https://nysaca.com/' 
        },
          {
            name:'Gary2',
            img:'https://res.cloudinary.com/dvr397xda/image/upload/v1675890917/logo/WechatIMG15884_z9akn0.png',
            position:[418, 208, 17],
            rotation:[Math.PI*1.8,0,Math.PI*1.95],
            scale:2,
            url:'' 
        },
        {
            name:'Gary3',
            img:'https://res.cloudinary.com/dvr397xda/image/upload/v1675890917/logo/WechatIMG15884_z9akn0.png',
            position:[421, 206, 4],
            rotation:[Math.PI/5,0,Math.PI*2],
            scale:2,
            url:'' 
        },
        {
            name:'Gary4',
            img:'https://res.cloudinary.com/dvr397xda/image/upload/v1675890917/logo/WechatIMG15884_z9akn0.png',
            position:[423.5, 214, -9],
            rotation:[Math.PI/5,0,Math.PI*2],
            scale:2,
            url:'' 
        },
        {
            name:'Gary5',
            img:'https://res.cloudinary.com/dvr397xda/image/upload/v1675890917/logo/WechatIMG15884_z9akn0.png',
            position:[428.5, 232, -11],
            rotation:[Math.PI/80,Math.PI*1.1,Math.PI*2],
            scale:2,
            url:'' 
        },
         {
            name:'1',
            img:'https://res.cloudinary.com/dvr397xda/image/upload/v1675890917/logo/WechatIMG15884_z9akn0.png',
            position:[438.5, 232, 9],
            rotation:[Math.PI/80,Math.PI*1.4,Math.PI*2],
            scale:2,
            url:'' 
        },
        {
            name:'2',
            img:'https://res.cloudinary.com/dvr397xda/image/upload/v1675890917/logo/WechatIMG15884_z9akn0.png',
            position:[448, 228, 23],
            rotation:[Math.PI/80,Math.PI,Math.PI*2],
            scale:2,
            url:'' 
        },
        {
            name:'3',
            img:'https://res.cloudinary.com/dvr397xda/image/upload/v1675890917/logo/WechatIMG15884_z9akn0.png',
            position:[451, 233, 43],
            rotation:[Math.PI/80,Math.PI,Math.PI*2],
            scale:2,
            url:'' 
        },
        {
            name:'4',
            img:'https://res.cloudinary.com/dvr397xda/image/upload/v1675890917/logo/WechatIMG15884_z9akn0.png',
            position:[448, 241, 62],
            rotation:[Math.PI/0.55,Math.PI,Math.PI*2.02],
            scale:2,
            url:'' 
        },
        {
            name:'5',
            img:'https://res.cloudinary.com/dvr397xda/image/upload/v1675890917/logo/WechatIMG15884_z9akn0.png',
            position:[448, 241, 62],
            rotation:[Math.PI/0.55,Math.PI,Math.PI*2.02],
            scale:2,
            url:'' 
        },
        {
            name:'6',
            img:'https://res.cloudinary.com/dvr397xda/image/upload/v1675890917/logo/WechatIMG15884_z9akn0.png',
            position:[443.2, 269, 91],
            rotation:[Math.PI/3,Math.PI/0.5,Math.PI*2.02],
            scale:2,
            url:'' 
        },
        {
            name:'7',
            img:'https://res.cloudinary.com/dvr397xda/image/upload/v1675890917/logo/WechatIMG15884_z9akn0.png',
            position:[444.5, 275.5, 76],
            rotation:[Math.PI/0.5,Math.PI/0.5,Math.PI*2],
            scale:2,
            url:'' 
        },
        {
            name:'8',
            img:'https://res.cloudinary.com/dvr397xda/image/upload/v1675890917/logo/WechatIMG15884_z9akn0.png',
            position:[450.5, 277, 53],
            rotation:[Math.PI/0.5,Math.PI/0.53,Math.PI*2],
            scale:2,
            url:'' 
        },
        {
            name:'9',
            img:'https://res.cloudinary.com/dvr397xda/image/upload/v1675890917/logo/WechatIMG15884_z9akn0.png',
            position:[460.3, 270, 30],
            rotation:[Math.PI/0.5,Math.PI/0.5,Math.PI*1.98],
            scale:2,
            url:'' 
        },
        {
            name:'10',
            img:'https://res.cloudinary.com/dvr397xda/image/upload/v1675890917/logo/WechatIMG15884_z9akn0.png',
            position:[462, 263.5, 5],
            rotation:[Math.PI/0.55,Math.PI/0.5,Math.PI*2.03],
            scale:2,
            url:'' 
        },
        {
            name:'11',
            img:'https://res.cloudinary.com/dvr397xda/image/upload/v1675890917/logo/WechatIMG15884_z9akn0.png',
            position:[461.5, 252.8, -15],
            rotation:[Math.PI/0.55,Math.PI/0.53,Math.PI*1.96],
            scale:2,
            url:'' 
        },
        {
            name:'12',
            img:'https://res.cloudinary.com/dvr397xda/image/upload/v1675890917/logo/WechatIMG15884_z9akn0.png',
            position:[460.5, 236.5, -10],
            rotation:[Math.PI/0.48,-Math.PI*1.15,Math.PI*1.985],
            scale:2,
            url:'' 
        },
        {
            name:'13',
            img:'https://res.cloudinary.com/dvr397xda/image/upload/v1675890917/logo/WechatIMG15884_z9akn0.png',
            position:[461.3, 231, 5],
            rotation:[Math.PI/0.48,Math.PI*1.15,Math.PI*2.02],
            scale:2,
            url:'' 
        },
        {
            name:'14',
            img:'https://res.cloudinary.com/dvr397xda/image/upload/v1675890917/logo/WechatIMG15884_z9akn0.png',
            position:[475.5, 225.5, 22],
            rotation:[Math.PI/0.48,Math.PI*1.2,Math.PI*2.05],
            scale:2,
            url:'' 
        },
        {
            name:'15',
            img:'https://res.cloudinary.com/dvr397xda/image/upload/v1675890917/logo/WechatIMG15884_z9akn0.png',
            position:[486.3, 223, 34],
            rotation:[Math.PI/0.48,Math.PI*1.2,Math.PI*2.02],
            scale:2,
            url:'' 
        },
        {
            name:'16',
            img:'https://res.cloudinary.com/dvr397xda/image/upload/v1675890917/logo/WechatIMG15884_z9akn0.png',
            position:[482, 206, 54],
            rotation:[Math.PI/0.55,Math.PI*0.5,Math.PI*2.22],
            scale:2,
            url:'' 
        },
        {
            name:'17',
            img:'https://res.cloudinary.com/dvr397xda/image/upload/v1675890917/logo/WechatIMG15884_z9akn0.png',
            position:[458, 203, 52],
            rotation:[Math.PI/0.55,Math.PI*0.45,Math.PI*2.18],
            scale:2,
            url:'' 
        },
        {
            name:'18',
            img:'https://res.cloudinary.com/dvr397xda/image/upload/v1675890917/logo/WechatIMG15884_z9akn0.png',
            position:[437, 188, 51],
            rotation:[Math.PI/0.45,Math.PI,Math.PI*1.95],
            scale:2,
            url:'' 
        },
        {
            name:'19',
            img:'https://res.cloudinary.com/dvr397xda/image/upload/v1675890917/logo/WechatIMG15884_z9akn0.png',
            position:[425, 183, 70],
            rotation:[Math.PI/0.55,Math.PI*0.5,Math.PI*2.15],
            scale:2,
            url:'' 
        },
        {
            name:'20',
            img:'https://res.cloudinary.com/dvr397xda/image/upload/v1675890917/logo/WechatIMG15884_z9akn0.png',
            position:[412.5, 184, 56],
            rotation:[Math.PI/0.5,Math.PI*0.25,Math.PI*2.02],
            scale:2,
            url:'' 
        },
        {
            name:'21',
            img:'https://res.cloudinary.com/dvr397xda/image/upload/v1675890917/logo/WechatIMG15884_z9akn0.png',
            position:[407.5, 187, 43],
            rotation:[Math.PI/0.5,Math.PI*0.25,Math.PI*2.02],
            scale:2,
            url:'' 
        },
         {
            name:'22',
            img:'https://res.cloudinary.com/dvr397xda/image/upload/v1675890917/logo/WechatIMG15884_z9akn0.png',
            position:[406, 195.2, 20.5],
            rotation:[Math.PI/0.49,Math.PI*0.1,Math.PI*2.02],
            scale:2,
            url:'' 
        },
        {
            name:'23',
            img:'https://res.cloudinary.com/dvr397xda/image/upload/v1675890917/logo/WechatIMG15884_z9akn0.png',
            position:[406, 206, 1.3],
            rotation:[Math.PI/0.45,Math.PI*0.05,Math.PI*1.95],
            scale:2,
            url:'' 
        },
        {
            name:'24',
            img:'https://res.cloudinary.com/dvr397xda/image/upload/v1675890917/logo/WechatIMG15884_z9akn0.png',
            position:[398.5, 221.5, 6.4],
            rotation:[Math.PI/0.5,Math.PI*0.85,Math.PI*2.02],
            scale:2,
            url:'' 
        },
        {
            name:'25',
            img:'https://res.cloudinary.com/dvr397xda/image/upload/v1675890917/logo/WechatIMG15884_z9akn0.png',
            position:[386.5, 237, 4.5],
            rotation:[Math.PI/2.3,Math.PI*1.85,Math.PI*2.22],
            scale:2,
            url:'' 
        },
    ]

    const handleOpenList = ()=>{
        setOpen(true)
    }
    const handleCloseList=()=>{
        setOpen(false)

    }
    const handleClick=(v)=>{
        console.log(v.name);
        if(v.url[5]===':'){
            window.open(`${v.url}`,'_self')
        }
        else{

            window.open(`https://meta11w.com/${v.url}`,'_self')
        }

    }

    

  return (
    <div  className='org-container' >

<Helmet
      title={'美国纽约中美商会'}
      meta={[
        {
          property:'og:image',
          content:'https://res.cloudinary.com/dvr397xda/image/upload/v1675890917/logo/WechatIMG15884_z9akn0.png'
        },
       
        {
          name: '美国纽约中美商会' ,
          content:'中美商会成立于2002年，是在美国注册的工商团体。商会成员的分布以纽约市为中心遍及中美各个地区，从事的行业广泛，有房地产开发，新能源科技，人工智能，金融投资，电子商务， 各类产品的设计和制造，国际贸易，建筑装潢，医药保健，餐饮娱乐，服装礼品，以及各种服务行业等等商会是信息交流的中心，是商业机会研讨，会员之间互相帮助和项目合作的平 台，同时商会还积极联络同胞，以团体的力量热心服务社区，弘扬爱国精神，促 进美中友谊。经过大家的长期不懈努力，商会在中美两地的政、商、侨、学等各 界都受到赞誉。商会将会成为中美地区的笃实有力的重要华人社团',
        },
      ]}
    
    />

        <div id='org-play' onClick={()=>handleStop()}>
        {stop? <PlayCircleFill   /> :<StopCircleFill   /> }
        </div>
        <div id='org-list'>
            <CardList onClick={()=>handleOpenList()}/>
        </div>

        {/* <div className='org-slider'>
            <input type="range" max='0.0004' min='0.0001' step='0.0001' value={speed} onChange={(e)=>setSpeed(Number(e.target.value))} />
            <Speedometer2/>

        </div> */}

        <div style={{height:open ? '100vh':'0vh',transition:'1s'}} className='org-pop'>
            {open&&<XCircle onClick={()=>handleCloseList()}/>}
            <div className='org-pop-wrap'>
            <div className='org-pop-wrap-top'>
                <img src="https://res.cloudinary.com/dvr397xda/image/upload/v1675890917/logo/WechatIMG15884_z9akn0.png" alt="" />
                <h3>关于我们</h3>
                <p>美国纽约中美商会成立于2002年，是在美国注册的工商团体。商会成员的分布以纽约市为中心遍及中美各个地区，从事的行业广泛，有房地产开发，新能源科技，人工智能，金融投资，电子商务， 各类产品的设计和制造，国际贸易，建筑装潢，医药保健，餐饮娱乐，服装礼品，以及各种服务行业等等。

商会是信息交流的中心，是商业机会研讨，会员之间互相帮助和项目合作的平 台，同时商会还积极联络同胞，以团体的力量热心服务社区，弘扬爱国精神，促 进美中友谊。经过大家的长期不懈努力，商会在中美两地的政、商、侨、学等各 界都受到赞誉。商会将会成为中美地区的笃实有力的重要华人社团。</p>
                </div>
                <h2>商会成员</h2>
                <div className='org-pop-wrap-team'>
                {
                    ORG.map((v,i)=>(
                        <div key={i}>

                            <img onClick={()=>handleClick(v)} src={v.img}  alt="" />
                        </div>
                    ))
                }
            </div>
            </div>

           

        </div>
        
       

    <Canvas camera={{ fov: 60, far: 20000 }} id='canvas-bg'>
    <Suspense fallback={<Loader/>}>
    <ambientLight intensity={0.3} />

      <Tunnel
       
       stop={stop}
        speed={speed}
      />
      {
        ORG.map((v,i)=>(
            <Logo key={i} img={v.img} position={v.position} rotation={v.rotation} scale={v.scale} name={v.name} url={v.url} />
        ))
      }
     
     
      
      {/* <ContactShadows rotation-x={Math.PI / 2} position={[0, -1.5, 0]} opacity={0.4} width={10} height={10} blur={2} far={1.5} /> */}
      </Suspense>

    </Canvas>

    </div>
  )
}


function  Logo({img,position,scale,rotation,name,url}){
   
        
    const mesh = useRef()

    const material = useLoader(THREE.TextureLoader,img)

    // useFrame(({clock}) => {
  
    //     mesh.current.rotation.y += 0.005
    //     mesh.current.position.y = Math.abs(Math.sin(clock.elapsedTime*0.2))
    //   })
    const handleClick=()=>{
        console.log(name);
        if(url[5]===':'){
            window.open(`${url}`,'_self')
        }
        else{

            window.open(`https://meta11w.com/${url}`,'_self')
        }

    }

    return (
        <mesh onClick={()=>handleClick()} scale={scale} rotation={rotation} position={position} ref={mesh} >
            <planeGeometry attach="geometry" args={[1,1]}  />
            <meshStandardMaterial side={THREE.DoubleSide}   transparent={true}  map={material} />
           
        </mesh>
    )

}

function Tunnel({stop,speed}){
    const shapePath=[
        [410, 255, 20],
        [413, 268, 7],
        [431, 261, 12],
        [418, 244, 30],
        [416, 217, 25],
        [420, 205, 8],
        [427, 227, -20],
        [432, 236, 5],
        [444, 228, 12],
        [451, 232, 41],
        [446, 246, 72],
        [443, 264, 96],
        [446, 278, 65],
        [463, 267, 20],
        [460, 258, -10],
        [464, 243, -20],
        [459, 233, 0],
        [475, 225, 22],
        [484, 225, 29],
        [490, 214, 51],
        [476, 202, 55],
        [462, 202, 55],
        [446, 205, 42],
        [440, 192, 42],
        [430, 183, 72],
        [413, 184, 58],
        [406, 191, 32],
        [406, 207, 0],
        [402, 220, 0],
        [390, 222, 20],
        [385, 228, 10],
        [389, 246, 0]
      ]
    
        const cameraSpeed = speed,
        lightSpeed = 0.001,
        tubularSegments = 1000,
        radialSegments = 20,
        tubeRadius = 3,
        ambientLight = '#222222',
        lightColor = '#ffffff',
        lightIntensity = 1,
        lightDistance = 20,
        hueStart = 0,
        hueEnd = 360
    const pct = useRef(0)
    const pct2 = useRef(0)
    const geometry = useRef()
    const groupRef = useRef()
    const [vertCount, setVertCount] = useState(0)
    const path = useMemo(() => new THREE.CatmullRomCurve3(shapePath.map((path) => new THREE.Vector3(...path))), [shapePath])
  
    useLayoutEffect(() => {
      if (!vertCount) setVertCount(geometry.current.attributes.position.count)
    }, [geometry, vertCount])

    let texture  = useLoader(THREE.TextureLoader,'https://res.cloudinary.com/dvr397xda/image/upload/v1675724433/logo/galaxyTexture_joycyk.jpg')
  
    const colorArray = useMemo(() => {
      let hue = hueStart
      let hup = true
      const tempColor = new THREE.Color()
      return Float32Array.from(
        new Array(vertCount).fill().flatMap((_, i) => {
          hup === 1 ? hue++ : hue--
          hup = hue === hueEnd ? false : hue === hueStart ? true : 0
          return tempColor.setHSL(hue / 100, 1, 0.5).toArray()
        })
      )
    }, [vertCount, hueEnd, hueStart])
    
  
    // useFrame(({ camera }) => {
    //   const children = groupRef.current.children
    //   const pt1 = path.getPointAt(pct.current % 1)
    //   const pt2 = path.getPointAt((pct.current + 0.01) % 1)
    //   pct.current += cameraSpeed
    //   pct2.current += lightSpeed
    //   camera.position.copy(pt1)
    //   camera.lookAt(pt2)
    //   children[0].position.copy(pt2)
    //   children[1].position.copy(path.getPointAt((pct2.current + 0.0) % 1))
    //   children[2].position.copy(path.getPointAt((pct2.current + 0.2) % 1))
    //   children[3].position.copy(path.getPointAt((pct2.current + 0.4) % 1))
    //   children[4].position.copy(path.getPointAt((pct2.current + 0.6) % 1))
    //   children[5].position.copy(path.getPointAt((pct2.current + 0.8) % 1))
    //   camera.updateProjectionMatrix()
    // })

    useFrame(({ camera }) => {
        const children = groupRef.current.children
        const pt1 = path.getPointAt(pct.current % 1)
        const pt2 = path.getPointAt((pct.current + 0.01) % 1)
        if(stop){
            pct.current +=0
        }
        else{
            pct.current += cameraSpeed

        }
        pct2.current += lightSpeed
        camera.position.copy(pt1)
        camera.lookAt(pt2)
        children[0].position.copy(pt2)
        children[1].position.copy(path.getPointAt((pct2.current + 0.0) % 1))
        children[2].position.copy(path.getPointAt((pct2.current + 0.2) % 1))
        children[3].position.copy(path.getPointAt((pct2.current + 0.4) % 1))
        children[4].position.copy(path.getPointAt((pct2.current + 0.6) % 1))
        children[5].position.copy(path.getPointAt((pct2.current + 0.8) % 1))
        // console.log(camera.position,camera.rotation);
        camera.updateProjectionMatrix()
      })

    return (
      <>
        <group ref={groupRef}>
          <pointLight color={lightColor} intensity={1} distance={50} />
          <pointLight intensity={lightIntensity} color={lightColor} distance={lightDistance} />
          <pointLight intensity={lightIntensity} color={lightColor} distance={lightDistance} />
          <pointLight intensity={lightIntensity} color={lightColor} distance={lightDistance} />
          <pointLight intensity={lightIntensity} color={lightColor} distance={lightDistance} />
          <pointLight intensity={lightIntensity} color={lightColor} distance={lightDistance} />
          <ambientLight intensity={1} color={ambientLight} />
        </group>
        {/* <mesh>
        <tubeGeometry ref={geometry} args={[path, tubularSegments, tubeRadius, radialSegments, true]}>
          <bufferAttribute attachObject={['attributes', 'color']} args={[colorArray, 3]} />
        </tubeGeometry>
        <meshLambertMaterial side={THREE.BackSide} vertexColors wireframe />
      </mesh> */}
      <mesh>
  <tubeGeometry ref={geometry} args={[path, tubularSegments, tubeRadius, radialSegments, false]}>
  <bufferAttribute attachObject={['attributes', 'color']} args={[colorArray, 3]} />
</tubeGeometry>
<meshLambertMaterial side={THREE.DoubleSide}  map={texture} />
</mesh>
      </>
    )
  }



  const shapePath=[
    [389, 246, 0],
    [410, 255, 20],
    [413, 268, 7],
    [431, 261, 12],
    [418, 244, 30],
    [416, 217, 25],
    [420, 205, 8],
    [427, 227, -20],
    [432, 236, 5],
    [444, 228, 12],
    [451, 232, 41],
    [446, 246, 72],
    [443, 264, 96],
    [446, 278, 65],
    [463, 267, 20],
    [460, 258, -10],
    [464, 243, -20],
    [459, 233, 0],
    [475, 225, 22],
    [484, 225, 29],
    [490, 214, 51],
    [476, 202, 55],
    [462, 202, 55],
    [446, 205, 42],
    [440, 192, 42],
    [430, 183, 72],
    [413, 184, 58],
    [406, 191, 32],
    [406, 207, 0],
    [402, 220, 0],
    [390, 222, 20],
    [385, 228, 10],
    [389, 246, 0]
  ]