import React, { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

import CheckoutForm from "./checkoutForm";
import axios from 'axios'
import './index.scss'


const stripePromise = loadStripe("pk_test_51LX3TIEQ6Jizg69Re3M0PrASobmhWYZIZsSX60DwFTo9x0hZfYAwtfYxIM41mTGh3KEqULtPRxNQcMV9GsobKWAB00R6PU3UQm");
const apiUrl ='https://metaserverapp.com'

const table = [
 
    ['模块创建',true,true],
    ['模块页面',true,true],
    ['简介文案*',true,true],
    ['微信二维码',true,true],
    ['背景',true,true],
    ['Email链接',true,true],
    ['网站链接',true,true],
    ['电话链接',true,true],
    ['地图链接',true,true],
    ['3d模型',true,true],
    ['团队介绍*',false,true],
    ['产品介绍*',false,true],
    ['载入动画',false,true],
    ['轮播图',false,true],
    ['3d商标',false,true],
    ['背景音乐',false,true],
]

const COMBO = [
    {
        name:'高级版',
        price:139,
        select:true
    },
    {
        name:'特级版',
        price:199,
        select:false
    },
    {
        name:'特色动态背景',
        price:30,
        select:false
    },
    {
        name:'视频剪辑',
        price:15,
        select:false
    },
    {
        name:'专属动画',
        price:30,
        select:false
    },
    {
        name:'DIY3D商标',
        price:40,
        select:false
    },
    {
        name:'专属动画',
        price:60,
        select:false
    },
    
]


export default function Information() {

    const [clientSecret, setClientSecret] = useState("");
    const [next,setNext] = useState(false)
    const [email, setEmail] = useState('');
    const [name,setName] = useState('');
    const [phone,setPhone] =useState('');
    const [wechat,setWechat] =useState('')
    const [amount,setAmount] = useState(139)
    const [success, setSuccess] = useState(false);
    const [combo,setCombo]= useState(COMBO)
    const [paymentId,setPaymentId] = useState('');
   
    const [read,setRead] = useState(false);

   
    
  
    // useEffect(() => {
        
    //     const fetchClientSecret = async()=>{

    //         const data =await axios.post(`${apiUrl}/form`,{
    //             amount:amount
    //         })
    //         console.log(data)
    //         setClientSecret(data.data.clientSecret);
    //     }
    //     if(next){
    //     fetchClientSecret()
    //     }

    // },[next,amount])

    const appearance = {
        theme: 'stripe',
      };
      const options = {
        clientSecret,
        appearance,
      };

      const validateEmail = (i) => {
        return String(i)
          .toLowerCase()
          .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          );
      };

      const handleNext = async()=>{

        if(!name){
          return  alert('Please enter a name');
        }
        if(phone.length !==10){
            return alert('Please enter a legal phone number');
        }
        const valid = validateEmail(email)
        if(!valid){
            return alert('Please enter an valid email address');
        }
       
        const data =await axios.post(`${apiUrl}/form`,{
            amount:amount
        })
        console.log(data)
        setClientSecret(data.data.clientSecret);
        if(data.status===200){

            setNext(true)
            setRead(true)
        }
        else{
            alert('Error! Something went wrong!')
        }
      }

      const handleChangeCombo = (i)=>{
          
        if(i===0){
            let temp = [...combo]
            temp[0].select = !combo[0].select
            temp[1].select = !temp[0].select
            setCombo(temp)
    
            const arr = temp.filter(v=>v.select===true)
           
                let money = 0
                for(let i=0;i<arr.length;i++){
                    money += arr[i].price
                }
                setAmount(money)
        }
        else if(i===1){
            let temp = [...combo]
            temp[1].select = !combo[1].select
            temp[0].select = !temp[1].select
            setCombo(temp)
    
            const arr = temp.filter(v=>v.select===true)
           
    
           
                let money = 0
                for(let i=0;i<arr.length;i++){
                    money += arr[i].price
                }
                setAmount(money)
        }
        else{
            let temp = [...combo]
            temp[i].select=!combo[i].select
            setCombo(temp)
    
            const arr = temp.filter(v=>v.select===true)
        
    
           
                let money = 0
                for(let i=0;i<arr.length;i++){
                    money += arr[i].price
                }
                setAmount(money)
        }
      
      }

      const handleChangeName=(e)=>{
          setName(e.target.value)
      }
      const handleChangePhone =(e)=>{ 
          setPhone(e.target.value)
      }
      const handleChangeEmail =(e)=>{ 
          setEmail(e.target.value)
      }
      const handleChangeWechat =(e)=>{ 
          setWechat(e.target.value)
      }
   
  return (
    <div className='info-wrap'> 
        
      
       <div className="Info-container">

        <h2>星天元宇宙名片价目表</h2>

        <div className='compare-wrap'>
            <table>
                <thead>
                    <tr>
                        <th> 项目</th>
                        <th>高级版</th>
                        <th>特级版</th>
                    </tr>
                </thead>
            <tbody>
            {
                table&&table.map((v,i)=>(
                    <tr key={i}>
                        {
                            v.map((m,n)=>(
                                n===0 ?<td key={n}>
                                    {m}
                                </td>
                                :
                                <td style={{backgroundColor:m?'#07ca0080':'#ff1b1b80'}} key={n}>
                                    {m?'Yes':'No'}
                                </td>
                            ))
                        }
                    </tr>
                ))
            }
            <tr>
                <td>价格(一年)</td>
                <td>$139</td>
                <td>$199</td>
            </tr>
            <tr>
                <td>价格(二年)</td>
                <td>$199</td>
                <td>$299</td>
            </tr>
             </tbody>
          
            </table>
            <p>注:*标项目需用户提供相应信息</p>

        </div>

        <div className='combo-wrap'>
            <h2>选择您的套餐</h2>

            <table>
                <thead>
                    <tr>
                        <th>项目</th>
                        <th>价格</th>
                        <th>选择</th>
                    </tr>
                </thead>
                <tbody>
                   
                    {
                        combo&&combo.map((v,i)=>(
                            <tr key={i}>
                                <td>{v.name}</td>
                                <td>{`$${v.price}`}</td>
                                <td><input onChange={read? null:()=>handleChangeCombo(i)}  type="checkbox" checked={v.select} readOnly={read} /></td>
                            </tr>
                        ))
                    }
                    <tr>
                        <td>总价</td>
                      
                       <td>{`$${amount}`}</td>
                      
                    </tr>
                </tbody>
            </table>
            <p>注:后期修改一次内容$10起</p>
            <br />
            <br />
            <p>动态背景制作|视频剪辑|
                载入动画制作|3d建模|点餐系统 
            </p>
            <br/>
            <br/>
            <p>
            <a href="tel:+13015200518">具体价格请联系客服:30152005518</a>
            </p>
          
        </div>

     
        <div className='form-container'>
        <h2>联系信息</h2>
        <div className='form-wrap'>
           
            <form onSubmit={(e) => e.preventDefault()}>
            <input
            type="text"
            value={name}
            onChange={(e)=>handleChangeName(e)}
            placeholder="Enter Your Name*"
          />
           <input
            type="text"
            value={phone}
            onChange={(e) => handleChangePhone(e)}
            placeholder="Enter Your Phone*"
          />
              <input
            type="email"
            value={email}
            onChange={(e) => handleChangeEmail(e)}
            placeholder="Enter email address*"
          />
           <input
            type="text"
            value={wechat}
            onChange={(e)=>handleChangeWechat(e)}
            placeholder="Enter Your Wechat Id"
          />
            </form>
         
    
            </div>
        </div>
           
        
      {  success ? 
       <div className='success-wrap'>
           <div >
           <h2>Payment Success!</h2>
           <h2>您的订单号为{paymentId}</h2>
           <h2>订单已发送至您的手机和email信箱</h2>
          <h2>我们将联系您制作您的专属元宇宙名片！</h2>
           </div>
         
       </div> :
        ((next? (clientSecret && <Elements options={options} stripe={stripePromise}>
            <CheckoutForm name={name} phone={phone} wechat={wechat} amount={amount} combo={combo} email={email} setSuccess={setSuccess} setPaymentId={setPaymentId} />
          </Elements>
        ): <button onClick={()=>handleNext()}>Next</button>)
    )
    }
    
  </div>



    </div>
  )
}
