import React,{useState} from 'react'
import './index.scss'


    const images = [
        'https://res.cloudinary.com/dvr397xda/image/upload/v1655480580/robot/robot-1_lt39hy.jpg',
        'https://res.cloudinary.com/dvr397xda/image/upload/v1655480579/robot/robot-2_fifa9g.jpg',
        'https://res.cloudinary.com/dvr397xda/image/upload/v1655480581/robot/robot-3_bccind.jpg',
        'https://res.cloudinary.com/dvr397xda/image/upload/v1655480584/robot/robot-4_nhpk3f.jpg',
        'https://res.cloudinary.com/dvr397xda/image/upload/v1655480582/robot/robot-5_kfcxn2.jpg',
        'https://res.cloudinary.com/dvr397xda/image/upload/v1655480581/robot/robot-6_ximajt.jpg',
        'https://res.cloudinary.com/dvr397xda/image/upload/v1655480579/robot/robot-7_zb34of.jpg',
        'https://res.cloudinary.com/dvr397xda/image/upload/v1655480585/robot/robot-8_r34gae.jpg',
        'https://res.cloudinary.com/dvr397xda/image/upload/v1655480584/robot/robot-9_anhuuf.jpg',
        'https://res.cloudinary.com/dvr397xda/image/upload/v1655480588/robot/robot-10_muaoty.jpg',
        'https://res.cloudinary.com/dvr397xda/image/upload/v1655480579/robot/robot-11_jllzdq.jpg',
        'https://res.cloudinary.com/dvr397xda/image/upload/v1655480579/robot/robot-12_q06i4m.jpg',
        'https://res.cloudinary.com/dvr397xda/image/upload/v1655480580/robot/robot-13_cd3tbv.jpg',
        'https://res.cloudinary.com/dvr397xda/image/upload/v1655480581/robot/robot-14_no1jrr.jpg',
        'https://res.cloudinary.com/dvr397xda/image/upload/v1655480586/robot/robot-15_ltrcdn.jpg',
        'https://res.cloudinary.com/dvr397xda/image/upload/v1655480582/robot/robot-16_i9tcnk.jpg',
        'https://res.cloudinary.com/dvr397xda/image/upload/v1655480582/robot/robot-17_cmxrjo.jpg',
        'https://res.cloudinary.com/dvr397xda/image/upload/v1655480586/robot/robot-18_o18xl6.jpg',
        'https://res.cloudinary.com/dvr397xda/image/upload/v1655480586/robot/robot-19_qcywso.jpg',
        'https://res.cloudinary.com/dvr397xda/image/upload/v1655480587/robot/robot-20_n3o4as.jpg',
        'https://res.cloudinary.com/dvr397xda/image/upload/v1655480588/robot/robot-21_czech4.jpg',
        'https://res.cloudinary.com/dvr397xda/image/upload/v1655480587/robot/robot-22_svmjkc.jpg',
        'https://res.cloudinary.com/dvr397xda/image/upload/v1655480588/robot/robot-23_ftyryb.jpg',
        'https://res.cloudinary.com/dvr397xda/image/upload/v1655480586/robot/robot-24_uz9irr.jpg',
        'https://res.cloudinary.com/dvr397xda/image/upload/v1655480584/robot/robot-25_yznoax.jpg',
        'https://res.cloudinary.com/dvr397xda/image/upload/v1655480584/robot/robot-26_aallmh.jpg',
        'https://res.cloudinary.com/dvr397xda/image/upload/v1655480581/robot/robot-27_kpsiu2.jpg',
        'https://res.cloudinary.com/dvr397xda/image/upload/v1655480581/robot/robot-28_smhcei.jpg',
        'https://res.cloudinary.com/dvr397xda/image/upload/v1655480579/robot/robot-29_fm9syx.jpg',
        'https://res.cloudinary.com/dvr397xda/image/upload/v1655480584/robot/robot-30_xwezbs.jpg',
        'https://res.cloudinary.com/dvr397xda/image/upload/v1655480584/robot/robot-31_cr9ti0.jpg',
        'https://res.cloudinary.com/dvr397xda/image/upload/v1655480586/robot/robot-32_jz5yua.jpg',
        'https://res.cloudinary.com/dvr397xda/image/upload/v1655480583/robot/robot-33_xb4vju.jpg',
        'https://res.cloudinary.com/dvr397xda/image/upload/v1655480588/robot/robot-34_embzpc.jpg',
        'https://res.cloudinary.com/dvr397xda/image/upload/v1655480586/robot/robot-35_iewugv.jpg',
       
    ]


export default function Rotate() {

    const [value, setValue] = useState(0);

    const getBackgroundSize = () => {
      return { backgroundSize: `${(value * 100) / 34}% 100%` };
    };
   
  return (
      <div className="rotatable-image" >
        <img src={images[value]} alt="" />
        <input
        type="range"
        min="0"
        max='34'
        onChange={(e) => setValue(e.target.value)}
        style={getBackgroundSize()}
        value={value}
      />
      </div>
  )
}
