const data = [

    { 
        id:'star-chen',
        name:'Yuxin Chen',
        tel:'3015200518',
        cell:'',
        email:'yuxin1992218@gmail.com',
        address:'4306 Main St,Flushing,NY,11355',
        map:'https://www.google.com/maps/place/43-06+Main+St,+Flushing,+NY+11355/@40.7522664,-73.8315613,17z/data=!3m1!4b1!4m5!3m4!1s0x89c2606cd640cb53:0x141db878e9815fe!8m2!3d40.7522665!4d-73.8270766',
        video:'',
        facebook:'',
        background:'https://res.cloudinary.com/dvr397xda/image/upload/v1653927826/cube/1921iq6-a-room-that-has-a-umbrella-in-her-hand_rainyw.jpg',
        linkedin:'',
        website:'https://xingtianstudio.com/',
        website2:'https://xiaopodun.com/',

        images:[
            'https://res.cloudinary.com/dvr397xda/image/upload/v1658518055/cube/weiiz/WechatIMG9330_gm5mez.png', //right
            'https://res.cloudinary.com/dvr397xda/image/upload/v1658518055/cube/weiiz/WechatIMG9330_gm5mez.png', //left
            'https://res.cloudinary.com/dvr397xda/image/upload/v1658518055/cube/weiiz/WechatIMG9330_gm5mez.png', //glass
            'https://res.cloudinary.com/dvr397xda/image/upload/v1658518055/cube/weiiz/WechatIMG9330_gm5mez.png', //glass
            'https://res.cloudinary.com/dvr397xda/image/upload/v1659132272/cube/basic/yuxin/WechatIMG9537_maonje.png', //front
            'https://res.cloudinary.com/dvr397xda/image/upload/v1659134102/cube/basic/yuxin/WechatIMG9549_deadgm.png'//back
    ],
  
    gif:'https://res.cloudinary.com/dvr397xda/image/upload/v1659113858/cube/basic/yuxin/ef724f2c2cf02a434b8464f17fe40ca1_iuctf2.gif',
    loadImg:'',
    qrCode:'https://res.cloudinary.com/dvr397xda/image/upload/v1659132293/cube/basic/yuxin/WechatIMG9541_ucafdd.png',
    model:{
        name:'mew',
        url:'https://res.cloudinary.com/dvr397xda/raw/upload/v1659132397/cube/basic/yuxin/MewtwoRigged_n4m1iy.fbx',
        metalMap:'',
        normalMap:'https://res.cloudinary.com/dvr397xda/image/upload/v1659132407/cube/basic/yuxin/MewtwoNormals_gy3tnq.png',
        roughnessMap:'',
        colorMap:'https://res.cloudinary.com/dvr397xda/image/upload/v1659132405/cube/basic/yuxin/Mewtwo_BaseColor_cov8jc.png',
        aoMap:'',
        scale:0.01,
        position:[0,-0.8,0],
        rotation:[0,0,0],
    }
    // model:{
    //     name:'mew',
    //     url:'https://res.cloudinary.com/dvr397xda/raw/upload/v1673373582/model/hat/cap_wx4zqn.fbx',
    //     metalMap:'',
    //     normalMap:'https://res.cloudinary.com/dvr397xda/image/upload/v1673373580/model/hat/NORMALMAPFINAL_y0p5wn.png',
    //     roughnessMap:'',
    //     colorMap:'https://res.cloudinary.com/dvr397xda/image/upload/v1673373581/model/hat/diffuse2_hc7pwn.png',
    //     aoMap:'',
    //     scale:0.001,
    //     position:[0,-0.8,0],
    //     rotation:[0,0,0],
    // }
    },

    { 
        id:'star',
        name:'Yuxin Chen',
        tel:'3015200518',
        cell:'',
        email:'yuxin1992218@gmail.com',
        address:'4306 Main St,Flushing,NY,11355',
        map:'https://www.google.com/maps/place/43-06+Main+St,+Flushing,+NY+11355/@40.7522664,-73.8315613,17z/data=!3m1!4b1!4m5!3m4!1s0x89c2606cd640cb53:0x141db878e9815fe!8m2!3d40.7522665!4d-73.8270766',
        video:'',
        facebook:'',
        background:'https://res.cloudinary.com/dvr397xda/image/upload/v1653927826/cube/1921iq6-a-room-that-has-a-umbrella-in-her-hand_rainyw.jpg',
        linkedin:'',
        website:'https://xingtianstudio.com/',
        images:[
            'https://res.cloudinary.com/dvr397xda/image/upload/v1658518055/cube/weiiz/WechatIMG9330_gm5mez.png', //right
            'https://res.cloudinary.com/dvr397xda/image/upload/v1658518055/cube/weiiz/WechatIMG9330_gm5mez.png', //left
            'https://res.cloudinary.com/dvr397xda/image/upload/v1658518055/cube/weiiz/WechatIMG9330_gm5mez.png', //glass
            'https://res.cloudinary.com/dvr397xda/image/upload/v1658518055/cube/weiiz/WechatIMG9330_gm5mez.png', //glass
            'https://res.cloudinary.com/dvr397xda/image/upload/v1659132272/cube/basic/yuxin/WechatIMG9537_maonje.png', //front
            'https://res.cloudinary.com/dvr397xda/image/upload/v1658518055/cube/weiiz/WechatIMG9330_gm5mez.png'//back
    ],
  
    gif:'https://res.cloudinary.com/dvr397xda/image/upload/v1659113858/cube/basic/yuxin/ef724f2c2cf02a434b8464f17fe40ca1_iuctf2.gif',
    loadImg:'',
    qrCode:'https://res.cloudinary.com/dvr397xda/image/upload/v1659132293/cube/basic/yuxin/WechatIMG9541_ucafdd.png',
    model:{
        name:'mew',
        url:'https://res.cloudinary.com/dvr397xda/raw/upload/v1659133881/cube/basic/yuxin/Mewtwo_dp3tcl.fbx',
        metalMap:'',
        normalMap:'',
        roughnessMap:'',
        colorMap:'',
        aoMap:'',
        scale:0.4,
        position:[0,-0.8,0],
        rotation:[0,0,0],
    }
    },

  




]

const fetchBasic = ()=>{
    return data;
}


export default fetchBasic