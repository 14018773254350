const data = [

    { 
        id:'massage-palace',
        name:'Massage Palace',
        tel:'2035246553',
        cell:'',
        email:'',
        address:'2111 Summer St 2nd floor, Stamford, CT 06905',
        map:'https://www.google.com/maps/place/Massage+Palace/@41.066999,-73.8253564,11z/data=!4m9!1m2!2m1!1smassage+palace!3m5!1s0x89c2a146bc323921:0xc748359f9bbaaff7!8m2!3d41.0669924!4d-73.5451841!15sCg5tYXNzYWdlIHBhbGFjZVoQIg5tYXNzYWdlIHBhbGFjZZIBC21hc3NhZ2Vfc3BhmgEjQ2haRFNVaE5NRzluUzBWSlEwRm5TVVJ0YlUxRFptRm5FQUU',
        video:'',
        facebook:'',
        background:'https://res.cloudinary.com/dvr397xda/image/upload/v1653927826/cube/1921iq6-a-room-that-has-a-umbrella-in-her-hand_rainyw.jpg',
        linkedin:'',
        website:'https://massage-palace.com/',
        menu:'https://res.cloudinary.com/dvr397xda/image/upload/v1665415659/WechatIMG12036_ymwumm.jpg',
        images:[
            'https://res.cloudinary.com/dvr397xda/image/upload/v1658518055/cube/weiiz/WechatIMG9330_gm5mez.png', //right
            'https://res.cloudinary.com/dvr397xda/image/upload/v1658518055/cube/weiiz/WechatIMG9330_gm5mez.png', //left
            'https://res.cloudinary.com/dvr397xda/image/upload/v1658518055/cube/weiiz/WechatIMG9330_gm5mez.png', //glass
            'https://res.cloudinary.com/dvr397xda/image/upload/v1658518055/cube/weiiz/WechatIMG9330_gm5mez.png', //glass
            'https://res.cloudinary.com/dvr397xda/image/upload/v1659449114/cube/basic/massage%20palace/WechatIMG9644_e5fy4r.png', //front
            'https://res.cloudinary.com/dvr397xda/image/upload/v1659462949/cube/basic/massage%20palace/WechatIMG9647_dplvi4.png'//back
    ],
  
    gif:'https://res.cloudinary.com/dvr397xda/image/upload/v1659462876/cube/basic/massage%20palace/image_processing20220103-19040-2urele_ndtxns.gif',
    loadImg:'https://res.cloudinary.com/dvr397xda/image/upload/v1659462787/cube/basic/massage%20palace/9bd4936d76302a3798663365c5900077_vmbpxm.gif',
    qrCode:'',
    model:{
        name:'Massage Bed',
        url:'https://res.cloudinary.com/dvr397xda/raw/upload/v1659461705/cube/basic/massage%20palace/scene_lzt9su.fbx',
        metalMap:'',
        normalMap:'',
        roughnessMap:'',
        colorMap:'',
        aoMap:'',
        scale:0.001,
        position:[0,-0.8,0],
        rotation:[0,Math.PI * 2.5,0],
    }
    },

  




]

const fetchBasic = ()=>{
    return data;
}


export default fetchBasic