import React ,{useRef}from 'react'

import { DoubleSide } from 'three';




export default function Plane(props) {

    const mesh = useRef()

    console.log(props)
    return (
        <mesh
         
          ref={mesh}
        position={props.position}
         rotateX={props.rotateX}
         rotateY={props.rotateY}
         rotateZ={props.rotateZ}
          >
              
              <planeBufferGeometry  attach="geometry" args={[2, 2]} />
              <meshStandardMaterial
        attach="material"
        roughness={0.6}
        metalness={0.1}
        color={props.color}
        side={DoubleSide}
      />
        </mesh>
      )
  
}
