const data= [
    {
        id:'1',
        name:'佛堂',
        data:[
            {
                name:'',
                url: 'https://res.cloudinary.com/dvr397xda/image/upload/v1657308253/cube/panorama/IMG_20220703_181420_00_019_kgghqf.jpg',
            },
            {
                name:'',
                url:'https://res.cloudinary.com/dvr397xda/image/upload/v1657308248/cube/panorama/IMG_20220703_181325_00_017_brgmeo.jpg',
            },
            {
                name:'',
                url:'https://res.cloudinary.com/dvr397xda/image/upload/v1657308244/cube/panorama/IMG_20220703_175730_00_011_bowf9z.jpg',
            },
            {
                name:'',
                url:         'https://res.cloudinary.com/dvr397xda/image/upload/v1657308241/cube/panorama/IMG_20220703_175718_00_010_nnfoej.jpg', 
            },
            {
                name:'',
                url:'https://res.cloudinary.com/dvr397xda/image/upload/v1657308236/cube/panorama/IMG_20220703_181201_00_015_vvvwkb.jpg',
            },
         
         
           
      
        ]
    },
    {
        id:'2',
        name:'佛堂',

       data:[
        {
            name:'',
            url:  'https://res.cloudinary.com/dvr397xda/image/upload/v1657903895/cube/fotang/PIC_20220709_173821_20220714152205_mk1aji.jpg',
        },
        {
            name:'',
            url:     'https://res.cloudinary.com/dvr397xda/image/upload/v1657903895/cube/fotang/PIC_20220709_173110_20220714152205_ehwiam.jpg',
        },
        {
            name:'',
            url:   'https://res.cloudinary.com/dvr397xda/image/upload/v1657903894/cube/fotang/PIC_20220709_173143_20220714152205_yx8xya.jpg',
        },
        {
            name:'',
            url:   'https://res.cloudinary.com/dvr397xda/image/upload/v1657903893/cube/fotang/PIC_20220709_173002_20220714152205_fydqib.jpg',
        },
        {
            name:'',
            url: 'https://res.cloudinary.com/dvr397xda/image/upload/v1657903892/cube/fotang/PIC_20220709_173053_20220714152205_q6dtyk.jpg',
        },
        {
            name:'',
            url: 'https://res.cloudinary.com/dvr397xda/image/upload/v1657903889/cube/fotang/PIC_20220709_173021_20220714152205_ydexbs.jpg',
        },
        {
            name:'',
            url: 'https://res.cloudinary.com/dvr397xda/image/upload/v1657903881/cube/fotang/PIC_20220709_172619_20220714152205_kbdxts.jpg',
        },
        {
            name:'',
            url:    
            'https://res.cloudinary.com/dvr397xda/image/upload/v1657903878/cube/fotang/PIC_20220709_172923_20220714152205_vzzpei.jpg',
        },
        {
            name:'',
            url:    'https://res.cloudinary.com/dvr397xda/image/upload/v1657903877/cube/fotang/PIC_20220709_172843_20220714152205_jgomvi.jpg',
        },
        {
            name:'',
            url:         'https://res.cloudinary.com/dvr397xda/image/upload/v1657903877/cube/fotang/PIC_20220709_172904_20220714152205_w52rds.jpg',
        },
        {
            name:'',
            url:'https://res.cloudinary.com/dvr397xda/image/upload/v1657903876/cube/fotang/PIC_20220709_172445_20220714152205_krrwdp.jpg',
        }, {
            name:'',
            url:   'https://res.cloudinary.com/dvr397xda/image/upload/v1657903876/cube/fotang/PIC_20220709_172445_20220714152205_krrwdp.jpg',
        },
        {
            name:'',
            url:   'https://res.cloudinary.com/dvr397xda/image/upload/v1657903870/cube/fotang/PIC_20220709_172511_20220714152205_hsmmis.jpg',
        }, 

       ]
    },

    {
        id:'3',
        name:'Long Island',
        data:[
            {
                name:'',
                url:     'https://res.cloudinary.com/dvr397xda/image/upload/v1658765692/cube/longisland/PIC_20220723_173142_20220724163538_rx37vt.jpg',
            },
            {
                name:'',
                url:  'https://res.cloudinary.com/dvr397xda/image/upload/v1658765681/cube/longisland/PIC_20220723_173008_20220724163538_y6zaay.jpg',
            },
            {
                name:'',
                url:    'https://res.cloudinary.com/dvr397xda/image/upload/v1658765680/cube/longisland/PIC_20220723_173023_20220724163538_nm8wyi.jpg',
            },
             {
                name:'',
                url:     'https://res.cloudinary.com/dvr397xda/image/upload/v1658765679/cube/longisland/PIC_20220723_173046_20220724163538_pdtpzp.jpg',
            },
            {
                name:'',
                url:   'https://res.cloudinary.com/dvr397xda/image/upload/v1658765674/cube/longisland/PIC_20220723_172949_20220724163538_k8xetn.jpg',
            },
            {
                name:'',
                url:   'https://res.cloudinary.com/dvr397xda/image/upload/v1658765663/cube/longisland/PIC_20220723_172647_20220724163538_k2xto2.jpg',
            },
            {
                name:'',
                url:   'https://res.cloudinary.com/dvr397xda/image/upload/v1658765653/cube/longisland/PIC_20220723_172430_20220724163538_nq7tw2.jpg',
            },
            {
                name:'',
                url:     'https://res.cloudinary.com/dvr397xda/image/upload/v1658765653/cube/longisland/PIC_20220723_172345_20220724163516_ns9guz.jpg',
            },
            {
                name:'',
                url:  'https://res.cloudinary.com/dvr397xda/image/upload/v1658765651/cube/longisland/PIC_20220723_172626_20220724163538_stgwka.jpg',
            },
        
            
            
                
             
           
             
                
             
        
      
              
              
            
           
              
             
            
          

              
              
          
             

             
              

              
             
             
             
           
              
            
           
            
           
        
       
         
        ]
    },
    {
        id:'kinhasushi',
        name:'Kinha Sushi',
        data:[
            {
                name:'门口马路',
                url:'https://res.cloudinary.com/dvr397xda/image/upload/v1662065341/cube/360/kinha/%E9%97%A8%E5%8F%A3%E9%A9%AC%E8%B7%AF_gpzr9f.jpg'
            },
            {
                name:'门口',
                url:'https://res.cloudinary.com/dvr397xda/image/upload/v1662065341/cube/360/kinha/%E9%97%A8%E5%8F%A3_bec98e.jpg'
            },
            {
                name:'走廊',
                url:'https://res.cloudinary.com/dvr397xda/image/upload/v1662065343/cube/360/kinha/%E8%B5%B0%E5%BB%8A_okwcbg.jpg'
            },
            {
                name:'走廊1',
                url:'https://res.cloudinary.com/dvr397xda/image/upload/v1662065340/cube/360/kinha/%E8%B5%B0%E5%BB%8A1_il0p6z.jpg'
            },
            {
                name:'走廊2',
                url:'https://res.cloudinary.com/dvr397xda/image/upload/v1662065343/cube/360/kinha/%E8%B5%B0%E5%BB%8A2_ehneow.jpg'
            },
            {
                name:'酒吧',
                url:'https://res.cloudinary.com/dvr397xda/image/upload/v1662065346/cube/360/kinha/%E9%85%92%E5%90%A7_jngtb0.jpg'
            },
            {
                name:'寿司厅',
                url:'https://res.cloudinary.com/dvr397xda/image/upload/v1662065343/cube/360/kinha/%E5%AF%BF%E5%8F%B8%E5%8E%85_psdy9a.jpg'
            },
            {
                name:'寿司厅2',
                url:'https://res.cloudinary.com/dvr397xda/image/upload/v1662065339/cube/360/kinha/%E5%AF%BF%E5%8F%B8%E5%8E%852_r7ydlz.jpg'
            },
            {
                name:'厅',
                url:'https://res.cloudinary.com/dvr397xda/image/upload/v1662065341/cube/360/kinha/%E5%8E%85_rzevrk.jpg'
            },
            {
                name:'厅2',
                url:'https://res.cloudinary.com/dvr397xda/image/upload/v1662065341/cube/360/kinha/%E5%8E%852_n3utfp.jpg'
            },
          
         
          
         
        ]
    },
    {
        id:'11',
        data:[

        ]
    },
]

const fetchPanorama = () => {
    return data
}

export default fetchPanorama