const data = [
    { 
        id:'philip',
        name:'Philip Chan',
        tel:'9178539266',
        cell:'',
        email:'785895662@qq.com',
        douyin:'',
        website:'',
        address:'68 E Broadway, 4FL New York, NY 10002',
        map:'https://goo.gl/maps/uHnGSdESj1be1Tvd9',
        avatar:'https://res.cloudinary.com/dvr397xda/image/upload/v1654544385/cube/4_ps8jf5.png',
        images:[
            'https://res.cloudinary.com/dvr397xda/image/upload/v1655308640/cube/WechatIMG8423_dxymep.jpg', //right
            'https://res.cloudinary.com/dvr397xda/image/upload/v1655319207/cube/WechatIMG8428_d8pu3m.jpg', //left
            'https://res.cloudinary.com/dvr397xda/image/upload/v1655318238/cube/WechatIMG8426_wmoqac.jpg', 
            'https://res.cloudinary.com/dvr397xda/image/upload/v1655318238/cube/WechatIMG8427_t5rexr.jpg', 
            'https://res.cloudinary.com/dvr397xda/image/upload/v1655317385/cube/WechatIMG8425_uqmbgv.jpg', //front
            'https://res.cloudinary.com/dvr397xda/image/upload/v1655308640/cube/WechatIMG8421_tvsubq.jpg'//back
    ],
    background:'https://res.cloudinary.com/dvr397xda/image/upload/v1653927826/cube/1921iq6-a-room-that-has-a-umbrella-in-her-hand_rainyw.jpg',
    mp3:'https://res.cloudinary.com/dvr397xda/video/upload/v1654542301/cube/f3123acb3ac425e2ff43452046ec6b23_ip7i0c.mp3',
    video:'',
    facebook:'',
    linkedin:'',
    show:[
        'https://res.cloudinary.com/dvr397xda/image/upload/v1655150127/cube/WechatIMG8392_lfrxly.jpg',
        'https://res.cloudinary.com/dvr397xda/image/upload/v1655150127/cube/WechatIMG8393_n2lckn.jpg',
        'https://res.cloudinary.com/dvr397xda/image/upload/v1655150127/cube/WechatIMG8398_kx1bny.jpg',
        'https://res.cloudinary.com/dvr397xda/image/upload/v1655150127/cube/WechatIMG8395_dfgfjt.jpg',
        'https://res.cloudinary.com/dvr397xda/image/upload/v1655150127/cube/WechatIMG8394_elkax7.jpg',

    ],
    gif:'https://res.cloudinary.com/dvr397xda/image/upload/v1650903783/cube/ezgif.com-gif-maker_b2xoms.gif'

    },
   
]

const fetchSingle = ()=>{
    return data
}

export default fetchSingle

