import React,{useState,useEffect,useRef,Suspense,useCallback}from 'react'
import { Canvas, useFrame,extend,useThree,useLoader,} from '@react-three/fiber'
import * as THREE from "three";
import HTMLFlipBook from "react-pageflip";

import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { TextureLoader } from 'three/src/loaders/TextureLoader.js';
import {useGLTF,useFBX,useProgress,Html} from '@react-three/drei'

import './index.scss'

function Loader() {
  const { progress } = useProgress()
  return <Html center> <h2 id='loadText'>
        Loading ...{progress.toFixed(0)}%
        <img id='loadImage' src="https://res.cloudinary.com/dvr397xda/image/upload/v1660580851/cube/output-onlinegiftools_rgnpbb.gif" alt="" />
        </h2></Html>
}

function Heart(){

    const mesh = useRef()

    let heart = useFBX('https://res.cloudinary.com/dvr397xda/raw/upload/v1658527333/cube/heart/PUMPING_HEART_MODEL_xphf1b.fbx')

  
    heart.traverse( function ( child ) {

        if ( child.isMesh ) {
      
          console.log( child.material );
          child.material.color={r:255,g:0,b:0}
        }
      
      } );
      useFrame((state, delta) => {
      
    
        mesh.current.rotation.y += 0.005
      
      })

      return (
        <mesh scale={0.0335} position={[0,-1,0]}  ref={mesh}>
            <primitive scale={0.8}  object={heart} />
    
        </mesh>
      )

}


function Model({ position }){
    const mesh = useRef()

    const { nodes, materials } = useGLTF('https://res.cloudinary.com/dvr397xda/image/upload/v1657212110/cube/museum/mac-draco_cvwbu1.glb')
 
    useFrame((state) => {
        mesh.current.rotation.y += 0.01
    
    })
    // Events and spring animations were added afterwards
    return (
      <group
        ref={mesh}
        position={position}
        scale={0.16}
        >
       
          <group position={[0, 2.96, 0]} rotation={[Math.PI *2.5, 0, 0]}>
            <mesh material={materials.aluminium} geometry={nodes['Cube008'].geometry} />
            <mesh material={materials['matte.001']} geometry={nodes['Cube008_1'].geometry} />
            <mesh material={materials['screen.001']} geometry={nodes['Cube008_2'].geometry} />
          </group>
      
        <mesh material={materials.keys} geometry={nodes.keyboard.geometry} position={[1.79, 0, 3.45]} />
        <group position={[0, -0.1, 3.39]}>
          <mesh material={materials.aluminium} geometry={nodes['Cube002'].geometry} />
          <mesh material={materials.trackpad} geometry={nodes['Cube002_1'].geometry} />
        </group>
        <mesh material={materials.touchbar} geometry={nodes.touchbar.geometry} position={[0, -0.03, 1.2]} />
      </group>
    )
  }



const  Cube = ({position})=>{

  const image = 'https://res.cloudinary.com/dvr397xda/image/upload/v1653324529/cube/%E8%BE%B9%E6%A1%862_b3lfk1.png'
  const mesh = useRef()
  const map = useLoader(THREE.TextureLoader,image)
  useFrame((state, delta) => {
    mesh.current.scale.x = 1 + (1 + Math.sin(state.clock.elapsedTime * 1)) * 0.03
    mesh.current.scale.y = 1 + (1 + Math.sin(state.clock.elapsedTime * 1)) * 0.03
    mesh.current.scale.z = 1 + (1 + Math.sin(state.clock.elapsedTime * 1)) * 0.03
  })

  return (
    <>
    <mesh position={position} ref={mesh}>
        <boxBufferGeometry  attach="geometry"  args={[2.2, 2.2, 2.2]}/>
        <meshStandardMaterial transparent={true} opacity={1}  attach="material" map={map}  roughness={0.1}
         />

       


    </mesh>
    </>
  )
}

function Book({people}){



  const onFlip = useCallback((e) => {
    console.log('Current page: ' + e.data);
}, []);

  return (


 
  <HTMLFlipBook className="wrapper" 
  size='stretch'  width={195}  height={338}        maxShadowOpacity={0.5}
  onFlip={onFlip}
  showCover
 >
  
  {
      people.show&&people.show.map((v,i)=>(
          <div id='page-item' className="demoPage" key={i}>
            
            <h2>
                    {v.name}
                </h2>
                {v.title&&<h2>
                    {v.title}
                </h2>}
               
             <img src={v.image} alt="" />
               
          </div>
      ))
  }
</HTMLFlipBook>

)
}


function Box(props) {
  const {people,position} = props
  const mesh = useRef()
  const [index,setIndex] = useState(0)

 

  useEffect(() => {
    const interval = setInterval(() => {

        if(index ===people.show.length-1){
            setIndex(0)
        }
        else{
            setIndex(index+1)
        }
    }, 2500);
    return () => clearInterval(interval);
  }, [index,people]);
  // setInterval(handleChangeIndex,1000)

  let textures;

textures = useLoader(THREE.TextureLoader,[people.images[0],people.images[1],people.images[2],people.images[3],people.images[4],people.images[5]])
// textures = useLoader(TextureLoader,[people.images[0],people.images[1],people.images[2],people.images[3],people.images[4],people.images[5]])

textures.minFilter = textures.magFilter = THREE.LinearFilter;



textures.format= THREE.RGBAFormat;




  function Map (){
    return(
    
      textures.map((texture, idx) =>(
        idx ===2||idx===3 || idx===0?<meshBasicMaterial key={idx} attach={`material-${idx}`} map={texture}   transparent={true} opacity={1}/>
        :<meshBasicMaterial opacity={1} transparent={true}  key={idx} attach={`material-${idx}`} map={texture}  />
        )
)
    )
  } 

  return (
    <>
    <mesh
      onClick={(e)=>{
        e.stopPropagation()
      }}
      ref={mesh}
     position={position}
     
      >
          
      <boxBufferGeometry attach="geometry"  args={[2.5, 2.5, 2.5]}/>
        <Map/>
       
      

    </mesh>


    </>
  )
}

extend({ OrbitControls });

const CameraControls = () => {

  const {
    camera,
    gl: { domElement },
  } = useThree();
  const controls = useRef();
  useFrame(() => controls.current.update());
  return (
    <orbitControls
      ref={controls}
      args={[camera, domElement]}
      autoRotate={false}
      enableZoom={true}
      maxDistance={5.5}
      minDistance={2}
    />
  );
};

const Phone = ({people,opacity,posY})=>{

  const mesh1 = useRef()


  const handleClick=()=>{
    window.open(`tel:+1${people.tel}`,'_self')
  }


  return (
    <>
    <mesh position={[0,posY-1.2,1.3]} rotation={[0,Math.PI * 2.5,0]} onClick={(e)=>{ handleClick();     e.stopPropagation()
}}   ref={mesh1}>
        <boxBufferGeometry  attach="geometry"  args={[0.1,0.6,2.5]}/>
        <meshStandardMaterial transparent={true} opacity={opacity}   color='green'  />

       


    </mesh>
    </>
  )
}

const Cell = ({people,opacity,posY})=>{
   
    const mesh1 = useRef()
 
  
    const handleClick=()=>{
      window.open(`tel:+1${people.cell}`,'_self')
    }
  
  
    return (
      <>
      <mesh position={[0,posY-1.9,1.3]} rotation={[0,Math.PI * 2.5,0]} onClick={(e)=>{ handleClick();     e.stopPropagation()
  }}   ref={mesh1}>
          <boxBufferGeometry  attach="geometry"  args={[0.1,0.6,2.5]}/>
          <meshStandardMaterial transparent={true} opacity={opacity}  color='red' />
  
         
  
  
      </mesh>
      </>
    )
  }

const Email = ({people,opacity,posY})=>{

  const mesh1 = useRef()
 

  const handleClick=()=>{
 
    window.open(`mailto:+${people.email}`,'_self')
  }

  return (
    <>
    <mesh position={[-1.4,posY-1.8,0.6]} rotation={[0,0,0]} onClick={(e)=>{ handleClick();     e.stopPropagation()
}}   ref={mesh1}>
        <boxBufferGeometry  attach="geometry"  args={[0.1,0.7,1.2]}/>
        <meshStandardMaterial transparent={true} opacity={opacity}  color='#fff' />

       


    </mesh>
    </>
  )
}

const Map = ({people,opacity,posY})=>{
 
  const mesh1 = useRef()


  const handleClick=()=>{

    window.open(`${people.map}`,'_self')
  }

  return (
    <>
    <mesh position={[-1.4,-1+posY,-0.6]} onClick={(e)=>{ handleClick();     e.stopPropagation()
}}   ref={mesh1}>
        <boxBufferGeometry  attach="geometry"  args={[0.1,0.7,1.2]}/>
        <meshStandardMaterial transparent={true} opacity={opacity}   color='blue'   />

       


    </mesh>
    </>
  )
}

const Youtube = ({people,opacity,posY})=>{

    const mesh1 = useRef()
   
  
    const handleClick=()=>{
  
      window.open(`${people.video}`,'_self')
    }
  
    return (
      <>
      <mesh position={[-1.4,-1+posY,0.6]} onClick={(e)=>{ handleClick();     e.stopPropagation()
  }}   ref={mesh1}>
          <boxBufferGeometry  attach="geometry"  args={[0.1,0.7,1.2]}/>
          <meshStandardMaterial transparent={true} opacity={opacity}  attach="material" color='green'  roughness={0.7}
          metalness={0.1} />
  
         
  
  
      </mesh>
      </>
    )
  }

  const QR = ({opacity,posY,handleOpenQr})=>{
  
    const mesh1 = useRef()
  
  
  
    return (
      <>
      <mesh position={[-1.4,-0.2+posY,-0.6]} onClick={(e)=>{  handleOpenQr();     e.stopPropagation()
  }}   ref={mesh1}>
          <boxBufferGeometry  attach="geometry"  args={[0.1,0.7,1.2]}/>
          <meshStandardMaterial transparent={true} opacity={opacity}   color='green'   />
  
         
  
  
      </mesh>
      </>
    )
  }



const Web = ({people,opacity,posY})=>{
 
  const mesh1 = useRef()

  const handleClick=()=>{
    window.open(`${people.website}`,'_self')
  }

  return (
    <>
    <mesh rotation={[0, 0, 0]} position={[-1.4,-0.2+posY,0.6]} onClick={(e)=>{ handleClick();     e.stopPropagation()
}}   ref={mesh1}>
        <boxBufferGeometry  attach="geometry"  args={[0.1,0.7,1.2]}/>
        <meshStandardMaterial transparent={true} opacity={opacity}   color='#fff'   />

       


    </mesh>
    </>
  )
}

const Linkedin = ({people,opacity,posY})=>{
    const mesh1 = useRef()

    const handleClick=()=>{
      window.open(`${people.linkedin}`,'_self')
    }
  
    return (
      <>
      <mesh position={[-0.5,posY-1.5,-1.2]} rotation={[0,Math.PI * 2.5,0]} onClick={(e)=>{ handleClick();     e.stopPropagation()
  }}   ref={mesh1}>
          <boxBufferGeometry  attach="geometry"  args={[0.1,1,1]}/>
          <meshStandardMaterial transparent={true} opacity={opacity}   color='#fff'   />
  
         
  
  
      </mesh>
      </>
    )
}


export default function Huoli1() {
 
    const mesh2 = useRef()

  const people = { 
    id:'johnny',
    name:'Johnny Chen',
    tel:'8453036348',
    cell:'2034569888',
    email:'Johnnychen@nicyg.com',
    address:'4306 Main St,Flushing,NY,11355',
    map:'https://www.google.com/maps/place/43-06+Main+St,+Flushing,+NY+11355/@40.7522664,-73.8315613,17z/data=!3m1!4b1!4m5!3m4!1s0x89c2606cd640cb53:0x141db878e9815fe!8m2!3d40.7522665!4d-73.8270766',
    video:'https://www.youtube.com/@user-fy2bx5nf6f/videos',
    facebook:'https://www.facebook.com/johnny.chen.792197',
    background:'https://res.cloudinary.com/dvr397xda/image/upload/v1653927826/cube/1921iq6-a-room-that-has-a-umbrella-in-her-hand_rainyw.jpg',
    linkedin:'https://www.linkedin.com/in/johnny-chen-358b971bb/',
    avatar:'https://res.cloudinary.com/dvr397xda/image/upload/v1654543127/cube/WechatIMG291_g0cny7.jpg',
    website:'http://metamedia668.com/',
    images:[
        'https://res.cloudinary.com/dvr397xda/image/upload/v1658519035/cube/WechatIMG9330_gm5mez_dpifk5.png', //right
        'https://res.cloudinary.com/dvr397xda/image/upload/v1675122062/WechatIMG15635_rgsphg.png', //left
        'https://res.cloudinary.com/dvr397xda/image/upload/v1658519035/cube/WechatIMG9330_gm5mez_dpifk5.png', //glass
        'https://res.cloudinary.com/dvr397xda/image/upload/v1658519035/cube/WechatIMG9330_gm5mez_dpifk5.png', //glass
        'https://res.cloudinary.com/dvr397xda/image/upload/v1675120782/WechatIMG15625_vbrn8y.png', //front
        'https://res.cloudinary.com/dvr397xda/image/upload/v1660319725/cube/basic/wingo/WechatIMG9810_bulm7n.png'//back
],
show:[
    {
        name:'美达传媒',
        title:'团队介绍',
        image:'https://res.cloudinary.com/dvr397xda/image/upload/v1671641418/WechatIMG14222_vzcpro.png'
    },
    {
        name:'Johnny Chen',
        title:'Founder Ceo',
        image:'https://res.cloudinary.com/dvr397xda/image/upload/v1655150127/cube/WechatIMG8392_lfrxly.jpg'
    },
    {
      name:'Wingo',
      title:'Photography',
      image:'https://res.cloudinary.com/dvr397xda/image/upload/v1675121175/WechatIMG15627_uehd4l.jpg'
    },
    {
      name:'Andrew',
      title:'Photography',
      image:'https://res.cloudinary.com/dvr397xda/image/upload/v1675121333/WechatIMG15631_ldkmx9.png'
    },
    {
        name:'Star',
        title:'UI Designer',
        image:'https://res.cloudinary.com/dvr397xda/image/upload/v1655150127/cube/WechatIMG8395_dfgfjt.jpg'
    },
    {
        name:'Hao',
        title:'UI Designer',
        image:'https://res.cloudinary.com/dvr397xda/image/upload/v1655150127/cube/WechatIMG8398_kx1bny.jpg'
    },
    {
        name:'David',
        title:'Marketing',
        image:'https://res.cloudinary.com/dvr397xda/image/upload/v1655150127/cube/WechatIMG8393_n2lckn.jpg'
    },
    {
        name:'Lily',
        title:'Marketing',
        image:'https://res.cloudinary.com/dvr397xda/image/upload/v1656085726/cube/WechatIMG8679_w8kk3r.jpg'
    },
    {
        name:'美达传媒',
        title:'团队介绍',
        image:'https://res.cloudinary.com/dvr397xda/image/upload/v1671641418/WechatIMG14222_vzcpro.png'
    },

],
gif:'https://res.cloudinary.com/dvr397xda/image/upload/v1658525566/cube/golden-wheat-field_v4unds.gif',
loadVideo:'https://res.cloudinary.com/dvr397xda/video/upload/v1660057708/cube/company/jingyou_yvsw3y.mp4',
qrCode:'https://res.cloudinary.com/dvr397xda/image/upload/v1657220590/cube/company/WechatIMG8887_alcgdr.png'

}

  const [isTeam,setIsTeam] = useState(false)
  const [isQR,setIsQR] = useState(false)
  const [isPlay,setIsPlay] = useState(false)
  const opacity=0
  const posY = 1

  const [load,setLoad] = useState(false)

  const handleOpenQr = ()=>{
      setIsQR(true)
  }




  const handleClickTeam = ()=>{
    setIsTeam(true)
  }
  const handleCloseTeam = ()=>{
    setIsTeam(false)
    setIsQR(false)
  }


  const handleClick =()=>{
    setLoad(true)
  }
  
 const handlePlay =()=>{
   setIsPlay(true)
 }

 


  return (
    <>

        <div className="container-card" style={{backgroundImage:`url(${people.gif})`}}  >

        <Canvas  gl={{ alpha: true, antialias: true}}  className='canvas-wrap'>
        <CameraControls />
            <pointLight position={[3, 3, 3]} intensity={2} color='#f0f0f0'/>
            <pointLight position={[3, -3, 3]} intensity={2} color='#f0f0f0'/>
    
            <pointLight position={[-3, 3, 3]} intensity={2} color='#f0f0f0'/>
    
            <pointLight position={[3, 3, -3]} intensity={2} color='#f0f0f0'/>
    
          <Suspense fallback={<Loader/>}>
       
    
            
          <Box people={people}   position={[0, 0, 0]} />
        
    
          {/* <Cube position={[0, 0, 0]} /> */}
          <Heart />
          
    
          <Phone posY={posY} people={people} opacity={opacity}/>
          <Cell posY={posY} people={people} opacity={opacity}/>
    
          <Email posY={posY} people={people} opacity={opacity}/>
          <QR handleOpenQr={handleOpenQr} posY={posY} people={people} opacity={opacity}/>
          <Map posY={posY} people={people} opacity={opacity}/>
          <Web posY={posY} people={people} opacity={opacity}/>
          <Youtube posY={posY} people={people} opacity={opacity}/>
    
          {/* <Linkedin posY={posY} people={people} opacity={opacity}/> */}
          {/* <VideoBtn people={people} opacity={opacity}/> */}
    
          <mesh rotation={[0,0,0]} position={[-1.4,-1.8+posY,-0.6]} onClick={(e)=>{ handleClickTeam();     e.stopPropagation()
    }}   ref={mesh2}>
            <boxBufferGeometry  attach="geometry"  args={[0.1,0.7,1.2]}/>
            <meshStandardMaterial transparent={true} opacity={opacity}    color='red'   />
    
           
        </mesh>
        
        </Suspense>
    
        </Canvas> 
     
   
    <div style={isTeam ? {top:'20%',transition:'1.5s'}:{top:'200%',transition:'1.5s'}}  className="wrapper">
          <Book people={people}/>
          <img onClick={()=>handleCloseTeam()} id='close' src="https://res.cloudinary.com/dvr397xda/image/upload/v1656101942/cube/cancel_dmukx0.png" alt="" />
    </div>
         

         

          <div style={isQR ? {top:'20%',transition:'1.5s'}:{top:'200%',transition:'1.5s'}}  className="wrapper">
          <img id='qr' src={people.qrCode} alt="" />
          <h3>长按识别图中二维码</h3>

          <img onClick={()=>handleCloseTeam()} id='close' src="https://res.cloudinary.com/dvr397xda/image/upload/v1656101942/cube/cancel_dmukx0.png" alt="" />
    </div>

    </div>
        
        
      
      <div className="play-container" style={{backgroundImage:`url(${people.gif})`}}>

        <img onClick={()=>handlePlay()} id='playBtn' src="https://res.cloudinary.com/dvr397xda/image/upload/v1660056267/cube/company/play-button_2_xp9vqi.png" alt="" />
      </div>
        
    
    </>
  
  )
}
