import React,{useState,useEffect} from 'react'
import {Link,useNavigate } from 'react-router-dom'
import axios from 'axios'
// import Select from 'react-select'
// import Nav from '../../../components/Nav/index'

import './index.scss'



export default function UserSignUp() {

    const apiUrl ='https://metaserverapp.com'
    const navigate = useNavigate();


    // const [location,setLocation] = useState('')
    // const [category,setCategory] = useState('')
    const [phone,setPhone] = useState('')
    const [email,setEmail] = useState('')
    const [password,setPassword] = useState('')
    const [password2,setPassword2] = useState('')

    // const [gender,setGender] = useState('')
    const [name,setName] =useState('')
    const [text,setText]=useState('获取验证码')
    const [isSms,setIsSms]=useState(false)
    const [code,setCode]=useState('')
    const [id,setId]=useState('')

    // const locations =[
    //     {
    //         value:'曼哈顿',
    //         label:'曼哈顿',
    //     },
    //     {
    //         value:'布鲁克林',
    //         label:'布鲁克林',
    //     },
    //     {
    //         value:'法拉盛',
    //         label:'法拉盛',
    //     },
    //     {
    //         value:'Elmhurst',
    //         label:'Elmhurst',
    //     },
    //     {
    //         value:'Staten Island',
    //         label:'Staten Island',
    //     },
    //     {
    //         value:'Long Island City',
    //         label:'Long Island City',
    //     },
    //     {
    //         value:'Long Island',
    //         label:'Long Island',
    //     },
    //    {
    //         value:'其它地区',
    //         label:'其它地区',
    
    //     }
    // ]
    
    // const categorys = [
    //     {
    //         value:'餐饮',
    //         label:'餐饮',
    //     },
    //     {
    //         value:'地产经纪',
    //         label:'地产经纪',
    //     },
    //     {
    //         value:'律师',
    //         label:'律师',
    //     },
    //     {
    //         value:'汽车买卖',
    //         label:'汽车买卖',
    //     },
    //     {
    //         value:'保险',
    //         label:'保险',
    //     },
    //     {
    //         value:'贷款',
    //         label:'贷款',
    //     },
    //     {
    //         value:'会计',
    //         label:'会计',
    //     },
    //     {
    //         value:'搬家',
    //         label:'搬家',
    //     },
    //     {
    //         value:'旅行',
    //         label:'旅行',
    //     },
    //     {
    //         value:'驾校',
    //         label:'驾校',
    //     },
    //     {
    //         value:'装修',
    //         label:'装修',
            
    
    //     },
    //     {
    //         value:'医生',
    //         label:'医生',
    //     },
    //     {
    //         value:'美容',
    //         label:'美容',
    //     },
    //     {
    //         value:'按摩',
    //         label:'按摩',
    //     },
    //     {
    //         value:'美甲',
    //         label:'美甲',
    //     },
    //     {
    //         value:'移民',
    //         label:'移民',
    //     },
    //     {
    //         value:'印刷',
    //         label:'印刷',
    //     },
    //     {
    //         value:'婚庆',
    //         label:'婚庆',
    //     },
    //     {
    //         value:'摄影',
    //         label:'摄影',
    //     },
    //     {
    //         value:'设计',
    //         label:'设计',
    //     },
    //     {
    //         value:'物流',
    //         label:'物流',
    //     },
    //     {
    //         value:'其它',
    //         label:'其它',
    //     },
    // ]
    
    // const genders = [
    //     { value: '男', label: '男' },
    //     { value: '女', label: '女' },
    //     { value: '不想回答', label: '不想回答' },
    
    // ]

    // const handleLocation = (e)=>{
    //     setLocation(e.value)
    // }

    // const handleCategory = (e)=>{
    //     setCategory(e.value)
    // }

    // const handleGender = (e)=>{
    //     setGender(e.value)
    // }

    const handlePhone=(e)=>{ 
        setPhone(e.target.value)
    }
    const handleEmail = (e)=>{ 
        setEmail(e.target.value)
    }
    const handlePassword = (e)=>{ 
        setPassword(e.target.value)
    }
    const handlePassword2 = (e)=>{ 
        setPassword2(e.target.value)
    }
    const handleName=(e)=>{ 
        setName(e.target.value)
    }



    const handleCode=(e)=>{ 
        setCode(e.target.value)
    }


    const handleSubmit =async()=>{
        if(!phone){
            alert('Please enter a phone number')
        }
        if(!name){
            alert('Please enter a  name')
        }
 
        if(!email){
            alert('Please enter a email')
        }
        // if(!gender){
        //     alert('Please enter a gender')
        // }
        // if(!category){
        //     alert('Please enter a category')
        // }
        // if(!location){
        //     alert('Please enter a location')
        // }
        if(!password){
            alert('Please enter a Password')
        }
        if(password !==password2){
            alert('Password not Match!')
        }
        if(!code){
            alert('请输入您手机收到的验证码')
        }

            const verify = await axios.post(`${apiUrl}/sms/check`,{
                request_id:id,
                code:code
            })
            console.log(verify);
            if(verify.data.success){
                const result = await axios.post(`${apiUrl}/auth/user`,{
                    phone,name,email,password
                })
        
                if(result.data.success){
                    window.localStorage.setItem('token',result.data.token)
                    window.localStorage.setItem('userId',result.data._id)
                    navigate("/user/dashboard");
                }
                else{
                    alert(`error ${result.data.data}`)
                }
            }
            else{
                alert('验证码错误')
            }
            
    }

    const handleGetSms= async()=>{
        if(phone.length===10){
            const temp = '1'+phone
            setIsSms(true)
            setText('60')
            const result = await axios.post(`${apiUrl}/sms/verify`,{
                number:temp
            })
            console.log(result.data)
            if(result.data.success){
                setId(result.data.code)
            }
        }
        else{
            alert('Please eneter correct phone number')
        }

    }


console.log(id)
    useEffect(() => {
        if(isSms){
            const interval =setInterval(() => {
                if(text!==1){
                    let temp = text
                    temp = temp-1
                    setText(temp--)
                }
                else{
                    setIsSms(false)
                    setText('获取验证码')
                }

            },1000)
            return () => clearInterval(interval);


        }

        
    },[isSms,text])

  

  return (
    <div className="user-login-wrap">

<div className="form-structor">
	<div className="signup">
		<h2 className="form-title" id="signup"><span></span>注册账户</h2>
        <Link to='/user/login'><h3 className="form-title2">已有账户，现在登录</h3></Link>
		<div className="form-holder">
			<input value={name} onChange={(e)=>handleName(e)} type="text" className="input" placeholder="Name" />
         
            <div>
            <input value={phone} onChange={(e)=>handlePhone(e)}  type="text" className="input" placeholder="Phone" />
            <button onClick={()=>handleGetSms()} disabled={isSms}>{text}</button>
            </div>

            <input value={code} onChange={(e)=>handleCode(e)}  type="text" className="input" placeholder="输入验证码" />


			<input value={email} onChange={(e)=>handleEmail(e)} type="email" className="input" placeholder="Email" />
			<input value={password} onChange={(e)=>handlePassword(e)} type="password" className="input" placeholder="Password" />
            <input value={password2} onChange={(e)=>handlePassword2(e)} type="password" className="input" placeholder="Password Again" />
           


		</div>
        {/* <div className='login-select-wrap'>
            <div className="create-input-wrap">
        <label>地区*</label>
        <Select           
        onChange={(e)=>handleLocation(e)}
        options={locations} 
        maxMenuHeight='20vh'
        menuPortalTarget={document.body}
        menuPosition="fixed"
        />
    </div>

    <div className="create-input-wrap">
        <label>分类*</label>
        <Select
        onChange={(e)=>handleCategory(e)}
        options={categorys}    menuPortalTarget={document.body}
        menuPosition="fixed"       maxMenuHeight='20vh'
        />
        
    </div>
    <div className="create-input-wrap">
        <label>性别*</label>
        <Select
        onChange={(e)=>handleGender(e)}
        options={genders}      menuPortalTarget={document.body}
        menuPosition="fixed"     maxMenuHeight='20vh'
        />
    </div>
    </div> */}
		<button onClick={()=>handleSubmit()} className="submit-btn">Sign up</button>
	</div>

</div>

    
    </div>
  )
}
